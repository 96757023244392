import * as types from 'action-types';
import axios from 'axios';
import { isEmpty, find } from 'lodash';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import { callAPI, encrypt, MEMBERSHIP_REQUEST_TYPE } from 'helpers/util-common';
import { status, memStockCodeConstant, stockCodeConstant, membershipTypeConstant } from 'modules/admin/manual-order/manual-order.model';

const getMembershipTypeListAction = (dispatch, callback) => {
    let url = getApiUrl('manageMembers', 'getMembershipTypeList');
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
                dispatch({ type: types.GET_MEMBERSHIPTYPE_LIST, membershipTypeList: response.data.content });
            } else {
                // Handle error case
                callback([]);
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '7025'));
        });
}

const getCommitteeListAction = (dispatch, callback) => {
    let url = getApiUrl('manageCommittee', 'getCommitteeList');
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback([]);
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3149'));
        });
}

const getPrimaryActivityListAction = (membershipTypeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getPrimaryActivities') + membershipTypeId;
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback([]);
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3150'));
        });
}

const getSubCommitteesListAction = (committeeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getUnSuppressedSubCommitteeList') + committeeId;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                callback([]);
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '9106'));
        });
}

const getDemographicAndMemberPersonalDetailsAction = (accountNumber, orderNumber, orderDate, callback, dispatch) => {
    let urlGetDemographicDetails = getApiUrl('manageMembers', 'getDemographicDetails') + `/${accountNumber}`;
    let urlGetMemberPersonalDetails = getApiUrl('manageMembers', 'getMemberPersonalDetails') + `/${encrypt(accountNumber)}`;
    let urlGetMembershipOrderDetails = getApiUrl('manageMembers', 'getMembershipOrderDetails') + `/${encrypt(orderNumber)}/${encrypt(accountNumber)}`;
    let urlGetMemberTempInformation = getApiUrl('manageMembers', 'getMemberTempInformation');
    dispatch(commonActions.setLoader(true));

    Promise.all([axios.get(urlGetDemographicDetails), axios.get(urlGetMemberPersonalDetails), axios.get(urlGetMembershipOrderDetails)])
        .then(([getDemographicDetailsResponse, getMemberPersonalDetailsResponse, getMembershipOrderDetailsResponse]) => {
            let demographicDetails = {};
            let memberPersonalDetails = {};
            let membershipOrderDetails = {};
            let memberTempInformationDetails = {};
            let orderDetailsObj = {};

            if (getDemographicDetailsResponse.data.status) {
                demographicDetails = getDemographicDetailsResponse.data.content;
                if (getMemberPersonalDetailsResponse.data.status) {
                    memberPersonalDetails = getMemberPersonalDetailsResponse.data.content;
                } else {
                    dispatch(commonActions.setMessage(true, getMemberPersonalDetailsResponse.data.message));
                }

                if (getMembershipOrderDetailsResponse.data.status) {
                    // Check If XErrorMsg is not empty from api response
                    if (getMembershipOrderDetailsResponse.data.content
                        && getMembershipOrderDetailsResponse.data.content.XErrorMsg) {
                        dispatch(commonActions.setMessage(true, '9132'));
                    }
                    membershipOrderDetails = getMembershipOrderDetailsResponse.data.content;
                } else {
                    dispatch(commonActions.setMessage(true, getMembershipOrderDetailsResponse.data.message));
                }

                const { OrderDetails } = membershipOrderDetails;
                let orderType = getOrderType(memberPersonalDetails, OrderDetails);
                if (OrderDetails) {
                    const { OrderLineItemDetails } = OrderDetails;
                    orderDetailsObj = getOrderDetailsObject(OrderLineItemDetails, accountNumber, orderNumber, orderType);
                }
                if (demographicDetails && demographicDetails.Email && orderType === MEMBERSHIP_REQUEST_TYPE.NEWMEMBER) {
                    axios.get(`${urlGetMemberTempInformation}/${demographicDetails.Email}/${orderDate}`)
                        .then((memberTempInfoResponse) => {
                            dispatch(commonActions.setLoader(false));
                            if (memberTempInfoResponse.data.status) {
                                memberTempInformationDetails = memberTempInfoResponse.data.content;
                                prepareMemberTempInformationToPrefill(orderType, memberTempInformationDetails);
                                callback({ demographicDetails, memberPersonalDetails, membershipOrderDetails, memberTempInformationDetails, orderDetailsObj });
                            } else {
                                // Handle error case
                                dispatch(commonActions.setMessage(true, memberTempInfoResponse.data.message));
                            }
                        })
                }
                else {
                    dispatch(commonActions.setLoader(false));
                    callback({ demographicDetails, memberPersonalDetails, membershipOrderDetails, memberTempInformationDetails, orderDetailsObj });
                }
            } else {
                dispatch(commonActions.setMessage(true, getDemographicDetailsResponse.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '9132'));
        });
}

const getOrderDetailsObjAndStockCode = (orderLineItemDetails) => {
    let stockCode = null
    const orderObj = find(orderLineItemDetails, (details) => {
        return details.OrderedItem && details.OrderedItem.indexOf(stockCodeConstant.member) === 0 ? true : false;
    });
    if (isEmpty(orderObj)) {
        return null;
    }
    const OrderedItem = orderObj.OrderedItem;
    if (OrderedItem.indexOf(memStockCodeConstant.MEMORGANIZATIONAL) !== -1) {
        stockCode = memStockCodeConstant.MEMORGANIZATIONAL
    } else if (OrderedItem.indexOf(memStockCodeConstant.MEMSTUDENT) !== -1) {
        stockCode = memStockCodeConstant.MEMSTUDENT
    }
    else if (OrderedItem.indexOf(memStockCodeConstant.MEMINDIVIDUAL) !== -1) {
        stockCode = memStockCodeConstant.MEMINDIVIDUAL
    }

    return { orderObj, stockCode };
}

const getOrderDetailsObject = (orderLineItemDetails, accountNumber, orderNumber, orderType) => {
    const { orderObj, stockCode } = getOrderDetailsObjAndStockCode(orderLineItemDetails);
    orderObj.accountNumber = accountNumber;
    orderObj.orderNumber = orderNumber;
    orderObj.orderType = orderType;
    orderObj.stockCode = stockCode;

    return orderObj;
}

const getOrderType = (memberPersonalDetails, orderDetails) => {
    let orderType = null;
    if (orderDetails) {
        orderType = MEMBERSHIP_REQUEST_TYPE.NEWMEMBER;
    }
    if (memberPersonalDetails) {
        if (memberPersonalDetails.MemberTypeId === membershipTypeConstant.studentMember) {
            orderType = MEMBERSHIP_REQUEST_TYPE.STUDENT_UPGRADE;
        } else if (memberPersonalDetails.StatusName === status.active) {
            orderType = MEMBERSHIP_REQUEST_TYPE.RENEW;
        } else if (memberPersonalDetails.StatusName === status.historical) {
            orderType = MEMBERSHIP_REQUEST_TYPE.REINSTATE;
        }
    }

    return orderType;
}

const prepareMemberTempInformationToPrefill = (orderType, memberTempInformationDetails) => {
    if (haveFormDataInMemberTempInformationDetailsInOnboardingProcess(orderType, memberTempInformationDetails)) {
        memberTempInformationDetails.haveFormData = true;
        memberTempInformationDetails.haveCompanyDetails = haveCompanyDetailsInMemberTempInformationDetails(memberTempInformationDetails);
        memberTempInformationDetails.haveStudentEducationalDegreeDetails = haveStudentEducationalDegreeDetailsInMemberTempInformationDetails(memberTempInformationDetails);
        memberTempInformationDetails.haveCommitteeDetails = haveCommitteeDetailsInMemberTempInformationDetails(memberTempInformationDetails);
        if (memberTempInformationDetails.haveCompanyDetails) {
            memberTempInformationDetails.haveCompanyAddressDetails = haveCompanyAddressDetailsInMemberTempInformationDetails(memberTempInformationDetails);
            if (memberTempInformationDetails.haveCompanyAddressDetails) {
                memberTempInformationDetails.FormData.CompanyData.Address.PostalCode = memberTempInformationDetails.FormData.CompanyData.Address.ZipCode;
            }
        }
    }
}

const haveFormDataInMemberTempInformationDetailsInOnboardingProcess = (orderType, memberTempInformationDetails) => {
    return orderType === MEMBERSHIP_REQUEST_TYPE.NEWMEMBER &&
        memberTempInformationDetails &&
        memberTempInformationDetails.FormData ? true : false;
}

const haveCompanyDetailsInMemberTempInformationDetails = (memberTempInformationDetails) => {
    return memberTempInformationDetails.FormData.CompanyData ? true : false
}

const haveCompanyAddressDetailsInMemberTempInformationDetails = (memberTempInformationDetails) => {
    return (memberTempInformationDetails.FormData.CompanyData &&
        memberTempInformationDetails.FormData.CompanyData.Address) ? true : false;
}

const haveStudentEducationalDegreeDetailsInMemberTempInformationDetails = (memberTempInformationDetails) => {
    return memberTempInformationDetails.FormData.EducationDetails ? true : false;
}

const haveCommitteeDetailsInMemberTempInformationDetails = (memberTempInformationDetails) => {
    return (memberTempInformationDetails.FormData.CommitteeDetails && memberTempInformationDetails.FormData.CommitteeDetails.length > 0) ? true : false;
}

const getMasterVolumeAction = (postData, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'postMasterVolume');
    axios.post(url, postData)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content ? response.data.content : []);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '9148')); // Default exception error
        });
}

const getOrganizationListAction = (orgName, callback) => {
    let url = getApiUrl('manageManualOrder', 'searchCompany') + encrypt(orgName.toLowerCase());
    callAPI(url, 'get', null, (response) => {
        callback(response.data.content);
    })
}

const getCompanyAddressListAction = (orgName, callback, dispatch) => {
    let url = getApiUrl('manageManualOrder', 'companyAddressList') + encrypt(orgName);
    dispatch(commonActions.setLoader(true));

    callAPI(url, 'get', null, (response) => {
        dispatch(commonActions.setLoader(false));
        if (response.data && response.data.status) {
            callback(response.data.content);
        } else {
            // Handle error case
            callback([]);
            dispatch(commonActions.setMessage(true, response.data.message));
        }
    })
}

const postNewMemberAction = (postData, type, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'createNewMember');
    dispatch(commonActions.setLoader(true));
    axios.post(url, postData)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content ? response.data.content : []);
                if (type === MEMBERSHIP_REQUEST_TYPE.RENEW) {
                    dispatch(commonActions.setMessage(true, "9169"));
                } else if (type === MEMBERSHIP_REQUEST_TYPE.REINSTATE) {
                    dispatch(commonActions.setMessage(true, "9170"));
                } else if (type === MEMBERSHIP_REQUEST_TYPE.STUDENT_UPGRADE) {
                    dispatch(commonActions.setMessage(true, "9171"));
                } else {
                    dispatch(commonActions.setMessage(true, "9167"));
                }
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '9165')); // Default exception error
        });
}

const postValidateActiveRepAction = (postData, type, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'checkAssociateMember');
    dispatch(commonActions.setLoader(true));
    axios.post(url, postData)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '9165')); // Default exception error
        });
}

const postValidateHistoricalOrgAction = (postData, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'checkIsHistoricalCompanyAndAddressExists');
    dispatch(commonActions.setLoader(true));
    axios.put(url, postData)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '9165')); // Default exception error
        });
}

const postValidateAddressAction = (postData, callback, dispatch) => {
    let url = getApiUrl('manageManualOrder', 'addressValidation');
    dispatch(commonActions.setLoader(true));
    axios.post(url, postData)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '9165')); // Default exception error
        });
}

const setJoinCommittee = (data, dispatch) => {
    dispatch({ type: types.SET_JOIN_COMMITTEE, joinCommitees: data });
}

const setStudentCommitteeListMasterAction = (data, dispatch) => {
    dispatch({ type: types.SET_STUDENT_COMMITTEE_LIST_MASTER, studentCommitteeListMaster: data });
}
const setShowLoader = (data, dispatch) => {
    dispatch(commonActions.setLoader(data));
}

const getCompanyAssociatedDetailsAction = (accountNumber, companyId = 0, callback, dispatch) => {
    let url = getApiUrl('manageManualOrder', 'getCompanyAssociatedDetails') + `${encrypt(accountNumber)}/${encrypt(companyId)}`;
    dispatch(commonActions.setLoader(true));

    callAPI(url, 'get', null, (response) => {
        dispatch(commonActions.setLoader(false));
        if (response.data && response.data.status) {
            callback(response.data.content);
        } else {
            // Handle error case
            callback([]);
        }
    })
}

export default {
    getMembershipTypeListAction,
    getPrimaryActivityListAction,
    getSubCommitteesListAction,
    getDemographicAndMemberPersonalDetailsAction,
    getMasterVolumeAction,
    getOrganizationListAction,
    getCompanyAddressListAction,
    postNewMemberAction,
    getCommitteeListAction,
    postValidateActiveRepAction,
    postValidateHistoricalOrgAction,
    postValidateAddressAction,
    setJoinCommittee,
    setStudentCommitteeListMasterAction,
    setShowLoader,
    getCompanyAssociatedDetailsAction
}
