import { RequestTypes } from '../../models/common.models';

export const handleConfirm = (self) => {
    const { currentRequestData, currentRequest } = self.props;

    if (currentRequestData && currentRequest) {

        // eslint-disable-next-line default-case
        switch (currentRequest) {
            case RequestTypes.UpdateTaskAssigneeStatus:
                currentRequestData.IsUpdateStatusDoneToOpen = true;
                self.props.updateTaskAssgineeStatus(currentRequestData);
                break;
            case RequestTypes.BreakDependency:
                currentRequestData.IsBreakDependency = true;
                self.props.updateTaskAssgineeStatus(currentRequestData);
                break;
            case RequestTypes.UpdateTaskDetails:
                currentRequestData.IsBreakDependency = true;

                self.props.updateTaskDetails(currentRequestData);
                break;
        }
    }
}
