import { addRedirectUrlToLogin, cacheManager, noCookiesRedirectToLogin } from 'helpers/util-common';
import React, { PureComponent } from "react";
import Breadcrumb from 'shared-components/Breadcrumb';
import ExportExcel from 'shared-components/ExportExcel';
import HeaderMenu from 'shared-components/Header';
import NavigationMenu from "shared-components/NavigationMenu";
import PageNotFound from 'shared-components/PageNotFound';
import UnAuthorizeAccess from 'shared-components/UnAuthorize';
import { isReactAppAuthorized } from '../helpers/util-common';
import * as _func from './function';

class Layout extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            previousPathName: '',
            parent: '',
            currentPage: '',
            parentPath: '',
            currentSearchOption: '',
            currentSearchText: '',
            shouldClearSearchText: false,
            isAuthorizedPage: false, // Used to check permission on page,
            NAVIGATION: []
        }

        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onAdvanceSearchSubmit = this.onAdvanceSearchSubmit.bind(this);
        this.validateCustomLoginRedirectUrl();
    }

    loadMasterApi = () => {
        if (isReactAppAuthorized()) {
            this.props.getCountryAndStateList();
        }
    }

    validateCustomLoginRedirectUrl = () => {
        const { isUserAuthorized, isLoginByAzureAD } = this.props;
        let redirectUrl = '/';
        // CONDITION WORKS WITHOUT LOGGED IN CONDITION
        if (!isUserAuthorized && !isLoginByAzureAD) { // VALIDATE THE TOEKN EXIST OR NOT
            if (this.props.location.pathname && this.props.location.pathname.length > 0) {
                redirectUrl = addRedirectUrlToLogin(this.props.location.pathname, isLoginByAzureAD);
            }
            this.props.history.push(redirectUrl);
        }
    }

    validateUrlHistory = () => {
        _func.validateBreadcrumbsUrls(this);
        this.unlisten = this.props.history.listen((location, action) => {
            if (location.pathname !== this.state.previousPathName) {
                // Update Breadcrumb on Change Url
                _func.validateBreadcrumbsUrls(this);
                // Hanlde on Navigation to Redirect to login if Other application has been logout.
                noCookiesRedirectToLogin();
                // TODO : Disabled autofill safari message on routes changed.
                this.props.setSafariAutoFillInfo(false);
            }
            _func.updateSearchText(this, location);
        }, this);
    }


    componentDidMount() {
        this.validateUrlHistory();
        this.loadMasterApi();
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    onSearchSubmit(currentSearchOption, searchText) {
        _func.handleSearchSubmit(this, currentSearchOption, searchText);
    }

    onAdvanceSearchSubmit(currentSearchOption, query) {
        _func.handleAdvanceSearchSubmit(this, currentSearchOption, query);
    }

    render() {
        const { parentPath, parent, currentPage, currentSearchText, shouldClearSearchText, isAuthorizedPage, currentSearchOption, NAVIGATION } = this.state;
        const { isUserAuthorized, showNotFoundPage } = this.props;
        const userDetail = cacheManager.getItem('userDetail') && JSON.parse(cacheManager.getItem('userDetail'));

        return (
            isUserAuthorized ?
                <div className="homePage">
                    <HeaderMenu {...this.props} userDetail={userDetail} currentSearchOption={currentSearchOption} shouldClearSearchText={shouldClearSearchText}
                        currentSearchText={currentSearchText} currentPage={currentPage}
                        onSearchSubmit={this.onSearchSubmit}
                        onAdvanceSearchSubmit={this.onAdvanceSearchSubmit} ></HeaderMenu>
                    <NavigationMenu NAVIGATION={NAVIGATION} {...this.props}></NavigationMenu>
                    <div className="ui container scrollData">
                        <Breadcrumb parentPath={parentPath} parent={parent} currentPage={currentPage} />
                        {showNotFoundPage ? <PageNotFound /> : (
                            <section className="mainWrapperRight">
                                {isAuthorizedPage ? this.props.children : (<UnAuthorizeAccess />)}
                            </section>
                        )}
                    </div>
                    {/* To load the chunk before the use */}
                    <ExportExcel />
                </div>
                : ""
        );
    }
}

export default Layout;
