import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal } from 'semantic-ui-react';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea'
import { ReasonForUpdateMaxLength } from '../../../../models/common.models';
import * as _func from './function';

class ReactivateCommitteeModal extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            reactivateSelectedValue: '0'
        }
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { reactivateSelectedValue } = this.state;
        const { handleSubmit, closePopUp } = this.props;

        return (
            <Modal open='true' className="tiny" data-testid="reactivateCommitteeModal">
                <Modal.Header>Reactivate Committee <i class="close" onClick={()=>closePopUp('reActivateCommittee')}>&#x2716;</i></Modal.Header>

                <Form size='large' onSubmit={handleSubmit}>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className={'radioWrap'} >
                                            <Field name={'reactivate'}
                                                component={RenderRadioField}
                                                className={'mr10'}
                                                type={'radio'}
                                                selectedValue={reactivateSelectedValue}
                                                onChange={_func.handleChange}
                                                options={[{ label: 'Reactivate Committee with no members', value: '1' }, { label: 'Reactivate Committee with members', value: '2' }]}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Field component={TextArea} type='text' label='Reason for update'
                                            name='reasonForUpdate' required={true} maxLength={ ReasonForUpdateMaxLength }
                                            placeholder="Please enter reason for update" 
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>

                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" type="submit">Reactivate</button>
                            <button className="ui button cancel" onClick={()=>closePopUp('reActivateCommittee')}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'ReactivateCommitteeModal',
    validate: _func.validateHandler,
    onSubmit: _func.reActivateCommittee
})(ReactivateCommitteeModal);
