import * as types from 'action-types';
import { formatDateToMMDDYYYY, formatDateToMMDDYYYYHHMMSS } from 'helpers/util-common';
import { CompletionIntervalList } from './work-item-admin.models';

const initialState = {
   initialValues: {},
   standardTypeList: [],
   subCommitteeList: [],
   technicalContactList: [],
   restoreTechnicalContactList: [],
   isCollabAPIFailedPopup: false,
   collabAPIFailedMessage: null
}

const getIncrementedDate = (createdDate, monthsToAdd) => {
   createdDate = new Date(createdDate);
   createdDate.setMonth(createdDate.getMonth() + monthsToAdd);

   return formatDateToMMDDYYYY(createdDate);
}

const getCompletionDates = (createdDate, targetInterval) => {
   let startDate = '';
   let endDate = '';

   if (targetInterval === CompletionIntervalList[0].Id) {
      startDate = getIncrementedDate(createdDate, 3);
      endDate = getIncrementedDate(createdDate, 6);
   } else if (targetInterval === CompletionIntervalList[1].Id) {
      startDate = getIncrementedDate(createdDate, 6);
      endDate = getIncrementedDate(createdDate, 12);
   } else if (targetInterval === CompletionIntervalList[2].Id) {
      startDate = getIncrementedDate(createdDate, 12);
      endDate = getIncrementedDate(createdDate, 18);
   } else if (targetInterval === CompletionIntervalList[3].Id) {
      startDate = getIncrementedDate(createdDate, 18);
      endDate = getIncrementedDate(createdDate, 24);
   }

   return { startDate, endDate };
}
const formatMemberList = (list) => {
   let result = [];

   result.push({ key: 0, value: 0, text: 'Select', accountNumber: '' });

   if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
         let text = `${list[i].LastName}, ${list[i].FirstName} (${list[i].AccountNumber})`;
         result.push(
            {
               key: list[i].MemberId, value: list[i].MemberId, text, accountNumber: list[i].AccountNumber,
               name: `${list[i].FirstName} ${list[i].LastName}`, email: list[i].Email
            });
      }
   }

   return result;
}
const formatInitialValues = (initialValues) => {
   const completionDateRange = getCompletionDates(initialValues.CreatedDate, initialValues.TargetCompletionInterval);

   initialValues.WorkItemCreationDate = initialValues.CreatedDate;
   initialValues.Type = parseInt(initialValues.WorkItemTypeId);
   initialValues.Copyright = initialValues.CopyrightCode === 1 ? 'Yes, authorized to submit original material' : 'Yes, authorized to release copyright material';
   initialValues.AuthorizationDate = initialValues.AuthorizationDate ? formatDateToMMDDYYYY(initialValues.AuthorizationDate) : '';
   initialValues.TargetBallotDate = initialValues.TargetBallotDate ? formatDateToMMDDYYYY(initialValues.TargetBallotDate) : '';
   initialValues.CreatedDate = formatDateToMMDDYYYYHHMMSS(initialValues.CreatedDate);
   initialValues.ModifiedDate = formatDateToMMDDYYYYHHMMSS(initialValues.ModifiedDate);
   initialValues.CompletionDateFrom = completionDateRange.startDate;
   initialValues.CompletionDateTo = completionDateRange.endDate;
   initialValues.SuppressedDate = initialValues.IsSuppressed ? formatDateToMMDDYYYY(initialValues.SuppressedDate) : formatDateToMMDDYYYY(new Date());
   initialValues.DeletedDate = initialValues.IsDeleted ? formatDateToMMDDYYYY(initialValues.DeletedDate) : formatDateToMMDDYYYY(new Date());
   initialValues.ballotAssociatedStatus = initialValues.BallotAssociatedStatus;
   initialValues.OldSubCommitteeName = initialValues.SubCommitteeName;
   initialValues.NewSubCommitteeName = initialValues.SubCommitteeName;
   initialValues.OldMainCommitteeName = initialValues.MainCommitteeName;
   initialValues.NewMainCommitteeName = initialValues.MainCommitteeName;
   initialValues.OldStandardTypeName = initialValues.StandardTypeName;
   initialValues.IsOnlineCollaboration = (initialValues.CollaborationId > 0 && !initialValues.CollaborationIsDelete) ? true : initialValues.IsOnlineCollaboration
   initialValues.OldMainCommitteeId = initialValues.MainCommitteeId;
   initialValues.OldSubCommitteeId = initialValues.SubCommitteeId;
   initialValues.CopyrightAcceptanceDateTime = initialValues.CopyrightAcceptanceDateTime ? formatDateToMMDDYYYYHHMMSS(initialValues.CopyrightAcceptanceDateTime) : null;

   return initialValues;
}

const formatStandardTypeList = (list) => {
   let result = [];

   result.push({ key: 0, value: 0, text: 'Select One' });

   if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
         result.push({ key: list[i].StandardTypeId, value: list[i].StandardTypeId, text: list[i].StandardTypeName });
      }
   }

   return result;
}

const formatSubCommitteeList = (list) => {
   let result = [];
   result.push({ mainCommitteeId: 0, mainCommitteeName: '', key: 0, value: 0, text: 'Select', status: true });
   if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
         result.push({ mainCommitteeId: list[i].ParentCommitteeId, mainCommitteeName: list[i].ParentCommitteeName, key: list[i].SubCommitteeId, value: list[i].SubCommitteeId, text: list[i].CommitteeDesignation, status: list[i].Status });
      }
   }

   return result;
}

const workItemDetailsReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_WORK_ITEM_DETAILS:
         return {
            ...state,
            initialValues: formatInitialValues(action.data),
            technicalContactList: formatMemberList(action.data.MemberList),
            restoreTechnicalContactList: formatMemberList(action.data.MemberList),
            subCommitteeList: formatSubCommitteeList(action.data.SubSectionCommitteeList)
         }

      case types.SET_STANDARD_TYPE_LIST:
         return {
            ...state,
            standardTypeList: formatStandardTypeList(action.standardTypeList)
         }

      case types.RESET_INITIAL_VALUES:
         return {
            ...state,
            initialValues: {}
         }
      case types.SET_MEMBER_LIST:
         return {
            ...state,
            technicalContactList: formatMemberList(action.memberList)
         }
      case types.SET_MEMBER_LIST_RESTORE:
         return {
            ...state,
            restoreTechnicalContactList: formatMemberList(action.memberListRestore)
         }
      case types.SET_COLLAB_API_FAILED:
         return {
            ...state,
            isCollabAPIFailedPopup: !state.isCollabAPIFailedPopup,
            collabAPIFailedMessage: action.collabAPIFailedMessage
         }
      default:
         return state;
   }
}

export default workItemDetailsReducer;
