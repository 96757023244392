import React from "react";
import ExportExcel from "shared-components/ExportExcel";
import moment from 'moment';
import { formatDateToMMDDYYYY } from 'helpers/util-common';

const MemberCommitteeInfoExcelExport = ({ data, AccountNo }) => {

    const fileName = AccountNo + '- Committee Records_' + moment(new Date()).format('MM-DD-YYYY');;
    const tabName = 'Member Committee Information';

    const columns = [
        { title: 'Committee Designation', style: { font: { bold: true } } },
        { title: 'Committee Title', style: { font: { bold: true } } },
        { title: 'Classification', style: { font: { bold: true } } },
        { title: 'Classification Assigned Date', style: { font: { bold: true } } },
        { title: 'Vote', style: { font: { bold: true } } },
        { title: 'No Vote Reason', style: { font: { bold: true } } },
        { title: 'Vote Assigned Date', style: { font: { bold: true } } },
        { title: 'Committee Join Date', style: { font: { bold: true } } },
        { title: 'Status', style: { font: { bold: true } } },
        { title: 'Officer Title', style: { font: { bold: true } } },
        { title: 'Officer Appointed Date', style: { font: { bold: true } } }
    ];

    let rows = [];
    for (let i = 0; i < data.length; i++) {

        let row = [
            { value: data[i].CommitteeDesignation },
            { value: data[i].CommitteeTitle },
            { value: data[i].Classification },
            { value: data[i].ClassificationDate },
            { value: data[i].Vote },
            { value: data[i].NoVoteReason },
            { value: data[i].VoteDate },
            { value: data[i].CommitteeJoinDate },
            { value: data[i].MemberStatus },
            { value: data[i].OfficerTitle },
            { value: data[i].OfficerAppointedDate }
        ];

        rows.push(row);
    }

    // Need atleast an empty row.
    if (data.length === 0) {
        let row = [
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' }
        ];

        rows.push(row);
    }

    const multiDataSet = [{ columns: columns, data: rows }];

    return (
        <ExportExcel fileName={fileName} tabName={tabName} multiDataSet={multiDataSet} />
    );
}


export default MemberCommitteeInfoExcelExport;
