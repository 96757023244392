import { getMessage, handleAutoComplete } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Icon } from 'semantic-ui-react';
import CustomAlertModal from 'shared-components/CustomAlertModal';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import { MembershipTypes } from '../../members.model';
import AffiliateMemberModal from '../affiliate-member-modal';
import CustomRendererSearch from '../custom-renderer-search';
import StatusConfirmationModal from '../status-confirmation-modal';
import * as _func from './function';

class MembershipTypeEditInfo extends Component {

    state = {
        membershipType: this.props.initialValues.MemberTypeId,
        membershipTypeList: [],
        showActiveRepWithActiveOrgConfirmation: false,
        descMessage: '',
        historicalRadioLabel: '',
        activeRadioLabel: '',
        showNoResultsMessage: false,
        isLoading: false,
        organizationList: [],
        showAffiliateMemberModal: false,
        committeeList: [],
        showAlertPopup: false
    }

    componentWillMount() {
        _func.setClassInstance(this);
        this.setState({ membershipTypeList: _func.filterMembershipTypes() });

        const { initialValues } = this.props;
        if (initialValues.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            this.props.change('RepOrgName', initialValues.OrgName);
        }
    }
    componentDidMount() {
        handleAutoComplete()
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { submitting, handleSubmit, initialValues, mouContactCodeList, onCloseEditMembershipTypeInfo, messageCodes } = this.props;
        const { membershipType, membershipTypeList, showActiveRepWithActiveOrgConfirmation, descMessage, historicalRadioLabel, activeRadioLabel, showNoResultsMessage, isLoading, organizationList, showAffiliateMemberModal, committeeList, showAlertPopup } = this.state;
        return (
            <div data-testid="membershipTypeEditInfo">
                <Form noValidate onSubmit={handleSubmit(_func.onSubmitHandler)}>

                    <h5>Membership Type
                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button title="Cancel" className="closeBtn" onClick={onCloseEditMembershipTypeInfo}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>

                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column style={{ width: '25%' }}>
                                <Field name='MemberTypeId' label='Member Type'
                                    component={RenderSelect} value={membershipType}
                                    onChange={_func.onUpdateMemberType}
                                    options={membershipTypeList}
                                />
                            </Grid.Column>
                            {membershipType === MembershipTypes.MouMember && (
                                <Grid.Column>
                                    <Field name='MOUContactCodeId' label='MOU Contact Code'
                                        component={RenderSelect} required={true}
                                        options={mouContactCodeList}
                                    />
                                </Grid.Column>
                            )}
                            {(membershipType === MembershipTypes.Representative || membershipType === MembershipTypes.cooperativeAgreementRep) && (
                                <Grid.Column>
                                    <Field name="RepOrgName" type="text"
                                        component={CustomRendererSearch}
                                        placeholder="Search by Account Number, Organization Name"
                                        label="Organization"
                                        maxLength="100"
                                        showNoResults={showNoResultsMessage}
                                        onChange={(e, val) => _func.onOrganizationChange(val)}
                                        source={organizationList}
                                        isLoading={isLoading}
                                        isOrganizationSearch={true}
                                        onResultSelect={_func.onOrganizationSelect}
                                        disabled={((initialValues.MemberTypeId === MembershipTypes.Representative && membershipType === MembershipTypes.cooperativeAgreementRep) || (initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep && membershipType === MembershipTypes.Representative))}
                                        className="autocompleteOff"
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                {showActiveRepWithActiveOrgConfirmation && (
                    <StatusConfirmationModal descMessage={descMessage}
                        historicalRadioLabel={historicalRadioLabel} activeRadioLabel={activeRadioLabel}
                        messageCodes={messageCodes} closeModal={_func.onCloseConfirmationModal} />
                )}

                {showAffiliateMemberModal && (
                    <AffiliateMemberModal messageCodes={messageCodes} committeeList={committeeList}
                        closeModal={_func.onCloseAffiliateMemberModal} onSubmit={_func.onSubmitAffiliateMemberModal} />
                )}

                {showAlertPopup &&
                    <CustomAlertModal content={getMessage(messageCodes, '9161.text')} handleClick={_func.handleClickOk} />
                }
            </div>
        )
    }
}

export default reduxForm({
    form: 'MembershipTypeEditInfoForm',
    validate: _func.validateHandler
})(MembershipTypeEditInfo);
