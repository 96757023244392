import moment from 'moment';
import React from 'react';
import { Link } from "react-router-dom";
import { Modal } from 'semantic-ui-react';
import { routeNavigationUrl } from '../../../../../helpers/util-common';

const OrganizationRepHistoryModal = ({ closeRepHistoryModalEvent, list, PAGE_TYPE, USER_PRIVILEGE }) => (

    <Modal open='true' >
        <Modal.Header>Representative(s) History <i data-testid="btnClodeModal" onClick={(e) => closeRepHistoryModalEvent(e)} className="close">&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <div className="tableWrapper">
                    <table className="customTable fixHeadertable">
                        <thead>
                            <tr>
                                <th width="15%">Account</th>
                                <th width="25%">Name</th>
                                <th width="34%">Email</th>
                                <th width="13%">Association Start Date</th>
                                <th width="13%">Association End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list && list.length > 0 &&
                                list.map((result) => {
                                    return (
                                        <tr>
                                            <td width="15%"><Link to={routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [result.AccountNumber])} >{result.AccountNumber}</Link></td>
                                            <td width="25%">{result.Name}</td>
                                            <td width="34%">{result.Email}</td>
                                            <td width="13%">{result.AssociationStartDate !== null ? moment(result.AssociationStartDate).format("MM/DD/YYYY") : ''}</td>
                                            <td width="13%">{result.AssociationEndDate !== null ? moment(result.AssociationEndDate).format("MM/DD/YYYY") : ''}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </div>

            </Modal.Description>
        </Modal.Content>

    </Modal>
)

export default OrganizationRepHistoryModal;
