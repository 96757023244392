import { formatDateToMMDDYYYY, formatDateToMMYYYY, routeNavigationUrl } from 'helpers/util-common';
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';

const MemberCommitteeOfficerTitleHistoryModal = ({ onCloseMemberCommitteeOfficerTitleHistoryModal, memberCommitteeOfficerTitleHistoryList, hasUserPageAccess, PAGE_TYPE, USER_PRIVILEGE }) => (


    <Modal open='true'>
        <Modal.Header>Officer History Details <i className="close" data-testid="close" onClick={onCloseMemberCommitteeOfficerTitleHistoryModal}>&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <div className="tableWrapper">
                    <table className="customTable memComHistoryTable">
                        <thead>
                            <tr>
                                <th width="20%">Officer Title</th>
                                <th width="25%">Officer Appointed Date</th>
                                <th width="20%">Officer End Date</th>
                                <th width="20%">Updated Date</th>
                                <th width="15%">Updated By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberCommitteeOfficerTitleHistoryList && memberCommitteeOfficerTitleHistoryList.length > 0 &&
                                memberCommitteeOfficerTitleHistoryList.map((item) => {
                                    return (<tr>
                                        <td width="20%">{item.OfficerTitleName}</td>
                                        <td width="25%">{formatDateToMMYYYY(item.OfficerAppointedDate)}</td>
                                        <td width="20%">{item.OfficerEndDate ? formatDateToMMYYYY(item.OfficerEndDate) : item.OfficerEndDate}</td>
                                        <td width="20%">{formatDateToMMDDYYYY(item.UpdatedDate)}</td>
                                        <td width="15%">
                                            {hasUserPageAccess && Number(item.UpdatedBy) > 0 ? (<Link target="_blank" to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW, [item.UpdatedBy])}`}>{item.UpdatedUserName}</Link>) : <span>{item.UpdatedUserName}</span>}
                                        </td>
                                    </tr>);
                                })}
                        </tbody>
                    </table>
                </div>
            </Modal.Description>
        </Modal.Content>
    </Modal>
)

export default MemberCommitteeOfficerTitleHistoryModal;
