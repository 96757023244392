import { cacheManager, getMessage } from 'helpers/util-common';
import React, { Component } from "react";
import { Button, Checkbox, Confirm, Dropdown, Form, Icon, Loader, Radio } from 'semantic-ui-react';
import RenderSelect from 'shared-components/Select';
import { CommitteeOptions, MemberCommitteeOptions, MemberCommitteeTableColumn } from '../../members.model';
import AddMemberCommitteeModal from '../add-member-committee';
import MemberCommitteeDetails from '../member-committee-details';
import MemberCommitteeInfoTable from '../member-committee-info-table';
import MemberCommitteeInfoExcelExport from '../member-committee-information-export-excel/Index';
import MemberCommitteeSummaryModal from '../member-committee-summary';
import * as _func from './function';

class MemberCommitteeInfo extends Component {

    state = {
        memberCommitteeInfo: [],
        initMemberCommitteeInfo: [],
        committeeOptions: [],
        selectedCommittees: [],
        selectedMemberCommitteeStatus: -1,
        selectedCommitteeStatus: -1,
        showLeftPannel: false,
        activeCommitteeId: 0,
        sortKey: 'CommitteeDesignation',
        orderType: 1,
        showAddcommitteeMember: false,
        isExportExcel: false,
        isOpenExcelDownloadConfirmBox: false,
        exportedData: [],
        enableUpdateComittees: false,
        updateCommitteesModel: [],
        showProceed: false,
        isGridEdited: false,
        showSelectedCommitteeFilter: false,
        selectClassification: {},
        selectedVote: {},
        selectNoReason: {},
        memberCommitteeTableColumnInfo: MemberCommitteeTableColumn,
        selectReactivate: {},
        showCommiteeSummary: false,
        memberSummaryModel: {},
        MemberId: 0,
        loading: false
    };

    componentWillMount = () => {
        _func.setClassInstance(this);
        _func.getInitialData();
        if (cacheManager.getItem('memberCommitteeInfo') && cacheManager.getItem('memberCommitteeInfo') > 0) {
            let activeCommitteeId = cacheManager.getItem('memberCommitteeInfo')
            let memberId = JSON.parse(cacheManager.getItem('memberId'))
            _func.showHideDetailPage(activeCommitteeId, memberId)
            cacheManager.removeItem('memberCommitteeInfo')
            cacheManager.removeItem('memberId')
        }
    }

    componentWillReceiveProps = () => {
        _func.setDefaultSortingIcon();
    }

    handleCommitteeChange = (event, data) => {
        this.setState({ selectedCommittees: data.value }, () => this.filterMemberCommitteeList());
    }

    memberCommitteeStatusHandler = (e, key) => {
        this.setState({ selectedMemberCommitteeStatus: key }, () => this.filterMemberCommitteeList());
    }

    committeeStatusHandler = (e, key) => {
        this.setState({ selectedCommitteeStatus: key }, () => this.filterMemberCommitteeList());
    }

    filterMemberCommitteeList = () => {
        _func.filterMemberCommitteeList(this.state.initMemberCommitteeInfo);
    }

    handleResetClick = () => {
        _func.resetData();
    }

    handleSearchClick = () => {
        this.filterMemberCommitteeList();
    }

    handleExportExcel = () => {
        _func.excelExport();
    }

    emptyExportDataCancelHandler = () => {
        _func.emptyExportDataCancelHandler();
    }

    emptyExportDataConfirmHandler = () => {
        _func.emptyExportDataConfirmHandler();
    }
    hideChildComponent = (e) => {
        e.stopPropagation();
        this.setState({ enableUpdateComittees: false })
    }

    render() {
        const { committeeOptions, exportedData, isExportExcel, isOpenExcelDownloadConfirmBox,
            selectedCommittees, selectedMemberCommitteeStatus, loading,
            selectedCommitteeStatus, memberCommitteeInfo, showLeftPannel, activeCommitteeId,
            showAddcommitteeMember, enableUpdateComittees, updateCommitteesModel, showProceed,
            isGridEdited, showSelectedCommitteeFilter, selectClassification, selectedVote,
            memberCommitteeTableColumnInfo, selectReactivate, showCommiteeSummary,
            memberSummaryModel, MemberId, selectNoReason } = this.state;

        const { messageCodes, initialValues, hasExportAccess, hasAddMemberToCommitteeAccess, hasEditMemberParticipationOnCommittees, userAccess } = this.props;

        return (

            <div className="memberCommInfo" onClick={this.hideChildComponent} data-testid="memberCommitteeInfo">
                <section className="tableDataLegends">
                    <div className="legends">
                        <span className="red-border mr10"></span><span>Both Member &amp; Committee Inactive</span>
                    </div>
                    <div className="legends">
                        <Icon name="star" /><span>Active Officer</span>
                    </div>
                    <div className="legends">
                        <Icon name="user" /><span>Active in Committee</span>
                    </div>
                    <div className="legends">
                        <Icon name="user" className="redIcon" /><span>Inactive in Committee</span>
                    </div>

                </section>
                <Form noValidate >
                    <div className='auditFilter mb20' >

                        <div className="colWrap mr10">
                            <div className="multiField">
                                <label className="label" htmlFor="Committee">Committee</label>
                                <Dropdown name="Committee" id="Committee" placeholder='All' value={selectedCommittees}
                                    onChange={this.handleCommitteeChange} multiple selection options={committeeOptions} disabled={isGridEdited || showSelectedCommitteeFilter} />
                            </div>
                        </div>

                        <div className="colWrap mr10">
                            <label className="label" htmlFor="MemberCommitteeStatus">Member Committee Status</label>
                            <RenderSelect name="MemberCommitteeStatus" id="MemberCommitteeStatus" placeholder="All"
                                value={selectedMemberCommitteeStatus} onChange={this.memberCommitteeStatusHandler} options={MemberCommitteeOptions} disabled={isGridEdited || showSelectedCommitteeFilter} />
                        </div>
                        <div className="colWrap">
                            <label className="label" htmlFor="CommitteeStatus">Committee Status</label>
                            <RenderSelect name="CommitteeStatus" id="CommitteeStatus" placeholder="All"
                                value={selectedCommitteeStatus} onChange={this.committeeStatusHandler} options={CommitteeOptions} disabled={isGridEdited || showSelectedCommitteeFilter} />
                        </div>


                        {/* <div className="auditAction">
                            <Button className="cancel" type="reset" onClick={this.handleResetClick} disabled={isGridEdited || showSelectedCommitteeFilter}>Reset</Button>
                            <Button className="primary ml5" type="submit" onClick={this.handleSearchClick} disabled={showSelectedCommitteeFilter}>Search</Button>
                        </div> */}
                    </div>
                    <div className="mciActions">
                        {(hasAddMemberToCommitteeAccess && initialValues.StatusName && initialValues.StatusName.toLowerCase() === 'active') && <Button secondary onClick={_func.OpenAddCommitteeMember}><Icon name="plus" />Add Member to a Committee</Button>}
                        {!showSelectedCommitteeFilter && <span className="buttonDropdown mr10 ml10">


                            {(memberCommitteeInfo && memberCommitteeInfo.length > 0 && hasEditMemberParticipationOnCommittees && initialValues.StatusName && initialValues.StatusName.toLowerCase() === 'active') && <Button secondary className="pr0" onClick={_func.showHideUpdateCommittees}>Update Committee(s) <Icon name="caret down" className="ml10" /></Button>}
                            {enableUpdateComittees && <div className="multipleCommitteesActions" onClick={(e) => e.stopPropagation()}>
                                <ul>
                                    {updateCommitteesModel && updateCommitteesModel.length > 0 &&
                                        updateCommitteesModel.map((committeeItem, index) =>
                                            <li className={committeeItem.status ? 'active' : ''}>
                                                {committeeItem.isEnable && <Checkbox label={committeeItem.title} checked={committeeItem.status} onClick={(e) => _func.onChangeCommitteesModel(e, committeeItem)} />}
                                                {!committeeItem.isEnable && <Checkbox label={committeeItem.title} disabled />}

                                                {[1, 2].includes(committeeItem.id) && updateCommitteesModel.filter(validItem => validItem.id === 3 && validItem.status).length > 0 && <i aria-hidden="true" className="asterisk  icon"></i>}

                                                {/* {(committeeItem.id === 1 && committeeItem.status) && */}
                                                {([1, 4].includes(committeeItem.id) && committeeItem.status) &&
                                                    <ul><li><div className="fullRadio">
                                                        {(committeeItem.radioArray && committeeItem.radioArray.length > 0) && committeeItem.radioArray.map((radioItem, radioIndex) => {
                                                            return <div className="radioWrap">
                                                                <Radio label={radioItem.label}
                                                                    name='ClassificationList'
                                                                    value={radioItem.value}
                                                                    checked={radioItem.value}
                                                                    onChange={(e) => _func.changeVoteStatus(e, index, radioIndex, 1)}
                                                                />
                                                            </div>
                                                        })}

                                                    </div>
                                                    </li></ul>
                                                }
                                                {(committeeItem.id === 2 && committeeItem.status) &&
                                                    <ul><li>
                                                        <div className="fullRadio">
                                                            {(committeeItem.radioArray && committeeItem.radioArray.length > 0) && committeeItem.radioArray.map((radioItem, radioIndex) => {
                                                                return <div className="radioWrap">
                                                                    <Radio label={radioItem.label}
                                                                        name='UpdateVote'
                                                                        value={radioItem.value}
                                                                        checked={radioItem.value}
                                                                        onChange={(e) => _func.changeVoteStatus(e, index, radioIndex, 1)}
                                                                    />
                                                                    {(radioItem.label === 'No' && radioItem.value &&
                                                                        (committeeItem.secondRadioArray && committeeItem.secondRadioArray.length > 0))
                                                                        &&
                                                                        <ul>
                                                                            {committeeItem.secondRadioArray.map((voteItem, voteIndex) => {
                                                                                return <li><Radio label={voteItem.label}
                                                                                    name='VoteList'
                                                                                    value={voteItem.value}
                                                                                    checked={voteItem.value}
                                                                                    onChange={(e) => _func.changeVoteStatus(e, index, voteIndex, 2)}
                                                                                /></li>
                                                                            })}
                                                                        </ul>
                                                                    }
                                                                </div>
                                                            })}

                                                        </div></li></ul>}
                                            </li>
                                        )
                                    }
                                </ul>
                                {showProceed && <div className="filterBtn ">
                                    <a className="resetBtn mr10" onClick={_func.resetCommitteesFilter}><Icon name="redo" /></a>
                                    <a className="searchBtn" onClick={_func.proceedCommitteeFilter}><Icon name="check" /></a>
                                </div>}
                            </div>

                            }
                        </span>
                        }
                        {showSelectedCommitteeFilter && <React.Fragment >
                            {selectReactivate.title && < span className="mciPills"> {selectReactivate.title}</span>}
                            {selectClassification.title && < span className="mciPills">Classification: {selectClassification.title}</span>}
                            {selectedVote.title && <span className="mciPills">Vote: {selectedVote.title}</span>}
                            {selectNoReason.title && < span className="mciPills"> Inactivate Member</span>}
                            {selectNoReason.title && <span className="mciPills">Inactive Reason: {selectNoReason.title}</span>}
                            <a className="resetBtn vMiddle ml10" href="#" onClick={_func.resetCommitteesFilter}><Icon name="redo" /></a>
                            <a className="editBtn vMiddle ml10" href="#" onClick={_func.resetSelectedCommitteesFilter}><Icon name="pencil" /></a>
                            {(memberCommitteeInfo.filter(item => item.isChecked == undefined || item.isChecked).length > 0)
                                && <a className="updateBtn vMiddle ml10" href="#" onClick={(e) => _func.showHideMemberCommitteeSummary(e, 0)}><Icon name="check" /></a>}
                        </React.Fragment>
                        }
                        {!showSelectedCommitteeFilter && hasExportAccess && <div className="display-inline-block floatRight">
                            <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                            <Button secondary onClick={this.handleExportExcel}><Icon name="file excel" className="mr5" />Excel</Button>
                        </div>}

                    </div>
                </Form>

                <div className="tableWrapper">
                    <MemberCommitteeInfoTable callOnUpdate={_func.getInitialData} isBulkEdit={showSelectedCommitteeFilter}{...this.props} isGridEdit={_func.isGridEdit} memberCommitteeInfo={memberCommitteeInfo} memberCommitteeTableColumnInfo={memberCommitteeTableColumnInfo} showAddcommitteeMember={showAddcommitteeMember} OnSelectMemerCommittee={_func.OnSelectMemerCommittee} selectAll={_func.selectAll} hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees} />
                </div>
                <div className={showLeftPannel ? 'fullPagePopup active' : 'fullPagePopup'}>
                    {showLeftPannel && <MemberCommitteeDetails {...this.props} memberCommitteeInfo={memberCommitteeInfo} showLeftPannel={showLeftPannel} activeCommitteeId={activeCommitteeId} showHide={_func.showHideDetailPage} MemberTypeId={initialValues.MemberTypeId} MemberId={MemberId} hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees} userAccess={userAccess} AccountNumber={initialValues.AccountNumber}></MemberCommitteeDetails>}
                </div>
                {showAddcommitteeMember && <AddMemberCommitteeModal {...this.props} close={_func.OpenAddCommitteeMember} isGridEdit={_func.isGridEdit} refreshList={_func.resetData}></AddMemberCommitteeModal>}

                {
                    isExportExcel &&
                    <MemberCommitteeInfoExcelExport data={exportedData} AccountNo={initialValues.AccountNumber}></MemberCommitteeInfoExcelExport>
                }

                {showCommiteeSummary && <MemberCommitteeSummaryModal {...this.props} memberSummaryModel={memberSummaryModel} onClose={_func.showHideMemberCommitteeSummary} />}
                <Confirm className="confirm-box"
                    open={isOpenExcelDownloadConfirmBox}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={this.emptyExportDataCancelHandler}
                    onConfirm={this.emptyExportDataConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                {loading && <Loader size='small' className="small-loader">Loading</Loader>}
            </div >
        );
    }
}
export default MemberCommitteeInfo
