import React, { Component } from 'react';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import TextArea from 'shared-components/TextArea';
import { getMessage } from 'helpers/util-common';


const validateHandler = (values, props) => {
    const errors = {};
    if (!values.UpdateReason || values.UpdateReason.trim().length === 0) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }
    return errors
}
class UpdateReasonModal extends Component {


    render() {
        const { onClose, handleSubmit, submitting, onSubmitCall } = this.props

        return (
            <Modal open='true' className="tiny">
                <Form onSubmit={handleSubmit(onSubmitCall)} noValidate>
                    <Modal.Header>Update Reason<i onClick={(e) => onClose()} className="close" title="close">&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>

                        <Modal.Description>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field component={TextArea} type='text' label='Reason for update'
                                            name='UpdateReason' required={true} maxLength='200'
                                            placeholder="Please enter reason for update"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <Button primary type="submit" disabled={submitting} >Update</Button>
                            <Button type="button" secondary onClick={(e) => onClose()}>Cancel</Button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal >
        )
    }
}
export default reduxForm({
    form: 'UpdateReasonModal',
    validate: validateHandler
})(UpdateReasonModal);
