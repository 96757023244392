import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import commonActions from "common.actions";
import Parser from 'html-react-parser';

const CustomInfoAlertModal = ({ headerMessage, content, showHeader = false, buttonText = "Ok", handleDismiss, handleClick, closeModal }) => (
    <Modal open='true' className="tiny errormodalbox" data-testid="CustomInfoAlertModal">
        <Modal.Header style={showHeader && headerMessage ? {} : { backgroundColor: "#f1f1f1" }}>
            {headerMessage ? headerMessage : ""}
            {closeModal && (<i onClick={(e) => closeModal(0)} className="close" style={showHeader && headerMessage ? {} : { color: "#686c6d" }} title="close">&#x2716;</i>)}
        </Modal.Header>
        <Modal.Content>
            <div>{Parser(content)}</div>
        </Modal.Content>
        <Modal.Actions>
            <div class="ui container center aligned">
                <Button class="ui segment" secondary onClick={handleClick ? handleClick : handleDismiss}>{buttonText}</Button>
            </div>
        </Modal.Actions>
    </Modal>
)

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
    handleDismiss: () => {
        commonActions.hideAlertPopup(dispatch);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomInfoAlertModal);