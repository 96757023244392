import * as utilCommon from 'helpers/util-common';
import React, { Component } from "react";
import { Image } from 'semantic-ui-react';
import logoImg from "../../assets/images/astm-logo-name-blue-rgb.svg";
import { PAGE_CONFIG } from '../../models/navigation.models';
import LoginForm from './login-form';
class Login extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoginPageShow: true
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		const { PAGE_TYPE, USER_PRIVILEGE } = PAGE_CONFIG;
		if (utilCommon.isReactAppAuthorized()) { // VALIDATE THE TOEKN EXIST OR NOT
			this.setState({
				isLoginPageShow: false
			})
			this.props.history.push(utilCommon.routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW));
		}
	}

	handleSubmit = values => {
		const { PAGE_TYPE, USER_PRIVILEGE } = PAGE_CONFIG;
		if (values && values.username && values.username.length > 0 &&
			values.password && values.password.length > 0) {
			this.props.login(values.username, values.password, () => {
				let redirectUrl = utilCommon.routeNavigationUrl(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)
				if (this.props.location.search && this.props.location.search.length > 0) {
					redirectUrl = utilCommon.getRedirectUrlAfterLogin(this.props.location.search);
				}
				this.props.history.push(redirectUrl);
			});
		}
	};

	render() {
		const { isLoginPageShow } = this.state;
		return (

			isLoginPageShow &&
			<div className="loginPage">
				{
					<div className="loginWrap">
						<figure className="logo">
							<Image src={logoImg} />
						</figure>
						<div className="loginCard">
							<p className="titleHead">Log In</p>

							<LoginForm onSubmit={this.handleSubmit} />
						</div>
					</div>
				}
			</div>
		);
	}
}

export default Login;
