import { deepCopy, encrypt, getMessage, routeNavigationUrl } from 'helpers/util-common';
import { McsStatusMasterId } from 'models/common.models';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Confirm, Icon, Popup } from 'semantic-ui-react';
import CustomAlertModal from 'shared-components/CustomAlertModal';
import CopyMembersModal from '../copy-members-modal';
import EditCommitteeEnableOnWeb from '../edit-committee-enable-on-web';
import EditCommitteeGenInfo from '../edit-committee-gen-info';
import EditCommitteeSettings from '../edit-committee-settings';
import InactivateCommitteeModal from '../inactivate-committee-modal';
import ReactivateCommitteeModal from '../reactivate-committee-modal';
import ViewCommitteeEnableOnWeb from '../view-committee-enable-on-web';
import ViewCommitteeGenInfo from '../view-committee-gen-info';
import ViewCommitteeSettings from '../view-committee-settings';
import ViewCommitteeStatisticalInfo from '../view-committee-statistical-info';

class CommitteInfoTab extends Component {

    confirmationMessage = '';
    isEnableCommitteeOnWebForCommitteeType = false;

    state = {
        isEditGenInfo: false,
        isEditSettings: false,
        isEditEnableOnWeb: false,
        showDeleteConfirmation: false,
        isPopUpOpen: false,
        applicationPrivilegeOnCommittee: [],
        applicationPrivilegeOnCommitteeType: [],
        inActiveCommittee: false,
        reActiveCommittee: false,
        openCopyMembersModal: false,
        searchActiveCommittees: [],
        isActiveStandard: false,
        activeStandardMessage: ''
    }

    componentWillMount() {
        const { CommitteeTypeId, LevelId, CommitteeExternalApplication, Status } = this.props.initialValues;

        if (this.props.allCommitteesHierarchyDetails) {
            this.props.getSelectedCommitteeHierarchyDetails(CommitteeTypeId, LevelId);
        }
        this.confirmationMessage = getMessage(this.props.messageCodes, '9057.text');

        if (this.props.applicationPrivilege && CommitteeExternalApplication && CommitteeExternalApplication.length > 0) {
            this.setApplicationPrivileges(this.props.applicationPrivilege, CommitteeExternalApplication, CommitteeTypeId, Status);
        }
        this.getApplicationPrivilegeOnCommitteeType(CommitteeTypeId, [], Status);

        this.props.getSearchActiveStandardCommittees((response) => {
            this.setState({ searchActiveCommittees: response })
        });
    }

    shouldComponentUpdate(nextProps) {
        const { CommitteeId, CommitteeExternalApplication, CommitteeTypeId, ModifiedDate, Status } = this.props.initialValues;
        // If the data was loaded after the render
        if (nextProps.allCommitteesHierarchyDetails && !this.props.allCommitteesHierarchyDetails) {
            this.props.getSelectedCommitteeHierarchyDetails(nextProps.initialValues.CommitteeTypeId, nextProps.initialValues.LevelId);
        }

        if (CommitteeId !== nextProps.initialValues.CommitteeId) {
            this.props.getSelectedCommitteeHierarchyDetails(nextProps.initialValues.CommitteeTypeId, nextProps.initialValues.LevelId);

            if (this.props.applicationPrivilege && nextProps.initialValues.CommitteeExternalApplication && nextProps.initialValues.CommitteeExternalApplication.length > 0) {
                this.setApplicationPrivileges(this.props.applicationPrivilege, nextProps.initialValues.CommitteeExternalApplication, nextProps.initialValues.CommitteeTypeId, nextProps.initialValues.Status);
            } else if (nextProps.initialValues.Status === McsStatusMasterId.ActiveCommittee) {
                this.getApplicationPrivilegeOnCommitteeType(nextProps.initialValues.CommitteeTypeId, [], nextProps.initialValues.Status);
            } else {
                this.setState({ applicationPrivilegeOnCommittee: [], applicationPrivilegeOnCommitteeType: [] });
            }

            // Reset the view and edit flag
            this.setState({ isEditGenInfo: false, isEditSettings: false, isEditEnableOnWeb: false });
        } else if (CommitteeId === nextProps.initialValues.CommitteeId && ModifiedDate !== nextProps.initialValues.ModifiedDate) {
            this.setApplicationPrivileges(nextProps.applicationPrivilege, nextProps.initialValues.CommitteeExternalApplication, CommitteeTypeId, nextProps.initialValues.Status);
        }

        if (this.props.applicationPrivilege !== nextProps.applicationPrivilege && CommitteeExternalApplication &&
            CommitteeExternalApplication.length > 0) {
            this.setApplicationPrivileges(nextProps.applicationPrivilege, CommitteeExternalApplication, CommitteeTypeId, Status);
        }

        return true;
    }

    setApplicationPrivileges = (applicationPrivilege, committeeExternalApplication, committeeTypeId, status) => {
        this.getApplicationPrivilegeOnCommittee(applicationPrivilege, committeeExternalApplication, (result) => {

            if (status === McsStatusMasterId.ActiveCommittee) {
                this.getApplicationPrivilegeOnCommitteeType(committeeTypeId, result, status);
            } else {
                this.setState({ applicationPrivilegeOnCommittee: result, applicationPrivilegeOnCommitteeType: [] });
            }
        });
    }

    // Combine the app privilege of committee and committeetype. In case of conflict, committee app privilege will display
    getApplicationPrivilegeOnCommitteeType = (committeeTypeId, appPrivilegeOnCommittee, status) => {
        this.props.getApplicationPrivilegeOnCommitteeType(committeeTypeId, (response) => {
            if (status === McsStatusMasterId.ActiveCommittee) {
                this.combinePrivileges(appPrivilegeOnCommittee, response);

                this.setState({ applicationPrivilegeOnCommitteeType: response });
            } else {
                this.isEnableCommitteeOnWebForCommitteeType = response && response.length > 0;
            }
        })
    }

    getApplicationPrivilegeOnCommittee = (allApplicationPrivilege, applicationPrivilegeOnCommittee, callback) => {
        let result = [];

        if (allApplicationPrivilege && allApplicationPrivilege.length > 0) {
            applicationPrivilegeOnCommittee.map((item) => {
                let appPrivilege = allApplicationPrivilege.find(app => app.ApplicationId === item.ApplicationId);
                let privilege = [];

                if (appPrivilege.Privilege && appPrivilege.Privilege.length > 0) {
                    privilege = deepCopy(appPrivilege.Privilege);

                    privilege.map((p) => {
                        let selectedPrivilege = item.ApplicationPrivilege.find(i => i.ApplicationPrivilegeId === p.ApplicationPrivilegeId);

                        if (selectedPrivilege) {
                            p.IsChecked = true;
                        }
                    })
                }

                result.push({ ApplicationId: appPrivilege.ApplicationId, ApplicationName: appPrivilege.ApplicationName, Privilege: privilege });
            })
        }

        callback(result);
    }

    combinePrivileges = (appPrivilegeOnCommittee, appPrivilegeOnCommitteeType) => {
        let applicationPrivilegeOnCommittee = [];

        if (appPrivilegeOnCommittee.length > 0) {
            applicationPrivilegeOnCommittee.push(...appPrivilegeOnCommittee);

            for (let i = 0; i < appPrivilegeOnCommitteeType.length; i++) {
                let data = applicationPrivilegeOnCommittee.find(item => item.ApplicationId === appPrivilegeOnCommitteeType[i].ApplicationId);

                if (!data) {
                    applicationPrivilegeOnCommittee.push(appPrivilegeOnCommitteeType[i]);
                }
            }

            applicationPrivilegeOnCommittee.sort((a, b) => a.ApplicationName.localeCompare(b.ApplicationName));
        } else {
            applicationPrivilegeOnCommittee = appPrivilegeOnCommitteeType;
        }

        this.setState({ applicationPrivilegeOnCommittee });
    }

    editGenInfo = () => {
        this.setState({ isEditGenInfo: true });
    }

    editSettings = () => {
        this.setState({ isEditSettings: true });
    }

    editEnableOnWeb = () => {
        this.setState({ isEditEnableOnWeb: true });
    }

    closeEditGenInfo = () => {
        this.setState({ isEditGenInfo: false });
    }

    closeSettings = () => {
        this.setState({ isEditSettings: false });
    }

    closeEnableOnWeb = () => {
        this.setState({ isEditEnableOnWeb: false });
    }

    deleteCommittee = () => {
        this.setState({ showDeleteConfirmation: true, isPopUpOpen: false });
    }

    onCancelModal = () => {
        this.setState({ showDeleteConfirmation: false });
    }
    manageQuickLink = (type, parentCommittee = false) => {
        const { ClassificationData } = this.props.initialValues.ClassificationStatisticalInfo;

        switch (type) {
            case 'copyCommittee':
                this.setState({ openCopyMembersModal: true, isPopUpOpen: false });
                break;
            case 'inActiveCommittee':
                if (ClassificationData && ClassificationData.length > 1 && ClassificationData[1].Count > 0) {
                    this.setState({ isActiveStandard: true, isPopUpOpen: false, activeStandardMessage: getMessage(this.props.messageCodes, parentCommittee === true ? '9520.text' : '9519.text') });
                } else {
                    let inActiveCommittee = !this.state.inActiveCommittee;
                    this.setState({ inActiveCommittee, isPopUpOpen: false });
                }
                break;
            case 'reActivateCommittee':
                let reActiveCommittee = !this.state.reActiveCommittee;
                this.setState({ reActiveCommittee: reActiveCommittee, isPopUpOpen: false, inActiveCommittee: false });
                break;
            default:
                break
        }
    }

    onConfirmModal = () => {
        const { PAGE_TYPE, USER_PRIVILEGE } = this.props;
        this.setState({ showDeleteConfirmation: false });
        this.props.deleteCommittee(encrypt(this.props.initialValues.CommitteeId), () => {
            this.props.history.push(routeNavigationUrl(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW));
        });
    }

    openPopup = () => {
        this.setState({ isPopUpOpen: true })
    }

    closePopUp = () => {
        this.setState({ isPopUpOpen: false, openCopyMembersModal: false, openReactivateCommitteeModal: false })
    }

    onCloseActiveStandardPopup = () => {
        this.setState({ isActiveStandard: false });
    }

    render() {
        const { isEditGenInfo, isEditSettings, isEditEnableOnWeb, showDeleteConfirmation, isPopUpOpen, applicationPrivilegeOnCommittee, applicationPrivilegeOnCommitteeType, openCopyMembersModal, searchActiveCommittees, inActiveCommittee, reActiveCommittee, isActiveStandard, activeStandardMessage } = this.state;
        const { initialValues: { ParentCommitteesList, Status }, hasUpdateAccess, PAGE_TYPE, USER_PRIVILEGE, hasInactivate, hasReactivate, hasAddMemberToCommitteeAccess } = this.props;
        return (
            <div className="committeDetailsTab" data-testid="committeInfoTab">
                <section className="committeeDetailSumary">
                    {ParentCommitteesList && ParentCommitteesList.length > 0 && (
                        <ul>
                            {ParentCommitteesList.map((item, index) => {
                                return (
                                    <li>
                                        {index === (ParentCommitteesList.length - 1) ?
                                            <span>{item.CommitteeDesignation}</span>
                                            :
                                            <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW, [encrypt(item.CommitteeId)])}`}>
                                                {item.CommitteeDesignation}
                                            </Link>
                                        }
                                        {index < ParentCommitteesList.length - 1 && (
                                            <span>&nbsp; // &nbsp;</span>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                    {(hasAddMemberToCommitteeAccess || hasUpdateAccess || hasInactivate || hasReactivate) && <div data-testid="comDetailsAction" className="comDetailsAction">
                        <Popup
                            hoverable
                            hideOnScroll
                            data-testid="comDetailsActionMenu"
                            className="comDetailsActionMenu"
                            trigger={<Icon name="ellipsis vertical" className="txtRight pointer" />}
                            position="bottom right" on="click" open={isPopUpOpen} onOpen={this.openPopup}
                            onClose={this.closePopUp}>
                            <div className="userGrroupname">
                                <ul>
                                    {hasAddMemberToCommitteeAccess && <li><button disabled={Status === McsStatusMasterId.ActiveCommittee ? '' : 'disabled'} onClick={() => this.manageQuickLink('copyCommittee')}>Copy Members</button></li>}
                                    {hasUpdateAccess && <li><button onClick={this.deleteCommittee}>Delete Erroneous Committee</button></li>}
                                    {hasInactivate && <li><button disabled={Status === McsStatusMasterId.ActiveCommittee ? '' : 'disabled'} onClick={() => this.manageQuickLink('inActiveCommittee', ParentCommitteesList.length > 0 ? true : false)}>Inactivate Committee</button></li>}
                                    {hasReactivate && <li><button disabled={Status === McsStatusMasterId.InactiveCommmittee ? '' : 'disabled'} onClick={() => this.manageQuickLink('reActivateCommittee')}>Reactivate Committee</button></li>}
                                </ul>
                            </div>
                        </Popup>
                    </div>}
                </section>

                <section className="committeeGeneralInfo">
                    {!isEditGenInfo ?
                        <ViewCommitteeGenInfo {...this.props} onEditGenInfo={this.editGenInfo} hasUpdateAccess={hasUpdateAccess} />
                        : <EditCommitteeGenInfo {...this.props} onCloseEditGenInfo={this.closeEditGenInfo} />
                    }
                </section>

                <section className="statisticalInfo clearfix">
                    <div className="dashed-border mt20 mb20"></div>
                    <ViewCommitteeStatisticalInfo {...this.props} />
                </section>

                <section className="settingInfo clearfix">
                    <div className="dashed-border mt20 mb20"></div>
                    {!isEditSettings ?
                        <ViewCommitteeSettings onEditSetting={this.editSettings} {...this.props} hasUpdateAccess={hasUpdateAccess} />
                        : <EditCommitteeSettings {...this.props} onCloseEditSettings={this.closeSettings} />
                    }
                </section>

                <section className="settingInfo clearfix">
                    <div className="dashed-border mt20 mb20"></div>
                    {!isEditEnableOnWeb ?
                        <ViewCommitteeEnableOnWeb onEditEnableOnWeb={this.editEnableOnWeb} {...this.props} hasUpdateAccess={hasUpdateAccess}
                            applicationPrivilegeOnCommittee={applicationPrivilegeOnCommittee} />
                        : <EditCommitteeEnableOnWeb {...this.props} onCloseEditEnableOnWeb={this.closeEnableOnWeb}
                            applicationPrivilegeOnCommittee={applicationPrivilegeOnCommittee}
                            applicationPrivilegeOnCommitteeType={applicationPrivilegeOnCommitteeType} />
                    }
                </section>
                {openCopyMembersModal && <CopyMembersModal {...this.props} activeCommittees={searchActiveCommittees} closePopUp={this.closePopUp} />}
                {inActiveCommittee && <InactivateCommitteeModal {...this.props} onClose={this.manageQuickLink} />}
                {reActiveCommittee && <ReactivateCommitteeModal {...this.props} isEnableCommitteeOnWebForCommitteeType={this.isEnableCommitteeOnWebForCommitteeType}
                    closePopUp={this.manageQuickLink} />}

                <Confirm className="confirm-box"
                    data-testid="confirm-box"
                    open={showDeleteConfirmation}
                    content={this.confirmationMessage}
                    onCancel={this.onCancelModal}
                    onConfirm={this.onConfirmModal}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
                {isActiveStandard &&
                    <CustomAlertModal content={activeStandardMessage} handleClick={this.onCloseActiveStandardPopup} />
                }

            </div >
        );
    }
}
export default CommitteInfoTab;
