
let self = {};

export const setClassInstance = (instance) => {
    self = instance;
}

export const loadData = (accountNumber) => {
    if (self.props.membershipTypeList && self.props.membershipTypeList.length === 0) {
        self.props.getMembershipTypeList();
    }
    if (!self.props.mouContactCodeList || !self.props.governmentTypeList || !self.props.interestedCommitteeList || !self.props.historicalReasonList || !self.props.memberAuditLogFields) {
        self.props.getMasterData();
    }
    if (!self.props.memberUserList) {
        self.props.getMemberUserList();
    }

    self.props.getMemberDetails(accountNumber);
    self.props.getMemberMasterData(accountNumber);
}
