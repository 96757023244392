import { PaidStatus } from './members.model';

export const getPaidStatusName = (paidStatus) => {
    if (paidStatus === PaidStatus.Paid) {
        return 'Paid';
    } else if (paidStatus === PaidStatus.Hold) {
        return 'Hold';
    }
    return 'Not Paid';
}

export const getMembershipTypeName = (id, membershipTypeList) => {

    if (membershipTypeList && membershipTypeList.length > 0) {
        let index = membershipTypeList.findIndex(item => item.key === id);
        if (index !== -1) {
            return membershipTypeList[index].text;
        }
    }

    return '';
}

export const formatHistoricalReasonList = (list, idToDisable) => {
    let result = [];
    if (list) {
        for (let i = 0; i < list.length; i++) {
            let res = {};

            if (idToDisable === list[i].Id) {
                res.disabled = true;
            } else {
                res.disabled = false;
            }
            res.key = list[i].Id;
            res.text = `${list[i].Name}`;
            res.value = list[i].Id;

            result.push(res);
        }
    }

    result.unshift({ 'key': 0, 'text': 'Select', 'value': 0 });

    return result;
}
