import React, { Component } from "react";
import { Grid, Modal, Form, Button } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form';
import CustomRendererSearch from '../custom-renderer-search';
import LinkPermissionTabs from '../link-permissions'
import TextArea from 'shared-components/TextArea'
import * as _func from './function';


class LinkAdditionalOfficerTitleModal extends Component {

    state = {
        deLinkOfficerList: this.props.deLinkOfficerList,
        officerTitleId: 0,
        mainresponsibility: [],
        responsibility: [],
        updatedResponsibility: []
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }
    render() {
        const { showHide, handleSubmit, linkedOfficerTitleId, selectedOfficerTitle } = this.props;
        const { showNoResultsMessage, isLoading, deLinkOfficerList, value, updatedResponsibility } = this.state;
        return (
            <div>
                <Modal open='true' className="small">
                    <Form size='large' onSubmit={handleSubmit} noValidate>
                        <Modal.Header>
                            {linkedOfficerTitleId > 0 ? `${selectedOfficerTitle + ' - Manage Responsibilities'}` : 'Link Additional Officer Title'}
                            <i className={'close'} onClick={showHide}>&#x2716;</i>
                        </Modal.Header>

                        <Modal.Content scrolling>
                            <Modal.Description style={{ 'min-height': '350px' }}>
                                <Grid columns='equal'>
                                    {linkedOfficerTitleId === 0 && <Grid.Row>
                                        <Grid.Column>
                                            <Field name="OfficerTitle" type="text"
                                                component={CustomRendererSearch}
                                                placeholder="Type to Select Officer Title"
                                                label="Select Officer Title"
                                                maxLength="100"
                                                value={value}
                                                showNoResults={showNoResultsMessage}
                                                onChange={(e, val) => _func.onOfficerTitleChange(val)}
                                                results={deLinkOfficerList}
                                                isLoading={isLoading}
                                                onResultSelect={_func.handleResultSelect}
                                                required={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>}
                                    {(updatedResponsibility.ResponsibilityList && updatedResponsibility.ResponsibilityList.length > 0) && <Grid.Row>
                                        <Grid.Column>
                                            <div className="linkPermissionTabs">
                                                <LinkPermissionTabs {...this.props} responsibility={updatedResponsibility.ResponsibilityList || []} changePrivilege={_func.changePrivilege} />

                                            </div>

                                            <div className="mt20">
                                                <Field component={TextArea} type='text' label='Reason for update'
                                                    name='UpdateReason' required={true} maxLength='200'
                                                    placeholder="Please enter reason for update"
                                                />

                                            </div>

                                        </Grid.Column>
                                    </Grid.Row>}
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>

                        <Modal.Actions>
                            <div>
                                <Button primary type="submit" disabled={!updatedResponsibility.ResponsibilityList || updatedResponsibility.ResponsibilityList.length === 0} >Link</Button>
                                <Button secondary className={'cancel'} onClick={showHide}>Cancel</Button>
                            </div>
                        </Modal.Actions>
                    </Form>
                </Modal >

            </div >
        )
    }
}
export default (reduxForm({
    form: 'LinkAdditionalOfficerTitleModal',
    validate: _func.validateHandler,
    onSubmit: _func.onSubmitHandler
})(LinkAdditionalOfficerTitleModal));
