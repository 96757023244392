import {
  dateMasking,
  formatDateToMMDDYYYY,
  getMessage,
  resetHeaderSortOrder,
  routeNavigationUrl,
  utcDateTimeToLocalDateTime,
  validateAuditLogDates,
} from "helpers/util-common";
import Parser from "html-react-parser";
import { ItemsPerPage, minDateForAuditLogs } from "models/common.models";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Loader } from "semantic-ui-react";
import DateTimePicker from "shared-components/DatePicker";
import PaginationShorthand from "shared-components/Pager";
import RenderSelect from "shared-components/Select";
import TableHeader from "shared-components/Table/tableHeader";
import { AuditGridColumns } from "modules/member-management/fee-group/feegroups.model";
import * as _func from "./function";

class FeeGroupAuditLog extends Component {
  state = {
    showLoader: true,
    auditLogList: [],
    pageNumber: 1,
    totalItems: 0,
    pageSize: 25,
    selectedFieldName: "All",
    dateFrom: "",
    dateTo: "",
    maxDate: formatDateToMMDDYYYY(),
    currentlyShowingItems: "",
    sortKey: "When",
    sortOrder: "desc",
  };

  componentDidMount() {
    _func.setInstance(this);
    _func.loadInitialData();
  }

  componentWillUnmount() {
    resetHeaderSortOrder(AuditGridColumns);
  }

  onChangeFieldType = (e, value) => {
    this.setState({ selectedFieldName: value });
  };

  onChangeDateFrom = (e, value) => {
    this.setState({
      dateFrom: value ? formatDateToMMDDYYYY(value) : minDateForAuditLogs,
    });
  };

  onChangeDateTo = (e, value) => {
    this.setState({ dateTo: formatDateToMMDDYYYY(value) });
  };

  render() {
    const {
      messageCodes,
      submitting,
      handleSubmit,
      hasUserPageAccess,
      feeGroupAuditLogField,
      PAGE_TYPE,
      USER_PRIVILEGE,
    } = this.props;
    const {
      showLoader,
      auditLogList,
      selectedFieldName,
      dateFrom,
      maxDate,
      totalItems,
      pageNumber,
      pageSize,
      currentlyShowingItems,
    } = this.state;

    let minDateForToDate = minDateForAuditLogs;
    if (moment(dateFrom, "MM/DD/YYYY", true).isValid() && dateFrom <= maxDate) {
      minDateForToDate = dateFrom;
    }
    return (
      <div data-testid="memberAuditLog">
        {showLoader && (
          <Loader
            data-testid="small-loader"
            size="small"
            className="small-loader"
          >
            Loading
          </Loader>
        )}
        <Form onSubmit={handleSubmit(_func.onClickAuditSearch)} noValidate>
          <div className="auditFilter inlineFilter mb20">
            <div className="colWrap mr20">
              <Field
                name="selectedFieldName"
                component={RenderSelect}
                value={selectedFieldName}
                placeholder="All"
                options={feeGroupAuditLogField}
                onChange={this.onChangeFieldType}
                label="Field Name"
              />
            </div>

            <div className="colWrap">
              <Field
                name="dateFrom"
                component={DateTimePicker}
                showTime={false}
                normalize={dateMasking}
                maxDate={maxDate}
                onChange={this.onChangeDateFrom}
                label="From"
              />
            </div>

            <div className="colWrap">
              <Field
                name="dateTo"
                component={DateTimePicker}
                showTime={false}
                normalize={dateMasking}
                minDate={minDateForToDate}
                maxDate={maxDate}
                onChange={this.onChangeDateTo}
                label="To"
              />
            </div>
            <div className="auditAction">
              <Button
                data-testid="btnReset"
                className="cancel"
                type="reset"
                onClick={_func.resetForm}
              >
                Reset
              </Button>
              <Button
                data-testid="btnSearch"
                className="primary ml5"
                disabled={submitting}
                type="submit"
              >
                Search
              </Button>
            </div>
          </div>
        </Form>

        <div>
          <div className="tableWrapper">
            <table className="customTable auditLogtable type2">
              <thead>
                <tr>
                  <TableHeader
                    headerProps={AuditGridColumns}
                    onClickProps={_func.sortByHandler}
                  ></TableHeader>
                </tr>
              </thead>
              <tbody>
                {auditLogList.map((res) => (
                  <tr>
                    <td>{utcDateTimeToLocalDateTime(res.When)}</td>

                    <td className="description">
                      {Parser(_func.getDescription(res.Where, res.Why))}
                    </td>
                    <td className="reason">{res.Why}</td>
                    <td>
                      {hasUserPageAccess &&
                      Number(res.OwnerId) > 0 &&
                      (!res.OwnerType || res.OwnerType === 0) ? (
                        <Link
                          className="text-overflow assignee"
                          to={`${routeNavigationUrl(
                            PAGE_TYPE.DETAIL(PAGE_TYPE.USER),
                            USER_PRIVILEGE.VIEW,
                            [res.OwnerId],
                          )}`}
                        >
                          {res.Who}
                        </Link>
                      ) : hasUserPageAccess &&
                        Number(res.OwnerId) > 0 &&
                        res.OwnerType === 1 ? (
                        <Link
                          className="text-overflow assignee"
                          to={`${routeNavigationUrl(
                            PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER),
                            USER_PRIVILEGE.VIEW,
                            [res.Who.replace(/[^0-9\.]/g, "")],
                          )}`}
                          onClick={() =>
                            _func.refreshMemberDetailPage(
                              `${routeNavigationUrl(
                                PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER),
                                USER_PRIVILEGE.VIEW,
                                [res.Who.replace(/[^0-9\.]/g, "")],
                              )}`,
                            )
                          }
                        >
                          {res.Who}
                        </Link>
                      ) : (
                        res.Who
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!showLoader && auditLogList.length === 0 && (
              <div className="noRecordMessage">
                {getMessage(messageCodes, "9012.text")}
              </div>
            )}
          </div>
          {auditLogList.length > 0 && (
            <section className="pagerWrap" data-testid="pagerWrap">
              <div className="pager">
                <PaginationShorthand
                  defaultActivePage={pageNumber}
                  totalPages={Math.ceil(totalItems / pageSize)}
                  onPageChange={_func.onPageChangeHandler}
                />
              </div>

              <div className="itemPerPage" data-testid="itemPerPage">
                <RenderSelect
                  onChange={_func.onChangeNumberOfItemsPerPage}
                  value={pageSize}
                  options={ItemsPerPage}
                />
                <span className="itemsPerPage">items per page</span>
              </div>
              <div className="totalPage">{currentlyShowingItems}</div>
            </section>
          )}
        </div>
      </div>
    );
  }
}
export default reduxForm({
  form: "FeeGroupAuditLog",
  validate: validateAuditLogDates,
})(FeeGroupAuditLog);
