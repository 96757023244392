import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import CommitteeAuditLogTab from '../committee-audit-log';
import CommitteeCommunicationLog from '../committee-communication-log';
import CommitteInfoTab from '../committee-info-tab';
import CommitteeRoster from '../committee-roster';
import MeetingsTab from '../meetings-tab';
import * as _func from './function';

class CommitteeDetailsTab extends Component {

    state = {
        activeIndex: 0,
        Conditional_Roster_Filters_Values: {
            status_value: null,
            classification: null,
            vote: null,
            userAccess: {}
        }
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    shouldComponentUpdate(nextProps) {
        _func.handleUpdate(nextProps);
        return true;
    }

    render() {
        const { activeIndex, Conditional_Roster_Filters_Values, userAccess } = this.state;

        const panes = [
            {
                menuItem: 'Committee Details', render: () => <Tab.Pane attached={false}>
                    <CommitteInfoTab {...this.props} hasUpdateAccess={userAccess.hasUpdateAccess} hasUserPageAccess={userAccess.hasUserPageAccess}
                        onTotalStatisticalBoxInformation={_func.totalStatisticalBoxInformation}
                        hasInactivate={userAccess.hasInactivate} hasReactivate={userAccess.hasReactivate} hasAddMemberToCommitteeAccess={userAccess.hasAddMemberToCommitteeAccess}
                        onClassificationStatisticalListInformation={_func.classificationStatisticalListInformation}
                    />
                </Tab.Pane>
            },
            {
                menuItem: 'Committee Roster', render: () => <Tab.Pane attached={false}>
                    <CommitteeRoster {...this.props} hasUpdateAccess={userAccess.hasUpdateAccess} hasExportAccess={userAccess.hasExportAccess} hasAddMemberToCommitteeAccess={userAccess.hasAddMemberToCommitteeAccess} hasEditMemberParticipationOnCommittees={userAccess.hasEditMemberParticipationOnCommittees}
                        selectedFilters={Conditional_Roster_Filters_Values} hasUserPageAccess={userAccess.hasUserPageAccess}
                    />
                </Tab.Pane>
            },
            {
                menuItem: 'Meetings', render: () => <Tab.Pane attached={false}>
                    <MeetingsTab {...this.props} hasUpdateAccess={userAccess.hasUpdateAccess} />
                </Tab.Pane>
            },
            {
                menuItem: 'Communication Log', render: () => <Tab.Pane attached={false}>
                    <CommitteeCommunicationLog {...this.props} hasUserPageAccess={userAccess.hasUserPageAccess} hasModerateCommunicationLogAccess={userAccess.hasModerateCommunicationLogAccess} />
                </Tab.Pane>
            },
            {
                menuItem: 'Audit Log', render: () => <Tab.Pane attached={false}>
                    <CommitteeAuditLogTab {...this.props} hasUserPageAccess={userAccess.hasUserPageAccess} />
                </Tab.Pane>
            }
        ]

        return (
            <Tab menu={{ secondary: true, pointing: true }} panes={panes}
                activeIndex={activeIndex}
                defaultActiveIndex={0}
                onTabChange={_func.tabOnChange}
            />
        )
    }
}

export default CommitteeDetailsTab;
