import * as utilCommon from 'helpers/util-common';


let self = {};
let memberList = [];
export const setClassInstance = (instance) => {
    self = instance;
    getFeeGroupNoneAssociatedMember();
}

export const getFeeGroupNoneAssociatedMember = () => {
    self.props.getFeeGroupNoneAssociatedMember(response => {
        memberList = response;
    })
}

export const handleSearh = (value) => {
    const regExp = new RegExp(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi);
    self.setState({ regExpSpecialCharacter: regExp.test(value) })
    clearTimeout(self.searchAfterStopTypingTimer);

    if (value === ' ') {
        self.setState({ searchValue: value.trim(), searchResultsData: [] })
    } else {
        self.setState({ searchValue: value, searchResultsData: [] })
    }

    if (value.trim() !== '' && value.trim() !== null) {
        self.setState({ isLoading: true, showNoResults: false, searchResultsData: [] })
        self.searchAfterStopTypingTimer = setTimeout(() => { searchTriggerChange(value) }, 1500);
    } else {
        self.setState({ isLoading: false, showNoResults: false, searchResultsData: [] })
    }
}
const searchTriggerChange = (value) => {
    const { renderSelectedValueFromSearchList } = self.state
    if (!self.state.regExpSpecialCharacter) {
        self.setState({ isLoading: true, showNoResults: false, searchResultsData: [] })
        value = value.toLowerCase();
        let temMemberList = memberList.filter(item => ((renderSelectedValueFromSearchList.length > 0 && renderSelectedValueFromSearchList.indexOf(item) === -1) || renderSelectedValueFromSearchList.length === 0) &&
            (`${item.AccountNumber}${item.Name}` || '').toLowerCase().includes(value));
        if (temMemberList && temMemberList.length > 0) {
            searchMember(temMemberList, false)
        } else {
            self.setState({ isLoading: false, showNoResults: true, searchResultsData: [] })
        }

    } else {
        self.setState({ isLoading: false, showNoResults: true, searchResultsData: [] })
    }
}

export const handleResultSelect = (value) => {
    let renderSelectedValueFromSearchList = self.state.renderSelectedValueFromSearchList;
    let searchResultsData = self.state.searchResultsData;

    if (searchResultsData.length > 0) {
        searchResultsData = searchResultsData.filter(item => item.MemberId !== value.MemberId);
    }
    renderSelectedValueFromSearchList.push(value);
    self.setState({ showErrorMessage: renderSelectedValueFromSearchList.length === 0 });
    self.setState({ renderSelectedValueFromSearchList, searchResultsData })
}

const searchMember = (res, isLoader) => {
    if (res && res.length > 0) {
        self.setState({ isLoading: isLoader, searchResultsData: res })
    } else {
       self.setState({ isLoading: isLoader, showNoResults: true, searchResultsData: [] });
    }
}
export const removeMember = (memberId) => {
    let currentList = self.state.renderSelectedValueFromSearchList.filter(item => item.MemberId === memberId) || [];
    let renderSelectedValueFromSearchList = self.state.renderSelectedValueFromSearchList.filter(item => item.MemberId !== memberId) || [];
    let searchResultsData = self.state.searchResultsData;
    searchResultsData=searchResultsData.concat(currentList);
    self.setState({ renderSelectedValueFromSearchList, searchResultsData, showErrorMessage: renderSelectedValueFromSearchList.length === 0 });

}

export const validateHandler = (values, props) => {
    const errors = {};
    if (!values.UpdateReason || !(values.UpdateReason || '').trim()) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text');
    }
    return errors;
}

export const associateMemberIntoFeeGroup = (value) => {
    self.setState({ showErrorMessage: false });
    let postData = {
        FeeGroupId: self.props.initialValues.FeeGroupId || 0,
        Members: self.state.renderSelectedValueFromSearchList.map(item => { return { MemberId: item.MemberId } }),
        ReasonForUpdate: value.UpdateReason || ''
    }
    if (postData.Members.length > 0) {
        self.props.putAssociateMemberFeeGroup(postData, () => self.props.close(true))
    } else {
        self.setState({ showErrorMessage: true });
    }
}
