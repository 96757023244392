import React, { Component } from "react";
import FeeGroupBannerHeader from "../fee-group-banner-header";
import FeeGroupDetailsTabs from "../fee-group-details-tab";
import { decrypt } from "helpers/util-common";
import { encrypt } from "../../../../../helpers/util-common";
import { FEE_GROUP_MODULE_ID } from "../../feegroups.model";

class FeeGroupDetail extends Component {
  constructor(props) {
    super(props);
    this.refFeeGroupDetail = React.createRef();
    this.state = {
      activeTabIndex: decrypt(this.props.match.params.activeIndex),
      feeGroupId: this.props.match.params.feeGroupId,
      FeeGroupBannerDetails: {},
      FeeGroupTabDetails: {},
    };
  }

  componentWillMount() {
    this.getFeeGroupBannerDetail();
    this.getFeeGroupTabDetail();
  }

  componentDidMount() {
    this.props.getAuditLogField(encrypt([FEE_GROUP_MODULE_ID]), () => {});
    this.props.getInitialMembershipTypeListData();
    if (this.refFeeGroupDetail.current.offsetParent !== null) {
      this.refFeeGroupDetail.current.offsetParent.offsetParent.querySelector(
        "#root",
      ).scrollTop = 0;
    }
  }
  getFeeGroupBannerDetail = () => {
    this.props.getFeeGroupBannerDetail(this.state.feeGroupId, (response) => {
      this.setState({ FeeGroupBannerDetails: response });
    });
  };

  getFeeGroupTabDetail = () => {
    this.props.getFeeGroupTabDetail(this.state.feeGroupId, (response) => {
      this.setState({ FeeGroupTabDetails: response });
    });
  };

  render() {
    const { activeTabIndex, FeeGroupBannerDetails, FeeGroupTabDetails } =
      this.state;
    const { getFeeGroupTabDetail, getFeeGroupBannerDetail } = this;

    return (
      <div data-testid="feeGroupDetailsComp">
        <div className="ui memberPage detailPage" ref={this.refFeeGroupDetail}>
          <FeeGroupBannerHeader
            {...this.props}
            FeeGroupBannerDetails={FeeGroupBannerDetails}
          />

          <div className="memberFeGroup">
            <FeeGroupDetailsTabs
              {...this.props}
              activeTabIndex={activeTabIndex}
              FeeGroupTabDetails={FeeGroupTabDetails}
              refreshPage={getFeeGroupTabDetail}
              bannerRefresh={getFeeGroupBannerDetail}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default FeeGroupDetail;
