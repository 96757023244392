import { getMessage } from 'helpers/util-common';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Modal } from 'semantic-ui-react';
import TextArea from 'shared-components/TextArea';

const validateHandler = (values, props) => {
    let errors = {};

    if (!values.UpdateReason || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}

class DeleteCommitteeMeetingSequenceModal extends PureComponent {

    onSubmitHandler = (data) => {
        this.props.onSubmit(data.UpdateReason);
    }

    render() {
        const { submitting, handleSubmit, messageCodes, onClose } = this.props;

        return (
            <Modal open='true' className="tiny errormodalbox">
                <Form size='large' onSubmit={handleSubmit(this.onSubmitHandler)}>
                    <Modal.Header>CONFIRM <i onClick={onClose} className="close" data-testid="close-btn" title="close">&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <p>{getMessage(messageCodes, '9048.text')}</p>

                            <Field component={TextArea} type='text' label='Reason for update'
                                name='UpdateReason' required={true} maxLength='200'
                                placeholder="Please enter reason for update"
                            />

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" disabled={submitting} type="submit">Yes</button>
                            <button className="ui button cancel" onClick={onClose}>No</button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'DeleteMeetingSequenceModal',
    validate: validateHandler
})(DeleteCommitteeMeetingSequenceModal);
