export const primaryActivityModelConstant = {
    select: 0,
    manufacturerOfProductService: 1,
    salesDistributorOfMaterialProductService: 2,
    userPurchaserOfProductService: 3,
    testingOfProductService: 4,
    consultant: 5,
    governmentAgency: 6,
    academia: 7,
    consumer: 8,
    consumerAdvocacyGroup: 9,
    other: 10,
    consultingFirm: 11
}

export const membershipTypeConstant = {
    honorary: 1,
    perpetualInstitution: 2,
    organizational: 3,
    affilate: 4,
    memberInstitution: 5,
    participating: 6,
    informational: 7,
    exemptMember: 8,
    seniorMember: 9,
    lifeMember: 10,
    perpetualIndustry: 11,
    temporary: 12,
    isoJoint: 13,
    representative: 14,
    newMember: 15,
    cooperativeAgreement: 16,
    studentMember: 17,
    mouMember: 18,
    GovtRepresentative: 19,
    cooperativeAgreementRep: 20
}

export const stockCodeConstant = {
    member: 'MEM',
    organizational: 'ORGANIZATIONAL',
    student: 'STUDENT'
}
export const memStockCodeConstant = {
    MEMINDIVIDUAL: 'MEMINDIVIDUAL',
    MEMORGANIZATIONAL: 'MEMORGANIZATIONAL',
    MEMSTUDENT: 'MEMSTUDENT'
}
export const status = {
    active: 'Active',
    historical: 'Historical'
}

export const paidStatus = {
    hold: 'H',
    paid: 'P',
    notPaid: 'N'
}

export const volumeFormat = {
    print: 'print'
}

export const membershipTypeName = [
    'Honorary Member',
    'Perpetual Institution Member',
    'Organizational Member',
    'Affiliate Member',
    'Member Institution',
    'Participating Member',
    'Informational Member',
    'Exempt Member',
    'Senior Member',
    'Life Member',
    'Perpetual Industry Member',
    'Temporary Member',
    'ISO/JOINT Member',
    'Representative Member',
    'New Member',
    // 'GOVT REP', This is out of scope from ASTM2.0 (MEM-12190)
    'Cooperative Agreement Member',
    'Student Member',
    'MOU Member'
]

export const MonthOptionsWithSelect = [
    { key: -1, text: 'Month', value: -1 },
    { key: 1, text: 'January', value: 1 },
    { key: 2, text: 'February', value: 2 },
    { key: 3, text: 'March', value: 3 },
    { key: 4, text: 'April', value: 4 },
    { key: 5, text: 'May', value: 5 },
    { key: 6, text: 'June', value: 6 },
    { key: 7, text: 'July', value: 7 },
    { key: 8, text: 'August', value: 8 },
    { key: 9, text: 'September', value: 9 },
    { key: 10, text: 'October', value: 10 },
    { key: 11, text: 'November', value: 11 },
    { key: 12, text: 'December', value: 12 }
]

export const DegreeSought = [
    { key: -1, text: 'Select', value: -1 },
    { key: 1, text: 'Certificate Program', value: "Certificate Program" },
    { key: 2, text: 'Associates Degree', value: "Associates Degree" },
    { key: 3, text: 'Bachelors Degree', value: "Bachelors Degree" },
    { key: 4, text: 'Masters Degree', value: "Masters Degree" },
    { key: 5, text: 'Doctorate Degree', value: "Doctorate Degree" }
]

export const SourceOfInformation = [
    { key: -1, text: 'Select', value: -1 },
    { key: 1, text: 'ASTM Website', value: "ASTM Website" },
    { key: 2, text: 'ASTM Event', value: "ASTM Event" },
    { key: 3, text: 'ASTM Committee/Student Member', value: "ASTM Committee/Student Member" },
    { key: 4, text: 'University Faculty', value: "University Faculty" },
    { key: 5, text: 'Industry Event', value: "Industry Event" },
    { key: 6, text: 'Guest Lecturer', value: "Guest Lecturer" },
    { key: 7, text: 'LinkedIn', value: "LinkedIn" },
    { key: 8, text: 'Other', value: "Other" }
]

export const studentMembershipConstant = {
    other: 'other',
    technicalCommittee: 'technical committee'
}

export const MembershipTypCodes = {
    organizational: "4"
}

export const stockCodePrice = {
    MEMINDIVIDUAL: '75',
    MEMORGANIZATIONAL: '400'
}

export const renewalWithSameStockCode = [
    membershipTypeConstant.informational,
    membershipTypeConstant.memberInstitution,
    membershipTypeConstant.participating,
    membershipTypeConstant.seniorMember,
    membershipTypeConstant.cooperativeAgreement,
    membershipTypeConstant.representative,
    membershipTypeConstant.temporary,
    membershipTypeConstant.organizational,
    membershipTypeConstant.cooperativeAgreementRep

]

export const historicalReasonId = {
    Error: 6
}

export const historicalMonthCheck = 36;

export const reinstateEligibleMemberships = {
    individual: [
        membershipTypeConstant.honorary,
        membershipTypeConstant.affilate,
        membershipTypeConstant.participating,
        membershipTypeConstant.informational,
        membershipTypeConstant.lifeMember,
        membershipTypeConstant.temporary,
        membershipTypeConstant.isoJoint,
        membershipTypeConstant.cooperativeAgreement,
        membershipTypeConstant.mouMember
    ],
    organizational: [
        membershipTypeConstant.organizational,
        membershipTypeConstant.representative,
        membershipTypeConstant.cooperativeAgreementRep
    ]
};