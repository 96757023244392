import { FORM_TYPE_ORG_UPDATE } from 'helpers/util-common';
import React from "react";
import { reduxForm } from 'redux-form';
import UpdateOrgInformation from '../../../members/components/update-org-info';

const OrganizationInfoEdit = (props) => {
   return props.isEditFacility ? <UpdateOrgInformation
      {...props}
      formType={FORM_TYPE_ORG_UPDATE.Organization}
      editType='update'
      onCloseModal={props.onCloseModal}
   /> : null
}

export default reduxForm({
   form: 'OrganizationInfoEdit',
   enableReinitialize: true,
   keepDirtyOnReinitialize: true
})(OrganizationInfoEdit);
