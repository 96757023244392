import { handleAutoComplete } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid, Icon } from 'semantic-ui-react';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import { membershipTypeJSON, MembershipTypes } from '../../../members/members.model';
import CustomGroupRendererSearch from '../custom-group-renderer-search';
import * as _func from './function';

class OrganizationEditGenInfo extends Component {

    state = {
        showConfirmationModal: false,
        descMessage: '',
        historicalRadioLabel: '',
        activeRadioLabel: '',
        showNoResultsMessage: false,
        isLoading: false,
        formattedMemberList: {}
    }

    componentWillMount() {
        _func.setClassInstance(this);
        this.props.change('OrgRepName', this.props.initialValues.RepName);
    }
    componentDidMount() {
        handleAutoComplete()
    }
    render() {
        const { submitting, handleSubmit, initialValues, onCloseEditGenInfo } = this.props;
        const { showConfirmationModal, descMessage, showNoResultsMessage, isLoading, formattedMemberList } = this.state;

        return (
            <div>
                <Form noValidate>

                    <h5>ASTM General Information
                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button data-testid="btnSaveGenInfo" title="Update" className="updateBtn" onClick={handleSubmit(_func.onSubmitHandler)} disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button data-testid="btnCancelEditGenInfo" title="Cancel" className="closeBtn" onClick={onCloseEditGenInfo}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>

                    <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <label className="label" htmlFor='MemberType'>Member Type</label>
                                <RenderSelect name='MemberType' id='MemberType'
                                    placeholder='ORGANIZATIONAL' disabled={true}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field name='FeeGroup' label='Fee Group'
                                    component={RenderSelect} placeholder={initialValues.FeeGroupName}
                                    disabled={true}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ width: '50%' }}>
                                <Field name="OrgRepName" type="text"
                                    component={CustomGroupRendererSearch}
                                    placeholder="Search Member by Account Number, Name"
                                    label={initialValues.RepMembershipTypeId === MembershipTypes.cooperativeAgreementRep ? membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName}
                                    maxLength="100"
                                    showNoResults={showNoResultsMessage}
                                    onChange={(e, val) => _func.onRepMemberChange(val)}
                                    results={formattedMemberList}
                                    isLoading={isLoading}
                                    isOrganizationSearch={true}
                                    onResultSelect={_func.onRepMemberSelect}
                                    className="autocompleteOff"
                                />
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <Confirm className="confirm-box"
                    open={showConfirmationModal}
                    content={<div className="content" dangerouslySetInnerHTML={{ __html: descMessage }}></div>}
                    onCancel={_func.onCancelModal}
                    onConfirm={_func.onConfirmModal}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        )
    }
}

export default reduxForm({
    form: 'OrganizationEditGenInfoForm',
    validate: _func.validateHandler
})(OrganizationEditGenInfo);
