import React, { Component } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

class RenderRadioField extends Component {
    state = {
        value: this.props.selectedValue
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.selectedValue !== this.props.selectedValue) {
            this.setState({ value: nextProps.selectedValue })
        }
        return true;
    }

    handleChange = (e) => {
        if (this.props.input) {
            this.props.input.onChange(e);
        } else {
            this.props.onChange(e);
        }

        if (e && e.currentTarget && e.currentTarget.querySelector('input')) {
            this.setState({
                value: e.currentTarget.querySelector('input').value
            });
        }
    }

    render() {
        // For redux form implementation
        if ('input' in this.props) {
            const { input, required, options, label, isDisabled, isSibling, meta: { touched, error } } = this.props;

            return (
                <div>
                    <label className="label">
                        {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                        {label}</label>
                    <div className={isSibling ? '' : "radioWrap"}>

                        {options.map(item => {
                            return (
                                <React.Fragment key={item.label}>
                                    <Form.Field>
                                        <Radio {...input} data-testid={item.value}
                                            label={item.label}
                                            value={item.value}
                                            type='radio'
                                            checked={item.value === this.state.value}
                                            onChange={this.handleChange}
                                            onFocus={this.handleChange}
                                            disabled={isDisabled || item.isDisabled}
                                        />
                                    </Form.Field>

                                    {!isEmpty(item.siblingComponent) && item.siblingComponent}
                                </React.Fragment>
                            )
                        })}
                        {touched && (error && <span className="errorMessage mt5">{error}</span>)}
                    </div>
                </div>
            )
        }

        // Without redux form implementation
        const { name, required, options, label, isDisabled } = this.props;
        return (
            <div>
                <label className="label">
                    {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                    {label}</label>
                <div className="radioWrap">

                    {options.map(item => {
                        return (
                            <Form.Field>
                                <Radio data-testid={item.value}
                                    name={name}
                                    label={item.label}
                                    value={item.value}
                                    type='radio'
                                    checked={item.value === this.state.value}
                                    onChange={this.handleChange}
                                    disabled={isDisabled || item.isDisabled}
                                />
                            </Form.Field>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default RenderRadioField;
