import { getMessage, removeScript } from 'helpers/util-common';
import { AppGridIds, ItemsPerPage } from 'models/common.models';
import React, { Component } from "react";
import { Confirm, Loader } from "semantic-ui-react";
import CustomDataGrid from 'shared-components/AdvancedGrid';
import ExportExcel from 'shared-components/ExportExcel';
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import { CompanyStatusOptions, OrganizationStatusOptions } from '../organizations/organizations.model';
import * as _func from './function';

class OrganizationList extends Component {

    constructor(props) {
        super(props);

        const self = this;
        self.state = {
            showLoader: false,
            totalPages: 0,
            totalItems: 0,
            activePageNumber: 1,
            selectedCountry: _func.getCountryFromUrl(props.location.search.length > 0 ? props.location.search.substring(1) : ''),
            selectedCompany: _func.getCompanyTypeFromUrl(props.location.search.length > 0 ? props.location.search.substring(1) : 0),
            selectedStatus: 0,
            pageSize: 25,
            currentlyShowingItems: '',
            sortedColumn: '',
            sortOrder: 0,
            searchText: props.location.search.length > 0 ? props.location.search.substring(1) : '',
            isDataUpdated: false,
            isWordEmpty: false,
            // Exportable Variable Setting Start with grid
            isOrganizationExcelExported: false,
            exportDataSet: [],
            isExcelEmpty: false,
            tableHeaderConfig: [],
            isTableHeaderReset: true,
            isShowGridMenu: false,
            isFilterChanged: false,
            gridProp: {
                records: [],
                attributes: {},
                expandables: [],
                sortables: {},
                omitOnMenu: [],
                excelExportedColumn: [],
                emptyMessage: null,
                excelExportableFieldsCallback: null,
                displayName: {},
                requestSaveParams: {}
            },
            hasExportAccess: false
        };

        this.resetDataUpdatedFlag = this.resetDataUpdatedFlag.bind(this);
    }

    componentWillMount() {
        const { pageSize, searchText, selectedCompany, selectedCountry, selectedStatus } = this.state;
        _func.setClassInstance(this);
        _func.loadData(1, pageSize, searchText, '', 0, selectedCompany, selectedCountry, selectedStatus, true);
        this.setState({ isTableHeaderReset: true });
    }

    componentWillUpdate(nextProps) {
        _func.handleComponentWillUpdate(nextProps);
    }

    onChangeCompanyType = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedCountry, selectedStatus } = this.state;
        this.setState({ selectedCompany: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true },
            () => _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, value, selectedCountry, selectedStatus));
    }

    onChangeStatusType = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedCountry, selectedCompany} = this.state;
        this.setState({ selectedStatus: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true },
            () => _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, value));
    }

    onChangeCountry = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedCompany, selectedStatus } = this.state;
        this.setState({ selectedCountry: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true },
            () => _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, value, selectedStatus));
    }

    onChangeNumberOfItemsPerPage = (e, value) => {
        const { sortedColumn, sortOrder, searchText, selectedCompany, selectedCountry, selectedStatus } = this.state;

        _func.loadData(1, value, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, selectedStatus);
        this.setState({ pageSize: value, activePageNumber: 1, isTableHeaderReset: false });
    }

    onChangeSortHandler = (sortedColumn, sortOrder) => {
        const { activePageNumber, pageSize, searchText, selectedCompany, selectedCountry, selectedStatus } = this.state;
        this.setState({ sortedColumn, sortOrder, isTableHeaderReset: false });

        // Load new data
        _func.loadData(activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, selectedStatus);
    }

    onPageChangeHandler = (event, data) => {
        const { pageSize, searchText, sortedColumn, sortOrder, totalPages, selectedCompany, selectedCountry, selectedStatus } = this.state;

        if (totalPages > 1) {
            _func.loadData(data.activePage, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, selectedStatus);
        }

        this.setState({
            activePageNumber: data.activePage,
            isTableHeaderReset: false
        })
    }

    resetDataUpdatedFlag = () => {
        this.setState({ isDataUpdated: false, isTableHeaderReset: true });
    }

    componentWillUnmount() {
        removeScript();
    }

    render() {
        const { isShowGridMenu, isWordEmpty, isExcelEmpty, exportDataSet, isOrganizationExcelExported, showLoader, gridProp, totalItems, totalPages, activePageNumber, selectedCompany, pageSize, currentlyShowingItems, isDataUpdated, selectedCountry, hasExportAccess, selectedStatus } = this.state;
        const { messageCodes, PAGE_TYPE, USER_PRIVILEGE, uniqueKey, countryList } = this.props;
        return (
            <div data-testid="organizationsList">
                {showLoader && (
                    <Loader size='small' className="small-loader" data-testid='loader'>Loading</Loader>
                )}
                <div className='ui memberPage memberListPage'>
                    <div className='headingTitle clearfix'>
                        <h2>Organizations</h2>
                    </div>

                    <div className="clearfix member-header">
                        <div className="display-inline-block mr25" id="countryWrapper" data-testid="countryWrapper">
                            <label className="label-inline" for="country">Country</label>
                            <RenderSelect name="country"
                                id="country"
                                placeholder="All"
                                value={selectedCountry}
                                onChange={this.onChangeCountry}
                                options={countryList}
                            />
                        </div>
                        <div className="display-inline-block mr25" id="accountTypeWrapper" data-testid="accountTypeWrapper">
                            <label className="label-inline" for="accountType">Organization Type</label>
                            <RenderSelect
                                name="accountType"
                                id="accountType"
                                value={selectedCompany}
                                onChange={this.onChangeCompanyType}
                                options={OrganizationStatusOptions}
                            />
                        </div>
                        <div className="display-inline-block mr25" id="selectedStatusWrapper" data-testid="selectedStatusWrapper">
                            <label className="label-inline" for="selectedStatus">Status</label>
                            <RenderSelect
                                name="selectedStatus"
                                id="selectedStatus"
                                value={selectedStatus}
                                onChange={this.onChangeStatusType}
                                options={CompanyStatusOptions}
                            />
                        </div>
                        {hasExportAccess && (
                            <div className="display-inline-block floatRight">
                                <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                                <button id="btnDownloadExcel" data-testid="btnDownloadExcel" style={{ pointerEvents: showLoader ? 'none' : 'auto' }} onClick={_func.getOrganizationExportedExcel} className="ui button secondary mr10"><i className="icon file excel mr5"></i>Excel</button>
                                {isOrganizationExcelExported &&
                                    <ExportExcel fileName={_func.fileName('Organizations')} tabName={'Organizations'} multiDataSet={exportDataSet} />
                                }
                                <button id="btnDownloadWord" data-testid="btnDownloadWord" className="ui button secondary " onClick={_func.getOrganizationExportedWord}><i className="icon file word mr5"></i>Word</button>
                            </div>
                        )}
                    </div>

                    <div className="relative">
                        {gridProp.excelExportedColumn !== null && gridProp.excelExportedColumn.length > 0 && (
                            <CustomDataGrid isShowGridMenuEvent={isShowGridMenu} gridId={AppGridIds.OrganizationGrid}
                                detailsPageUrl={uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)} detailsPageUrl2={uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)}
                                isDataUpdated={isDataUpdated} resetDataUpdatedFlag={this.resetDataUpdatedFlag}
                                gridSetting={gridProp} onChangeSort={this.onChangeSortHandler}
                                saveGridPerferenceClickAction={_func.saveGridPreferenceClickAction} />
                        )}
                    </div>
                    {totalItems > 0 && (
                        <div className="pagerWrap" id="pagerWrap" data-testid="pagerWrap">
                            <div className="pager">
                                <PaginationShorthand defaultActivePage={activePageNumber} totalPages={totalPages}
                                    onPageChange={this.onPageChangeHandler} />
                                <div>
                                </div>
                            </div>
                            <div className="itemPerPage" id='itemPerPage' data-testid='itemPerPage'>
                                <RenderSelect
                                    name="itemsPerPage"
                                    value={pageSize}
                                    onChange={this.onChangeNumberOfItemsPerPage}
                                    options={ItemsPerPage}
                                />
                                <span className="itemsPerPage">items per page</span>
                            </div>

                            <div className="totalPage">{currentlyShowingItems}</div>

                        </div>
                    )}

                </div>
                <Confirm
                    className="confirm-box"
                    open={isExcelEmpty}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyExcelCancelHandler}
                    onConfirm={_func.emptyExcelConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                <Confirm
                    className="confirm-box"
                    open={isWordEmpty}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyWordCancelHandler}
                    onConfirm={_func.emptyWordConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        );
    }
}

export default OrganizationList;
