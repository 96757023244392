import { generateUUIDForAutofill } from 'helpers/util-common';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Loader } from 'semantic-ui-react';

const InputTag = ({ input, offAutoComplete, required, label, isLoading, handleOnKeyDown, handleOnBlur, searchText, handleOnChange, handleSelectChange, options, tags, placeholder, handleRemoveTag, meta: { touched, error } }) => {

   const containerRef = useRef(null);
   const [showOptions, setShowOptions] = useState(true);
   const uuid = generateUUIDForAutofill();

   useEffect(() => {
      document.addEventListener('click', handleClick, true);

      return () => {
         document.removeEventListener('click', handleClick, true);
      }
   }, []);

   const handleClick = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
         handleOnBlur();
         setShowOptions(false);
      } else if (containerRef.current && containerRef.current.contains(e.target)) {
         setShowOptions(true);
      }
   }

   const handleOnChangeSelect = (value) => {
      containerRef.current.querySelector('input').focus();
      handleSelectChange(value);
      setShowOptions(true);
   }

   return <div className="customMSelect mt10">
      <label className="label">
         {required && (<i aria-hidden="true" className="asterisk icon"></i>)}
         {label}
      </label >

      <div ref={containerRef}>
         <div>
            <div className="mt5 mb5">
               {tags.map((item, index) => {
                  return <a key={index} className="ui label">
                     {item} <i aria-label="close button" className="pointer"
                        onClick={() => { handleRemoveTag(index) }}>&nbsp;&times;</i>
                  </a>
               })}
            </div>
            <Form.Input
               fluid size='mini'
               name={`input_${input.name}`} placeholder={placeholder}
               className={touched && error ? " error" : ''}
               value={searchText}
               onFocus={() => { setShowOptions(true) }}
               onKeyDown={handleOnKeyDown}
               onChange={(e, { value }) => { handleOnChange(value) }}
               autoComplete={offAutoComplete ? `none_${uuid}` : "on"}
            />
            {showOptions && isLoading && <Loader active inline />}
         </div>

         {
            options && options.length > 0 && <Form.Select
               open={showOptions}
               name={`select_${input.name}`}
               onChange={(e, { value }) => handleOnChangeSelect(value)}
               options={options}
               value={-1}
               selectOnNavigation={false}
            />
         }
      </div>
      {touched && (error && <span className="errorMessage mt0">{error}</span>)}
   </div >
}

export default InputTag;
