
import lodash from 'lodash';
import * as _actions from '../action-types';
import * as utilCommon from '../helpers/util-common';

// Validate only GET Permission from Requested Urls
export const checkGetPermissionRequestFromUrl = (requestUrl) => {
    let isAuth = false;
    // Condition for Allow Default Route
    if (requestUrl && requestUrl.routeUrl === 'home') {
        isAuth = true
    } else {
        // Rest Validate condition for Route allow or not
        let isPageAuthorized = utilCommon.filterPermissionUrl(requestUrl, requestUrl.oslMethod);
        isAuth = utilCommon.allowPermissionForAuth(isPageAuthorized);
    }
    return isAuth;
}

// Validate BreadCrumb from Requested Urls
export const validateBreadcrumbsUrls = (self) => {
    let parent = '';
    let currentPage = '';
    let parentPath = '';
    let isAuth = false;
    const currentPath = self.props.history.location.pathname;
    self.props.dispatchEvent(_actions.HIDE_NOT_FOUND_PAGE);
    // Get URL Details
    let urlDetails = utilCommon.validateNavigationFromUrl(currentPath);
    if (!urlDetails) {	// IF Routes Not Matched
        self.props.dispatchEvent(_actions.SHOW_NOT_FOUND_PAGE);
        return false;
    }
    // IF Routes Matched
    if (lodash(urlDetails).get('selectedSingleResult', null) && lodash(urlDetails).get('selectedSingleResult').length > 0) {
        let navUrlResult = lodash(urlDetails).get('selectedSingleResult')[0];
        parent = navUrlResult.breadcrumbsParentTitle;
        currentPage = navUrlResult.breadcrumbsTitle;
        parentPath = navUrlResult.breadcrumbsParentPath;
        isAuth = checkGetPermissionRequestFromUrl(navUrlResult);
        if (utilCommon.getSearchCurrentGlobalDropDownOptions(urlDetails)) {
            // Update Current Search State If Found Current Search Value
            self.setState({
                currentSearchOption: utilCommon.getSearchCurrentGlobalDropDownOptions(urlDetails)
            })
        }
        self.props.getPermissionOnPage(navUrlResult, lodash(urlDetails).get('selectedFullResult', null))
    }

    self.setState({
        parent,
        currentPage,
        previousPathName: currentPath,
        parentPath,
        isAuthorizedPage: isAuth
    }, () => loadNavPermission(self))
}


export const loadNavPermission = (self) => {
    const { isUserAuthorized } = self.props;
    if (isUserAuthorized) {
        utilCommon.validateNavigationPermission().then((result) => {
            self.setState({
                NAVIGATION: result
            })
        });
        }
}

export const updateSearchText = (self, location) => {

    // Update the current search text
    let query = location.search;
    let index = query.toLowerCase().indexOf('searchtext=');
    let currentSearchText = '';

    if (query.length > 0 && index > -1) {
        currentSearchText = query.substring(index + 11);
        currentSearchText = utilCommon.decrypt(currentSearchText);
    }
    self.setState({
        currentSearchText,
        shouldClearSearchText: currentSearchText.length <= 0
    });

    // Reset the flag
    setTimeout(() => {
        self.setState({
            shouldClearSearchText: false
        });
    }, 100);
}


export const handleSearchSubmit = (self, currentSearchOption, searchText) => {
    let urlToRedirect = utilCommon.getUrlToRedirect(currentSearchOption, self.props);
    urlToRedirect = urlToRedirect !== '' ? urlToRedirect : self.props.location.pathname;

    if (searchText && searchText.length > 0) {
        // Encode the special chars
        searchText = utilCommon.encrypt(searchText);
        self.props.history.push(`${urlToRedirect}?SearchText=${searchText}`);
    } else {
        self.props.history.push(`${urlToRedirect}`);
    }

    self.setState({ currentSearchOption });
    dispatchEvent(self);
}

export const handleAdvanceSearchSubmit = (self, currentSearchOption, query) => {
    self.setState({ currentSearchOption });
    dispatchEvent(self);

    let urlToRedirect = utilCommon.getUrlToRedirect(currentSearchOption, self.props);
    urlToRedirect = urlToRedirect !== '' ? urlToRedirect : self.props.location.pathname;
    self.props.history.push(`${urlToRedirect}?${query}`);
}

const dispatchEvent = (self) => {
    const { currentSearchOption } = self.state;
    if (currentSearchOption) {
        self.props.dispatchEvent(_actions[currentSearchOption]);
    }
}

