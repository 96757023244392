import React, { Component } from "react";
import { Tab } from "semantic-ui-react";
import FeeGroupPersonalDetails from "../fee-group-personal-details";
import AssociatedMembersList from "../associated-members";
import FeeGroupAuditLog from "../fee-group-audit-log";
import { checkUserPermissionOnPage } from "helpers/util-common";

class FeeGroupDetailsTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: this.props.activeTabIndex,
    };
  }

  userAccess = {};

  componentWillMount() {
    const { PAGE_TYPE, USER_PRIVILEGE, userPermission } = this.props;
    this.userAccess = {
      hasUserPageAccess: checkUserPermissionOnPage(
        userPermission,
        PAGE_TYPE.USER,
        USER_PRIVILEGE.VIEW,
      ),
    };
  }

  onTabChange = (event, data) => {
    this.setState({ activeTabIndex: data.activeIndex });
  };

  render() {
    const { activeTabIndex } = this.state;
    const panes = [
      {
        menuItem: "Details",
        render: () => (
          <Tab.Pane attached={false}>
            <FeeGroupPersonalDetails {...this.props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Associated Members",
        render: () => (
          <Tab.Pane attached={false}>
            <AssociatedMembersList {...this.props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Audit Log",
        render: () => (
          <Tab.Pane attached={false}>
            <FeeGroupAuditLog
              {...this.props}
              hasUserPageAccess={this.userAccess.hasUserPageAccess}
            />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <Tab
        data-testid="AssMemberTabChange"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={activeTabIndex}
        onTabChange={this.onTabChange}
      />
    );
  }
}
export default FeeGroupDetailsTabs;
