import { connect } from "react-redux";
import organizationActions from '../../organizations/actions/organizations.actions';
import AdvanceSearchOrganization from '../component/advance-organization-search/index';

const mapStateToProps = state => {
    return {
        userPermission: state.commonReducer.userPermission,
        countryList: state.commonReducer.countryList,
        usStateList: state.commonReducer.usStateList,
        canadaStateList: state.commonReducer.canadaStateList,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getAutoSearchFilter: (name, type, callback) => organizationActions.getAutoSearchFilterAction(name, type, callback, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchOrganization);
