import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Grid } from 'semantic-ui-react';
import AutoSearch from 'shared-components/Search';
import RenderSelect from 'shared-components/Select';
import { StatusFilter, AutoRenewal, PaidStatus } from '../../feegroups.model';
import * as _func from './function';

class AdvanceSearchFeeGroup extends Component {
    membershipTypeList = []
    constructor(props){
        super(props);

        this.state = {
            isResetClicked: false,
            firstNameList: [],
            isShowFirstNameErrorMsg: false,
            isShowLastNameErrorMsg: false,
            isShowContactNameErrorMsg: false,
            isShowOrgErrorMsg: false,
            isStateListDisabled: false,
            lastNameList: [],
            organizationNameList: [],
            stateList: [],
            feeGroupListArray: props.feeGroupList,
            isShowAssociateAccountNumberErrorMsg: false,
            isShowAssociateFirstNameErrorMsg: false,
            isShowAssociateLastNameErrorMsg: false,
            associateLastNameList: [],
            isLoadingAssociateLastList: false,
            isLoadingAssociateFistList: false,
            associateFistNameList: [],
            associateAccountNumberList: [],
            isLoadingAssociateAccountList: false,
            contactNameList: [],
            isLoadingContactList: false
        }
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.loadInitialData();
    }

    render() {
        const { handleSubmit, countryList } = this.props;
        const { firstNameList, lastNameList, isShowFirstNameErrorMsg,
            isShowLastNameErrorMsg,
            isLoadingFirstList,
            isLoadingLastList,
            isShowAssociateAccountNumberErrorMsg,
            isShowAssociateFirstNameErrorMsg,
            isShowAssociateLastNameErrorMsg,
            associateLastNameList,
            isLoadingAssociateLastList,
            isLoadingAssociateFistList,
            associateFistNameList,
            associateAccountNumberList,
            isLoadingAssociateAccountList,
            contactNameList,
            isShowContactNameErrorMsg,
            isLoadingContactList } = this.state;

        return (
            <Form onSubmit={handleSubmit} noValidate>
                <Grid columns='equal' className="mt0">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label>Fee Group Number</label>
                            <Field name="FeeGroupNumber" type="text"
                                component={AutoSearch}
                                placeholder="Fee Group Number"
                                maxLength="150"
                                showNoResults={isShowFirstNameErrorMsg}
                                onChange={(e, val) => _func.getFirstName(val)}
                                source={firstNameList}
                                isLoading={isLoadingFirstList}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <label>Fee Group Title</label>
                            <Field name="FeeGroupTitle" type="text"
                                component={AutoSearch}
                                maxLength="150"
                                placeholder="Fee Group Title"
                                showNoResults={isShowLastNameErrorMsg}
                                onChange={(e, val) => _func.getLastName(val)}
                                source={lastNameList}
                                isLoading={isLoadingLastList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb0">
                        <Grid.Column>
                            <Field name="Status"
                                component={RenderSelect} label="Status"
                                placeholder="All"
                                options={StatusFilter}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Field name="PaidStatus"
                                component={RenderSelect} label="Paid Status"
                                placeholder="All"
                                options={PaidStatus}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="AutoRenewal"
                                component={RenderSelect} label="Auto Renewal"
                                placeholder="All"
                                options={AutoRenewal}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Field name="Country"
                                component={RenderSelect} label="Country"
                                placeholder="All"
                                options={countryList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label>Contact Name</label>
                            <Field name="ContactName" type="text"
                                component={AutoSearch}
                                maxLength="150"
                                placeholder="Contact Name"
                                showNoResults={isShowContactNameErrorMsg}
                                onChange={(e, val) => _func.getContactName(val)}
                                source={contactNameList}
                                isLoading={isLoadingContactList}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <label>Associated Member Account Number</label>
                            <Field name="AssociatedMemberAccountNo" type="text"
                                component={AutoSearch}
                                maxLength="150"
                                placeholder="Account Number"
                                showNoResults={isShowAssociateAccountNumberErrorMsg}
                                onChange={(e, val) => _func.getAssociateAccountNumber(val)}
                                source={associateAccountNumberList}
                                isLoading={isLoadingAssociateAccountList}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label>Associated Member First Name</label>
                            <Field name="AssociatedMemberFirstName" type="text"
                                component={AutoSearch}
                                maxLength="150"
                                placeholder="First Name"
                                showNoResults={isShowAssociateFirstNameErrorMsg}
                                onChange={(e, val) => _func.getAssociateFirstName(val)}
                                source={associateFistNameList}
                                isLoading={isLoadingAssociateFistList}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <label>Associated Member Last Name</label>
                            <Field name="AssociatedMemberLastName" type="text"
                                component={AutoSearch}
                                maxLength="150"
                                placeholder="Last Name"
                                showNoResults={isShowAssociateLastNameErrorMsg}
                                onChange={(e, val) => _func.getAssociateLastName(val)}
                                source={associateLastNameList}
                                isLoading={isLoadingAssociateLastList}
                            />
                        </Grid.Column>
                    </Grid.Row>


                    <Grid.Row className="mb0">
                        <Grid.Column className="actions">
                            <Button data-testid="submitFGSearch" primary type="submit">Search</Button>
                            <Button data-testid="resetFGsearch" className="cancel ml10" type="reset" onClick={_func.resetForm}>Reset</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

        );
    }
}

export default (reduxForm({
    form: 'AdvanceSearchFeeGroup',
    onSubmit: _func.onSubmitHandler
})(AdvanceSearchFeeGroup));
