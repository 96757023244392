import { formatAddressLineList, canadaZipCodeFix, FORM_TYPE_ORG_UPDATE } from 'helpers/util-common';
import { CountryCodes } from 'models/common.models';

let self = {};
const initialAddressState = {
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    CountryCode: '',
    StateName: '',
    PostalCode: '',
}

export const setClassInstance = (instance) => {
    self = instance;
    //reset state
    self.props.change('manualOrderOrggNam', '');
    setAddressFields({});

    // Set the state based in previous selected values
    handlePostData(self.props);
}

export const prefillOrganizationDetailsInOnboardingProcess = () => {
    if (self.props.orderFormData) {
        const { memberTempInformationDetails } = self.props.orderFormData;
        if (memberTempInformationDetails && memberTempInformationDetails.haveCompanyDetails) {
            const { CompanyData } = memberTempInformationDetails.FormData;
            self.props.change('manualOrderOrggNam', CompanyData.Name);
            prefillOrganizationAddressDetailsInOnboardingProcess(CompanyData.Address);
        }
    }
}

const prefillOrganizationAddressDetailsInOnboardingProcess = (address) => {
    // Set the below address fields
    const country = address.CountryCode ? getCountryNameOrCode(address.CountryCode, 'code') : '-1';
    onCountryChange(country, false);
    self.props.change('manualOrderOrgAdrLine1', address.AddressLine1 ? address.AddressLine1 : '');
    self.props.change('manualOrderOrgAdrLine2', address.AddressLine2 ? address.AddressLine2 : '');
    self.props.change('manualOrderOrgCty', address.City ? address.City : '');
    self.props.change('manualOrderstatpro', address.StateCode ? address.StateCode : address.StateName);
    self.props.change('manualOrderZpcd', address.PostalCode ? canadaZipCodeFix(address.PostalCode) : '');
}

export const handlePostData = (props) => {
    const { postData, isEditing, checkStateConditions } = props;
    if (postData) {
        let { manualOrderOrggNam, manualOrderOrgAdrLine1, orgCountry } = postData;
        let isAddressFieldEmpty = true;
        if (manualOrderOrggNam && !isEditing) {
            if (manualOrderOrgAdrLine1 && manualOrderOrgAdrLine1.trim()) {
                isAddressFieldEmpty = false;
            }
            self.setState({ isAddressFieldEmpty });
            getCompanyAddressList(manualOrderOrggNam, isAddressFieldEmpty);
        }
        if (orgCountry) {
            onCountryChange(orgCountry, false);
        }

        if (checkStateConditions && orgCountry) {
            getStateValue(getCountryNameOrCode(orgCountry, 'name'))
        }
        else if (props.formType) {
            self.props.change('manualOrderZpcd', postData.PostalCode ? canadaZipCodeFix(postData.PostalCode) : '');
        }
    }
}

export const handleOnResultSelect = (result) => {
    let { organizationList } = self.state;
    organizationList = organizationList.filter(item => item.title === result.title)
    self.setState({ organizationList });
    getCompanyAddressList(result.title, true);
    self.props.change('manualOrderOrggNam', result.title);

    setAddressFields(initialAddressState)
    self.props.change('_validationHack', Date.now()); // Used this to call validation when search and select values are same
}

export const setAddressLine1Visibility = (value) => {
    self.setState({ isAddressLineSearchOpen: value })
}

export const handleOnChangeAddressLine1 = (e, value) => {
    setAddressLine1Visibility(true);
    const trimmedValue = value ? value.trim().toLowerCase() : '';

    let { addressList, filteredAddressList } = self.state;
    if (trimmedValue.length > 0) {
        filteredAddressList = addressList.filter(i => i.FullAddress.toLowerCase().indexOf(trimmedValue) !== -1);

        // In case exact match is found then pre populate the fields
        if (filteredAddressList.length === 1 && filteredAddressList[0].FullAddress.toLowerCase() === trimmedValue) {
            setAddressFields(filteredAddressList[0]);
        }
    } else {
        filteredAddressList = [...addressList];
    }

    self.setState({ filteredAddressList, isAddressFieldEmpty: trimmedValue.length === 0 });
}


export const handleOnResultSelectAddressLine1 = (result) => {
    let { filteredAddressList } = self.state;
    filteredAddressList = filteredAddressList.filter(i => i.title === result.title);
    self.setState({ filteredAddressList,  isAddressFieldEmpty: false });
    if(self.props.isCompanyTypeNeeded){
        self.props.setCompanyTypeDetail(filteredAddressList);
    }
    setAddressLine1Visibility(false);
    setAddressFields(filteredAddressList[0]);
    self.props.change('_validationHack', Date.now()); // Used this to call validation when search and select values are same
}

export const onCountryChange = (value, resetValue = true) => {
    let stateValue = '';
    let isUnitedstatesOrCanadaSelected = false;
    let isUSSelected = false;
    let selectedCountry = getCountryNameOrCode(value, "name")
    let maxLength = 9
    if (value === CountryCodes.UNITEDSTATES.Name || value === CountryCodes.CANADA.Name) {
        isUnitedstatesOrCanadaSelected = true;
        stateValue = '-1';
        if (value === CountryCodes.UNITEDSTATES.Name) {
            isUSSelected = true;
            maxLength = 5
        } else {
            maxLength = 7
        }
    }
    self.setState({ isUnitedstatesOrCanadaSelected, maxLength, isUSSelected }, () => {
        self.props.change('orgCountryCode', selectedCountry);
        self.props.change('orgCountry', value);
    });
    if (resetValue) {
        self.props.change('manualOrderstatpro', stateValue);
        self.props.change('manualOrderZpcd', '');
    }
}

export const setAddressFields = (address) => {
    // Clear the below address fields
    self.props.change('manualOrderOrgAdrLine1', address.AddressLine1 ? address.AddressLine1 : '');
    self.props.change('manualOrderOrgAdrLine2', address.AddressLine2 ? address.AddressLine2 : '');
    self.props.change('manualOrderOrgCty', address.City ? address.City : '');
    self.props.change('orgCountry', address.CountryCode ? getCountryNameOrCode(address.CountryCode, 'code') : '-1');
    self.props.change('manualOrderstatpro', getStateValue(address.CountryCode, address.StateName)); // TODO
    self.props.change('manualOrderZpcd', address.PostalCode ? canadaZipCodeFix(address.PostalCode) : '');
    self.props.change('orgCountryCode', address.CountryCode ? address.CountryCode : '-1');
    if (!address) {
        self.setState({ isUnitedstatesOrCanadaSelected: false, isUSSelected: false });
    }
}

export const getCountryNameOrCode = (countryCode, type) => {
    let matchedItem = {}
    if (countryCode && self.props.originalActiveCountryList) {
        if (type === 'code')
            matchedItem = self.props.originalActiveCountryList.find(i => i.CountryCode === countryCode);
        else
            matchedItem = self.props.originalActiveCountryList.find(i => i.CountryName === countryCode);

        if (matchedItem && type === 'code') {
            return matchedItem.CountryName;
        } else if (matchedItem && type === 'name') {
            return matchedItem.CountryCode;
        }
    }
    return '-1';
}

export const getStateValue = (countryCode, stateName) => {
    if (countryCode === CountryCodes.UNITEDSTATES.Code) {
        self.setState({ isUnitedstatesOrCanadaSelected: true, isUSSelected: true, maxLength: 5 });
        return stateName ? stateName : '-1';
    } else if (countryCode === CountryCodes.CANADA.Code) {
        self.setState({ isUnitedstatesOrCanadaSelected: true, isUSSelected: false, maxLength: 7 });
        return stateName ? stateName : '-1';
    } else {
        self.setState({ isUnitedstatesOrCanadaSelected: false, isUSSelected: false, maxLength: 9 });
    }
    return stateName ? stateName : '';
}

const getCompanyAddressList = (orgName, isAddressFieldEmpty) => {
    self.props.getCompanyAddressList(orgName, (response) => {
        let addressList = formatAddressLineList(response);

        self.setState({
            addressList,
            filteredAddressList: isAddressFieldEmpty ? addressList : []
        });
    });
}
