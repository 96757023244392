import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { generateUUIDForAutofill } from '../../helpers/util-common';

class AutoSearch extends Component {

    handleResultSelect = (e, { result }) => {
        if (this.props.onResultSelect) {
            this.props.onResultSelect(result);
        } else {
            this.props.input.onChange(result.title);
        }
    }

    handleSearchChange = (e) => {
        if ('input' in this.props) { // Auto Search with redux Form
            this.props.input.onChange(e.target.value);
        }
    }
    setAutoComplete = () => {
        const none_value = `none_${generateUUIDForAutofill()}`;
        const { maxLength } = this.props;
        let el = document.querySelectorAll("div.autocompleteOff input[type='text']")
        if (el && el.length > 0) {
            el.forEach(function (element) {
                element.setAttribute('autocomplete', none_value)
                if (maxLength) {
                    element.setAttribute('maxLength', maxLength);
                }
            });
        }
    }
    handleFocus = () => {
        const { input: { onFocus } } = this.props;
        this.setAutoComplete();
        if (onFocus)
            onFocus();
    }

    render() {
        const { input: { name, value, onFocus, onBlur }, placeholder, className, isLoading, source, open, showNoResults, meta: { touched, error }, disabled, maxLength } = this.props;

        // This Search working with Redux form and without redux ---

        if ('input' in this.props) { // Auto Search with redux Form
            return (
                <div>
                    <Search
                        data-testid="autoSearch"
                        loading={isLoading}
                        name={name}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                        results={source}
                        value={value}
                        showNoResults={showNoResults}
                        placeholder={placeholder}
                        open={open}
                        onFocus={this.handleFocus}
                        onBlur={onBlur}
                        disabled={disabled}
                        maxLength={maxLength}
                        className={touched && error ? `error ${className}` : disabled ? 'ui disabled' : className}
                    />

                    {touched && (error && <span className="mt0 errorMessage">{error}</span>)}
                </div>
            )
        }
        // Without redux form pass props
        return (
            <Search
                loading={false}
                className={className ? className : ''}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                results={source}
                placeholder={placeholder}
                maxLength={maxLength}
            />
        )
    }
}
export default AutoSearch;
