import React, { Component } from 'react';
import { Grid, Form, Icon, Button, Confirm } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import { PaidStatusOptions } from '../../../members/members.model';
import MultipleRadioConfirmationPopup from 'shared-components/CustomConfirmPopup/multipleRadioConfirmationPopup';
import AssociateRepAccountModal from '../associate-rep-account-modal';
import { AccountStatusOptions, ActiveOrganizationStatusId } from '../../organizations.model'
import * as _func from './function';
import CustomTooltip from 'shared-components/Tooltip';
import { forEach } from 'lodash';
import { COMPANY_TYPE } from '../../../../../models/common.models';

class OrganizationEditAccountDetails extends Component {

    state = {
        descMessage: '',
        selectedStatus: this.props.initialValues.McsStatusMasterId,
        showHistoricalOrgConfirmation: false,
        radioOptions: [],
        showAssociateRepModal: false
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { submitting, handleSubmit, initialValues, onCloseEditAccountInfo, historicalReasonList, historicalFacilityReasonList, messageCodes, hasUpdatePaidStatusAccess } = this.props;
        const { descMessage, selectedStatus, showHistoricalOrgConfirmation, radioOptions, showAssociateRepModal } = this.state;
        const isFacility = initialValues.CompanyTypeId === COMPANY_TYPE.FACILITY;

        return (
            <div>
                <Form noValidate>
                    <h5>{isFacility ? 'Account Status Details ' : 'Member Account Status Details'}
                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button data-testid='btnSavePaidStatus' title="Update" className="updateBtn" onClick={handleSubmit(_func.onSubmitHandler)} disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button data-testid='btnCancelPaidStatus' title="Cancel" className="closeBtn" onClick={onCloseEditAccountInfo}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>

                    {isFacility && <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Account Status</span>
                                <span className="labelValue viewModeData">
                                    {initialValues.StatusName}
                                </span>
                            </Grid.Column>
                            <Grid.Column>
                                <Field name='HistoricalReasonId' label='Historical Reason'
                                    component={RenderSelect} required={true}
                                    options={historicalFacilityReasonList}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>}
                    {!isFacility && <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name='McsStatusMasterId' label='Account Status'
                                    component={RenderSelect} required={true}
                                    value={selectedStatus}
                                    onChange={_func.onUpdateAccountStatus}
                                    options={AccountStatusOptions}
                                />
                            </Grid.Column>
                            {selectedStatus !== ActiveOrganizationStatusId && (
                                <Grid.Column>
                                    <Field name='HistoricalReasonId' label='Historical Reason'
                                        component={RenderSelect} required={true}
                                        options={historicalReasonList}
                                    />
                                </Grid.Column>
                            )}
                            {hasUpdatePaidStatusAccess &&
                                <Grid.Column>
                                    <Field name='PaidStatus' label='Paid Status'
                                        component={RenderSelect} required={true}
                                        disabled={initialValues.McsStatusMasterId !== ActiveOrganizationStatusId}
                                        options={PaidStatusOptions}
                                    />
                                </Grid.Column>
                            }
                        </Grid.Row>
                    </Grid>}
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                {showHistoricalOrgConfirmation && (
                    <MultipleRadioConfirmationPopup descMessage={descMessage} options={radioOptions}
                        messageCodes={messageCodes} closeModal={_func.onCloseHistoricalOrgModal} />
                )}

                {showAssociateRepModal && (
                    <AssociateRepAccountModal messageCodes={messageCodes} closeModal={_func.onCloseAssociateRepModal} {...this.props} />
                )}
            </div>
        )
    }
}

export default reduxForm({
    form: 'OrganizationEditAccountDetails',
    validate: _func.validateHandler
})(OrganizationEditAccountDetails);
