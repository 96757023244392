import axios from 'axios';
import * as type from '../../../action-types';
import * as commonActions from '../../../common.actions';
import getApiUrl from '../../../helpers/api-urls';

export const getTasks = (pageNumber, pageSize, searchText, showOverdueTasks, showDependentTasks, upcomingSortByStatus, openSortByStatus, doneSortByStatus, dispatch, callback) => {

	let url = getApiUrl('renewalTask', 'getTasks');
	url = `${url}/PageNumber=${pageNumber}&PageSize=${pageSize}&upcomingSortBy=${upcomingSortByStatus}&openSortBy=${openSortByStatus}&doneSortBy=${doneSortByStatus}&${searchText}`;

	if (showOverdueTasks) {
		url = `${url}&ShowOverdueTasks=${showOverdueTasks}`;
	}
	if (showDependentTasks) {
		url = `${url}&ShowDependentTasks=${showDependentTasks}`;
	}

	axios.get(url)
		.then((response) => {

			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3013'));
			callback();
		});
};

// Add task payload received method
const addTaskReceivedPayload = (data) => {
	return {
		type: type.ADD_RENEWAL_TASK_ACTION,
		payload: data
	};
}

// Add task post api action
export const addTaskAction = (dispatch, data, reqType = 'add') => {
	const url = getApiUrl('renewalTask', 'addTasks');
	dispatch(commonActions.setLoader(true));
	axios.post(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch(addTaskReceivedPayload(content));

				if (reqType === 'add') {
					dispatch(commonActions.setMessage(true, '2023'));
				} else {
					dispatch(commonActions.setMessage(true, '2032'));
				}

			} else if (response.data.message === 8020) {
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3001')); // Default exception error
			return false;
		});
};

// Add task payload received method -- sucess
const addDependsOnTaskReceivedSuccessPayload = (data) => {
	return {
		type: type.ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_SUCCESS,
		payload: data
	};
}

// Add task payload received method -- failure
const addDependsOnTaskReceivedFailurePayload = (data) => {
	return {
		type: type.ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_FAILURE,
		payload: data
	};
}
// Add Depends On post api action
export const addDependsOnTaskAction = (dispatch, data, reqType = 'Add') => {
	data.reqType = reqType;
	const url = getApiUrl('renewalTask', 'addDepends');
	dispatch(commonActions.setLoader(true));
	axios.post(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch(addDependsOnTaskReceivedSuccessPayload(content));

				if (reqType === 'Edit') {
					dispatch(commonActions.setMessage(true, '2001'));
				}
			} else {
				dispatch(addDependsOnTaskReceivedFailurePayload(null));
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3022'));
			return false;
		});
};

// User  payload received method
const userReceivedPayload = (data) => {
	return {
		type: type.GET_USER_RENEWAL_TASK_ACTION,
		payload: data
	};
}

// User get api action
export const getUserAction = (dispatch, status = true) => {
	let url = getApiUrl('renewalTask', 'getAllAssignees');
	dispatch(commonActions.setLoader(true));
	url = url + '/' + status;
	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch(userReceivedPayload(content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3021')); // Default exception error
			return false;
		});
};

// Get dependent payload received method
const dependentTaskReceivedPayload = (data) => {
	return {
		type: type.GET_DEPENDENT_RENEWAL_TASK_ACTION,
		payload: data
	};
}
// Get dependent api action
export const getTasksForDependencyAction = (dispatch, params, isLoader = true) => {
	let url = getApiUrl('renewalTask', 'getTasksForDependency') + `/renewalyear=${params.RenewalYear}`;

	if (params.TaskId !== '') {
		url = `${url}&taskid=${params.TaskId}`;
	}

	if (isLoader) {
		dispatch(commonActions.setLoader(true));
	}
	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch(dependentTaskReceivedPayload(content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3017')); // Default exception error
			return false;
		});
};

// Set reminder api action
export const setReminder = (data, dispatch) => {
	const url = getApiUrl('renewalTask', 'saveReminder');
	dispatch(commonActions.setLoader(true));

	axios.post(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '2025'))
			} else if (response.data.message && response.data.message === 8020) {
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message))
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3026')); // Default exception error
			return false;
		});
};

export const getAllRenewalYears = (dispatch) => {
	const url = getApiUrl('renewalTask', 'getAlllRenewalYears');

	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch({ type: type.GET_RENEWAL_YEARS_LIST, renewalYearsList: content });
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3016')); // Default exception error
		});
};

export const addTaskWatchers = (data, forCurrentUserOnly, callback, dispatch) => {
	const url = getApiUrl('renewalTask', 'addTaskWatchers');
	dispatch(commonActions.setLoader(true));

	axios.post(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {

				if (forCurrentUserOnly) {
					dispatch(commonActions.setMessage(true, '2026'));
				} else {
					dispatch(commonActions.setMessage(true, '2027'));
				}
				callback();
			} else if (response.data.message && response.data.message === 8020) {
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message))
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3027')); // Default exception error
		});
}

export const removeTaskFromWatchList = (taskId, callback, dispatch) => {
	let url = getApiUrl('renewalTask', 'removeTaskFromWatchList');
	url = `${url}/${taskId}`;

	dispatch(commonActions.setLoader(true));

	axios.delete(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '2028'));
				callback();
			} else {
				dispatch(commonActions.setMessage(true, response.data.message))
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3028')); // Default exception error
		});
}

// Upload Documents call
export const uploadDocumentsAction = (data, dispatch, callback) => {
	const url = getApiUrl('renewalTask', 'saveDocuments');

	axios.post(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				if (callback) {
					callback(response.data.content); // Send the new document list
					dispatch(commonActions.setMessage(true, '2001'));
				}
			} else if (response.data.message && response.data.message === 8020) {
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message))
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3033')); // Default exception error
		});
}

export const getDocumentListAction = (taskId, dispatch) => {
	let url = getApiUrl('renewalTask', 'getTaskDocuments');
	url = `${url}/${taskId}`;

	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch({ type: type.GET_DOCUMENT_LIST_SUCCESS, uploadedFiles: content });
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3034')); // Default exception error
		});
};

export const getTasksForExportAction = (searchText, showOverdueTasks, showDependentTasks, dispatch, callback) => {

	let url = getApiUrl('renewalTask', 'getTasksForExport');
	url = `${url}/${searchText}`;

	if (showOverdueTasks) {
		url = `${url}&ShowOverdueTasks=${showOverdueTasks}`;
	}
	if (showDependentTasks) {
		url = `${url}&ShowDependentTasks=${showDependentTasks}`;
	}

	axios.get(url)
		.then((response) => {

			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3038'));
		});
};

export default {
	getTasks,
	addTaskAction,
	getUserAction,
	getTasksForDependencyAction,
	addDependsOnTaskAction,
	setReminder,
	getAllRenewalYears,
	addTaskWatchers,
	removeTaskFromWatchList,
	uploadDocumentsAction,
	getDocumentListAction,
	getTasksForExportAction
}
