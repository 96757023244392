import React from 'react';
import { Route } from 'react-router-dom';
import MemberDetail from '../modules/member-management/members/containers/member-details.container';
import MemberList from '../modules/member-management/members/containers/members.container';
import OrganizationDetail from '../modules/member-management/organizations/containers/organization-details.container';
import OrganizationList from '../modules/member-management/organizations/containers/organizations.container';
import FeeGroupList from '../modules/member-management/fee-group/containers/fee-group.container';
import FeeGroupDetails from '../modules/member-management/fee-group/containers/fee-group-details.container';

const MemberRoutes = (props) => (
    <div>
        <Route path='/member-management/members' exact component={MemberList} />
        <Route path='/member-management/members/details/:accountNumber' exact component={MemberDetail} />
        <Route path='/member-management/organizations' exact component={OrganizationList} />
        <Route path='/member-management/organizations/details/:accountNumber' exact component={OrganizationDetail} />
        <Route path='/member-management/fee-group' exact component={FeeGroupList} />
        <Route path='/member-management/fee-group/details/:activeIndex/:feeGroupId' exact component={FeeGroupDetails} />
    </div>
)

export default MemberRoutes;
