import React from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import RenderCheckBox from 'shared-components/CheckBox'

const LinkPermissionTabs = (props) => {

    let panes = props.responsibility.filter(item => item.ResponsibilityId === 1).map((item, index) => {
        return {
            menuItem: item.ResponsibilityName,
            render: () => <Tab.Pane attached={false}>
                {item.ResponsibilityGroupList.map((item2, index2) => {
                    return <div> <span className="modulePermission">{item2.ResponsibilityGroupName}</span>
                        <Grid columns={4}>
                            <Grid.Row>
                                {item2.ResponsibilityPrivilegeList.map((item3, index3) => {
                                    return <Grid.Column>

                                        <RenderCheckBox className="ui checkbox partial" IsEditable={true}
                                            IsChecked={item3.IsChecked} defaultChecked={item3.IsChecked}
                                            name={item3.ResponsibilityPrivilegeName}
                                            onChange={() => props.changePrivilege(index, index2, index3, item3.ResponsibilityPrivilegeName)} />
                                        <label>{item3.ResponsibilityPrivilegeName}</label>
                                    </Grid.Column>

                                })}
                            </Grid.Row>
                            {index2 < (item.ResponsibilityGroupList.length - 1) && <Grid.Row>
                                <div className="dashed-border FullWidth"></div>
                            </Grid.Row>}

                        </Grid>
                    </div>


                })}
            </Tab.Pane>
        }
    })

    return (
        <Tab {...props} menu={{ secondary: true, pointing: true }} panes={panes} />
    )
}

export default LinkPermissionTabs;
