import * as _action from 'action-types';
import { checkIfUserDidAdvanceSearch, checkUserPermissionOnPage, decrypt, encrypt, exportFileName, exportWordFile, extractValueFromUrl, formatDateToMMDDYYYY, getCurrentlyShowingItemsInGrid, getMessage, getUrlForExportData, loadScriptTag, replaceUrlParam, scrollToTopOfPage, setupDataForExcelExportFromCosmos, updateGridProps, validateExportableFields } from 'helpers/util-common';
import { AppGridIds } from 'models/common.models';
import { OrganizationStatusOptions } from './organizations.model';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
    loadScript();
    setUserAccess(self.props.userPermission);
}

export const handleComponentWillUpdate = (nextProps) => {
    const { currentSearchType, currentModuleTitle } = nextProps;

    if (nextProps.location.search !== self.props.location.search && currentSearchType === _action.SEARCH_ORGANIZATIONS) {
        const { pageSize, sortedColumn, sortOrder, selectedStatus } = self.state;
        const searchText = nextProps.location.search.length > 0 ? nextProps.location.search.substring(1) : '';
        const companyFromUrl = getCompanyTypeFromUrl(searchText);
        const selectedCountry = getCountryFromUrl(searchText);

        self.setState({ searchText, activePageNumber: 1, isTableHeaderReset: false, selectedCompany: companyFromUrl, isFilterChanged: false, selectedCountry });

        loadData(1, pageSize, searchText, sortedColumn, sortOrder, companyFromUrl, selectedCountry, selectedStatus);
    }

    // Recheck the user permissions when user click on Back button of window tab
    if (currentModuleTitle !== self.props.currentModuleTitle) {
        setUserAccess(nextProps.userPermission);
    }
}

export const loadData = (pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, selectedStatus, showPageLoader = false) => {
    const query = getQuery(pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, selectedStatus);

    if (!showPageLoader) {
        self.setState({ showLoader: true });
    }
    self.props.getOrganizationList(query, (tableRecord, tableHeader) => {
        handleOrganizationListDataResponse(tableRecord, tableHeader, pageSize, pageNumber);
        self.setState({
            tableHeaderConfig: tableHeader
        })
    }, showPageLoader)
}

// Private functions
const getQuery = (pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry, selectedStatus) => {
    let query = `gridId=${AppGridIds.OrganizationGrid}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const status = String(selectedStatus);
    const companyTypeValue = String(selectedCompany);
    if (searchText && searchText.length > 0) {
        query = `${query}&${searchText}`;
    }
    if (sortedColumn && sortedColumn.length > 0) {
        query = `${query}&sortedColumn=${sortedColumn}`;
    }
    if (sortOrder === 1 || sortOrder === 2) {
        const order = sortOrder === 1 ? 'asc' : 'desc';
        query = `${query}&sortOrder=${order}`;
    }
    if (status && status.length) {
        query = `${query}&status=${selectedStatus}`;
    }
    // Check Company Type key in URL
    if (companyTypeValue && companyTypeValue.length > 0) {
        let companyType = extractValueFromUrl(query, "companyType");

        if (companyType === null) {
            query = `${query}&companyType=${companyTypeValue}`;
        } else if (companyType !== null && companyType !== companyTypeValue) {
            query = replaceUrlParam(query, "companyType", companyTypeValue);
        }
    }

    if (selectedCountry && selectedCountry.length > 0) {
        let country = extractValueFromUrl(query, "country");

        if (country === null) {
            query = `${query}&country=${encrypt(selectedCountry)}`;
        } else if (country !== null && decrypt(country) !== selectedCountry) {
            query = replaceUrlParam(query, "country", encrypt(selectedCountry));
        }
    }
    return query;
}

const handleOrganizationListDataResponse = (TableResult, TableHeader, pageSize, pageNumber) => {
    let organizations = [];
    let count = 0;

    if (TableResult && TableResult.Count && TableResult.Organizations) {
        organizations = TableResult.Organizations;
        count = TableResult.Count
    }

    if (count > pageSize) {
        self.state.showPager = true;
    }

    setAllGridPreference(organizations, TableHeader, count);
    updateCurrentlyShowingItems(count, pageSize, pageNumber);

    // Whenever data is refreshed, Move the scroll to top.
    scrollToTopOfPage();
}

const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);

    self.setState({ currentlyShowingItems, totalPages });
}

export const setGridPreferenceParams = (params) => {
    const { gridProp } = self.state;
    let gridConfigParams = { "Columns": params };
    gridProp.requestSaveParams = gridConfigParams;
    self.setState({
        gridProp
    })
}

export const resetGridTableData = () => {
    const { activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry } = self.state;
    const query = getQuery(activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedCompany, selectedCountry);
    self.props.getOrganizationList(query, (tableRecord, tableHeader) => {
        handleOrganizationListDataResponse(tableRecord, tableHeader, pageSize, activePageNumber);
        self.setState({
            tableHeaderConfig: tableHeader
        })
    }, false)
}

export const saveGridPreferenceClickAction = () => {
    const { gridProp } = self.state;
    self.setState({ isShowGridMenu: false });
    self.props.updateGridPreference(AppGridIds.OrganizationGrid, gridProp.requestSaveParams, (result) => {
        if (result) {
            self.setState({ isShowGridMenu: true }, () => resetGridTableData());
        } else {
            self.setState({ isShowGridMenu: false });
        }
    });
}


export const excelExportableFieldsCallback = (fields) => {
    let exportFields = {};
    let column = [];
    let columnsKeys = [];

    const { gridProp } = self.state;
    if (fields !== null) {
        for (let keys in fields) {
            if (fields[keys]) {
                columnsKeys.push(keys);
                exportFields[keys] = fields[keys];
                column.push({ title: gridProp.displayName[keys], fieldsKey: keys, style: { font: { bold: true } } })
            }
        }
        gridProp.excelExportedColumn = column;
        self.setState({
            gridProp
        }, () => setGridPreferenceParams(validateExportableFields(self.state.tableHeaderConfig, columnsKeys)))
    }
}

export const setAllGridPreference = (TableResult, gridHeader, count) => {
    const { gridProp, isFilterChanged } = self.state;
    let columnKeys = [];
    if (self.state.isTableHeaderReset) { // Worked only value of isTableHeaderReset = true;
        // Set empty object for set all the grid table values
        let tableConfigProps = {
            attributes: {}, displayName: {}, expandables: [], sortables: {}, omitOnMenu: [], excelExportedColumn: [], emptyMessage: checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? getMessage(self.props.messageCodes, '9013.text') : getMessage(self.props.messageCodes, '9011.text'), records: TableResult, excelExportableFieldsCallback: excelExportableFieldsCallback
        }

        updateGridProps(gridHeader, tableConfigProps, columnKeys);

        self.setState({
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp: tableConfigProps
        }, () => setGridPreferenceParams(columnKeys));
    } else {
        // Update only grid records

        updateGridProps(gridHeader, gridProp, columnKeys);
        gridProp.records = TableResult;
        gridProp.emptyMessage = checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? getMessage(self.props.messageCodes, '9013.text') : getMessage(self.props.messageCodes, '9011.text');
        self.setState({
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp
        })
    }
}

// Http Request for Exported Data
export const getOrganizationExportedExcel = () => {
    const urlString = getUrlForExportData(self.state.totalItems);
    self.props.getOrganizationExportedData(getExportedResponse, urlString);
    self.setState({
        showLoader: true
    });
}

// Exported Response in Callback : -
export const getExportedResponse = (response) => {
    if (response && response.length > 0) {
        excelExportSetup(response);
    } else {
        self.setState({
            isExcelEmpty: true,
            showLoader: false
        })
    }
}

export const excelExportSetup = (result) => {
    const { gridProp } = self.state;

    let data = [];

    if (result && result.length > 0) {
        result.map((item) => {
            data.push(item);
        })
    }

    let dataSet = setupDataForExcelExportFromCosmos(gridProp, data);

    self.setState({
        showLoader: false,
        exportDataSet: dataSet,
        isOrganizationExcelExported: true
    }, () => resetExportComponent());
}

export const resetExportComponent = () => {
    setTimeout(() => {
        self.setState({
            isOrganizationExcelExported: false
        })
    }, 100);
}

export const fileName = (name) => {
    return exportFileName(name)
}

export const emptyExcelCancelHandler = () => {
    self.setState({
        isExcelEmpty: false
    });
}

export const emptyExcelConfirmHandler = () => {
    self.setState({
        isExcelEmpty: false
    }, () => excelExportSetup([])); // Exported Empty File
}

export const getCompanyTypeFromUrl = (searchText) => {
    let status = extractValueFromUrl(searchText, "companyType");
    return status !== null ? Number(status) : (searchText.length === 0 ? OrganizationStatusOptions[0].value : 0);
}

export const getCountryFromUrl = (searchText) => {
    let country = extractValueFromUrl(searchText, 'country');
    return country !== null ? decrypt(country) : 'All';
}

// Start work on Word  export part :-
export const emptyWordCancelHandler = () => {
    self.setState({
        isWordEmpty: false
    });
}

export const emptyWordConfirmHandler = () => {
    self.setState({
        isWordEmpty: false
    }, () => getWordDocxPageResponse([])); // Exported Empty File
}

export const getOrganizationExportedWord = () => {
    const urlString = getUrlForExportData(self.state.totalItems);
    self.props.getOrganizationExportedData(getExportedWordResponse, urlString);
    self.setState({
        showLoader: true
    });
}

// Exported Word Response in Callback : -
export const getExportedWordResponse = (response) => {
    if (response !== null && response.length > 0) {
        getWordDocxPageResponse(response);
    } else {
        self.setState({
            isWordEmpty: true,
            showLoader: false
        })
    }
}

export const getWordDocxPageResponse = (response) => {
    const { gridProp } = self.state;

    let data = [];
    if (response && response.length > 0) {
        response.map((item) => {
            data.push(item);
        })
    }

    let docXConfig = new window.docXConfiguration(gridProp.excelExportedColumn, exportFileName('Organizations'), formatDateToMMDDYYYY(), "Organizations", [], 'organization');
    exportWordFile(docXConfig, data);
    self.setState({ showLoader: false }) // Disable loader while file downloaded
}

export const loadScript = () => {
    loadScriptTag(['/docx/docx.js', '/docx/file-save.js', '/docx/docx-service.js'], 'head');
}

const setUserAccess = (userPermission) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    self.setState({
        hasExportAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.EXPORT)
    })
}
