import * as _actions from 'action-types';
import * as commonActions from 'common.actions';
import _ from 'lodash';
import { connect } from "react-redux";
import organizationActions from '../actions/organizations.actions';
import OrganizationList from '../index';

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        currentSearchType: state.commonReducer.currentSearchType,
        userPermission: state.commonReducer.userPermission,
        currentModuleTitle: state.commonReducer.currentModuleTitle,
        countryList: state.commonReducer.countryList,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getOrganizationList: (query, callback, isLoader = false) => {
        organizationActions.getOrganizationListAction(query, callback, dispatch, isLoader)
    },
    clearSearchType: () => { dispatch({ type: _actions.CLEAR_SEARCH_TYPE }); },
    getOrganizationExportedData: (callback, url) => organizationActions.getOrganizationExportedDataAction(dispatch, callback, url),
    getGridPreferenceAction: (callback, url) => organizationActions.getGridPreferenceAction(callback, dispatch),
    updateGridPreference: (gridId, data, callback) => {
        commonActions.updateGridPreferenceAction(gridId, data, callback, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
