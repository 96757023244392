import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { formatDateToMMDDYYYY, checkUserPermissionOnPage } from 'helpers/util-common';


class FeeGroupStatusDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeeGroupTabDetails: this.props.FeeGroupTabDetails
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ FeeGroupTabDetails: nextProps.FeeGroupTabDetails });
    }

    render() {
        const { FeeGroupTabDetails: { Status, PaidStatus, PaidStatusDate } } = this.state;

        const { PAGE_TYPE, USER_PRIVILEGE, userPermission, editFormHandler } = this.props
        return (
            <section className="subCommInfo mt30">
                <div className="dashed-border mt20 mb20"></div>
                <h5>Status
                {checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.UPDATE) &&
                        <a href title="Edit" className="editBtn" onClick={() => editFormHandler()}>
                            <Icon name="pencil" />
                        </a>}
                </h5>

                <Grid divided='vertically' columns={4}>
                    <Grid.Row>
                        <Grid.Column>
                            <span className="labelTitle">Status</span>
                            <span className="labelValue">{Status}</span>
                        </Grid.Column>

                        <Grid.Column>
                            <span className="labelTitle">Paid Status</span>
                            <span className="labelValue">{PaidStatus}</span>
                        </Grid.Column>

                        {PaidStatus && PaidStatus.toLowerCase() === 'paid' &&
                            <Grid.Column>
                                <span className="labelTitle">Paid Date</span>
                                <span className="labelValue">{PaidStatusDate && formatDateToMMDDYYYY(PaidStatusDate)}</span>
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            </section>
        )
    }
}
export default FeeGroupStatusDetails;
