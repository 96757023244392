import * as utilCommon from 'helpers/util-common';
import lodash from 'lodash';

let self = {};
let reqData = {}
export const setClassInstance = (instance) => {
    self = instance;

}
export const updateFeeGroupStatus = (value) => {
    const { initialValues } = self.props;
    reqData = {
        FeeGroupId: self.props.initialValues.FeeGroupId || 0,
        Status: value.selectedMenu === 'Inactive' ? 'I' : 'A',
        UpdateReason: value.UpdateReason || ''
    }
    if (lodash.isEqual({ FeeGroupId: initialValues.FeeGroupId, Status: initialValues.Status === "Active" ? "A" : "I" }, { FeeGroupId: reqData.FeeGroupId, Status: reqData.Status })) {
        self.props.displayNoChangesMadeMessage();
        self.props.editFormHandler(false);
    } else if ((self.props.initialValues.AssociatedMemberCount || 0) > 0 && reqData.Status === 'I') {
        self.setState({ showConfirm: true });
    } else {
        self.props.putFeeGroupStatus(reqData, () => {
            self.props.editFormHandler()
        })
    }

}
export const onConfirmHandler = () => {
    self.setState({ showConfirm: false }, () => {
        self.props.putFeeGroupStatus(reqData, () => {
            self.props.editFormHandler()
        })
    }
    )
}
export const validateHandler = (values, props) => {
    const errors = {};

    if (!values.selectedMenu) {
        errors.selectedMenu = utilCommon.getMessage(props.messageCodes, '8155.text');
    }
    if (!values.UpdateReason || !(values.UpdateReason || '').trim()) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}
