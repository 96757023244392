import React from "react";
import { Grid, Icon } from 'semantic-ui-react';
import { ActiveMemberStatusId } from '../../members.model';

const MemberViewGeneralStudentInfo = ({ initialValues, onEditGenInfo, hasUpdateAccess }) => (
    <div data-testid="memberViewGeneralStudentInfo">
        <h5>ASTM General Information
            {initialValues.McsStatusMasterId === ActiveMemberStatusId && hasUpdateAccess && (
                <a title="Edit" className="editBtn" onClick={onEditGenInfo}><Icon name="pencil" /></a>
            )}
        </h5>
        <Grid divided='vertically' columns={4}>
            <Grid.Row>
                <Grid.Column>
                    <span className="labelTitle">Major</span>
                    <span className="labelValue">
                        {initialValues.StudyField}
                    </span>
                </Grid.Column>
                <Grid.Column>
                    <span className="labelTitle">Graduation Date</span>
                    <span className="labelValue">
                        {initialValues.GraduationDate}
                    </span>
                </Grid.Column>
                <Grid.Column>
                    <label className="labelTitle">University/College/Institution</label>
                    <span className="labelValue">{initialValues.FacilityName}</span>
                </Grid.Column>
                <Grid.Column>
                    <span className="labelTitle">Degree Sought</span>
                    <span className="labelValue">
                        {initialValues.Degree}
                    </span>
                </Grid.Column>
                <Grid.Column>
                    <span className="labelTitle">Committees of Interest</span>
                    <span className="labelValue">
                        {initialValues.InterestedCommittee ? initialValues.InterestedCommittee.join(', ') : ''}
                    </span>
                </Grid.Column>
                <Grid.Column>
                    <span className="labelTitle">Mailing List</span>
                    <span className="labelValue">Opt In</span>
                </Grid.Column>

            </Grid.Row>
        </Grid>
        <Grid divided='vertically' columns={2}>
            <Grid.Row>
                <Grid.Column>
                    <span className="labelTitle">How did you hear about Student Membership?</span>
                    <span className="labelValue">
                        {initialValues.HearaboutStudentMembership}
                    </span>
                </Grid.Column>

            </Grid.Row>
        </Grid>
    </div>
)

export default MemberViewGeneralStudentInfo;
