export const HEADERINFO = [
    { 'title': 'Sub Committee', 'hasSorting': true, "sortKey": 'SubCommittee', 'orderBy': 1, 'orderByIcon': 1 },
    { 'title': 'Committee Title', 'hasSorting': true, 'sortKey': 'Committee Title', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Total Members', 'hasSorting': true, 'sortKey': 'Total Members', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Status', 'hasSorting': false, 'sortKey': 'Status', 'orderBy': 0, 'orderByIcon': 0 }
]

export const CommitteeStatusOptions = [
    { key: 0, text: "All", value: '0' },
    { key: 1, text: "Active", value: '1' },
    { key: 3, text: "Inactive", value: '3' }
]

export const CommitteeMemberStatusOptions = [
    { key: 0, text: "All", value: '0' },
    { key: 11, text: "Active", value: '11' },
    { key: 12, text: "Inactive", value: '12' }
]

export const CommitteeClassificationOptions = [
    { key: 0, text: 'All', value: 0 },
    { key: 1, text: 'Classified', value: 'c' },
    { key: 2, text: 'Unclassified', value: 'u' }
]

export const CommitteeClassificationOptionsWithSelect = [
    { key: 0, text: 'Select', value: 0 },
    { key: 1, text: 'Classified', value: 'c' },
    { key: 2, text: 'Unclassified', value: 'u' }
]

export const AddCommitteeFields = {
    Classification: 'Classification',
    Overview: 'Overview',
    Scope: 'Scope',
    Bylaws: 'Bylaws'
}

// We have kept the template and the fields hard coded, Please update all the occurences of the field names if there is any change required.
export const OverviewTemplate = '<p>ASTM Committee <a class="wysiwyg-mention" data-mention data-value="COMMITTEEDESIGNATION">@COMMITTEE DESIGNATION</a> on <a class="wysiwyg-mention" data-mention data-value="TITLE">@TITLE</a> was formed in <a class="wysiwyg-mention" data-mention data-value="YEARFORMED">@YEAR FORMED</a>. <a class="wysiwyg-mention" data-mention data-value="COMMITTEEDESIGNATION">@COMMITTEE DESIGNATION</a> meets <a class="wysiwyg-mention" data-mention data-value="NUMBEROFTIMESCOMMITTEEMEETS">@NUMBER OF TIMES COMMITTEE MEETS</a> times a year, in <a class="wysiwyg-mention" data-mention data-value="MEETING MONTHS">@MEETING MONTHS</a> with approximately <a class="wysiwyg-mention" data-mention data-value="NUMBEROFATTENDEES">@NUMBER OF ATTENDEES</a> members attending <a class="wysiwyg-mention" data-mention data-value="NUMBEROFMEETINGDAYS">@NUMBER OF MEETING DAYS</a> days of technical meetings. The Committee is comprised of <a class="wysiwyg-mention" data-mention data-value="NUMBEROFMEMBERS">@NUMBER OF MEMBERS</a> members and currently has jurisdiction of <a class="wysiwyg-mention" data-mention data-value="NUMBEROFSTANDARDS">@NUMBER OF STANDARDS</a> standards, published in the Annual Book of ASTM Standards, Volume <a class="wysiwyg-mention" data-mention data-value="VOLUMENUMBER">@VOLUME NUMBER</a>. <a class="wysiwyg-mention" data-mention data-value="COMMITTEEDESIGNATION">@COMMITTEE DESIGNATION</a> has <a class="wysiwyg-mention" data-mention data-value="NUMBEROFCOMMITTEESATNEXTLEVEL">@NUMBER OF COMMITTEES AT NEXT LEVEL</a> <a class="wysiwyg-mention" data-mention data-value="COMMITTEETYPE">@COMMITTEE TYPE</a> <a class="wysiwyg-mention" data-mention data-value="NEXTLEVELTITLE">@NEXT LEVEL TITLE</a> that maintain jurisdiction over these standards.</p>';

export const getStatusValueFromEligibilities = (eligibilities, fieldTitle) => {
    let result = true;

    let field = eligibilities.find(item => item.Title === fieldTitle);

    if (field) {
        result = field.Status;
    }

    return result;
}

export const MonthOptionsWithSelect = [
    { key: 0, text: 'Select', value: 0 },
    { key: 1, text: 'January', value: 1 },
    { key: 2, text: 'February', value: 2 },
    { key: 3, text: 'March', value: 3 },
    { key: 4, text: 'April', value: 4 },
    { key: 5, text: 'May', value: 5 },
    { key: 6, text: 'June', value: 6 },
    { key: 7, text: 'July', value: 7 },
    { key: 8, text: 'August', value: 8 },
    { key: 9, text: 'September', value: 9 },
    { key: 10, text: 'October', value: 10 },
    { key: 11, text: 'November', value: 11 },
    { key: 12, text: 'December', value: 12 }
]

export const CommitteeMeetingStatusList = [
    { key: 0, text: 'All', value: -1 },
    { key: 1, text: 'Active', value: 1 },
    { key: 2, text: 'Inactive', value: 0 }
]
export const CommitteeRosterVoteOptions = [
    { key: 0, text: 'All', value: '' },
    { key: 1, text: 'Yes', value: 1 },
    { key: 2, text: 'No', value: 0 }
]
export const CommitteeRosterFilter = {
    'MemberType': 1,
    'Classification': 2,
    'Vote': 3,
    'Status': 4
}

export const SubCommitteeGridHeaderInfo = [
    { 'title': 'Designation', 'width': '25%', 'hasSorting': true, "sortKey": 'Designation', 'orderBy': 1, 'orderByIcon': 1 },
    { 'title': 'Title', 'width': '60%', 'hasSorting': false, 'sortKey': '', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Total Members', 'width': '15%', 'hasSorting': true, 'sortKey': 'CommitteeMemberCount', 'orderBy': 0, 'orderByIcon': 0 }
]

export const CommitteeStatisticalBoxRedColorList = ["Producer Votes Available"]

export const CommitteeClassificationList = {
    Producer: "Producer",
    User: "User",
    General_Interest: "General Interest",
    Consumer: "Consumer",
    Unclassified: "Unclassified"
}
export const ComitteeClassificationBoxList = {
    Total_Members: "Total Members",
    Total_Standards: "Total Standards",
    Profiled_Members: "Profiled Members",
    Producer_Votes_Available: "Producer Votes Available"
}

export const CommitteeRosterOfficerGridHeaderInfo = [
    { 'title': 'Officer Title', 'width': '50%', 'hasSorting': true, "sortKey": 'OfficerTitleName', 'orderBy': 1, 'orderByIcon': 1 },
    { 'title': 'Linked', 'width': '10%', 'hasSorting': false, 'sortKey': '', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Assigned Officer(s)', 'width': '40%', 'hasSorting': false, 'sortKey': 'CommitteeMemberCount', 'orderBy': 0, 'orderByIcon': 0 }
]

export const AuditGridColumns = [
    { 'title': 'Modified Date/Time', 'hasSorting': true, 'className': "date", "sortKey": 'When', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Description', 'hasSorting': false, 'className': "description", 'sortKey': 'description', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Reason for Update', 'hasSorting': false, 'className': "reason", 'sortKey': 'description', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Modified By', 'hasSorting': true, 'className': "modifiedby", 'sortKey': 'Who', 'orderBy': 0, 'orderByIcon': 0 }
]

export const BylawsAuditFieldName = 'Bylaws';

export const CopyMembersModalLegendNote = 'Members from this Committee will be copied to another Committee.';

export const CopyMembersModalFootNote = 'If Classification of copied Members is \'Producer\' on a Subcommittee, the classification will be changed to \'Producer\' on the Main Committee (if not already assigned as Producer).';

export const commiteeInactiveReason = { DISCHARGED: '22', MERGED: '23' }


