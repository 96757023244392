import * as actions from 'action-types';
import _ from 'lodash';
import { connect } from "react-redux";
import feeGroupActions from '../actions/fee-group.actions';
import FeeGroup from '../index';

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        isDuplicate: state.feeGroupReducer.isDuplicate,
        countryList: state.commonReducer.countryList,
        currentSearchType: state.commonReducer.currentSearchType,
        currentModuleTitle: state.commonReducer.currentModuleTitle,
        activeCountryList: state.commonReducer.activeCountryList,
        originalActiveCountryList: state.commonReducer.originalActiveCountryList,
        usStateList: state.commonReducer.usStateList,
        canadaStateList: state.commonReducer.canadaStateList,
        userPermission: state.commonReducer.userPermission,
        ...state.commonReducer.PAGE_CONFIG
    }

};

const mapDispatchToProps = (dispatch) => ({
    getFeeGroupList: (callback) => { feeGroupActions.getFeeGroupListAction(callback, dispatch); },
    getFeeGroupTitleExist: (data, callback) => feeGroupActions.getFeeGroupTitleExistAction(data, dispatch, callback),
    postFeeGroup: (data, callback) => feeGroupActions.postFeeGroupAction(dispatch, data, callback),
    resetFeeGroup: () => dispatch({ type: actions.FEEGROUP_RESET })
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeGroup);
