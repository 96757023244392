
import { encrypt, formatListDataForAutoSearch } from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds } from 'models/common.models';
import { change } from 'redux-form';
import { CommitteeStatusOptions } from '../../committee.model';

let self = {};
let TIMEOUT = null;
let prevDesignation = '';
let prevTitle = '';

export const setClassInstance = (instance) => {
    self = instance;

    // Set initial Data
    prevDesignation = '';
    prevTitle = '';

    if (!self.props.committeeTypeList || !self.props.hierarchyListForFilter || self.props.allCommitteesHierarchyDetails) {
        self.props.getMasterDataForCommitteeList();
    }
}

export const getCommitteeDesignationList = (e, value) => {
    const trimmedValue = value.trim();

    if (trimmedValue === prevDesignation.trim()) {
        return;
    }

    clearTimeout(TIMEOUT);

    self.setState({
        isLoadingDesignationList: trimmedValue !== '',
        showDesignationErrorMsg: false
    });

    TIMEOUT = setTimeout(() => {
        if (trimmedValue.length > 0) {
            self.setState({ designationList: [] });

            self.props.getDesignationList(trimmedValue, (response) => {

                let formattedList = formatListDataForAutoSearch(response);

                self.setState({
                    showDesignationErrorMsg: formattedList.length === 0,
                    isLoadingDesignationList: false,
                    designationList: formattedList
                });
            })
        } else {
            self.setState({ isLoadingDesignationList: false, designationList: [] });
        }

        prevDesignation = value;
    }, 300);
}

export const getCommitteeTitleList = (e, value) => {
    value = value.toLowerCase();
    const trimmedValue = value.trim();

    if (trimmedValue === prevTitle.trim()) {
        return;
    }

    clearTimeout(TIMEOUT);

    self.setState({
        isLoadingTitleList: trimmedValue !== '',
        showTitleErrorMsg: false
    });

    TIMEOUT = setTimeout(() => {

        // Check if we need to pull data from API rather than filtering the existing list
        if (prevTitle.trim() !== '' && trimmedValue !== '' && value.length > prevTitle.length &&
            value.indexOf(prevTitle) === 0 && self.state.titleList.length > 0) {
            let { titleList } = self.state;

            titleList = titleList.filter(item => {
                return item.title.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0;
            })

            self.setState({
                titleList,
                isLoadingTitleList: false,
                showTitleErrorMsg: titleList.length === 0
            });
        } else if (trimmedValue.length > 0) {
            self.setState({ titleList: [] });

            self.props.getTitleList(trimmedValue, (response) => {
                let formattedList = formatListDataForAutoSearch(response);

                self.setState({
                    showTitleErrorMsg: formattedList.length === 0,
                    isLoadingTitleList: false,
                    titleList: formattedList
                });
            })
        } else {
            self.setState({ isLoadingTitleList: false, titleList: [] });
        }

        prevTitle = value;
    }, 300);
}

export const onChangeCommitteeType = (e, value) => {
    // Change the hierarchy level list
    self.props.changeCommitteeHierarchy(value);
    self.setState({ selectedHierarchy: [] });
}

export const onChangeHierarchy = (value) => {
    self.setState({ selectedHierarchy: value });
}

// Reset the entire grid
export const resetForm = () => {
    Object.keys(self.props.initialValues).forEach(fieldKey => {
        let value = '';
        if (fieldKey === 'Status') {
            value = CommitteeStatusOptions[1].value;
        }
        self.props.dispatch(change(self.props.form, fieldKey, value));
    });
    self.setState({ selectedHierarchy: [] });

    const searchQuery = getSearchQuery({});
    self.props.onResetClick(searchQuery);
}

export const onSubmitHandler = (values, dispatch, props) => {
    const searchQuery = getSearchQuery(values, self.state.selectedHierarchy);
    props.onAdvanceSearchSubmit(searchQuery);
}

const getSearchQuery = (values, selectedHierarchy) => {
    let queries = [];
    let hierarchy = selectedHierarchy && selectedHierarchy.length > 0 ? selectedHierarchy : 'all';
    let status = values.Status ? values.Status : '1';

    queries.push(`${AdvanceSearchQueryFields.IsAdvanceSearch}=true`);
    queries.push(`${AdvanceSearchQueryFields.GridId}=${AppGridIds.CommitteeGrid}`);

    if (values.CommitteeDesignation) {
        queries.push(`${AdvanceSearchQueryFields.CommitteeDesignation}=${encrypt(values.CommitteeDesignation.trim())}`);
    }
    if (values.CommitteeTitle) {
        queries.push(`${AdvanceSearchQueryFields.CommitteeTitle}=${encrypt(values.CommitteeTitle.trim())}`);
    }

    queries.push(`${AdvanceSearchQueryFields.Status}=${status}`);

    queries.push(`${AdvanceSearchQueryFields.CommitteeLevel}=${hierarchy}`);

    if (values.Classification) {
        queries.push(`${AdvanceSearchQueryFields.Classification}=${values.Classification}`);
    }
    if (values.CommitteeType) {
        queries.push(`${AdvanceSearchQueryFields.CommitteeType}=${values.CommitteeType}`);
    }

    return queries.join('&');
}
