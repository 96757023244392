export const FeeGroupTableColumns = [
  {
    title: "Fee Group Number",
    hasSorting: true,
    className: "feeGroupNumber",
    sortKey: "FeeGroupNumber",
    orderBy: 1,
    orderByIcon: 1,
  },
  {
    title: "Fee Group Title",
    hasSorting: true,
    className: "feeGroupTitle",
    sortKey: "FeeGroupTitle",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Contact Name",
    hasSorting: true,
    className: "contactName",
    sortKey: "ContactPersonName",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Associated Members",
    hasSorting: true,
    className: "associatedMembers",
    sortKey: "AssociatedMembers",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Paid Status",
    hasSorting: true,
    className: "paidStatus",
    sortKey: "PaidStatus",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Status",
    hasSorting: true,
    className: "status",
    sortKey: "Status",
    orderBy: 0,
    orderByIcon: 0,
  },
];

export const StatusFilter = [
  { key: 1, text: "All", value: "All" },
  { key: 2, text: "Active", value: "Active" },
  { key: 3, text: "Inactive", value: "Inactive" },
];

export const AutoRenewal = [
  { key: 1, text: "All", value: "All" },
  { key: 2, text: "Yes", value: "Yes" },
  { key: 3, text: "No", value: "No" },
];

export const PaidStatus = [
  { key: 1, text: "All", value: "All" },
  { key: 2, text: "Paid", value: "Paid" },
  { key: 3, text: "Partial Paid", value: "Partial Paid" },
  { key: 4, text: "Not Paid", value: "Not Paid" },
];

export const FeeGroupDetailsActiveTabIndex = {
  DETAILS: 0,
  ASSOCIATEDMEMBERS: 1,
};

export const FeeGroupAssociatedMemberTableColumns = [
  {
    title: "Account",
    hasSorting: true,
    className: "account",
    sortKey: "AccountNumber",
    orderBy: 1,
    orderByIcon: 1,
  },
  {
    title: "Name",
    hasSorting: true,
    className: "name",
    sortKey: "Name",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Member Type",
    hasSorting: true,
    className: "memberType",
    sortKey: "MembershipTypeName",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Email",
    hasSorting: true,
    className: "email",
    sortKey: "Email",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Organization",
    hasSorting: true,
    className: "organization",
    sortKey: "Organization",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Status",
    hasSorting: true,
    className: "status",
    sortKey: "Status",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Paid Status",
    hasSorting: true,
    className: "paidStatus",
    sortKey: "PaidStatus",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Association Date",
    hasSorting: true,
    className: "associationDate",
    sortKey: "AssociationDate",
    orderBy: 0,
    orderByIcon: 0,
  },
];

export const feeGroupExportFormat = {
  template: "template",
  invoice: "invoice",
};

export const feeGroupSearchType = {
  advance: "advance",
  basic: "basic",
  noSearch: "",
};

export const RefreshAssociatedMemberHeader = () => {
  FeeGroupAssociatedMemberTableColumns.map((item) => {
    item.orderBy = item.sortKey === "AccountNumber" ? 1 : 0;
    item.orderByIcon = item.sortKey === "AccountNumber" ? 1 : 0;
  });
};

export const AuditGridColumns = [
  {
    title: "Modified Date/Time",
    hasSorting: true,
    className: "date",
    sortKey: "When",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Description",
    hasSorting: false,
    className: "description",
    sortKey: "description",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Reason for Update",
    hasSorting: false,
    className: "reason",
    sortKey: "description",
    orderBy: 0,
    orderByIcon: 0,
  },
  {
    title: "Modified By",
    hasSorting: true,
    className: "modifiedby",
    sortKey: "Who",
    orderBy: 0,
    orderByIcon: 0,
  },
];

export const FEE_GROUP_MODULE_ID = "26";
