import { getMessage, removeScript } from 'helpers/util-common';
import { AppGridIds, ItemsPerPage } from 'models/common.models';
import React, { Component } from "react";
import { Confirm, Loader } from "semantic-ui-react";
import CustomDataGrid from 'shared-components/AdvancedGrid';
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import * as _func from './function';
import { MemberStatusOptions, MemberPaidStatusOptions } from './members.model';

class MemberList extends Component {
    membershipTypeList = [];

    constructor(props) {
        super(props);

        const self = this;
        self.state = {
            showLoader: false,
            totalPages: 0,
            totalItems: 0,
            activePageNumber: 1,
            selectedMemberType: 0,
            selectedCountry: 'All',
            selectedStatus: 17, // Default Active members are displayed
            selectedPaidStatus: MemberPaidStatusOptions[0].value,
            pageSize: 25,
            currentlyShowingItems: '',
            sortedColumn: '',
            sortOrder: 0,
            searchText: props.location.search.length > 0 ? props.location.search.substring(1) : '',
            isDataUpdated: false,
            // Exportable Excel Variable Setting Start with grid
            isExcelEmpty: false,
            isWordEmpty: false,
            tableHeaderConfig: [],
            isTableHeaderReset: true,
            isShowGridMenu: false,
            isFilterChanged: false,
            gridProp: {
                records: [],
                attributes: {},
                expandables: [],
                sortables: {},
                omitOnMenu: [],
                excelExportedColumn: [],
                emptyMessage: null,
                excelExportableFieldsCallback: null,
                displayName: {},
                requestSaveParams: {}
            },
            hasExportAccess: false
        };
        this.resetDataUpdatedFlag = this.resetDataUpdatedFlag.bind(this);
    }

    componentDidMount() {
        _func.setClassInstance(this);

        const { searchText } = this.state;
        const selectedMemberType = _func.getSelectedMemberTypesFromUrl(searchText);
        const selectedCountry = _func.getCountryFromUrl(searchText);
        const selectedStatus = _func.getStatusFromUrl(searchText);
        const selectedPaidStatus = _func.getPaidStatusFromUrl(searchText);
        this.setState({
            selectedMemberType: Number(selectedMemberType),
            selectedCountry,
            selectedStatus: Number(selectedStatus),
            selectedPaidStatus
        }, () => this.isHeaderSetted(true));
    }

    isHeaderSetted = (isSet) => {
        const { pageSize, searchText, selectedMemberType, selectedCountry, selectedStatus, selectedPaidStatus } = this.state;
        if (isSet) {
            _func.loadData(1, pageSize, searchText, '', 0, Number(selectedMemberType), Number(selectedStatus), selectedCountry, selectedPaidStatus, true, true)
        }
    }

    componentWillUpdate(nextProps) {
        _func.handleComponentWillUpdate(nextProps);
    }

    onChangeMemberType = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedStatus, selectedCountry, selectedPaidStatus } = this.state;

        _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, value, selectedStatus, selectedCountry, selectedPaidStatus);
        this.setState({ selectedMemberType: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true });

    }

    onChangeCountry = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedMemberType, selectedStatus, selectedPaidStatus } = this.state;

        _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, value, selectedPaidStatus);
        this.setState({ selectedCountry: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true });

    }

    onChangeStatusType = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedMemberType, selectedCountry, selectedPaidStatus } = this.state;

        _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, value, selectedCountry, selectedPaidStatus);
        this.setState({ selectedStatus: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true });
    }

    onChangePaidStatus = (e, value) => {
        const { pageSize, sortedColumn, sortOrder, searchText, selectedMemberType, selectedStatus, selectedCountry } = this.state;

        _func.loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, value);
        this.setState({ selectedPaidStatus: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true });

    }

    onChangeNumberOfItemsPerPage = (e, value) => {
        const { sortedColumn, sortOrder, searchText, selectedMemberType, selectedCountry, selectedStatus, selectedPaidStatus } = this.state;

        _func.loadData(1, value, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus);
        this.setState({ pageSize: value, activePageNumber: 1, isTableHeaderReset: false });
    }

    onChangeSortHandler = (sortedColumn, sortOrder) => {
        const { activePageNumber, pageSize, searchText, selectedMemberType, selectedCountry, selectedStatus, selectedPaidStatus } = this.state;
        this.setState({ sortedColumn, sortOrder, isTableHeaderReset: false });

        // Load new data
        _func.loadData(activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus);
    }

    onPageChangeHandler = (event, data) => {
        const { pageSize, searchText, sortedColumn, sortOrder, totalPages, selectedMemberType, selectedCountry, selectedStatus, selectedPaidStatus } = this.state;

        if (totalPages > 1) {
            _func.loadData(data.activePage, pageSize, searchText, sortedColumn, sortOrder, selectedMemberType, selectedStatus, selectedCountry, selectedPaidStatus);
        }

        this.setState({
            activePageNumber: data.activePage,
            isTableHeaderReset: false
        })
    }

    shouldComponentUpdate = () => {
        this.membershipTypeList = _func.filterMembershipTypes()
        return true;
    }
    resetDataUpdatedFlag = () => {
        this.setState({ isDataUpdated: false, isTableHeaderReset: true });
    }
    componentWillUnmount() {
        removeScript();
    }
    render() {
        const { isShowGridMenu, isWordEmpty, isExcelEmpty, showLoader, gridProp, totalItems, totalPages, activePageNumber, selectedMemberType, selectedStatus, selectedPaidStatus, pageSize, currentlyShowingItems, isDataUpdated, selectedCountry, hasExportAccess } = this.state;
        const { messageCodes, PAGE_TYPE, USER_PRIVILEGE, uniqueKey, countryList } = this.props;

        return (
            <div data-testid='memberListComponent'>
                {showLoader && (
                    <Loader size='small' className="small-loader" data-testid='loader'>Loading</Loader>
                )}
                <div className='ui memberPage memberListPage'>
                    <div className='headingTitle clearfix'>
                        <h2>Members</h2>
                    </div>

                    <div className="clearfix member-header">
                        <div className="display-inline-block mr20">
                            <label className="label-inline" for="memberType">Member Type</label>
                            <RenderSelect
                                name="memberType"
                                id="memberType"
                                placeholder="All"
                                value={selectedMemberType}
                                onChange={this.onChangeMemberType}
                                options={this.membershipTypeList}
                            />
                        </div>
                        <div className="display-inline-block mr20">
                            <label className="label-inline" for="country">Country</label>
                            <RenderSelect name="country"
                                id="country"
                                placeholder="All"
                                value={selectedCountry}
                                onChange={this.onChangeCountry}
                                options={countryList}
                            />
                        </div>
                        <div className="display-inline-block mr20">
                            <label className="label-inline" for="accountType">Account Status</label>
                            <RenderSelect
                                name="accountType"
                                id="accountType"
                                placeholder="Active"
                                value={selectedStatus}
                                onChange={this.onChangeStatusType}
                                options={MemberStatusOptions}
                            />
                        </div>
                        <div className="display-inline-block mr20" data-testid="paidStatusMember">
                            <label className="label-inline" for="paidStatus">Paid Status</label>
                            <RenderSelect data-testid="paidStatus"
                                placeholder="All"
                                name="paidStatus"
                                id="paidStatus"
                                value={selectedPaidStatus}
                                onChange={this.onChangePaidStatus}
                                options={MemberPaidStatusOptions}
                            />
                        </div>
                        {hasExportAccess && (
                            <div className="display-inline-block floatRight">
                                <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                                <button style={{ pointerEvents: showLoader ? 'none' : 'auto' }} onClick={_func.getMemberExportedExcel} className="ui button secondary mr10" data-testid="btnDownloadExcel"><i className="icon file excel mr5"></i>Excel</button>
                                <button style={{ pointerEvents: showLoader ? 'none' : 'auto' }} className="ui button secondary" onClick={_func.getMemberExportedWord} data-testid="btnDownloadWord"><i className="icon file word mr5"></i>Word</button>
                            </div>
                        )}
                    </div>

                    <div className="relative">
                        {gridProp.excelExportedColumn !== null && gridProp.excelExportedColumn.length > 0 && (
                            <CustomDataGrid isShowGridMenuEvent={isShowGridMenu} gridId={AppGridIds.MemberGrid}
                                membershipTypeList={this.membershipTypeList}
                                detailsPageUrl={uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)} detailsPageUrl2={uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)}
                                isDataUpdated={isDataUpdated} resetDataUpdatedFlag={this.resetDataUpdatedFlag}
                                gridSetting={gridProp} onChangeSort={this.onChangeSortHandler}
                                saveGridPerferenceClickAction={_func.saveGridPreferenceClickAction} />
                        )}
                    </div>
                    {totalItems > 0 && (
                        <div className="pagerWrap" id="pagerWrap" data-testid="pagerWrap">
                            <div className="pager">
                                <PaginationShorthand defaultActivePage={activePageNumber} totalPages={totalPages}
                                    onPageChange={this.onPageChangeHandler} />
                                <div>
                                </div>
                            </div>
                            <div className="itemPerPage">
                                <RenderSelect
                                    name="itemsPerPage"
                                    value={pageSize}
                                    onChange={this.onChangeNumberOfItemsPerPage}
                                    options={ItemsPerPage}
                                />
                                <span className="itemsPerPage">items per page</span>
                            </div>

                            <div className="totalPage">{currentlyShowingItems}</div>

                        </div>
                    )}

                </div>
                <Confirm
                    className="confirm-box"
                    open={isExcelEmpty}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyExcelCancelHandler}
                    onConfirm={_func.emptyExcelConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                <Confirm
                    className="confirm-box"
                    open={isWordEmpty}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyWordCancelHandler}
                    onConfirm={_func.emptyWordConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        );
    }
}

export default MemberList;
