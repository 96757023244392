import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { getHighlightedHTMLElement } from 'helpers/util-common';

let searchValue = '';

// Auto search for redux form
class CustomRendererSearch extends Component {

    resultRenderer = (value) => {
        let highlightedOrgNumberElement = getHighlightedHTMLElement(value.OrgAccountNumber, searchValue);
        let highlightedOrgNameElement = getHighlightedHTMLElement(value.OrgName, searchValue);
        let renderElement = '';

        if (value && value.RepName && value.RepName.length > 0) {
            renderElement = `<p>${highlightedOrgNumberElement} &bull; ${highlightedOrgNameElement} &bull; <span className="organizationName">${value.RepName} (Associated Rep)</span></p>`;
        } else {
            renderElement = `<p>${highlightedOrgNumberElement} &bull; ${highlightedOrgNameElement}</p>`;
        }

        return <div dangerouslySetInnerHTML={{ __html: renderElement }}></div>
    }

    handleResultSelect = (e, { result }) => {
        this.props.onResultSelect(result);
    }

    handleSearchChange = (e) => {
        searchValue = e.target.value.trim();
        this.props.input.onChange(e.target.value);
    }

    render() {
        const { label, input: { name, value }, placeholder, className, isLoading, source, showNoResults, meta: { touched, error },disabled } = this.props;
        searchValue = value;

        return (
            <div>
                <label className="label" htmlFor={name}>{label}</label>
                <Search
                    loading={isLoading}
                    name={name}
                    id={name}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                    results={source}
                    value={value}
                    showNoResults={showNoResults}
                    placeholder={placeholder}
                    resultRenderer={this.resultRenderer}
                    className={className}
                    disabled={disabled}
                />

                {touched && (error && <span className="errorMessage mt10">{error}</span>)}
            </div >
        )
    }
}
export default CustomRendererSearch;
