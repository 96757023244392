
import React from "react";
import { Message, Icon } from 'semantic-ui-react';

const SafariAutofillMessage = ({ ...props }) => {
    const { isSafariAutoFillInfoVisible, setSafariAutoFillInfoHandler } = props;
    return (
        isSafariAutoFillInfoVisible ?
            <div className="top-message">
                <Message color='teal' onDismiss={(e) => setSafariAutoFillInfoHandler(false)}>
                    <Icon name='exclamation circle' />
                    <span style={{ color: '#006b98' }}>We are assuming that Autofill settings are turned on for Safari browser (Safari > Preferences > AutoFill). Please turn off the settings for the best site experience or use a different browser.</span>
                </Message>
            </div>
            : null
    )
}

export default SafariAutofillMessage;
