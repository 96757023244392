import * as types from 'action-types';
import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import { cacheManager, encrypt } from 'helpers/util-common';
import { AppGridIds } from 'models/common.models';

const getCommitteeListAction = (query, showLoader, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getCommitteeList');

    if (query.length > 0) {
        url = `${url}/${query}`;
        cacheManager.setItem('exportedParams', query);
    }

    if (showLoader) {
        dispatch(commonActions.setLoader(true));
    }

    axios.get(url)
        .then((response) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }
            dispatch(commonActions.setMessage(true, '3062'));
        });
}

const getCommitteeListAndGridPreferencesAction = (query, showLoader, gridId, callback, dispatch) => {
    let committeeListUrl = getApiUrl('manageCommittee', 'getCommitteeList');
    const committeeGridPreferencesUrl = `${getApiUrl('userPermission', 'getGridUserPreferences')}/${gridId}`;

    if (query.length > 0) {
        committeeListUrl = `${committeeListUrl}/${query}`;
        cacheManager.setItem('exportedParams', query);
    }

    if (showLoader) {
        dispatch(commonActions.setLoader(true));
    }

    Promise.all([axios.get(committeeListUrl), axios.get(committeeGridPreferencesUrl)])
        .then(([committeeResponse, committeeGridPreferenceResponse]) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }
            if (committeeResponse.data.status) {
                callback({
                    committeeList: committeeResponse.data.content,
                    committeeGridPreference: committeeGridPreferenceResponse.data.content
                });

                if (gridId === AppGridIds.CommitteeGrid) {
                    dispatch({ type: types.GET_COMMITTEE_GRID_PREFERENCES, committeeGridPreferences: committeeGridPreferenceResponse.data.content });
                }
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, committeeResponse.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3072'));
        });
}

const getMasterDataForCommitteeListAction = (dispatch) => {
    const committeeTypeListUrl = getApiUrl('manageCommittee', 'getCommitteeTypeList');
    const committeeLevelListUrl = getApiUrl('manageCommittee', 'getAllCommitteeLevel');
    const allCommitteesHierarcyDetailsUrl = getApiUrl('manageCommittee', 'getAllCommitteeHierarchy');
    const overviewTagFieldsUrl = getApiUrl('manageCommittee', 'getCommitteeOverviewTemplateField');
    Promise.all([axios.get(committeeTypeListUrl), axios.get(committeeLevelListUrl), axios.get(allCommitteesHierarcyDetailsUrl), axios.get(overviewTagFieldsUrl)])
        .then(([committeeTypeListResponse, committeeLevelListResponse, allCommitteesHierarcyDetailsResponse, overviewTagFieldsResponse]) => {
            let committeeLevelList = [];
            let committeeTypeList = [];
            let allCommitteesHierarchyDetails = [];
            let overviewTagFieldList = [];

            if (committeeTypeListResponse.data.status) {
                committeeTypeList = committeeTypeListResponse.data.content;
            } else {
                dispatch(commonActions.setMessage(true, committeeTypeListResponse.data.message));
            }

            if (committeeLevelListResponse.data.status) {
                committeeLevelList = committeeLevelListResponse.data.content;
            } else {
                dispatch(commonActions.setMessage(true, committeeLevelListResponse.data.message));
            }

            if (allCommitteesHierarcyDetailsResponse.data.status) {
                allCommitteesHierarchyDetails = allCommitteesHierarcyDetailsResponse.data.content;
            } else {
                dispatch(commonActions.setMessage(true, allCommitteesHierarcyDetailsResponse.data.message));
            }

            if (overviewTagFieldsResponse.data.status) {
                overviewTagFieldList = overviewTagFieldsResponse.data.content;
            } else {
                dispatch(commonActions.setMessage(true, overviewTagFieldsResponse.data.message));
            }

            dispatch({ type: types.GET_COMMITTEE_LIST_MASTER_DATA, committeeTypeList, committeeLevelList, allCommitteesHierarchyDetails, overviewTagFieldList });

        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3072'));
        });
}

const getDesignationListAction = (searchText, callback) => {
    const url = `${getApiUrl('manageCommittee', 'getCommitteeDesignation')}${encrypt(searchText)}`;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback([]);
            }
        })
        .catch((err) => {
            callback([]);
        });
}

const getTitleListAction = (searchText, callback) => {
    const url = `${getApiUrl('manageCommittee', 'getCommitteeTitle')}${encrypt(searchText)}`;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback([]);
            }
        })
        .catch((err) => {
            callback([]);
        });
}

const getCommitteesForSearchAction = (query, callback) => {
    const url = `${getApiUrl('manageCommittee', 'getCommitteesForSearch')}${query}`;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback([]);
            }
        })
        .catch((err) => {
            callback([]);
        });
}

const isAlreadyExistsCommitteeDesignationAction = (query, callback, dispatch) => {
    const url = `${getApiUrl('manageCommittee', 'isAlreadyExistsCommitteeDesignation')}${query}`;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3092'));
        });
}

const addCommitteeAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'addCommittee');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(true);
                dispatch(commonActions.setMessage(true, '2023'));
            } else if (response.data.message === 4012) {
                callback(false);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3093'));
        });
}

const getCommmitteeExportedDataAction = (dispatch, callback, query) => {
    let url = getApiUrl('manageCommittee', 'committeeExportFile');
    url = `${url}/${query}`;
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3038'));
        });
}

const getBosVolumeForCommitteeAction = (committeeId, callback, dispatch) => {
    dispatch(commonActions.setLoader(true));
    const url = `${getApiUrl('manageCommittee', 'getBosVolumeForCommittee')}/${committeeId}`;
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3148'));
        });
}

export default {
    getCommitteeListAction,
    getMasterDataForCommitteeListAction,
    getDesignationListAction,
    getTitleListAction,
    getCommitteesForSearchAction,
    isAlreadyExistsCommitteeDesignationAction,
    addCommitteeAction,
    getCommmitteeExportedDataAction,
    getCommitteeListAndGridPreferencesAction,
    getBosVolumeForCommitteeAction
}
