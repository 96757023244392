import { copyDataToClipboard, encrypt } from 'helpers/util-common';
import React, { Component } from "react";
import { Form, Grid, Icon } from 'semantic-ui-react';
import { COMPANY_TYPE } from '../../../../../models/common.models';
import MemberAccountHistoryModal from '../../../members/components/member-account-history-modal';
import OrganizationEditAccountDetails from '../organization-edit-account-details';
import OrganizationEditGenInfo from '../organization-edit-gen-info';
import OrganizationInfoEdit from '../organization-information-edit';
import OrganizationRepHistoryModal from '../organization-rep-history';
import OrganizationViewAccountDetails from '../organization-view-account-details';
import OrganizationViewGeneralInfo from '../organization-view-gen-info';
class OrganizationPersonalDetails extends Component {

    state = {
        isEditGenInfo: false,
        isEditAccountInfo: false,
        isRepHistroyModalOpen: false,
        showAccountStatusModal: false,
        isEditFacility: false
    }

    openRepHistoryModal = (e) => {
        this.setState({ isRepHistroyModalOpen: true })
    }

    closeRepHistoryModal = (e) => {
        this.setState({ isRepHistroyModalOpen: false })
    }

    copyData = () => {
        const { initialValues } = this.props;
        const orgName = initialValues.FacilityName ? initialValues.FacilityName : '';
        const address = initialValues && initialValues.FullAddress ? initialValues.FullAddress : '';
        let copiedData = orgName;

        if (address) {
            copiedData = copiedData + '\r\n' + address;
        }

        const isCopiedSuccess = copyDataToClipboard(copiedData);

        if (isCopiedSuccess) {
            this.props.showSuccessCopyMessage();
        }
    }

    editGenInfo = () => {
        this.setState({ isEditGenInfo: true })
    }

    closeEditGenInfo = () => {
        this.setState({ isEditGenInfo: false })
    }

    editAccountInfo = () => {
        this.setState({ isEditAccountInfo: true });
    }

    closeAccountInfo = () => {
        this.setState({ isEditAccountInfo: false });
    }

    openAccountStatusModal = () => {
        const companyId = this.props.initialValues.OrganizationalMemberId;
        const accountNumber = this.props.initialValues.AccountNumber ? this.props.initialValues.AccountNumber : encrypt(companyId);
        this.props.getOrganizationAccountHistoryDetails(accountNumber, this.props.accountStatusAuditFieldName, this.props.paidStatusAuditFieldName);
        this.setState({ showAccountStatusModal: true });
    }

    closeAccountHistoryModal = () => {
        this.setState({ showAccountStatusModal: false });
    }
    handleEditFacilityClick = () => {
        this.setState({ isEditFacility: true }, () => {
            this.props.setSafariAutoFillInfo(true);
        })
    }
    onCloseModal = () => {
        this.setState({ isEditFacility: false });
    }
    render() {
        const { initialValues, organizationRepHistoryList, userAccess, organizationAccountHistoryList, memberMasterDetails } = this.props;
        const { isEditGenInfo, isEditAccountInfo, isRepHistroyModalOpen, showAccountStatusModal, isEditFacility } = this.state;

        let webUrlLink = initialValues.WebUrl && initialValues.WebUrl.toLowerCase().indexOf('http') === 0 ? initialValues.WebUrl : 'http://' + initialValues.WebUrl;
        return (
            <div className="memberPersonalDetail">
                {initialValues && (initialValues.AccountNumber || initialValues.OrganizationalMemberId) && (
                    <>
                        <Form>
                            <section>
                                {initialValues && initialValues.StatusName === 'Active' && userAccess.hasUpdateAccess && <div><h5 className="txtRight"><a title="Edit"  data-testid="editBtn" className="editBtn" onClick={this.handleEditFacilityClick}>
                                    <Icon name="pencil" />
                                        </a>
                                        </h5></div>
                                }
                                <Grid divided='vertically' columns={4}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <span className="labelTitle">Account Number</span>
                                            <span className="labelValue">
                                                {initialValues.AccountNumber ? initialValues.AccountNumber : "##"}
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="labelTitle">Name</span>
                                            <span className="labelValue">
                                                <span class="inlineEditBtn">
                                                    {initialValues.FacilityName}
                                                </span>
                                            </span>
                                        </Grid.Column>
                                        {initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY ? (
                                            <>
                                                <Grid.Column>
                                                    <span className="labelTitle">Join Date</span>
                                                    <span className="labelValue">
                                                        {initialValues.JoinDate}
                                                    </span>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <span className="labelTitle">Web URL</span>
                                                    <span className="labelValue">
                                                        {initialValues.WebUrl && (
                                                            <a href={webUrlLink} className="linkTxt" rel="noopener noreferrer" target='_blank'>{initialValues.WebUrl}</a>
                                                        )}
                                                    </span>
                                                </Grid.Column>
                                            </>
                                        ) : ''}
                                        <Grid.Column>
                                            <span className="labelTitle">Address</span>
                                            <span className="labelValue">{initialValues && initialValues.FullAddress ? initialValues.FullAddress : ''}</span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="labelTitle">Email</span>
                                            <span className="labelValue">
                                                <a href={`mailto:${initialValues.Email}`}>{initialValues.Email}</a>
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="labelTitle">Phone</span>
                                            <span className="labelValue">{initialValues && initialValues.PhoneNumber ? initialValues.PhoneNumber : ''}</span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="labelTitle">Fax</span>
                                            <span className="labelValue">{initialValues && initialValues.FaxNumber ? initialValues.FaxNumber : ''}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <button data-testid='btnCopyData' className="ui button secondary" onClick={this.copyData}><Icon name="copy" />Copy</button>
                            </section>
                            
                            {initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY ? (
                                <section>
                                    <div className="dashed-border mt20 mb20"></div>
                                    {!isEditGenInfo ?
                                        <OrganizationViewGeneralInfo {...this.props} openRepHistoryModalEvent={this.openRepHistoryModal} onEditGenInfo={this.editGenInfo} />
                                        : <OrganizationEditGenInfo {...this.props} onCloseEditGenInfo={this.closeEditGenInfo}></OrganizationEditGenInfo>
                                    }

                                </section>
                            ) : ''}
                            
                            <section>
                                <div className="dashed-border mt20 mb20"></div>
                                {!isEditAccountInfo ? (
                                    <OrganizationViewAccountDetails initialValues={initialValues} onEditAccountInfo={this.editAccountInfo} onClickAccountStatusHistory={this.openAccountStatusModal} hasUpdateAccess={userAccess.hasUpdateAccess} />
                                ) : <OrganizationEditAccountDetails {...this.props} onCloseEditAccountInfo={this.closeAccountInfo} hasUpdatePaidStatusAccess={userAccess.hasUpdatePaidStatusAccess}  />
                                }
                            </section>
                        </Form>
                    </>
                )}

                {isRepHistroyModalOpen &&
                    <OrganizationRepHistoryModal list={organizationRepHistoryList} closeRepHistoryModalEvent={this.closeRepHistoryModal} {...this.props} />
                }

                {showAccountStatusModal && (
                    <MemberAccountHistoryModal label="Organization Status History" hasUserPageAccess={userAccess.hasUserPageAccess}
                        memberAccountHistoryList={organizationAccountHistoryList} onCloseAccountHistoryModal={this.closeAccountHistoryModal} {...this.props} />
                )}
                <OrganizationInfoEdit
                    {...this.props}
                    isEditFacility={isEditFacility}
                    onCloseModal={this.onCloseModal}
                />
            </div>
        );
    }
}

export default OrganizationPersonalDetails;
