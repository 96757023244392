import * as utilCommon from 'helpers/util-common';
import lodash from 'lodash';
import { CountryCodes } from 'models/common.models';
import { AlphaNumeric_Regex } from '../../../../../helpers/util-common';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
    utilCommon.setStateListData(self.props.initialValues.Country, self)
    self.setState({ countryList: utilCommon.formatCountryList(self.props.activeCountryList, true) })
}

export const validateHandler = (values, props) => {
    const errors = {};

    if (!values.FeeGroupTitle || !(values.FeeGroupTitle || '').trim()) {
        props.resetFeeGroup();
        errors.FeeGroupTitle = utilCommon.getMessage(props.messageCodes, '8147.text');
    }
    if (values.ContactEmailAddress && !utilCommon.validateEmail(values.ContactEmailAddress)) {
        errors.ContactEmailAddress = utilCommon.getMessage(props.messageCodes, '3128.text');
    }
    if (!values.UpdateReason || !(values.UpdateReason || '').trim()) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text');
    }
    if (values.Country && values.Country.toLowerCase() === CountryCodes.UNITEDSTATES.Name.toLowerCase() && values.ContactPhoneNumber && values.ContactPhoneNumber.length < 10) {
        errors.ContactPhoneNumber = utilCommon.getMessage(props.messageCodes, '4023.text').replace('<number>', '10');;
    }
    if (values.Address2 && (!AlphaNumeric_Regex.test(values.Address2) || values.Address2.length > 10)) {
        errors.Address2 = utilCommon.getMessage(props.messageCodes, '4001.text');
    }
    if (!values.Country || values.Country === '-1' || values.Country == 0) {
        errors.Country = utilCommon.getMessage(props.messageCodes, '8167.text');
    }
    return errors;
}

export const updateFeeGroup = (value) => {
    const { stateList } = self.state;
    const { activeCountryList, initialValues } = self.props

    let reqData = {
        FeeGroupId: initialValues.FeeGroupId || 0,
        FeeGroupTitle: value.FeeGroupTitle,
        Organization: value.Organization,
        Address1: value.Address1 || '',
        Address2: value.Address2 || '',
        Country: (activeCountryList && activeCountryList.length > 0 && activeCountryList.filter(item => item.value.toLowerCase() === (value.Country || '').toLowerCase()).length > 0) ? (value.Country || '') : value.Country || CountryCodes.UNITEDSTATES.Name,
        State: (stateList && stateList.length > 0 && stateList.filter(item => item.value.toLowerCase() === (value.State || '').toLowerCase()).length > 0) ? (value.State || '') : value.State || '',
        City: value.City || '',
        PostalCode: value.PostalCode || '',
        IsAutoRenewal: value.IsAutoRenewal || false,
        IsSuppressFeeRenewalEmail: value.IsSuppressFeeRenewalEmail || false,
        IsSuppressFeeRenewalPrint: value.IsSuppressFeeRenewalPrint || false,
        ContactPersonName: value.ContactPersonName,
        ContactEmailAddress: value.ContactEmailAddress,
        ContactPhoneNumber: value.ContactPhoneNumber,
        ContactExtension: value.ContactExtension,
        Status: 'A',
        UpdateReason: value.UpdateReason || ''
    }
    if (lodash.isEqual({
        FeeGroupId: initialValues.FeeGroupId, FeeGroupTitle: initialValues.FeeGroupTitle, Organization: initialValues.Organization,
        Address1: initialValues.Address1, Address2: initialValues.Address2, Country: initialValues.Country, State: initialValues.State,
        City: initialValues.City, PostalCode: initialValues.PostalCode, IsAutoRenewal: initialValues.IsAutoRenewal, IsSuppressFeeRenewalEmail: initialValues.IsSuppressFeeRenewalEmail,
        IsSuppressFeeRenewalPrint: initialValues.IsSuppressFeeRenewalPrint, ContactPersonName: initialValues.ContactPersonName, ContactEmailAddress: initialValues.ContactEmailAddress,
        ContactPhoneNumber: initialValues.ContactPhoneNumber, ContactExtension: initialValues.ContactExtension
    }, {
        FeeGroupId: reqData.FeeGroupId, FeeGroupTitle: reqData.FeeGroupTitle, Organization: reqData.Organization,
        Address1: reqData.Address1, Address2: reqData.Address2, Country: reqData.Country, State: reqData.State,
        City: reqData.City, PostalCode: reqData.PostalCode, IsAutoRenewal: reqData.IsAutoRenewal, IsSuppressFeeRenewalEmail: reqData.IsSuppressFeeRenewalEmail,
        IsSuppressFeeRenewalPrint: reqData.IsSuppressFeeRenewalPrint, ContactPersonName: reqData.ContactPersonName, ContactEmailAddress: reqData.ContactEmailAddress,
        ContactPhoneNumber: reqData.ContactPhoneNumber, ContactExtension: reqData.ContactExtension
    })) {
        self.props.displayNoChangesMadeMessage();
        self.props.editFormHandler(false);
    } else {
        self.props.putFeeGroup(reqData, (response) => {
            if (response) {
                self.props.editFormHandler();
                self.props.bannerRefresh();
            }
        })
    }
}

export const getFeeGroupTitleExist = (e, val) => {
    if (val) {
        self.setState({ disabledSubmit: true });
        let reqData = { feeGroupId: self.props.initialValues.FeeGroupId || 0, feeGroupTitle: val || '' };
        self.props.getFeeGroupTitleExist(reqData, () => {
            self.setState({ disabledSubmit: self.props.isDuplicate })
        })
    } else {
        self.setState({ disabledSubmit: false })
    }
}

