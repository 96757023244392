import * as utilCommon from 'helpers/util-common';
import { deepCopy, formatDateToMMDDYYYY } from '../../../../../helpers/util-common';
import { MembershipTypes } from '../../../members/members.model';
import { PaidStatus, RefreshAssociatedMemberHeader } from '../../feegroups.model';


let self = {};
export const setClassInstance = (instance) => {
    self = instance;
}

export const manageAssociatePopUp = (isListReload = true) => {
    RefreshAssociatedMemberHeader()
    let isShowAssociateMember = !self.state.isShowAssociateMember;
    if (!isShowAssociateMember && isListReload) {
        resetPaging();
        self.props.bannerRefresh();
        self.props.refreshPage();
        getFeeGroupAssociatedMemberList();
    }
    self.setState({ isShowAssociateMember })
}

export const filterMembershipTypes = () => {
    let membershipTypeList = deepCopy(self.props.membershipTypeList);
    membershipTypeList = membershipTypeList.filter(type => {
        return type.key !== MembershipTypes.Organizational
    })
    return membershipTypeList;
}

export const getFeeGroupAssociatedMemberList = (callApi = true) => {
    if (callApi) {
        RefreshAssociatedMemberHeader();
        self.props.getFeeGroupAssociatedMemberList(self.props.match.params.feeGroupId, (response) => {
            self.setState({associatedAllMemberList: response, partialClass: '', isSelectAll: false, showRemoveButton: false, selectedMemberType: 'All',
            selectedPaidStatus: PaidStatus[0].value, sortOrder: 'AccountNumber', sortAction: '0'})
            setTimeout(() => {
                filteredFeeGroupAssociatedMemberList(self.state.associatedAllMemberList);
            }, 50)
        })
    } else {
        filteredFeeGroupAssociatedMemberList(self.state.associatedAllMemberList)
    }
}

const filteredFeeGroupAssociatedMemberList = (listFeeGroupAssociatedMembers) => {
    let count = 0;
    let associatedMemberList = []
    const { pageSize, pageNumber, selectedMemberType, selectedPaidStatus } = self.state;
    if (listFeeGroupAssociatedMembers && listFeeGroupAssociatedMembers.length > 0) {
        associatedMemberList = listFeeGroupAssociatedMembers.filter((item) => {
            if (
                (selectedMemberType.toLowerCase() === self.membershipTypeList[0].value.toLowerCase() ? true : item.MembershipTypeName.toLowerCase() === selectedMemberType.toLowerCase()) &&
                (selectedPaidStatus.toLowerCase() === PaidStatus[0].value.toLowerCase() ? true : item.PaidStatus.toLowerCase() === selectedPaidStatus.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    }
    count = associatedMemberList ? associatedMemberList.length : 0;
    updateCurrentlyShowingItems(count, pageSize, pageNumber);
    self.setState({ totalItems: count });
    sortRecords(self.state.sortOrder, self.state.sortAction, associatedMemberList);
}

const sortRecords = (sortkey, sortAction, associatedMemberList) => {
    if (sortAction === "0") {
        associatedMemberList.sort((a, b) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));

    }
    else {
        associatedMemberList.sort((b, a) => (a[sortkey] === null ? "" : a[sortkey].toString()).localeCompare((b[sortkey] === null ? "" : b[sortkey].toString())));
    }

    self.setState({ associatedMemberList })
}

export const handleSorting = (event, sortKey) => {
    let orderType = utilCommon.getSortOrderFromHeaderClick(event);
    const associatedMemberList = self.state.associatedMemberList;
    if (associatedMemberList && associatedMemberList.length > 0) {
        sortRecords(sortKey, orderType, associatedMemberList)
    }
    self.setState({
        associatedMemberList,
        sortOrder: sortKey,
        sortAction: orderType
    })
}

const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = utilCommon.getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
    self.setState({ currentlyShowingItems, totalPages });
};

export const onPageChangeHandler = (event, data) => {
    let { totalPages, activePageNumber, pageSize } = self.state;

    if (totalPages > 1) {
        activePageNumber = data.activePage;
    }
    updateCurrentlyShowingItems(self.state.associatedMemberList.length || 0, pageSize, activePageNumber);
    self.setState({ activePageNumber });
};
const resetPaging = () => {
    self.setState({ activePageNumber: 1 })
}
export const onChangeNumberOfItemsPerPage = (e, value) => {
    let { pageSize, pageNumber, feeGroupList } = self.state;
    pageSize = value;
    pageNumber = 1;

    self.setState({ activePageNumber: 1, pageSize });
    updateCurrentlyShowingItems(self.state.associatedMemberList.length || 0, pageSize, pageNumber);
};

export const onChangeMemberType = (e, value) => {
    self.setState({ selectedMemberType: value }, () => {
        getFeeGroupAssociatedMemberList(false)
    })
}

export const onChangePaidStatus = (e, value) => {
    self.setState({ selectedPaidStatus: value }, () => {
        getFeeGroupAssociatedMemberList(false)
    })
}

export const selectAll = (e, data) => {
    let associatedMemberList = self.state.associatedMemberList.filter((item) => {
        item.isChecked = data.checked;
        return item;
    });

    self.setState({ associatedMemberList, isSelectAll: data.checked })

    if (associatedMemberList.length > 0 && associatedMemberList.filter(x => x.isChecked == true).length > 0 && associatedMemberList.filter(x => x.isChecked == true).length < associatedMemberList.length) {
        self.setState({ partialClass: 'partial' });
    } else {
        self.setState({ partialClass: '' });
    }
}

export const onChangeCheckBoxHandler = (e, data) => {
    let associatedMemberList = self.state.associatedMemberList
    associatedMemberList[data.index].isChecked = data.checked
    self.setState({ associatedMemberList });

    if (associatedMemberList.length > 0 && associatedMemberList.filter(x => x.isChecked == true).length > 0 && associatedMemberList.filter(x => x.isChecked == true).length < associatedMemberList.length) {
        self.setState({ partialClass: 'partial', isSelectAll: false });
    } else {
        self.setState({ partialClass: '' });
    }

    if (associatedMemberList.filter(x => x.isChecked == true).length === associatedMemberList.length) {
        self.setState({ isSelectAll: true })
    }
}
export const showUpdateReason = () => {
    RefreshAssociatedMemberHeader();
    self.setState({ showUpdateReason: true })
}
export const enableAssociateRemove = () => {
    self.setState({ showRemoveButton: true });
}
export const popUpCancel = () => {
    self.setState({ showUpdateReason: false }, () => {
        let associatedMemberList = self.state.associatedMemberList;
        associatedMemberList.map(item => {
            item.isChecked = false;
            return item
        })
        self.setState({ partialClass: '', associatedMemberList, showRemoveButton: false, isSelectAll: false });
    })
}
export const resetMemberList = () => {
    let associatedMemberList = self.state.associatedMemberList;
    associatedMemberList.map(item => {
        item.isChecked = false;
        return item
    })
    self.setState({ partialClass: '', associatedMemberList, isSelectAll: false })
}

export const OnDeleteMember = (value) => {
    if (value && value.UpdateReason) {
        self.setState({ showUpdateReason: false });
        let associatedMemberList = self.state.associatedMemberList.filter(item => item.isChecked) || []
        let postData = {
            FeeGroupId: self.props.initialValues.FeeGroupId || 0,
            Members: associatedMemberList.length > 0 ? associatedMemberList.map(item => { return { MemberId: item.MemberId, MembershipTypeId: item.MembershipTypeId } }) : [],
            ReasonForUpdate: value.UpdateReason || ''
        }
        self.props.deleteAssociateMemberFeeGroup(postData, () => {
            resetPaging();
            self.props.bannerRefresh();
            self.props.refreshPage();
            getFeeGroupAssociatedMemberList();
        })
    }
}

const resetExcelDownload = () => {
    setTimeout(() => {
        self.setState({ isExportExcel: false });
    }, 100);
}

export const getAssociateMemberExportExcel = () => {
    self.setState({ loading: true });
    const exportedData = [];
    if (self.state.associatedMemberList && self.state.associatedMemberList.length > 0) {
        self.state.associatedMemberList.forEach((item) => {
            exportedData.push({
                Account: item.AccountNumber,
                Name: item.Name,
                MemberType: item.MembershipTypeName,
                Email: item.Email,
                Organization: item.Organization === null ? '' : item.Organization,
                Status: item.Status,
                PaidStatus: item.PaidStatus,
                AssociatedDate: formatDateToMMDDYYYY(item.AssociationDate)
            })
        })

        self.setState({ isExportExcel: true, exportedData, loading: false }, () => resetExcelDownload())
    } else {
        self.setState({ isOpenExcelDownloadConfirmBox: true, exportedData: [], loading: false });
    }
}

export const emptyExportDataCancelHandler = () => {
    self.setState({ isOpenExcelDownloadConfirmBox: false });
}

export const emptyExportDataConfirmHandler = () => {
    self.setState({ isExportExcel: true, isOpenExcelDownloadConfirmBox: false }, () => { resetExcelDownload() });
}
