import * as utilCommon from 'helpers/util-common';
import { MembershipTypes } from '../../members.model';
import * as _funMemberCommitteeDetails from '../member-committee-details/function';

let self = {};
let accountNo = '';
let updateBasicDetailsEvent = {};
let updatedDetailsForupdateMemberCommitteeBasicDetails = {};

export const setClassInstance = (instance) => {
    self = instance;
    accountNo = self.props.AccountNumber;
    self.props.change('CommitteeJoinDate', self.state.committeeJoinDate);
    self.props.change('InactiveReason', self.state.inactiveReasonSelectedValue);
    self.props.change('OfficerTitle', self.state.officerTitleSelectedValue);
    self.props.change('OfficerAppointedDate', self.state.officerAppointedDate);
    self.props.change('Status', self.state.memberCommitteeStatus);
    self.props.change('HonoraryStatus', self.props.memberCommitteeDetails.TopSection.HonoraryStatus === true ? 'yes' : 'no');
    self.props.change('CommitteePayStatus', self.props.memberCommitteeDetails.TopSection.CommitteePayStatus === true ? 'yes' : 'no');

    self.props.getActiveFeeGroupList((response) => {
        self.setState({ feeGroupList: formatFeeGroupList(response || []) }
        );
    });
}

export const handleChangeCommitteeJoinDate = (event, value) => {
    self.setState({ committeeJoinDate: value })
}
export const handleChangeOfficerAppointedDate = (event, value) => {
    self.setState({ officerAppointedDate: value })
}
export const handleChangeMemberCommitteeStatus = (event, value) => {
    let memberCommitteeStatus = event.currentTarget.querySelector('input').value;
    self.setState({ memberCommitteeStatus })

    if (memberCommitteeStatus == 'inactive') {
        self.props.change('OfficerTitle', '')
        self.props.change('OfficerAppointedDate', self.props.memberCommitteeDetails.OfficerAppointedDate)
        self.props.change('CommitteeJoinDate', self.props.memberCommitteeDetails.CommitteeJoinDate)
        self.setState({
            officerTitleSelectedValue: '',
            officerAppointedDate: self.props.memberCommitteeDetails.OfficerAppointedDate,
            committeeJoinDate: self.props.memberCommitteeDetails.CommitteeJoinDate
        })
    }
}
export const handleChangeInactiveReason = (event, value) => {
    self.setState({ inactiveReasonSelectedValue: value })
}
export const handleChangeofficerTitle = (event, value) => {
    self.setState({ officerTitleSelectedValue: value })
}
export const popUpCancel = () => {
    self.setState({ isCommitteeJoinDateGreaterThanClassificationDate: false, isCommitteeJoinDateGreaterThanVoteDate: false, isCommitteeJoinDateGreaterThanBothDates: false });
}
export const cancelAdministrativeAssistantOrStaffManagerPopup = () => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
}
export const popUpConfirm = (event, value) => {
    self.setState({ isCommitteeJoinDateGreaterThanClassificationDate: false, isCommitteeJoinDateGreaterThanVoteDate: false, isCommitteeJoinDateGreaterThanBothDates: false });
    updateMemberCommitteeBasicDetails(updateBasicDetailsEvent.event, updateBasicDetailsEvent.value);
}
export const confirmAdministrativeAssistantOrStaffManagerPopup = (event, value) => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });

    // Updating Member Committee Basic Details
    self.props.updateMemberCommitteeBasicDetails(updatedDetailsForupdateMemberCommitteeBasicDetails, (response = false) => {
        _funMemberCommitteeDetails.getMemberMainSubCommittees(self.props.activeCommitteeId);
        self.props.getMemberDetails(accountNo);

        if (response) {
            self.setState({ isShowAdministrativeAssistantPopup: true });
        } else {
            self.props.onEditCancel();
        }
    });
}
export const updateBasicDetails = (event, value) => {
    updateBasicDetailsEvent = { event, value }
    const { CommitteeJoinDate } = event
    const { memberCommitteeDetails: { GeneralInformation: { ClassificationDate, VoteDate } } } = self.props
    let isCommitteeJoinDateGreaterThanClassificationDate = utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(CommitteeJoinDate), utilCommon.formatDateToMMDDYYYY(ClassificationDate), true, 'MM/DD/YYYY');
    let isCommitteeJoinDateGreaterThanVoteDate = utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(CommitteeJoinDate), utilCommon.formatDateToMMDDYYYY(VoteDate), true, 'MM/DD/YYYY')
    let isCommitteeJoinDateGreaterThanBothDates = (isCommitteeJoinDateGreaterThanClassificationDate && isCommitteeJoinDateGreaterThanVoteDate)
    isCommitteeJoinDateGreaterThanClassificationDate && self.setState({ isCommitteeJoinDateGreaterThanClassificationDate });
    isCommitteeJoinDateGreaterThanVoteDate && self.setState({ isCommitteeJoinDateGreaterThanVoteDate });
    isCommitteeJoinDateGreaterThanBothDates && self.setState({ isCommitteeJoinDateGreaterThanBothDates });
    (!isCommitteeJoinDateGreaterThanClassificationDate && !isCommitteeJoinDateGreaterThanVoteDate && !isCommitteeJoinDateGreaterThanBothDates) && updateMemberCommitteeBasicDetails(event, value);
}
export const updateMemberCommitteeBasicDetails = (event, value) => {
    const { CommitteeJoinDate, Status, OfficerTitle, InactiveReason, OfficerAppointedDate, ReasonforUpdate, FeeGroupId, HonoraryStatus, CommitteePayStatus } = event
    const { committeeJoinDate, memberCommitteeStatus, officerTitleSelectedValue, inactiveReasonSelectedValue, officerAppointedDate } = self.prevState;
    const { activeCommitteeId, MemberId, AccountNumber, initialValues: { CommitteeDesignation }, memberCommitteeInfo } = self.props
    var CommitteeDesignations = memberCommitteeInfo.filter(x => x.CommitteeId == activeCommitteeId)[0].CommitteeDesignation;
    self.setState({
        committeeName: CommitteeDesignations && CommitteeDesignations.split('.')[0]
    });
    let officersList = self.state.officerTitle;
    const updatedDetails = {
        AccountNumber: AccountNumber,
        CommitteeId: activeCommitteeId,
        MemberId: MemberId,
        JoinAsMemberDate: CommitteeJoinDate,
        MemberCommitteeStatus: Status == 'active' ? 1 : 0,
        InactiveReasonId: InactiveReason == 'select' || Status == 'active' ? 0 : InactiveReason,
        OfficerTitleId: OfficerTitle == 'select' ? 0 : OfficerTitle,
        OfficerAppointedDate: OfficerAppointedDate ? OfficerAppointedDate : '',
        ReasonForUpdate: ReasonforUpdate,
        Officers: (officersList && officersList.length > 0 ? officersList.filter((x, index) => { return index > 0 }) : null),
        FeeGroupId: self.state.committeePayStatus === 'yes' ? FeeGroupId : 0,
        HonoraryStatus: self.state.honoraryStatus === 'yes',
        CommitteePayStatus: self.state.committeePayStatus === 'yes',
        OfficerTitle: (officersList && officersList.length > 0 && OfficerTitle != 'select' && OfficerTitle != '' ? officersList.filter(x => x.OfficerTitleId == OfficerTitle)[0].OfficerTitleName : ''), 
        OfficerTitleSelectedValue: (officersList && officersList.length > 0 && officerTitleSelectedValue != 'select' && officerTitleSelectedValue != '' ? officersList.filter(x => x.OfficerTitleId == officerTitleSelectedValue)[0].OfficerTitleName : ''),
        CommitteeDesignation: (CommitteeDesignations && CommitteeDesignations.split('.')[0] ? CommitteeDesignations.split('.')[0] : CommitteeDesignations),
        SubCommitteeDesignation: [(CommitteeDesignations && CommitteeDesignations.split('.')[0] ? CommitteeDesignations : '')]
    }
    let honoraryNomessage = (updatedDetails.CommitteePayStatus === self.props.memberCommitteeDetails.TopSection.CommitteePayStatus && updatedDetails.HonoraryStatus === self.props.memberCommitteeDetails.TopSection.HonoraryStatus && (updatedDetails.FeeGroupId || '').toString() === (self.props.memberCommitteeDetails.TopSection.FeeGroupId || '').toString())
    if ((CommitteeJoinDate == committeeJoinDate && Status == memberCommitteeStatus && OfficerTitle == officerTitleSelectedValue) && ((honoraryNomessage && self.props.MemberTypeId !== MembershipTypes.Student) || (self.props.MemberTypeId === MembershipTypes.Student))) {
        if (Status == 'inactive') {
            if (InactiveReason == inactiveReasonSelectedValue) {
                self.props.displayNoChangesMadeMessage()
            } else {

                if ((self.state.officerTitleSelectedValue === 7 || self.state.officerTitleSelectedValue === 6) && CommitteeDesignations.indexOf('.') > -1) {
                    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: true });
                    updatedDetailsForupdateMemberCommitteeBasicDetails = updatedDetails;

                } else {
                    // Updating Member Committee Basic Details
                    self.props.updateMemberCommitteeBasicDetails(updatedDetails, (response = false) => {
                        if (response) {
                            self.setState({ isShowAdministrativeAssistantPopup: true });
                        }
                        return _funMemberCommitteeDetails.getMemberMainSubCommittees(activeCommitteeId)
                    });
                }

            }
        } else if (Status == 'active' && OfficerTitle != 'select') {
            if (OfficerAppointedDate == officerAppointedDate) {
                self.props.displayNoChangesMadeMessage()
            } else {

                if ((self.state.officerTitleSelectedValue === 7 || self.state.officerTitleSelectedValue === 6) && CommitteeDesignations.indexOf('.') > -1) {
                    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: true });
                    updatedDetailsForupdateMemberCommitteeBasicDetails = updatedDetails;

                } else {
                    // Updating Member Committee Basic Details
                    self.props.updateMemberCommitteeBasicDetails(updatedDetails, (response = false) => {
                        if (response) {
                            self.setState({ isShowAdministrativeAssistantPopup: true });
                        }

                        return _funMemberCommitteeDetails.getMemberMainSubCommittees(activeCommitteeId)
                    });
                }

            }
        } else {
            self.props.displayNoChangesMadeMessage()
        }

        self.props.onEditCancel();
    } else {
        if ((self.state.officerTitleSelectedValue === 7 || self.state.officerTitleSelectedValue === 6) && CommitteeDesignations.indexOf('.') > -1) {
            self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: true });
            updatedDetailsForupdateMemberCommitteeBasicDetails = updatedDetails;

        } else {
            // Updating Member Committee Basic Details
            self.props.updateMemberCommitteeBasicDetails(updatedDetails, (response = false) => {
                _funMemberCommitteeDetails.getMemberMainSubCommittees(activeCommitteeId);
                self.props.getMemberDetails(accountNo);

                if (response) {
                    self.setState({ isShowAdministrativeAssistantPopup: true });
                } else {
                    self.props.onEditCancel();
                }
            });
        }

    }
}
export const validateHandler = (values, props) => {
    const errors = {};
    if (!values.CommitteeJoinDate) {
        errors.CommitteeJoinDate = utilCommon.getMessage(props.messageCodes, '8130.text');
    } else if (values.CommitteeJoinDate && (!utilCommon.compareDates(utilCommon.formatDateToMMDDYYYY(values.CommitteeJoinDate), utilCommon.formatDateToMMDDYYYY(utilCommon.MinDate), true, 'MM/DD/YYYY'))) {
        errors.CommitteeJoinDate = utilCommon.getMessage(props.messageCodes, '4021.text');
    }

    if (values.Status == 'inactive') {
        if (values.InactiveReason == 'select' || !values.InactiveReason) {
            errors.InactiveReason = utilCommon.getMessage(props.messageCodes, '8131.text');
        }
    }
    if (values.OfficerTitle != 'select' && !values.OfficerAppointedDate) {
        errors.OfficerAppointedDate = utilCommon.getMessage(props.messageCodes, '3078.text');
    } else if (values.OfficerTitle != 'select' && (values.OfficerAppointedDate.length < 7 || !utilCommon.checkIfDateValid(values.OfficerAppointedDate, 'MM/YYYY'))) {
        errors.OfficerAppointedDate = utilCommon.getMessage(props.messageCodes, '3079.text');
    } else if (values.OfficerTitle != 'select' && values.OfficerAppointedDate) {
        let year = Number(values.OfficerAppointedDate.substring(3));
        if (isNaN(year) || (year <= 0)) {
            errors.OfficerAppointedDate = utilCommon.getMessage(props.messageCodes, '4003.text');
        } else if (utilCommon.compareDates(values.OfficerAppointedDate, new Date(), true, 'MM/YYYY')) {
            errors.OfficerAppointedDate = utilCommon.getMessage(props.messageCodes, '3084.text');
        } else if (utilCommon.compareDates(utilCommon.formatDateToMMYYYY(utilCommon.MinDate), values.OfficerAppointedDate, true, 'MM/YYYY')) {
            errors.OfficerAppointedDate = utilCommon.getMessage(props.messageCodes, '4021.text');
        }
    }
    if (!values.ReasonforUpdate || values.ReasonforUpdate.trim() == '') {
        errors.ReasonforUpdate = utilCommon.getMessage(props.messageCodes, '8121.text');
    }

    if (!values.HonoraryStatus && props.MemberTypeId !== MembershipTypes.Student) {

        errors.HonoraryStatus = utilCommon.getMessage(props.messageCodes, '8163.text');
    } else if (values.HonoraryStatus && values.HonoraryStatus === 'yes' && !values.CommitteePayStatus && props.MemberTypeId !== MembershipTypes.Student) {
        errors.CommitteePayStatus = utilCommon.getMessage(props.messageCodes, '8164.text');

    } else if (values.CommitteePayStatus && values.CommitteePayStatus === 'yes' && (!values.FeeGroupId || parseInt(values.FeeGroupId) < 1) && props.MemberTypeId !== MembershipTypes.Student) {
        errors.FeeGroupId = utilCommon.getMessage(props.messageCodes, '8162.text');

    }

    return errors;
}
export const handelHonoraryChange = (event, value, type) => {
    let selectedValue = event.currentTarget.querySelector('input').value;
    self.props.change('FeeGroupId', '')
    switch (type) {
        case 'honorary':
            self.setState({ honoraryStatus: selectedValue, committeePayStatus: selectedValue }, () => {
                self.props.change('CommitteePayStatus', selectedValue);
            })
            break;
        case 'committeePay':
            self.setState({ committeePayStatus: selectedValue })
            break;
        default:
            // Do nothing
            break
    }

}
export const formatFeeGroupList = (list) => {
    let result = [];

    if (list && list.length > 0) {
        result = list.filter(item => item.FeeGroupTitle.toLowerCase() !== 'all').map((res) => {
            res.text = `${res.FeeGroupNumber}-${res.FeeGroupTitle}`;
            res.value = `${res.FeeGroupId}`;
            return res;
        });
        return result;
    }
    return result;
}
export const handleClickOk = (e) => {
    self.setState({ isShowAdministrativeAssistantPopup: false }, () => {
        self.props.onEditCancel();
    });
}