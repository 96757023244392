import { getCurrentlyShowingItemsInGrid, getSortOrderFromHeaderClick, resetHeaderSortOrder, toUTCDateAndTime } from 'helpers/util-common';
import { endDayTime } from 'models/common.models';
import { AssociatedMembersColumns } from '../../../members/members.model';

let self = null;

export const setInstance = (instance) => {
   self = instance;
}

export const loadInitialData = () => {
   const { selectedFieldName, statusSelectedValue, pageSize, pageNumber, sortKey, sortOrder } = self.state;

   loadAssociatedMembersList(selectedFieldName, statusSelectedValue, pageSize, pageNumber, sortKey, sortOrder);
}

export const loadAssociatedMembersList = (selectedFieldName, statusSelectedValue, pageSize, pageNumber, sortKey, sortOrder) => {
   self.setState({ showLoader: true });
   const requestData = {
      searchText: selectedFieldName,
      status: statusSelectedValue,
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortKey: sortKey,
      sortOrder: sortOrder
   }
   const companyId = self.props.initialValues.OrganizationalMemberId;

   self.props.getOrganizationAssociatedMembers(self.props.initialValues.AccountNumber, requestData, companyId, (result) => {
      let auditLogList = [];
      let totalItems = 0;

      if (result && result.MembersDetail && result.MembersDetail.length > 0) {
         auditLogList = result.MembersDetail;
         totalItems = result.Count;
      }

      self.setState({ auditLogList, totalItems, showLoader: false });
      updateCurrentlyShowingItems(totalItems, pageSize, pageNumber);

      let scrollElement = document.getElementsByClassName('customTable auditLogtable')[0];
      if (scrollElement) {
         scrollElement.scrollIntoView({
            behavior: 'smooth'
         });
      }
   });
}

export const onPageChangeHandler = (event, data) => {
   const { selectedFieldName, statusSelectedValue, pageSize, sortKey, sortOrder } = self.state;
   loadAssociatedMembersList(selectedFieldName, statusSelectedValue, pageSize, data.activePage, sortKey, sortOrder);

   self.setState({ pageNumber: data.activePage })
}

export const onChangeNumberOfItemsPerPage = (e, value) => {
   const { selectedFieldName, statusSelectedValue, sortKey, sortOrder } = self.state;
   loadAssociatedMembersList(selectedFieldName, statusSelectedValue, value, 1, sortKey, sortOrder);

   self.setState({ pageSize: value, pageNumber: 1 });
}

// Reset the entire grid
export const resetForm = () => {
   const selectedFieldName = '';
   const statusSelectedValue = 17
   const pageSize = 25;
   const pageNumber = 1;
   const sortKey = 'MemberAccountNumber';
   const sortOrder = 'desc';

   self.props.reset();
   self.setState({ selectedFieldName, statusSelectedValue, pageSize, pageNumber, sortKey, sortOrder })
   resetHeaderSortOrder(AssociatedMembersColumns);
   loadAssociatedMembersList(selectedFieldName, statusSelectedValue, pageSize, pageNumber, sortKey, sortOrder);
}

export const sortByHandler = (event, sortKey) => {
   let orderType = getSortOrderFromHeaderClick(event);
   const sortOrder = orderType === '0' ? 'asc' : 'desc';

   self.setState({ sortKey, sortOrder });

   const { selectedFieldName, statusSelectedValue, pageSize, pageNumber } = self.state;
   loadAssociatedMembersList(selectedFieldName, statusSelectedValue, pageSize, pageNumber, sortKey, sortOrder);
}

export const onClickAssociatedMembersSearch = (values) => {
   const { statusSelectedValue, pageSize, sortKey, sortOrder } = self.state;
   const { selectedFieldName } = values;
   self.setState({ pageNumber: 1, statusSelectedValue, selectedFieldName });
   loadAssociatedMembersList(selectedFieldName, statusSelectedValue, pageSize, 1, sortKey, sortOrder);
}

// Comment-Unused code
// export const getDescription = (where) => {
//    if (where && where.length > 0) {
//       let htmlResult = '';
//       where.map((item) => {
//          htmlResult = `${htmlResult}<p><strong>${item.Field}</strong> updated from <strong>${item.OldValue ? item.OldValue : '--'}</strong> to <strong>${item.NewValue ? item.NewValue : '--'}</strong></p>`;
//       })

//       return htmlResult;
//    }

//    return '';
// }

export const onChangeFilter = (value) => {
   self.setState({ statusSelectedValue: value });
}

export const onEnterSearchFilterRecord = (event) => {
   if(event && event.key == 'Enter') {
      self.props.handleSubmit({});
   }   
}

// Private functions
const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
   const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);

   self.setState({ currentlyShowingItems });
}

// Hanlder for avoid special symbol.
export const onChangeAccountNumberSearchHandler = (e, values) => {
   const reg = /[^a-zA-Z0-9]/g;
   const { value } = e.target;
   const input = value ? String(value) : '';
   const isInValid = input ? reg.test(input) : false;
   if (isInValid) {
       e.preventDefault();
   }
}
export const refreshOrganizaionalDetailPage = (url) => this.props.history.push(url);
