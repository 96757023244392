import * as types from 'action-types';

const initialState = {
    initialValues: {},
    membershipTypeList: [],
    joinCommitees:[1],
    studentCommitteeListMaster: []
}

const formatMembershipTypeList = (data) => {
    let result = [];
    result = data.map(item => {
        return { key: item.MembershipTypeId, value: item.MembershipTypeId, text: item.MembershipTypeName }
    })

    result.unshift({ key: 0, value: 0, text: 'All' });

    return result;
}

const manualOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MEMBERSHIPTYPE_LIST:
            return {
                ...state,
                membershipTypeList: formatMembershipTypeList(action.membershipTypeList)
            };
        case types.SET_JOIN_COMMITTEE:
                return {
                    ...state,
                    joinCommitees: action.joinCommitees
                };
        case types.SET_STUDENT_COMMITTEE_LIST_MASTER:
            return {
                ...state,
                studentCommitteeListMaster: action.studentCommitteeListMaster
            };
        default:
            return state;
    }
}

export default manualOrderReducer;
