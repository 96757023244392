import * as types from 'action-types';
import { formatAuditLogFieldList, formatDateToMMDDYYYY, utcDateTimeToLocalDateTime } from 'helpers/util-common';
import { formatHistoricalReasonList } from '../../members/common-functions';
import { DroppedOrgHistoricalReasonId, FacilityHistorical, DroppedFacilityHistoricalReasonId, DefaultHistoricalReason, DefaultHistoricalReasonId } from '../../members/members.model';
import { COMPANY_TYPE } from '../../../../models/common.models';

const initialState = {
    organizationDetails: {},
    organizationUserList: null,
    organizationRepHistoryList: null,
    historicalReasonList: null,
    historicalFacilityReasonList: null,
    organizationAuditLogFieldList: null
}

const formatData = (data) => {
    let name = '';
    let repName = '';
    let fullAddress = '';

    if (data) {
        fullAddress = data.Address ? `${data.Address}, ` : '';
        fullAddress = data.City ? `${fullAddress}${data.City}, ` : fullAddress;
        fullAddress = data.State ? `${fullAddress}${data.State}, ` : fullAddress;
        fullAddress = data.PostalCode ? `${fullAddress}${data.PostalCode}, ` : fullAddress;
        fullAddress = data.Country ? `${fullAddress}${data.Country}` : fullAddress;
    }

    if (data.LastName) {
        name = `${data.LastName}, `;
    }
    if (data.FirstName) {
        name = name + data.FirstName + ' ';
    }
    if (data.MiddleName) {
        name = name + data.MiddleName.substring(0, 1);
    }

    if (data.RepLastName) {
        repName = `${data.RepLastName}, `;
    }
    if (data.RepFirstName) {
        repName = repName + data.RepFirstName + ' ';
    }
    if (data.RepMiddleName) {
        repName = repName + data.RepMiddleName.substring(0, 1);
    }
    if (!data.RepAccountNumber) {
        data.RepAccountNumber = '';
    }

    data.Name = name;
    data.RepName = repName;
    data.FullAddress = fullAddress;
    data.JoinDate = formatDateToMMDDYYYY(data.JoinDate);

    if (data.PaidDate) {
        data.PaidDate = formatDateToMMDDYYYY(data.PaidDate);
    }

    if (data.GraduationDate) {
        data.GraduationDate = formatDateToMMDDYYYY(data.GraduationDate);
    }

    if (data.ModifiedDate) {
        data.lastModifiedDate = utcDateTimeToLocalDateTime(data.ModifiedDate);
    } else {
        data.lastModifiedDate = '';
    }
    
    if (data.StatusName === FacilityHistorical && data.CompanyTypeId === COMPANY_TYPE.FACILITY && data.HistoricalReasonId === 0){
        data.HistoricalReasonId = DefaultHistoricalReasonId;
        data.HistoricalReason = DefaultHistoricalReason;
    }

    return data;
}

const formatAddressFromMemberMasterData = (data) => {
    let fullAddress = '';
    if (data) {
        fullAddress = data.MemberDetail_Address ? `${data.MemberDetail_Address}, ` : '';
        fullAddress = data.MemberDetail_City ? `${fullAddress}${data.MemberDetail_City}, ` : fullAddress;
        fullAddress = data.MemberDetail_PostalCode ? `${fullAddress}${data.MemberDetail_PostalCode}, ` : fullAddress;
        fullAddress = data.MemberDetail_Country ? `${fullAddress}${data.MemberDetail_Country}` : fullAddress;
    }

    data.FullAddress = fullAddress;
    return data;
}

const organizationDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ORGANIZATION_DETAILS:
            return {
                ...state,
                organizationDetails: formatData(action.data)
            }
        case types.GET_ORGANIZATION_MASTER_DATA:
            return {
                ...state,
                organizationUserList: action.organizationUserList,
                historicalReasonList: formatHistoricalReasonList(action.historicalReasonList, DroppedOrgHistoricalReasonId),
                historicalFacilityReasonList: formatHistoricalReasonList(action.historicalFacilityReasonList, DroppedFacilityHistoricalReasonId),
                organizationAuditLogFieldList: formatAuditLogFieldList(action.organizationAuditLogFieldList)
            }
        case types.RESET_ORGANIZATION_DETAILS_DATA:
            return {
                ...state,
                organizationDetails: {}
            }
        case types.GET_ORGANIZATION_REP_HISTORY_LIST:
            return {
                ...state,
                organizationRepHistoryList: action.list
            }
        default:
            return state;
    }
}

export default organizationDetailsReducer;
