import React from 'react';
import { Popup } from 'semantic-ui-react';

const CustomTooltip = (props) => (

  <Popup
    className={props.className}
    // open={true}
    trigger={props.icon}
    content={props.content}
    inverted
    hideOnScroll
    position={props.position}
  />
)

export default CustomTooltip;
