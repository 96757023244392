import * as _actions from 'action-types';
import * as commonActions from 'common.actions';
import _ from 'lodash';
import { connect } from "react-redux";
import memberDetailsActions from '../../member-management/members/actions/member-details.actions';
import committeeDetailsActions from '../actions/committee-details.actions';
import committeeActions from '../actions/committee.actions';
import CommitteeDetails from '../components/committee-details';
import manualOrderActions from '../../admin/manual-order/manual-order.action';

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        initialValues: Object.assign({}, state.committeeDetailsReducer.initialValues, { memberDetails: state.memberDetailsReducer.memberDetails }),
        membershipTypeList: state.committeeDetailsReducer.membershipTypeList,
        committeeRosterGridPreference: state.commonReducer.committeeRosterGridPreference,
        committeeTypeList: state.committeesReducer.committeeTypeList,
        allCommitteesHierarchyDetails: state.committeesReducer.allCommitteesHierarchyDetails,
        selectedCommitteeLevelEligibilitiesDetails: state.committeesReducer.selectedCommitteeLevelEligibilitiesDetails,
        overviewTagFieldList: state.committeesReducer.overviewTagFieldList,
        allMeetingTypeList: state.committeeDetailsReducer.allMeetingTypeList,
        activeMeetingTypeList: state.committeeDetailsReducer.activeMeetingTypeList,
        committeeUserList: state.committeeDetailsReducer.committeeUserList,
        classificationList: state.committeeDetailsReducer.classificationList,
        noVoteReasonList: state.committeeDetailsReducer.noVoteReasonList,
        officerTitleList: state.committeeDetailsReducer.officerTitleList,
        inactiveReasonList: state.memberDetailsReducer.inactiveReasonList,
        operatorList: state.committeeDetailsReducer.operatorList,
        applicationPrivilege: state.committeeDetailsReducer.applicationPrivilege,
        committeeAuditLogFieldList: state.committeeDetailsReducer.committeeAuditLogFieldList,
        userPermission: state.commonReducer.userPermission,
        currentModuleTitle: state.commonReducer.currentModuleTitle,
        inActiveReasons: state.committeeDetailsReducer.inActiveReasons,
        dependentCommittee: state.committeeDetailsReducer.dependentCommittee,
        committeeLevelList: state.committeesReducer.committeeLevelList,
        mergeCommittees: state.committeeDetailsReducer.mergeCommittees,
        activeMemberCount: state.committeeDetailsReducer.activeMemberCount,
        ...state.commonReducer.PAGE_CONFIG,
        memberCommitteeDetails: state.memberDetailsReducer.memberCommitteeDetails,
        memberCommitteeOfficerTitles: state.memberDetailsReducer.officerTitleList,
        memberCommitteeHistoryList: state.memberDetailsReducer.memberCommitteeHistoryList,
        memberCommitteeOfficerTitleHistoryList: state.memberDetailsReducer.memberCommitteeOfficerTitleHistoryList,
        memberCommitteeNoVoteReasonList: state.memberDetailsReducer.noVoteReasonList,
        commonCommitteeClassificationTypeList: state.memberDetailsReducer.commonCommitteeClassificationTypeList
    }
};

const mapDispatchToProps = (dispatch) => ({
    getCommitteeGridPreferences: (gridId, callback) => {
        commonActions.getGridPreferenceAction(gridId, dispatch, callback)
    },
    getCommitteeDetails: (committeeId) => {
        committeeDetailsActions.getCommitteeDetailsAction(committeeId, dispatch)
    },
    getUserName: (userId, callback) => {
        commonActions.getUserNameAction(userId, callback)
    },
    getMasterDataForCommitteeList: () => {
        committeeActions.getMasterDataForCommitteeListAction(dispatch);
    },
    getMasterDataForCommitteeDetails: () => {
        committeeDetailsActions.getMasterDataForCommitteeDetailsAction(dispatch);
    },
    resetInitialValues: () => {
        dispatch({ type: _actions.RESET_COMMITTEE_DETAILS_DATA })
    },
    getSelectedCommitteeHierarchyDetails: (committeeTypeId, levelNo) => {
        dispatch({ type: _actions.GET_SELECTED_COMMITTEE_HIERARCHY_DETAILS, committeeTypeId, levelNo });
    },
    updateCommittee: (committeeId, data, callback) => {
        committeeDetailsActions.updateCommitteeAction(committeeId, data, callback, dispatch);
    },
    displayNoChangesMadeMessage: () => {
        dispatch(commonActions.setMessage(true, '2040'));
    },
    getCommitteeMeetingSequence: (committeeId, callback) => {
        committeeDetailsActions.getCommitteeMeetingSequenceAction(committeeId, callback, dispatch);
    },
    getOfficerTitleOnCommittee: (committeeId) => {
        committeeDetailsActions.getOfficerTitleOnCommitteeAction(committeeId, dispatch)
    },
    removeCommitteeMeetingSequence: (id, committeeId, data, callback) => {
        committeeDetailsActions.removeCommitteeMeetingSequenceAction(id, committeeId, data, callback, dispatch);
    },
    addCommitteeMeetingSequence: (data, callback) => {
        committeeDetailsActions.addCommitteeMeetingSequenceAction(data, callback, dispatch);
    },
    manageCommitteeOfficerTitleExtendedResponsibility: (data, callback) => {
        committeeDetailsActions.manageCommitteeOfficerTitleExtendedResponsibilityAction(data, callback, dispatch)
    },
    updateCommitteeMeetingAndAttendees: (committeeId, data, callback) => {
        committeeDetailsActions.updateCommitteeMeetingAndAttendeesAction(committeeId, data, callback, dispatch);
    },
    getCommitteeCommunicationLog: (committeeId, pageSize, pageNumber, showLoader, callback) => {
        committeeDetailsActions.getCommitteeCommunicationLogAction(committeeId, pageSize, pageNumber, showLoader, callback, dispatch)
    },
    saveCommitteeCommunicationLog: (data, callback) => {
        committeeDetailsActions.saveCommitteeCommunicationLogAction(data, callback, dispatch)
    },
    updateCommitteeCommunicationLog: (commentId, data, isOwnComment, callback) => {
        committeeDetailsActions.updateCommitteeCommunicationLogAction(commentId, data, isOwnComment, callback, dispatch)
    },
    removeCommitteeCommunicationLog: (commentId, data, isOwnComment, callback) => {
        committeeDetailsActions.removeCommitteeCommunicationLogAction(commentId, data, isOwnComment, callback, dispatch)
    },
    getCommitteeUserList: () => {
        committeeDetailsActions.getCommitteeUserListAction(dispatch)
    },
    addCommitteeMeetingDate: (data, callback) => {
        committeeDetailsActions.addCommitteeMeetingDateAction(data, callback, dispatch);
    },
    getCommitteeMeetingDateList: (committeeId, query, showPageLoader, callback) => {
        committeeDetailsActions.getCommitteeMeetingDateListAction(committeeId, query, showPageLoader, callback, dispatch);
    },
    updateCommitteeMeetingDate: (meetingId, data, callback) => {
        committeeDetailsActions.updateCommitteeMeetingDateAction(meetingId, data, callback, dispatch);
    },
    getSearchCommitteeRosterMember: (data, callback) => {
        committeeDetailsActions.getSearchCommitteeRosterMemberAction(data, callback, dispatch);
    },
    getMembershipTypeList: () => {
        committeeDetailsActions.getMembershipTypeListAction(dispatch)
    },
    getCommitteeClassificationTypeAndNoVoteReason: (committeeId, callback) => {
        committeeDetailsActions.getCommitteeClassificationTypeAndNoVoteReasonAction(committeeId, callback, dispatch);
    },
    checkMemberExistsInParentCommittee: (data, callback) => {
        committeeDetailsActions.checkMemberExistsInParentCommittee(data, callback, dispatch);
    },
    saveCommitteeMember: (data, callback) => {
        committeeDetailsActions.saveCommitteeMember(data, callback, dispatch);
    },
    getRosterCommitteeMemberList: (data, callback) => {
        committeeDetailsActions.getRosterCommitteeMemberList(data, callback, dispatch);
    },
    getCommontClassification: (data) => {
        committeeDetailsActions.getCommontClassificationAction(data, dispatch)
    },
    getClassificationList: (committeeId, callback) => {
        committeeDetailsActions.getClassificationListAction(committeeId, callback, dispatch)
    },
    validateProducerClassification: (committeeId, accountNumber, callback) => {
        committeeDetailsActions.validateProducerClassificationAction(committeeId, accountNumber, callback, dispatch)
    },
    postCommitteeRoster: (data, callback) => {
        committeeDetailsActions.postCommitteeRosterAction(data, callback, dispatch);
    },
    updateCommitteeClassification: (committeeId, data, callback) => {
        committeeDetailsActions.updateCommitteeClassificationAction(committeeId, data, callback, dispatch)
    },
    updateGridPreference: (gridId, data, callback) => {
        commonActions.updateGridPreferenceAction(gridId, data, callback, dispatch)
    },
    updateCommitteeSettings: (committeeId, requestData, callback) => {
        committeeDetailsActions.updateCommitteeSettingsAction(committeeId, requestData, callback, dispatch)
    },
    deleteCommittee: (committeeId, callback) => {
        committeeDetailsActions.deleteCommitteeAction(committeeId, callback, dispatch);
    },
    getStatisticalCommitteeInfo: (committeeId, queryParams, callback) => {
        committeeDetailsActions.getStatisticalCommitteeInfoAction(committeeId, queryParams, callback, dispatch);
    },
    getMemberOnCommittee: (committeeId, callback) => {
        committeeDetailsActions.getMemberOnCommitteeAction(committeeId, callback, dispatch);
    },
    getOfficerTitleOnCommitteeLevel: (committeeId, callback) => {
        committeeDetailsActions.getOfficerTitleOnCommitteeLevelAction(committeeId, callback, dispatch);
    },
    getOfficerTitlesAsPerRulesAndExceptionOrder: (OfficerTitleIds, callback) => {
        committeeDetailsActions.getOfficerTitlesAsPerRulesAndExceptionOrderAction(OfficerTitleIds, callback, dispatch);
    },
    postCommitteeOfficer: (data, callback) => {
        committeeDetailsActions.postCommitteeOfficerAction(data, callback, dispatch);
    },
    showAlertPopup: (alertMessage) => {
        dispatch(commonActions.showAlertPopup(true, alertMessage));
    },
    getApplicationPrivilegeOnCommitteeType: (committeeTypeId, callback) => {
        committeeDetailsActions.getApplicationPrivilegeOnCommitteeTypeAction(committeeTypeId, callback, dispatch);
    },
    updateEnableCommitteeOnWeb: (committeeId, requestData, callback) => {
        committeeDetailsActions.updateEnableCommitteeOnWebAction(committeeId, requestData, callback, dispatch)
    },
    getDelinkedOfficerResponsibilityOnCommittee: (data, callback) => {
        committeeDetailsActions.getDelinkedOfficerResponsibilityOnCommitteeAction(data, callback, dispatch)
    },
    getCommitteeAuditLog: (accountNumber, requestData, callback) => {
        committeeDetailsActions.getCommitteeAuditLogAction(accountNumber, requestData, callback, dispatch)
    },
    getBylawsRevisionHistoryList: (committeeId, fieldName, callback) => {
        committeeDetailsActions.getBylawsRevisionHistoryListAction(committeeId, fieldName, callback, dispatch);
    },
    getSearchActiveCommittees: (callback) => {
        committeeDetailsActions.getSearchActiveCommitteesAction(callback, dispatch);
    },
    getCountActiveMembersInCommittee: (committeeId, callback) => {
        committeeDetailsActions.getCountActiveMembersInCommitteeAction(committeeId, callback, dispatch);
    },
    postCopyMembersToCommittee: (data, callback) => {
        committeeDetailsActions.postCopyMembersToCommitteeAction(data, callback, dispatch);
    },
    getInActiveCommitteeMasterData: (commiteeId) => {
        committeeDetailsActions.getInActiveCommitteeMasterDataAction(commiteeId, dispatch)
    },
    putInactivateCommittee: (data, callback) => {
        committeeDetailsActions.putInactivateCommitteeAction(data, callback, dispatch)
    },
    putReactivateCommittee: (data, callback) => {
        committeeDetailsActions.putReactivateCommitteeAction(data, callback, dispatch);
    },
    resetLeftPanel: () => dispatch({ type: _actions.RESET_LEFT_PANEL }),
    getMemberDetails: (accountNumber) => {
        memberDetailsActions.getMemberPersonalDetailsAction(accountNumber, dispatch)
    },
    getMemberCommitteeInfoList: (accountNumber, callback) => {
        memberDetailsActions.getMemberCommitteeInfoAction(accountNumber, callback, dispatch)
    },
    getMemberMainSubCommitteesList: (accountNumber, callback) => {
        memberDetailsActions.getMemberMainSubCommitteesAction(accountNumber, callback, dispatch)
    },
    getMemberCommitteeDetails: (data) => {
        memberDetailsActions.getMemberCommitteeDetailsAction(data, dispatch);
    },
    getMemberOfficerTitle: (data, callback) => {
        memberDetailsActions.getMemberOfficerTitleAction(data, callback, dispatch);
    },
    getCommonCommitteeClassificationTypeAndNoVoteReason: (committeeId, accountNumber) => {
        memberDetailsActions.getCommonCommitteeClassificationTypeAndNoVoteReasonAction(committeeId, accountNumber, dispatch);
    },
    getActiveFeeGroupList: (callback) => {
        memberDetailsActions.getActiveFeeGroupList(callback, dispatch)
    },
    getOfficerTitleListAndInactiveReasonList: (data) => {
        memberDetailsActions.getOfficerTitleListAndInactiveReasonListAction(data, dispatch);
    },
    getMemberCommitteeHistoryDetails: (accountNumber, committeeId) => {
        memberDetailsActions.getMemberCommitteeHistoryDetailsAction(accountNumber, committeeId, dispatch);
    },
    getActivityList: (data, callback) => {
        memberDetailsActions.getActivityList(data, callback, dispatch)
    },
    updateMemberCommitteeBasicDetails: (data, callback) => {
        memberDetailsActions.updateMemberCommitteeBasicDetailsAction(data, callback, dispatch);
    },
    getMemberCommitteeOfficerTitleHistoryDetails: (accountNumber, commentId) => {
        memberDetailsActions.getMemberCommitteeOfficerTitleHistoryDetailsAction(accountNumber, commentId, dispatch);
    },
    getOfficerResponsibilities: (data, callback) => {
        memberDetailsActions.getOfficerResponsibilitiesAction(data, callback, dispatch);
    },
    updateMemberCommitteeGeneralInfo: (data, callback) => {
        memberDetailsActions.putMemberCommitteeGeneralInfoAction(data, callback, dispatch)
    },
    updateCommitteeActivityInfo: (data, callback) => {
        memberDetailsActions.updateCommitteeActivityInfo(data, callback, dispatch)
    },
    updateResponsibilties: (data) => {
        memberDetailsActions.updateResponsibilties(data, dispatch);
    },
    getSearchActiveStandardCommittees: (callback) => {
        committeeDetailsActions.getSearchActiveStandardCommitteesAction(callback, dispatch);
    },
    getPrimaryActivityList: (membershipTypeId, callback) => { manualOrderActions.getPrimaryActivityListAction(membershipTypeId, callback, dispatch) },
    getOrganizationList: (orgName, callback) => {
        manualOrderActions.getOrganizationListAction(orgName, callback, dispatch)
    },
    getMemberAccountNumbers: (data, callback) => {
        memberDetailsActions.getMemberAccountNumbers(data, callback, dispatch);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommitteeDetails);
