import { getMessage, routeNavigationUrl } from 'helpers/util-common';
import { membershipTypeJSON, MembershipTypes } from '../../members.model';
import { getOrganizations, onOrganizationChange } from '../membership-type-edit/function';

let self = null;
let selectedOrganization = '';

export const setInstance = (instance) => {
    self = instance;
}

export const onChange = (e, value) => {
    selectedOrganization = '';
    onOrganizationChange(value);
}

export const onClose = () => {
    self.props.closeModal();
}

export const onOrganizationSelect = (result) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    if (result.RepName && result.RepName.length > 0) {
        let alertMessage = getMessage(self.props.messageCodes, '9026.text');
        let orgLink = `<a className='linkTxt vAuto' title='${result.OrgName}' href='${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW, [result.OrgAccountNumber])}'>Organization</a>`;
        alertMessage = alertMessage.replace('@OrganizationLink', orgLink);
        if (result.MembershipTypeId === MembershipTypes.cooperativeAgreementRep) {
            alertMessage = alertMessage.replaceAll('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
        } else {
            alertMessage = alertMessage.replace('@membertype', membershipTypeJSON.represntative.displayName);
        }

        self.props.showAlertPopup(alertMessage);
    } else {
        selectedOrganization = result.OrgAccountNumber;
        self.setState({ showErrorMessage: false })
        self.props.change('Organization', result.OrgName);
        getOrganizations(result.OrgName);
    }
}

export const onSubmitHandler = () => {
    if (selectedOrganization !== '') {
        self.props.closeModal(selectedOrganization);
    } else {
        self.setState({ showErrorMessage: true })
    }
}
