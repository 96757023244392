import React from "react";
import { Grid } from 'semantic-ui-react'
import { Field } from 'redux-form';
import { getMessage, handleOnChangeOrgName, handleAutoComplete } from "helpers/util-common";
import RenderInputField from 'shared-components/Input';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea';
import AutoSearch from 'shared-components/Search';

class ConsultantFormFirst extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationList: [],
            isOrgLoading: false
        }
    }
    componentDidMount() {
        let isPrimarilyConsultant ='';
        handleAutoComplete()
        const orderFormData = this.props.orderFormData
        if(orderFormData && orderFormData.memberTempInformationDetails
            && orderFormData.memberTempInformationDetails.FormData &&
           orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification
            ){
        isPrimarilyConsultant =
        orderFormData.memberTempInformationDetails.FormData
          .CommitteeDetails[this.props.item].CommitteeMemberClassification
          .PrimarilyConsultant === true ? "2" : orderFormData.memberTempInformationDetails.FormData
          .CommitteeDetails[this.props.item].CommitteeMemberClassification
          .PrimarilyConsultant === false ? "1" : "";
      this.onChangeProduceProducts(
        undefined,
        isPrimarilyConsultant,
        undefined,
        "organizationPrimarilyProduceProducts" + this.props.item
      );
      setTimeout(() => {
        this.props.change("organizationPrimarilyProduceProducts"+this.props.item, isPrimarilyConsultant)
    }, 50);
            }

            if(orderFormData && orderFormData.memberTempInformationDetails
                && orderFormData.memberTempInformationDetails.FormData &&
                orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
                orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification &&
                orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.BusinessActivity
                ){

      const businessActivity =
      orderFormData.memberTempInformationDetails.FormData
        .CommitteeDetails[this.props.item].CommitteeMemberClassification.BusinessActivity;

    this.onChangeProductSell(
      undefined,
      businessActivity,
      undefined,
      isPrimarilyConsultant > 1 ? "productSells"+this.props.item : "businessActivity" + this.props.item
    );
    this.props.change(isPrimarilyConsultant > 1 ? "productSells"+this.props.item : "businessActivity" + this.props.item, businessActivity)
    }

    if(orderFormData && orderFormData.memberTempInformationDetails
        && orderFormData.memberTempInformationDetails.FormData &&
        orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
        orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification &&
        orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.NameofOrganization
        ){
    const nameOfOrg = orderFormData.memberTempInformationDetails.FormData
    .CommitteeDetails[this.props.item].CommitteeMemberClassification.NameofOrganization
    this.setState({ organizationList:[{title:nameOfOrg}] },
        () => {
        this.props.change('organizationNameYouRepresent'+this.props.item, nameOfOrg);
    });
    }
    }

    resetAllState = (item) => {
        this.setState({
            ['productSells'+item]: '',
            ['businessActivity'+item]: ''
        }, () => {
            this.props.change('productSells'+item, this.state['productSells'+item]);
            this.props.change('businessActivity'+item, this.state['businessActivity'+item]);
        });
    }
    onChangeConsultantDetails = (event, value, preValue, name) => {
        this.setState({ [name]: value });
    };
    onChangeProduceProducts = (event, value, preValue, name) => {
        this.setState({ [name]: value });
        let item=name.replace('organizationPrimarilyProduceProducts', '')
        this.resetAllState(item)
    };
    onChangeProductSell = (event, value, preValue, name) => {
        this.setState({ [name]: value });
    };
    handleOnResultSelect = (result, _self, pane) => {
        let { organizationList } = _self.state;
        organizationList = organizationList.filter(item => item.title === result.title)
        _self.setState({ organizationList });
        _self.props.change('organizationNameYouRepresent'+pane, result.title);
        _self.props.change('_validationHack'+pane, Date.now()); // Used this to call validation when search and select values are same
    }

    render() {
        const { item, messageCodes, optionsYesNo } = this.props;
        const { organizationList, isOrgLoading } = this.state;
        return (
            <React.Fragment>
                <Grid.Row>
                    <Grid.Column>
                        <label class="label"><i aria-hidden="true" class="asterisk  icon"></i>Name of the Organization you represent</label>
                        <Field name={"organizationNameYouRepresent"+item}
                            type="text"
                            label="Name of the Organization you represent"
                            component={AutoSearch}
                            placeholder='Please provide the organization name or select from the list'
                            required={true}
                            source={organizationList}
                            showNoResults={false}
                            isLoading={isOrgLoading}
                            onChange={(e, value) => handleOnChangeOrgName(this, value)}
                            onResultSelect={(result) => this.handleOnResultSelect(result, this, item)}
                            className="autocompleteOff"
                            maxLength={255}
                        />
                    </Grid.Column>

                    <Grid.Column className='mt10'>
                        <Field
                            name={"organizationWebsiteConsultantDetails"+item}
                            label="Organization Website"
                            component={RenderInputField}
                            required={false}
                            onChange={this.onChangeConsultantDetails}
                            maxLength={255}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="mt10">
                    <Grid.Column className='mb10'>
                        <div className="radioWrap" >
                            <Field
                                name={"organizationPrimarilyProduceProducts"+item}
                                label={getMessage(messageCodes, '9119.text')}
                                type="radio"
                                className="mr10"
                                component={RenderRadioField}
                                required={true}
                                selectedValue={this.state['organizationPrimarilyProduceProducts'+item]}
                                onChange={this.onChangeProduceProducts}
                                options={optionsYesNo}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {this.state['organizationPrimarilyProduceProducts'+item] &&
                    <Grid.Row className='mb20'>
                        <Grid.Column>
                            <Field
                                name={(Number(this.state['organizationPrimarilyProduceProducts'+item]) > 1) ? 'productSells'+item : 'businessActivity'+item}
                                label={(Number(this.state['organizationPrimarilyProduceProducts'+item]) > 1) ? 'What does the Organization(s) you represent produce/sell?' : 'Describe the business activities of the organization you represent.'}
                                component={TextArea}
                                required={true}
                                onChange={this.onChangeProductSell}
                                maxLength={255}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }
            </React.Fragment>
        );
    }
}

export default ConsultantFormFirst;
