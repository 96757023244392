import React from "react";
import { Grid, Icon } from 'semantic-ui-react';
import { getPaidStatusName } from '../../../members/common-functions';
import { PaidStatus } from '../../../members/members.model';
import { ActiveOrganizationStatusId } from '../../organizations.model'
import CustomTooltip from 'shared-components/Tooltip';
import { COMPANY_TYPE } from '../../../../../models/common.models';

const OrganizationViewAccountDetails = ({ initialValues, onEditAccountInfo, onClickAccountStatusHistory, hasUpdateAccess}) => {
    const paidStatus = getPaidStatusName(initialValues.PaidStatusId);
    const isFacility = initialValues.CompanyTypeId === COMPANY_TYPE.FACILITY;
    return (
        <div>
            <h5>{isFacility ? 'Account Status Details ' : 'Member Account Status Details'}
                {isFacility && initialValues.StatusName.toLowerCase() === 'active' && <CustomTooltip content='The Facility account status cannot be updated to historical as there are active members associated with it.' icon={<Icon name="info circle" className="ml5 skyBlue vTextTop pointer" />} />}
                {initialValues.AccountNumber && !(initialValues.McsStatusMasterId === ActiveOrganizationStatusId && initialValues.RepAccountNumber) && hasUpdateAccess && (
                    <a data-testid='btnEditPaidStatus' title="Edit" className={`${isFacility && initialValues.StatusName.toLowerCase() === 'active' ? 'editBtn disabledRow' : 'editBtn'}`} onClick={onEditAccountInfo}>
                        <Icon name="pencil" />
                    </a>)}
            </h5>
            {!isFacility && <Grid divided='vertically' columns={4}>
                <Grid.Row>
                    <Grid.Column>
                        <a className="linkTxt" onClick={onClickAccountStatusHistory}>View Organization Status History</a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <span className="labelTitle">Account Status</span>
                        <span className="labelValue viewModeData">
                            {initialValues.StatusName}
                        </span>
                    </Grid.Column>
                    {initialValues.McsStatusMasterId !== ActiveOrganizationStatusId && (
                        <Grid.Column>
                            <span className="labelTitle">Historical Reason</span>
                            <span className="labelValue viewModeData">
                                {initialValues.HistoricalReason}
                            </span>
                        </Grid.Column>
                    )}
                    <Grid.Column>
                        <span className="labelTitle">Paid Status</span>
                        <span className="labelValue viewModeData">
                            {paidStatus}
                        </span>
                    </Grid.Column>
                    <Grid.Column>
                        <span className="labelTitle">Paid Date</span>
                        {initialValues.PaidStatusId === PaidStatus.Paid && (
                            <span className="labelValue">
                                {initialValues.PaidDate}
                            </span>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>}
            {isFacility && <Grid divided='vertically' columns={4}>
                <Grid.Row>
                    <Grid.Column>
                        <span className="labelTitle">Account Status</span>
                        <span className="labelValue viewModeData">
                            {initialValues.StatusName}
                        </span>
                    </Grid.Column>
                    {initialValues.StatusName.toLowerCase() !== 'active' && (
                        <Grid.Column>
                            <span className="labelTitle">Historical Reason</span>
                            <span className="labelValue viewModeData">
                                {initialValues.HistoricalReason}
                            </span>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>}
        </div>
    )
}

export default OrganizationViewAccountDetails;
