import { encrypt, formatListDataForAutoSearch } from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds } from 'models/common.models';
import { change } from 'redux-form';
import { StatusFilter, PaidStatus, AutoRenewal } from '../../feegroups.model'

export let TIMEOUT = null;
let self = null;
let prevFirstName = '';
let prevLastName = '';
let prevContactName = '';
let prevAccountNumber = '';
let prevAssociateFirstName = '';
let prevAssociateLastName = '';
let FeeGroupArray = []

export const setClassInstance = (instance) => {
    self = instance;
    FeeGroupArray = self.state.feeGroupListArray
}

export const loadInitialData = () => {
    if(self.props.feeGroupList.length === 0){
        self.props.getFeeGroupList((result) => {
            FeeGroupArray = result
        })
    }
}

export const getFirstName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevFirstName) {
        self.setState({
            isLoadingFirstList: value !== '',
            firstNameList: [],
            isShowFirstNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevFirstName) {
            if(FeeGroupArray.length > 0){
                let dataAfterFilter = FeeGroupArray.filter(feeGroup => feeGroup.FeeGroupNumber.toLowerCase().includes(value.toLowerCase()));
                if(dataAfterFilter.length > 0){
                    self.setState({
                        firstNameList: formatListDataForAutoSearch(dataAfterFilter, 'FeeGroupNumber'),
                        isLoadingFirstList: false,
                        isShowFirstNameErrorMsg: false
                    });
                }else{
                    self.setState({
                        isShowFirstNameErrorMsg: true,
                        isLoadingFirstList: false
                    });
                }
            }else{
                self.setState({
                    isShowFirstNameErrorMsg: true,
                    isLoadingFirstList: false
                });
            }
        }

        prevFirstName = value;
    }, 300);
}

export const getLastName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevLastName) {
        self.setState({
            isLoadingLastList: value !== '',
            lastNameList: [],
            isShowLastNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevLastName) {
            if(FeeGroupArray.length > 0){
                let dataAfterFilter = FeeGroupArray.filter(feeGroup => feeGroup.FeeGroupTitle.toLowerCase().includes(value.toLowerCase()));
                if(dataAfterFilter.length > 0){
                    self.setState({
                        lastNameList: formatListDataForAutoSearch(dataAfterFilter, 'FeeGroupTitle'),
                        isLoadingLastList: false,
                        isShowLastNameErrorMsg: false
                    });
                }else{
                    self.setState({
                        isShowLastNameErrorMsg: true,
                        isLoadingLastList: false
                    });
                }
            }else{
                self.setState({
                    isShowLastNameErrorMsg: true,
                    isLoadingLastList: false
                });
            }
        }

        prevLastName = value;
    }, 300);
}

export const getContactName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevContactName) {
        self.setState({
            isLoadingContactList: value !== '',
            contactNameList: [],
            isShowContactNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevContactName) {
            if(FeeGroupArray.length > 0){
                let dataAfterFilter = FeeGroupArray.filter(feeGroup => feeGroup.ContactPersonName?.toLowerCase().includes(value.toLowerCase()));
                if(dataAfterFilter.length > 0){
                    self.setState({
                        contactNameList: formatListDataForAutoSearch(dataAfterFilter, 'ContactPersonName'),
                        isLoadingContactList: false,
                        isShowContactNameErrorMsg: false
                    });
                }else{
                    self.setState({
                        isShowContactNameErrorMsg: true,
                        isLoadingContactList: false
                    });
                }
            }else{
                self.setState({
                    isShowContactNameErrorMsg: true,
                    isLoadingContactList: false
                });
            }
        }

        prevContactName = value;
    }, 300);
}

export const getAssociateAccountNumber = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevAccountNumber) {
        self.setState({
            isLoadingAssociateAccountList: value !== '',
            associateAccountNumberList: [],
            isShowAssociateAccountNumberErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevAccountNumber) {
            if(FeeGroupArray.length > 0){
                let dataAfterFilter = []
                for(const feeGroup of FeeGroupArray){
                    let associateMembers = feeGroup.Members
                    const filterAccountData = associateMembers.filter( member => member.AccountNumber.toLowerCase().includes(value.toLowerCase()))
                    if(filterAccountData.length > 0){
                        dataAfterFilter = [...dataAfterFilter, ...filterAccountData ]
                    }
                }
                if(dataAfterFilter.length > 0){
                    self.setState({
                        associateAccountNumberList: formatListDataForAutoSearch(dataAfterFilter, 'AccountNumber'),
                        isLoadingAssociateAccountList: false,
                        isShowAssociateAccountNumberErrorMsg: false
                    });
                }else{
                    self.setState({
                        isShowAssociateAccountNumberErrorMsg: true,
                        isLoadingAssociateAccountList: false
                    });
                }
            }else{
                self.setState({
                    isShowAssociateAccountNumberErrorMsg: true,
                    isLoadingAssociateAccountList: false
                });
            }
        }

        prevAccountNumber = value;
    }, 300);
}

export const getAssociateFirstName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevAssociateFirstName) {
        self.setState({
            isLoadingAssociateFistList: value !== '',
            associateFistNameList: [],
            isShowAssociateFirstNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevAssociateFirstName) {
            if(FeeGroupArray.length > 0){
                let dataAfterFilter = []
                for(const feeGroup of FeeGroupArray){
                    let associateMembers = feeGroup.Members
                    const filterAccountData = associateMembers.filter( member => member.FirstName?.toLowerCase().includes(value.toLowerCase()))
                    if(filterAccountData.length > 0){
                        dataAfterFilter = [...dataAfterFilter, ...filterAccountData ]
                    }
                }
                if(dataAfterFilter.length > 0){
                    self.setState({
                        associateFistNameList: formatListDataForAutoSearch(dataAfterFilter.slice(0, 10), 'FirstName'),
                        isLoadingAssociateFistList: false,
                        isShowAssociateFirstNameErrorMsg: false
                    });
                }else{
                    self.setState({
                        isShowAssociateFirstNameErrorMsg: true,
                        isLoadingAssociateFistList: false
                    });
                }
            }else{
                self.setState({
                    isShowAssociateFirstNameErrorMsg: true,
                    isLoadingAssociateFistList: false
                });
            }
        }

        prevAssociateFirstName = value;
    }, 300);
}

export const getAssociateLastName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevAssociateLastName) {
        self.setState({
            isLoadingAssociateLastList: value !== '',
            associateLastNameList: [],
            isShowAssociateLastNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevAssociateLastName) {
            if(FeeGroupArray.length > 0){
                let dataAfterFilter = []
                for(const feeGroup of FeeGroupArray){
                    let associateMembers = feeGroup.Members
                    const filterAccountData = associateMembers.filter( member => member.LastName?.toLowerCase().includes(value.toLowerCase()))
                    if(filterAccountData.length > 0){
                        dataAfterFilter = [...dataAfterFilter, ...filterAccountData ]
                    }
                }
                if(dataAfterFilter.length > 0){
                    self.setState({
                        associateLastNameList: formatListDataForAutoSearch(dataAfterFilter.slice(0, 10), 'LastName'),
                        isLoadingAssociateLastList: false,
                        isShowAssociateLastNameErrorMsg: false
                    });
                }else{
                    self.setState({
                        isShowAssociateLastNameErrorMsg: true,
                        isLoadingAssociateLastList: false
                    });
                }
            }else{
                self.setState({
                    isShowAssociateLastNameErrorMsg: true,
                    isLoadingAssociateLastList: false
                });
            }
        }

        prevAssociateLastName = value;
    }, 300);
}

export const onSubmitHandler = (values, dispatch, props) => {
    const searchQuery = getSearchQuery(values);
    props.onAdvanceSearchSubmit(searchQuery);
}

export const resetForm = () => {

    Object.keys(self.props.initialValues).forEach(fieldKey => {
        let value = '';
        if (fieldKey === 'Status') {
            value = StatusFilter[1].value;
        }
        self.props.dispatch(change(self.props.form, fieldKey, value));
    });
    self.setState({ isResetClicked: true });
    const searchQuery = getSearchQuery({});
    self.props.onResetClick(searchQuery);
}

const getSearchQuery = (values) => {
    let queries = [];
    queries.push('iisAdvanceSearch=true');
    queries.push(`${AdvanceSearchQueryFields.GridId}=${AppGridIds.FeeGroupGrid}`);
    if (values.FeeGroupNumber && values.FeeGroupNumber.trim().length > 0) {
        queries.push(`feeGroupNumber=${encrypt(values.FeeGroupNumber.trim())}`);
    }
    if (values.FeeGroupTitle && values.FeeGroupTitle.trim().length > 0) {
        queries.push(`feeGroupTitle=${encrypt(values.FeeGroupTitle.trim())}`);
    }
    if (values.Status ) {
        queries.push(`status=${values.Status.trim()}`);
    }else{
        queries.push(`status=${StatusFilter[1].value}`);
    }

    if (values.PaidStatus ) {
        queries.push(`paidStatus=${values.PaidStatus.trim()}`);
    }else {
        queries.push(`paidStatus=${PaidStatus[0].value}`);
    }

    if (values.AutoRenewal) {
        queries.push(`autoRenewal=${values.AutoRenewal}`);
    } else {
        queries.push(`autoRenewal=${AutoRenewal[0].value}`);
    }

    if (values.Country) {
        queries.push(`country=${values.Country}`);
    }

    if (values.ContactName && values.ContactName.trim().length > 0) {
        queries.push(`contactName=${encrypt(values.ContactName.trim())}`);
    }
    if (values.AssociatedMemberAccountNo && values.AssociatedMemberAccountNo.trim().length > 0) {
        queries.push(`associatedMemberAccountNo=${encrypt(values.AssociatedMemberAccountNo)}`);
    }

    if (values.AssociatedMemberFirstName && values.AssociatedMemberFirstName.trim().length > 0) {
        queries.push(`associatedMemberFirstName=${encrypt(values.AssociatedMemberFirstName.trim())}`);
    }
    if (values.AssociatedMemberLastName && values.AssociatedMemberLastName.trim().length > 0) {
        queries.push(`associatedMemberLastName=${encrypt(values.AssociatedMemberLastName.trim())}`);
    }

    return queries.join('&');
}
