import moment from 'moment';
import { getMessage, toDateWithTime, formatDateToMMDDYYYY, getSortOrderFromHeaderClick } from 'helpers/util-common';
import { minDateForInputDateFields } from 'models/common.models';

let self = {};
export const setClassInstance = (instance) => {
    self = instance;
}

export const loadInitialList = () => {
    const accountNumber = self.props.initialValues.AccountNumber;
	const joinDate = self.props.initialValues.JoinDate;
	const currentDate = formatDateToMMDDYYYY();
    self.props.getMemberOrderHistory(accountNumber, (orderHistoryData) => {
        let defaultOrderList = [];
        orderHistoryData.OrderDetail = orderHistoryData.OrderDetail ? orderHistoryData.OrderDetail : [];
        orderHistoryData.OrderDetail.map(order => {
            order.Items.map(item => defaultOrderList.push({ OrderNo: order.OrderNo, TimeStamp: order.TimeStamp, OrderTimeStamp: order.OrderTimeStamp, ItemName: item.ItemName, Description: item.Description, ShipToAddress: order.ShipToAddress }))
        });
        defaultOrderList = (defaultOrderList && defaultOrderList.length > 0) ? defaultOrderList.sort((a, b) => new Date(b.OrderTimeStamp) - new Date(a.OrderTimeStamp)) : [];
        self.setState({
            orderHistoryList: defaultOrderList ? defaultOrderList : [],
            backupOrderHistoryList: defaultOrderList ? defaultOrderList : []
        });
    },joinDate,currentDate);
}

export const sortBy = (event, key) => {
    let orderType = getSortOrderFromHeaderClick(event);

    const orderHistoryData = self.state.orderHistoryList
    if (orderHistoryData && orderHistoryData.length > 0) {
        let sortkey = key.split(' ').join('');
        if (orderType === "0" && sortkey !== self.state.sortKey) {
            if (sortkey === 'TimeStamp') {
                orderHistoryData.sort((a, b) => new Date(a.OrderTimeStamp) - new Date(b.OrderTimeStamp));
            } else {
                orderHistoryData.sort((a, b) => a[sortkey].localeCompare(b[sortkey]));
            }
        } else {
            orderHistoryData.reverse((a, b) => a[sortkey].localeCompare(b[sortkey]));
        }
        self.setState({
            sortKey: sortkey,
            orderHistoryList: orderHistoryData
        });
    }
}

// Export Excel Doc Fuction
export const exportExcel = () => {
    let ExportedData = [];

    // Prepare Exported Data For Excel
    if (self.state.orderHistoryList && self.state.orderHistoryList.length > 0) {
        self.state.orderHistoryList.forEach(outerelement => {
            ExportedData.push({ "OrderNo": outerelement.OrderNo, "ItemName": outerelement.ItemName, "Description": outerelement.Description, "ShipToAddress": outerelement.ShipToAddress, "TimeStamp": toDateWithTime(outerelement.OrderTimeStamp) });
        });
    }
    if (ExportedData.length > 0) {
        self.setState({ isExportExcel: true, exportedData: ExportedData }, () => resetExcelExport());
    }
    else {
        self.setState({ isOpenExcelDownloadConfirmBox: true });
    }
}

export const resetExcelExport = () => {
    setTimeout(() => { self.setState({ isExportExcel: false }) }, 100);
}

export const validateFilterForm = (values, props) => {
    const errors = {};
    if (values.dateFrom && !moment(values.dateFrom, 'MM/DD/YYYY', true).isValid()) {
        errors.dateFrom = getMessage(props.messageCodes, '4003.text');
    }
    if (values.dateTo && !moment(values.dateTo, 'MM/DD/YYYY', true).isValid()) {
        errors.dateTo = getMessage(props.messageCodes, '4003.text');
    }
    if (moment(values.dateFrom, 'MM/DD/YYYY', true).isValid() && moment(values.dateTo, 'MM/DD/YYYY', true).isValid() &&
        moment(values.dateFrom, 'MM/DD/YYYY', true) > moment(values.dateTo, 'MM/DD/YYYY', true)) {
        errors.dateTo = getMessage(props.messageCodes, '4002.text');
    }
    return errors;
}

// For filter member order history
export const filterOrderHistory = (value) => {
    let tempOrderlist = self.state.backupOrderHistoryList;
    if (tempOrderlist && tempOrderlist.length > 0) {

        tempOrderlist = tempOrderlist.filter((a) => ((value.itemName && a.ItemName.toLowerCase().startsWith(value.itemName.toLowerCase())) || !value.itemName)
            && ((value.description && a.Description.toLowerCase().startsWith(value.description.toLowerCase())) || !value.description)
            && ((value.dateFrom && new Date(formatDateToMMDDYYYY(a.OrderTimeStamp)) >= new Date(value.dateFrom)) || !value.dateFrom)
            && ((value.dateTo && new Date(formatDateToMMDDYYYY(a.OrderTimeStamp)) <= new Date(value.dateTo)) || !value.dateTo));

        self.setState({ orderHistoryList: tempOrderlist });
    }
}

export const resetFilter = () => {
    self.props.reset();
    let tempOrderlist = self.state.backupOrderHistoryList;
    self.setState({ orderHistoryList: tempOrderlist, dateFrom: '', dateTo: '' });
}
export const OnChangeDateFrom = (e, value) => {
    if (value && moment(value, 'MM/DD/YYYY', true).isValid()) {
       self.setState({ dateFrom:  value ? formatDateToMMDDYYYY(value) : minDateForInputDateFields  });
    } else {
        self.setState({ dateFrom: '' })
    }

}
export const OnChangeDateTo = (e, value) => {

    if (value && moment(value, 'MM/DD/YYYY', true).isValid()) {
        self.setState({ dateTo: value });
    } else {
        self.setState({ dateTo: '' });
    }
}
