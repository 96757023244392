import React, { Component } from "react";
import { Form, Button, Confirm } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import RenderInputField from 'shared-components/Input';
import DateTimePicker from 'shared-components/DatePicker';
import { getMessage, toDateWithTime, dateMasking, formatDateToMMDDYYYY } from 'helpers/util-common';
import { minDateForInputDateFields } from 'models/common.models';
import * as _func from './function';
import { OrderTableColumn } from '../../members.model'
import TableHeader from 'shared-components/Table/tableHeader';
import OrderHistoryExcelExport from '../order-history-export-excel';
import CustomTooltip from 'shared-components/Tooltip';
class MemberOrderHistory extends Component {

    constructor(props) {

        super(props);

        this.state = {
            orderHistoryList: [],
            backupOrderHistoryList: [],
            sortKey: '',
            dateFrom: '',
            dateTo: '',
            isExportExcel: false,
            exportedData: [],
            isOpenExcelDownloadConfirmBox: false,
            maxDate: formatDateToMMDDYYYY(),
            orderTableColumn: OrderTableColumn.map((item) => {
                if (item.sortKey === 'TimeStamp') {
                    item.orderBy = 1;
                    item.orderByIcon = 2;
                } else {
                    item.orderBy = 0;
                    item.orderByIcon = 0;
                }
                return item;
            })
        }

        this.emptyExportDataCancelHandler = this.emptyExportDataCancelHandler.bind(this);
        this.emptyExportDataConfirmHandler = this.emptyExportDataConfirmHandler.bind(this);
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.loadInitialList();
    }

    // Export Excel Confirm Box Cancel Event
    emptyExportDataCancelHandler = () => {
        this.setState({ isOpenExcelDownloadConfirmBox: false });
    }

    // Export Excel Confirm Box Ok Event
    emptyExportDataConfirmHandler = () => {
        this.setState({ isExportExcel: true, isOpenExcelDownloadConfirmBox: false, exportedData: [] }, () => _func.resetExcelExport());
    }

    onClickOrderHistorySearch = (value) => {
        _func.filterOrderHistory(value);
    }

    resetValues = () => {
        _func.resetFilter(this);
    }

    render() {
        const { orderHistoryList, isExportExcel, exportedData, isOpenExcelDownloadConfirmBox, orderTableColumn, dateFrom, dateTo, maxDate } = this.state;


        const { initialValues, handleSubmit, messageCodes, submitting, hasExportAccess } = this.props;
        return (
            <div className="memberOrderHistory" data-testid="memberOrderHistory">
                <Form onSubmit={handleSubmit(_func.filterOrderHistory)} noValidate >
                    <div className="auditFilter mb20">

                        <div className="colWrap mr10">
                            <Field name="itemName" type="text"
                                component={RenderInputField}
                                label="Item Name"
                            />

                        </div>
                        <div className="colWrap mr10">
                            <Field name="description" type="text"
                                component={RenderInputField}
                                label="Description"
                            />
                        </div>
                        <div className="colWrap mr10">
                            <Field name="dateFrom"
                                component={DateTimePicker}
                                showTime={false}
                                normalize={dateMasking}
                                onChange={_func.OnChangeDateFrom}
                                label="From"
                                maxDate={maxDate}
                            />
                        </div>

                        <div className="colWrap">
                            <Field name="dateTo"
                                component={DateTimePicker}
                                showTime={false}
                                normalize={dateMasking}
                                onChange={_func.OnChangeDateTo}
                                label="To"
                                maxDate={maxDate}
                                minDate={(dateFrom && dateFrom !== null && dateFrom !== '') ? dateFrom : minDateForInputDateFields}
                            />
                        </div>
                        <div className="auditAction">
                            <Button data-testid="btnReset" className="cancel" type="reset" onClick={_func.resetFilter} >Reset</Button>
                            <Button data-testid="btnSearch" className="primary ml5" disabled={submitting} type="submit" >Search</Button>
                        </div>
                    </div>
                </Form>
                {hasExportAccess && (
                    <div className="txtRight mt-10">
                        <span className="label2  mr10 vMiddle display-inline-block">Export as:</span> <Button data-testid="btnExcel" className="ui button secondary mr10" type="button" onClick={_func.exportExcel} ><i className="icon file excel mr5"></i>Excel</Button>

                    </div>
                )}
                <div className="tableWrapper mt0">
                    <table className="customTable memHisTable">
                        <thead>
                            <tr>
                                <TableHeader headerProps={orderTableColumn} onClickProps={_func.sortBy} ></TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {(orderHistoryList && orderHistoryList.length > 0) && orderHistoryList.map((item) => {
                                return <tr>
                                    <td>{item.OrderNo}</td>
                                    <td>{item.ItemName}</td>
                                    <td>{item.Description}</td>
                                    <td>
                                        {(item.ShipToAddress && item.ShipToAddress.length > 110) ? <CustomTooltip icon={<span>{item.ShipToAddress.substring(0, 110) + '' + item.ShipToAddress.substring(0, 110) + '...'}</span>} content={item.ShipToAddress} /> : item.ShipToAddress}
                                    </td>
                                    <td>{toDateWithTime(item.OrderTimeStamp)}</td>
                                </tr>

                            })}

                        </tbody>

                    </table>
                    {(!orderHistoryList || orderHistoryList.length === 0) &&
                        <div className="noRecordMessage">{getMessage(messageCodes, '9010.text')}</div>
                    }
                </div>
                {isExportExcel &&
                    <OrderHistoryExcelExport data={exportedData} AccountNo={initialValues.AccountNumber}></OrderHistoryExcelExport>
                }

                <Confirm className="confirm-box"
                    open={isOpenExcelDownloadConfirmBox}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={this.emptyExportDataCancelHandler}
                    onConfirm={this.emptyExportDataConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

            </div >
        );
    }
}

export default reduxForm({
    form: 'memberOrderHistory',
    validate: _func.validateFilterForm
})(MemberOrderHistory);
