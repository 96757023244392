// Login
export const LOGIN = "LOGIN";
export const AZURE_LOGIN = "AZURE_LOGIN";
export const AZURE_REFRESH_TOKEN = "AZURE_REFRESH_TOKEN";
// Common
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_LOADER = "SET_LOADER";
export const SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
export const SHOW_ALERT_POPUP = "SHOW_ALERT_POPUP";
export const SET_MINI_LOADER = "SET_MINI_LOADER";
export const SHOW_NOT_FOUND_PAGE = "SHOW_NOT_FOUND_PAGE";
export const HIDE_NOT_FOUND_PAGE = "HIDE_NOT_FOUND_PAGE";
export const GET_APP_USER_LIST = "GET_APP_USER_LIST";
export const IS_SAFARI_AUTOFILL_VISISBLE = "IS_SAFARI_AUTOFILL_VISISBLE";

// Renewal tasks details
export const GET_RENEWAL_TASK_DETAILS = "GET_RENEWAL_TASK_DETAILS";
export const GET_ONLY_RENEWAL_TASK_DETAILS = "GET_ONLY_RENEWAL_TASK_DETAILS";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const GET_RENEWAL_YEARS_LIST = "GET_RENEWAL_YEARS_LIST";
export const GET_TASK_WATCHERS_LIST = "GET_TASK_WATCHERS_LIST";
export const OPEN_CONFIRMATION_POPUP = "OPEN_CONFIRMATION_POPUP";
export const CLOSE_CONFIRMATION_POPUP = "CLOSE_CONFIRMATION_POPUP";
export const SET_CURRENT_REQUEST_DATA = "SET_CURRENT_REQUEST_DATA";
export const UPDATE_TASK_DETAILS_SUCCESS = "UPDATE_TASK_DETAILS_SUCCESS";
export const UPDATE_TASK_ASSIGNEE_STATUS_SUCCESS =
  "UPDATE_TASK_ASSIGNEE_STATUS_SUCCESS";
export const RESET_TASK_DETAILS_DATA = "RESET_TASK_DETAILS_DATA";
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS";
export const CLEAR_DOCUMENTS_LIST = "CLEAR_DOCUMENTS_LIST";

// Add Renewal task Action
export const ADD_RENEWAL_TASK_ACTION = "ADD_RENEWAL_TASK_ACTION";
export const CLOSE_POPUP_RENEWAL_TASK_ACTION =
  "CLOSE_POPUP_RENEWAL_TASK_ACTION";
export const RESET_ADD_TASK_STORE = "RESET_ADD_TASK_STORE";
export const GET_DEPENDENT_RENEWAL_TASK_ACTION =
  "GET_DEPENDENT_RENEWAL_TASK_ACTION";
export const GET_USER_RENEWAL_TASK_ACTION = "GET_USER_RENEWAL_TASK_ACTION";
export const ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_SUCCESS =
  "ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_SUCCESS";
export const ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_FAILURE =
  "ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_FAILURE";
export const OPEN_DEPENDS_ON_TASK_POPUP = "OPEN_DEPENDS_ON_TASK_POPUP";
export const CLOSE_DEPENDS_ON_TASK_POPUP = "CLOSE_DEPENDS_ON_TASK_POPUP";
export const SET_RENEWAL_TASK_DETAILS_INITIAL_STATE =
  "SET_RENEWAL_TASK_DETAILS_INITIAL_STATE";

// User Manage Action List
export const USER_ROLE_LIST = "USER_ROLE_LIST";
export const USER_DEFAULT_PRIVILEGE = "USER_DEFAULT_PRIVILEGE";
export const USER_PRIVILEGE_BY_ROLE = "USER_PRIVILEGE_BY_ROLE";
export const USER_PRIVILEGE_BY_USER = "USER_PRIVILEGE_BY_USER";
export const USER_DETAIL_WITH_PRIVILEGE = "USER_DETAIL_WITH_PRIVILEGE";
export const USER_VALIDATE_IN_AD = "USER_VALIDATE_IN_AD";
export const USER_ADD = "USER_ADD";
export const USER_CHANGE_PRIVILEGE = "USER_CHANGE_PRIVILEGE";
export const USER_ISDUPLICATE = "USER_ISDUPLICATE";
export const USER_VIEW = "USER_VIEW";
export const USER_EDIT_SUBMITED = "USER_EDIT_SUBMITED";
export const USER_LIST = "USER_LIST";

// Search related actions
export const SEARCH_TASK = "SEARCH_TASK";
export const SEARCH_MEMBERS = "SEARCH_MEMBERS";
export const SEARCH_ORGANIZATIONS = "SEARCH_ORGANIZATIONS";
export const CLEAR_SEARCH_TYPE = "CLEAR_SEARCH_TYPE";
export const SEARCH_COMMITTEES = "SEARCH_COMMITTEES";
export const SEARCH_FEEGROUP = "SEARCH_FEEGROUP";

// Role Manage Action List
export const ROLE_DETAIL = "ROLE_DETAIL";
export const ROLE_ADD = "ROLE_ADD";
export const ROLE_PRIVILEGE = "ROLE_PRIVILEGE";
export const ROLE_DUPLICATE = "ROLE_DUPLICATE";
export const ROLE_VIEW = "ROLE_VIEW";
export const ROLE_RESETVALIDATION = "ROLE_RESETVALIDATION";

// Members action list
export const GET_MEMBERSHIPTYPE_LIST = "GET_MEMBERSHIPTYPE_LIST";
export const SET_STUDENT_COMMITTEE_LIST_MASTER =
  "SET_STUDENT_COMMITTEE_LIST_MASTER";
export const GET_MEMBER_DETAILS = "GET_MEMBER_DETAILS";
export const RESET_MEMBER_DETAILS_DATA = "RESET_MEMBER_DETAILS_DATA";
export const GET_MASTER_DATA = "GET_MASTER_DATA";
export const GET_MEMBER_USER_LIST = "GET_MEMBER_USER_LIST";
export const GET_ADD_MEMBER_COMMITTEE_MASTER_DATA =
  "GET_ADD_MEMBER_COMMITTEE_MASTER_DATA";
export const GET_MEMBER_COMMITTEE_DETAILS = "GET_MEMBER_COMMITTEE_DETAILS";
export const GET_COMMON_COMMITTEE_CLASSIFICATION_AND_NO_VOTE_REASON =
  "GET_COMMON_COMMITTEE_CLASSIFICATION_AND_NO_VOTE_REASON";
export const UPDATE_MEMBER_COMMITTEE_DETAILS =
  "UPDATE_MEMBER_COMMITTEE_DETAILS";
export const GET_MEMBER_ACCOUNT_HISTORY_LIST =
  "GET_MEMBER_ACCOUNT_HISTORY_LIST";
export const RESET_LEFT_PANEL = "RESET_LEFT_PANEL";
export const GET_MEMBER_COMMITTEE_OFFICER_RESPONSIBILITIES =
  "GET_MEMBER_COMMITTEE_OFFICER_RESPONSIBILITIES";
export const GET_MEMBER_COMMITTEE_HISTORY_LIST =
  "GET_MEMBER_COMMITTEE_HISTORY_LIST";
export const GET_OFFICER_TITLE_AND_INACTIVE_REASONS_LIST =
  "GET_OFFICER_TITLE_AND_INACTIVE_REASONS_LIST";
export const GET_MEMBER_COMMITTEE_OFFICER_TITLE_HISTORY_LIST =
  "GET_MEMBER_COMMITTEE_OFFICER_TITLE_HISTORY_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_US_STATE_LIST = "GET_US_STATE_LIST";
export const GET_CANADA_STATE_LIST = "GET_CANADA_STATE_LIST";
export const GET_MEMBER_MASTER_DATA = "GET_MEMBER_MASTER_DATA";
export const GET_MEMBER_GRID_PREFERENCES = "GET_MEMBER_GRID_PREFERENCES";

// Organization action List
export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";
export const RESET_ORGANIZATION_DETAILS_DATA =
  "RESET_ORGANIZATION_DETAILS_DATA";
export const GET_ORGANIZATION_REP_HISTORY_LIST =
  "GET_ORGANIZATION_REP_HISTORY_LIST";
export const GET_ORGANIZATION_MASTER_DATA = "GET_ORGANIZATION_MASTER_DATA";

// Committee action list
export const GET_COMMITTEE_GRID_PREFERENCES = "GET_COMMITTEE_GRID_PREFERENCES";
export const GET_COMMITTEE_LIST_MASTER_DATA = "GET_COMMITTEE_LIST_MASTER_DATA";
export const GET_COMMITTEE_HIERARCHY_LIST = "GET_COMMITTEE_HIERARCHY_LIST";
export const GET_COMMITTEE_DETAILS = "GET_COMMITTEE_DETAILS";
export const GET_COMMITTEE_DETAILS_MASTER_DATA =
  "GET_COMMITTEE_DETAILS_MASTER_DATA";
export const RESET_COMMITTEE_DETAILS_DATA = "RESET_COMMITTEE_DETAILS_DATA";
export const GET_SELECTED_COMMITTEE_HIERARCHY_DETAILS =
  "GET_SELECTED_COMMITTEE_HIERARCHY_DETAILS";
export const GET_COMMITTEE_USER_LIST = "GET_COMMITTEE_USER_LIST";
export const GET_COMMITTEE_ROSTER_GRID_PREFERENCES =
  "GET_COMMITTEE_ROSTER_GRID_PREFERENCES";
export const GET_COMMITTEE_CLASSIFICATION = "GET_COMMITTEE_CLASSIFICATION";
export const GET_COMMITTEE_INLINE_MASTER_DATA =
  "GET_COMMITTEE_INLINE_MASTER_DATA";
export const GET_COMMITTEE_OFFICERTITLE = "GET_COMMITTEE_OFFICERTITLE";
export const GET_MASTER_DATA_FOR_INACTIVE_COMMITTEE =
  "GET_MASTER_DATA_FOR_INACTIVE_COMMITTEE";

// Permission Action
export const ALLOW_PERMISSION_FOR_PAGE = "ALLOW_PERMISSION_FOR_PAGE";

// Fee Group Action
export const FEEGROUP_ISDUPLICATE = "FEEGROUP_ISDUPLICATE";
export const GET_FEEGROUP_LIST = "GET_FEEGROUP_LIST";
export const GET_FEEGROUP_DETAILS = "GET_FEEGROUP_DETAILS";
export const FEEGROUP_RESET = "FEEGROUP_RESET";
export const MEMBERSHIPTYPE_LIST = "MEMBERSHIPTYPE_LIST";
export const GET_AUDIT_LOG_FEILD = "GET_AUDIT_LOG_FEILD";

// Work Item Admin
export const SET_WORK_ITEM_DETAILS = "SET_WORK_ITEM_DETAILS";
export const SET_STANDARD_TYPE_LIST = "SET_STANDARD_TYPE_LIST";
export const RESET_INITIAL_VALUES = "RESET_INITIAL_VALUES";
export const SET_MEMBER_LIST = "SET_MEMBER_LIST";
export const SET_MEMBER_LIST_RESTORE = "SET_MEMBER_LIST_RESTORE";
export const SET_COLLAB_API_FAILED = "SET_COLLAB_API_FAILED";

// Ballot Admin
export const SET_MEMBER_ACCOUNT_DETAILS = "SET_MEMBER_ACCOUNT_DETAILS";

// Membership Exceptions
export const GET_EXCEPTION_GRID_PREFERENCES = "GET_EXCEPTION_GRID_PREFERENCES";

// BOS Volume Location
export const GET_BOS_VOLUME_MASTER_LIST = "GET_BOS_VOLUME_MASTER_LIST";

// Manual Order
export const SET_JOIN_COMMITTEE = "SET_JOIN_COMMITTEE";
