import React from "react";
import ExportExcel from "shared-components/ExportExcel";
import moment from 'moment';

const AssociatedMemberExcelExport = ({ data, title }) => {
    const titleName = title.trim()
    const fileName = `${titleName}_` + moment(new Date()).format('MM-DD-YYYY');
    const tabName = titleName;

    const columns = [
        { title: 'Account', style: { font: { bold: true } } },
        { title: 'Name', style: { font: { bold: true } } },
        { title: 'Member Type', style: { font: { bold: true } } },
        { title: 'Email', style: { font: { bold: true } } },
        { title: 'Organization', style: { font: { bold: true } } },
        { title: 'Status', style: { font: { bold: true } } },
        { title: 'Paid Status', style: { font: { bold: true } } },
        { title: 'Associated Date', style: { font: { bold: true } } }
    ];

    let rows = [];
    for (let i = 0; i < data.length; i++) {
        let row = [
            { value: data[i].Account },
            { value: data[i].Name },
            { value: data[i].MemberType },
            { value: data[i].Email },
            { value: data[i].Organization },
            { value: data[i].Status },
            { value: data[i].PaidStatus },
            { value: data[i].AssociatedDate }
        ];
        rows.push(row);
    }

    // Need atleast an empty row.
    if (data.length === 0) {
        let row = [
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' }
        ];
        rows.push(row);
    }

    const multiDataSet = [{ columns: columns, data: rows }];

    return (
        <ExportExcel fileName={fileName} tabName={tabName} multiDataSet={multiDataSet} />
    );
}
export default AssociatedMemberExcelExport;
