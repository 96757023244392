import { deepCopy, getCurrentlyShowingItemsInGrid, getSortOrderFromHeaderClick, scrollToTopOfPage } from 'helpers/util-common';

let self = null;

export const setInstance = (instance) => {
    self = instance;

    getPageData(self.props.initialValues);
}

export const refreshData = (initialValues) => {
    self.setState({
        subCommitteesCountInfo: [],
        subCommitteesList: [],
        showSubCommitteesList: false,
        pageNumber: 1,
        pageSize: 25,
        currentlyShowingItems: '',
        sortKey: '',
        sortOrder: '',
        showLoader: false
    })
    scrollToTopOfPage();
    getPageData(initialValues);
}

export const setSubCommitteesCountInfo = (statisticalInfo, committeeTypeId, levelId) => {
    const { allCommitteesHierarchyDetails } = self.props;
    let subCommitteesCountInfo = [];

    if (statisticalInfo && statisticalInfo.CommitteeLevels) {
        subCommitteesCountInfo = deepCopy(statisticalInfo.CommitteeLevels);
    }
    let filteredList = allCommitteesHierarchyDetails.filter(item => item.CommitteeTypeId === committeeTypeId);

    if (filteredList.length === levelId) {
        filteredList = [];
    } else if (filteredList.length > levelId) {
        filteredList.splice(0, levelId);
    }

    // Show count 0 for each net level title even if there is no committee at next level
    for (let i = 0; i < filteredList.length; i++) {

        if (subCommitteesCountInfo[i]) {
            subCommitteesCountInfo[i].LevelName = filteredList[i].CommitteeLevelTitle;
        } else {
            let subCommitteeInfo = { LevelId: i + 1 + levelId, LevelName: filteredList[i].CommitteeLevelTitle, Count: 0 };
            subCommitteesCountInfo.push(subCommitteeInfo);
        }
    }

    self.setState({ subCommitteesCountInfo });
}

export const toggleSubCommitteesList = () => {
    self.setState({ showSubCommitteesList: !self.state.showSubCommitteesList });
}

export const sortByHandler = (event, sortKey) => {
    let orderType = getSortOrderFromHeaderClick(event);
    const sortOrder = orderType === '0' ? 'asc' : 'desc';

    getSubCommitteesList(self.state.pageNumber, self.state.pageSize, sortKey, sortOrder);

    self.setState({ sortKey, sortOrder });
}

export const onPageChangeHandler = (event, data) => {
    self.setState({ pageNumber: data.activePage })

    getSubCommitteesList(data.activePage, self.state.pageSize, self.state.sortKey, self.state.sortOrder);
}

export const onChangeNumberOfItemsPerPage = (e, value) => {
    self.setState({ pageSize: value, pageNumber: 1 });

    getSubCommitteesList(1, value, self.state.sortKey, self.state.sortOrder);
}

// Private functions
const getPageData = (initialValues) => {
    const { StatisticalInfo, CommitteeTypeId, LevelId } = initialValues;
    if (StatisticalInfo) {
        if (StatisticalInfo.Committees && StatisticalInfo.Committees.length > 0) {
            updateCurrentlyShowingItems(StatisticalInfo.Count);
            self.setState({ subCommitteesList: StatisticalInfo.Committees })
        }
        if (StatisticalInfo.CommitteeLevels) {
            setSubCommitteesCountInfo(StatisticalInfo, CommitteeTypeId, LevelId);
        }
    }
}

const updateCurrentlyShowingItems = (totalItems, pageSize = 25, pageNumber = 1) => {
    const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);

    self.setState({ currentlyShowingItems, totalSubCommittees: totalItems });
}

const getSubCommitteesList = (pageNumber, pageSize, sortKey, sortOrder) => {
    let query = `pageNumber=${pageNumber}&pageSize=${pageSize}&sortedColumn=${sortKey}&sortOrder=${sortOrder}`;
    self.setState({ showLoader: true });
    let scrollElement = document.getElementsByClassName('subCommitteessTable')[0];
    scrollElement.scrollIntoView({
        behavior: 'smooth'
    });

    self.props.getStatisticalCommitteeInfo(self.props.initialValues.CommitteeId, query, (result) => {
        updateCurrentlyShowingItems(result.Count, pageSize, pageNumber);
        self.setState({ showLoader: false, subCommitteesList: result.Committees });
    })
}
