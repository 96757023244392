import { exportFileName, getMessage } from 'helpers/util-common';
import { AppGridIds, ItemsPerPage } from 'models/common.models';
import React, { Component } from 'react';
import { Confirm, Loader } from 'semantic-ui-react';
import CustomDataGrid from 'shared-components/AdvancedGrid';
import ExportExcel from 'shared-components/ExportExcel';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import { CommitteeStatusOptions } from './committee.model';
import AddCommitteeModal from './components/add-committee-modal';
import AddCommitteePage from './components/add-committee-page';
import * as _func from './function';

class CommitteeList extends Component {

    state = {
        showLoader: false,
        showAddCommitteeModal: false,
        showActiveClassInAddCommitteePage: false,
        showAddCommitteePage: false,
        addCommitteeData: {},
        totalPages: 0,
        totalItems: 0,
        activePageNumber: 1,
        selectedStatus: '',
        selectedHierarchy: '',
        pageSize: 25,
        currentlyShowingItems: '',
        sortedColumn: '',
        sortOrder: 0,
        searchText: this.props.location.search.length > 0 ? this.props.location.search.substring(1) : '',
        isDataUpdated: false,
        // Exportable Variable Setting Start with grid
        isCommitteeExcelExported: false,
        exportDataSet: [],
        showEmptyExcelConfirmation: false,
        tableHeaderConfig: [],
        isTableHeaderReset: true,
        isShowGridMenu: false,
        isFilterChanged: false,
        gridProp: {
            records: [],
            attributes: {},
            expandables: [],
            sortables: {},
            omitOnMenu: [],
            excelExportedColumn: [],
            emptyMessage: null,
            excelExportableFieldsCallback: null,
            displayName: {},
            requestSaveParams: {}
        },
        hasExportAccess: false,
        hasAddAccess: false
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    shouldComponentUpdate(nextProps) {
        _func.handleSearchUpdate(nextProps);
        return true;
    }

    resetDataUpdatedFlag = () => {
        this.setState({ isDataUpdated: false, isTableHeaderReset: true });
    }

    render() {
        const { committeeTypeList, hierarchyList, messageCodes, PAGE_TYPE, USER_PRIVILEGE, uniqueKey } = this.props;
        const { isShowGridMenu, showEmptyExcelConfirmation, exportDataSet, isCommitteeExcelExported, hasAddAccess, hasExportAccess,
            showLoader, gridProp, totalItems, totalPages, activePageNumber, selectedStatus, pageSize, currentlyShowingItems,
            isDataUpdated, selectedHierarchy, showAddCommitteeModal, showAddCommitteePage, addCommitteeData, showActiveClassInAddCommitteePage } = this.state;

        return (
            <div className="committeePage" data-testid="committeeListPage">
                {showLoader && (
                    <Loader size='small' data-testid="loading" className="small-loader">Loading</Loader>
                )}
                <div className="headingTitle clearfix">
                    <h2>Committees</h2>
                    {hasAddAccess &&
                        <button data-testid="addCommHandler" onClick={_func.addCommitteeClickHandler}
                            className="ui secondary button"><i aria-hidden="true" className="plus icon"></i>
                            Add Committee</button>
                    }
                </div>
                <div className="clearfix member-header">
                    <div className="display-inline-block mr25" data-testid="statusDorpdown">
                        <label className="label-inline" for="status">Status</label>
                        <RenderSelect
                            name="status"
                            id="status"
                            placeholder="Active"
                            value={selectedStatus}
                            onChange={_func.onChangeStatusType}
                            options={CommitteeStatusOptions}
                        />
                    </div>
                    <div className="display-inline-block mr25" data-testid="hierarchyDropdown">
                        <label className="label-inline" for="Hierarchy">Hierarchy</label>
                        <div className="display-inline-block">
                            <DropdownMultipleSelection name="Hierarchy"
                                id="Hierarchy"
                                placeholder="All"
                                isMultiple={true}
                                value={selectedHierarchy}
                                onChange={_func.onChangeHierarchy}
                                isSinglItemPrevent={false}
                                options={hierarchyList} />
                        </div>
                    </div>
                    <div data-testid="excelExportBlock" className="display-inline-block floatRight">
                        {hasExportAccess &&
                            <div>
                                <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                                <button data-testid="excelExportHandle" className="ui button secondary mr10" onClick={_func.getCommitteeExportExcel}><i className="icon file excel mr5"></i>Excel</button>
                            </div>}
                        {isCommitteeExcelExported &&
                            <ExportExcel fileName={exportFileName('Committee Records')} tabName={'Committees'} multiDataSet={exportDataSet} />
                        }
                    </div>
                </div>

                <div className="relative">
                    {gridProp.excelExportedColumn !== null && gridProp.excelExportedColumn.length > 0 && (
                        <CustomDataGrid isShowGridMenuEvent={isShowGridMenu} gridId={AppGridIds.CommitteeGrid}
                            detailsPageUrl={uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)} committeeTypeList={committeeTypeList}
                            isDataUpdated={isDataUpdated} resetDataUpdatedFlag={this.resetDataUpdatedFlag}
                            gridSetting={gridProp} onChangeSort={_func.onChangeSortHandler}
                            saveGridPerferenceClickAction={_func.saveGridPreferenceClickAction} />
                    )}
                </div>
                {
                    totalItems > 0 && (
                        <div className="pagerWrap">
                            <div data-testid="pageWrapClick" className="pager">
                                <PaginationShorthand defaultActivePage={activePageNumber} totalPages={totalPages}
                                    onPageChange={_func.onPageChangeHandler} />
                                <div>
                                </div>
                            </div>
                            <div className="itemPerPage">
                                <RenderSelect
                                    name="itemsPerPage"
                                    value={pageSize}
                                    onChange={_func.onChangeNumberOfItemsPerPage}
                                    options={ItemsPerPage}
                                />
                                <span className="itemsPerPage">items per page</span>
                            </div>

                            <div className="totalPage">{currentlyShowingItems}</div>

                        </div>
                    )
                }
                <Confirm
                    className="confirm-box"
                    open={showEmptyExcelConfirmation}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyExcelCancelHandler}
                    onConfirm={_func.emptyExcelConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                {
                    showAddCommitteeModal && (
                        <AddCommitteeModal {...this.props} onClose={_func.onCloseAddCommitteeModal}
                            onSubmit={_func.onSubmitAddCommitteeModal} />
                    )
                }
                {
                    showAddCommitteePage && (
                        <AddCommitteePage {...this.props} className={showActiveClassInAddCommitteePage ? 'fullPagePopup active' : 'fullPagePopup'}
                            addCommitteeData={addCommitteeData}
                            onClose={_func.onCloseAddCommitteePage} />
                    )
                }
            </div >
        )
    }
}

export default CommitteeList;
