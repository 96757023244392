// @flow
import * as type from '../../../action-types';

const initialState = {
    payload: {
        isSuccess: false, type: null, data: null
    },
    renewalYearsList: [],
    uploadedFiles: []
}

const setDefaultYears = (list) => {
    let currentYear = new Date().getFullYear();
    let year = currentYear - 1;

    if (list.indexOf(year) === -1) {
        list.push(year);
    }
    if (list.indexOf(currentYear) === -1) {
        list.push(currentYear);
    }
    year = currentYear + 1;
    if (list.indexOf(year) === -1) {
        list.push(year);
    }

    return list.sort();
}

const renewalTaskReducer = (state = initialState, action) => {
    switch (action.type) {

        case type.ADD_RENEWAL_TASK_ACTION:
            return {
                ...state,
                payload: {
                    isSuccess: true,
                    type: action.type,
                    data: action.payload
                }

            };
        case type.ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_SUCCESS:
            return {
                ...state,
                payload: {
                    isSuccess: true,
                    type: action.type,
                    data: action.payload
                }

            };
        case type.ADD_DEPENDS_ON_RENEWAL_TASK_ACTION_FAILURE:
            return {
                ...state,
                payload: {
                    isSuccess: false,
                    type: action.type,
                    data: null
                }

            };
        case type.CLOSE_POPUP_RENEWAL_TASK_ACTION: { // Close popup add task in add task
            return {
                ...state,
                payload: {
                    isSuccess: false,
                    type: action.type,
                    data: state.payload ? state.payload.data : 0
                }

            };
        }
        case type.GET_DEPENDENT_RENEWAL_TASK_ACTION: { // Get dependent list  in add task
            return {
                ...state,
                payload: {
                    isSuccess: true,
                    type: action.type,
                    data: action.payload
                }
            };
        }
        case type.GET_USER_RENEWAL_TASK_ACTION: { // Get user list in add task
            return {
                ...state,
                payload: {
                    isSuccess: true,
                    type: action.type,
                    data: action.payload
                }
            };
        }
        case type.GET_RENEWAL_YEARS_LIST:
            return {
                ...state,
                renewalYearsList: setDefaultYears(action.renewalYearsList)
            }
        case type.GET_DOCUMENT_LIST_SUCCESS:
            return {
                ...state,
                uploadedFiles: action.uploadedFiles
            }
        case type.CLEAR_DOCUMENTS_LIST:
            return {
                ...state,
                uploadedFiles: []
            }
        case type.RESET_ADD_TASK_STORE:
            return {
                ...state,
                payload: {
                    isSuccess: true,
                    type: '',
                    data: null
                }
            }
        default:
            return state;
    }
};

export default renewalTaskReducer;
