import { encrypt } from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds, CountryCodes } from 'models/common.models';
import { change } from 'redux-form';

export let TIMEOUT = null;
let self = null;
let prevOrgName = '';
let prevFirstName = '';
let prevLastName = '';

export const setClassInstance = (instance) => {
    self = instance;
    setStateListData(self.props.initialValues.Country);
}

export const getOrgnizationName = (e, value) => {
    value = value.trim();

    clearTimeout(TIMEOUT);

    if (prevOrgName !== value) {
        self.setState({ // Disabled loading
            isLoadingOrgList: value !== '',
            orgNameList: [],
            isShowOrgErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (prevOrgName !== value) {
            self.props.getAutoSearchFilter(value, 1, (result) => {
                if (result && result.length > 0) {
                    self.setState({
                        isShowOrgErrorMsg: false
                    })
                } else {
                    self.setState({
                        isShowOrgErrorMsg: true
                    })
                }

                self.setState({
                    orgNameList: formatAutoSearchList(result),
                    isLoadingOrgList: false,
                    isShowOrgErrorMsg: true
                })
            });
        }

        prevOrgName = value;
    }, 300);
}


export const getRepresentativeFirstName = (e, value) => {
    // value = value.trim();

    // clearTimeout(TIMEOUT);
    // if (value !== prevFirstName) { // If enter blank space
    //     self.setState({ // Disabled loading
    //         isLoadingRepFirstList: value !== '',
    //         repFirstNameList: [],
    //         isShowRepFirstErrorMsg: false
    //     });
    // }

    // TIMEOUT = setTimeout(() => {
    //     if (value !== prevFirstName) {
    //         self.props.getAutoSearchFilter(value, 2, (result) => {
    //             if (result && result.length > 0) {
    //                 self.setState({
    //                     repFirstNameList: formatAutoSearchList(result),
    //                     isLoadingRepFirstList: false,
    //                     isShowRepFirstErrorMsg: false
    //                 });
    //             } else {
    //                 self.setState({
    //                     repFirstNameList: [],
    //                     isLoadingRepFirstList: false,
    //                     isShowRepFirstErrorMsg: true
    //                 });
    //             }
    //         });
    //     }
    //     prevFirstName = value;
    // }, 300);
}


export const getRepresentativeLastName = (e, value) => {
    // value = value.trim();

    // clearTimeout(TIMEOUT);
    // if (value !== prevLastName) { // If enter blank space
    //     self.setState({ // Disabled loading
    //         isLoadingRepLastList: value !== '',
    //         repLastNameList: [],
    //         isShowRepLastErrorMsg: false
    //     });
    // }

    // TIMEOUT = setTimeout(() => {
    //     if (value !== prevLastName) {
    //         self.props.getAutoSearchFilter(value, 3, (result) => {
    //             if (result && result.length > 0) {
    //                 self.setState({
    //                     repLastNameList: formatAutoSearchList(result),
    //                     isLoadingRepLastList: false,
    //                     isShowRepLastErrorMsg: false
    //                 });
    //             } else {
    //                 self.setState({
    //                     repLastNameList: [],
    //                     isLoadingRepLastList: false,
    //                     isShowRepLastErrorMsg: true
    //                 });
    //             }
    //         });
    //     }
    //     prevLastName = value;
    // }, 300);
}

export const formatAutoSearchList = (list) => {
    if (list && list.length > 0) {
        let result = list.map((res) => {
            res.title = res.Name;
            return res;
        });
        return result;
    }

    return [];
}

export const onCountryChange = (e, countryName) => {
    setStateListData(countryName);
    self.props.change('State', '');
}

const setStateListData = (countryName) => {
    let stateList = [];
    stateList.push({ key: 'All', text: 'All', value: 'All' });

    if (countryName === CountryCodes.UNITEDSTATES.Name) {
        stateList = self.props.usStateList;
    } else if (countryName === CountryCodes.CANADA.Name) {
        stateList = self.props.canadaStateList;
    }

    self.setState({ stateList, isStateListDisabled: stateList.length === 1 });
}

export const onSubmitHandler = (values, dispatch, props) => {
    const searchQuery = getSearchQuery(values);
    props.onAdvanceSearchSubmit(searchQuery);
}

export const resetForm = () => {
    Object.keys(self.props.initialValues).forEach(fieldKey => {
        let value = '';

        if (fieldKey === 'State') {
            self.setState({ stateList: [], isStateListDisabled: true });
        }

        self.props.dispatch(change(self.props.form, fieldKey, value));
    });

    self.setState({ isResetClicked: true });

    const searchQuery = getSearchQuery({});
    self.props.onResetClick(searchQuery);
}

const getSearchQuery = (values) => {
    let queries = [];

    queries.push('isAdvanceSearch=true');
    queries.push(`${AdvanceSearchQueryFields.GridId}=${AppGridIds.OrganizationGrid}`);
    if (values.Account) {
        queries.push(`account=${encrypt(values.Account.trim())}`);
    }
    if (values.OrganizationName) {
        queries.push(`organizationName=${encrypt(values.OrganizationName.trim())}`);
    }
    if (values.FirstName) {
        queries.push(`FirstName=${encrypt(values.FirstName.trim())}`);
    }
    if (values.LastName) {
        queries.push(`LastName=${encrypt(values.LastName.trim())}`);
    }

    if (values.Country) {
        queries.push(`country=${encrypt(values.Country)}`);
    }
    if (values.State) {
        queries.push(`state=${encrypt(values.State)}`);
    }
    if (values.PostalCode && values.PostalCode.trim().length > 0) {
        queries.push(`postalcode=${encrypt(values.PostalCode.trim())}`);
    }
    if (values.City && values.City.trim().length > 0) {
        queries.push(`city=${encrypt(values.City.trim())}`);
    }
    if (values.AccountStatus) {
        queries.push(`companyType=${values.AccountStatus}`);
    } else {
        queries.push(`companyType=0`);
    }

    return queries.join('&');
}
