export const TableColumns = {
    "Account": true,
    "OrganizationName": true,
    "Representative": true,
    "Country": true,
    "State": false,
    "City": false,
    "PostalCode": true,
    "Status": true
}

export const SortableColumns = {
    "Account": true,
    "OrganizationName": true,
    "Representative": true,
    "Country": false,
    "State": false,
    "City": false,
    "Status": true,
    "PostalCode": false
}

export const ExpandableColumns = ['OrganizationName', 'Representative', 'Status', 'Country', 'State', 'City', 'PostalCode']

export const OmitColumns = ['Account', 'OrganizationName', 'Representative', 'Status']

export const ActiveOrganizationStatusId = 28;

export const OrganizationStatusOptions = [
    { key: 0, text: "All", value: 0 },
    { key: 2, text: "Member Facility/College/University", value: 2 },
    { key: 1, text: "Organizational Membership", value: 1 }

]

export const AccountStatusOptions = [
    { key: 28, text: "Active", value: 28 },
    { key: 30, text: "Historical", value: 30 }
]

export const CompanyStatusOptions = [
    { key: 0, text: "All", value: 0 },
    { key: 28, text: "Active", value: 28 },
    { key: 30, text: "Historical", value: 30 }
]


