import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import commonActions from "../../common.actions";
import MultipleErrorsList from '../MultipleErrorsList';

const ErrorModalBox = ({ content, handleDismiss }) => (
    <Modal open='true' className="tiny errormodalbox">
        <Modal.Header><Icon name="warning sign" /> Warning <i onClick={handleDismiss} className="close">&#x2716;</i></Modal.Header>
        <Modal.Content>
            <MultipleErrorsList content={content} />
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleDismiss}>Ok</Button>
        </Modal.Actions>
    </Modal>
)

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
    handleDismiss: () => {
        commonActions.hideErrorPopup(dispatch);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModalBox);

