
import { allowOnlyAlphaNumericInput, getPhoneNumber, onChangePhoneNo, onKeyPostalCode, onChangePostalCode, onCountryChange, getMessage, getExtension } from 'helpers/util-common';
import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { Form, Grid } from 'semantic-ui-react';
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import RenderInput from 'shared-components/Input';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import * as _func from './function';

class AddFeeGroupForm extends Component {
    state = {
        disabledSubmit: this.props.isDuplicate,
        stateList: [],
        isUnitedstatesOrCanadaSelected: true,
        isUnitedSelected: true,
        phoneNumber: '',
        maxLen: 10,
        countryList: []
    }
    componentDidMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { close, handleSubmit, submitting, isDuplicate, messageCodes } = this.props
        const { disabledSubmit, stateList, isUnitedstatesOrCanadaSelected, maxLen, isUnitedSelected } = this.state
        return (
            <section className="mainWrapperRight bg">
                <Form onSubmit={handleSubmit(_func.addFeeGroup)} noValidate >
                    <div  className="headingTitle clearfix mb20">
                        <h2>Add Fee Group</h2>
                    </div>
                    <div data-testid="feeGroupAddForm" className="addFeeGroup">
                        <Grid divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='FeeGroupTitle' type="text"
                                        label='Fee Group Title'
                                        required={true}
                                        component={RenderInput} maxLength={50}
                                        isDuplicate={isDuplicate} duplicateError={getMessage(messageCodes, '8149.text')}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='Organization' type="text"
                                        label='Organization'
                                        required={false}
                                        component={RenderInput} maxLength={200}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='Address1' type="text"
                                        label='Address 1'
                                        component={RenderInput} maxLength={500}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <Field name='Address2' type="text"
                                        label='Address 2'
                                        component={RenderInput} maxLength={10}
                                        onKeyPress={allowOnlyAlphaNumericInput}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='City' type="text"
                                        label='City'
                                        component={RenderInput} maxLength={50}
                                    />
                                </Grid.Column>

                                <Grid.Column>

                                    <Field name="Country"
                                        component={DropdownMultipleSelection}
                                        label="Country"
                                        isSearch={true}
                                        isMultiple={false}
                                        isDefaultValue={true}
                                        required
                                        placeholder="Please Select Country"
                                        onChange={(e, val) => onCountryChange(val, this)}
                                        options={this.state.countryList}
                                    />

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {isUnitedstatesOrCanadaSelected ?
                                        <Field name="State"
                                            component={DropdownMultipleSelection} label="State/Province"
                                            isSearch={true}
                                            isMultiple={false}
                                            isDefaultValue={true}
                                            placeholder=""
                                            options={stateList}
                                        /> :
                                        <Field name='State' type="text"
                                            label='State/Province'
                                            component={RenderInput} maxLength={50}
                                        />
                                    }
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name='PostalCode' type="text"
                                        label='Postal Code'
                                        onKeyPress={onKeyPostalCode}
                                        onChange={(e, value) => onChangePostalCode(e, value, this)}
                                        component={RenderInput} maxLength={10}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                        <Grid divided='vertically' columns={4}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="IsAutoRenewal" type="checkbox"
                                        component={ReduxCheckboxElement}
                                        label='Auto Renewal'
                                    />

                                </Grid.Column>
                                <Grid.Column>
                                    <Field name="IsSuppressFeeRenewalEmail" type="checkbox"
                                        component={ReduxCheckboxElement}
                                        label='Suppress Fee Renewal Emails'
                                    />

                                </Grid.Column>
                                <Grid.Column>
                                    <Field name="IsSuppressFeeRenewalPrint" type="checkbox"
                                        component={ReduxCheckboxElement}
                                        label='Suppress Fee Renewal Print'
                                    />
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </div>
                    <div className="addFeeGroupContact">
                        <h5 className="mt30">Contact Person Details</h5>
                        <Grid divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='ContactPersonName' type="text"
                                        label='Contact Person Name'
                                        required={false}
                                        component={RenderInput} maxLength={50}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='ContactEmailAddress' type="text"
                                        label='Email address'
                                        component={RenderInput} maxLength={50}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    {isUnitedSelected ?
                                        <Grid divided='vertically' columns={2} className="mt0">
                                            <Grid.Row>
                                                <Grid.Column width={12}>
                                                    <Field name='ContactPhoneNumber' type="text"
                                                        label='Phone Number' onChange={(e, value) => onChangePhoneNo(e, value, this)}
                                                        component={RenderInput} maxLength={maxLen}
                                                        onBlur={(e, value) => getPhoneNumber(e, value, this)}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={4}>
                                                    <Field name='ContactExtension' type="text"
                                                        label='Extension' onChange={(e, value) => onChangePhoneNo(e, value, this)}
                                                        component={RenderInput} maxLength={4}
                                                        onBlur={(e, value) => getExtension(e, value, this)}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid> : <Field name='ContactPhoneNumber' type="text"
                                            label='Phone Number' onChange={(e, value) => onChangePhoneNo(e, value, this)}
                                            component={RenderInput} maxLength={maxLen}
                                            onBlur={(e, value) => getPhoneNumber(e, value, this)}
                                        />}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </div>
                    <div className="mt30">
                        <button data-testid="btnCancel" className="ui button cancel mr10" onClick={() => close(false)}>Cancel</button>
                        <button data-testid="btnSave" className="ui button primary" type="submit" disabled={submitting || disabledSubmit}>Save</button>
                    </div>
                </Form>
            </section >
        )
    }
}
export default (reduxForm({
    form: 'AddFeeGroupForm',
    validate: _func.validateHandler
})(AddFeeGroupForm));


