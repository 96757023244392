import React from "react";
import { Field } from 'redux-form';
import InputTag from 'shared-components/InputTag';
import { handleOnChangeOrgName } from 'helpers/util-common';

class InputTagField extends React.Component {
   state = {
      isOrgLoading: false,
      organizationList: [],
      searchText: '',
      tags: []
   }

   componentDidMount() {
      const {prefilledOptions} = this.props;
      if(prefilledOptions){
        const tags = prefilledOptions?.split(",");
        this.setState({ tags: tags });
        setTimeout(() => {
          this.props.change(this.props.fieldName, tags?.join(", "));
        }, 50);
      }
 }

   validateManually = () => {
      this.props.change('_validationHack', Date.now())
   }

   handleOnKeyDown = (e) => {
      let value = e.target.value;
      const { maxLength } = this.props;
      // Set MaxLength from props.
      if (maxLength && e.target) {
         e.target.setAttribute('maxLength', maxLength);
      }
      // Handle Enter/Tab key to make a tag
      if (e.keyCode === 13 || e.keyCode === 9) {
         if (e.keyCode === 13) {
            e.preventDefault();
         }
         this.addTag(value);
      }// On click of backspace, need to remove the tags
      else if (e.keyCode === 8 && !value && this.state.tags.length > 0) {
         this.handleRemoveTag(this.state.tags.length - 1);
      }
   }

   handleOnBlur = () => {
      if(this.state && this.state.searchText){
         this.addTag(this.state.searchText);
      }
   }

   handleOnChange = (value) => {
      handleOnChangeOrgName(this, value, false, true, 3);
      this.setState({ searchText: value });
   }

   handleSelectMultiOrganization = (value) => {
      this.addTag(value);
   }

   handleRemoveTag = (index) => {
      let newTags = [...this.state.tags];
      newTags.splice(index, 1);
      this.props.change(this.props.fieldName, newTags.join(', '));
      this.setState({ tags: newTags }, this.validateManually);
   }

   addTag = (value) => {
      // Do not add empty and duplicate pill
      if (value && value.trim().length > 0 && this.state.tags.map(i => i.toLowerCase()).indexOf(value.trim().toLowerCase()) === -1) {

         let tags = [...this.state.tags, value.trim()];
         this.props.change(this.props.fieldName, tags.join(', '));
         // Call redux validate function
         this.setState({ tags }, this.validateManually);
      }
      this.setState({ searchText: '', organizationList: [] });
   }

   render() {
      const { isOrgLoading, organizationList, searchText, tags } = this.state;
      const { fieldName, label, hintText } = this.props;
      return (
         <Field name={fieldName}
            offAutoComplete={true}
            label={label}
            component={InputTag}
            required={true}
            minLengthToShowDropdown={3}
            isLoading={isOrgLoading}
            placeholder={hintText}
            searchText={searchText}
            options={organizationList} tags={tags}
            handleOnKeyDown={this.handleOnKeyDown}
            handleOnBlur={this.handleOnBlur}
            handleOnChange={this.handleOnChange}
            handleSelectChange={this.handleSelectMultiOrganization}
            handleRemoveTag={this.handleRemoveTag}
         />
      );
   }
}

export default InputTagField;
