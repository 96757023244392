import React from "react";
import { Link } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';
import { routeNavigationUrl, encrypt } from '../../../../../helpers/util-common';
import { membershipTypeJSON, MembershipTypes } from "../../../members/members.model";
import { ActiveOrganizationStatusId } from '../../organizations.model'

const OrganizationViewGeneralInfo = ({ initialValues, openRepHistoryModalEvent, onEditGenInfo, userAccess, PAGE_TYPE, USER_PRIVILEGE }) => {
    return (
        <div>
            <h5>ASTM General Information
                {initialValues.AccountNumber && initialValues.McsStatusMasterId === ActiveOrganizationStatusId && userAccess.hasUpdateAccess && (
                    <a data-testid="btnEditGenInfo" title="Edit" className="editBtn" onClick={onEditGenInfo}>
                        <Icon name="pencil" />
                    </a>
                )}
            </h5>
            <Grid divided='vertically' columns={4}>
                <Grid.Row>
                    <Grid.Column>
                        <a className="linkTxt" onClick={(e) => openRepHistoryModalEvent(e)}>View Representative(s) History</a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <span className="labelTitle">Member Type</span>
                        <span className="labelValue">ORGANIZATIONAL</span>
                    </Grid.Column>

                    <Grid.Column>
                        <span className="labelTitle">Fee Group</span>
                        {!userAccess.hasFeeGroupViewAccess && <span className="labelValue">{initialValues.FeeGroupName}</span>}
                        {userAccess.hasFeeGroupViewAccess &&
                            <a className="linkTxt" href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW, [encrypt(0), encrypt(initialValues.FeeGroupId)])}`} target="_blank">{initialValues.FeeGroupName}</a>
                        }
                    </Grid.Column>
                    <Grid.Column style={{ width: '50%' }}>
                        <span className="labelTitle">{initialValues.RepMembershipTypeId === MembershipTypes.cooperativeAgreementRep ? membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName}</span>
                        <span className="labelValue">
                            <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [initialValues.RepAccountNumber])}`}>
                                {initialValues.RepName} {initialValues.RepAccountNumber}
                            </Link>
                        </span>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </div >
    )
}

export default OrganizationViewGeneralInfo;
