import { deepCopy, getMessage } from 'helpers/util-common';
import React, { Component } from 'react';
import { Form, Grid, Modal } from 'semantic-ui-react';
import CheckboxElement from 'shared-components/CheckBox';

class AffiliateMemberModal extends Component {

    state = {
        committeeList: []
    }

    componentWillMount() {
        this.setState({ committeeList: deepCopy(this.props.committeeList) });
    }

    onChange = (index) => {
        let { committeeList } = this.state;

        committeeList[index].IsChecked = !committeeList[index].IsChecked;

        this.setState({ committeeList });
    }

    onSubmitHandler = () => {
        let selectedCommitteeList = [];

        this.state.committeeList.map((item) => {
            if (item.IsChecked) {
                selectedCommitteeList.push({ CommitteeId: item.CommitteeId, Code: item.Code });
            }
        });
        this.props.onSubmit(selectedCommitteeList);
    }

    render() {
        const { messageCodes, closeModal } = this.props;
        const { committeeList } = this.state;

        return (
            <Modal open='true' className="small" data-testid="affiliateMemberModal">
                <Form size='large' onSubmit={this.onSubmitHandler}>

                    <Modal.Header>Affiliate Member <i className="close" onClick={closeModal}>&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="legendNotes">{getMessage(messageCodes, '9064.text')}</span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="tableWrapper paddingLR0 pt0">
                                            <table className="customTable fixHeadertable">
                                                <thead>
                                                    <tr><th width="50%">Committee</th>
                                                        <th width="50%">Approved</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {committeeList && committeeList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td width="50%">
                                                                    {item.Code}
                                                                </td>
                                                                <td width="50%">
                                                                    <CheckboxElement name={index} IsChecked={item.IsChecked}
                                                                        setLabeltitleProp='Approved as Affiliate' IsEditable={true}
                                                                        onChange={() => { this.onChange(index) }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <span className="legendNotes"><strong>Note:</strong> {getMessage(messageCodes, '9065.text')}</span>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" type="submit">Update</button>
                            <button className="ui button cancel" onClick={closeModal}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default AffiliateMemberModal;
