import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const CheckboxElement = (props) => (
  <Checkbox {...props}
    data-testid={props.name ? props.name : props.setLabeltitleProp}
    label={props.setLabeltitleProp}
    checked={props.IsChecked}
    defaultChecked={props.IsChecked}
    disabled={!props.IsEditable}
    style={{ "pointer-events": !props.IsEditable ? "none" : "auto" }}
    name={props.name ? props.name : props.setLabeltitleProp}
    onChange={props.onChange} />
);

export default CheckboxElement;
