import AdvanceSearchCommittee from 'modules/committee-management/containers/committee-advance-search.container';
import AdvanceSearchMember from 'modules/member-management/members/containers/advance-member-search.container';
import AdvanceSearchOrganization from 'modules/member-management/organizations/containers/advance-organization-search.container';
import AdvanceSearchRenewalTask from 'modules/renewal-task/containers/advance-task-search.container';
import AdvanceSearchFeeGroup from 'modules/member-management/fee-group/containers/advance-fee-group-search.container';
import React, { Component } from 'react';
import * as _func from './function';

class AdvanceSearchPopOver extends Component {

    state = {
        showAdvanceSearchRenewalTask: false,
        showAdvanceSearchMember: false,
        showAdvanceSearchOrganization: false,
        showAdvanceSearchCommittee: false,
        showAdvanceSearchFeeGroup: false,
        advanceSearchTaskInitialValues: {},
        advanceSearchMemberInitialValues: {},
        advanceSearchOrganizationInitialValues: {},
        advanceSearchCommitteeInitialValues: {},
        advanceSearchFeeGroupInitialValues: {}
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
        _func.setInstance(this);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = (e) => {
        if (!this.node.contains(e.target) && e.target.className !== 'delete icon') {
            this.props.closeAdvanceSeach();
        }
    }

    render() {
        const { showAdvanceSearchRenewalTask, showAdvanceSearchMember, showAdvanceSearchCommittee, showAdvanceSearchFeeGroup, advanceSearchTaskInitialValues, advanceSearchMemberInitialValues, showAdvanceSearchOrganization, advanceSearchOrganizationInitialValues, advanceSearchCommitteeInitialValues, advanceSearchFeeGroupInitialValues } = this.state;

        return (
            <div className="advanced-search" ref={node => this.node = node}>
                {showAdvanceSearchRenewalTask && (
                    <AdvanceSearchRenewalTask initialValues={advanceSearchTaskInitialValues} onAdvanceSearchSubmit={_func.onAdvanceSearchSubmit}
                        onResetClick={this.props.onResetClick} />
                )}
                {showAdvanceSearchMember && (
                    <AdvanceSearchMember initialValues={advanceSearchMemberInitialValues} onAdvanceSearchSubmit={_func.onAdvanceSearchSubmit}
                        onResetClick={this.props.onResetClick} />
                )}
                {showAdvanceSearchOrganization && (
                    <AdvanceSearchOrganization initialValues={advanceSearchOrganizationInitialValues} onAdvanceSearchSubmit={_func.onAdvanceSearchSubmit}
                        onResetClick={this.props.onResetClick} />
                )}
                {showAdvanceSearchCommittee && (
                    <AdvanceSearchCommittee initialValues={advanceSearchCommitteeInitialValues} onAdvanceSearchSubmit={_func.onAdvanceSearchSubmit}
                        onResetClick={this.props.onResetClick} />
                )}
                {showAdvanceSearchFeeGroup && (
                    <AdvanceSearchFeeGroup initialValues={advanceSearchFeeGroupInitialValues} onAdvanceSearchSubmit={_func.onAdvanceSearchSubmit}
                        onResetClick={this.props.onResetClick} />
                )}
            </div>
        )
    }
}

export default AdvanceSearchPopOver;
