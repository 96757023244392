import { deepCopy, encrypt, getMessage } from 'helpers/util-common';

let self = {};
let scrollElement = {};
let isResetToDefault = false;

export const setInstance = (instance) => {
    self = instance;
    scrollElement = {};
    let classificationList = [];
    classificationList = deepCopy(self.props.initialValues.ActiveCommitteeClassifications);
    self.setState({
        showBalanceRuleSection: self.props.initialValues.IsBalanceRequired === 'Yes',
        classificationList,
        isBalanceRequired: self.props.initialValues.IsBalanceRequired
    });
}

export const handleOnChangeSuppressCommittee = (e, data) => {
    // When Checkbox is de-selected, then show alert message
    if (!data) {
        let alertMessage = getMessage(self.props.messageCodes, '9060.text');
        self.props.showAlertPopup(alertMessage);
    }
}

export const toggleBalanceRuleSection = (e) => {
    isResetToDefault = false;

    self.setState({
        showBalanceRuleSection: !self.state.showBalanceRuleSection, showSetBalanceRuleError: false,
        showLeftClassificationError: false, showRightClassificationError: false,
        isBalanceRequired: !self.state.showBalanceRuleSection ? 'Yes' : 'No'
    }, () => {
        validateOperatorSymbol();
    });

}

export const validateOperatorSymbol = () => {

    const { CommitteeTypeBalanceRule, CommitteeBalanceRule, IsBalanceRuleExist } = self.props.initialValues;
    let classificationList = self.state.classificationList;

    if (!IsBalanceRuleExist && self.state.isBalanceRequired === 'Yes') {
        self.props.change('OperatorSymbol', CommitteeTypeBalanceRule.OperatorSymbol)

        classificationList.map(item => {
            item.isLeft = CommitteeTypeBalanceRule.ClassificationTypeLeft.filter(item1 => item1 === item.Id)
        })
        classificationList.map(item => {
            item.isLeft = CommitteeTypeBalanceRule.ClassificationTypeLeft.filter(item1 => item1 === item.Id).length > 0;
            item.isRight = CommitteeTypeBalanceRule.ClassificationTypeRight.filter(item1 => item1 === item.Id).length > 0;

        });
    } else if (IsBalanceRuleExist && self.state.isBalanceRequired === 'Yes') {
        self.props.change('OperatorSymbol', CommitteeBalanceRule.OperatorSymbol)

        classificationList.map(item => {
            item.isLeft = CommitteeBalanceRule.ClassificationTypeLeft.filter(item1 => item1 === item.Id)
        })
        classificationList.map(item => {
            item.isLeft = CommitteeBalanceRule.ClassificationTypeLeft.filter(item1 => item1 === item.Id).length > 0;
            item.isRight = CommitteeBalanceRule.ClassificationTypeRight.filter(item1 => item1 === item.Id).length > 0;

        });
    }

    self.setState({ classificationList });
}

export const showConfirmationModal = () => {
    self.setState({ showConfirmationModal: true });
}

export const onCancelModal = () => {
    self.setState({ showConfirmationModal: false });
    self.props.onCloseEditSettings();
}

export const resetBalanceRule = () => {
    const { CommitteeTypeBalanceRule } = self.props.initialValues;
    const showBalanceRuleSection = !!(CommitteeTypeBalanceRule && CommitteeTypeBalanceRule.OperatorSymbol);
    let { classificationList } = self.state;
    isResetToDefault = true;

    if (showBalanceRuleSection) {
        self.props.change('OperatorSymbol', CommitteeTypeBalanceRule.OperatorSymbol);

        classificationList.map((item) => {
            item.isLeft = !!CommitteeTypeBalanceRule.ClassificationTypeLeft.find(i => i === item.Id);
            item.isRight = !!CommitteeTypeBalanceRule.ClassificationTypeRight.find(i => i === item.Id);
        })
    }

    self.setState({
        showBalanceRuleSection, showSetBalanceRuleError: false, showLeftClassificationError: false, showConfirmationModal: false,
        showRightClassificationError: false, isBalanceRequired: showBalanceRuleSection ? 'Yes' : 'No', classificationList
    });
}

// Position 1 Means left, and 2 means Right
export const updateClassificationList = (position, index) => {
    let classificationList = self.state.classificationList;
    isResetToDefault = false;

    if (position === 1) {
        classificationList[index].isLeft = !classificationList[index].isLeft;
    } else {
        classificationList[index].isRight = !classificationList[index].isRight;
    }

    validateBalanceRuleClassifications();

    self.setState({ classificationList, showSetBalanceRuleError: false });

    self.props.change('_validationHack', Date.now()); // Used this to call redux form validation when classifications are updated
}

export const onChangeOperatorSymbol = () => {
    isResetToDefault = false;
}

export const validateHandler = (values, props) => {
    const errors = {};
    const { UpdateReason, OperatorSymbol } = values;

    let hasBalanceRuleError = false;
    if (self.state) {
        hasBalanceRuleError = validateBalanceRule(OperatorSymbol, self.state.isBalanceRequired);

        if (!hasBalanceRuleError && self.state.isBalanceRequired === 'Yes') {
            validateBalanceRuleClassifications();
        }
    }

    if (!hasBalanceRuleError && (OperatorSymbol === 0 || !OperatorSymbol)) {
        errors.OperatorSymbol = getMessage(props.messageCodes, '8137.text');
    }

    if (!UpdateReason || (UpdateReason && UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    // If Update Reason has error, then scroll to it
    if (errors.UpdateReason && self.refs) {
        scrollElement = self.refs.forScrollPurpose;
    } else {
        scrollElement = {};
    }

    return errors;
}

// Scroll to the errors for bylaw/update reason fields
export const onSubmitFailHandler = () => {
    if (scrollElement) {
        scrollElement.scrollIntoView(true);
    }
}

export const onSubmitHandler = (data) => {
    const { initialValues, updateCommitteeSettings, getCommitteeDetails, onCloseEditSettings, displayNoChangesMadeMessage } = self.props;
    const { showLeftClassificationError, showRightClassificationError, showSetBalanceRuleError } = self.state;

    let isBalanceRequired = self.state.isBalanceRequired === 'Yes';
    let isOperatorSymbolUpdated = initialValues.OperatorSymbol !== data.OperatorSymbol;

    if (!(isBalanceRequired && (showLeftClassificationError || showRightClassificationError || showSetBalanceRuleError))) {

        let committeeBalanceRuleInfoUpdate = isBalanceRequired ? getClassificationDataToUpdate() : [];
        let isBalanceRequiredUpdated = initialValues.IsBalanceRequired !== self.state.isBalanceRequired;
        let isBalanceRuleUpdated = isBalanceRequired ? checkIfBalanceRuleUpdated(committeeBalanceRuleInfoUpdate) : false;
        let oldCommitteeBalanceRule = formatInitialBalanceRule(initialValues.CommitteeBalanceRule);

        // If there is no change, then do not send api to update
        if (initialValues.IsSuppressCommittee !== data.IsSuppressCommittee || isBalanceRequiredUpdated ||
            isBalanceRuleUpdated || isOperatorSymbolUpdated) {

            let requestData = {
                IsSuppressCommittee: data.IsSuppressCommittee,
                IsBalanceRequired: isBalanceRequired,
                IsBalanceRequiredOld: initialValues.IsBalanceRequired === 'Yes',
                IsResetToDefault: isResetToDefault,
                OperatorSymbol: isBalanceRequired ? data.OperatorSymbol : '',
                OldOperatorSymbol: initialValues.OperatorSymbol,
                CommitteeBalanceRuleInfoUpdate: ((isBalanceRuleUpdated || isOperatorSymbolUpdated) && !isResetToDefault) ? committeeBalanceRuleInfoUpdate : [], // Balance rule data overwritten for committee
                CommitteeBalanceRule: committeeBalanceRuleInfoUpdate, // New Balance Rule visible when submitting
                OldCommitteeBalanceRule: oldCommitteeBalanceRule, // Old Balance Rule
                UpdateReason: data.UpdateReason
            }

            let encodedCommitteeId = encrypt(initialValues.CommitteeId);

            updateCommitteeSettings(encodedCommitteeId, requestData, () => {
                getCommitteeDetails(encodedCommitteeId);
                onCloseEditSettings();
            })
        } else {
            displayNoChangesMadeMessage();
            onCloseEditSettings();
        }
    }
}

const validateBalanceRuleClassifications = () => {
    if (self.state && self.state.classificationList) {
        let leftItem = self.state.classificationList.find(item => item.isLeft === true);
        let rightItem = self.state.classificationList.find(item => item.isRight === true);

        self.setState({ showLeftClassificationError: !leftItem, showRightClassificationError: !rightItem });
    }
}

const validateBalanceRule = (operatorSymbol, isBalanceRequired) => {
    let hasError = false;
    if (self.state) {
        if (isBalanceRequired === 'Yes' && (operatorSymbol === 0 || !operatorSymbol) && self.state.classificationList) {

            if (self.state.classificationList.length === 0) {
                hasError = true;
            } else {
                let hasClassificationSelected = self.state.classificationList.find((item) => item.isLeft || item.isRight);

                hasError = !hasClassificationSelected;
            }
        }

        if (hasError) {
            self.setState({ showLeftClassificationError: false, showRightClassificationError: false });
        }

        self.setState({ showSetBalanceRuleError: hasError });
    }

    return hasError;
}

const getClassificationDataToUpdate = () => {
    const { classificationList } = self.state;
    let committeeBalanceRuleInfoUpdate = [];

    classificationList.map((item) => {
        if (item.isLeft) {
            committeeBalanceRuleInfoUpdate.push({ ClassificationId: item.Id, IsLeft: true })
        } else if (item.isRight) {
            committeeBalanceRuleInfoUpdate.push({ ClassificationId: item.Id, IsLeft: false })
        }
    })

    return committeeBalanceRuleInfoUpdate;
}

const checkIfBalanceRuleUpdated = (newBalanceRule) => {
    let isUpdated = false;
    const { CommitteeBalanceRule } = self.props.initialValues;

    if (CommitteeBalanceRule && CommitteeBalanceRule.ClassificationTypeLeft && CommitteeBalanceRule.ClassificationTypeRight) {

        if (CommitteeBalanceRule.ClassificationTypeLeft.length + CommitteeBalanceRule.ClassificationTypeRight.length !== newBalanceRule.length) {
            isUpdated = true;
        } else {
            for (let i = 0; i < newBalanceRule.length; i++) {
                let item = newBalanceRule[i];
                if ((item.IsLeft && CommitteeBalanceRule.ClassificationTypeLeft.indexOf(item.ClassificationId) === -1) ||
                    (!item.IsLeft && CommitteeBalanceRule.ClassificationTypeRight.indexOf(item.ClassificationId) === -1)) {
                    isUpdated = true;
                    break;
                }
            }
        }
    } else {
        isUpdated = true;
    }

    return isUpdated;
}

const formatInitialBalanceRule = (balanceRule) => {
    let result = [];

    if (balanceRule && balanceRule.ClassificationTypeLeft && balanceRule.ClassificationTypeLeft.length > 0) {
        balanceRule.ClassificationTypeLeft.map((item) => {
            result.push({ ClassificationId: item, IsLeft: true });
        });
    }
    if (balanceRule && balanceRule.ClassificationTypeRight && balanceRule.ClassificationTypeRight.length > 0) {
        balanceRule.ClassificationTypeRight.map((item) => {
            result.push({ ClassificationId: item, IsLeft: false });
        });
    }

    return result;
}
