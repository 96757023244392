import * as _actions from 'action-types';
import * as commonActions from 'common.actions';
import _ from 'lodash';
import { connect } from "react-redux";
import { formValueSelector, reset, getFormValues } from "redux-form";
import manualOrderActions from '../../../admin/manual-order/manual-order.action';
import memberDetailsActions from '../actions/member-details.actions';
import memberActions from '../actions/members.actions';
import MemberDetails from '../components/member-details';

const selector = formValueSelector("MembershipTypeEditInfoForm");

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        initialValues: state.memberDetailsReducer.memberDetails,
        membershipTypeList: state.memberReducer.membershipTypeList,
        mouContactCodeList: state.memberDetailsReducer.mouContactCodeList,
        governmentTypeList: state.memberDetailsReducer.governmentTypeList,
        interestedCommitteeList: state.memberDetailsReducer.interestedCommitteeList,
        currentModifiedById: state.memberDetailsReducer.currentModifiedById,
        memberUserList: state.memberDetailsReducer.memberUserList,
        historicalReasonList: state.memberDetailsReducer.historicalReasonList,
        memberAuditLogFields: state.memberDetailsReducer.memberAuditLogFields,
        memberCommitteeDetails: state.memberDetailsReducer.memberCommitteeDetails,
        loading: state.commonReducer.loading,
        commonCommitteeClassificationTypeList: state.memberDetailsReducer.commonCommitteeClassificationTypeList,
        noVoteReasonList: state.memberDetailsReducer.noVoteReasonList,
        isUpdateMemberCommitteeDetails: state.memberDetailsReducer.isUpdateMemberCommitteeDetails,
        committeeList: state.memberDetailsReducer.committeeList,
        validateProducerClassification: state.memberDetailsReducer.validateProducerClassification,
        memberAccountHistoryList: state.memberDetailsReducer.memberAccountHistoryList,
        accountStatusAuditFieldName: state.memberDetailsReducer.accountStatusAuditFieldName,
        paidStatusAuditFieldName: state.memberDetailsReducer.paidStatusAuditFieldName,
        officerResponsibilities: state.memberDetailsReducer.officerResponsibilities,
        responsibilities: state.memberDetailsReducer.responsibilities,
        memberCommitteeHistoryList: state.memberDetailsReducer.memberCommitteeHistoryList,
        officerTitleList: state.memberDetailsReducer.officerTitleList,
        inactiveReasonList: state.memberDetailsReducer.inactiveReasonList,
        memberCommitteeOfficerTitleHistoryList: state.memberDetailsReducer.memberCommitteeOfficerTitleHistoryList,
        membershipTypeFormValues: selector(state, 'AccountNumber', 'MemberTypeId', 'MOUContactCodeId', 'UpdateReason'),
        userPermission: state.commonReducer.userPermission,
        memberMasterDetails: state.memberDetailsReducer.memberMasterDetails,
        countryList: state.commonReducer.countryList,
        activeCountryList: state.commonReducer.activeCountryList,
        originalActiveCountryList: state.commonReducer.originalActiveCountryList,
        originalCountryList: state.commonReducer.originalCountryList,
        usStateList: state.commonReducer.usStateList,
        canadaStateList: state.commonReducer.canadaStateList,
        currentModifiedType: state.memberDetailsReducer.currentModifiedType,
        orgFormValues: getFormValues('MemberPersonalDetailsViewEdit')(state),
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getMemberDetails: (accountNumber) => {
        memberDetailsActions.getMemberPersonalDetailsAction(accountNumber, dispatch)
    },
    getMemberMasterData: (accountNumber) => {
        memberDetailsActions.getMemberMasterDataAction(accountNumber, dispatch)
    },
    getMembershipTypeList: () => {
        memberActions.getMembershipTypeListAction(dispatch)
    },
    showSuccessCopyMessage: () => {
        dispatch(commonActions.setMessage(true, '2037'));
    },
    showMessageForNoChangeInCompanyInfoForm: () => {
        dispatch(commonActions.setMessage(true, '2052'));
    },
    resetDetailsData: () => {
        dispatch({ type: _actions.RESET_MEMBER_DETAILS_DATA })
    },
    getUserName: (userId, callback) => {
        commonActions.getUserNameAction(userId, callback)
    },
    getMasterData: () => {
        memberDetailsActions.getMasterDataAction(dispatch)
    },
    updateMemberDetails: (data, callback) => {
        memberDetailsActions.updateMemberDetailsAction(data, callback, dispatch)
    },
    getMemberCommunicationLog: (accountNumber, pageSize, pageNumber, showLoader, callback) => {
        memberDetailsActions.getMemberCommunicationLogAction(accountNumber, pageSize, pageNumber, showLoader, callback, dispatch)
    },
    getMemberUserList: () => {
        memberDetailsActions.getMemberUserListAction(dispatch)
    },
    getMemberOrderHistory: (accountNumber, callback, joinDate, currentDate) => {
        memberDetailsActions.getMemberOrderHistoryAction(accountNumber, callback, dispatch, joinDate, currentDate);
    },
    addCommunicationLog: (data, callback) => {
        memberDetailsActions.addCommunicationLogAction(data, callback, dispatch)
    },
    updateMembershipTypeInfo: (data, callback) => {
        memberDetailsActions.updateMembershipTypeInfoAction(data, callback, dispatch)
    },
    updateMemberCommunicationLog: (commentId, data, isOwnComment, callback) => {
        memberDetailsActions.updateMemberCommunicationLogAction(commentId, data, isOwnComment, callback, dispatch)
    },
    removeMemberCommunicationLog: (commentId, data, isOwnComment, callback) => {
        memberDetailsActions.removeMemberCommunicationLogAction(commentId, data, isOwnComment, callback, dispatch)
    },
    getOrgRepActiveMappingList: (search, callback) => {
        memberDetailsActions.getOrgRepActiveMappingListAction(search, callback)
    },
    showAlertPopup: (alertMessage) => {
        dispatch(commonActions.showAlertPopup(true, alertMessage));
    },
    updateAccountDetails: (accountNumber, data, callback) => {
        memberDetailsActions.updateAccountDetailsAction(accountNumber, data, callback, dispatch)
    },
    updateRepAccountStatus: (accountNumber, data, callback) => {
        memberDetailsActions.updateRepAccountStatusAction(accountNumber, data, callback, dispatch)
    },
    getMemberAuditLog: (accountNumber, requestData, callback) => {
        memberDetailsActions.getMemberAuditLogAction(accountNumber, requestData, callback, dispatch)
    },
    getMemberCommitteeInfoList: (accountNumber, callback) => {
        memberDetailsActions.getMemberCommitteeInfoAction(accountNumber, callback, dispatch)
    },
    getMemberMainCommittees: (accountNumber, callback) => {
        memberDetailsActions.getMemberMainCommitteesAction(accountNumber, callback, dispatch)
    },
    getMemberMainSubCommitteesList: (accountNumber, callback) => {
        memberDetailsActions.getMemberMainSubCommitteesAction(accountNumber, callback, dispatch)
    },
    getMemberOfficerTitle: (data, callback) => {
        memberDetailsActions.getMemberOfficerTitleAction(data, callback, dispatch);
    },
    getAddMemberCommitteeMasterData: (accountNumber, callback) => {
        memberDetailsActions.getAddMemberCommitteeMasterDataAction(accountNumber, callback, dispatch)
    },
    addMemberCommittee: (data, callback) => {
        memberDetailsActions.addMemberCommitteeAction(data, callback, dispatch)
    },
    getMemberCommitteeDetails: (data) => {
        memberDetailsActions.getMemberCommitteeDetailsAction(data, dispatch);
    },
    getCommonCommitteeClassificationTypeAndNoVoteReason: (committeeId, accountNumber) => {
        memberDetailsActions.getCommonCommitteeClassificationTypeAndNoVoteReasonAction(committeeId, accountNumber, dispatch);
    },
    updateMemberCommitteeDetails: (data, accountNumber, callback) => {
        memberDetailsActions.updateMemberCommitteeDetailsAction(data, accountNumber, callback, dispatch);
    },
    getAddMemberCommitteeSecondryMasterData: (data, callback) => {
        memberDetailsActions.getAddMemberCommitteeSecondryMasterDataAction(data, callback, dispatch)
    },
    resetLeftPanel: () => dispatch({ type: _actions.RESET_LEFT_PANEL }),
    getMasterDataforUpdateCommittee: (accountNumber, callback) => {
        memberDetailsActions.getMasterDataforUpdateCommitteeAction(accountNumber, callback);
    },
    getMemberAccountHistoryDetails: (accountNumber, accountStatusAuditFieldName, paidStatusAuditFieldName) => {
        memberDetailsActions.getMemberAccountHistoryDetailsAction(accountNumber, accountStatusAuditFieldName, paidStatusAuditFieldName, dispatch);
    },
    getMemberCommitteeListBultEdit: (data, callback) => {
        memberDetailsActions.getMemberCommitteeListBultEditAction(data, callback, dispatch)
    },
    getImpactedCommitteeList: (data, callback) => {
        memberDetailsActions.getImpactedCommitteeListAction(data, callback, dispatch)
    },
    updateMemberCommittees: (data, callback) => {
        memberDetailsActions.updateMemberCommitteesAction(data, callback, dispatch)
    },
    displayNoChangesMadeMessage: () => {
        dispatch(commonActions.setMessage(true, '2040'));
    },
    getOfficerResponsibilities: (data, callback) => {
        memberDetailsActions.getOfficerResponsibilitiesAction(data, callback, dispatch);
    },
    updateResponsibilties: (data) => {
        memberDetailsActions.updateResponsibilties(data, dispatch);
    },
    getMemberCommitteeHistoryDetails: (accountNumber, committeeId) => {
        memberDetailsActions.getMemberCommitteeHistoryDetailsAction(accountNumber, committeeId, dispatch);
    },
    updateMemberCommitteeBasicDetails: (data, callback) => {
        memberDetailsActions.updateMemberCommitteeBasicDetailsAction(data, callback, dispatch);
    },
    getOfficerTitleListAndInactiveReasonList: (data) => {
        memberDetailsActions.getOfficerTitleListAndInactiveReasonListAction(data, dispatch);
    },
    getMemberCommitteeOfficerTitleHistoryDetails: (accountNumber, commentId) => {
        memberDetailsActions.getMemberCommitteeOfficerTitleHistoryDetailsAction(accountNumber, commentId, dispatch);
    },
    updateMemberCommitteeGeneralInfo: (data, callback) => {
        memberDetailsActions.putMemberCommitteeGeneralInfoAction(data, callback, dispatch)
    },
    getActivityList: (data, callback) => {
        memberDetailsActions.getActivityList(data, callback, dispatch)
    },
    updateCommitteeActivityInfo: (data, callback) => {
        memberDetailsActions.updateCommitteeActivityInfo(data, callback, dispatch)
    },
    getAssociatedCommitteeList: (accountNumber, callback) => {
        memberDetailsActions.getAssociatedCommitteeListAction(accountNumber, callback, dispatch)
    },
    getActiveFeeGroupList: (callback) => {
        memberDetailsActions.getActiveFeeGroupList(callback, dispatch)
    },
    getCompanyByMemberAccountNumber: (accountNumber, callback) => {
        memberDetailsActions.getCompanyByMemberAccountNumber(accountNumber, callback, dispatch)
    },
    getCompanyAssociatedMembersDetails: (payload, callback) => {
        memberDetailsActions.getCompanyAssociatedMembersDetails(payload, callback, dispatch)
    },
    checkIsCompanyAndAddressExistsAction: (payload, callback) => {
        memberDetailsActions.checkIsCompanyAndAddressExistsAction(payload, callback, dispatch)
    },
    checkIsHistoricalCompanyAndAddressExistsAction: (payload, flag, callback) => {
        memberDetailsActions.checkIsHistoricalCompanyAndAddressExistsAction(payload, flag, callback, dispatch)
    },
    updateDemographicInfo: (requestBody, callback) => {
        memberDetailsActions.updateDemographicInfoAction(requestBody, callback, dispatch)
    },
    resetEditForm: () => {
        dispatch(reset('MemberPersonalDetailsViewEdit'))
    },
    getOrganizationList: (orgName, callback) => {
        manualOrderActions.getOrganizationListAction(orgName, callback, dispatch)
    },
    getCompanyAddressList: (orgName, callback) => {
        manualOrderActions.getCompanyAddressListAction(orgName, callback, dispatch)
    },
    updateCompanyInfo: (payload, callback) => {
        memberDetailsActions.updateCompanyInfoAction(payload, callback, dispatch)
    },
    getFeeGroupTabDetail: (feeGroupId, callback) => {
        memberDetailsActions.getFeeGroupTabDetailAction(feeGroupId, callback, dispatch);
    },
    getMembershipTypeInfoByMemberTypeId: (MemberTypeId, callback) => {
        memberDetailsActions.getMembershipTypeInfoByMemberTypeIdAction(MemberTypeId, callback, dispatch);
    },
    getMemberIdByMemberName: (memberId, callback) => {
        memberDetailsActions.getMemberDetailAction(memberId, callback);
    },
    updateOrganization: (payload, callback) => {
        commonActions.updateOrganizationAction(payload, callback, dispatch)
    },
    setSafariAutoFillInfo: (flag) => {
        commonActions.setSafariAutoFillInfoAction(dispatch, flag)
    },
    getCompanyAssociatedDetailsAction: (accountNumber, companyId = 0, callback) => {
        manualOrderActions.getCompanyAssociatedDetailsAction(accountNumber, companyId, callback, dispatch)
    },
    getPrimaryActivityList: (membershipTypeId, callback) => { manualOrderActions.getPrimaryActivityListAction(membershipTypeId, callback, dispatch) }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);
