import React, { PureComponent } from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import commonActions from "../../common.actions";

class MessageDismissible extends PureComponent {

  constructor(props) {
    super(props);

    const type = props.content && props.content.type ? props.content.type : 'error';
    const message = props.content && props.content.text ? props.content.text : 'Unknown error occured.';

    this.state = {
      type,
      message
    }

    let timeout = 5000;
    if (type === 'error') {
      timeout = 20000;
    }

    setTimeout(() => {
      props.handleDismiss();
    }, timeout)
  }

  render() {
    const { type, message } = this.state;

    return (
      <div className="top-message">
        <Message compact className={type}
          onDismiss={this.props.handleDismiss}
          content={message}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
  handleDismiss: () => {
    commonActions.hideMessage(dispatch);

  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDismissible);
