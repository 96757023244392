import { getMessage } from "helpers/util-common";
import React from "react";
import { Field } from 'redux-form';
import { Grid, Item } from 'semantic-ui-react';
import RenderRadioField from 'shared-components/RadioBox';
import ConsultantFormFirst from './consultant-form-first';
import ConsultantFormSecond from './consultant-form-second';

class ConsultantDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consultingFirmName: '',
            bestDescribesConsultingFirm: '',
            organizationNameConsultantDetails: '',
            organizationWebsiteConsultantDetails: '',
            organizationPrimarilyProduceProducts: '',
            anyOrganizationPrimarilyProduceProducts: '',
            productSells: '',
            businessActivity: '',
            organizationNameYouRepresenting: '',
            optionsYesNo: [{ label: 'Yes', value: '2' }, { label: 'No', value: '1' }]
        }
    }

    resetAllState = (item) => {
        this.setState({
            ['organizationNameConsultantDetails'+item]: '',
            ['organizationWebsiteConsultantDetails'+item]: '',
            ['organizationPrimarilyProduceProducts'+item]: '',
            ['anyOrganizationPrimarilyProduceProducts'+item]: '',
            ['productSells'+item]: '',
            ['businessActivity'+item]: '',
            ['organizationNameYouRepresenting'+item]: ''
        }, () => {
            this.props.change('organizationNameConsultantDetails'+item, this.state['organizationNameConsultantDetails'+item]);
            this.props.change('organizationWebsiteConsultantDetails'+item, this.state['organizationWebsiteConsultantDetails'+item]);
            this.props.change('organizationPrimarilyProduceProducts'+item, this.state['organizationPrimarilyProduceProducts'+item]);
            this.props.change('anyOrganizationPrimarilyProduceProducts'+item, this.state['anyOrganizationPrimarilyProduceProducts'+item]);
            this.props.change('productSells'+item, this.state['productSells'+item]);
            this.props.change('businessActivity'+item, this.state['businessActivity'+item]);
            this.props.change('organizationNameYouRepresenting'+item, this.state['organizationNameYouRepresenting'+item]);
            this.props.change('organizationNameYouRepresent'+item, this.state['organizationNameYouRepresenting'+item]);
        });
    }
    onChangeConsultantDetails = (event, value, preValue, name) => {
        this.setState({ [name]: value });
        let item=name.replace('bestDescribesConsultingFirm', '')
        this.resetAllState(item)
    };
    componentDidMount = () => {
        const name = "bestDescribesConsultingFirm" + this.props.item;
        const orderFormData = this.props.orderFormData;

        if(orderFormData && orderFormData.memberTempInformationDetails &&
            orderFormData.memberTempInformationDetails.FormData &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
            (orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.SingleOrganization
            ||
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.MultipleOrganization
                )){
        const isSingleOrMultipleOrganisation =
          orderFormData.memberTempInformationDetails.FormData
            .CommitteeDetails[this.props.item].CommitteeMemberClassification
            .SingleOrganization === true
            ? "1"
            : orderFormData.memberTempInformationDetails.FormData
                .CommitteeDetails[this.props.item].CommitteeMemberClassification
                .MultipleOrganization === true
            ? "2"
            : false;
        this.onChangeConsultantDetails(
          undefined,
          isSingleOrMultipleOrganisation,
          undefined,
          name
        );
        this.props.change( "bestDescribesConsultingFirm" + this.props.item, isSingleOrMultipleOrganisation)
        }
        if(orderFormData && orderFormData.memberTempInformationDetails &&
            orderFormData.memberTempInformationDetails.FormData &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.Website
            ){
        const nameOfTheWebsite =
          orderFormData.memberTempInformationDetails.FormData
            .CommitteeDetails[this.props.item].CommitteeMemberClassification
            .Website;
        this.onChangeConsultantDetails(
          undefined,
          nameOfTheWebsite,
          undefined,
          "organizationWebsiteConsultantDetails" + this.props.item
        );
        this.props.change("organizationWebsiteConsultantDetails" + this.props.item, nameOfTheWebsite)
        }
    }
    render() {
        const { organizationPrimarilyProduceProducts, anyOrganizationPrimarilyProduceProducts, optionsYesNo } = this.state;
        const { messageCodes, item} = this.props;

        return (
            <div>
                <Grid columns='equal' className="mt0 spaceToRadio">
                    <Grid.Row className="mt10">
                        <Grid.Column>
                            <Field
                                name={"bestDescribesConsultingFirm"+item}
                                label="Clarify what best describes your consulting services by selecting one of the options below"
                                type="radio"
                                className="mr10"
                                component={RenderRadioField}
                                required={true}
                                isSibling={true}
                                selectedValue={this.state["bestDescribesConsultingFirm"+item]}
                                onChange={this.onChangeConsultantDetails}
                                options={[
                                    {
                                        label: getMessage(messageCodes, '9116.text'),
                                        value: '1',
                                        siblingComponent: this.state["bestDescribesConsultingFirm"+item] && Number(this.state["bestDescribesConsultingFirm"+item]) === 1 && <ConsultantFormFirst
                                            optionsYesNo={optionsYesNo}
                                            organizationPrimarilyProduceProducts={organizationPrimarilyProduceProducts}
                                            {...this.props} />
                                    },
                                    {
                                        label: getMessage(messageCodes, '9117.text'),
                                        value: '2',
                                        siblingComponent: this.state["bestDescribesConsultingFirm"+item] && Number(this.state["bestDescribesConsultingFirm"+item]) === 2 && <ConsultantFormSecond
                                            optionsYesNo={optionsYesNo}
                                            anyOrganizationPrimarilyProduceProducts={anyOrganizationPrimarilyProduceProducts}
                                            {...this.props} />
                                    }
                                ]}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default ConsultantDetails;
