import {
   allowOnlyAlphabets,
   compareTwoTextFields, copyDataToClipboard,
   getMessage
} from 'helpers/util-common';
import { McsStatusMasterId as McsStatusMasterIdEnum } from 'models/common.models';
import { MembershipTypes } from 'modules/member-management/members/members.model';
import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import RenderInput from 'shared-components/Input';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea';
import { encrypt, FORM_TYPE_ORG_UPDATE } from '../../../../../helpers/util-common';
import UpdateOrgInformation from '../update-org-info';

class MemberPersonalDetailsViewEdit extends Component {
   state = {
      isEditMemberNickName: false,
      isEditConfirm: false,
      editType: 'update',
      isEditFacility: false
   }

   copyData = () => {
      const { initialValues, memberMasterDetails } = this.props;
      const name = initialValues.Name ? initialValues.Name : '';
      const facilityName = initialValues.FacilityName ? initialValues.FacilityName : '';
      const address = initialValues && initialValues.FullAddress ? initialValues.FullAddress : '';
      const email = initialValues.Email ? initialValues.Email : '';
      const phoneNumber = initialValues && initialValues.PhoneNumber ? initialValues.PhoneNumber : '';
      const nickName = initialValues.NickName ? initialValues.NickName : '';

      let copiedData = name;

      if (nickName.length > 0) {
         copiedData = copiedData + '\r\n' + nickName;
      }
      if (facilityName.length > 0) {
         copiedData = copiedData + '\r\n' + facilityName;
      }
      if (address.length > 0) {
         copiedData = copiedData + '\r\n' + address;
      }
      if (email.length > 0) {
         copiedData = copiedData + '\r\n' + email;
      }
      if (phoneNumber.length > 0) {
         copiedData = copiedData + '\r\n' + phoneNumber;
      }

      const isCopiedSuccess = copyDataToClipboard(copiedData);

      if (isCopiedSuccess) {
         this.props.showSuccessCopyMessage();
      }
   }

   onEditMemberNickName = () => {
      this.props.change('NickName', this.props.initialValues.NickName);
      this.setState({ isEditMemberNickName: true });
   }

   onCloseMemberNickName = () => {
      this.props.resetEditForm();
      this.setState({ isEditMemberNickName: false });
   }

   requiredValidation = value => (value && value.trim() ? undefined : getMessage(this.props.messageCodes, '8121.text'))

   onSubmitHandler = (values) => {
      // Send API only in case user has updated atleast one prop
      if (compareTwoTextFields(values.NickName, this.props.initialValues.NickName)) {
         const requestBody = {
            NickName: values.NickName ? values.NickName.trim() : '',
            MemberAccountNumber: this.props.initialValues.AccountNumber,
            IsRemoveNickName: !values.NickName,
            UpdateReason: values.UpdateReason
         }
         this.props.updateDemographicInfo(requestBody, () => {
            this.props.getMemberDetails(requestBody.MemberAccountNumber);
            this.onCloseMemberNickName();
         })
      } else {
         this.props.displayNoChangesMadeMessage();
         this.onCloseMemberNickName();
      }
   }

   handleEditFacilityClick = () => {
      const { updatedFacilityName, reset } = this.props;
      reset();
      if (updatedFacilityName) {
         this.setState({ editType: 'update', isEditConfirm: true });
      } else {
         this.setState({ editType: 'new', isEditFacility: true }, () => {
            const { initialize } = this.props;
            initialize('MemberPersonalDetailsViewEdit');
         });
      }
      this.props.setSafariAutoFillInfo(true);
   }

   handleEditConfirm = () => {
      const { initialize, updateCompanyInfo, initialValues } = this.props;
      const { editType } = this.state;
      if (editType === 'update') {
         this.setState({
            isEditConfirm: false,
            isEditFacility: true
         })
      } else if (editType === 'remove') {
         const { AccountNumber, MemberId } = initialValues;
         updateCompanyInfo({
            AccountNumber,
            MemberId: MemberId ? encrypt(MemberId) : null,
            IsRemoveExistingOrg: true
         }, (res) => {
            this.setState({
               isEditConfirm: false
            });
            this.props.getMemberDetails(AccountNumber);
         });
      } else {
         this.setState({ isEditConfirm: false, isEditFacility: true }, () => {
            initialize('MemberPersonalDetailsViewEdit');
         });
      }
   }


   onCloseModal = () => {
      this.setState({ isEditConfirm: false, isEditFacility: false });
   }

   handleEditTypeChange = (e, value) => {
      const { updatedFacilityName } = this.props;
      if (value === 'update' && !Boolean(updatedFacilityName)) {
         return;
      }
      this.setState({
         editType: e && e.currentTarget.querySelector('input') ? e.currentTarget.querySelector('input').value : value
      });
   }
   render() {
      const { submitting, handleSubmit, initialValues, McsStatusMasterId, updatedFacilityName, hasUpdateAccess } = this.props;
      const {
         isEditMemberNickName,
         isEditConfirm,
         editType,
         isEditFacility
      } = this.state;
      return (
         <div data-testid="memberPersonalDetail">
            <Form noValidate onSubmit={handleSubmit(this.onSubmitHandler)}>
               <div className="memberPersonalDetail">
                  <section>
                     <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                           <Grid.Column>
                              <span className="labelTitle">Account Number</span>
                              <span className="labelValue">
                                 {initialValues.AccountNumber}
                              </span>
                           </Grid.Column>
                           <Grid.Column>
                              <span className="labelTitle">First Name</span>
                              <span className="labelValue">
                                 {initialValues.FirstName}
                              </span>
                           </Grid.Column>
                           <Grid.Column>
                              <span className="labelTitle">Middle Name</span>
                              <span className="labelValue">
                                 {initialValues.MiddleName}
                              </span>
                           </Grid.Column>
                           <Grid.Column>
                              <span className="labelTitle">Last Name</span>
                              <span className="labelValue">
                                 {initialValues.LastName}
                              </span>
                           </Grid.Column>
                           <Grid.Column>
                              {isEditMemberNickName ?
                                 <div className="clearfix">
                                    <ul className="editDetailsBtn floatRight">
                                       <li>
                                          <React.Fragment><Field
                                             name='NickName' type="text"
                                             label='Nickname'
                                             component={RenderInput} maxLength={15}
                                             onChange={allowOnlyAlphabets}
                                          />
                                             <span className="font14">Your Nickname can include 1-15 alphabet characters</span>
                                          </React.Fragment>
                                       </li>
                                       <li>
                                          <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                             <Icon name="check" />
                                          </button>
                                       </li>
                                       <li>
                                          <button title="Cancel" className="closeBtn" onClick={this.onCloseMemberNickName}>
                                             <Icon name="close" />
                                          </button>
                                       </li>
                                    </ul></div> : <div className="clearfix">
                                    <React.Fragment>
                                       <span className="labelTitle">Nickname</span>

                                       <span className="labelValue">
                                          <span class="inlineEditBtn">
                                             {initialValues.NickName}
                                          </span>
                                          {McsStatusMasterId === McsStatusMasterIdEnum.ActiveMember && hasUpdateAccess && <a
                                             title="Edit" className="editBtn" onClick={this.onEditMemberNickName}>
                                             <Icon name="pencil" /></a>
                                          }
                                       </span>
                                    </React.Fragment>
                                 </div>}
                           </Grid.Column>

                           <Grid.Column>
                              <span className="labelTitle">Join Date</span>
                              <span className="labelValue">
                                 {initialValues.JoinDate}
                              </span>
                           </Grid.Column>
                           <Grid.Column>
                              <div className="clearfix">
                                 <span className="labelTitle">Facility</span>
                                 <span className="labelValue ">
                                    <span class="inlineEditBtn">
                                       {updatedFacilityName}
                                    </span>
                                    {(initialValues && initialValues.MemberTypeId && initialValues.MemberTypeId !== MembershipTypes.Representative &&
                                       initialValues.MemberTypeId !== MembershipTypes.cooperativeAgreementRep) && McsStatusMasterId === McsStatusMasterIdEnum.ActiveMember &&
                                       hasUpdateAccess && <a title="Edit" className="editBtn" data-testid="editBtn" onClick={this.handleEditFacilityClick}>
                                          <Icon name="pencil" /></a>
                                    }
                                 </span>
                              </div>
                           </Grid.Column>
                           <Grid.Column>
                              <span className="labelTitle">Email</span>
                              <span className="labelValue">
                                 <a href={`mailto:${initialValues.Email}`}>{initialValues.Email}</a>
                              </span>
                           </Grid.Column>
                           <Grid.Column>
                              <span className="labelTitle">Address</span>
                              <span className="labelValue">{initialValues && initialValues.FullAddress ? initialValues.FullAddress : ''}</span>
                           </Grid.Column>

                           <Grid.Column>
                              <span className="labelTitle">Fax</span>
                              <span className="labelValue">{initialValues && initialValues.FaxNumber ? initialValues.FaxNumber : ''}</span>
                           </Grid.Column>
                           <Grid.Column>
                              <span className="labelTitle">Phone</span>
                              <span className="labelValue">{initialValues && initialValues.PhoneNumber ? initialValues.PhoneNumber : ''}</span>
                           </Grid.Column>

                        </Grid.Row>
                     </Grid>
                     {isEditMemberNickName ? <Grid divided='vertically'>
                        <Grid.Row>
                           <Grid.Column>
                              <Field component={TextArea} type='text' label='Reason for update'
                                 name='UpdateReason' required={true} maxLength='200'
                                 placeholder="Please enter reason for update"
                                 validate={[this.requiredValidation]}
                              />
                           </Grid.Column>
                        </Grid.Row>
                     </Grid> : <button type="button"
                        className="ui button secondary" onClick={this.copyData}><Icon name="copy" />Copy</button>
                     }
                  </section>
               </div>
            </Form>
            {isEditConfirm && (
               <Modal open='true' className="tiny addCommitttee" data-testid="addCommitttee">
                  <Modal.Header>Add/Update Organization<i className="close" onClick={this.onCloseModal}>&#x2716;</i></Modal.Header>
                  <Modal.Content scrolling>
                     <Modal.Description>
                        <div className="add-update-facility-div">
                           <RenderRadioField
                              name="editType"
                              type="radio"
                              className="mr10"
                              onChange={this.handleEditTypeChange}
                              selectedValue={editType}
                              options={[
                                 {
                                    value: 'update',
                                    label: (
                                       <label>
                                          <strong onClick={() => this.handleEditTypeChange(null, 'update')}>Update Your Existing Organization:</strong><br />
                                          You can make corrections in existing organization name and address details.
                                       </label>
                                    ),
                                    isDisabled: !Boolean(updatedFacilityName)
                                 },
                                 {
                                    value: 'new',
                                    label: (
                                       <label>
                                          <strong onClick={() => this.handleEditTypeChange(null, 'new')}>Associate With New Organization:</strong><br />
                                          You can either select from the list of available organizations or create new organization by filling in the details.
                                       </label>
                                    )
                                 },
                                 {
                                    value: 'remove',
                                    label: (
                                       <label>
                                          <strong onClick={() => this.handleEditTypeChange(null, 'remove')}> Remove Existing Organization:</strong><br />
                                          You can remove or dissociate the organization from the member account.
                                       </label>
                                    )
                                 }
                              ]}
                           />

                        </div>
                     </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                     <div>
                        <Button primary onClick={this.handleEditConfirm}>Proceed</Button>
                        <Button secondary className='cancel' onClick={this.onCloseModal}>Cancel</Button>
                     </div>
                  </Modal.Actions>
               </Modal>
            )}
            {isEditFacility && <UpdateOrgInformation
               {...this.props}
               formType={FORM_TYPE_ORG_UPDATE.Member}
               editType={editType}
               onCloseModal={this.onCloseModal}
            />}
         </div>
      );
   }
}


export default reduxForm({
   form: 'MemberPersonalDetailsViewEdit',
   enableReinitialize: true,
   keepDirtyOnReinitialize: true
})(MemberPersonalDetailsViewEdit);
