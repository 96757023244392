
import { GET_FEEGROUP_LIST, SEARCH_COMMITTEES, SEARCH_MEMBERS, SEARCH_ORGANIZATIONS, SEARCH_TASK, SEARCH_FEEGROUP } from '../action-types';


/* **********************************  Keys Defination *********************************
ModuleName                  : Display Module Name
ModuleKeys                  : Module Key for Identification for Module
iconClass                   : Display Module Icon
hasNavChild                 : Check Sub Page View Display
routeUrl                    : Navigation Url/Route
isShow                      : Toggle View handle Collapse/Expand
permissionType              : Identification of Permission
componentElementOnPage      : Element's Display on Parent Pages
isVisibleOnNavigation       : Display In Navigation Component Parent/Child
title                       : Display SubChild Tile Name
breadcrumbsTitle            : Display in BreadCrumb Name
breadcrumbsParentPath       : Display BreadCrumb Path
breadcrumbsParentTitle      : Display BreadCrumb Parent Title
oslUrl                      : For Validate OSL path if allow permission
oslMethod                   : For Validate OSL Method if allow permission
searchParams                : For Global Search
uniqueKey                   : Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]
***************************************************************************************/
export const MODULES = {
    0: "Home",
    1: "Membership_Management",
    2: "Committee_Management",
    3: "Renewal_Tasks",
    4: "Work_Item",
    5: "Ballot_Admin",
    6: "Admin",
    7: "Reports",
    8: "Documents"
}

export const PAGE_CONFIG = {
    // Privilege Type
    USER_PRIVILEGE: {
        ALL: "All",
        VIEW: "View",
        ADD: "Add",
        UPDATE: "Update",
        DELETE: "Delete",
        EXPORT: "Export",
        MODERATE_COMMUNICATION_LOG: "ModerateCommunicationLog",
        ADD_MEMBER_TO_COMMITTEE: "AddMembertoCommittee",
        EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE: "EditMemberParticipationonCommittees",
        TASK_ADMIN: "TaskAdmin",
        INACTIVATE: "Inactivate",
        REACTIVATE: "Reactivate",
        START_RENEWAL_ON_WEB: "StartRenewalOnWeb",
        PROCESS_FEE_DROP: "ProcessFeeDrop",
        PROCESS_TEMPORARY_MEMBER_DROP: "ProcessTemporaryMemberDrop",
        BOOK_VOLUME_INFORMATION: "BookVolumeInformation",
        MEMBERSHIP_INVOICE_OR_RECEPT: "MembershipInvoiceorReceipt",
        CHANGE_IN_BOOK_VOLUME: "ChangeInBookVolume",
        MEMBERSHIPS_BY_COUNT: "MembershipsbyCount",
        MEMBERSHIP_REVENUE_BY_TYPE: "MembershipRevenuesbyType",
        SOCIETY_MEMBER_REPORT: "SocietyMemberReport",
        MEMBERSHIP_STATISTICS_REPORT: "MembershipStatisticsReport",
        INATIVE_PAID_MEMBERS: "InactivePaidMembers",
        UN_PAID_OFFIERS: "UnpaidOfficers",
        NON_PAID_MEBERSHIP_REPORT: "Non-PaidMembershipReport",
        GROUPINGS_LIST: "GroupingsList",
        BLOCK_SCHEDULE_REPORT: 'BlockScheduleReport',
        LINE_SCHEDULE_REPORT: 'LineScheduleReport',
        REGISTRATION_FEES_REPORT: 'RegistrationFeesReport',
        REGISTRATION_REPORT: 'RegistrationReport',
        SYMPOSIA_FEE_REPORT: 'SymposiaFeeReport',
        SYMPOSIA_EXPORT: 'SymposiaExport',
        ACCOUNTING_REPORT: 'AccountingReport',
        TOTALS_REPORTS: 'TotalsReports',
        COMMITTEE_ATTENDANCE_SUMMARY: 'CommitteeAttendanceSummary',
        SYMPOSIA_ABSTRACT_LISTING: 'SymposiaAbstractListing',
        REGISTRATION_LIST_BY_HOTEL: 'RegistrationListbyHotel',
        EMAIL_REMINDER_LIST: 'EmailReminderList',
        UPDATE_PAID_STATUS: 'UpdatePaidStatus',
        ACCOUNTING_REPORT: 'AccountingReport',
        CHAIR_RECEPTION_LIST_REPORT: 'ChairReceptionListReport',
        SEQUENCE:'Sequence',
        PUBLISH:'Publish'
    },


    // HTTP METHODS
    HTTP_METHOD: {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE"
    },
    // Type of Pages
    PAGE_TYPE: {
        DETAIL: (page) => `${page}-Detail`,
        HOME: "Home",
        MEMBER: "Member",
        ORGANIZATION: "Organization",
        COMMITTEE: "Committee",
        TASK: "Task",
        USER: "User",
        ROLE: "Role",
        FEEGROUP: 'Feegroup',
        WORK_ITEM_ADMIN_TOOL: 'WORK_ITEM_ADMIN_TOOL',
        DELETED_WORK_ITEMS: 'DELETED_WORK_ITEMS',
        WORK_ITEM_AUDIT_LOG: 'WORK_ITEM_AUDIT_LOG',
        MEMBERSHIP_RENEWAL: "MembershipRenewal",
        ADD_MEMBER: 'Addmember',
        BALLOT_ADMIN: 'BALLOT_ADMIN',
        VIEW_VOTE_HISTORY: 'VIEW_VOTE_HISTORY',
        VIEW_BALLOT_ITEM_ADMIN: 'VIEW_BALLOT_ITEM_ADMIN',
        BALLOT_ITEM_LIST: 'BALLOT_ITEM_LIST',
        BALLOT_ITEM_SUBMISSION_LIST: 'BALLOT_ITEM_SUBMISSION_LIST',
        VIEW_VOTE_HISTORY_DETAILS: 'VIEW_VOTE_HISTORY_DETAILS',
        MEMBERSHIP_REPORT: 'MEMBERSHIP_REPORT',
        MEMBER_EXCEPTION_REPORT: 'MEMBERSHIP_REPORT',
        RENEWAL_REPORT: 'RENEWAL_REPORT',
        MEETING_REPORT: 'MEETING_REPORT',
        EMAIL_REMINDER_LIST: 'EmailReminderList',
        MEMBER_EXCEPTION: 'MEMBER_EXCEPTION',
        BOS_VOLUME_LOCATION: 'BOS_VOLUME_LOCATION',
        DOCUMENTS:'Documents',
        MANAGE_ALERTS:'MANAGE_ALERTS'
    },
    // Create Unique Key For page Routes
    uniqueKey: (page = "", privilege = "") => { // Page Will Generate Combination of Page Name and Permission its self.
        // Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]
        return `${page}-${privilege}`;
    }
}

// Set All Page Configuration
export const { HTTP_METHOD, PAGE_TYPE, USER_PRIVILEGE, uniqueKey } = PAGE_CONFIG;

export const NAVIGATION = [
    // Dashboard Module JSON
    {
        'ModuleName': "Home",
        'ModuleKeys': MODULES[0],
        'iconClass': '',
        'isShow': false, // For Toggle Navgation if Required
        'hasNavChild': true, // For Check Has Page Exist
        'routeUrl': "#",
        "isVisibleOnNavigation": false,
        [MODULES[0]]: [
            { // DashBoard View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': '/',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "Dashboard",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "oslMethod": "",
                "uniqueKey": uniqueKey(PAGE_TYPE.HOME, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Member Management Module JSON
    {
        'ModuleName': "Member",
        'ModuleKeys': MODULES[1],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "isVisibleOnNavigation": true,
        [MODULES[1]]: [
            { // Member List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/members',
                "isVisibleOnNavigation": true,
                'title': "Members",
                "breadcrumbsTitle": "Members",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": { value: SEARCH_MEMBERS, text: 'Members', placeholder: 'Search by Account No., First Name or Last Name, Organization/Facility, Email, Postal Code, Phone Number', orderIndex: 1 }
            },
            { // Member Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/members/details/:accountNumber',
                "isVisibleOnNavigation": false,
                'title': "Member Details",
                "breadcrumbsTitle": "Member Details",
                "breadcrumbsParentPath": "member-management/members",
                "breadcrumbsParentTitle": "Members",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE),
                uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE),
                uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)
                ],
                "searchParams": { value: SEARCH_MEMBERS, text: 'Members', placeholder: 'Search Member by Account Number, Name, Organization/Facility, Email, Postal Code, Phone Number', orderIndex: 1 }
            },
            { // Member Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/exportFile/getMembers/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Paid status Permission
                "permissionType": USER_PRIVILEGE.UPDATE_PAID_STATUS,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/paidStatus/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.UPDATE_PAID_STATUS),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/organizations',
                "isVisibleOnNavigation": true,
                'title': "Organizations",
                "breadcrumbsTitle": "Organizations",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": { value: SEARCH_ORGANIZATIONS, text: 'Organizations', placeholder: 'Search Organization by Account Number, Organization Name', orderIndex: 4 }
            },
            { // Organization Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/organizations/details/:accountNumber',
                "isVisibleOnNavigation": false,
                'title': "Organizational Account Details",
                "breadcrumbsTitle": "Organizational Details",
                "breadcrumbsParentPath": "member-management/organizations",
                "breadcrumbsParentTitle": "Organizations",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_ORGANIZATIONS, text: 'Organizations', placeholder: 'Search Organization by Account Number, Organization Name', orderIndex: 4 }
            },
            { // Organization Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/exportFile/getOrganizations/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization Paid status Permission
                "permissionType": USER_PRIVILEGE.UPDATE_PAID_STATUS,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/paidStatus/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.UPDATE_PAID_STATUS),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Fee Group List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/fee-group',
                "isVisibleOnNavigation": true,
                'title': "Fee Group",
                "breadcrumbsTitle": "Fee Group",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": {value: SEARCH_FEEGROUP, text: 'Fee Group', placeholder: 'Search Fee Group by Fee Group Number, Title, Contact Name', orderIndex: 3}
            },
            { // Fee Group Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/fee-group/details/:activeIndex/:feeGroupId',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Fee Group Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": {value: SEARCH_FEEGROUP, text: 'Fee Group', placeholder: 'Search Fee Group by Fee Group Number, Title, Contact Name', orderIndex: 3}
            },
            { // Fee Group ADD Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Fee Group UPDATE Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Fee Group DELETE Permission
                "permissionType": USER_PRIVILEGE.DELETE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.DELETE),
                "oslMethod": HTTP_METHOD.DELETE,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Committee Management Module JSON
    {
        'ModuleName': "Committee",
        'ModuleKeys': MODULES[2],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "committee-management/committees",
        "breadcrumbsRootTitle": "",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        [MODULES[2]]: [
            { // Committee List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'committee-management/committees',
                "isVisibleOnNavigation": false,
                'title': 'Committee Management',
                "breadcrumbsTitle": "Committees",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": { value: SEARCH_COMMITTEES, text: 'Committees', placeholder: 'Search Committee by Designation, Title', orderIndex: 2 }
            },
            { // Committee Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'committee-management/committees/details/:committeeId',
                "isVisibleOnNavigation": false,
                'title': 'Committee Details',
                "breadcrumbsTitle": "Committee Details",
                "breadcrumbsParentPath": "committee-management/committees",
                "breadcrumbsParentTitle": "Committees",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_COMMITTEES, text: 'Committees', placeholder: 'Search Committee by Designation, Title', orderIndex: 2 }
            },
            { // Committee Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/exportFile/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Add Member on committee Permission
                "permissionType": USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/addMemberToCommittee/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Edit Member participation on committee Permission
                "permissionType": USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/memberParticipation/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee INACTIVATE on committee Permission
                "permissionType": USER_PRIVILEGE.INACTIVATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/inactiveCommittee/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.INACTIVATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee INACTIVATE on committee Permission
                "permissionType": USER_PRIVILEGE.REACTIVATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/reactiveCommittee/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.REACTIVATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            }

        ]
    },
    // Renewal Task Management Module JSON
    {
        'ModuleName': "Renewal Tasks",
        'ModuleKeys': MODULES[3],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "renewal-tasks",
        "isVisibleOnNavigation": true,
        [MODULES[3]]: [
            { // Renewal List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'renewal-tasks',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Tasks',
                "breadcrumbsTitle": "Renewal Tasks",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_TASK, text: 'Renewal Tasks', placeholder: 'Search Task by Task ID, Title, Assignee', orderIndex: 5 }
            },
            { // Renewal Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'renewal-tasks/details/:taskId',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Task Details',
                "breadcrumbsTitle": "Renewal Task Details",
                "breadcrumbsParentPath": "renewal-tasks",
                "breadcrumbsParentTitle": "Renewal Tasks",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_TASK, text: 'Renewal Tasks', placeholder: 'Search Task by Task ID, Title, Assignee', orderIndex: 5 }
            },
            { // Renewal Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/exportFile/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Task Admin Permission
                "permissionType": USER_PRIVILEGE.TASK_ADMIN,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/taskAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.TASK_ADMIN),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Work Item Admin Module
    {
        'ModuleName': "Work Item",
        'ModuleKeys': MODULES[4],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "work-item-admin",
        "breadcrumbsRootTitle": "",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        [MODULES[4]]: [
            { // Work Item Admin Tools page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Admin Tool',
                "breadcrumbsTitle": "Work Item Admin Tool",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Work Item Admin Tools Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.UPDATE)],
                'routeUrl': 'work-item-admin',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Admin Tool',
                "breadcrumbsTitle": "Work Item Admin Tool",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            },
            { // Work Item Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.DELETE), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.REACTIVATE)],
                "searchParams": null
            },
            { // Work Item Details Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.UPDATE)],
                "searchParams": null
            },
            { // Work Item Details Delete Permission
                "permissionType": USER_PRIVILEGE.DELETE,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.DELETE),
                "oslMethod": HTTP_METHOD.DELETE,
                "additionalPermission": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.DELETE)],
                "searchParams": null
            },
            { // Work Item Details Restore Permission
                "permissionType": USER_PRIVILEGE.REACTIVATE,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/recoverWorkItem/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.REACTIVATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.REACTIVATE)],
                "searchParams": null
            },
            { // Deleted Work Items Page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/deletedWorkItems',
                "isVisibleOnNavigation": false,
                'title': 'Deleted Work Items',
                "breadcrumbsTitle": "Deleted Work Items",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DELETED_WORK_ITEMS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            },
            { // Work Items Audit Page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/workItemsAuditLog',
                "isVisibleOnNavigation": false,
                'title': 'Audit Log',
                "breadcrumbsTitle": "Audit Log",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.WORK_ITEM_AUDIT_LOG, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            },
            { // Ballot Item Submission Page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/ballotItemSubmissions',
                "isVisibleOnNavigation": false,
                'title': 'View Ballot Items',
                "breadcrumbsTitle": "View Ballot Items",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ITEM_SUBMISSION_LIST, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            }
        ]
    },
    // Ballot Admin Module JSON
    {
        'ModuleName': "Ballot",
        'ModuleKeys': MODULES[5],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "breadcrumbsRootTitle": "Ballot Admin",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        [MODULES[5]]: [
            { // Ballot Admin View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin',
                "isVisibleOnNavigation": true,
                'title': "Ballot Admin",
                "breadcrumbsTitle": "Ballot Admin",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
            { // view-vote-history Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-vote-history/:memberAccountNumber/:memberId',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "View Vote History",
                "breadcrumbsParentPath": "ballot-admin",
                "breadcrumbsParentTitle": "Ballot Admin",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
            { // view-ballot-item-admin Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.VIEW_BALLOT_ITEM_ADMIN, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-ballot-item-admin',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "View Ballot Item Admin",
                "breadcrumbsParentPath": "ballot-admin",
                "breadcrumbsParentTitle": "Ballot Admin",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.VIEW_BALLOT_ITEM_ADMIN, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
            { // Ballot Admin View Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [],
                'routeUrl': 'ballot-admin',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "Ballot Admin",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.PUT,
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.UPDATE),
                "additionalPermission": [],
                "searchParams": null
            },
            { // ballot-item-list Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BALLOT_ITEM_LIST, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-ballot-item-admin/ballot-item-list/:LetterBallot',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "Letter Ballot",
                "breadcrumbsParentPath": "ballot-admin/view-ballot-item-admin",
                "breadcrumbsParentTitle": "Ballot Admin / View Ballot Item Admin",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ITEM_LIST, USER_PRIVILEGE.VIEW),
                "additionalPermission": [uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.UPDATE)],
                "searchParams": null
            },
            { // view-vote-history Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY_DETAILS, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-vote-history-details/:letterBallot/:memberAccountNumber/:memberId/:voteStatus',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "View Vote History Details",
                "breadcrumbsParentPath": "ballot-admin",
                "breadcrumbsParentTitle": "Ballot Admin / View Vote History",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY_DETAILS, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
        ]
    },
    // Admin Management Module JSON
    {
        'ModuleName': "Admin",
        'ModuleKeys': MODULES[6],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "isVisibleOnNavigation": true,
        [MODULES[6]]: [
            { // User Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/user',
                "isVisibleOnNavigation": true,
                'title': 'User Permissions',
                "breadcrumbsTitle": "Users",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/user/detail/:userName',
                "isVisibleOnNavigation": false,
                'title': 'User Details',
                "breadcrumbsTitle": "User Details",
                "breadcrumbsParentPath": "admin/user",
                "breadcrumbsParentTitle": "User",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/exportFile/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Role List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/roles',
                "isVisibleOnNavigation": true,
                'title': 'Roles',
                "breadcrumbsTitle": "Roles",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/roledetail/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Role Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/roledetail/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Role Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/roledetail/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [View] Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/membership-renewal',
                "isVisibleOnNavigation": true,
                'title': 'Membership Renewal',
                "breadcrumbsTitle": "Membership Renewal",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Start Renewal on Web] Permission
                "permissionType": USER_PRIVILEGE.START_RENEWAL_ON_WEB,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/startRenewalOnWeb/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.START_RENEWAL_ON_WEB),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Process Fee Drop] Permission
                "permissionType": USER_PRIVILEGE.PROCESS_FEE_DROP,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "memberShipRenewal/processFeeDrop/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.PROCESS_FEE_DROP),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Process Temporary Member Drop] Permission
                "permissionType": USER_PRIVILEGE.PROCESS_TEMPORARY_MEMBER_DROP,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/processTemporaryMemberDrop/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.PROCESS_TEMPORARY_MEMBER_DROP),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Book Volume Information] Permission
                "permissionType": USER_PRIVILEGE.BOOK_VOLUME_INFORMATION,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/bookVolumeInfo/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.BOOK_VOLUME_INFORMATION),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Manual Order View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/manual-order',
                "isVisibleOnNavigation": true,
                'title': 'Manual Order',
                "breadcrumbsTitle": "Manual Order",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/addMember/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Manual Order Add Member Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/addMember/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View MEMBER EXCEPTION List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/membership-exception',
                "isVisibleOnNavigation": true,
                'title': 'Membership Exceptions',
                "breadcrumbsTitle": "Membership Exceptions",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membership-exception/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View MEMBER EXCEPTION Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': 'Membership Exceptions',
                "breadcrumbsTitle": "Membership Exceptions",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membership-exception/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View BOS VOLUME LOCATION List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/bos-volume-location',
                "isVisibleOnNavigation": true,
                'title': 'BOS Volume Location',
                "breadcrumbsTitle": "BOS Volume Location",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Update BOS VOLUME LOCATION Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // BOS VOLUME LOCATION Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/export/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View Manage Alert Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/manage-alerts',
                "isVisibleOnNavigation": true,
                'title': 'Manage Alerts',
                "breadcrumbsTitle": "Manage Alerts",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageAlerts/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Add Manage Alert Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageAlerts/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Update Manage Alert Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageAlerts/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Report Management Module JSON
    {
        'ModuleName': "Reports",
        'ModuleKeys': MODULES[7],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "isVisibleOnNavigation": true,
        [MODULES[7]]: [
            { // Memberships Reports Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'membership-reports',
                "isVisibleOnNavigation": true,
                'title': 'Membership Reports',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // mcs status Reports Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIPS_BY_COUNT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/statusReport',
                "isVisibleOnNavigation": false,
                'title': 'MCS Status report',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/statusReport",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIPS_BY_COUNT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Revenue by Type Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIP_REVENUE_BY_TYPE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/revenueReport',
                "isVisibleOnNavigation": false,
                'title': 'Revenue report',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "reports/membership-reports/revenueReport/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIP_REVENUE_BY_TYPE),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // society member Report Permission
                "permissionType": USER_PRIVILEGE.SOCIETY_MEMBER_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/society-member-report',
                "isVisibleOnNavigation": false,
                'title': "All Society Member Report",
                "breadcrumbsTitle": "All Society Member Report",
                "breadcrumbsParentPath": "membership-reports",
                "breadcrumbsParentTitle": "Membership Reports",
                "oslUrl": "/reports/membership-reports/societyreport",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.SOCIETY_MEMBER_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // membership statistics report Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIP_STATISTICS_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/statisticsReport',
                "isVisibleOnNavigation": false,
                'title': 'Statistics Report',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/statisticsReport",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIP_STATISTICS_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Memberships Reports Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIP_INVOICE_OR_RECEPT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/membership-invoice-receipt',
                "isVisibleOnNavigation": false,
                'title': 'Membership Invoice or Receipt',
                "breadcrumbsTitle": "Membership Invoice or Receipt",
                "breadcrumbsParentPath": "membership-reports",
                "breadcrumbsParentTitle": "Membership Reports",
                "oslUrl": "reports/membership-reports/membership-invoice-receipt/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIP_INVOICE_OR_RECEPT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Reports Change in Book Volume Permission
                "permissionType": USER_PRIVILEGE.CHANGE_IN_BOOK_VOLUME,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/change-book-volume',
                "isVisibleOnNavigation": false,
                'title': 'Change in Book Volume',
                "breadcrumbsTitle": "Change in Book Volume",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/change-book-volume/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.CHANGE_IN_BOOK_VOLUME),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Exception Report Permission
                "permissionType": '',
                "componentElementOnPage": [],
                'routeUrl': '',
                "isVisibleOnNavigation": true,
                'title': 'Member Exception Report',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "uniqueKey": '',
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Report View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'renewal-reports',
                "isVisibleOnNavigation": true,
                'title': 'Renewal Reports',
                "breadcrumbsTitle": "Renewal Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/renewal-reports/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Report UN_PAID_OFFIERS View Permission
                "permissionType": USER_PRIVILEGE.UN_PAID_OFFIERS,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'renewal-reports',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Reports',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/renewal-reports/unpaid-officer",
                "uniqueKey": uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.UN_PAID_OFFIERS),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Report Org/Non-Og Member Permission
                "permissionType": USER_PRIVILEGE.EMAIL_REMINDER_LIST,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'renewal-reports',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Reports',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/email-reminder-list/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.EMAIL_REMINDER_LIST),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'meeting-reports',
                "isVisibleOnNavigation": true,
                'title': 'Meeting Reports',
                "breadcrumbsTitle": "Meeting Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/meeting-reports/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Block Schedule Permission
                "permissionType": USER_PRIVILEGE.BLOCK_SCHEDULE_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/block-schedule',
                "isVisibleOnNavigation": false,
                'title': 'Block Schedule',
                "breadcrumbsTitle": "Block Schedule",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/block-schedule-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.BLOCK_SCHEDULE_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Line Schedule Report Permission
                "permissionType": USER_PRIVILEGE.LINE_SCHEDULE_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/line-schedule',
                "isVisibleOnNavigation": false,
                'title': 'Line Schedule',
                "breadcrumbsTitle": "Line Schedule",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/line-schedule-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.LINE_SCHEDULE_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Registration Fees Report Permission
                "permissionType": USER_PRIVILEGE.REGISTRATION_FEES_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/registration-attendance-fees-report',
                "isVisibleOnNavigation": false,
                'title': 'Registration, Attendance & Fees Report',
                "breadcrumbsTitle": "Registration, Attendance & Fees Report",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/registration-fees-report/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.REGISTRATION_FEES_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Accounting Report Permission
                "permissionType": USER_PRIVILEGE.ACCOUNTING_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/accounting-report',
                "isVisibleOnNavigation": false,
                'title': 'Accounting Report',
                "breadcrumbsTitle": "Accounting Report",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/accounting-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.ACCOUNTING_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Chair Reception List Report Permission
                "permissionType": USER_PRIVILEGE.CHAIR_RECEPTION_LIST_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/chair-reception-list-report',
                "isVisibleOnNavigation": false,
                'title': 'Chair Reception List',
                "breadcrumbsTitle": "Chair Reception List",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/chair-reception-list-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.CHAIR_RECEPTION_LIST_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    }
];

export const NAVIGATIONHEADER = [
    // Dashboard Module JSON
    {
        'ModuleName': "Home",
        'ModuleKeys': MODULES[0],
        'iconClass': '',
        'isShow': false, // For Toggle Navgation if Required
        'hasNavChild': true, // For Check Has Page Exist
        'routeUrl': "#",
        "isVisibleOnNavigation": false,
        [MODULES[0]]: [
            { // DashBoard View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': '/',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "Dashboard",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "oslMethod": "",
                "uniqueKey": uniqueKey(PAGE_TYPE.HOME, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Member Management Module JSON
    {
        'ModuleName': "Member",
        'ModuleKeys': MODULES[1],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "isVisibleOnNavigation": true,
        [MODULES[1]]: [
            { // Member List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/members',
                "isVisibleOnNavigation": true,
                'title': "Members",
                "breadcrumbsTitle": "Members",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": { value: SEARCH_MEMBERS, text: 'Members', placeholder: 'Search by Account No., First Name or Last Name, Organization/Facility, Email, Postal Code, Phone Number', orderIndex: 1 }
            },
            { // Member Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/members/details/:accountNumber',
                "isVisibleOnNavigation": false,
                'title': "Member Details",
                "breadcrumbsTitle": "Member Details",
                "breadcrumbsParentPath": "member-management/members",
                "breadcrumbsParentTitle": "Members",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE),
                uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE),
                uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)
                ],
                "searchParams": { value: SEARCH_MEMBERS, text: 'Members', placeholder: 'Search Member by Account Number, Name, Organization/Facility, Email, Postal Code, Phone Number', orderIndex: 1 }
            },
            { // Member Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW),
                uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/exportFile/getMembers/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Paid status Permission
                "permissionType": USER_PRIVILEGE.UPDATE_PAID_STATUS,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/paidStatus/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.UPDATE_PAID_STATUS),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/organizations',
                "isVisibleOnNavigation": true,
                'title': "Organizations",
                "breadcrumbsTitle": "Organizations",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": { value: SEARCH_ORGANIZATIONS, text: 'Organizations', placeholder: 'Search Organization by Account Number, Organization Name', orderIndex: 4 }
            },
            { // Organization Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/organizations/details/:accountNumber',
                "isVisibleOnNavigation": false,
                'title': "Organizational Account Details",
                "breadcrumbsTitle": "Organizational Details",
                "breadcrumbsParentPath": "member-management/organizations",
                "breadcrumbsParentTitle": "Organizations",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_ORGANIZATIONS, text: 'Organizations', placeholder: 'Search Organization by Account Number, Organization Name', orderIndex: 4 }
            },
            { // Organization Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/exportFile/getOrganizations/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Organization Paid status Permission
                "permissionType": USER_PRIVILEGE.UPDATE_PAID_STATUS,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberManagement/paidStatus/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.UPDATE_PAID_STATUS),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Fee Group List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/fee-group',
                "isVisibleOnNavigation": true,
                'title': "Fee Group",
                "breadcrumbsTitle": "Fee Group",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": {value: SEARCH_FEEGROUP, text: 'Fee Group', placeholder: 'Search Fee Group by Fee Group Number, Title, Contact Name', orderIndex: 3}
            },
            { // Fee Group Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'member-management/fee-group/details/:activeIndex/:feeGroupId',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Fee Group Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": {value: SEARCH_FEEGROUP, text: 'Fee Group', placeholder: 'Search Fee Group by Fee Group Number, Title, Contact Name', orderIndex: 3}
            },
            { // Fee Group ADD Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Fee Group UPDATE Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Fee Group DELETE Permission
                "permissionType": USER_PRIVILEGE.DELETE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "Fee Group Details",
                "breadcrumbsTitle": "Details",
                "breadcrumbsParentPath": "member-management/fee-group",
                "breadcrumbsParentTitle": "Fee Group",
                "oslUrl": "/feeGroup/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.DELETE),
                "oslMethod": HTTP_METHOD.DELETE,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Committee Management Module JSON
    {
        'ModuleName': "Committee",
        'ModuleKeys': MODULES[2],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "committee-management/committees",
        "breadcrumbsRootTitle": "",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        [MODULES[2]]: [
            { // Committee List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'committee-management/committees',
                "isVisibleOnNavigation": false,
                'title': 'Committee Management',
                "breadcrumbsTitle": "Committees",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": { value: SEARCH_COMMITTEES, text: 'Committees', placeholder: 'Search Committee by Designation, Title', orderIndex: 2 }
            },
            { // Committee Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'committee-management/committees/details/:committeeId',
                "isVisibleOnNavigation": false,
                'title': 'Committee Details',
                "breadcrumbsTitle": "Committee Details",
                "breadcrumbsParentPath": "committee-management/committees",
                "breadcrumbsParentTitle": "Committees",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_COMMITTEES, text: 'Committees', placeholder: 'Search Committee by Designation, Title', orderIndex: 2 }
            },
            { // Committee Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/exportFile/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Add Member on committee Permission
                "permissionType": USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/addMemberToCommittee/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee Edit Member participation on committee Permission
                "permissionType": USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/memberParticipation/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee INACTIVATE on committee Permission
                "permissionType": USER_PRIVILEGE.INACTIVATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/inactiveCommittee/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.INACTIVATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Committee INACTIVATE on committee Permission
                "permissionType": USER_PRIVILEGE.REACTIVATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeeManagement/reactiveCommittee/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.REACTIVATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            }

        ]
    },
    // Renewal Task Management Module JSON
    {
        'ModuleName': "Renewal Tasks",
        'ModuleKeys': MODULES[3],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "renewal-tasks",
        "isVisibleOnNavigation": true,
        [MODULES[3]]: [
            { // Renewal List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'renewal-tasks',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Tasks',
                "breadcrumbsTitle": "Renewal Tasks",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_TASK, text: 'Renewal Tasks', placeholder: 'Search Task by Task ID, Title, Assignee', orderIndex: 5 }
            },
            { // Renewal Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'renewal-tasks/details/:taskId',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Task Details',
                "breadcrumbsTitle": "Renewal Task Details",
                "breadcrumbsParentPath": "renewal-tasks",
                "breadcrumbsParentTitle": "Renewal Tasks",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": { value: SEARCH_TASK, text: 'Renewal Tasks', placeholder: 'Search Task by Task ID, Title, Assignee', orderIndex: 5 }
            },
            { // Renewal Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/exportFile/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Moderate Communication Log Permission
                "permissionType": USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/moderate/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Task Admin Permission
                "permissionType": USER_PRIVILEGE.TASK_ADMIN,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.TASK), USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/task/taskAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.TASK, USER_PRIVILEGE.TASK_ADMIN),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Work Item Admin Module
    {
        'ModuleName': "Work Item",
        'ModuleKeys': MODULES[4],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "work-item-admin",
        "breadcrumbsRootTitle": "",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        [MODULES[4]]: [
            { // Work Item Admin Tools page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Admin Tool',
                "breadcrumbsTitle": "Work Item Admin Tool",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Work Item Admin Tools Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.UPDATE)],
                'routeUrl': 'work-item-admin',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Admin Tool',
                "breadcrumbsTitle": "Work Item Admin Tool",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            },
            { // Work Item Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.UPDATE), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.DELETE), uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.REACTIVATE)],
                "searchParams": null
            },
            { // Work Item Details Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.UPDATE)],
                "searchParams": null
            },
            { // Work Item Details Delete Permission
                "permissionType": USER_PRIVILEGE.DELETE,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.DELETE),
                "oslMethod": HTTP_METHOD.DELETE,
                "additionalPermission": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.DELETE)],
                "searchParams": null
            },
            { // Work Item Details Restore Permission
                "permissionType": USER_PRIVILEGE.REACTIVATE,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/details/:workItemNumber',
                "isVisibleOnNavigation": false,
                'title': 'Work Item Details',
                "breadcrumbsTitle": "Work Item Details",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/recoverWorkItem/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.REACTIVATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.WORK_ITEM_ADMIN_TOOL), USER_PRIVILEGE.REACTIVATE)],
                "searchParams": null
            },
            { // Deleted Work Items Page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/deletedWorkItems',
                "isVisibleOnNavigation": false,
                'title': 'Deleted Work Items',
                "breadcrumbsTitle": "Deleted Work Items",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DELETED_WORK_ITEMS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            },
            { // Work Items Audit Page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/workItemsAuditLog',
                "isVisibleOnNavigation": false,
                'title': 'Audit Log',
                "breadcrumbsTitle": "Audit Log",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.WORK_ITEM_AUDIT_LOG, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            },
            { // Ballot Item Submission Page
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'work-item-admin/ballotItemSubmissions',
                "isVisibleOnNavigation": false,
                'title': 'View Ballot Items',
                "breadcrumbsTitle": "View Ballot Items",
                "breadcrumbsParentPath": "work-item-admin",
                "breadcrumbsParentTitle": "Work Item Admin Tool",
                "oslUrl": "/workItemAdmin/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ITEM_SUBMISSION_LIST, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)],
                "searchParams": null
            }
        ]
    },
    // Ballot Admin Module JSON
    {
        'ModuleName': "Ballot",
        'ModuleKeys': MODULES[5],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "breadcrumbsRootTitle": "Ballot Admin",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        [MODULES[5]]: [
            { // Ballot Admin View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin',
                "isVisibleOnNavigation": true,
                'title': "Ballot Admin",
                "breadcrumbsTitle": "Ballot Admin",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
            { // view-vote-history Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-vote-history/:memberAccountNumber/:memberId',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "View Vote History",
                "breadcrumbsParentPath": "ballot-admin",
                "breadcrumbsParentTitle": "Ballot Admin",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
            { // view-ballot-item-admin Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.VIEW_BALLOT_ITEM_ADMIN, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-ballot-item-admin',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "View Ballot Item Admin",
                "breadcrumbsParentPath": "ballot-admin",
                "breadcrumbsParentTitle": "Ballot Admin",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.VIEW_BALLOT_ITEM_ADMIN, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
            { // Ballot Admin View Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [],
                'routeUrl': 'ballot-admin',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "Ballot Admin",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.PUT,
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.UPDATE),
                "additionalPermission": [],
                "searchParams": null
            },
            { // ballot-item-list Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BALLOT_ITEM_LIST, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-ballot-item-admin/ballot-item-list/:LetterBallot',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "Letter Ballot",
                "breadcrumbsParentPath": "ballot-admin/view-ballot-item-admin",
                "breadcrumbsParentTitle": "Ballot Admin / View Ballot Item Admin",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.BALLOT_ITEM_LIST, USER_PRIVILEGE.VIEW),
                "additionalPermission": [uniqueKey(PAGE_TYPE.BALLOT_ADMIN, USER_PRIVILEGE.UPDATE)],
                "searchParams": null
            },
            { // view-vote-history Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY_DETAILS, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'ballot-admin/view-vote-history-details/:letterBallot/:memberAccountNumber/:memberId/:voteStatus',
                "isVisibleOnNavigation": false,
                'title': "Ballot",
                "breadcrumbsTitle": "View Vote History Details",
                "breadcrumbsParentPath": "ballot-admin",
                "breadcrumbsParentTitle": "Ballot Admin / View Vote History",
                "oslUrl": "/ballotAdmin/*",
                "oslMethod": HTTP_METHOD.GET,
                "uniqueKey": uniqueKey(PAGE_TYPE.VIEW_VOTE_HISTORY_DETAILS, USER_PRIVILEGE.VIEW),
                "additionalPermission": [],
                "searchParams": null
            },
        ]
    },
    // Admin Management Module JSON
    {
        'ModuleName': "Admin",
        'ModuleKeys': MODULES[6],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "isVisibleOnNavigation": true,
        [MODULES[6]]: [
            { // User Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/user',
                "isVisibleOnNavigation": true,
                'title': 'User Permissions',
                "breadcrumbsTitle": "Users",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Details Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/user/detail/:userName',
                "isVisibleOnNavigation": false,
                'title': 'User Details',
                "breadcrumbsTitle": "User Details",
                "breadcrumbsParentPath": "admin/user",
                "breadcrumbsParentTitle": "User",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW), uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // User Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/exportFile/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.USER, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Role List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/roles',
                "isVisibleOnNavigation": true,
                'title': 'Roles',
                "breadcrumbsTitle": "Roles",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/roledetail/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Role Add Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/roledetail/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Role Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/roledetail/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ROLE, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [View] Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/membership-renewal',
                "isVisibleOnNavigation": true,
                'title': 'Membership Renewal',
                "breadcrumbsTitle": "Membership Renewal",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Start Renewal on Web] Permission
                "permissionType": USER_PRIVILEGE.START_RENEWAL_ON_WEB,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/startRenewalOnWeb/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.START_RENEWAL_ON_WEB),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Process Fee Drop] Permission
                "permissionType": USER_PRIVILEGE.PROCESS_FEE_DROP,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "memberShipRenewal/processFeeDrop/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.PROCESS_FEE_DROP),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Process Temporary Member Drop] Permission
                "permissionType": USER_PRIVILEGE.PROCESS_TEMPORARY_MEMBER_DROP,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/processTemporaryMemberDrop/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.PROCESS_TEMPORARY_MEMBER_DROP),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Renewal [Book Volume Information] Permission
                "permissionType": USER_PRIVILEGE.BOOK_VOLUME_INFORMATION,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberShipRenewal/bookVolumeInfo/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_RENEWAL, USER_PRIVILEGE.BOOK_VOLUME_INFORMATION),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Manual Order View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/manual-order',
                "isVisibleOnNavigation": true,
                'title': 'Manual Order',
                "breadcrumbsTitle": "Manual Order",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/addMember/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Manual Order Add Member Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/addMember/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.ADD_MEMBER, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View MEMBER EXCEPTION List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/membership-exception',
                "isVisibleOnNavigation": true,
                'title': 'Membership Exceptions',
                "breadcrumbsTitle": "Membership Exceptions",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membership-exception/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View MEMBER EXCEPTION Update Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': 'Membership Exceptions',
                "breadcrumbsTitle": "Membership Exceptions",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membership-exception/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBER_EXCEPTION, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View BOS VOLUME LOCATION List Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/bos-volume-location',
                "isVisibleOnNavigation": true,
                'title': 'BOS Volume Location',
                "breadcrumbsTitle": "BOS Volume Location",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Update BOS VOLUME LOCATION Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            },
            { // BOS VOLUME LOCATION Export Permission
                "permissionType": USER_PRIVILEGE.EXPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/export/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.BOS_VOLUME_LOCATION, USER_PRIVILEGE.EXPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // View Manage Alert Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'admin/manage-alerts',
                "isVisibleOnNavigation": true,
                'title': 'Manage Alerts',
                "breadcrumbsTitle": "Manage Alerts",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageAlerts/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Add Manage Alert Permission
                "permissionType": USER_PRIVILEGE.ADD,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageAlerts/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.ADD),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Update Manage Alert Permission
                "permissionType": USER_PRIVILEGE.UPDATE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.VIEW)],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageAlerts/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MANAGE_ALERTS, USER_PRIVILEGE.UPDATE),
                "oslMethod": HTTP_METHOD.PUT,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
    // Report Management Module JSON
    {
        'ModuleName': "Reports",
        'ModuleKeys': MODULES[7],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "#",
        "isVisibleOnNavigation": true,
        [MODULES[7]]: [
            { // Memberships Reports Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'membership-reports',
                "isVisibleOnNavigation": true,
                'title': 'Membership Reports',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // mcs status Reports Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIPS_BY_COUNT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/statusReport',
                "isVisibleOnNavigation": false,
                'title': 'MCS Status report',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/statusReport",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIPS_BY_COUNT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Membership Revenue by Type Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIP_REVENUE_BY_TYPE,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/revenueReport',
                "isVisibleOnNavigation": false,
                'title': 'Revenue report',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "reports/membership-reports/revenueReport/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIP_REVENUE_BY_TYPE),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // society member Report Permission
                "permissionType": USER_PRIVILEGE.SOCIETY_MEMBER_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/society-member-report',
                "isVisibleOnNavigation": false,
                'title': "All Society Member Report",
                "breadcrumbsTitle": "All Society Member Report",
                "breadcrumbsParentPath": "membership-reports",
                "breadcrumbsParentTitle": "Membership Reports",
                "oslUrl": "/reports/membership-reports/societyreport",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.SOCIETY_MEMBER_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // membership statistics report Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIP_STATISTICS_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/statisticsReport',
                "isVisibleOnNavigation": false,
                'title': 'Statistics Report',
                "breadcrumbsTitle": "Membership Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/statisticsReport",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIP_STATISTICS_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Memberships Reports Permission
                "permissionType": USER_PRIVILEGE.MEMBERSHIP_INVOICE_OR_RECEPT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/membership-invoice-receipt',
                "isVisibleOnNavigation": false,
                'title': 'Membership Invoice or Receipt',
                "breadcrumbsTitle": "Membership Invoice or Receipt",
                "breadcrumbsParentPath": "membership-reports",
                "breadcrumbsParentTitle": "Membership Reports",
                "oslUrl": "reports/membership-reports/membership-invoice-receipt/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.MEMBERSHIP_INVOICE_OR_RECEPT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Reports Change in Book Volume Permission
                "permissionType": USER_PRIVILEGE.CHANGE_IN_BOOK_VOLUME,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'membership-reports/change-book-volume',
                "isVisibleOnNavigation": false,
                'title': 'Change in Book Volume',
                "breadcrumbsTitle": "Change in Book Volume",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/membership-reports/change-book-volume/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEMBERSHIP_REPORT, USER_PRIVILEGE.CHANGE_IN_BOOK_VOLUME),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Member Exception Report Permission
                "permissionType": '',
                "componentElementOnPage": [],
                'routeUrl': '',
                "isVisibleOnNavigation": true,
                'title': 'Member Exception Report',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "uniqueKey": '',
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Report View Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'renewal-reports',
                "isVisibleOnNavigation": true,
                'title': 'Renewal Reports',
                "breadcrumbsTitle": "Renewal Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/renewal-reports/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Report UN_PAID_OFFIERS View Permission
                "permissionType": USER_PRIVILEGE.UN_PAID_OFFIERS,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'renewal-reports',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Reports',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/renewal-reports/unpaid-officer",
                "uniqueKey": uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.UN_PAID_OFFIERS),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Renewal Report Org/Non-Og Member Permission
                "permissionType": USER_PRIVILEGE.EMAIL_REMINDER_LIST,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'renewal-reports',
                "isVisibleOnNavigation": false,
                'title': 'Renewal Reports',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/email-reminder-list/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.RENEWAL_REPORT, USER_PRIVILEGE.EMAIL_REMINDER_LIST),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'meeting-reports',
                "isVisibleOnNavigation": true,
                'title': 'Meeting Reports',
                "breadcrumbsTitle": "Meeting Reports",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/reports/meeting-reports/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Block Schedule Permission
                "permissionType": USER_PRIVILEGE.BLOCK_SCHEDULE_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/block-schedule',
                "isVisibleOnNavigation": false,
                'title': 'Block Schedule',
                "breadcrumbsTitle": "Block Schedule",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/block-schedule-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.BLOCK_SCHEDULE_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Line Schedule Report Permission
                "permissionType": USER_PRIVILEGE.LINE_SCHEDULE_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/line-schedule',
                "isVisibleOnNavigation": false,
                'title': 'Line Schedule',
                "breadcrumbsTitle": "Line Schedule",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/line-schedule-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.LINE_SCHEDULE_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Registration Fees Report Permission
                "permissionType": USER_PRIVILEGE.REGISTRATION_FEES_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/registration-attendance-fees-report',
                "isVisibleOnNavigation": false,
                'title': 'Registration, Attendance & Fees Report',
                "breadcrumbsTitle": "Registration, Attendance & Fees Report",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/registration-fees-report/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.REGISTRATION_FEES_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Accounting Report Permission
                "permissionType": USER_PRIVILEGE.ACCOUNTING_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/accounting-report',
                "isVisibleOnNavigation": false,
                'title': 'Accounting Report',
                "breadcrumbsTitle": "Accounting Report",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/accounting-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.ACCOUNTING_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Meeting Reports -> Chair Reception List Report Permission
                "permissionType": USER_PRIVILEGE.CHAIR_RECEPTION_LIST_REPORT,
                "componentElementOnPage": [uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.VIEW)],
                'routeUrl': 'meeting-reports/chair-reception-list-report',
                "isVisibleOnNavigation": false,
                'title': 'Chair Reception List',
                "breadcrumbsTitle": "Chair Reception List",
                "breadcrumbsParentPath": "meeting-reports",
                "breadcrumbsParentTitle": "Meeting Reports",
                "oslUrl": "/reports/meeting-reports/chair-reception-list-report",
                "uniqueKey": uniqueKey(PAGE_TYPE.MEETING_REPORT, USER_PRIVILEGE.CHAIR_RECEPTION_LIST_REPORT),
                "oslMethod": HTTP_METHOD.GET,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    },
     // Document Management Module JSON
     {
        'ModuleName':"Documents",
        'ModuleKeys': MODULES[8],
        'iconClass': '',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': process.env.REACT_APP_DMS_URL,
        'isExternalUrl':true,
        "isVisibleOnNavigation": true,
        [MODULES[8]]: [
            { // Document Agendas Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'documents-agendas',
                "isVisibleOnNavigation": true,
                'title': 'Documents Agendas',
                "breadcrumbsTitle": "Documents Agenda",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/agendas/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DOCUMENTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Document Minutes Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'documents-minutes',
                "isVisibleOnNavigation": true,
                'title': 'Documents Minutes',
                "breadcrumbsTitle": "Documents Minutes",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/minutes/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DOCUMENTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Document CommitteeDocuments Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'documents-committeeDocuments',
                "isVisibleOnNavigation": true,
                'title': 'Documents Agenda',
                "breadcrumbsTitle": "Documents CommitteeDocuments",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/committeeDocuments/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DOCUMENTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Document VoteAttachment Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'documents-voteAttachment',
                "isVisibleOnNavigation": true,
                'title': 'Documents VoteAttachment',
                "breadcrumbsTitle": "Documents VoteAttachment",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/voteAttachment/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DOCUMENTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Document BallotDocuments Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'documents-ballotDocuments',
                "isVisibleOnNavigation": true,
                'title': 'Documents BallotDocuments',
                "breadcrumbsTitle": "Documents BallotDocuments",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/ballotDocuments/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DOCUMENTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            },
            { // Document ClosingReport Permission
                "permissionType": USER_PRIVILEGE.VIEW,
                "componentElementOnPage": [],
                'routeUrl': 'documents-closingReport',
                "isVisibleOnNavigation": true,
                'title': 'Documents ClosingReport',
                "breadcrumbsTitle": "Documents ClosingReport",
                "breadcrumbsParentPath": "/",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/closingReport/*",
                "uniqueKey": uniqueKey(PAGE_TYPE.DOCUMENTS, USER_PRIVILEGE.VIEW),
                "oslMethod": HTTP_METHOD.POST,
                "additionalPermission": [],
                "searchParams": null
            }
        ]
    }
];

export const DMSIANavigation = {
    MEMBER_MGMT: '/member-management/members',
    MEMBER_ORG: '/member-management/organizations', MEMBER_FEE_GROUP: '/member-management/fee-group',
    MEMBER_COMMITTEE: '/committee-management/committees', RENEWAL_TASK: '/renewal-tasks',
    WORK_ITEM_ADMIN: '/work-item-admin', BALLOT_ADMIN: '/ballot-admin', ADMIN_ROLE: '/admin/roles',
    ADMIN_MEMBER_RENEWAL: '/admin/membership-renewal', ADMIN_MANUAL_ORDER: '/admin/manual-order',
    ADMIN_MEMBER_EXEP: '/admin/membership-exception', ADMIN_BOS_VOLUME_LOCATION: '/admin/bos-volume-location',
    MEMBER_REPORT: '/membership-reports', RENEWAL_REPORT: '/renewal-reports', MEETING_REPORT: '/meeting-reports'
}
