import React, { Component } from "react";

const ReactExport = () => {
    return import('react-data-export')
}

class ExportExcel extends Component {

    state = {
        ExcelFile: null
    }

    componentWillMount() {
        ReactExport()
            .then(cmp => {
                this.setState({ ExcelFile: cmp.default.ExcelFile })
            })
    }

    render() {
        const { fileName, tabName, multiDataSet } = this.props;
        const { ExcelFile } = this.state;

        return ExcelFile && fileName ? (
            <ExcelFile hideElement={true} filename={fileName}>
                <ExcelFile.ExcelSheet dataSet={multiDataSet} name={tabName} />
            </ExcelFile>
        ) : null;
    }
}

export default ExportExcel;
