// @flow

const init = {
    isEdit: false,
    isUserList: true,
    userInfo: {
        UserId: 0,
        UserName: "",
        Email: "",
        FirstName: "",
        LastName: "",
        Phone: "",
        Status: true,
        RoleId: 3,
        UserPrivelege: [],
        LastLogin: '',
        RoleName: ''
    },
    roleData: [],
    isDuplicate: false,
    viewDetail: false

}


const reducerUser = (state = init, action) => {
    switch (action.type) {
        case 'USER_ROLE_LIST':
            return {
                ...state,
                isEdit: false,
                type: action.type,
                roledata: action.payload,
            }

        case 'USER_DEFAULT_PRIVILEGE':
            state.userInfo.UserPrivelege = action.payload.privilege;
            return {
                ...state,
                isEdit: false,
                type: action.type,
                roledata: action.payload.roleList,
                userInfo: state.userInfo,
            }
        case 'USER_PRIVILEGE_BY_ROLE':
            state.userInfo.UserPrivelege = action.payload
            return {
                ...state,
                isEdit: false,
                type: action.type,
                userInfo: state.userInfo
            }
        case 'USER_PRIVILEGE_BY_USER':
            state.userInfo.UserPrivelege = action.payload
            return {
                ...state,
                isEdit: false,
                type: action.type,
                userInfo: state.userInfo
            }
        case 'USER_DETAIL_WITH_PRIVILEGE':
            return {
                ...state,
                isEdit: false,
                type: action.type,
                privilegedata: action.payload
            }
        case 'USER_VALIDATE_IN_AD':
            return {
                ...state,
                type: action.type

            }
        case 'USER_ADD':
            let adduserInfo = state.userInfo;
            adduserInfo.UserPrivelege = [];
            return {
                ...state,
                isEdit: false,
                userInfo: adduserInfo,
                isUserList: false,
                type: action.type,
                isDuplicate: false

            }
        case 'USER_CHANGE_PRIVILEGE':
            let _userInfo = state.userInfo

            return {
                ...state,
                isEdit: false,
                userInfo: _userInfo,
                isUserList: false,
                type: action.type

            }
        case 'USER_ISDUPLICATE':
            let tmp_userInfo = state.userInfo
            tmp_userInfo.UserId = action.payload.userId
            return {
                ...state,
                isEdit: false,
                isUserList: false,
                type: action.type,
                isDuplicate: action.payload.isDuplicate,
                userInfo: tmp_userInfo
            }
        case 'USER_VIEW':
            let userInfoView = state.userInfo;
            userInfoView = action.payload.UserDetail;
            userInfoView.UserPrivelege = action.payload.UserPrivilege;
            return {
                ...state,
                isEdit: true,
                userInfo: userInfoView,
                isUserList: false,
                type: action.type
            }
        case 'USER_EDIT_SUBMITED':
            return {
                ...state,
                isEdit: false,
                userInfo: userInfoView,
                isUserList: true,
                type: action.type
            }
        case 'USER_LIST':
            return {
                ...state,
                isEdit: false,
                isUserList: true,
                viewDetail: false,
                type: action.type,
                roledata: action.payload
            }

        default:
            return state;
    }
};

export default reducerUser;
