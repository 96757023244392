import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _action from '../../action-types';
import { RequestTypes } from '../../models/common.models';
import * as _taskDetails from '../../modules/renewal-task/actions/renewal-task-details.actions';
import MultipleErrorsList from '../MultipleErrorsList';
import * as _func from './function';


class CustomConfirmPopup extends Component {

    handleConfirm = () => {
        _func.handleConfirm(this);
    }

    render() {

        const { content, handleDismiss, currentRequest, codes } = this.props;

        return (
            <div className="custom-confirm-overlay">

                <div className="CustomConfirmPopup">
                    <div className="content">
                        <MultipleErrorsList content={content} />
                    </div>
                    {currentRequest === RequestTypes.UpdateTaskDetails && (
                        <div>
                            {_.get(codes, '9004.text')}
                        </div>
                    )}
                    {currentRequest === RequestTypes.BreakDependency && (
                        <div>
                            {_.get(codes, '9005.text')}
                        </div>
                    )}
                    <div className="actions">
                        <button className="ui button" onClick={this.handleConfirm}>{currentRequest === RequestTypes.UpdateTaskAssigneeStatus ? 'Break Dependency' : 'Yes'}</button>
                        <button className="ui button ml10" onClick={handleDismiss}>{currentRequest === RequestTypes.UpdateTaskAssigneeStatus ? 'Cancel' : 'No'}</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    content: state.commonReducer.confirmationMessage,
    currentRequestData: state.commonReducer.currentRequestData,
    currentRequest: state.commonReducer.currentRequest,
    codes: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]
});

const mapDispatchToProps = (dispatch) => ({
    handleDismiss: () => dispatch({ type: _action.CLOSE_CONFIRMATION_POPUP }),
    updateTaskAssgineeStatus: (data) => {
        _taskDetails.updateTaskAssgineeStatus(data, dispatch);
    },
    updateTaskDetails: (data) => {
        _taskDetails.updateTaskDetails(data, dispatch);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomConfirmPopup);

