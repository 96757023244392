import * as common from 'helpers/util-common';
import _ from "lodash";
import { minDateForInputDateFields } from 'models/common.models';
import React, { Component } from "react";
import { Checkbox, Confirm, Form, Icon } from 'semantic-ui-react';
import DateTimePicker from 'shared-components/DatePicker';
import RenderSelect from 'shared-components/Select';
import TableHeader from 'shared-components/Table/tableHeader';
import CustomTooltip from 'shared-components/Tooltip';
import UpdateReasonModel from '../../../../committee-management/components/update-reason-modal';
import { MemberCommitteeTableColumnOnBulkEdit } from '../../members.model';
import * as _memberCommitteeInformationFunction from '../member-committee-information/function';
import * as _func from './function';


class MemberCommitteeInfoTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxDate: common.formatDateToMMDDYYYY(),
            voteDate: '',
            classificationDate: '',
            validatevoteDate: true,
            validateCommitteeJoinDate: true,
            committeeJoinDate: common.formatDateToMMDDYYYY(),
            isUpdatePopUp: false,
            isRowEditable: _func.isGridEditable,
            edit: false,
            classificationDropdownSelectedValue: '',
            classificationDropdown: [],
            voteDropdownSelectedValue: null,
            voteDropdown: [
                { key: 'y', value: true, text: 'Yes' },
                { key: 'n', value: false, text: 'No' }
            ],
            noVoteReasonDropdownSelectedValue: '',
            noVoteReasonDropdown: [],
            updateMainCommitteeToProducer: null,
            isAllSelected: 0,
            tableHeader: this.props.memberCommitteeTableColumnInfo,
            showUpdateReason: false,
            isValueChanged: false
        }
    }

    componentDidMount() {
        _func.setClassInstance(this)
    }
    checkInactiveCommittee = (item) => {
        let committeeInactive = ''
        if (item.CommitteeStatus === "I") {
            committeeInactive = ((item.InactiveReason || '').toLowerCase() === "merged") ? (`${'Merged with ' + (item.MergedCommittee || 'Merged')} on` + common.formatDateToMMYYYY(item.InactiveDate || new Date())) :
                ((item.InactiveReason || '').toLowerCase() === "discharged") ? (`${'Discharged on ' + common.formatDateToMMYYYY(item.InactiveDate || new Date())}`) : '';

            committeeInactive = committeeInactive !== '' ? item.CommitteeTitle + ` (${committeeInactive})` : item.CommitteeTitle;

        } else {
            committeeInactive = item.CommitteeTitle
        }
        return committeeInactive.length < 150 ? committeeInactive : <CustomTooltip icon={<span className="threeLiner" style={{ '-webkit-box-orient': 'vertical' }}>{committeeInactive}</span>} content={committeeInactive} />
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            classificationDropdown: nextProps.commonCommitteeClassificationTypeList,
            noVoteReasonDropdown: nextProps.noVoteReasonList
        })
        if (this.props.showAddcommitteeMember) {
            this.setState({ isRowEditable: _func.isGridEditable })
        }
    }

    render() {
        const { maxDate, voteDate, committeeJoinDate, classificationDropdown, voteDropdown, classificationDropdownSelectedValue, voteDropdownSelectedValue, noVoteReasonDropdownSelectedValue, noVoteReasonDropdown, isRowEditable, isUpdatePopUp, validatevoteDate, validateCommitteeJoinDate, tableHeader, classificationDate, showUpdateReason } = this.state
        const { messageCodes, isBulkEdit, OnSelectMemerCommittee, memberCommitteeInfo, selectAll, hasEditMemberParticipationOnCommittees } = this.props
        const checkboxstatus = (memberCommitteeInfo && memberCommitteeInfo.length > 0 && memberCommitteeInfo.filter(item => item.isChecked).length > 0 && memberCommitteeInfo.filter(item => item.isChecked).length < memberCommitteeInfo.length) ? 'partial' : ''

        return (
            <Form>
                <table className="customTable memComInfoTable" data-testid="memberCommitteeInfoTable">
                    <thead>
                        {<tr>
                            {isBulkEdit && <React.Fragment><th width="40px" ><Checkbox label={''} className={checkboxstatus + ' vMiddle'} onClick={(e) => selectAll(e)} checked={(memberCommitteeInfo && memberCommitteeInfo.length > 0 && memberCommitteeInfo.filter(item => item.isChecked).length === memberCommitteeInfo.length)}

                            /></th>
                                <TableHeader headerProps={MemberCommitteeTableColumnOnBulkEdit} onClickProps={_memberCommitteeInformationFunction.sortBy} hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees}></TableHeader>

                            </React.Fragment>
                            }
                            {!isBulkEdit && <TableHeader headerProps={tableHeader} onClickProps={_memberCommitteeInformationFunction.sortBy} hasEditMemberParticipationOnCommittees={hasEditMemberParticipationOnCommittees}></TableHeader>}
                        </tr>}
                    </thead>

                    <tbody>
                        {memberCommitteeInfo && memberCommitteeInfo.length > 0 &&
                            (
                                memberCommitteeInfo.map((item, index) => {
                                    return (
                                        <tr>
                                            {isBulkEdit && <td><Checkbox label={' '} className="vMiddle" checked={item.isChecked} onClick={(e) => OnSelectMemerCommittee(e, index)} /></td>}
                                            {_memberCommitteeInformationFunction.getIconClass(item)}
                                            <td >
                                                {this.checkInactiveCommittee(item)}

                                            </td>
                                            <td>
                                                {_func.setCellState(`classificationDropdown_${index}`)}
                                                {isRowEditable[`classificationDropdown_${index}`] ?
                                                    <RenderSelect
                                                        name={'classificationDropdown'}
                                                        value={classificationDropdownSelectedValue}
                                                        onChange={_func.handleChangeClassification}
                                                        options={classificationDropdown}
                                                    /> :
                                                    item.Classification
                                                }
                                            </td>
                                            <td>
                                                {_func.setCellState(`classificationDate_${index}`)}
                                                {isRowEditable[`classificationDate_${index}`] ?
                                                    <DateTimePicker
                                                        name={'classificationDate'}
                                                        label=""
                                                        showTime={false}
                                                        defaultValue={classificationDate}
                                                        initialDate={classificationDate}
                                                        maxDate={maxDate}
                                                        minDate={committeeJoinDate}
                                                        onChange={_func.handleChangeClassificationDate}
                                                        readOnly={false}
                                                    />
                                                    :
                                                    item.ClassificationDate === null ? "" : common.formatDateToMMDDYYYY(item.ClassificationDate)
                                                }
                                            </td>
                                            <td>
                                                {_func.setCellState(`voteDropdown_${index}`)}
                                                {isRowEditable[`voteDropdown_${index}`] ?
                                                    <RenderSelect
                                                        name={'voteDropdown'}
                                                        value={voteDropdownSelectedValue}
                                                        onChange={_func.handleChangeVote}
                                                        options={voteDropdown}
                                                    /> :
                                                    _memberCommitteeInformationFunction.getVote(item.Vote)
                                                }
                                            </td>
                                            <td>
                                                {_func.setCellState(`noVoteReasonDropdown_${index}`)}
                                                {isRowEditable[`noVoteReasonDropdown_${index}`] ?
                                                    <RenderSelect
                                                        name={'noVoteReasonDropdown'}
                                                        value={noVoteReasonDropdownSelectedValue}
                                                        onChange={_func.handleChangeNoVoteReason}
                                                        options={noVoteReasonDropdown}
                                                        disabled={voteDropdownSelectedValue}
                                                    /> :
                                                    item.NoVoteReason
                                                }
                                            </td>
                                            <td>
                                                {_func.setCellState(`voteDate_${index}`)}
                                                {isRowEditable[`voteDate_${index}`] ?
                                                    <DateTimePicker
                                                        name={'voteDate'}
                                                        label=""
                                                        showTime={false}
                                                        defaultValue={voteDate}
                                                        initialDate={voteDate}
                                                        maxDate={maxDate}
                                                        minDate={committeeJoinDate}
                                                        onChange={_func.handleChangeVoteDate}
                                                        disabled={false}
                                                        readOnly={false}
                                                        className={validatevoteDate ? '' : 'field error mini'}
                                                    />
                                                    :
                                                    item.VoteDate === null ? "" : common.formatDateToMMDDYYYY(item.VoteDate)
                                                }
                                            </td>
                                            <td>
                                                {_func.setCellState(`committeeJoinDate_${index}`)}
                                                {isRowEditable[`committeeJoinDate_${index}`] ?
                                                    <DateTimePicker
                                                        name={'committeeJoinDate'}
                                                        label=""
                                                        showTime={false}
                                                        defaultValue={committeeJoinDate}
                                                        initialDate={committeeJoinDate}
                                                        maxDate={maxDate}
                                                        minDate={_(item).get('EstablishmentDate') ? common.formatDateToMMDDYYYY(item.EstablishmentDate) : common.formatDateToMMDDYYYY(minDateForInputDateFields)}
                                                        onChange={_func.handleChangeCommitteeJoinDate}
                                                        readOnly={false}
                                                        className={validateCommitteeJoinDate ? '' : 'field error mini'}
                                                    />
                                                    :
                                                    item.CommitteeJoinDate === null ? "" : common.formatDateToMMDDYYYY(item.CommitteeJoinDate)
                                                }
                                            </td>
                                            {!isBulkEdit && hasEditMemberParticipationOnCommittees && <td>
                                                {_func.setCellState(`action_${index}`)}
                                                {isRowEditable[`action_${index}`] ?
                                                    <div name="action">
                                                        <a className="updateBtn mr10" title="Update" onClick={(event) => { _func.handleUpdate(event, index, item) }}><Icon name="check" /></a>
                                                        <a className="closeBtn" title="Close" onClick={(event) => { _func.handleCancel(event, index) }}><Icon name="close" /></a>
                                                    </div> :

                                                    (item.MemberStatus.toLowerCase() == 'a' && item.CommitteeStatus.toLowerCase() == 'a') ?
                                                        <a onClick={(event) => { _func.handleEdit(event, index, item) }} className="editBtn" title="Edit"><Icon name="pencil" /></a>
                                                        : ''
                                                }
                                            </td>}
                                        </tr>
                                    )
                                }
                                )
                            )
                        }
                    </tbody>
                </table>
                {memberCommitteeInfo && memberCommitteeInfo.length === 0 &&
                    <div className="noRecordMessage">{common.getMessage(messageCodes, '9010.text')}</div>
                }
                {showUpdateReason && <UpdateReasonModel {...this.props} onSubmitCall={_func.onUpdateRecored} onClose={_func.popUpCancel} />}

                <Confirm className="confirm-box"
                    open={isUpdatePopUp}
                    content={common.getMessage(messageCodes, '9044.text')}
                    onCancel={_func.popUpCancel}
                    onConfirm={_func.popUpConfirm}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </Form>
        );
    }
}
export default MemberCommitteeInfoTable
