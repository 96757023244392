import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import * as type from 'action-types';

// For getting fee group list
const getFeeGroupListAction = (callback, dispatch) => {

    let url = getApiUrl('feeGroup', 'getFeeGrouplist');

    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: type.GET_FEEGROUP_LIST, payload: response.data.content || [] })
                callback(response.data.content || []);
                dispatch(commonActions.setLoader(false));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback([]);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8144'));
            callback([]);
        });
};

// For adding fee group
const postFeeGroupAction = (dispatch, req, callback) => {

    let url = getApiUrl('feeGroup', 'addFeeGroup');

    dispatch(commonActions.setLoader(true));

    axios.post(url, req)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2023'));
                callback(true);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback(false);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8143'));
            callback();
        });
};

// For update fee group
const putFeeGroupAction = (dispatch, req, callback) => {

    let url = getApiUrl('feeGroup', 'updateFeeGroup');
    dispatch(commonActions.setLoader(true));
    axios.put(url, req)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback(true);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback(false);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8143'));
            callback();
        });
};
const getFeeGroupTitleExistAction = (req, dispatch, callback) => {
    let url = getApiUrl('feeGroup', 'checkDuplicateFeeGroupTitle') + `/${req.feeGroupId}/${req.feeGroupTitle}`;
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: type.FEEGROUP_ISDUPLICATE, payload: { isDuplicate: response.data.content || false } })
                callback();
            } else {
                // Handle error case
                callback(true);
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));

        });

}

export default {
    getFeeGroupListAction,
    getFeeGroupTitleExistAction,
    postFeeGroupAction,
    putFeeGroupAction
}
