import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

const RenderSelect = (props) => {
  // For redux form implementation
  if ('input' in props) {
    const { input, options, placeholder, label, hintLabel, required, isDuplicate, duplicateError, meta: { touched, error }, disabled } = props;

    return (
      <div data-testid="txtSelect">
        <label className="label" htmlFor={input.name}>
          {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
          {label}
          {hintLabel ? hintLabel : null}
        </label>
        <Form.Select data-testid={input.name}
          name={input.name}
          id={input.name}
          onChange={(e, { value }) => input.onChange(value)}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          value={input.value}
          className={touched && error ? "error" : ''}
        />
        {touched && (error && <span className="errorMessage">{error}</span>)}
        {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
      </div >
    )
    // Without redux form implementation
  }

  const {name, options, placeholder, onChange, value, disabled } = props
  return (
    <Form.Select
      name={name} data-testid={name}
      options={options}
      onChange={(e, { value }) => onChange(e, value)}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
    />
  )
}

RenderSelect.propTypes = {
  options: PropTypes.array
}

RenderSelect.defaultProps = {
  options: []
}

export default RenderSelect;
