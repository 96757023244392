
import React, { Component } from "react";
import { routeNavigationUrl, encrypt, getMessage } from 'helpers/util-common';
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import TableHeader from 'shared-components/Table/tableHeader';
import { FeeGroupDetailsActiveTabIndex } from '../../feegroups.model';
import { ItemsPerPage } from 'models/common.models';
import { Link } from "react-router-dom";
import { get } from 'lodash';

class FeeGroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalItems: get(this.props, 'totalItems') ? this.props.totalItems : 0,
            activePageNumber: get(this.props, 'activePageNumber') ? this.props.activePageNumber : 1,
            totalPages: get(this.props, 'totalPages') ? this.props.totalPages : 0,
            currentlyShowingItems: get(this.props, 'currentlyShowingItems') ? this.props.currentlyShowingItems : 0,
            pageSize: get(this.props, 'pageSize') ? this.props.pageSize : 25,
            feeGroupList: get(this.props, 'pageSize') ? this.props.feeGroupList : [],
            feeGroupAllList: get(this.props, 'feeGroupAllList') ? this.props.feeGroupAllList : []
        }
    }
    // activePageNumber totalPages currentlyShowingItems pageSize pageSize feeGroupAllList
   

    componentWillReceiveProps(nextProps) {
        this.setState({
            feeGroupList: nextProps.feeGroupList,
            feeGroupAllList: nextProps.feeGroupAllList,
            totalItems: nextProps.totalItems,
            activePageNumber: nextProps.activePageNumber,
            totalPages: nextProps.totalPages,
            currentlyShowingItems: nextProps.currentlyShowingItems,
            pageSize: nextProps.pageSize
        });
    }

    render() {
        const { feeGroupList, totalItems, activePageNumber, totalPages, currentlyShowingItems, pageSize } = this.state
        const { onPageChangeHandler, onChangeNumberOfItemsPerPage, handleSorting, PAGE_TYPE, USER_PRIVILEGE, feeGroupTableHeaders, isAdvanceSearch, noRecordTextMessage } = this.props;
        let begin = (((activePageNumber - 1) * pageSize));
        let end = begin + pageSize;
        return (
            <div>
                <div className="tableWrapper" data-testid="tableWrapper">
                    <table className="customTable">
                        <thead>
                            <tr>
                                <TableHeader headerProps={feeGroupTableHeaders} onClickProps={handleSorting}></TableHeader>
                            </tr>
                        </thead>

                        <tbody>
                            {feeGroupList && feeGroupList.length > 0 && feeGroupList.slice(begin, end).map((feeGroupListItem, index) => {
                                return (
                                    <tr>
                                        <td className={`comDesign ${feeGroupListItem.Status.toLocaleLowerCase() === 'inactive' && 'leftRedBorder'}`}>
                                            <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW, [encrypt(FeeGroupDetailsActiveTabIndex.DETAILS), encrypt(feeGroupListItem.FeeGroupId)])}`}>{feeGroupListItem.FeeGroupNumber.toUpperCase()}</Link>
                                        </td>
                                        <td>{feeGroupListItem.FeeGroupTitle}</td>
                                        <td>{feeGroupListItem.ContactPersonName}</td>
                                        <td>
                                            <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW, [encrypt(FeeGroupDetailsActiveTabIndex.ASSOCIATEDMEMBERS), encrypt(feeGroupListItem.FeeGroupId)])}`}>{feeGroupListItem.AssociatedMembers}</Link>
                                        </td>
                                        <td>{feeGroupListItem.PaidStatus}</td>
                                        <td>{feeGroupListItem.Status}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {(feeGroupList && feeGroupList.length <= 0) && (
                    <div className="noRecordMessage">
                        {noRecordTextMessage}
                    </div>
                )}
                {totalItems > 0 && (
                    <div className="pagerWrap" data-testid="pagerWrap">
                        <div className="pager">
                            <PaginationShorthand
                                defaultActivePage={activePageNumber}
                                totalPages={totalPages}
                                onPageChange={onPageChangeHandler}
                            />
                        </div>

                        <div className="itemPerPage" data-testid="itemPerPage">
                            <RenderSelect
                                name="itemsPerPage"
                                value={pageSize}
                                onChange={onChangeNumberOfItemsPerPage}
                                options={ItemsPerPage}
                            />
                            <span className="itemsPerPage">items per page</span>
                        </div>

                        <div className="totalPage">{currentlyShowingItems}</div>
                    </div>
                )}
            </div>
        )
    }
}
export default FeeGroupList;
