import React from 'react';

class tableHeader extends React.Component {
    constructor(props) {
        super(props);
        const self = this;
        self.state = {
            headerList: self.props.headerProps
        }
        self.onHeaderClick = self.onHeaderClick.bind(self)

    }

    onHeaderClick = (event, index) => {
        let self = this;
        let header = self.state.headerList;

        if (header[index].hasSorting) {
            header.map((item, _index) => {
                if (_index !== index) {
                    item.orderByIcon = 0;
                    item.orderBy = 0;
                }
                return true;
            });
            header[index].orderByIcon = (header[index].orderByIcon === 0 ? 1 : header[index].orderByIcon === 1 ? 2 : 1);
            header[index].orderBy = header[index].orderBy === 0 ? 1 : 0;
            self.setState({ headerList: header })
            this.props.onClickProps(event, header[index].sortKey)
        }
    }

    render() {
        const { headerList } = this.state;
        const { hasEditMemberParticipationOnCommittees } = this.props;

        return (
            headerList.map((item, index) => {
                if (item.width) {
                    return <React.Fragment key={index}>
                        {(item.title !== 'Action' || (item.title === 'Action' && hasEditMemberParticipationOnCommittees)) && <th width={item.width} className={item.hasSorting ? 'pointer ' + item.className : item.className} onClick={e => this.onHeaderClick(e, index)} data-order={item.orderBy}>
                            {item.title}

                            {item.hasSorting && (
                                <i aria-hidden="true" className={item.orderByIcon === 0 ? 'sort icon' : item.orderByIcon === 1 ? 'long arrow alternate up icon activeSort' : 'long arrow alternate down icon activeSort'} ></i>
                            )}
                        </th>}
                    </React.Fragment>
                }
                return <React.Fragment key={index}>
                    {(item.title !== 'Action' || (item.title === 'Action' && hasEditMemberParticipationOnCommittees)) && <th className={item.hasSorting ? 'pointer ' + item.className : item.className} onClick={e => this.onHeaderClick(e, index)} data-order={item.orderBy}>
                        {item.title}

                        {item.hasSorting && (
                            <i aria-hidden="true" className={item.orderByIcon === 0 ? 'sort icon' : item.orderByIcon === 1 ? 'long arrow alternate up icon activeSort' : 'long arrow alternate down icon activeSort'} ></i>
                        )}
                    </th>}
                </React.Fragment>
            })
        );
    }

}
export default tableHeader;
