import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';
import { routeNavigationUrl } from '../../../../../helpers/util-common';
import { ActiveMemberStatusId, MembershipTypes } from '../../members.model';
import StudentInfoModal from '../student-info-modal';

class MembershipTypeInfo extends Component {

    state = {
        showStudentInfoModal: false
    }

    openStudentInfoModal = () => {
        this.setState({
            showStudentInfoModal: true
        })
    }

    closeStudentInfoModal = () => {
        this.setState({
            showStudentInfoModal: false
        })
    }

    render() {
        const { initialValues, membershipTypeList, onEditMembershipTypeInfo, userAccess: { hasUpdateAccess }, PAGE_TYPE, USER_PRIVILEGE } = this.props;
        const { showStudentInfoModal } = this.state;

        let membershipType = '';
        if (membershipTypeList && membershipTypeList.length > 0) {
            let index = membershipTypeList.findIndex(item => item.key === initialValues.MemberTypeId);
            if (index !== -1) {
                membershipType = membershipTypeList[index].text;
            }
        }
        return (
            <div data-testid="membershipTypeInfo">
                <h5>Membership Type
                    {initialValues.McsStatusMasterId === ActiveMemberStatusId && hasUpdateAccess && (
                        <a title="Edit" className="editBtn" onClick={onEditMembershipTypeInfo}><Icon name="pencil" /></a>
                    )}
                </h5>
                <Grid divided='vertically' columns={4}>
                    {initialValues.MemberTypeId !== MembershipTypes.Student && initialValues.IsStudent && (
                        <Grid.Row>
                            <Grid.Column>
                                <span className="pillButton">Upgraded from Student</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="linkTxt" onClick={this.openStudentInfoModal}>View Student Information</span>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
                <Grid divided='vertically' columns={2}>
                    <Grid.Row>
                        <Grid.Column style={{ width: '25%' }}>
                            <span className="labelTitle">Member Type</span>
                            <span className="labelValue">
                                {membershipType}
                            </span>
                        </Grid.Column>
                        {initialValues.MemberTypeId === MembershipTypes.MouMember && (
                            <Grid.Column>
                                <span className="labelTitle">MOU Contact Code</span>
                                <span className="labelValue">
                                    {initialValues.MOUContactCode}
                                </span>
                            </Grid.Column>
                        )}
                        {(initialValues.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) && (
                            <Grid.Column>
                                <span className="labelTitle">Organization</span>
                                <span className="labelValue">
                                    {initialValues.OrgName ?
                                        <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW, [initialValues.OrgAccountNumber])}`}>
                                            {initialValues.OrgName ?? ''} {initialValues.OrgAccountNumber ?? ''}
                                        </Link>
                                        :
                                        `${initialValues?.FacilityName ?? ''} ${initialValues.FacilityAccountNumber ?? ''}`
                                    }
                                </span>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
                {
                    showStudentInfoModal && (
                        <StudentInfoModal data={initialValues} closeModal={this.closeStudentInfoModal} />
                    )
                }
            </div >
        )
    }
}

export default MembershipTypeInfo;
