import React, { Component } from 'react';
import { Icon, Grid } from 'semantic-ui-react';
import CustomLoader from 'shared-components/Loader';
import { formatDateToMMDDYYYY } from 'helpers/util-common';

class MemberCommitteeGeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMemberCommitteeStatusActive: !(this.props.memberCommitteeDetails.TopSection && this.props.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive'),
            checkCommitteeStatus: this.props.committeeStatus.toLowerCase() === 'a',
            checkMemberStatus: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const isMemberCommitteeStatusActive = !(nextProps.memberCommitteeDetails.TopSection && nextProps.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive');
        const checkCommitteeStatus = nextProps.committeeStatus.toLowerCase() === 'a';
        const checkMemberStatus = nextProps.memberStatus.toLowerCase() === 'a';

        this.setState({
            isMemberCommitteeStatusActive: isMemberCommitteeStatusActive,
            checkCommitteeStatus: checkCommitteeStatus,
            checkMemberStatus: checkMemberStatus
        })
    }

    render() {
        const { isMemberCommitteeStatusActive, checkCommitteeStatus, checkMemberStatus } = this.state
        const { memberCommitteeDetails: { GeneralInformation, JoinAsAffiliateDate, VoteDate, TopSection }, loading, showEditForm, MemberTypeId, hasEditMemberParticipationOnCommittees } = this.props;

        return (
            <section className="subCommGenInfo">
                {GeneralInformation && (
                    <div>
                        <h5>General Information
                            {isMemberCommitteeStatusActive && checkCommitteeStatus && hasEditMemberParticipationOnCommittees ?
                                <a title="Edit" className="editBtn" data-testid="editButton" onClick={showEditForm}><Icon name="pencil" /></a> : ''
                            }
                        </h5>
                        <Grid divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="labelTitle">Classification</span>
                                    <span className="labelValue">{GeneralInformation.Classification}</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <span className="labelTitle">Classification Assigned Date</span>
                                    <span className="labelValue">{
                                        (GeneralInformation.ClassificationDate && GeneralInformation.ClassificationDate !== '') ?
                                            formatDateToMMDDYYYY(GeneralInformation.ClassificationDate) : GeneralInformation.ClassificationDate}
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="labelTitle">Official Vote</span>
                                    <span className="labelValue">{GeneralInformation.IsOfficialVote ? 'Yes' : "No"}</span>
                                </Grid.Column>

                                {GeneralInformation.IsOfficialVote ?
                                    <Grid.Column className='hide'>
                                        <span className="labelTitle">Vote Date</span>
                                        <span className="labelValue">{VoteDate}</span>
                                    </Grid.Column> :

                                    <Grid.Column>
                                        <span className="labelTitle">No Vote Reason</span>
                                        <span className="labelValue">{GeneralInformation.NoVoteReason}</span>
                                    </Grid.Column>
                                }
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="labelTitle">Vote Assigned Date</span>
                                    <span className="labelValue">{
                                        (GeneralInformation.VoteDate && GeneralInformation.VoteDate !== '') ?
                                            formatDateToMMDDYYYY(GeneralInformation.VoteDate) : GeneralInformation.VoteDate}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid divided='vertically'>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="labelTitle">Main Committee Roster Notes</span>
                                    <span className="labelValue">{GeneralInformation.RosterNotes}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {(MemberTypeId === 4) ? // If the membership type of the member is AFFILIATE
                            <Grid divided='vertically' columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelTitle">Joined as Affiliate Date</span>
                                        <span className="labelValue">{JoinAsAffiliateDate && JoinAsAffiliateDate != null ? JoinAsAffiliateDate: formatDateToMMDDYYYY(TopSection.CommitteeJoinDate)}</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid> : ''
                        }
                    </div>)}
            </section>
        )
    }
}
export default MemberCommitteeGeneralInfo;
