import React from 'react'
import { Icon, Pagination } from 'semantic-ui-react'

const PaginationShorthand = (props) => (

  <Pagination
    defaultActivePage={props.defaultActivePage}
    activePage={props.defaultActivePage}
    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
    firstItem={{ content: <Icon name='step backward left' disabled={props.defaultActivePage === 1} />, icon: true }}
    lastItem={{ content: <Icon name='step forward  right' disabled={props.defaultActivePage === props.totalPages} />, icon: true }}
    prevItem={{ content: <Icon name='caret left' disabled={props.defaultActivePage === 1} />, icon: true }}
    nextItem={{ content: <Icon name='caret right' disabled={props.defaultActivePage === props.totalPages} />, icon: true }}
    totalPages={props.totalPages}
    onPageChange={props.onPageChange}
  />
)
export default PaginationShorthand
