import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { getHighlightedHTMLElement } from 'helpers/util-common';

let searchValue = '';

export default class CustomGroupRendererSearch extends Component {

    categoryRenderer = ({ name }) => {
        return <div>{name}</div>
    }

    resultRenderer = (value) => {
        let highlightedMemberAccountNumber = getHighlightedHTMLElement(value.MemberAccountNumber, searchValue);
        let highlightedMemberName = getHighlightedHTMLElement(value.MemberName, searchValue);
        let renderElement = '';

        if (value && value.OrgName && value.OrgName.length > 0) {
            renderElement = `<p>${highlightedMemberAccountNumber} &bull; ${highlightedMemberName} &bull; <span className="organizationName">${value.OrgName} (Associated Org)</span></p>`;
        } else {
            renderElement = `<p>${highlightedMemberAccountNumber} &bull; ${highlightedMemberName}</p>`;
        }

        return <div dangerouslySetInnerHTML={{ __html: renderElement }}></div>
    }

    handleResultSelect = (e, { result }) => {
        this.props.onResultSelect(result);
    }

    handleSearchChange = (e) => {
        searchValue = e.target.value.trim();
        this.props.input.onChange(e.target.value);
    }

    render() {
        const { label, input: { name, value }, placeholder, className, isLoading, results, showNoResults, meta: { touched, error } } = this.props;

        return (
            <div data-testid="assocMemSearch">
                <label className="label" htmlFor={name}>{label}</label>
                <Search
                    name={name}
                    id={name}
                    value={value}
                    placeholder={placeholder}
                    category
                    categoryRenderer={this.categoryRenderer}
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                    resultRenderer={this.resultRenderer}
                    results={results}
                    showNoResults={showNoResults}
                    className={className}
                />

                {touched && (error && <span className="errorMessage mt10">{error}</span>)}
            </div>
        )
    }
}