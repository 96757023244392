import * as _actions from 'action-types';
import * as commonActions from 'common.actions';
import _ from 'lodash';
import { connect } from "react-redux";
import memberActions from '../actions/members.actions';
import MemberList from '../index';

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        currentSearchType: state.commonReducer.currentSearchType,
        membershipTypeList: state.memberReducer.membershipTypeList,
        userPermission: state.commonReducer.userPermission,
        currentModuleTitle: state.commonReducer.currentModuleTitle,
        countryList: state.commonReducer.countryList,
        activeCountryList: state.commonReducer.activeCountryList,
        originalActiveCountryList: state.commonReducer.originalActiveCountryList,
        memberGridPreferences: state.commonReducer.memberGridPreferences,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getInitialMemberListData: (query, callback, isLoader = false) => { memberActions.getInitialMemberListDataAction(query, callback, dispatch, isLoader) },
    getMemberList: (query, callback, isLoader = false) => { memberActions.getMemberListAction(query, callback, dispatch, isLoader) },
    clearSearchType: () => { dispatch({ type: _actions.CLEAR_SEARCH_TYPE }); },
    getMemberExportedData: (callback, url, postData, isWord) => memberActions.getMemberExportedDataAction(dispatch, callback, url, postData, isWord),
    updateGridPreference: (gridId, data, callback) => {
        commonActions.updateGridPreferenceAction(gridId, data, callback, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
