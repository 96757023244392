import Parser from 'html-react-parser';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const MultipleErrorsList = ({ content, codes }) => {
    let errorMessages = [];

    if (content) {
        for (let i = 0; i < content.length; i++) {
            let data = content[i];

            if (data.IsShowFromBackend) {
                errorMessages.push(data.Message);
            } else {
                errorMessages.push(_.get(codes, `${data.MessageCode}.text`));
            }
        }
    }

    return errorMessages && errorMessages.length > 1 ? (
        <ul>
            {errorMessages.map((message) =>
                <li>
                    <div className="truncatedText">{Parser(String(message))}</div>
                </li>
            )}
        </ul>
    ) : errorMessages.map((message) =>
        <div className="truncatedText">{Parser(String(message))}</div>
    )
}

const mapStateToProps = state => ({
    codes: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]
});

export default connect(mapStateToProps)(MultipleErrorsList);
