import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

const StandardBreadcrumb = (props) => (
    <Breadcrumb>
        <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
        {props.parent && props.parent.length > 0 && (
            <span>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link>
                    <Link to={`/${props.parentPath}`}>{props.parent}</Link>
                </Breadcrumb.Section>
            </span>
        )}
        {props.currentPage && props.currentPage.length > 0 && (
            <span>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>{props.currentPage}</Breadcrumb.Section>
            </span>
        )}
    </Breadcrumb>
)

export default StandardBreadcrumb;
