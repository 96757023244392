import lodash from 'lodash';
import React from "react";
import { Image } from 'semantic-ui-react';
import logoImg from "../../assets/images/astm-internal.png";
import MenuDropdown from "../MenuDropdown";
import SearchBox from "../SearchBox";

const HeaderMenu = (props) => (
  <header>
    <div className="ui container ">
      <div className="headerWrap">
        <div className="logoWrap">
          <Image src={logoImg} />
        </div>
        <div className="rightWrap">
          <div className="searchWrap">
            <SearchBox {...props} />
          </div>
          <div className="loggedUserWrap">
            <MenuDropdown loggedUserName={props.userDetail ? lodash(props.userDetail).get('userName', '').toLocaleUpperCase() : ''}
              options={[
                {
                  key: 'user',
                  text: (
                    <span>
                      Signed in as <strong>{props.userDetail ? lodash(props.userDetail).get('userName', '').toLocaleUpperCase() : ''}</strong>
                    </span>
                  ),
                  disabled: true
                },
                { key: 'profile', text: 'Your Profile' },
                { key: 'sign-out', text: 'Sign Out' }
              ]}
              logout={props.logout} />
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default HeaderMenu;
