import { dateMasking, formatDateToMMDDYYYY, getMessage, resetHeaderSortOrder, routeNavigationUrl, utcDateTimeToLocalDateTime, validateAuditLogDates } from 'helpers/util-common';
import Parser from 'html-react-parser';
import { ItemsPerPage, minDateForAuditLogs } from 'models/common.models';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Loader } from 'semantic-ui-react';
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import TableHeader from 'shared-components/Table/tableHeader';
import { AssociatedMembersColumns, MemberStatusOptions } from '../../../members/members.model';
import RenderInputField from 'shared-components/Input';
import * as _func from './function';

class OrganizationAssociatedMembersTab extends Component {

    state = {
        showLoader: true,
        auditLogList: [],
        pageNumber: 1,
        totalItems: 0,
        pageSize: 25,
        selectedFieldName: '',
        statusSelectedValue: 17,
        dateFrom: '',
        dateTo: '',
        maxDate: formatDateToMMDDYYYY(),
        currentlyShowingItems: '',
        sortKey: 'MemberAccountNumber',
        sortOrder: 'desc'
    }

    componentDidMount() {
        _func.setInstance(this);
        _func.loadInitialData();
    }

    componentWillUnmount() {
        resetHeaderSortOrder(AssociatedMembersColumns);
    }

    // Comment-Unused code
    // onChangeFieldType = (e, value) => {
    //     this.setState({ selectedFieldName: value });
    // }

    // onChangeDateFrom = (e, value) => {
    //     this.setState({ dateFrom: value ? formatDateToMMDDYYYY(value) : minDateForAuditLogs });
    // }

    // onChangeDateTo = (e, value) => {
    //     this.setState({ dateTo: formatDateToMMDDYYYY(value) });
    // }

    render() {

        const { messageCodes, submitting, handleSubmit, hasUserPageAccess, organizationAuditLogFieldList, PAGE_TYPE, USER_PRIVILEGE } = this.props;
        const { showLoader, selectedFieldName, auditLogList, dateFrom, maxDate, totalItems, pageNumber, pageSize, currentlyShowingItems, statusSelectedValue } = this.state;
      
        let minDateForToDate = minDateForAuditLogs;
        if (moment(dateFrom, 'MM/DD/YYYY', true).isValid() && dateFrom <= maxDate) {
            minDateForToDate = dateFrom;
        }
        return (
            <div>
                {showLoader && (
                    <Loader size='small' className="small-loader">Loading</Loader>
                )}
                <Form onSubmit={handleSubmit(_func.onClickAssociatedMembersSearch)} noValidate >
                    <div className="auditFilter inlineFilter mb20">
                        <div className="colWrap mr20" title="Search by Member Account Number, Name" data-testid="member" id="member">
                           { <Field name="selectedFieldName"
                                component={RenderInputField}
                                placeholder="Search by Member Account Number, Name"
                                label="Member"
                                onKeyPress={_func.onEnterSearchFilterRecord}
                                onChange={_func.onChangeAccountNumberSearchHandler}
                            /> }
                        </div>
                        <div className="colWrap" data-testid="status" id="status">
                            <label className="label">Status</label>
                            <RenderSelect name="statusSelectedValue" value={statusSelectedValue} onChange={(e, value) => _func.onChangeFilter(value)} options={MemberStatusOptions} />
                        </div>
                        <div className="auditAction">
                            <Button data-testid="btnReset" id="btnReset" className="cancel" type="reset" onClick={_func.resetForm}>Reset</Button>
                            <Button data-testid="btnSearch" id="btnSearch" className="primary ml5" disabled={submitting} type="submit">Search</Button>
                        </div>
                    </div>
                </Form>

                <div>
                    <div className="tableWrapper">
                        <table className="customTable auditLogtable type2" data-testid="customTable">
                            <thead>
                                <tr>
                                    <TableHeader headerProps={AssociatedMembersColumns} onClickProps={_func.sortByHandler}></TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {auditLogList.map((res) => (
                                    <tr>
                                        <td>
                                            <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [res.MemberAccountNumber])}`}>
                                                {res.MemberAccountNumber}
                                            </Link>
                                        </td>
                                        <td className="Name">
                                            {res.Name}
                                        </td>
                                        <td className="MembershipTypeName">
                                            {res.MembershipTypeName}
                                        </td>
                                        <td>
                                            {res.Status}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {!showLoader && auditLogList.length === 0 && (
                            <div className="noRecordMessage">{getMessage(messageCodes, '9010.text')}</div>
                        )}
                    </div>
                    {auditLogList.length > 0 &&
                        <section className="pagerWrap" data-testid="pagerWrap">
                            <div className="pager" data-testid="pager">
                                <PaginationShorthand defaultActivePage={pageNumber}
                                    totalPages={Math.ceil(totalItems / pageSize)}
                                    onPageChange={_func.onPageChangeHandler} />
                            </div>

                            <div className="itemPerPage" data-testid="itemPerPage">
                                <RenderSelect onChange={_func.onChangeNumberOfItemsPerPage}
                                    value={pageSize} options={ItemsPerPage} />
                                <span className="itemsPerPage">items per page</span>
                            </div>
                            <div className="totalPage">{currentlyShowingItems}</div>
                        </section>
                    }
                </div>

            </div >

        )
    }

}
export default (reduxForm({
    form: 'OrganizationAssociatedMembersTab',
    validate: validateAuditLogDates
})(OrganizationAssociatedMembersTab));
