
const initialState = {
}

const organizationReducer = (state = initialState, action) => {

    switch (action.type) {
        default:
            return state;
    }
}

export default organizationReducer;
