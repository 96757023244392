import { checkUserPermissionOnPage } from 'helpers/util-common';
import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import OrganizationAuditLogTab from '../organization-audit-log';
import OrganizationCommunicationLog from '../organization-communication-log';
import OrganizationPersonalDetails from '../organization-personal-details';
import OrganizationAssociatedMembersTab from '../organization-associated-members';
import { COMPANY_TYPE } from '../../../../../models/common.models';

class OrganizationDetailsTabs extends Component {

    componentWillMount() {
        const { PAGE_TYPE, USER_PRIVILEGE, userPermission } = this.props;
        this.userAccess = {
            hasUserPageAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
            hasUpdateAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.UPDATE),
            hasModerateCommunicationLogAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
            hasFeeGroupViewAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
            hasUpdatePaidStatusAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.UPDATE_PAID_STATUS)
        }
    }
    render() {

        const panes = [
            {
                menuItem: 'Organization Details', render: () => <Tab.Pane attached={false}>
                    <OrganizationPersonalDetails {...this.props} userAccess={this.userAccess} />
                </Tab.Pane>
            },
            {
                menuItem: 'Associated Members', render: () => <Tab.Pane attached={false}>
                    <OrganizationAssociatedMembersTab {...this.props} hasUserPageAccess={this.userAccess.hasUserPageAccess} />
                </Tab.Pane>
            }];

            if(this.props.initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY) {
                panes.push({
                    menuItem: 'Communication Log', render: () => <Tab.Pane attached={false}>
                        <OrganizationCommunicationLog {...this.props} hasModerateCommunicationLogAccess={this.userAccess.hasModerateCommunicationLogAccess} hasUserPageAccess={this.userAccess.hasUserPageAccess} />
                    </Tab.Pane>
                },
                {
                    menuItem: 'Audit Log', render: () => <Tab.Pane attached={false}>
                        <OrganizationAuditLogTab {...this.props} hasUserPageAccess={this.userAccess.hasUserPageAccess} />
                    </Tab.Pane>
                });
            }

        return (
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        )
    }
}

export default OrganizationDetailsTabs;
