import {
  getCurrentlyShowingItemsInGrid,
  getSortOrderFromHeaderClick,
  resetHeaderSortOrder,
  toUTCDateAndTime,
  utcDateTimeSecondToLocalDateTime,
} from "helpers/util-common";
import { endDayTime } from "models/common.models";
import { AuditGridColumns } from "modules/member-management/fee-group/feegroups.model";

let self = null;

export const setInstance = (instance) => {
  self = instance;
};

export const loadInitialData = () => {
  const {
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    pageNumber,
    sortKey,
    sortOrder,
  } = self.state;

  loadAuditLogList(
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    pageNumber,
    sortKey,
    sortOrder,
  );
};

export const loadAuditLogList = (
  selectedFieldName,
  dateFrom,
  dateTo,
  pageSize,
  pageNumber,
  sortKey,
  sortOrder,
) => {
  self.setState({ showLoader: true });

  if (dateFrom) {
    dateFrom = toUTCDateAndTime(dateFrom, "YYYY-MM-DD HH:mm:ss");
  }
  if (dateTo) {
    dateTo = dateTo + endDayTime;
    dateTo = toUTCDateAndTime(dateTo, "YYYY-MM-DD HH:mm:ss");
  }

  const requestData = {
    fieldName: selectedFieldName,
    dateFrom: dateFrom,
    dateTo: dateTo,
    pageSize: pageSize,
    pageNumber: pageNumber,
    sortKey: sortKey,
    sortOrder: sortOrder,
  };
  self.props.getFeeGroupAuditLog(
    self.props.initialValues.FeeGroupNumber,
    requestData,
    (result) => {
      let auditLogList = [];
      let totalItems = 0;

      if (result && result.AuditLogs && result.AuditLogs.length > 0) {
        auditLogList = result.AuditLogs;
        totalItems = result.Count;
      }

      self.setState({ auditLogList, totalItems, showLoader: false });
      updateCurrentlyShowingItems(totalItems, pageSize, pageNumber);

      let scrollElement = document.getElementsByClassName(
        "customTable auditLogtable",
      )[0];
      scrollElement.scrollIntoView({
        behavior: "smooth",
      });
    },
  );
};

export const onPageChangeHandler = (event, data) => {
  const { selectedFieldName, dateFrom, dateTo, pageSize, sortKey, sortOrder } =
    self.state;
  loadAuditLogList(
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    data.activePage,
    sortKey,
    sortOrder,
  );

  self.setState({ pageNumber: data.activePage });
};

export const onChangeNumberOfItemsPerPage = (e, value) => {
  const { selectedFieldName, dateFrom, dateTo, sortKey, sortOrder } =
    self.state;
  loadAuditLogList(
    selectedFieldName,
    dateFrom,
    dateTo,
    value,
    1,
    sortKey,
    sortOrder,
  );

  self.setState({ pageSize: value, pageNumber: 1 });
};

// Reset the entire grid
export const resetForm = () => {
  const selectedFieldName = "All";
  const dateFrom = "";
  const dateTo = "";
  const pageSize = 25;
  const pageNumber = 1;
  const sortKey = "When";
  const sortOrder = "desc";

  self.props.reset();
  self.props.change("dateFrom", dateFrom);
  self.props.change("dateTo", dateTo);
  self.setState({
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    pageNumber,
    sortKey,
    sortOrder,
  });
  resetHeaderSortOrder(AuditGridColumns);
  loadAuditLogList(
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    pageNumber,
    sortKey,
    sortOrder,
  );
};

export const sortByHandler = (event, sortKey) => {
  let orderType = getSortOrderFromHeaderClick(event);
  const sortOrder = orderType === "0" ? "asc" : "desc";

  self.setState({ sortKey, sortOrder });

  const { selectedFieldName, dateFrom, dateTo, pageSize, pageNumber } =
    self.state;
  loadAuditLogList(
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    pageNumber,
    sortKey,
    sortOrder,
  );
};

export const onClickAuditSearch = () => {
  const { selectedFieldName, dateFrom, dateTo, pageSize, sortKey, sortOrder } =
    self.state;

  self.setState({ pageNumber: 1 });
  loadAuditLogList(
    selectedFieldName,
    dateFrom,
    dateTo,
    pageSize,
    1,
    sortKey,
    sortOrder,
  );
};

export const getDescription = (where, why = null) => {
  if (where && where.length > 0) {
    let htmlResult = "";
    const copied = "Member copied";
    const memberAdded = "Member added";
    const copyrightAcceptance = "Copyright Acceptance";
    where.map((item) => {
      if (item.FormatTemplate) {
        htmlResult += `<p><strong>${item.NewValue}</strong> ${item.FormatTemplate}</p>`; //item.FormatTemplate.replace("NewValue", item.NewValue);
      } else {
        htmlResult = `${htmlResult}<p><strong>${item.Field}</strong> 
            ${"updated  from"} 
            <strong>${
              item.OldValue ? item.OldValue : "--"
            }</strong> ${"to"} <strong>${
          item.NewValue ? item.NewValue : "--"
        }</strong></p>`;
      }
    });
    return htmlResult;
  }
  return "";
};
export const refreshMemberDetailPage = (url) => this.props.history.push(url);

// Private functions
const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
  const currentlyShowingItems = getCurrentlyShowingItemsInGrid(
    pageNumber,
    pageSize,
    totalItems,
  );

  self.setState({ currentlyShowingItems });
};
