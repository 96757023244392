// This file will have all the model objects which are used in put or post requests

export const TaskAssigneeUpdateModel = {
    'TaskId': '',
    'AssigneeId': '',
    'TaskStatusId': '',
    'IsBreakDependency': false,
    'IsUpdateStatusDoneToOpen': false,
    'IsAssigneeChanged': false,
    'IsStatusChange': false
}

export const TaskDetailsUpdateModel = {
    'TaskId': '',
    'Title': '',
    'StartDate': '',
    'EndDate': '',
    'TaskDescription': '',
    'IsDeleted': false,
    'DeleteReason': '',
    'IsBreakDependency': false
}

export const ShowTasksOptions = [
    { key: 1, text: "All Tasks", value: 1 },
    { key: 2, text: "Only My Tasks", value: 2 }
]

// Need to remove this and fetch it from db
export const FieldNameOptions = [
    { key: 1, text: "All", value: 1 },
    { key: 2, text: "Task Title", value: 2 },
    { key: 3, text: "Start Date", value: 3 },
    { key: 4, text: "End Date", value: 4 },
    { key: 5, text: "Description", value: 5 },
    { key: 6, text: "Assignee", value: 6 },
    { key: 7, text: "Status", value: 7 },
    { key: 8, text: "Depends on Tasks", value: 8 }
]

export const AuditGridColumns = [
    { 'title': 'Modified Date/Time', 'hasSorting': true, 'className': "date", "sortKey": 'When', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Description', 'hasSorting': false, 'className': "description" },
    { 'title': 'Modified By', 'hasSorting': true, 'className': "modifiedby", 'sortKey': 'Who', 'orderBy': 0, 'orderByIcon': 0 }
]

export const getDefaultYearsList = () => {
    let years = [];
    let currentYear = new Date().getFullYear();
    years.push(`${currentYear}`);
    return years;
}
