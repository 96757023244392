import Userimg from "assets/images/user-circle.png";
import { dateFormatToStringWithDateAndTime, formatUserListForCommunicationLog, getLoggedInUserId, removeExtraSpaces, routeNavigationUrl } from 'helpers/util-common';
import Parser from 'html-react-parser';
import _ from 'lodash';
import React, { Component } from 'react';
import { Comment, Confirm, Icon, Loader } from 'semantic-ui-react';
import CommentPost from 'shared-components/PostComment';
import CustomTooltip from 'shared-components/Tooltip';

class CommunicationLogInfo extends Component {

    shouldComponentUpdate(nextProps) {
        const { showLoader, isPopOpen, commentCount, commentLength, clickedCommentId } = this.props;

        if (nextProps.showLoader !== showLoader || nextProps.isPopOpen !== isPopOpen || nextProps.commentCount !== commentCount ||
            nextProps.commentLength !== commentLength || nextProps.clickedCommentId !== clickedCommentId) {
            return true;
        }
    }

    currentUserId = getLoggedInUserId();

    communicationCancelHandler = () => {
        this.refs.commentPost.refs.commentPost.innerHTML = '';
        this.props.communicationCancelHandler();
    }

    validateRefExistHandler = (comment) => {
        if (this.refs.CommentPostEdit !== undefined) {
            const { refs } = this.refs.CommentPostEdit;
            refs.commentPost.innerHTML = comment;
        }
        return true;
    }

    onUpdateHandler = () => {
        const refs = this.refs.CommentPostEdit.refs;

        if (refs && refs.commentPost && refs.commentPost.innerText.trim() !== '') { // Validate the condition for innertTxt Exit or Not
            this.props.onUpdateHandler(removeExtraSpaces(refs.commentPost.innerHTML), this.onSuccessHandler)
        } else {
            refs.commentPost.innerHTML = '';
        }
    }

    onSubmitHandler = () => {
        const { refs } = this.refs.commentPost;

        if (refs && refs.commentPost && refs.commentPost.innerText !== '') { // Validate the condition for innertTxt Exit or Not
            this.props.onSubmitHandler(removeExtraSpaces(refs.commentPost.innerHTML), this.onSuccessHandler)
        }
    }

    onSuccessHandler = () => {
        const { refs } = this.refs.commentPost;
        refs.commentPost.innerHTML = '';
    }

    getUserName = (userId) => {
        const { createdByUserList } = this.props;

        if (createdByUserList && createdByUserList.length > 0) {
            const record = createdByUserList.find(user => user.UserId === userId);
            return record ? record.UserName : '';
        }
        return '';
    }

    render() {
        const { messageCodes, hasUserPageAccess, userList, communicationLogList, showLoader, isPopOpen, commentCount, communicationEditHandler, checkUserPagePermission, handleConfirm, handleCancel, openDeleteConfirmPopUp, onChange, commentLength, hasModerateCommunicationLogAccess, PAGE_TYPE, USER_PRIVILEGE } = this.props;
        const formattedUserList = formatUserListForCommunicationLog(userList);
        const permissionProps = { PAGE_TYPE, USER_PRIVILEGE, hasUserPageAccess };
        const getCommentList = (list) => {
            if (list && list.length > 0) {
                return list.map((res) => {
                    return (
                        <Comment data-testid="communicationlog">
                            <Comment.Avatar as='a' src={Userimg} />
                            <Comment.Content>
                                {!res.isClickedOnEdit &&
                                    <div>
                                        {hasUserPageAccess ? (
                                            <Comment.Author as='a' href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW, [res.CreatedBy])}`}>{this.getUserName(res.CreatedBy)}</Comment.Author>
                                        ) : (
                                                <Comment.Author as='span'>{this.getUserName(res.CreatedBy)}</Comment.Author>
                                            )}
                                        <Comment.Metadata>
                                            <div>{dateFormatToStringWithDateAndTime(res.CreatedDate)}{res.ModifiedDate > res.CreatedDate && <span> • Edited</span>}
                                            </div>
                                        </Comment.Metadata>
                                        <Comment.Text onClick={checkUserPagePermission}>{Parser(res.Comment)}</Comment.Text>
                                    </div>
                                }
                                {res.isClickedOnEdit && this.validateRefExistHandler(res.Comment) &&
                                    <div>
                                        <CommentPost {...permissionProps} comment={res.Comment} ref='CommentPostEdit'
                                            resultSet={formattedUserList} />
                                    </div>
                                }
                                <Confirm className="confirm-box" open={isPopOpen} content={_.get(messageCodes, '[9008].text')}
                                    onConfirm={handleConfirm} confirmButton="Yes"
                                    onCancel={handleCancel} cancelButton='No' />
                                {!res.isClickedOnEdit && (hasModerateCommunicationLogAccess || this.currentUserId == res.CreatedBy) &&
                                    <span className="edit-delete">
                                        <CustomTooltip content='Edit' icon={<Icon name='pencil' onClick={() => communicationEditHandler(res.CommentId)} />} />
                                        <CustomTooltip content='Delete' icon={<Icon name='delete' onClick={() => { openDeleteConfirmPopUp(res.CommentId) }} />} />
                                    </span>
                                }
                                {res.isClickedOnEdit && this.validateRefExistHandler(res.Comment) &&
                                    <span className="update-cancel">
                                        <button type='submit' className="ui primary button mr10" onClick={this.onUpdateHandler} >Update</button>
                                        <button className="ui button cancel mr0" onClick={() => { communicationEditHandler(0) }} >Cancel</button>
                                    </span>
                                }
                            </Comment.Content>
                        </Comment>
                    )
                })
            }
            return _.get(messageCodes, '[9015].text')
        }
        return (
            <Comment.Group minimal>
                <h4 className="txtCenter mb10">{commentCount} Comments</h4>

                <CommentPost {...this.props} onChange={onChange} ref='commentPost' resultSet={formattedUserList}
                    placeholder={'Type @ to mention a person'} />
                <div className="clearfix">
                    {
                        commentLength > 0 &&
                        <div className="column actions text-align-right">
                            <button type="submit" className="ui primary button mr10" onClick={this.onSubmitHandler}>Submit</button>
                            <button className="ui button cancel mr0" onClick={this.communicationCancelHandler}>Cancel</button>
                        </div>
                    }
                    {showLoader && (
                        <Loader size='small' className="small-loader">Loading</Loader>
                    )}
                </div>
                {getCommentList(communicationLogList, this.validateRefExistHandler)}
            </Comment.Group>
        )
    }
}

export default CommunicationLogInfo;

