import {
   cacheManager, encrypt,
   getFormData, getMessage, manipulateCountryList
} from 'helpers/util-common';
import { isEmpty, map, sortBy } from 'lodash';
import CommonFormOrganizationActivity from 'modules/admin/manual-order/components/common-form-organization-activity';
import React, { Component } from "react";
import { SubmissionError, Field } from 'redux-form';
import { Grid, Button, Confirm, Form, Modal } from 'semantic-ui-react';
import TableHeader from 'shared-components/Table/tableHeader';
import { FORM_TYPE_ORG_UPDATE, routeNavigationUrl, zipCodeCARegex, zipCodeRegex, zipCodeUSRegex } from '../../../../../helpers/util-common';
import { CountryCodes, organizationFacilityRadioText } from '../../../../../models/common.models';
import { PAGE_TYPE, USER_PRIVILEGE } from '../../../../../models/navigation.models';
import AddressVerificationConfirmPopup from '../../../../../shared-components/AddressVerificationPopup';
import { useFlagFeatures } from '../../../../../providers/flagFeature'
import RenderRadioField from 'shared-components/RadioBox';
import RenderInputField from 'shared-components/Input';
import { MembershipTypes } from 'modules/member-management/members/members.model';

class UpdateOrgInformation extends Component {
   state = {
      payloadToBeSubmitted: null,
      isSubmitConfirm: false,
      isShowMemberList: false,
      memberList: [],
      isAlreadyExistsCompany: false,
      countryStateData: {},
      oldCompanyDetails: null,
      commonOrgFormInitializeData: null,
      openModal: false,
      alertMsg: '',
      isShowAddressValidationModal: false,
      isInvalidAddressModal: false,
      contentForVerificationAddress: null,
      isUpdateOrganizationExceptFourFields: false,
      isAssociationSelected: false,
      isAssociationSelectedValue: true,
      isAccountAssociationSelected: false,
      manualOrgAccountNumber: '',
      isInvalidAccount: false,
      isInvalidOrgAccount: false,
      isInvalidManualOrgAccount: false,
      errorMessage: '',
      companyType: '',
      mcsStatusMasterId: 0
   }
   componentDidMount() {
      const { activeCountryList, usStateList, canadaStateList } = this.props;
      const countryStateData = manipulateCountryList(
         {
            activeCountryList: JSON.parse(JSON.stringify(activeCountryList)),
            usStateList: JSON.parse(JSON.stringify(usStateList)),
            canadaStateList: JSON.parse(JSON.stringify(canadaStateList))
         }
      );
      this.setState({ countryStateData });
      this.getCompanyDataAndPopulate();
   }

   getCompanyDataAndPopulate = () => {
      const { formType, editType } = this.props;
      if (editType === 'update') {
         if (formType === FORM_TYPE_ORG_UPDATE.Member) {
            const { initialize, initialValues, getCompanyByMemberAccountNumber } = this.props;
            getCompanyByMemberAccountNumber(initialValues.AccountNumber, (result) => {
               this.setState({
                  oldCompanyDetails: result,
                  commonOrgFormInitializeData: {
                     manualOrderOrggNam: result.CompanyName,
                     manualOrderOrgAdrLine1: result.AddressLine1,
                     orgCountry: result.CountryName,
                     manualOrderstatpro: result.StateCode ? result.StateCode : result.StateName,
                     PostalCode: result.PostalCode
                  },
                  openModal: true
               }, () => {
                  initialize({
                     manualOrderOrggNam: result.CompanyName,
                     manualOrderOrgAdrLine1: result.AddressLine1,
                     manualOrderOrgAdrLine2: result.AddressLine2,
                     manualOrderOrgCty: result.City,
                     orgCountry: result.CountryName,
                     manualOrderstatpro: result.StateCode ? result.StateCode : result.StateName,
                     manualOrderZpcd: result.PostalCode,
                     orgCountryCode: this.getCountryCode(result.CountryName)
                  });
               })
            });
         } else if (formType === FORM_TYPE_ORG_UPDATE.Organization) {
            const { initialize, initialValues } = this.props;
            this.setState({
               oldCompanyDetails: {
                  CompanyName: initialValues.OrgName,
                  AddressLine1: initialValues.AddressLine1,
                  AddressLine2: initialValues.AddressLine2,
                  CountryCode: this.getCountryCode(initialValues.Country),
                  City: initialValues.City,
                  StateName: initialValues.State,
                  StateCode: initialValues.State,
                  PostalCode: initialValues.PostalCode,
                  CompanyId: initialValues.OrganizationalMemberId,
                  AccountNumber: initialValues.AccountNumber,
                  Email: initialValues.Email,
                  FaxNumber: initialValues.FaxNumber,
                  PhoneNumber: initialValues.PhoneNumber,
                  WebUrl: initialValues.WebUrl
               },
               openModal: true,
               commonOrgFormInitializeData: {
                  manualOrderOrggNam: initialValues.OrgName,
                  manualOrderOrgAdrLine1: initialValues.AddressLine1,
                  orgCountry: initialValues.Country,
                  State: initialValues.State,
                  PostalCode: initialValues.PostalCode
               }
            }, () => {
               initialize({
                  manualOrderOrggNam: initialValues.OrgName,
                  manualOrderOrgAdrLine1: initialValues.AddressLine1,
                  manualOrderOrgAdrLine2: initialValues.AddressLine2,
                  manualOrderOrgCty: initialValues.City,
                  orgCountry: initialValues.Country,
                  manualOrderstatpro: initialValues.State,
                  manualOrderZpcd: initialValues.PostalCode,
                  orgCountryCode: this.getCountryCode(initialValues.Country),
                  manualOrderOrgFax: initialValues?.FaxNumber,
                  manualOrderOrgEmail: initialValues?.Email,
                  manualOrderOrgPhone: initialValues?.PhoneNumber,
                  manualOrderOrgWebURL: initialValues?.WebUrl
               });
            })
         }
      } else {
         this.setState({ openModal: true })
      }
   }
   getCountryCode = (CountryName) => {
      if (CountryName && this.props.originalActiveCountryList) {
         let matchedItem = this.props.originalActiveCountryList.find(i => i.CountryName === CountryName);
         if (matchedItem) {
            return matchedItem.CountryCode;
         }
      }
      return '-1';
   }
   getStateValue = (countryCode, stateName) => {
      const { activeCountryList, usStateList, canadaStateList } = this.props;
      if (countryCode === CountryCodes.UNITEDSTATES.Code) {
          return stateName ? stateName : '-1';
      } else if (countryCode === CountryCodes.CANADA.Code) {
          return stateName ? stateName : '-1';
      } else {
      }
      return stateName ? stateName : '';
  }
   handle_UpdateFacility = (values) => {
      
      if (!(values.manualOrderOrggNam && values.manualOrderOrggNam.trim())) {
         throw new SubmissionError({ manualOrderOrggNam: getMessage(this.props.messageCodes, '9113.text') });
      }
      else if (!(values.manualOrderOrgAdrLine1 && values.manualOrderOrgAdrLine1.trim())) {
         throw new SubmissionError({ manualOrderOrgAdrLine1: getMessage(this.props.messageCodes, '9137.text') });
      }
      else if (!(values.manualOrderOrgCty && values.manualOrderOrgCty.trim())) {
         throw new SubmissionError({ manualOrderOrgCty: getMessage(this.props.messageCodes, '9138.text') });
      }
      else if (!values.orgCountry || parseInt(values.orgCountry) === -1) {
         throw new SubmissionError({ orgCountry: getMessage(this.props.messageCodes, '9139.text') });
      }
      else if (values.orgCountry &&
         values.orgCountry !== '-1' &&
         (values.orgCountry === CountryCodes.UNITEDSTATES.Name || values.orgCountry === CountryCodes.CANADA.Name) &&
         (!values.manualOrderZpcd || (values.manualOrderZpcd && values.manualOrderZpcd.trim().length === 0))
      ) {
         throw new SubmissionError({ manualOrderZpcd: getMessage(this.props.messageCodes, '9140.text') });
      }
      else if ((values.orgCountry === CountryCodes.CANADA.Name &&
         ((values.manualOrderZpcd.trim().length < 7) || !zipCodeCARegex.test(values.manualOrderZpcd)))
         ||
         (values.orgCountry === CountryCodes.UNITEDSTATES.Name &&
            ((values.manualOrderZpcd.trim().length < 5) || !zipCodeUSRegex.test(values.manualOrderZpcd)))) {
         throw new SubmissionError({ manualOrderZpcd: getMessage(this.props.messageCodes, '9529.text') });
      }
      else if (values.orgCountry !== CountryCodes.UNITEDSTATES.Name && values.orgCountry !== CountryCodes.CANADA.Name && (values.manualOrderZpcd && !zipCodeRegex.test(values.manualOrderZpcd))) {
         throw new SubmissionError({ manualOrderZpcd: getMessage(this.props.messageCodes, '9529.text') });
      }
      let CountryCode = this.props.originalActiveCountryList && this.props.originalActiveCountryList.length > 0 ? this.props.originalActiveCountryList.find(x => x.CountryName === values.orgCountry).CountryCode : '';
      let stateValidateArray = ['US', 'CA'];
      if ((!values.manualOrderstatpro || parseInt(values.manualOrderstatpro) === -1) && stateValidateArray.indexOf(CountryCode) > -1) {
         throw new SubmissionError({ manualOrderstatpro: getMessage(this.props.messageCodes, '9145.text') });
      }

      const { editType, formType, initialValues } = this.props;
      const isAddressVerification = this.props.flagFeature.getFlagFeatures('AddressValidation');
      const { oldCompanyDetails, companyType } = this.state;

      let payload = {
         "MemberId": encrypt(this.props.initialValues.MemberId),
         "AccountNumber": this.props.initialValues.AccountNumber,
         "OldFacilityName": this.props.initialValues.FacilityName,
         "CompanyId": (editType === 'update' && oldCompanyDetails.CompanyId) || null,
         "CompanyAccountNumber": (editType === 'update' && oldCompanyDetails.AccountNumber) || null,
         "CompanyName": values.manualOrderOrggNam.trim(),
         "AddressLine1": values.manualOrderOrgAdrLine1.trim(),
         "AddressLine2": values.manualOrderOrgAdrLine2 ? values.manualOrderOrgAdrLine2.trim() : null,
         "CountryCode": CountryCode,
         "StateName": values.manualOrderstatpro ? values.manualOrderstatpro.trim() : null,
         "City": values.manualOrderOrgCty.trim(),
         "PostalCode": values.manualOrderZpcd,
         "ModifiedUserName": JSON.parse(cacheManager.getItem('userDetail')).userName
      }
      if (formType === FORM_TYPE_ORG_UPDATE.Organization) {
         payload.Email = values?.manualOrderOrgEmail ? values?.manualOrderOrgEmail.trim() : null
         payload.Phone = values?.manualOrderOrgPhone ? values?.manualOrderOrgPhone.trim() : null
         payload.Fax = values?.manualOrderOrgFax ? values?.manualOrderOrgFax.trim() : null
         payload.WebsiteUrl = values?.manualOrderOrgWebURL ? values?.manualOrderOrgWebURL.trim() : null
         payload.IsUpdateAdditionalOrgDetails = true
      }
      const checkCompanyPayload = {
         "Company": {
            "CompanyId": this.props.initialValues.CompanyId,
            "CompanyName": payload.CompanyName
         },
         "Address": {
            "AddressLine1": payload.AddressLine1,
            "AddressLine2": payload.AddressLine2,
            "CountryCode": payload.CountryCode,
            "StateName": payload.StateName,
            "City": payload.City,
            "PostalCode": payload.PostalCode,
            "ForFinalUpdateAddress": isAddressVerification === true ? false : undefined
         }
      }
      if(this.props.checkIsHistoricalCompanyAndAddressExistsAction && checkCompanyPayload.Company.CompanyId>0){
         this.props.checkIsHistoricalCompanyAndAddressExistsAction(checkCompanyPayload, true, (res) => {
            const { editType } = this.props;
            if (res) {
               let errorCode = '9900.text';
               this.setState({isInvalidManualOrgAccount:true, errorMessage: getMessage(this.props.messageCodes, errorCode)})
            } else if(this.props.checkIsHistoricalCompanyAndAddressExistsAction && (companyType === undefined || companyType === '')) {
               this.callHistoricalCompanyAndMemberApi(checkCompanyPayload, payload)
            }else if (editType === 'update') {
               const checkOldAndNewValues = this.checkOldOrganizationValues(oldCompanyDetails, payload)
               if (checkOldAndNewValues.value) {
                  this.props.showMessageForNoChangeInCompanyInfoForm();
                  this.handleCloseModal();
               } else {
                  this.setState({ isUpdateOrganizationExceptFourFields: checkOldAndNewValues.isUpdateOrganizationExceptFourFields }, () => {
                     this.callCompanyAndMemberApi(checkCompanyPayload, payload)
                  })
               }
            } else {
               payload.ForFinalUpdateAddress = isAddressVerification === true ? false : undefined;
               this.setState({ payloadToBeSubmitted: payload }, () => {
                  this.submitPayload();
               });
            }
         });
      } else if(this.props.checkIsHistoricalCompanyAndAddressExistsAction && (companyType === undefined || companyType === '')) {
         this.callHistoricalCompanyAndMemberApi(checkCompanyPayload, payload)
      }else if (editType === 'update') {
         const checkOldAndNewValues = this.checkOldOrganizationValues(oldCompanyDetails, payload)
         if (checkOldAndNewValues.value) {
            this.props.showMessageForNoChangeInCompanyInfoForm();
            this.handleCloseModal();
         } else {
            this.setState({ isUpdateOrganizationExceptFourFields: checkOldAndNewValues.isUpdateOrganizationExceptFourFields }, () => {
               this.callCompanyAndMemberApi(checkCompanyPayload, payload)
            })
         }
      } else {
         payload.ForFinalUpdateAddress = isAddressVerification === true ? false : undefined;
         this.setState({ payloadToBeSubmitted: payload }, () => {
            this.submitPayload();
         });
      }
   }

   callHistoricalCompanyAndMemberApi = (checkCompanyPayload, payload) => {
      const { editType, initialValues, messageCodes } = this.props;
      const isAddressVerification = this.props.flagFeature.getFlagFeatures('AddressValidation');
      const { oldCompanyDetails } = this.state;
      this.props.checkIsHistoricalCompanyAndAddressExistsAction(checkCompanyPayload, false, (res) => {
         const payloadData = { ...payload, ...checkCompanyPayload.Address }
         const setObj = { payloadToBeSubmitted: payloadData, isAlreadyExistsCompany: false };
         if (res) {
            let errorCode = initialValues.MemberTypeId === MembershipTypes.Student ? '9182.text' : '9183.text';
            this.setState({isInvalidManualOrgAccount:true, errorMessage: getMessage(messageCodes, errorCode)})
         }else if (editType === 'update') {
            const checkOldAndNewValues = this.checkOldOrganizationValues(oldCompanyDetails, payload)
            if (checkOldAndNewValues.value) {
               this.props.showMessageForNoChangeInCompanyInfoForm();
               this.handleCloseModal();
            } else {
               this.setState({ isUpdateOrganizationExceptFourFields: checkOldAndNewValues.isUpdateOrganizationExceptFourFields }, () => {
                  const checkCompanyPayload = {
                     "Company": {
                        "CompanyName": payload.CompanyName
                     },
                     "Address": {
                        "AddressLine1": payload.AddressLine1,
                        "AddressLine2": payload.AddressLine2,
                        "CountryCode": payload.CountryCode,
                        "StateName": payload.StateName,
                        "City": payload.City,
                        "PostalCode": payload.PostalCode,
                        "ForFinalUpdateAddress": isAddressVerification === true ? false : undefined
                     }
                  }
                  this.callCompanyAndMemberApi(checkCompanyPayload, payload)
               })
            }
         } else {
            payload.ForFinalUpdateAddress = isAddressVerification === true ? false : undefined;
            this.setState({ payloadToBeSubmitted: payload }, () => {
               this.submitPayload();
            });
         }
         this.setState(setObj);
      });
   }


   callCompanyAndMemberApi = (checkCompanyPayload, payload) => {
      const { messageCodes, formType } = this.props;
      if (formType === FORM_TYPE_ORG_UPDATE.Organization) {
         if (this.state.isUpdateOrganizationExceptFourFields) {
            this.props.checkIsCompanyAndAddressDetailsExistsAction(checkCompanyPayload, (res) => {
               const setObj = { payloadToBeSubmitted: payload };
               if (res) {
                  if (res.isQueryForVerifyingAddress !== undefined) {
                     if (res?.isQueryForVerifyingAddress) {
                        this.setState({ isShowAddressValidationModal: true, contentForVerificationAddress: res, payloadToBeSubmitted: payload })
                     } else {
                        this.setState({ isInvalidAddressModal: true, contentForVerificationAddress: res, payloadToBeSubmitted: payload })
                     }
                  } else {
                     let alertMsg = getMessage(messageCodes, '9172.text');
                     let orgLink = `<a className='linkTxt vAuto' title='${payload.CompanyName}' href='${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW, [res])}'>Organization</a>`;
                     alertMsg = alertMsg.replace('@Organization', orgLink);
                     this.props.showAlertPopup(alertMsg);
                  }
               } else {
                  setObj.isSubmitConfirm = true;
               }
               this.setState(setObj);
            });
         } else {
            this.setState({ payloadToBeSubmitted: payload, isSubmitConfirm: true })
         }
      } else {
         this.props.checkIsCompanyAndAddressExistsAction(checkCompanyPayload, (res) => {
            const payloadData = { ...payload, ...checkCompanyPayload.Address }
            const setObj = { payloadToBeSubmitted: payloadData, isAlreadyExistsCompany: false };
            if (res) {
               if (res.isQueryForVerifyingAddress !== undefined) {
                  if (res.isQueryForVerifyingAddress) {
                     this.setState({ isShowAddressValidationModal: true, contentForVerificationAddress: res, payloadToBeSubmitted: payloadData })
                  } else {
                     this.setState({ isInvalidAddressModal: true, contentForVerificationAddress: res, payloadToBeSubmitted: payload })
                  }
               } else {
                  setObj.isAlreadyExistsCompany = true;
                  setObj.alertMsg = getMessage(messageCodes, '9173.text')
               }
            } else {
               setObj.isSubmitConfirm = true;
            }
            this.setState(setObj);
         });
      }
   }

   checkOldOrganizationValues = (oldCompanyDetails, payload) => {
      if (isEmpty(oldCompanyDetails)) {
         return { value: false, isUpdateOrganizationExceptFourFields: true };
      }
      let stateMatchKey = 'StateName';
      let stateValidateArray = ['US', 'CA'];
      if (stateValidateArray.indexOf(oldCompanyDetails.CountryCode) !== -1) {
         stateMatchKey = 'StateCode';
      }
      let addressLine2 = isEmpty(oldCompanyDetails.AddressLine2) ? null : oldCompanyDetails.AddressLine2;
      let stateName = isEmpty(oldCompanyDetails[stateMatchKey]) ? null : oldCompanyDetails[stateMatchKey];
      let postalCode = isEmpty(oldCompanyDetails.PostalCode) ? null : oldCompanyDetails.PostalCode;
      let CountryCode = isEmpty(oldCompanyDetails.CountryCode) ? null : oldCompanyDetails.CountryCode;

      if (oldCompanyDetails.CompanyName !== payload.CompanyName ||
         oldCompanyDetails.AddressLine1 !== payload.AddressLine1 ||
         addressLine2 !== payload.AddressLine2 ||
         oldCompanyDetails.City !== payload.City ||
         CountryCode !== payload.CountryCode ||
         stateName !== payload.StateName ||
         postalCode !== payload.PostalCode
      ) {
         return { value: false, isUpdateOrganizationExceptFourFields: true };
      }
      if (this.props.formType === FORM_TYPE_ORG_UPDATE.Organization) {
         if (oldCompanyDetails.PhoneNumber !== payload.Phone ||
            oldCompanyDetails.Email !== payload.Email ||
            oldCompanyDetails.FaxNumber !== payload.Fax ||
            oldCompanyDetails.WebUrl !== payload.WebsiteUrl) {
            return { value: false, isUpdateOrganizationExceptFourFields: false };
         }
      }
      return { value: true, isUpdateOrganizationExceptFourFields: false };
   }
   handleShowMemberList = () => {
      const { oldCompanyDetails } = this.state;
      const payload = {
         companyId: oldCompanyDetails.CompanyId
      };
      this.props.getCompanyAssociatedMembersDetails(payload, (memberView) => {
         let updatedList = map(memberView.MembersDetail, member => ({
            accountNumber: member.MemberAccountNumber,
            memberName: member.Name,
            accountStatus: member.Status
         }));
         updatedList = sortBy(updatedList, ["memberName", "accountNumber"]);
         this.setState({ isShowMemberList: true, memberList: updatedList });
      });
   }

   handleHideMemberList = () => {
      this.setState({ isShowMemberList: false, memberList: [] });
   }

   onCancelSubmitConfirm = () => {
      this.setState({ isSubmitConfirm: false });
   }

   submitPayload = () => {
      const { payloadToBeSubmitted } = this.state;
      this.updateOrgInfo(payloadToBeSubmitted);
   }

   handleHideAssociationConfirmationPopup = () => {
      this.setState({ isAlreadyExistsCompany: false });
   }

   associateWithExistingCompany = () => {
      const { payloadToBeSubmitted } = this.state;
      payloadToBeSubmitted.CompanyId = null;
      payloadToBeSubmitted.CompanyAccountNumber = null;
      this.updateOrgInfo(payloadToBeSubmitted);
   }
   updateOrgInfo = (payloadToBeSubmitted) => {
      const { updateCompanyInfo, formType, updateOrganization } = this.props;
      if (formType === FORM_TYPE_ORG_UPDATE.Organization) {
         updateOrganization(payloadToBeSubmitted, (res) => {
            this.closeFacilityPopupsAndRefreshMemberData(true);
         });
      } else {
         updateCompanyInfo(payloadToBeSubmitted, (res) => {
            if (res.isQueryForVerifyingAddress !== undefined && !payloadToBeSubmitted.ForFinalUpdateAddress) {
               if (res?.isQueryForVerifyingAddress) {
                  this.setState({ isShowAddressValidationModal: true, contentForVerificationAddress: res })
               } else {
                  this.setState({ isInvalidAddressModal: true, contentForVerificationAddress: res })
               }
            } else {
               this.closeFacilityPopupsAndRefreshMemberData(true);
            }
         });
      }
   }
   closeFacilityPopupsAndRefreshMemberData = (isMemberRefresh) => {
      const { initialValues, reset, formType } = this.props;
      const { payloadToBeSubmitted } = this.state
      payloadToBeSubmitted.ForFinalUpdateAddress = false

      this.setState({
         isSubmitConfirm: false,
         isAlreadyExistsCompany: false,
         payloadToBeSubmitted
      }, () => {
         this.handleCloseModal()
         if (isMemberRefresh && formType === FORM_TYPE_ORG_UPDATE.Member) {
            this.props.getMemberDetails(initialValues.AccountNumber);
         } else if (isMemberRefresh && formType === FORM_TYPE_ORG_UPDATE.Organization) {
            const companyId = initialValues.OrganizationalMemberId;
            this.props.getOrganizationDetails(initialValues.AccountNumber, companyId);
         }
         reset();
      });
   }
   handleCloseModal = () => {
      this.setState({ openModal: false },
         () => this.props.onCloseModal()
      );
   }

   onCloseAddressVerificationModal = (cleansedStoreValue = null, action = 'submit') => {
      const { payloadToBeSubmitted, contentForVerificationAddress, isUpdateOrganizationExceptFourFields, isSuggestedAddressUpdate } = this.state
      const { editType, formType } = this.props
      if (action === 'submit' || typeof action === 'object') {
         if (editType === 'update') {
            payloadToBeSubmitted.ForFinalUpdateAddress = true
            let checkCompanyPayload = {
               "Company": {
                  "CompanyName": payloadToBeSubmitted.CompanyName
               },
               "Address": {
                  "AddressLine1": payloadToBeSubmitted.AddressLine1,
                  "AddressLine2": payloadToBeSubmitted.AddressLine2,
                  "CountryCode": payloadToBeSubmitted.CountryCode,
                  "StateName": payloadToBeSubmitted.StateName,
                  "City": payloadToBeSubmitted.City,
                  "PostalCode": payloadToBeSubmitted.PostalCode,
                  "ForFinalUpdateAddress": true
               }
            }
            if (contentForVerificationAddress?.isQueryForVerifyingAddress) {
               if (cleansedStoreValue === "false") {
                  checkCompanyPayload.Company = { "CompanyName": payloadToBeSubmitted.CompanyName }
                  checkCompanyPayload.Address = {
                     "AddressLine1": contentForVerificationAddress.validateAddress.AddressLine1,
                     "AddressLine2": contentForVerificationAddress.validateAddress.AddressLine2,
                     "CountryCode": contentForVerificationAddress.validateAddress.CountryCode,
                     "StateName": contentForVerificationAddress.validateAddress.StateName,
                     "City": contentForVerificationAddress.validateAddress.City,
                     "PostalCode": contentForVerificationAddress.validateAddress.PostalCode,
                     "ForFinalUpdateAddress": true
                  }
                  payloadToBeSubmitted.AddressLine1 = contentForVerificationAddress.validateAddress.AddressLine1
                  payloadToBeSubmitted.AddressLine2 = contentForVerificationAddress.validateAddress.AddressLine2
                  payloadToBeSubmitted.CountryCode = contentForVerificationAddress.validateAddress.CountryCode
                  payloadToBeSubmitted.StateName = contentForVerificationAddress.validateAddress.StateName
                  payloadToBeSubmitted.City = contentForVerificationAddress.validateAddress.City
                  payloadToBeSubmitted.PostalCode = contentForVerificationAddress.validateAddress.PostalCode

               }
            }
            this.setState({ isShowAddressValidationModal: false, isInvalidAddressModal: false, payloadToBeSubmitted }, () => {
               if (isUpdateOrganizationExceptFourFields && contentForVerificationAddress?.isQueryForVerifyingAddress && cleansedStoreValue === "true" && formType === FORM_TYPE_ORG_UPDATE.Organization) {
                  this.setState({ payloadToBeSubmitted, isSubmitConfirm: true })
               }
               else if (cleansedStoreValue !== null) {
                  this.callCompanyAndMemberApi(checkCompanyPayload, payloadToBeSubmitted)
               }
            })
         } else {
            payloadToBeSubmitted.ForFinalUpdateAddress = true
            if (!cleansedStoreValue) {
               payloadToBeSubmitted.AddressLine1 = contentForVerificationAddress.validateAddress.AddressLine1
               payloadToBeSubmitted.AddressLine2 = contentForVerificationAddress.validateAddress.AddressLine2
               payloadToBeSubmitted.CountryCode = contentForVerificationAddress.validateAddress.CountryCode
               payloadToBeSubmitted.StateName = contentForVerificationAddress.validateAddress.StateName
               payloadToBeSubmitted.City = contentForVerificationAddress.validateAddress.City
               payloadToBeSubmitted.PostalCode = contentForVerificationAddress.validateAddress.PostalCode
            }
            payloadToBeSubmitted.ForFinalUpdateAddress = true
            this.setState({ payloadToBeSubmitted }, () => {
               this.updateOrgInfo(payloadToBeSubmitted)
            })
         }
      } else {
         this.setState({ isShowAddressValidationModal: false })
      }
   }

   closeInvalidModal = () => {
      this.setState({ isInvalidAddressModal: false })
   }

   handleAccountAssociationTypeChange = (e) => {
      this.setInvalidManualOrgMessage();
      this.props.change('manualOrderZpcd', '');
      this.props.change('manualOrgAccountNumber', "");
      this.setState({
         isInvalidAccount: false, 
         isInvalidOrgAccount: false,
         isInvalidManualOrgAccount: false,
         isAssociationSelected: false,
         commonOrgFormInitializeData: null,
          isAssociationSelectedValue: e && e.currentTarget.querySelector('input') && e.currentTarget.querySelector('input').value === 'yes' ? true : false,
          isAccountAssociationSelected: e && e.currentTarget.querySelector('input') && e.currentTarget.querySelector('input').value === 'no' ? true : false
      });
   }
   handle_GetCompanyDetails = (event) => {
      event.preventDefault();
      const orgCompanyType = '1';
      const facilityCompanyType = '2';
      const orgActiveStatus = 28;
      const facilityErrorInActiveReasonId = 24
      const {manualOrgAccountNumber} = this.state;
      this.setState({isInvalidAccount: false, isInvalidOrgAccount: false})
      if((!!this.props.initialValues.FacilityAccountNumber && manualOrgAccountNumber === this.props.initialValues.FacilityAccountNumber) || (!!this.props.initialValues.OrgAccountNumber && manualOrgAccountNumber === this.props.initialValues.OrgAccountNumber)){
         let errorCode = '9899';
         this.setState({isInvalidOrgAccount:true, isAccountAssociationSelected: false, errorMessage: getMessage(this.props.messageCodes, errorCode+'.text')})
      } else{
         this.props.getCompanyAssociatedDetailsAction(manualOrgAccountNumber, 0, (result) => {
            const { initialize  } = this.props;
            this.props.change('manualOrgAccountNumber', manualOrgAccountNumber);
            if(!result || result.length==0 || (result.AddressId===0 && result.CompanyId===0)){
               this.setState({isInvalidAccount:true, isAccountAssociationSelected: false})
            } else if(result && ((result.CompanyType === orgCompanyType && result.McsStatusMasterId !== orgActiveStatus) || (result.CompanyType === facilityCompanyType && result.InActiveReasonIds === facilityErrorInActiveReasonId))){
               let errorCode = this.props.initialValues.MemberTypeId === MembershipTypes.Student ? '9180' : '9181';
               this.setState({isInvalidOrgAccount:true, isAccountAssociationSelected: false, errorMessage: getMessage(this.props.messageCodes, errorCode+'.text')})
            }
            else{
            let matchedItem = this.props.originalActiveCountryList.find(i => i.CountryCode === result.CountryCode || i.CountryName === result.CountryName)
            this.setState({
               commonOrgFormInitializeData: {
                  manualOrderOrggNam: result.CompanyName,
                  manualOrderOrgAdrLine1: result.AddressLine1,
                  manualOrderOrgAdrLine2: result.AddressLine2,
                  manualOrderOrgCty: result.City,
                  orgCountry: matchedItem && matchedItem.CountryName ? matchedItem.CountryName : result.CountryCode,
                  manualOrderZpcd: result.PostalCode,
                  orgCountryCode: matchedItem && matchedItem.CountryName ? this.getCountryCode(matchedItem.CountryName) : result.CountryCode,
                  manualOrderstatpro: result.StateCode ? result.StateCode : result.StateName
               },
               isAccountAssociationSelected: true
            }, () => {
               initialize({
                  manualOrderOrggNam: result.CompanyName,
                  manualOrderOrgAdrLine1: result.AddressLine1,
                  manualOrderOrgAdrLine2: result.AddressLine2,
                  manualOrderOrgCty: result.City,
                  orgCountry: matchedItem && matchedItem.CountryName ? matchedItem.CountryName : result.CountryCode,
                  manualOrderZpcd: result.PostalCode,
                  orgCountryCode: matchedItem && matchedItem.CountryName ? this.getCountryCode(matchedItem.CountryName) : result.CountryCode,
                  manualOrderstatpro: result.StateCode ? result.StateCode : result.StateName,
                  manualOrgAccountNumber : manualOrgAccountNumber
               })
            });
            }
         })
      }
   }

   setCompanyTypeDetail = (filteredAddressList) => {
      this.setState({ companyType: filteredAddressList[0].CompanyType, mcsStatusMasterId: filteredAddressList[0].McsStatusMasterId  });
   }

   setInvalidManualOrgMessage = () => {
      const { isInvalidManualOrgAccount } = this.state;
      if(isInvalidManualOrgAccount){
         this.setState({ isInvalidManualOrgAccount: false  });
      }
      this.setState({ companyType: '', mcsStatusMasterId: 0  });
   }

   render() {
      const { isShowMemberList, memberList, isSubmitConfirm, isAlreadyExistsCompany, commonOrgFormInitializeData,
          oldCompanyDetails, openModal, alertMsg, isShowAddressValidationModal, contentForVerificationAddress, 
          isInvalidAddressModal, isAccountAssociationSelected, isAssociationSelected, isAssociationSelectedValue,
           manualOrgAccountNumber, isInvalidAccount, isInvalidOrgAccount, isInvalidManualOrgAccount, errorMessage } = this.state;
      const { handleSubmit, editType, formType, orgFormValues, messageCodes } = this.props;
      const {setCompanyTypeDetail, setInvalidManualOrgMessage} = this;
      const updateOrg = formType === FORM_TYPE_ORG_UPDATE.Organization;
      const firstRadioText = this.props.initialValues.MemberTypeId === MembershipTypes.Student ? organizationFacilityRadioText.firstRadioFacility : organizationFacilityRadioText.firstRadioMemOrg;
      const secondRadioText = this.props.initialValues.MemberTypeId === MembershipTypes.Student ? organizationFacilityRadioText.secondRadioFacility : organizationFacilityRadioText.secondRadioMemOrg;
      return (
         <>
            {openModal && 
               <Form data-testid="orgForm" onSubmit={handleSubmit(this.handle_UpdateFacility)}>
                  <Modal open='true' className="small addCommitttee" as={Form} data-testid="updateOrganizationInformation">
                     <Modal.Header>{updateOrg ? 'Update Organization' : 'Add/Update Organization'}<i className="close" onClick={this.handleCloseModal}>&#x2716;</i></Modal.Header>
                     <Modal.Content scrolling>
                        <Modal.Description style={{ 'min-height': editType === 'new' ? '70px' : '300px' }}>
                        {editType === 'new' && <div>
                        <RenderRadioField
                              name="accountAssociationType"
                              type="radio"
                              selectedValue={'yes'}
                              className="autocompleteOff"
                              onChange={this.handleAccountAssociationTypeChange}
                              options={[
                                 {
                                    value: 'yes',
                                    label: firstRadioText
                                       
                                 },
                                 {
                                    value: 'no',
                                    label: secondRadioText
                                 }
                              ]}
                           />
                           <br/>
                    </div> }
                    {editType === 'update' && 
                           <CommonFormOrganizationActivity
                              {...this.props}
                              isEditing={editType === 'update'}
                              postData={editType === 'update' ? commonOrgFormInitializeData : {}}
                              formData={getFormData(2, this)}
                              orgFormValues={orgFormValues}
                              isManualOrder={false}
                              isCompanyTypeNeeded={false}
                              setCompanyTypeDetail={setCompanyTypeDetail}
                              setInvalidManualOrgMessage={setInvalidManualOrgMessage}
                           />
                     }
                     {editType === 'new' && isAssociationSelectedValue &&
                     <div>
                        <Grid columns='equal' className="mt0">
                              <Grid.Row>
                                 <Grid.Column>
                                    <Field
                                          name="manualOrgAccountNumber"
                                          required={true}
                                          label={'Organization Account Number'}
                                          placeholder={'Enter organization account number'}
                                          component={RenderInputField}
                                          className="autocompleteOff"
                                          maxLength={60}
                                          onChange={(e, value) => { this.setState({ isAssociationSelected: value?true:false, isInvalidAccount: false, isInvalidOrgAccount: false, manualOrgAccountNumber: value }); }}
                                          value={manualOrgAccountNumber}
                                    />
                                 </Grid.Column>
                                 <Grid.Column className='btnGetOrgDetails'>
                                    <Button data-testid="btnGetOrgDetails" disabled={isAssociationSelected ? false : true} primary onClick={(e)=>this.handle_GetCompanyDetails(e)}>{'Get Details'}</Button>
                                 </Grid.Column>
                              </Grid.Row>
                        </Grid>
                        {isInvalidAccount &&<Grid columns='equal' className="mt0">
                              <Grid.Row>
                                 <Grid.Column>
                                 <p className='errorMessage'>The account number does not exist in the system. You can add the Organization details manually.</p>
                                 </Grid.Column>
                                 </Grid.Row>
                           </Grid>
                        }
                        {isInvalidOrgAccount &&<Grid columns='equal' className="mt0">
                              <Grid.Row>
                                 <Grid.Column>
                                 <p className='errorMessage'>{errorMessage}</p>
                                 </Grid.Column>
                                 </Grid.Row>
                           </Grid>
                        }
                     </div>

                     }
                     {isAccountAssociationSelected && !commonOrgFormInitializeData && 
                           <CommonFormOrganizationActivity
                              {...this.props}
                              isEditing={null}
                              postData={null}
                              formData={getFormData(2, this)}
                              orgFormValues={orgFormValues}
                              isManualOrder={false}
                              isCompanyTypeNeeded={true}
                              setCompanyTypeDetail={setCompanyTypeDetail}
                              setInvalidManualOrgMessage={setInvalidManualOrgMessage}
                           />
                     }
                     {isAccountAssociationSelected && commonOrgFormInitializeData && 
                           <CommonFormOrganizationActivity
                              {...this.props}
                              isEditing="true"
                              postData={commonOrgFormInitializeData}
                              formData={getFormData(2, this)}
                              orgFormValues={orgFormValues}
                              isManualOrder={false}
                              disabledForm={true}
                              isCompanyTypeNeeded={false}
                              setCompanyTypeDetail={setCompanyTypeDetail}
                              setInvalidManualOrgMessage={setInvalidManualOrgMessage}
                           />
                     }
                        </Modal.Description>
                     </Modal.Content>
                     <Modal.Actions>
                     {isInvalidManualOrgAccount &&<Grid columns='equal' className="mt0">
                              <Grid.Row>
                                 <Grid.Column>
                                 <p className='errorMessage'>{errorMessage}</p>
                                 </Grid.Column>
                                 </Grid.Row>
                           </Grid>
                        }
                        <div>
                           <Button data-testid="btnUpdate" primary style={{ 'display': (editType === 'new' && commonOrgFormInitializeData) || (editType === 'new' && !isAssociationSelectedValue) || editType === 'update' ? 'inline-block' : 'none' }} type='submit'>{editType === 'update' ? 'Update' : 'Save'}</Button>
                           <Button data-testid="btnCancel" secondary style={{ 'display': (editType === 'new' && commonOrgFormInitializeData) || (editType === 'new' && !isAssociationSelectedValue) || editType === 'update' ? 'inline-block' : 'none' }} className='cancel' onClick={this.handleCloseModal}>Cancel</Button>
                        </div>
                     </Modal.Actions>
                  </Modal>
               </Form>
            }
            <Confirm className="confirm-box"
               data-testid="confirm-box"
               size="tiny"
               open={isSubmitConfirm}
               content={(
                  <>
                     <p className="txtCenter">This update is going to happen for the Member Accounts who are associated with the facility <strong>{oldCompanyDetails?.CompanyName}</strong>. <a className="linkTxt vAuto" onClick={this.handleShowMemberList}>Click Here</a> to view the list of Members who are associated with the same facility.</p>
                     <p className="txtCenter" data-testid='confirmPara'>Are you sure you want to continue?</p>
                  </>
               )}
               cancelButton="No"
               confirmButton="Yes"
               onCancel={this.onCancelSubmitConfirm}
               onConfirm={this.submitPayload}
            />
            <Confirm className="confirm-box"
               size="tiny"
               open={isInvalidAddressModal}
               content={(
                  <>
                     <p className="txtCenter">{getMessage(messageCodes, '9177.text')}</p>
                  </>
               )}
               cancelButton="No"
               confirmButton="Yes"
               onCancel={this.closeInvalidModal}
               onConfirm={this.onCloseAddressVerificationModal}
            />
            {isShowAddressValidationModal && (
               <AddressVerificationConfirmPopup message={getMessage(messageCodes, '9178.text')} content={contentForVerificationAddress} closeModal={this.onCloseAddressVerificationModal} />
            )}
            {isShowMemberList &&
               <Modal open='true' className="tiny addCommitttee" data-testid="memberListAssociated">
                  <Modal.Header>List of members who are associated with same facility<i className="close" onClick={this.handleHideMemberList}>&#x2716;</i></Modal.Header>
                  <Modal.Content scrolling>
                     <Modal.Description>
                        <table className="customTable memHisTable">
                           <thead>
                              <tr>
                                 <TableHeader
                                    headerProps={[
                                       { 'title': 'Account Number', 'hasSorting': false },
                                       { 'title': 'Member Name', 'hasSorting': false },
                                       { 'title': 'Account Status', 'hasSorting': false },
                                    ]}
                                 />
                              </tr>
                           </thead>
                           <tbody>
                              {map(memberList, (item) => (
                                 <tr>
                                    <td>{item.accountNumber}</td>
                                    <td>{item.memberName}</td>
                                    <td>{item.accountStatus}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </Modal.Description>
                  </Modal.Content>
               </Modal>
            }
            <Confirm className={updateOrg ? "confirm-box noCancelBtn" : "confirm-box"}
               size="tiny"
               open={isAlreadyExistsCompany}
               content={(
                  <>
                     <p className="txtCenter">{alertMsg}</p>
                  </>
               )}
               cancelButton="No"
               confirmButton={updateOrg ? "Ok" : "Yes"}
               onCancel={this.handleHideAssociationConfirmationPopup}
               onConfirm={updateOrg ? this.handleHideAssociationConfirmationPopup : this.associateWithExistingCompany}
            />
         </>
      );
   }
}

const UpdateOrgInformationComponent = (props) => {
   const flagFeature = useFlagFeatures();
   return <UpdateOrgInformation {...props} flagFeature={flagFeature}></UpdateOrgInformation>
};

export default UpdateOrgInformationComponent;


