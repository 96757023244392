
let self = {};

export const setClassInstance = (instance) => {
    self = instance;
}

export const closeAccountNumberNullConfirmationHandler = () => {
    self.setState({
        isOpenAccountNumberNullConfirmation: false
    });
}

export const loadData = (accountNumber, companyId = 0) => {
    if (self.props.membershipTypeList && self.props.membershipTypeList.length === 0) {
        self.props.getMembershipTypeList();
    }

    if (!self.props.organizationUserList || !self.props.historicalFacilityReasonList || !self.props.historicalReasonList || !self.props.organizationAuditLogFields) {
        self.props.getOrganizationMasterData();
    }

    if (!self.props.organizationRepHistoryList) {
        self.props.getRepresentativeHistory(accountNumber, companyId);
    }

    self.props.getOrganizationDetails(accountNumber, companyId);
    self.props.getMemberMasterData(accountNumber);
}
