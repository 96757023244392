import asyncComponent from 'asyncComponent';
import Home from 'layout/layout.container';
// Components
import Login from 'modules/login/login.container';
import React, { Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import CustomLoader from 'shared-components/Loader';
import MemberRoutes from 'chunks/member.chunk';
import CommitteeRoutes from 'chunks/committee.chunk';
import GenerateInvoice from 'modules/reports/components/membership-invoice-receipt/generate-invoice'
// Lazy loaded components goes here.
const UserRoutes = asyncComponent(() => {
    return import('chunks/user.chunk')
});

const WorkItemAdminRoutes = asyncComponent(() => {
    return import('chunks/work-item-admin.chunk')
});

const BallotAdminRoutes = asyncComponent(() => {
    return import('chunks/ballot-admin.chunk')
});

const MembershipReports = asyncComponent(() => {
    return import('chunks/membership-reports.chunk')
});

const MeetingReports = asyncComponent(() => {
    return import('chunks/meeting-reports.chunk')
});

const RenewalReports = asyncComponent(() => {
    return import('chunks/renewal-reports.chunk')
});

export const AppRoutes = ({ defaultUrl, isLoginByAzureAD, ...props }) => (
    <Suspense fallback={CustomLoader}>
        <Switch>
            {defaultUrl && <Redirect exact from="/" to={defaultUrl} />}
            {!isLoginByAzureAD && <Route path='/login' component={Login} />}
            <Route path='/generate/:type(invoice|receipt)/:stock(stock)?' exact component={GenerateInvoice} {...props} isLoginByAzureAD={isLoginByAzureAD}/>
            <Home {...props} isLoginByAzureAD={isLoginByAzureAD}>
                <div className="App">
                    <Route path='/renewal-tasks' component={UserRoutes} />
                    <Route path='/admin' component={UserRoutes} />
                    <Route path='/user' component={UserRoutes} />
                    <Route path='/roles' component={UserRoutes} />
                    <Route path='/member-management' component={MemberRoutes} />
                    <Route path='/committee-management' component={CommitteeRoutes} />
                    <Route path='/work-item-admin' component={WorkItemAdminRoutes} />
                    <Route path='/ballot-admin' component={BallotAdminRoutes} />
                    <Route path='/membership-reports' component={MembershipReports} />
                    <Route path='/meeting-reports' component={MeetingReports} />
                    <Route path='/renewal-reports' component={RenewalReports} />

                </div>
            </Home>
        </Switch>
    </Suspense>
)

export default AppRoutes;
