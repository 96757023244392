import { getListItemTextById, getMessage } from 'helpers/util-common';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal } from 'semantic-ui-react';
import RenderSelect from 'shared-components/Select';
import { MonthOptionsWithSelect } from '../../committee.model';

const validateHandler = (values, props) => {
    let errors = {};

    if (!values.Month) {
        errors.Month = getMessage(props.messageCodes, '4018.text');
    }
    if (!values.MeetingType) {
        errors.MeetingType = getMessage(props.messageCodes, '4019.text');
    }

    return errors;
}

class AddCommitteeMeetingSequenceModal extends PureComponent {

    onSubmitHandler = (data) => {

        let meetingTypeName = getListItemTextById(this.props.activeMeetingTypeList, data.MeetingType, 'text');

        let requestData = {
            CommitteeId: this.props.initialValues.CommitteeId,
            Month: data.Month,
            MeetingTypeId: data.MeetingType,
            MeetingTypeName: meetingTypeName
        }

        this.props.addCommitteeMeetingSequence(requestData, () => {
            // Refresh the data
            this.props.onSubmit();
        })
    }

    render() {
        const { handleSubmit, submitting, onClose, activeMeetingTypeList } = this.props;

        return (
            <Modal open='true' className="tiny addCommitttee">
                <Form size='large' onSubmit={handleSubmit(this.onSubmitHandler)}>

                    <Modal.Header>ADD MEETING SEQUENCE <i className="close" data-testid="closeButton" onClick={onClose}>&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description style={{ 'height': '170px' }}>
                            <Grid columns='2'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="Month" type="text" required={true}
                                            component={RenderSelect} label="Month"
                                            placeholder="Select"
                                            options={MonthOptionsWithSelect}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name="MeetingType" type="text" required={true}
                                            component={RenderSelect} label="Meeting Type"
                                            placeholder="Select"
                                            options={activeMeetingTypeList}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" disabled={submitting} type="submit">Save</button>
                            <button className="ui button cancel" onClick={onClose}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'AddCommitteeMeetingSequenceModal',
    validate: validateHandler
})(AddCommitteeMeetingSequenceModal);
