import * as _action from 'action-types';
import * as utilCommon from 'helpers/util-common';
import { isEmpty } from 'lodash';
import { decrypt, deepCopy } from '../../../helpers/util-common';
import { AutoRenewal, StatusFilter, PaidStatus, FeeGroupTableColumns, feeGroupSearchType } from './feegroups.model';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
    loadInitialData();
}

export const loadInitialData = (callApi = true, isCallFromOnChangeEvent = false) => {
        const { search : searchText } = self.props.location
        const searchValue = searchText.substring(1);
        const convertParamsIntoObject = QueryStringToJSON(searchValue)
        const checkIsAdvanceSearchKey = 'isAdvanceSearch' in convertParamsIntoObject
        if (callApi) {
            self.props.getFeeGroupList((response) => {
                self.setState({ feeGroupAllList: response, afterSearchStoreDataForFiltering: response}, () => {
                    if(isCallFromOnChangeEvent){
                        if(checkIsAdvanceSearchKey && Boolean(convertParamsIntoObject?.isAdvanceSearch) === true){
                            basicSearchFilter(self.state.afterSearchStoreDataForFiltering, searchText, true)
                        }else{
                            basicSearchFilter(self.state.feeGroupAllList, searchText)
                        }
                    }else if(checkIsAdvanceSearchKey && Boolean(convertParamsIntoObject?.isAdvanceSearch) === true){
                        handleAdvanceSearchUpdate(searchText, convertParamsIntoObject)
                    }else{
                        basicSearchFilter(self.state.feeGroupAllList, self.state.searchText)
                    }
                });
            });
        }else if(isCallFromOnChangeEvent){
            if(checkIsAdvanceSearchKey && Boolean(convertParamsIntoObject?.isAdvanceSearch) === true){
                basicSearchFilter(self.state.afterSearchStoreDataForFiltering, searchText, true)
            }else{
                basicSearchFilter(self.state.feeGroupAllList, searchText)
            }
        }
        else if(checkIsAdvanceSearchKey && Boolean(convertParamsIntoObject?.isAdvanceSearch) === true){
            handleAdvanceSearchUpdate(searchText, convertParamsIntoObject)
        }else{
            basicSearchFilter(self.state.feeGroupAllList, searchText)
        }
        self.setState({ feeGroupTableHeaders: deepCopy(FeeGroupTableColumns) });
};

const basicSearchFilter = (feeGroupAllList, selectedSearchText, isCallingFilterForAdvanceData = false) => {
    self.setState({ searchType: feeGroupSearchType.basic })
    if (feeGroupAllList && feeGroupAllList.length > 0 && selectedSearchText && selectedSearchText.length > 0 && !Boolean(isCallingFilterForAdvanceData)) {
            let SearchTextValue = ''
            if(selectedSearchText !== self.state.searchText){
                const searchText = selectedSearchText ? decrypt(selectedSearchText.substring(12)) : '';
                self.setState({ searchText});
                SearchTextValue = searchText
            }else{
                SearchTextValue = selectedSearchText
            }
        const list = feeGroupAllList.filter((item) => {
            const feeGroupTitle = item.FeeGroupTitle === null ? false : item.FeeGroupTitle?.toLowerCase().includes(SearchTextValue.toLowerCase());
            const feeGroupNumber = item.FeeGroupNumber?.toLowerCase().includes(SearchTextValue.toLowerCase());
            const contactName = item.ContactPersonName === null ? false : item.ContactPersonName?.toLowerCase().includes(SearchTextValue.toLowerCase());
            return feeGroupTitle || feeGroupNumber || contactName
        })
        filteredFeeGroupList(list);
    } else {
        filteredFeeGroupList(feeGroupAllList);
    }
}

export const handleSearchUpdate = (nextProps) => {
    const { currentSearchType, currentModuleTitle } = nextProps;
    if (nextProps.location.search !== self.props.location.search && currentSearchType === _action.SEARCH_FEEGROUP) {
        const searchValue = nextProps.location.search.length > 0 ? nextProps.location.search.substring(1) : '';
        const convertParamsIntoObject = QueryStringToJSON(searchValue)
        const checkIsAdvanceSearchKey = 'isAdvanceSearch' in convertParamsIntoObject
        if(checkIsAdvanceSearchKey && Boolean(convertParamsIntoObject?.isAdvanceSearch) === true){
            handleAdvanceSearchUpdate(nextProps.location.search, convertParamsIntoObject)
        }else{
            const searchText = nextProps.location.search ? decrypt(nextProps.location.search.substring(12)) : '';
            const selectedStatus = nextProps.location.search ? StatusFilter[0].value : StatusFilter[1].value;
            self.setState({ searchText, selectedStatus, selectedAutoRenewal: AutoRenewal[0].value, selectedPaidStatus: PaidStatus[0].value, isAdvanceSearch: false, showList: true });
            setTimeout(() => {
                basicSearchFilter(self.state.feeGroupAllList, searchText)
            }, 300);
        }
    }
}

const checkVal = (value) => {
    let flag = false;
    if (value && value !== undefined && value !== null && value !== '') {
        flag = true;
    }
    return flag;
}

const sorting = (list, sortKey, orderType) => {
    if (sortKey === 'AssociatedMembers') {
        if (orderType === '0') {
            list.sort((a, b) => a[sortKey] - b[sortKey]);
        } else {
            list.sort((a, b) => b[sortKey] - a[sortKey]);
        }
    } else if (sortKey !== 'AssociatedMembers') {
        if (orderType === "0") {
            list.sort((a, b) => (a[sortKey] === null ? "" : a[sortKey].toString().toLowerCase()).localeCompare((b[sortKey] === null ? "" : b[sortKey].toString().toLowerCase())));

        }
        else {
            list.sort((b, a) => (a[sortKey] === null ? "" : a[sortKey].toString().toLowerCase()).localeCompare((b[sortKey] === null ? "" : b[sortKey].toString().toLowerCase())));
        }
    }

    return list;
}

const filteredFeeGroupList = (listFeeGroup) => {
    let count = 0;
    let orderType = self.state.orderType;
    let sortKey = self.state.sortKey;
    const { pageSize, pageNumber } = self.state;
    let feeGroupList = (listFeeGroup && listFeeGroup.length >= 0) && listFeeGroup.filter((listFeeGroupItem) => {
        if (
            (self.state.selectedStatus.toLowerCase() ==
                StatusFilter[0].value.toLowerCase()
                ? true
                : listFeeGroupItem.Status.toLowerCase() ==
                self.state.selectedStatus.toLowerCase()) &&
            (self.state.selectedAutoRenewal.toLowerCase() ==
                AutoRenewal[0].value.toLowerCase()
                ? true
                : listFeeGroupItem.IsAutoRenewal.toLowerCase() ==
                self.state.selectedAutoRenewal.toLowerCase()) &&
            (self.state.selectedPaidStatus.toLowerCase() ==
                PaidStatus[0].value.toLowerCase()
                ? true
                : listFeeGroupItem.PaidStatus.toLowerCase() ==
                self.state.selectedPaidStatus.toLowerCase())
        ) {
            return true;
        }
        return false;
    });

    if (feeGroupList && feeGroupList.length > 0 && checkVal(orderType) && checkVal(sortKey)) {
        sorting(feeGroupList, sortKey, orderType)
    }
    count = feeGroupList ? feeGroupList.length : 0;
    updateCurrentlyShowingItems(count, pageSize, pageNumber);
    self.setState({ feeGroupList, totalItems: count, activePageNumber: 1 });
}

const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = utilCommon.getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
    self.setState({ currentlyShowingItems, totalPages });
}

export const onPageChangeHandler = (event, data) => {
    let { totalPages, activePageNumber, feeGroupList, pageSize } = self.state;

    if (totalPages > 1) {
        activePageNumber = data.activePage;
    }
    updateCurrentlyShowingItems(feeGroupList.length || 0, pageSize, activePageNumber);
    self.setState({ activePageNumber });
};
export const onChangeNumberOfItemsPerPage = (e, value) => {
    let { pageSize, pageNumber, feeGroupList } = self.state;
    pageSize = value;
    pageNumber = 1;

    self.setState({ activePageNumber: 1, pageSize });
    updateCurrentlyShowingItems(feeGroupList.length || 0, pageSize, pageNumber);
};


export const openAddFeeGroup = (isListReload = false) => {
    let showList = !self.state.showList;
    self.props.resetFeeGroup();

    self.setState({ showList });
    if (isListReload) {
        loadInitialData(true);
    }
}

export const onChangeStatus = (e, value) => {
    self.setState({ selectedStatus: value }, () => {
        loadInitialData(false, true)
    })
};

export const onChangeAutoRenewal = (e, value) => {
    self.setState({ selectedAutoRenewal: value }, () => {
        loadInitialData(false, true)
    })
};

const QueryStringToJSON = (queryString) => {
    let pairs = queryString.slice(1).split('&');
    let result = {};
    pairs.forEach((pair) => {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}

export const loadData = (pageNumber, pageSize, searchText, convertParamsIntoObject, showPageLoader = false, firstLoad = false) => {
    const { feeGroupAllList } = self.state
    if (!showPageLoader) {
        self.setState({ showLoader: true });
    }
    let updateStateVariable = {}
    let advanceSearchFeeGroupArray = feeGroupAllList && feeGroupAllList.length > 0 ? feeGroupAllList : []
    if(!isEmpty(convertParamsIntoObject?.feeGroupNumber) || convertParamsIntoObject?.feeGroupNumber !== undefined){
        const searchTextValue = utilCommon.decrypt(convertParamsIntoObject.feeGroupNumber);
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.FeeGroupNumber.includes(searchTextValue));
        advanceSearchFeeGroupArray = feeGroupFilterValue

    }

    if(!isEmpty(convertParamsIntoObject?.feeGroupTitle) || convertParamsIntoObject?.feeGroupTitle !== undefined){
        const searchTextValue = utilCommon.decrypt(convertParamsIntoObject.feeGroupTitle);
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.FeeGroupTitle.includes(searchTextValue));
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(convertParamsIntoObject.paidStatus !== 'All'){
        const searchTextValue = convertParamsIntoObject.paidStatus;
        updateStateVariable.selectedPaidStatus = searchTextValue
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.PaidStatus === searchTextValue);
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(convertParamsIntoObject.status !== 'All'){
        const searchTextValue = convertParamsIntoObject.status;
        updateStateVariable.selectedStatus = searchTextValue
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.Status === searchTextValue);
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(convertParamsIntoObject.autoRenewal !== 'All'){
        const searchTextValue = convertParamsIntoObject.autoRenewal;
        updateStateVariable.selectedAutoRenewal = searchTextValue
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.IsAutoRenewal.includes(searchTextValue));
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(convertParamsIntoObject.country !== undefined && convertParamsIntoObject.country !== 'All' ){
        const searchTextValue = convertParamsIntoObject.country;
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.Country === searchTextValue);
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(!isEmpty(convertParamsIntoObject?.contactName) || convertParamsIntoObject?.contactName !== undefined){
        const searchTextValue = utilCommon.decrypt(convertParamsIntoObject.contactName);
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.ContactPersonName?.includes(searchTextValue));
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(!isEmpty(convertParamsIntoObject?.associatedMemberAccountNo) || convertParamsIntoObject?.associatedMemberAccountNo !== undefined){
        const searchTextValue = utilCommon.decrypt(convertParamsIntoObject.associatedMemberAccountNo);
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.Members.some(member => member.AccountNumber?.includes(searchTextValue)));
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(!isEmpty(convertParamsIntoObject?.associatedMemberFirstName) || convertParamsIntoObject?.associatedMemberFirstName !== undefined){
        const searchTextValue = utilCommon.decrypt(convertParamsIntoObject.associatedMemberFirstName);
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.Members.some(member => member.FirstName?.includes(searchTextValue)));
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }

    if(!isEmpty(convertParamsIntoObject?.associatedMemberLastName) || convertParamsIntoObject?.associatedMemberLastName !== undefined){
        const searchTextValue = utilCommon.decrypt(convertParamsIntoObject.associatedMemberLastName);
        const feeGroupFilterValue = advanceSearchFeeGroupArray.filter(feeGroup => feeGroup.Members.some(member => member.LastName?.includes(searchTextValue)));
        advanceSearchFeeGroupArray = feeGroupFilterValue
    }
    self.setState({
        selectedStatus : convertParamsIntoObject.status !== StatusFilter[0].value ? updateStateVariable.selectedStatus : StatusFilter[0].value,
        selectedAutoRenewal: convertParamsIntoObject.autoRenewal !== AutoRenewal[0].value ? updateStateVariable.selectedAutoRenewal : AutoRenewal[0].value,
        selectedPaidStatus: convertParamsIntoObject.paidStatus !== PaidStatus[0].value ? updateStateVariable.selectedPaidStatus : PaidStatus[0].value
    }, () => {
        self.setState({ feeGroupList: advanceSearchFeeGroupArray, afterSearchStoreDataForFiltering  : advanceSearchFeeGroupArray }, () => {
            filteredFeeGroupList(advanceSearchFeeGroupArray);
        });
    })
}

export const handleAdvanceSearchUpdate = (searchString, convertParamsIntoObject) => {
    const { pageSize } = self.state;
    const searchText = searchString.length > 0 ? searchString.substring(1) : '';

    self.setState({
        searchText,
        activePageNumber: 1,
        isAdvanceSearch: true,
        searchType: feeGroupSearchType.advance,
        showList: true
    });

    loadData(1, pageSize, searchText, convertParamsIntoObject );
}

export const onChangePaidStatus = (e, value) => {
    self.setState({ selectedPaidStatus: value }, () => {
        loadInitialData(false, true)
    })
};

export const handleSorting = (event, sortKey) => {
    let orderType = utilCommon.getSortOrderFromHeaderClick(event);
    const feeGroupList = self.state.feeGroupList;
    if (feeGroupList && feeGroupList.length > 0) {
        sorting(feeGroupList, sortKey, orderType)
    }
    self.setState({
        feeGroupList,
        sortKey,
        orderType
    })
}

export const getFeeGroupExportExcel = () => {
    self.setState({ loading: true });
    const exportedData = [];
    if (self.state.feeGroupList && self.state.feeGroupList.length > 0) {
        self.state.feeGroupList.forEach((item) => {
            exportedData.push({
                "FeeGroupNumber": item.FeeGroupNumber,
                "FeeGroupTitle": item.FeeGroupTitle,
                "ContactName": item.ContactPersonName === null ? '' : item.ContactPersonName,
                "AssociatedMembers": item.AssociatedMembers,
                "PaidStatus": item.PaidStatus,
                "Status": item.Status
            })
        })
        self.setState({ isExportExcel: true, exportedData, loading: false }, () => resetExcelDownload());
    } else {
        self.setState({ isOpenExcelDownloadConfirmBox: true, exportedData: [], loading: false });
    }
}

const resetExcelDownload = () => {
    setTimeout(() => {
        self.setState({ isExportExcel: false });
    }, 100);
}

export const emptyExportDataCancelHandler = () => {
    self.setState({ isOpenExcelDownloadConfirmBox: false });
}

export const emptyExportDataConfirmHandler = () => {
    self.setState({ isExportExcel: true, isOpenExcelDownloadConfirmBox: false }, () => { resetExcelDownload() });
}
