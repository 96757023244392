
import { AlphaNumeric_Regex, checkIfDateValid, compareDates, compareTwoLists, compareTwoTextFields, getMessage } from 'helpers/util-common';
import { DegreeOptions, MembershipTypes } from '../../members.model';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;

    // Set the default value to Select for Degree
    if (!self.props.initialValues.Degree) {
        self.props.change('Degree', DegreeOptions[0].value);
    }
    bindFeeGroupList();
}

const bindFeeGroupList = () => {
    self.props.getActiveFeeGroupList(feeGroupListData => {
        if (feeGroupListData && feeGroupListData.length > 0) {
            let data = [{ key: 0, value: 0, text: 'Select' }];
            feeGroupListData.map(item => { data.push({ key: item.FeeGroupId, value: item.FeeGroupId, text: item.FeeGroupTitle }) })
            self.setState({ feeGroupList: data })
        }
    });
}

// Validation method for validate all value as per requirement
export const validateHandler = (values, props) => {
    const errors = {};

    if (values.MemberTypeId === MembershipTypes.MouMember && values.MOUContactCodeId === 0) {
        errors.MOUContactCodeId = getMessage(props.messageCodes, '8120.text');
    }

    if ((!values.UpdateReason) || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    if (values.GovtDunsAct && !AlphaNumeric_Regex.test(values.GovtDunsAct)) {
        errors.GovtDunsAct = getMessage(props.messageCodes, '4001.text');
    }

    if (!values.GraduationDate) {
        errors.GraduationDate = getMessage(props.messageCodes, '8122.text');
    } else if (values.GraduationDate.length < 7 || !checkIfDateValid(values.GraduationDate, 'MM/YYYY')) {
        errors.GraduationDate = getMessage(props.messageCodes, '4003.text');
    } else {
        let year = Number(values.GraduationDate.substring(3));
        if (isNaN(year)) {
            errors.GraduationDate = getMessage(props.messageCodes, '4003.text');
        } else if (compareDates(new Date(), values.GraduationDate, false, 'MM/YYYY')) {
            errors.GraduationDate = getMessage(props.messageCodes, '4004.text');
        }
    }

    return errors;
}

export const onSubmitHandler = (data) => {
    const { initialValues } = self.props;
    const { isAutoRenewal, isSupressFeeRenewalEmail, isSupressFeeRenewalPrint } = self.state;

    let listForUpdatedFields = [];

    data.Degree = data.Degree === 'Select' ? '' : data.Degree;
    listForUpdatedFields.push(data.IsProfileMember !== initialValues.IsProfileMember);
    listForUpdatedFields.push(compareTwoTextFields(data.FacilityName, initialValues.FacilityName));
    listForUpdatedFields.push(data.GovernmentTypeId !== initialValues.GovernmentTypeId);
    listForUpdatedFields.push(compareTwoTextFields(data.GovtDunsAct, initialValues.GovtDunsAct));
    listForUpdatedFields.push(compareTwoTextFields(data.StudyField, initialValues.StudyField));
    listForUpdatedFields.push(data.GraduationDate !== initialValues.GraduationDate);
    listForUpdatedFields.push(data.Degree !== initialValues.Degree);
    listForUpdatedFields.push(compareTwoLists(data.InterestedCommittee, initialValues.InterestedCommittee));
    listForUpdatedFields.push(compareTwoTextFields(data.HearaboutStudentMembership, initialValues.HearaboutStudentMembership));
    listForUpdatedFields.push(isAutoRenewal !== initialValues.IsAutoRenewal);
    listForUpdatedFields.push(isSupressFeeRenewalEmail !== initialValues.IsSupressFeeRenewalEmail);
    listForUpdatedFields.push(isSupressFeeRenewalPrint !== initialValues.IsSupressFeeRenewalPrint);
    listForUpdatedFields.push(data.OptMailingList !== initialValues.OptMailingList);
    listForUpdatedFields.push(data.FeeGroupId !== initialValues.FeeGroupId);

    // Send API only in case user has updated atleast one prop
    if (listForUpdatedFields.indexOf(true) >= 0) {
        let requestData = {
            AccountNumber: data.AccountNumber,
            IsProfileMember: data.MemberTypeId !== MembershipTypes.Student ? data.IsProfileMember : false,
            MemberTypeId: data.MemberTypeId,
            FacilityName: data.FacilityName ? data.FacilityName.trim() : '',
            GovernmentTypeId: data.MemberTypeId !== MembershipTypes.Student ? data.GovernmentTypeId : 0,
            GovtDunsAct: data.MemberTypeId !== MembershipTypes.Student ? data.GovtDunsAct : '',
            StudyField: data.StudyField ? data.StudyField.trim() : '',
            GraduationDate: data.MemberTypeId === MembershipTypes.Student ? data.GraduationDate : '',
            Degree: data.MemberTypeId === MembershipTypes.Student ? data.Degree : '',
            InterestedCommittee: data.MemberTypeId === MembershipTypes.Student ? formatInterestedCommitteeList(data.InterestedCommittee) : '',
            HearaboutStudentMembership: data.HearaboutStudentMembership ? data.HearaboutStudentMembership.trim() : '',
            IsAutoRenewal: data.MemberTypeId !== MembershipTypes.Student ? isAutoRenewal : false,
            IsSupressFeeRenewalEmail: data.MemberTypeId !== MembershipTypes.Student ? isSupressFeeRenewalEmail : false,
            IsSupressFeeRenewalPrint: data.MemberTypeId !== MembershipTypes.Student ? isSupressFeeRenewalPrint : false,
            UpdateReason: data.UpdateReason,
            FeeGroupId: self.state.selectedFeeGroupId
        };

        self.props.updateMemberDetails(requestData, () => {
            self.props.getMemberDetails(data.AccountNumber);
            self.props.onCloseEditGenInfo();
        })
    } else {
        self.props.displayNoChangesMadeMessage();
        self.props.onCloseEditGenInfo();
    }
}

// Private functions
const formatInterestedCommitteeList = (list) => {
    if (list && list.length > 0) {
        return list.join(',');
    }
    return '';
}

export const changeFeeGroupHandler = (e, value) => {
    self.setState({ selectedFeeGroupId: value }, () => {

        if ((self.state.selectedFeeGroupId > 0)) {
            self.props.getFeeGroupTabDetail(self.state.selectedFeeGroupId, (feeGroupTabDetailResponse) => {
                const { IsAutoRenewal, IsSuppressFeeRenewalEmail, IsSuppressFeeRenewalPrint } = feeGroupTabDetailResponse;

                self.setState({
                    isAutoRenewal: IsAutoRenewal || false,
                    isSupressFeeRenewalEmail: IsSuppressFeeRenewalEmail || false,
                    isSupressFeeRenewalPrint: IsSuppressFeeRenewalPrint || false
                });
            });
        } else {
            self.props.getMembershipTypeInfoByMemberTypeId(self.props.initialValues.MemberTypeId, (membershipTypeResponse) => {
                const { AutoRenewalApplicable, IsSuppressFeeRenewalEmails, IsSuppressFeeRenewalPrint } = membershipTypeResponse;

                self.setState({
                    isAutoRenewal: AutoRenewalApplicable || false,
                    isSupressFeeRenewalEmail: IsSuppressFeeRenewalEmails || false,
                    isSupressFeeRenewalPrint: IsSuppressFeeRenewalPrint || false
                });
            });
        }
    });
};

export const checkIsEditable = () => {
    return (self.state.selectedFeeGroupId === 0);
};
