import { getSearchAllGlobalDropDownOptions } from 'helpers/util-common';
import lodash from 'lodash';
import React, { PureComponent } from 'react';
import { Button, Icon, Input, Select } from 'semantic-ui-react';
import AdvanceSearchPopOver from '../AdvanceSearchPopOver';

class SearchBox extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentSearchOption: props.currentSearchOption,
            currentSearchText: props.currentSearchText,
            showAdvanceSearchPopOver: false,
            searchOptions: [],
            resetSearchQuery: ''
        }

        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.onAdvanceSearchSubmit = this.onAdvanceSearchSubmit.bind(this);
        this.toggleAdvanceSearchVisibility = this.toggleAdvanceSearchVisibility.bind(this);
    }

    setUpInitialSearchState = () => {
        let { currentSearchOption } = this.state;
        let searchOptions = getSearchAllGlobalDropDownOptions();
        searchOptions = lodash.uniqBy(searchOptions, result => result.value)
        if (searchOptions && searchOptions.length > 0) {
            let index = searchOptions.findIndex(option => option.value === currentSearchOption);
            if (index === -1) {
                currentSearchOption = searchOptions[0].value;
            }
        }

        this.setState({ searchOptions, currentSearchOption });
    }

    componentWillMount() {
        this.setUpInitialSearchState();
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentSearchOption !== prevProps.currentSearchOption) {
            this.setState({
                currentSearchOption: this.props.currentSearchOption,
                currentSearchText: this.props.currentSearchText
            });
        }

        if (this.props.shouldClearSearchText) {
            this.setState({
                currentSearchText: ''
            });
        }
    }

    // When Search option is updated, clear the search text
    updateSearchSelection = (value) => {
        this.setState({
            currentSearchOption: value,
            currentSearchText: ''
        })
    }

    handleSearchInputChange = (ev) => {
        this.setState({
            currentSearchText: ev.currentTarget.value
        });
    }

    // Handle the Enter key on Search input
    handleSearchInputKeyPress = (ev) => {
        if (ev.key === "Enter") {
            this.onSearchSubmit();
        }
    }

    onSearchSubmit() {
        const { currentSearchOption, currentSearchText } = this.state;
        this.props.onSearchSubmit(currentSearchOption, currentSearchText.trim());
    }

    toggleAdvanceSearchVisibility(e) {
        e && e.stopPropagation();
        const { showAdvanceSearchPopOver, resetSearchQuery, currentSearchOption } = this.state;

        if (showAdvanceSearchPopOver && resetSearchQuery.length > 0) {
            this.props.onAdvanceSearchSubmit(currentSearchOption, resetSearchQuery);
        }

        this.setState({ showAdvanceSearchPopOver: !showAdvanceSearchPopOver, resetSearchQuery: '' });
    }

    onAdvanceSearchSubmit(query) {
        this.setState({ showAdvanceSearchPopOver: false, resetSearchQuery: '' });
        this.props.onAdvanceSearchSubmit(this.state.currentSearchOption, query);
    }

    onResetClick = (query) => {
        this.setState({ resetSearchQuery: query });
    }

    render() {

        const { showAdvanceSearchPopOver, currentSearchText, searchOptions, currentSearchOption } = this.state;

        if (searchOptions && searchOptions.length > 0) {
            let index = searchOptions.findIndex(option => option.value === currentSearchOption);
            const placeholder = lodash(searchOptions[index]).get('placeholder');

            return (
                <div className="action" data-testid="advSearchContainer">

                    <Select compact options={searchOptions} value={currentSearchOption}
                        onChange={(ev, { value }) => this.updateSearchSelection(value)} />
                    <Input placeholder={placeholder} className="searchInput" data-testid='gSearchBox'
                        value={currentSearchText} onChange={this.handleSearchInputChange}
                        onKeyPress={this.handleSearchInputKeyPress} title={placeholder} />

                    <i data-testid='btnOpenAdvSearch' aria-hidden="true" className={showAdvanceSearchPopOver ? 'dropdown icon openAdvSearch active' : 'dropdown icon openAdvSearch'} onClick={this.toggleAdvanceSearchVisibility}></i>

                    <Button data-testid='btnGSearch' className="primary" onClick={this.onSearchSubmit}>
                        <Icon big name='search' />
                    </Button>

                    {showAdvanceSearchPopOver && (
                        <AdvanceSearchPopOver {...this.props} currentSearchOption={currentSearchOption}
                            onAdvanceSearchSubmit={this.onAdvanceSearchSubmit} closeAdvanceSeach={this.toggleAdvanceSearchVisibility}
                            onResetClick={this.onResetClick} />
                    )}

                </div>
            )
        }

        return '';
    }
};

export default SearchBox;
