import * as utilCommon from 'helpers/util-common';

let self = {};
let accountNo = '';
export const setClassInstance = (instance) => {
    self = instance;
    accountNo = self.props.initialValues.AccountNumber;
}
export const validateHandler = (values, props) => {
    const errors = {};
    if (!values.UpdateReason || values.UpdateReason.trim().length === 0) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text');
    }
    return errors
}
export const postMemberCommittees = (values) => {
    let memberSummaryModel = self.props.memberSummaryModel;
    memberSummaryModel.CommitteeIds = [...new Set(memberSummaryModel.CommitteeIds)]
    memberSummaryModel.UpdateReason = values.UpdateReason;
    memberSummaryModel.AccountNo = self.props.initialValues.AccountNumber;
    memberSummaryModel.AccountNumber = self.props.initialValues.AccountNumber;
    memberSummaryModel.MemberId = self.props.initialValues.MemberId;
    memberSummaryModel.OfficerTitleId = null;
    memberSummaryModel.Status = null;
    self.props.updateMemberCommittees(memberSummaryModel, () => {
        self.props.getMemberDetails(accountNo);
        self.props.onClose('', 2);
    });
}