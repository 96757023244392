import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import * as common from 'helpers/util-common';


const getOrganizationListAction = (query, callback, dispatch, isLoader) => {

    let getOrganizationList = getApiUrl('manageMembers', 'getOrganizationList');
    let getMemberTableConfiguration = getApiUrl('userPermission', 'getGridUserPreferences') + '/2';
    if (query.length > 0) {
        getOrganizationList = `${getOrganizationList}/${query}`;
        common.cacheManager.setItem('exportedParams', query);
    }
    if (isLoader) {
        dispatch(commonActions.setLoader(true));
    }
    Promise.all([axios.get(getOrganizationList), axios.get(getMemberTableConfiguration)])
        .then(([getOrganizationListResponse, getOrganizationTableConfigurationResponse]) => {
            if (isLoader) {
                dispatch(commonActions.setLoader(false));
            }
            if (getOrganizationListResponse.data.status || getOrganizationTableConfigurationResponse.data.status) {
                callback(getOrganizationListResponse.data.content, getOrganizationTableConfigurationResponse.data.content);
            }
            if (!getOrganizationListResponse.data.status) {
                dispatch(commonActions.setMessage(true, getOrganizationListResponse.data.message));
            }
            if (!getOrganizationTableConfigurationResponse.data.status) {
                dispatch(commonActions.setMessage(true, getOrganizationTableConfigurationResponse.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3043'));
        });
}

// Auto search Organization name and representative name : => 1 : Org , 2 : Rep
const getAutoSearchFilterAction = (name, type = 1, callback, dispatch) => {
    let url = null;
    // Validate the name is not empty
    if (name === '') {
        return false;
    }
    // Validate the Type of request
    if (type === 1) {
        url = getApiUrl('manageMembers', 'getOrganizationName') + `/${common.encrypt(name)}`;
    } else if (type === 2) {
        url = getApiUrl('manageMembers', 'getRepresentativeFirstName') + `/${name}`;
    } else if (type === 3) {
        url = getApiUrl('manageMembers', 'getRepresentativeLastName') + `/${name}`;
    }

    axios.get(url)
        .then((response) => {
            let list = [];
            if (response.data.status) {
                list = response.data.content;
            }
            callback(list);
        })
        .catch((err) => {
            callback();
        });
}

const getOrganizationExportedDataAction = (dispatch, callback, query) => {
    let url = getApiUrl('manageMembers', 'organizationExportFile');
    url = `${url}/${query}`;
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content.Organizations);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3048'));
        });
}


const getGridPreferenceAction = (callback, dispatch) => {
    let url = getApiUrl('userPermission', 'getGridUserPreferences') + '/2';
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback();
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '2506'));
        });
}

export default {
    getOrganizationListAction,
    getAutoSearchFilterAction,
    getOrganizationExportedDataAction,
    getGridPreferenceAction
}
