import { cacheManager } from 'helpers/util-common';
import React, { Component } from 'react';
import CommunicationLogInfo from 'shared-components/CommunicationLogInfo';
import * as _func from './function';
class CommitteeCommunicationLog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isPopOpen: false,
            communicationLogList: [],
            commentLength: 0,
            previousScrollPosition: 0,
            pageSize: 6,
            pageNumber: 1,
            commentCount: '',
            clickedCommentId: 0,
            showLoader: false,
            createdByUserList: [],
            userDetails: JSON.parse(cacheManager.getItem('userDetail'))
        }

        this.handleCancel = this.handleCancel.bind(this);
        this.openDeleteConfirmPopUp = this.openDeleteConfirmPopUp.bind(this);
        this.communicationCancelHandler = this.communicationCancelHandler.bind(this);

        this.handleScroll = this.handleScroll.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkUserPagePermission = this.checkUserPagePermission.bind(this);
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.loadInitialList();
    }

    componentDidMount() {
        let scrollElement = document.getElementById('root');
        scrollElement.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        let scrollElement = document.getElementById('root');
        scrollElement.removeEventListener('scroll', this.handleScroll);
    }

    isLoadingData = false; // Need this only at component level isLoadingData

    handleScroll(ev) {
        if (this.isLoadingData) {
            return;
        }

        let { communicationLogList, commentCount } = this.state;
        let scrollElement = ev.srcElement;

        // Check if scroll direction is downward and there is significant distance from the bottom of page to load new data
        if (scrollElement.scrollTop > this.state.previousScrollPosition &&
            (scrollElement.scrollHeight - scrollElement.clientHeight - scrollElement.scrollTop < 10)) {
            if (communicationLogList && commentCount > communicationLogList.length) {
                this.isLoadingData = true;
                this.setState({ showLoader: true })

                _func.fetchNextRecords(() => {
                    this.isLoadingData = false;
                })
            }
        }
    }

    openDeleteConfirmPopUp(commentId) {
        this.setState({ isPopOpen: true, clickedCommentId: commentId })
    }

    handleCancel() {
        this.setState({ isPopOpen: false })
    }

    communicationCancelHandler = () => {
        this.setState({ commentLength: 0 });
    }

    onChange(e) {
        this.setState({ commentLength: e.currentTarget.innerText.trim().length })
    }

    checkUserPagePermission(event) {
        if (event.target && event.target.nodeName === 'A' && !this.props.hasUserPageAccess) {
            event.preventDefault();
        }
    }

    render() {
        const { messageCodes, hasUserPageAccess, committeeUserList, hasModerateCommunicationLogAccess, PAGE_TYPE, USER_PRIVILEGE } = this.props
        const { communicationLogList, showLoader, isPopOpen, commentCount, commentLength, createdByUserList, clickedCommentId } = this.state;

        return (
            <CommunicationLogInfo messageCodes={messageCodes} hasModerateCommunicationLogAccess={hasModerateCommunicationLogAccess} hasUserPageAccess={hasUserPageAccess} userList={committeeUserList}
                communicationLogList={communicationLogList} showLoader={showLoader} isPopOpen={isPopOpen}
                commentCount={commentCount} commentLength={commentLength} createdByUserList={createdByUserList}
                communicationEditHandler={_func.communicationEditHandler} checkUserPagePermission={this.checkUserPagePermission}
                handleConfirm={_func.onRemoveHandler} handleCancel={this.handleCancel}
                openDeleteConfirmPopUp={this.openDeleteConfirmPopUp} onUpdateHandler={_func.onUpdateHandler}
                onSubmitHandler={_func.onSubmitHandler} communicationCancelHandler={this.communicationCancelHandler}
                onChange={this.onChange} clickedCommentId={clickedCommentId}
                PAGE_TYPE={PAGE_TYPE}
                USER_PRIVILEGE={USER_PRIVILEGE}
            />
        )
    }
}

export default CommitteeCommunicationLog;
