import React, { Component } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import AutoSearch from 'shared-components/Search';
import RenderSelect from 'shared-components/Select';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import { CommitteeStatusOptions, CommitteeClassificationOptions } from '../../committee.model';
import * as _func from './function';

class AdvanceSearchCommittee extends Component {

    state = {
        designationList: [],
        titleList: [],
        isLoadingDesignationList: false,
        isLoadingTitleList: false,
        showDesignationErrorMsg: false,
        showTitleErrorMsg: false,
        selectedHierarchy: this.props.initialValues.CommitteeHierarchy
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { handleSubmit, committeeTypeList, hierarchyListForFilter } = this.props;
        const { designationList, titleList, isLoadingDesignationList, isLoadingTitleList, showDesignationErrorMsg, showTitleErrorMsg, selectedHierarchy } = this.state;

        return (
            <Form data-testid="CommADVSearchForm" onSubmit={handleSubmit} noValidate>
                <Grid columns='equal' className="mt0">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label>Committee Designation</label>
                            <Field name="CommitteeDesignation" type="text"
                                component={AutoSearch}
                                placeholder="Committee Designation"
                                source={designationList}
                                onChange={_func.getCommitteeDesignationList}
                                showNoResults={showDesignationErrorMsg}
                                isLoading={isLoadingDesignationList}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label>Committee Title</label>
                            <Field name="CommitteeTitle" type="text"
                                component={AutoSearch}
                                placeholder="Committee Title"
                                source={titleList}
                                onChange={_func.getCommitteeTitleList}
                                isLoading={isLoadingTitleList}
                                showNoResults={showTitleErrorMsg}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="Status"
                                component={RenderSelect} label="Status"
                                isMultiple={false}
                                placeholder="All"
                                options={CommitteeStatusOptions}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Field name="Classification"
                                component={RenderSelect} label="Classification"
                                isMultiple={false}
                                placeholder="All"
                                options={CommitteeClassificationOptions}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="CommitteeType"
                                component={RenderSelect} label="Committee Type"
                                isMultiple={false}
                                placeholder="All"
                                options={committeeTypeList}
                                onChange={_func.onChangeCommitteeType}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label>Hierarchy</label>
                            <DropdownMultipleSelection name="Hierarchy"
                                placeholder="All"
                                isMultiple={true}
                                value={selectedHierarchy}
                                onChange={_func.onChangeHierarchy}
                                isSinglItemPrevent={false}
                                options={hierarchyListForFilter} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb0">
                        <Grid.Column className="actions">
                            <Button data-testid="submitSearchHandle" primary type="submit">Search</Button>
                            <Button data-testid="resetSearch" className="cancel ml10" type="reset" onClick={_func.resetForm}>Reset</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

        );
    }
};

export default (reduxForm({
    form: 'AdvanceSearchOrganizationForm',
    onSubmit: _func.onSubmitHandler
})(AdvanceSearchCommittee));
