import React from 'react';
import { Icon, Button, Form, Loader, Confirm } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import RenderSelect from 'shared-components/Select'
import PaginationShorthand from 'shared-components/Pager'
import LinkAdditionalOfficerTitleModal from '../link-additional-officer-title-modal'
import RenderCheckbox from 'shared-components/CheckBox';
import { ItemsPerPage } from 'models/common.models';
import TableHeader from 'shared-components/Table/tableHeader';
import * as _func from './function';
import * as common from 'helpers/util-common';
import { CommitteeRosterOfficerGridHeaderInfo } from '../../committee.model';
import UpdateReasonModel from '../update-reason-modal'
import CustomAlertModal from 'shared-components/CustomAlertModal';
import { decrypt } from '../../../../helpers/util-common';

class ManageCommitteeOfficersModal extends React.Component {
    state = {
        linkAdditionalOfficer: false,
        committeeOfficerInfo: { OfficerTitleList: [], MemberList: [] },
        activePageNumber: 1,
        showPager: false,
        pageSize: 25,
        showLoader: true,
        RecordChage: false,
        isBackButtonPopUp: false,
        isDeLink: false,
        isNoChangeFound: false,
        activeIndex: -1,
        showUpdateReason: false,
        deLinkOfficerList: [],
        linkedOfficerTitleId: 0,
        selectedOfficerTitle: '',
        isShowAdministrativeAssistantPopup: false,
        isShowAdministrativeAssistantOrStaffManagerPopup: false,
        OfficerTitleDisplayMsg : ''
    }

    componentDidMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { linkAdditionalOfficer, committeeOfficerInfo: { OfficerTitleList, MemberList }, activePageNumber, totalPages, currentlyShowingItems, pageSize, showLoader, totalItems, isBackButtonPopUp, isDeLink, isNoChangeFound, showUpdateReason, deLinkOfficerList, linkedOfficerTitleId, selectedOfficerTitle, isShowAdministrativeAssistantPopup, isShowAdministrativeAssistantOrStaffManagerPopup, committeeName,OfficerTitleDisplayMsg } = this.state;
        const { messageCodes, hasUpdateAccess } = this.props
        let begin = (((activePageNumber - 1) * pageSize));
        let end = begin + pageSize;
        return (
            <div className="fullPagePopup active" data-testid="manageCommitteeOfficersModal">
                {showLoader && (
                    <Loader size='small' className="small-loader">Loading</Loader>
                )}
                <div className="ui container">
                    <a href="#" className="editBtn" style={{ 'width': '32px' }} data-testid="backButton" onClick={_func.backButtonHandler}><Icon name="arrow left" /></a>
                    <div>
                        <div className="headingTitle clearfix mt20 mb20">
                            <h2>Committee Officers</h2>
                            {(hasUpdateAccess && (deLinkOfficerList && deLinkOfficerList.length > 0)) && <Button secondary onClick={() => _func.showHideLinkOfficer(0)}>Link Additional Officer Title</Button>}
                        </div>
                    </div>
                    <div>
                        <span className="legendNotes"> x -  This Officer Title is assigned to a member and cannot be delinked if a member is associated.</span>
                        <span className="legendNotes">y - This Officer Title is applicable to All Committees.</span>
                    </div>
                    <Form size='large'>
                        <div className="tableWrapper">
                            <table className="customTable">
                                <thead>
                                    <tr>
                                        <TableHeader headerProps={CommitteeRosterOfficerGridHeaderInfo} onClickProps={_func.sortBy} ></TableHeader>

                                    </tr>
                                </thead>
                                <tbody>
                                    {OfficerTitleList && OfficerTitleList.length > 0 &&
                                        OfficerTitleList.slice(begin, end).map((item, index) => {
                                            return <tr>
                                                <td>
                                                    <a data-testid="linkedOfficer" onClick={() => _func.showHideLinkOfficer(item.OfficerTitleId, item.OfficerTitleName)}>{item.OfficerTitleName}</a>
                                                    {(item.IsApplicableAll && item.AssignedMembers && item.AssignedMembers.length > 0) && <span className="supTxtLegends">xy</span>}
                                                    {(item.IsApplicableAll && item.AssignedMembers && item.AssignedMembers.length <= 0) && <span className="supTxtLegends">y</span>}
                                                    {(!item.IsApplicableAll && item.AssignedMembers && item.AssignedMembers.length > 0) && <span className="supTxtLegends">x</span>}

                                                </td>
                                                <td>
                                                    {item.AssignedMembers.length === 0 ?
                                                        <RenderCheckbox IsChecked={item.IsLinked} IsEditable={true} onClick={() => _func.officerTitleLinkHandler(item.OfficerTitleId)} /> : <RenderCheckbox IsChecked={item.IsLinked} IsEditable={false} />}


                                                </td>
                                                <td>
                                                    <DropdownMultipleSelection name="CommitteeMember"
                                                        isMultiple={true}
                                                        open={false}
                                                        isSearch={true}
                                                        placeholder="Select Officer(s)"
                                                        value={item.AssignedMembers.map(member => member.toString())}
                                                        onChange={(value) => _func.onChangeMemberHandler(value, item.OfficerTitleId)}
                                                        isSinglItemPrevent={false}
                                                        options={_func.formatMemberList(item.memberList)} />
                                                </td>
                                            </tr>
                                        })}
                                </tbody>
                            </table>
                            {OfficerTitleList && OfficerTitleList.length === 0 &&
                                <div className="noRecordMessage">{common.getMessage(messageCodes, '9010.text')}</div>
                            }
                        </div>
                    </Form>
                    {totalItems > 0 && <React.Fragment>
                        <section className="pagerWrap" data-testid="pagerWrap">
                            <div className="pager">
                                <PaginationShorthand defaultActivePage={activePageNumber} totalPages={totalPages}
                                    onPageChange={_func.onPageChangeHandler} />
                            </div>
                            <div className="itemPerPage">
                                <RenderSelect
                                    name="itemsPerPage"
                                    value={pageSize}
                                    onChange={_func.onChangeNumberOfItemsPerPage}
                                    options={ItemsPerPage}
                                />
                                <span className="itemsPerPage">items per page</span>
                            </div>
                            <div className="totalPage">{currentlyShowingItems}</div>
                        </section>
                        <div className="mt20">
                            {hasUpdateAccess && <Button primary onClick={_func.postCommitteeOfficer}>Save</Button>}
                            <Button secondary className="ml10" onClick={() => this.props.showHide(false)}>Cancel</Button>
                        </div>
                    </React.Fragment>}
                </div>
                {linkAdditionalOfficer && <LinkAdditionalOfficerTitleModal {...this.props} showHide={_func.showHideLinkOfficer} linkedOfficerTitleId={linkedOfficerTitleId} deLinkOfficerList={deLinkOfficerList} selectedOfficerTitle={selectedOfficerTitle} />}
                {(showUpdateReason && hasUpdateAccess) && <UpdateReasonModel {...this.props} onSubmitCall={_func.OnUpdateRecored} onClose={this.props.showHide} />}
                <Confirm className="confirm-box"
                    open={isBackButtonPopUp || isDeLink || isNoChangeFound}
                    content={common.getMessage(messageCodes, (isDeLink && '9058.text') || (isBackButtonPopUp && '9059.text') || ((isNoChangeFound && '2040.text')))}
                    onCancel={() => (isBackButtonPopUp && _func.ConfirmationPupUpHandler(_func.ConfirmationOption.Back, 0)) || (isDeLink && _func.ConfirmationPupUpHandler(_func.ConfirmationOption.Delink, 0)) || (isNoChangeFound && _func.ConfirmationPupUpHandler(_func.ConfirmationOption.NoChage, 0))}
                    onConfirm={() => (isBackButtonPopUp && _func.ConfirmationPupUpHandler(_func.ConfirmationOption.Back, 1)) || (isDeLink && _func.ConfirmationPupUpHandler(_func.ConfirmationOption.Delink, 1)) || (isNoChangeFound && _func.ConfirmationPupUpHandler(_func.ConfirmationOption.NoChage, 1))}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
                <Confirm className="confirm-box"
                    open={isShowAdministrativeAssistantOrStaffManagerPopup}
                    content={OfficerTitleDisplayMsg}
                    onCancel={_func.cancelAdministrativeAssistantOrStaffManagerPopup}
                    onConfirm={_func.confirmAdministrativeAssistantOrStaffManagerPopup}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
                {isShowAdministrativeAssistantPopup &&
                    <CustomAlertModal content={common.getMessage(messageCodes, '9523.text')} handleClick={_func.handleClickOk} />
                }
            </div>
        )
    }
}
export default ManageCommitteeOfficersModal;
