import React from 'react';

const ViewCommitteeMeetingsGenInfo = ({ initialValues, onEditGenInfo, hasUpdateAccess }) => (
    <section>
        {hasUpdateAccess &&
            <div className="txtRight mb10 mt-10">
           {initialValues.StatusName.toLowerCase() === 'active' && <a title="Edit" class="editBtn" data-testid="editBtn" onClick={onEditGenInfo}><i aria-hidden="true" class="pencil icon"></i></a>}
            </div>
        }
        <div className="meetingSummary clearfix">
            <div className="summaryBox">
                {initialValues.NumberOfAttendees ? (
                    <span className="count">{initialValues.NumberOfAttendees}</span>
                ) : (
                        <span className="NAText">Not available</span>
                    )}
                <span className="title">Average Number of Attendees</span>
            </div>
            <div className="summaryBox">
                {initialValues.NumberOfMeetingDays ? (
                    <span className="count">{initialValues.NumberOfMeetingDays}</span>
                ) : (
                        <span className="NAText">Not available</span>
                    )}
                <span className="title">Number of Meeting Days</span>
            </div>
        </div>

    </section>
)

export default ViewCommitteeMeetingsGenInfo;
