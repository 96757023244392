import lodash from 'lodash';
import React from 'react';
import { Link } from "react-router-dom";
import { validateUrlParams } from '../../helpers/util-common';
import { MODULES } from '../../models/navigation.models';

const NavigationMenu = ({ activeHeader, NAVIGATION, ...rest }) => {
  const { history } = rest;
  // Validate Active Items : Returns Active or Not
  const activeItems = (items, navIndex) => {
    let currentRoute = history.location.pathname && history.location.pathname; // Get the key from the route;
    if (validateUrlParams(items.routeUrl, currentRoute) === currentRoute) {
      return true;
    } else if (items[MODULES[navIndex]] && items[MODULES[navIndex]].length > 0) {
      let filterResult = items[MODULES[navIndex]].filter((filterItems) => {
        return validateUrlParams(filterItems.routeUrl, currentRoute) === currentRoute.substring(1)
      })
      return filterResult && filterResult[0];
    }
    return false;
  }

  // Check Parent Url has exist hash
  const validateRouteUrl = (url) => {
    if (url && url.indexOf("#") > -1) {
      return url;
    }
    return `/${url}`;
  }
  // Render Parent Menu
  const renderParentMenu = (item, navIndex) => {
    return (
      item.isVisibleOnNavigation &&
      <li>
          {!item.isExternalUrl
            && <Link target="_self" to={lodash(item).get('isExternalUrl') ? { pathname: lodash(item).get('routeUrl') } : validateRouteUrl(lodash(item).get('routeUrl'))} className={`${activeItems(item, navIndex) ? 'active' : ''}`}>{lodash(item).get('ModuleName')} <span className="seperator">|</span></Link>
          }
          {item.isExternalUrl && <a className="" href={lodash(item).get('isExternalUrl') ? lodash(item).get('routeUrl') : validateRouteUrl(lodash(item).get('routeUrl'))}>{lodash(item).get('ModuleName')} <span className="seperator">|</span></a>}
          {item.hasNavChild && item[MODULES[navIndex]] && item[MODULES[navIndex]].length > 0 &&
            < ul className="subMenu">
              {renderChildMenu(item[MODULES[navIndex]])}
            </ul>
          }
      </li >
    );
  }

  // Render Child Menu
  const renderChildMenu = (subItems) => {
    return subItems && subItems.length > 0 && subItems.map((items) => {
      return (
        items.isVisibleOnNavigation &&
        <li><Link to={validateRouteUrl(lodash(items).get('routeUrl'))}>{lodash(items).get('title')}</Link></li>
      )
    });
  }


  return (
    <div className="ui container topMenuBar">
      <div className="menuWrapper">
        <ul>
          {NAVIGATION && NAVIGATION.length > 0 && NAVIGATION.map((item, navIndex) => {
            return renderParentMenu(item, navIndex)
          })}
        </ul>
      </div>
    </div>
  )
}

export default NavigationMenu;
