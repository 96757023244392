import { encrypt } from 'helpers/util-common';
import { connect } from "react-redux";
import Login from './index';
import loginActions from "./login.actions";

const mapStateToProps = state => {
};

const mapDispatchToProps = (dispatch) => ({
	login: (username, password, callback) => {
		loginActions.login(dispatch, { username: username, password: encrypt(password) }, callback);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

