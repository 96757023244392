import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { checkUserPermissionOnPage } from 'helpers/util-common';

class FeeGroupBasicDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeeGroupTabDetails: this.props.FeeGroupTabDetails
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ FeeGroupTabDetails: nextProps.FeeGroupTabDetails });
    }

    render() {
        const { FeeGroupTabDetails: { FeeGroupNumber, FeeGroupTitle, Address1, Address2, Organization, City, Country, State, PostalCode, IsAutoRenewal, IsSuppressFeeRenewalEmail, IsSuppressFeeRenewalPrint } } = this.state;
        const { PAGE_TYPE, USER_PRIVILEGE, userPermission, editFormHandler, FeeGroupTabDetails: { Status } } = this.props
        return (
            <div data-testid='basicDetailsTab'>
                <section className="subCommInfo">
                    <h5>
                        {(checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.UPDATE) && (Status && Status.toLowerCase() !== 'inactive')) &&
                            <a href title="Edit" className="editBtn" onClick={() => editFormHandler()}>
                                <Icon name="pencil" />
                            </a>}
                    </h5>

                    <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Fee Group Number</span>
                                <span className="labelValue">{(FeeGroupNumber || '').toUpperCase()}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">Fee Group Title</span>
                                <span className="labelValue">{FeeGroupTitle}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">Address</span>
                                <span className="labelValue">{Address1 && `${Address1}`} {Address2 && `, ${Address2}`}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">City</span>
                                <span className="labelValue">{City}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Country</span>
                                <span className="labelValue">{(Country !== '0') && Country}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">State/Province</span>
                                <span className="labelValue">{State}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">Postal Code</span>
                                <span className="labelValue">{PostalCode}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">Organization</span>
                                <span className="labelValue">{Organization}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Auto-Renewal</span>
                                <span className="labelValue">{IsAutoRenewal && IsAutoRenewal ? 'Yes' : 'No'}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">Suppress Fee Renewal Emails</span>
                                <span className="labelValue">{IsSuppressFeeRenewalEmail && IsSuppressFeeRenewalEmail ? 'Yes' : 'No'}</span>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="labelTitle">Suppress Fee Renewal Print</span>
                                <span className="labelValue">{IsSuppressFeeRenewalPrint && IsSuppressFeeRenewalPrint ? 'Yes' : 'No'}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </section>
            </div>    
            
        )
    }
}
export default FeeGroupBasicDetails;
