import React, { PureComponent } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import RenderRadioField from 'shared-components/RadioBox'
import { getMessage } from 'helpers/util-common';

class MultipleRadioConfirmationPopup extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: '',
            showErrorMessage: false
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {

        this.setState({
            selectedValue: e.currentTarget.querySelector('input') ? e.currentTarget.querySelector('input').value : '',
            showErrorMessage: false
        });
    }

    onClickOk = () => {
        const { selectedValue } = this.state;

        if (selectedValue !== '') {
            this.props.closeModal(selectedValue);
        } else {
            this.setState({ showErrorMessage: true });
        }
    }

    onClickCancel = () => {
        this.props.closeModal();
    }

    render() {
        const { descMessage, messageCodes, options } = this.props;
        const { showErrorMessage } = this.state;

        return (
            <Modal open='true' className="tiny errormodalbox">
                <Modal.Header>Confirm<i onClick={this.onClickCancel} className="close" title="close">&#x2716;</i></Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <p>{descMessage}</p>
                        <p>{getMessage(messageCodes, '9016.text')}</p>
                        <div className="fullRadio">
                            <RenderRadioField name="StatusRadioBox"
                                className="mr10"
                                type="radio" onChange={this.onChange}
                                options={options}
                            />
                        </div>
                        {showErrorMessage && (
                            <div className="errorMessage mt10">{getMessage(messageCodes, '9017.text')}</div>
                        )}

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <div className="mt30">
                        <Button className="mr10" primary onClick={this.onClickOk}>Ok</Button>
                        <Button secondary onClick={this.onClickCancel}>Cancel</Button>
                    </div>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default MultipleRadioConfirmationPopup;
