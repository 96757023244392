import { AlphaNumeric_Regex, checkIfDateValid, compareDates, encrypt, formatDateToMMDDYYYY, getMessage } from 'helpers/util-common';
import { minDateForInputDateFields } from 'models/common.models';
import { stopSubmit } from 'redux-form';
import { OverviewTemplate } from '../../committee.model';

let self = null;
let isDuplicateCommittee = false;

export const setInstance = (instance) => {
    self = instance;
    isDuplicateCommittee = false;
    self.props.change('EstablishmentDate', formatDateToMMDDYYYY());
    self.props.change('Overview', OverviewTemplate);
}

export const validateCommitteeDesignation = (e, val) => {
    if (val && val.trim().length > 0) {
        const { addCommitteeData } = self.props;
        isDuplicateCommittee = false;
        let query = `Code=${encrypt(val)}&LevelNo=${addCommitteeData.newLevelDetails.levelId}`;

        if (addCommitteeData.newLevelDetails.levelId > 1) {
            query = `${query}&ParentId=${addCommitteeData.newLevelDetails.parentId}`;
        }
        self.props.isAlreadyExistsCommitteeDesignation(query, (response) => {
            // Set the error if committee designation exists
            if (response) {
                setCommitteeDesignationError();
            }
        })
    }
}

export const onConfirmationCancelHandler = () => {
    self.setState({ showAddCommitteeCancelConfirmation: false });
}

export const onConfirmationConfirmHandler = () => {
    self.setState({ showAddCommitteeCancelConfirmation: false });
    self.props.onClose();
}

export const onCancelHandler = () => {
    self.setState({ showAddCommitteeCancelConfirmation: true });
}

export const validateHandler = (values, props) => {
    const errors = {};
    const { Code, EstablishmentDate, Classification, Title, BosVolume } = values;
    const RegExpCode = new RegExp(AlphaNumeric_Regex);
    const levelId = (props.addCommitteeData.newLevelDetails.levelId > 1) ? 2 : 3;

    if (!Code || (Code && Code.trim().length === 0)) {
        errors.Code = getMessage(props.messageCodes, '4008.text');
    }
    if ((Code && Code.trim().length > 0) && !RegExpCode.test(Code)) {
        errors.Code = getMessage(props.messageCodes, '4022.text');
    }
    if ((Code && Code.trim().length < levelId) && RegExpCode.test(Code)) {
        errors.Code = getMessage(props.messageCodes, '4023.text').replace("<number>", levelId);
    }

    if (!EstablishmentDate) {
        errors.EstablishmentDate = getMessage(props.messageCodes, '4009.text');
    } else if (EstablishmentDate && !checkIfDateValid(EstablishmentDate, 'MM/DD/YYYY')) {
        errors.EstablishmentDate = getMessage(props.messageCodes, '4003.text');
    } else if (compareDates(minDateForInputDateFields, EstablishmentDate)) {
        errors.EstablishmentDate = getMessage(props.messageCodes, '4021.text');
    }

    if (!Classification && props.addCommitteeData.newLevelDetails.showClassification) {
        errors.Classification = getMessage(props.messageCodes, '4010.text');
    }
    if (!Title || (Title && Title.trim().length === 0)) {
        errors.Title = getMessage(props.messageCodes, '4011.text');
    }
    if ((!BosVolume || BosVolume == 0) && props.addCommitteeData.newLevelDetails.levelId === 2) {
        errors.BosVolume = getMessage(props.messageCodes, '4027.text');
    }
    return errors;
}

export const onSubmitHandler = (data) => {
    if (!isDuplicateCommittee) {
        const { addCommitteeData, onClose, addCommittee } = self.props;

        let overview = data.Overview;
        if (self.props.addCommitteeData.newLevelDetails.showOverview) {
            overview = overview ? overview.replace(new RegExp(' href="undefined"', 'g'), '')
                .replace(new RegExp(' href=""', 'g'), '') : ''; // To remove the hrefs which were added for mention
        } else {
            overview = OverviewTemplate;
        }

        // Submit the form
        let requestData = {
            Code: data.Code.trim(),
            Title: data.Title.trim(),
            EstablishmentDate: data.EstablishmentDate,
            Classification: data.Classification,
            ParentCommitteeId: addCommitteeData.newLevelDetails.parentId,
            CommitteeTypeId: addCommitteeData.committeeTypeId,
            LevelId: addCommitteeData.newLevelDetails.levelId,
            LevelName: addCommitteeData.newLevelDetails.levelName,
            IsSuppressCommittee: data.IsSuppressCommittee,
            Overview: overview ? overview.trim() : '',
            Scope: data.Scope ? data.Scope.trim() : '',
            BosVolumeId: data.BosVolume
        }
        addCommittee(requestData, (response) => {
            // If committee is added then close the page and refresh the list
            if (response) {
                onClose(true);
            } else {
                setCommitteeDesignationError();
            }
        });
    }
}

const setCommitteeDesignationError = () => {
    let errors = {};
    isDuplicateCommittee = true;
    errors.Code = getMessage(self.props.messageCodes, '4012.text');
    self.props.dispatch(stopSubmit(self.props.form, errors));
}


export const scrollToError = (errors) => {
    if (self.refs.fullPagePopup) {
        self.refs.fullPagePopup.scrollTo(0, 0)
    }
}



