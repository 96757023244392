import React from 'react';
import { CommitteeClassification } from '../view-committee-classification';
import ViewSubCommitteesInfo from '../view-sub-committees-info';

const ViewCommitteeStatisticalInfo = (props) => {
    return (
        <div>
            <h5 className="mt30">Statistical Information</h5>
            <CommitteeClassification {...props} />
            {props.allCommitteesHierarchyDetails && props.allCommitteesHierarchyDetails.length > 0 && (
                <ViewSubCommitteesInfo {...props} />
            )}
        </div>
    )
}

export default ViewCommitteeStatisticalInfo;
