import * as types from 'action-types';
import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import { callAPI, encrypt } from 'helpers/util-common';
import { API_PARAM_NULL_VALUE } from 'models/common.models';

const getOrganizationPersonalDetailsAction = (accountNumber, companyId = 0, dispatch) => {
    const apiParamNullValue = API_PARAM_NULL_VALUE;
    const paramValueAccountNumber = accountNumber ? accountNumber : apiParamNullValue;
    let url = getApiUrl('manageMembers', 'getOrganizationPersonalDetails') + `/${encrypt(paramValueAccountNumber)}/${encrypt(companyId)}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status && response.data.content) {
                dispatch({ type: types.GET_ORGANIZATION_DETAILS, data: response.data.content });
            } else {
                // Handle error case
                dispatch({ type: types.SHOW_NOT_FOUND_PAGE });
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3046'));
        });
}


const getOrganizationMasterDataAction = (dispatch) => {
    let getOrganizationUserUrl = getApiUrl('manageMembers', 'getOrganizationUserList');
    let getHistoricalReasonListUrl = getApiUrl('manageMembers', 'getHistoricalReasonList') + '/true';
    let getFacilityHistoricalReasonListUrl = getApiUrl('manageMembers', 'getHistoricalReasonList') + '/false';
    let getOrganizationAuditLogFields = getApiUrl('manageMembers', 'getOrganizationalAuditLogFieldList');

    Promise.all([axios.get(getOrganizationUserUrl), axios.get(getHistoricalReasonListUrl), axios.get(getFacilityHistoricalReasonListUrl), axios.get(getOrganizationAuditLogFields)])
        .then(([getOrganizationUserListResponse, getHistoricalReasonListResponse, getFacilityHistoricalReasonListResponse, getOrganizationAuditLogFieldsResponse]) => {

            let organizationUserList = [];
            let historicalReasonList = [];
            let historicalFacilityReasonList = [];
            let organizationAuditLogFieldList = [];

            if (getOrganizationUserListResponse.data.status) {
                organizationUserList = getOrganizationUserListResponse.data.content;
            }
            if (getHistoricalReasonListResponse.data.status) {
                historicalReasonList = getHistoricalReasonListResponse.data.content;
            }
            if (getFacilityHistoricalReasonListResponse.data.status) {
                historicalFacilityReasonList = getFacilityHistoricalReasonListResponse.data.content;
            }
            if (getOrganizationAuditLogFieldsResponse.data.status) {
                organizationAuditLogFieldList = getOrganizationAuditLogFieldsResponse.data.content;
            }

            dispatch({ type: types.GET_ORGANIZATION_MASTER_DATA, organizationUserList, historicalReasonList, organizationAuditLogFieldList, historicalFacilityReasonList });
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3050'));
        });
}

const getRepresentativeHistoryAction = (accountNumber, companyId = 0, dispatch) => {
    const apiParamNullValue = API_PARAM_NULL_VALUE;
    const paramValueAccountNumber = accountNumber ? accountNumber : apiParamNullValue;
    const url = `${getApiUrl('manageMembers', 'getRepHistoryByAccountNumber')}/${paramValueAccountNumber}/${encrypt(companyId)}`;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: types.GET_ORGANIZATION_REP_HISTORY_LIST, list: response.data.content });
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3056'));
        });
}

const getOrganizationCommunicationLogAction = (accountNumber, pageSize, pageNumber, showLoader, companyId = 0, callback, dispatch) => {
    const apiParamNullValue = API_PARAM_NULL_VALUE;
    const paramValueAccountNumber = accountNumber ? accountNumber : apiParamNullValue;
    let url = `${getApiUrl('manageMembers', 'getOrganizationCommunicationLogs')}/accountNumber=${encrypt(paramValueAccountNumber)}&pageSize=${pageSize}&pageNumber=${pageNumber}&companyId=${encrypt(companyId)}`;

    if (showLoader) {
        dispatch(commonActions.setLoader(true));
    }

    axios.get(url)
        .then((response) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }
            dispatch(commonActions.setMessage(true, '3057'));
        });
}

const getMemberOrgActiveMappingAction = (searchText, accountNumber, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberOrgActiveMapping');
    url = `${url}/${encrypt(searchText)}/${encrypt(accountNumber)}`;
    axios.get(url)
        .then((response) => {
            let list = [];
            if (response.data.status) {
                list = response.data.content;
            }
            callback(list);
        })
        .catch((err) => {
            callback();
        });
}

const addOrganizationCommunicationLogAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'saveOrganizationCommunicationLog');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2029'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3029')); // Default exception error
            return false;
        });
};

const updateOrganizationCommunicationLogAction = (commentId, data, isOwnComment, callback, dispatch) => {
    let url = '';

    if (isOwnComment) {
        url = getApiUrl('manageMembers', 'updateOwnOrganizationCommunicationLog');
    } else {
        url = getApiUrl('manageMembers', 'updateOrganizationCommunicationLog');
    }
    url = `${url}/${commentId}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2030'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3030')); // Default exception error
        });
};

const updateOrgRepMappingAction = (accountNumber, data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'updateOrgRepMapping') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3061'));
        });
};

export const removeOrganizationCommunicationLogAction = (commentId, data, isOwnComment, callback, dispatch) => {
    let url = '';
    if (isOwnComment) {
        url = getApiUrl('manageMembers', 'removeOwnOrganizationCommunicationLog');
    } else {
        url = getApiUrl('manageMembers', 'removeOrganizationCommunicationLog');
    }
    url = `${url}/${commentId}`;
    dispatch(commonActions.setLoader(true));

    // Need to send it Put as the data is used in send mail
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2031'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((error) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3032')); // Default exception error
        });
};

const updateOrganizationAccountDetailsAction = (accountNumber, data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'updateOrganizationAccountDetails') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3051'));
        });
};
const checkIsCompanyAndAddressDetailsExistsAction = (payload, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'checkIsCompanyAndAddressDetailsExists');
    dispatch(commonActions.setLoader(true));
    callAPI(url, 'post', payload, (response) => {
        dispatch(commonActions.setLoader(false));
        if (response.data && response.data.status) {
            callback(response.data.content);
        } else {
            dispatch(commonActions.setMessage(true, response.data.message || '3147'));
        }
    });
}
export default {
    getOrganizationPersonalDetailsAction,
    getOrganizationMasterDataAction,
    getRepresentativeHistoryAction,
    getOrganizationCommunicationLogAction,
    getMemberOrgActiveMappingAction,
    addOrganizationCommunicationLogAction,
    updateOrganizationCommunicationLogAction,
    updateOrgRepMappingAction,
    removeOrganizationCommunicationLogAction,
    updateOrganizationAccountDetailsAction,
    checkIsCompanyAndAddressDetailsExistsAction
};
