import { checkUserPermissionOnPage } from 'helpers/util-common';
import { McsStatusMasterId } from 'models/common.models';
import { ComitteeClassificationBoxList, CommitteeClassificationList } from '../../committee.model';

let self = null;

export const setClassInstance = (instance) => {
    self = instance;
    setUserAccess(self.props.userPermission);
}

export const handleUpdate = (nextProps) => {
    // Recheck the user permissions when user click on Back button of window tab
    if (nextProps.currentModuleTitle !== self.props.currentModuleTitle) {
        setUserAccess(nextProps.userPermission);
    }
}

export const statusValidator = (status) => {
    self.setState({
        activeIndex: 1,
        Conditional_Roster_Filters_Values: {
            status_value: status,
            classification: null,
            vote: null
        }
    });
}

// Statistical Information Right Side Box Information
export const totalStatisticalBoxInformation = (evt, selector, counter = 0) => {
    const validateKeys = (keys) => {
        return keys.replace("_", " ");
    }

    switch (selector) {
        case validateKeys(ComitteeClassificationBoxList.Total_Members):
            if (Number(counter) === 0) {
                return false;
            }
            statusValidator(`${McsStatusMasterId.ActiveCommitteeMember}`);
            break;
        default:
            return false;

    }
}

const isVoteValidator = (status, classificationVal, voteVal) => {
    self.setState({
        activeIndex: 1,
        Conditional_Roster_Filters_Values: {
            status_value: status,
            classification: classificationVal,
            vote: voteVal
        }
    })
}


// Left Side List Classification Information
export const classificationStatisticalListInformation = (evt, selector, value, isVoter, Id = -1) => {

    if (Number(value) === 0) {
        return false;
    }

    const validateKeys = (keys) => {
        return keys.replace("_", " ");
    }

    const status = `${McsStatusMasterId.ActiveCommitteeMember}`;

    switch (selector) {
        case validateKeys(CommitteeClassificationList.Producer):
            if (isVoter) {
                isVoteValidator(status, 3, 1);
            } else {
                isVoteValidator(status, 3, 0)
            }
            break;
        case validateKeys(CommitteeClassificationList.User):
            if (isVoter) {
                isVoteValidator(status, 1, 1);
            } else {
                isVoteValidator(status, 1, 0)
            }
            break;
        case validateKeys(CommitteeClassificationList.General_Interest):
            if (isVoter) {
                isVoteValidator(status, 4, 1);
            } else {
                isVoteValidator(status, 4, 0)
            }
            break;
        case validateKeys(CommitteeClassificationList.Consumer):
            if (isVoter) {
                isVoteValidator(status, 2, 1);
            } else {
                isVoteValidator(status, 2, 0)
            }
            break;
        case validateKeys(CommitteeClassificationList.Unclassified):
            if (isVoter) {
                isVoteValidator(status, 5, 1);
            } else {
                isVoteValidator(status, 5, 0)
            }
            break;
        case 'Total':
            if (isVoter) {
                isVoteValidator(status, 0, 1);
            } else {
                isVoteValidator(status, 0, 0)
            }
            break;
        default:
            if (isVoter) {
                isVoteValidator(status, Id, 1);
            } else {
                isVoteValidator(status, Id, 0)
            }
            break;
    }
}

export const tabOnChange = (event, data) => {
    self.setState({
        activeIndex: data.activeIndex,
        Conditional_Roster_Filters_Values: {}
    })
}

const setUserAccess = (userPermission) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    self.setState({
        userAccess: {
            hasUpdateAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.UPDATE),
            hasUserPageAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
            hasModerateCommunicationLogAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
            hasExportAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EXPORT),
            hasAddMemberToCommitteeAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE),
            hasEditMemberParticipationOnCommittees: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE),
            hasInactivate: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.INACTIVATE),
            hasReactivate: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.REACTIVATE)

        }
    })
}
