import { getMessage } from "helpers/util-common";
import React from "react";
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea';
import InputTagField from "../input-tag-field";

class ConsultantFormSecond extends React.Component {
    state = {
        isOrgLoading: false,
        organizationList: [],
        searchText: '',
        tags: []
    }

    componentDidMount() {
        if (this.props.postData && this.props.postData.organizationNameYouRepresenting && this.props.postData.organizationNameYouRepresenting.length > 0) {
            this.setState({
                tags: this.props.postData.organizationNameYouRepresenting.split(', ')
            });
        }
        let isPrimarilyConsultant='';
        const orderFormData = this.props.orderFormData;

        if(orderFormData && orderFormData.memberTempInformationDetails
            && orderFormData.memberTempInformationDetails.FormData &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification &&
            orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification
            ){
                isPrimarilyConsultant =
                orderFormData.memberTempInformationDetails.FormData
                  .CommitteeDetails[this.props.item].CommitteeMemberClassification
                  .PrimarilyConsultant === true ? "2" : orderFormData.memberTempInformationDetails.FormData
                  .CommitteeDetails[this.props.item].CommitteeMemberClassification
                  .PrimarilyConsultant === false ? "1" : "";

      this.onChangeProduceProducts(
        undefined,
        isPrimarilyConsultant,
        undefined,
        "anyOrganizationPrimarilyProduceProducts" + this.props.item
      );
      setTimeout(() => {
        this.props.change("anyOrganizationPrimarilyProduceProducts"+this.props.item, isPrimarilyConsultant)
    }, 50);
      }
      if(orderFormData && orderFormData.memberTempInformationDetails
        && orderFormData.memberTempInformationDetails.FormData &&
        orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
        orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification &&
        orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.BusinessActivity
        ){
      const businessActivity =
      orderFormData.memberTempInformationDetails.FormData
        .CommitteeDetails[this.props.item].CommitteeMemberClassification.BusinessActivity;

    this.onChangeProductSell(
      undefined,
      businessActivity,
      undefined,
      isPrimarilyConsultant > 1 ? "productSells"+this.props.item : "businessActivity" + this.props.item
    );
    setTimeout(() => {
        this.props.change(isPrimarilyConsultant > 1 ? "productSells"+this.props.item : "businessActivity" + this.props.item, businessActivity)
    }, 50);
    }
}

    resetAllState = (item) => {
        this.setState({
            ['productSells'+item]: '',
            ['businessActivity'+item]: ''
        }, () => {
            this.props.change('productSells'+item, this.state['productSells'+item]);
            this.props.change('businessActivity'+item, this.state['businessActivity'+item]);
        });
    }

    onChangeProduceProducts = (event, value, preValue, name) => {
        this.setState({ [name]: value });
        let item=name.replace('anyOrganizationPrimarilyProduceProducts', '')
        this.resetAllState(item)
    };

    onChangeProductSell = (event, value, preValue, name) => {
        this.setState({ [name]: value });
    };

    render() {
        const {item, messageCodes, optionsYesNo } = this.props;
const orderFormData = this.props.orderFormData;

        return (
            <React.Fragment>
                <Grid.Row className="mt10">
                    <Grid.Column>
                        <div className="radioWrap" >
                            <Field
                                name={"anyOrganizationPrimarilyProduceProducts"+item}
                                label={getMessage(messageCodes, '9122.text')}
                                type="radio"
                                className="mr10"
                                component={RenderRadioField}
                                required={true}
                                selectedValue={this.state['anyOrganizationPrimarilyProduceProducts'+item]}
                                onChange={this.onChangeProduceProducts}
                                options={optionsYesNo}
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {this.state['anyOrganizationPrimarilyProduceProducts'+item] &&
                    <Grid.Row>
                        <Grid.Column className='mt10'>
                            <Field
                                name={(Number(this.state['anyOrganizationPrimarilyProduceProducts'+item]) > 1) ? 'productSells'+item : 'businessActivity'+item}
                                label={(Number(this.state['anyOrganizationPrimarilyProduceProducts'+item]) > 1) ? 'What does the Organization(s) you represent produce/sell?' : 'Describe the business activities of the Organizations you represent.'}
                                component={TextArea}
                                required={true}
                                onChange={this.onChangeProductSell}
                                maxLength={255}
                            />
                        </Grid.Column>
                    </Grid.Row>
                }

                <Grid.Row>
                    <Grid.Column className='mt0'>
                        <InputTagField
                            {...this.props}
                            fieldName={'organizationNameYouRepresenting'+item}
                            label='Name of the Organizations you are representing'
                            hintText='Please provide the organization name or select from the list'
                            maxLength={255}
                            prefilledOptions={
                                orderFormData && orderFormData.memberTempInformationDetails
        && orderFormData.memberTempInformationDetails.FormData &&
       orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item] &&
       orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification &&
       orderFormData.memberTempInformationDetails.FormData.CommitteeDetails[this.props.item].CommitteeMemberClassification.NameofOrganization ? orderFormData.memberTempInformationDetails.FormData
                            .CommitteeDetails[item].CommitteeMemberClassification.NameofOrganization : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
            </React.Fragment>
        );
    }
}

export default ConsultantFormSecond;
