import { deepCopy, getMessage, routeNavigationUrl } from 'helpers/util-common';
import { getMembershipTypeName } from '../../common-functions';
import { membershipTypeJSON, MembershipTypes } from '../../members.model';

let self = {};

let updatedMembershipTypeId = '';
let updatedOrgResult = null;
let selectedOrgAccountNumber = '';
let isOrgStatusHistorical = false;
let TIMEOUT = null;
let prevSearchText = '';
let action = '';
const actions = {
    RepChange: 'RepChange',
    OrgChange: 'OrgChange',
    OrgBlank: 'OrgBlank'
}
let hasUserConfirmedBlankOrg = false;
let selectedCommitteeList = [];

// Caution: This function is referenced in associate-organization-account-modal file as well
export const setClassInstance = (instance, type = 1) => {
    self = instance;

    if (type === 1) {
        const { initialValues } = self.props;

        selectedOrgAccountNumber = initialValues.OrgAccountNumber;
        updatedMembershipTypeId = initialValues.MemberTypeId;
        hasUserConfirmedBlankOrg = initialValues.OrgAccountNumber === '';
    }

    prevSearchText = '';
    selectedCommitteeList = [];
}

export const filterMembershipTypes = () => {
    let membershipTypeList = deepCopy(self.props.membershipTypeList);
    membershipTypeList.splice(0, 1); // Remove the default value

    // Remove Rep, Org and Student
    if (self.props.initialValues.MemberTypeId === MembershipTypes.Student) {
        membershipTypeList = membershipTypeList.filter(type => {
            return type.key !== MembershipTypes.Representative && type.key !== MembershipTypes.Organizational
        })
    } else if (self.props.initialValues.MemberTypeId === MembershipTypes.Representative || self.props.initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
        membershipTypeList = membershipTypeList.filter(type => {
            return type.key !== MembershipTypes.Student && type.key !== MembershipTypes.Organizational
        })
    } else {
        membershipTypeList = membershipTypeList.filter(type => {
            return type.key !== MembershipTypes.Representative && type.key !== MembershipTypes.cooperativeAgreementRep && type.key !== MembershipTypes.Student && type.key !== MembershipTypes.Organizational
        })
    }

    membershipTypeList = membershipTypeList.filter(type => {
        return (type.key === self.props.initialValues.MemberTypeId || !(
            type.key === MembershipTypes.NewMember ||
            type.key === MembershipTypes.PerpIndus ||
            type.key === MembershipTypes.PerpInst ||
            type.key === MembershipTypes.ExemptMember ||
            type.key === MembershipTypes.GovtRepresentative ||
            type.key === MembershipTypes.MemberInst
        ));
    })

    return membershipTypeList;
}

export const onUpdateMemberType = (e, value) => {
    isOrgStatusHistorical = false;
    selectedCommitteeList = [];
    if ((self.props.initialValues.MemberTypeId === MembershipTypes.Representative &&
        self.state.membershipType === MembershipTypes.Representative &&
        value !== MembershipTypes.cooperativeAgreementRep &&
        value !== MembershipTypes.Representative) || (self.props.initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep &&
            self.state.membershipType === MembershipTypes.cooperativeAgreementRep &&
            value !== MembershipTypes.Representative &&
            value !== MembershipTypes.cooperativeAgreementRep)) {
        action = actions.RepChange;
        self.setState({
            showActiveRepWithActiveOrgConfirmation: true
        });
        setActiveRepStatusRelatedMessages();

        updatedMembershipTypeId = value;
    } else {

        if (value === MembershipTypes.Affiliate) {
            // Get the active committee list and then show the modal
            self.props.getAssociatedCommitteeList(self.props.initialValues.AccountNumber, (result) => {
                self.setState({ showAffiliateMemberModal: true, committeeList: result });
            })
        }

        self.setState({
            membershipType: value
        });
    }

    if (self.props.initialValues.MemberTypeId === MembershipTypes.MemberNonTech && value === MembershipTypes.MemberTech) {
        self.setState({ showAlertPopup: true });
    }
}

export const handleClickOk = (e) => {
    self.setState({ showAlertPopup: false }, () => {
        self.props.handleTabChange(e, { activeIndex: 1 });
    });
}

export const onCloseConfirmationModal = (value) => {
    let membershipType = self.props.initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep ? MembershipTypes.cooperativeAgreementRep : MembershipTypes.Representative;
    if (value) {
        if (value === 'true') {
            isOrgStatusHistorical = true;
        } else {
            isOrgStatusHistorical = false;
        }

        hasUserConfirmedBlankOrg = true;
        if (action === actions.OrgChange) {
            selectedOrgAccountNumber = updatedOrgResult.OrgAccountNumber;
            self.props.change('RepOrgName', updatedOrgResult.OrgName);
            getOrganizations(updatedOrgResult.OrgName);
        } else if (action === actions.RepChange) {
            membershipType = updatedMembershipTypeId;
        } else if (action === actions.OrgBlank) {
            onSubmitHandler(self.props.membershipTypeFormValues); // Submit the form
        }
    } else if (action === actions.RepChange || action === actions.OrgBlank) { // On click of Cancel, Close the edit view
        self.props.onCloseEditMembershipTypeInfo();
    } else if (action === actions.OrgChange) { // On click of Cancel, Empty the search value
        self.props.change('RepOrgName', '');
        self.setState({ organizationList: [] })
    }

    if (action === actions.RepChange) {
        self.setState({ membershipType });
    }

    action = '';
    self.setState({
        showActiveRepWithActiveOrgConfirmation: false
    })
}

export const onConfirmHistoricalOrgModal = () => {
    self.setState({
        membershipType: updatedMembershipTypeId
    })
}

// Caution: This function is referenced in associate-organization-account-modal file as well
export const onOrganizationChange = (value) => {
    selectedOrgAccountNumber = '';
    const trimmedValue = value.trim();

    clearTimeout(TIMEOUT);

    self.setState({
        isLoading: trimmedValue !== '',
        showNoResultsMessage: false
    });

    TIMEOUT = setTimeout(() => {

        // Chedck if we need to pull data from API rather than filtering the existing list
        if (prevSearchText.trim() !== '' && trimmedValue !== '' && value.length > prevSearchText.length &&
            value.indexOf(prevSearchText) === 0 && self.state.organizationList.length > 0) {
            let { organizationList } = self.state;

            organizationList = organizationList.filter(item => {
                return item.OrgAccountNumber.indexOf(trimmedValue) >= 0 || item.OrgName.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0;
            })

            self.setState({
                organizationList,
                isLoading: false,
                showNoResultsMessage: organizationList.length === 0
            });
        } else if (trimmedValue.length > 0) {
            getOrganizations(trimmedValue);
        } else {
            self.setState({ isLoading: false, organizationList: [] });
        }

        prevSearchText = value;
    }, 300);
}

export const onOrganizationSelect = (result) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    action = actions.OrgChange;
    updatedOrgResult = result;
    isOrgStatusHistorical = false;
    if (result.RepName && result.RepName.length > 0) {
        let alertMessage = getMessage(self.props.messageCodes, '9026.text');
        let orgLink = `<a className='linkTxt vAuto' title='${result.OrgName}' href='${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW, [result.OrgAccountNumber])}'>Organization</a>`;
        alertMessage = alertMessage.replace('@OrganizationLink', orgLink);
        if (result.MembershipTypeId === MembershipTypes.cooperativeAgreementRep) {
            alertMessage = alertMessage.replaceAll('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
        } else {
            alertMessage = alertMessage.replace('@membertype', membershipTypeJSON.represntative.displayName);
        }
        self.props.showAlertPopup(alertMessage);
    } else if(!!self.props.initialValues.OrgName){
        self.setState({
            showActiveRepWithActiveOrgConfirmation: true
        });
        setActiveOrgStatusRelatedMessages();
    } else{
        selectedOrgAccountNumber = updatedOrgResult.OrgAccountNumber;
        self.props.change('RepOrgName', updatedOrgResult.OrgName);
        getOrganizations(updatedOrgResult.OrgName);
        action = '';
        self.setState({
            showActiveRepWithActiveOrgConfirmation: false
        });
    }
}

export const onCloseAffiliateMemberModal = () => {
    self.props.onCloseEditMembershipTypeInfo();
}

export const onSubmitAffiliateMemberModal = (data) => {
    self.setState({ showAffiliateMemberModal: false });
    selectedCommitteeList = data;
}

// Validation method for validate all value as per requirement
export const validateHandler = (values, props) => {
    const errors = {};

    if (values.MemberTypeId === MembershipTypes.MouMember && values.MOUContactCodeId === 0) {
        errors.MOUContactCodeId = getMessage(props.messageCodes, '8120.text');
    }

    if ((values.MemberTypeId === MembershipTypes.Representative || values.MemberTypeId === MembershipTypes.cooperativeAgreementRep) && values.RepOrgName && values.RepOrgName.length > 0 && selectedOrgAccountNumber === '') {
        errors.RepOrgName = getMessage(props.messageCodes, '8123.text');
    }

    if ((!values.UpdateReason) || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}

export const onSubmitHandler = (data) => {
    const { initialValues } = self.props;
    // Send API only in case user has updated atleast one prop
    if (data.MemberTypeId !== initialValues.MemberTypeId || (data.MemberTypeId === MembershipTypes.MouMember && data.MOUContactCodeId !== initialValues.MOUContactCodeId) ||
        selectedOrgAccountNumber !== initialValues.OrgAccountNumber) {
        // In case user has removed the organization
        if (!hasUserConfirmedBlankOrg && (data.MemberTypeId === MembershipTypes.Representative || data.MemberTypeId === MembershipTypes.cooperativeAgreementRep) && selectedOrgAccountNumber === '') {
            action = actions.OrgBlank;
            setBlankOrgRelatedMessages();
            self.setState({ showActiveRepWithActiveOrgConfirmation: true })
        } else {
            let requestData = {
                AccountNumber: data.AccountNumber,
                MemberTypeId: data.MemberTypeId,
                PrevMemberTypeId: initialValues.MemberTypeId,
                MOUContactCodeId: data.MemberTypeId === MembershipTypes.MouMember ? data.MOUContactCodeId : 0,
                PrevMOUContactCodeId: initialValues.MOUContactCodeId,
                OrgAccountNumber: (data.MemberTypeId !== initialValues.MemberTypeId) ? '' : selectedOrgAccountNumber,
                PrevOrgAccountNumber: initialValues.OrgAccountNumber,
                IsOrgStatusHistorical: isOrgStatusHistorical,
                OldMemberTypeName: getMembershipTypeName(initialValues.MemberTypeId, self.props.membershipTypeList),
                NewMemberTypeName: getMembershipTypeName(data.MemberTypeId, self.props.membershipTypeList),
                SelectedAffiliateCommitteeList: selectedCommitteeList,
                UpdateReason: data.UpdateReason
            };
            if ((self.props.initialValues.MemberTypeId === MembershipTypes.Representative &&
                self.state.membershipType === MembershipTypes.cooperativeAgreementRep) ||
                (self.props.initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep &&
                    self.state.membershipType === MembershipTypes.Representative)) {
                requestData.OrgAccountNumber = selectedOrgAccountNumber
            }
            self.props.updateMembershipTypeInfo(requestData, () => {
                // self.props.getMemberDetails(data.AccountNumber);
                self.props.loadData(data.AccountNumber);
                self.props.onCloseEditMembershipTypeInfo();
            });
        }
    } else {
        self.props.displayNoChangesMadeMessage();
        self.props.onCloseEditMembershipTypeInfo();
    }
}

export const getOrganizations = (value) => {
    self.setState({ organizationList: [] });

    self.props.getOrgRepActiveMappingList(value, (organizationList) => {

        // Remove the current organization from the list
        organizationList = organizationList.filter(org => {
            return org.OrgAccountNumber !== self.props.initialValues.OrgAccountNumber
        })
        if (organizationList.length > 0) {
            self.setState({
                showNoResultsMessage: false
            });
        } else {
            self.setState({
                showNoResultsMessage: true
            });
        }

        self.setState({ isLoading: false, organizationList });
    })
}

// Private functions
const setActiveRepStatusRelatedMessages = () => {
    let descMessage = getMessage(self.props.messageCodes, '9018.text');
    descMessage = descMessage.replace('@Organization', self.props.initialValues.OrgName);
    let historicalRadioLabel = getMessage(self.props.messageCodes, '9019.text');
    let activeRadioLabel = getMessage(self.props.messageCodes, '9020.text');

    self.setState({ descMessage, historicalRadioLabel, activeRadioLabel });
}

const setActiveOrgStatusRelatedMessages = () => {
    const orgName = self.props.initialValues.OrgName;
    const MemberTypeId = self.props.initialValues.MemberTypeId;

    let descMessage = getMessage(self.props.messageCodes, '9022.text');
    descMessage = descMessage.replace('@Organization', orgName);
    let historicalRadioLabel = getMessage(self.props.messageCodes, '9023.text');
    historicalRadioLabel = historicalRadioLabel.replace('@Organization', orgName);
    let activeRadioLabel = getMessage(self.props.messageCodes, '9024.text');
    activeRadioLabel = activeRadioLabel.replace('@Organization', orgName);
    if (MemberTypeId === MembershipTypes.cooperativeAgreementRep) {
        descMessage = descMessage.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
    } else {
        descMessage = descMessage.replace('@membertype', membershipTypeJSON.represntative.displayName);
    }
    self.setState({ descMessage, historicalRadioLabel, activeRadioLabel });
}

const setBlankOrgRelatedMessages = () => {
    let descMessage = getMessage(self.props.messageCodes, '9025.text');
    let historicalRadioLabel = getMessage(self.props.messageCodes, '9019.text');
    let activeRadioLabel = getMessage(self.props.messageCodes, '9020.text');

    self.setState({ descMessage, historicalRadioLabel, activeRadioLabel });
}
