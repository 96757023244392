/* eslint-disable jsx-a11y/anchor-is-valid */
import { checkUserPermissionOnPage, routeNavigationUrl } from 'helpers/util-common';
import lodash from 'lodash';
import { CommitteeStatisticalBoxRedColorList } from 'modules/committee-management/committee.model';
import React from 'react';
import { Link } from 'react-router-dom';
import { Popup } from "semantic-ui-react";

const calculateVoteCount = (ClassificationStatisticalInfo, key) => {
    let counter = 0;
    if (lodash(ClassificationStatisticalInfo).get("ClassificationList", null) && lodash(ClassificationStatisticalInfo).get("ClassificationList") !== null && lodash(ClassificationStatisticalInfo).get("ClassificationList").length > 0) {
        let list = lodash(ClassificationStatisticalInfo).get("ClassificationList");
        list.map((res) => {
            counter = counter + res[key];
        });
    }
    return counter;
}

const validateHover = (res) => {
    let escapeHoverList = ["Total Standards", "Producer Votes Available"];
    if (escapeHoverList.indexOf(res.Name) === -1) {
        if (Number(res.Count) === 0) {
            return false
        }
        return true
    }
    return false;
}

const onClickCountClassification = (e, data, props, isVoter = true) => {
    const classification = lodash(data).get('Classification', '');
    const id = lodash(data).get('Id');
    let value = 0;

    if (isVoter) {
        value = lodash(data).get('Voter', 0);
    } else {
        value = lodash(data).get('NonVoter', 0)
    }

    props.onClassificationStatisticalListInformation(e, classification, value, isVoter, id);
}

const renderClassificationInformation = (ClassificationStatisticalInfo, props) => {
    if (lodash(ClassificationStatisticalInfo).get("ClassificationList", null) && lodash(ClassificationStatisticalInfo).get("ClassificationList") !== null && lodash(ClassificationStatisticalInfo).get("ClassificationList").length > 0) {
        let list = lodash(ClassificationStatisticalInfo).get("ClassificationList");
        return list.map((res, index) => {
            return (
                <tr key={index}>
                    <td width="50%"><span className="producer"></span>{lodash(res).get('Classification', '')}</td>
                    <td width="25%">
                        <a className={lodash(res).get('Voter', 0) <= 0 ? 'noPointer' : ''} href="javascript:void(0)"
                            onClick={(evt) => onClickCountClassification(evt, res, props)}>{lodash(res).get('Voter', 0)}</a>
                    </td>
                    <td width="25%">
                        <a className={lodash(res).get('NonVoter', 0) <= 0 ? 'noPointer' : ''} href="javascript:void(0)"
                            onClick={(evt) => onClickCountClassification(evt, res, props, false)}> {lodash(res).get('NonVoter', 0)}</a>
                    </td>
                </tr>
            )
        });
    }
}

const renderCountColor = (res) => {
    if (CommitteeStatisticalBoxRedColorList.indexOf(lodash(res).get('Name', '')) > -1) {
        const colorClass = Number(lodash(res).get('Count')) === 0 ? 'count txtRed noPointer' : 'count noPointer';
        const isNegative = Number(lodash(res).get('Count')) < 0;
        return (
            <span>
                <span className={colorClass} >{lodash(res).get('Count', 0)}</span>
                <span className="totalLabel" >{lodash(res).get('Name', '')}</span>
                {isNegative && (
                    <span className="totalLabel outofBalance txtRed">Committee is out of balance!</span>
                )}
            </span>
        )
    }
    return (
        <span>
            <span className={validateHover(res) ? 'count' : 'count noPointer'}>{lodash(res).get('Count', 0)}</span>
            <span className="totalLabel" >{lodash(res).get('Name', '')}</span>
        </span>
    )
}


const renderMemberList = (ProfiledMembersDetails, { PAGE_TYPE, USER_PRIVILEGE, userPermission }) => {
    let isViewAccess = checkUserPermissionOnPage(userPermission, PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)
    if (ProfiledMembersDetails !== null && lodash(ProfiledMembersDetails).get('length', 0) > 0) {
        return ProfiledMembersDetails.map((res) => {
            return (
                <li>
                    {isViewAccess &&
                        <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [lodash(res).get("AccountNumber", "#")])}`}>
                            {lodash(res).get('Name', '')}
                        </Link>
                    }

                    {!isViewAccess &&
                        <span>{lodash(res).get('Name', '')}</span>
                    }

                </li>
            )
        })

    }
    return (
        <li>No Record Found</li>
    )
}

const renderClassificationStatis = (ClassificationStatisticalInfo, poupOverName, props) => {
    const { initialValues: { ProfiledMembersDetails } } = props;
    if (lodash(ClassificationStatisticalInfo).get("ClassificationData", null) && lodash(ClassificationStatisticalInfo).get("ClassificationData") !== null) {
        let list = lodash(ClassificationStatisticalInfo).get("ClassificationData");
        return list.map((res, index) => {
            return (
                <div >
                    {poupOverName !== lodash(res).get('Name') && (
                        <div onClick={(e) => props.onTotalStatisticalBoxInformation(e, lodash(res).get('Name'), lodash(res).get('Count', 0))} >
                            {renderCountColor(res)}
                        </div>
                    )
                    }

                    {poupOverName === lodash(res).get('Name') && (
                        <div>
                            <Popup on='click' trigger={<span style={{ "pointer-events": `${Number(lodash(res).get('Count')) <= 0 ? 'none' : 'all'}` }} className={(Number(lodash(res).get('Count')) === 0) ? 'noPointer count' : 'count'}>
                                {lodash(res).get('Count', 0)}</span>} flowing hoverable className="countDetailsPopup" position="bottom center" hideOnScroll>
                                <div className="countDetails">
                                    <span className="countHead">{lodash(res).get('Name')}</span>
                                    <ul>
                                        {renderMemberList(ProfiledMembersDetails, props)}
                                    </ul>
                                </div>
                            </Popup>
                            <span className="totalLabel">{lodash(res).get('Name')}</span>
                        </div>
                    )
                    }
                </div>
            )
        })
    }
}


export const CommitteeClassification = (props) => {
    const { initialValues: { ClassificationStatisticalInfo } } = props;
    return (
        <div>
            <div className="tableWrapper mt0">
                <table className="customTable fixHeadertable">
                    <thead>
                        <tr>
                            <th width="50%">Classifications</th>
                            <th width="25%">Voter</th>
                            <th width="25%">Non Voter</th>
                        </tr>
                    </thead>
                    <tbody style={{ 'max-height': '280px' }}>
                        {renderClassificationInformation(ClassificationStatisticalInfo, props)}
                    </tbody>
                    <tbody>
                        <tr style={{ 'padding-right': '4px' }}>
                            <td width="50%"><strong>Total</strong></td>
                            <td width="25%"><a href="javascript:void(0);" className={calculateVoteCount(ClassificationStatisticalInfo, "Voter") <= 0 ? 'noPointer' : ''} onClick={(evt) => props.onClassificationStatisticalListInformation(evt, 'Total', calculateVoteCount(ClassificationStatisticalInfo, "Voter"), true)} ><strong>{calculateVoteCount(ClassificationStatisticalInfo, "Voter")}</strong></a></td>
                            <td width="25%"><a href="javascript:void(0);" className={calculateVoteCount(ClassificationStatisticalInfo, "NonVoter") <= 0 ? 'noPointer' : ''} onClick={(evt) => props.onClassificationStatisticalListInformation(evt, 'Total', calculateVoteCount(ClassificationStatisticalInfo, "NonVoter"), false)}><strong>{calculateVoteCount(ClassificationStatisticalInfo, "NonVoter")}</strong></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="totalCountWrap mt20">
                {renderClassificationStatis(ClassificationStatisticalInfo, "Profiled Members", props)}
            </div>
        </div>
    )
}


