import { connect } from "react-redux";
import commonActions, { getCountryAndStateListAction, getPermissionOnPageAction, logout } from '../common.actions';
import Home from './index';

const mapStateToProps = state => ({
	showNotFoundPage: state.commonReducer.showNotFoundPage,
	userPermission: state.commonReducer.userPermission,
	...state.commonReducer.PAGE_CONFIG
});

const mapDispatchToProps = (dispatch) => ({
	dispatchEvent: (actionType) => { dispatch({ type: actionType }); },
	logout: () => { logout(dispatch) },
	getPermissionOnPage: (requestInput, groupInput) => dispatch(getPermissionOnPageAction(requestInput, groupInput)),
	getCountryAndStateList: () => { getCountryAndStateListAction(dispatch) },
	setSafariAutoFillInfo: (flag) => {
		commonActions.setSafariAutoFillInfoAction(dispatch, flag)
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

