import React, { Component } from 'react';
import { DateInput } from 'semantic-ui-calendar-react-yz';

class RenderDateTimePicker extends Component {

    // Update value in redux fields
    handleChange = (event, { name, value }) => {
        this.props.input.onChange(value);
    }

    render() {
        // For redux form implementation
        if ('input' in this.props) {
            const { label, dataTestId, required, isDuplicate, placeholder, dateFormat, input: { value, name }, duplicateError, meta: { touched, error }, minDate, maxDate, defaultValue, disabled, readOnly, className, initialDate, popupPosition } = this.props;
            return (
                <div className="date-picker">
                    <label className="label" htmlFor={name}>
                        {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                        {label}
                    </label>
                    <div>
                        <DateInput className={touched && error ? "error mini" : 'mini' && className ? className : ''}
                            data-testid={dataTestId}
                            name={name}
                            id={name}
                            popupPosition={popupPosition ? popupPosition : "bottom left"}
                            placeholder={placeholder ? placeholder : "MM/DD/YYYY"}
                            value={defaultValue ? defaultValue : value}
                            initialDate={initialDate ? initialDate : ''}
                            iconPosition="left"
                            onChange={this.handleChange}
                            dateFormat={dateFormat ? dateFormat : 'MM/DD/YYYY'}
                            closable={true}
                            autoComplete="off"
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={disabled ? disabled : false}
                            readOnly={readOnly ? readOnly : false}
                        />

                        {touched && (error && <span className="errorMessage">{error}</span>)}
                        {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
                    </div>
                </div>
            )
        }

        // Without redux form implementation
        const { label, required, isDuplicate, placeholder, dateFormat, value, name, duplicateError, touched, error, minDate, maxDate, defaultValue, disabled, readOnly, className, onChange, popupPosition } = this.props;
        return (
            <div className="date-picker">
                <label className="label" htmlFor={name}>
                    {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                    {label}
                </label>
                <div>
                    <DateInput className={touched && error ? "error mini" : 'mini' && className ? className : ''}
                        name={name}
                        id={name}
                        popupPosition={popupPosition ? popupPosition : "bottom left"}
                        placeholder={placeholder ? placeholder : "MM/DD/YYYY"}
                        value={defaultValue ? defaultValue : value ? value : ''}
                        initialDate={defaultValue ? defaultValue : value ? value : ''}
                        iconPosition="left"
                        onChange={(e, { name, value }) => onChange(e, value, name)}
                        dateFormat={dateFormat ? dateFormat : 'MM/DD/YYYY'}
                        closable={true}
                        autoComplete="off"
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={disabled ? disabled : false}
                        readOnly={readOnly ? readOnly : false}
                    />
                    {touched && (error && <span className="errorMessage">{error}</span>)}
                    {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
                </div>
            </div>
        )
    }
}

export default RenderDateTimePicker;
