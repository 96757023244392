import { getMessage } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Accordion, Confirm, Form, Grid, Icon } from "semantic-ui-react";
import CheckboxElement from 'shared-components/CheckBox';
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import TextArea from 'shared-components/TextArea';
import * as _func from './function';

class EditCommitteeEnableOnWeb extends Component {

    state = {
        showEnableCommitteeOnWebSection: false,
        showErrorMessage: false,
        showConfirmationModal: false,
        activeIndex: 0,
        applicationPrivilegeList: [],
        showActiveStandardStatusModel: false
    }

    componentWillMount() {
        _func.setInstance(this);
    }

    render() {
        const { onCloseEditEnableOnWeb, handleSubmit, submitting, messageCodes } = this.props;
        const { showEnableCommitteeOnWebSection, showErrorMessage, applicationPrivilegeList, activeIndex, showConfirmationModal, showActiveStandardStatusModel } = this.state;

        return (
            <div data-testid="editCommitteeEnableOnWeb">
                <Form noValidate onSubmit={handleSubmit(_func.onSubmitHandler)} className="settingArea">
                    <Grid divided='vertically' className="mt0">
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="IsEnableCommitteeOnWeb" type="checkbox"
                                    component={ReduxCheckboxElement}
                                    label='Suppress committee from public web (committee home page, search, product pages and jurisdiction list)'
                                    onChange={_func.handleOnChangeEnableCommitteeOnWeb}
                                />
                                <ul className="editDetailsBtn floatRight">
                                    <li>
                                        <button title="Reset to default" className="editBtn" type="button" onClick={_func.showConfirmationModal}>
                                            <Icon name="redo" />
                                        </button>
                                    </li>
                                    <li>
                                        <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                            <Icon name="check" />
                                        </button>
                                    </li>
                                    <li>
                                        <button title="Cancel" className="closeBtn" onClick={onCloseEditEnableOnWeb}>
                                            <Icon name="close" />
                                        </button>
                                    </li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="IsSuppressOnWI" type="checkbox"
                                    component={ReduxCheckboxElement}
                                    label='Suppress committee from registering/editing a work item or submitting a ballot item'
                                    onChange={_func.handleOnChangeEnableSuppressOnWI}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {showEnableCommitteeOnWebSection && applicationPrivilegeList && (
                            <Grid.Row style={{ display: 'none' }}>
                                <Grid.Column>
                                    <div>
                                        {applicationPrivilegeList.map((item, index) => {
                                            return (
                                                <Accordion fluid >
                                                    <Accordion.Title
                                                        active={activeIndex === index}
                                                        index={index}
                                                        onClick={_func.handleClick}>
                                                        <Icon name='dropdown' />
                                                        {item.ApplicationName}
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex === index}>
                                                        <div className="checkBoxWrapper">
                                                            <Grid columns='equal'>
                                                                <Grid.Row>
                                                                    {item.Privilege && item.Privilege.map((privilegeItem, privilegeIndex) => {
                                                                        return (
                                                                            <Grid.Column>
                                                                                <CheckboxElement className="checkAccordian" IsEditable={privilegeItem.IsEditable}
                                                                                    IsChecked={privilegeItem.IsChecked}
                                                                                    setLabeltitleProp={privilegeItem.ApplicationPrivilegeName}
                                                                                    onChange={() => _func.handleOnChangePrivilege(index, privilegeIndex)}></CheckboxElement>
                                                                            </Grid.Column>
                                                                        )
                                                                    })}
                                                                </Grid.Row>
                                                            </Grid>
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                    {showErrorMessage && <span className="errorMessage">{getMessage(messageCodes, '8140.text')}</span>}
                    <div ref='forScrollPurpose'></div>
                    <Grid className="mt0" divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <Confirm className="confirm-box"
                    open={showConfirmationModal}
                    content={getMessage(messageCodes, '9069.text')}
                    onCancel={_func.onCancelModal}
                    onConfirm={_func.resetEnableOnWeb}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                <Confirm className="confirm-box"
                    open={showActiveStandardStatusModel}
                    content={getMessage(messageCodes, '9162.text')}
                    onCancel={_func.cancelSuppressOnWI}
                    onConfirm={_func.confirmSuppressOnWI}
                    size="tiny"
                    cancelButton="NO"
                    confirmButton="YES"
                />
            </div>
        )
    }
}

export default reduxForm({
    form: 'EditCommitteeEnableOnWeb',
    validate: _func.validateHandler,
    onSubmitFail: _func.onSubmitFailHandler
})(EditCommitteeEnableOnWeb);
