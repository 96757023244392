import React from "react";
import ExportExcel from "shared-components/ExportExcel";

const OrderHistoryExcelExport = ({ data, AccountNo }) => {

    const fileName = AccountNo + ' Order History';
    const tabName = 'Order History';

    const columns = [
        { title: 'Order Number', style: { font: { bold: true } } },
        { title: 'Item Name', style: { font: { bold: true } } },
        { title: 'Description', style: { font: { bold: true } } },
        { title: 'Ship To Address', style: { font: { bold: true } } },
        { title: 'Timestamp', style: { font: { bold: true } } }
    ];

    let rows = [];
    for (let i = 0; i < data.length; i++) {

        let row = [
            { value: data[i].OrderNo },
            { value: data[i].ItemName },
            { value: data[i].Description },
            { value: data[i].ShipToAddress },
            { value: data[i].TimeStamp }
        ];

        rows.push(row);
    }

    // Need atleast an empty row.
    if (data.length === 0) {
        let row = [
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' }
        ];

        rows.push(row);
    }

    const multiDataSet = [{ columns: columns, data: rows }];

    return (
        <ExportExcel fileName={fileName} tabName={tabName} multiDataSet={multiDataSet} />
    );
}

export default OrderHistoryExcelExport;
