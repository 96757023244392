import { connect } from "react-redux";
import memberActions from '../actions/members.actions';
import AdvanceSearchMember from '../components/advance-member-search';

const mapStateToProps = state => {
    return {
        membershipTypeList: state.memberReducer.membershipTypeList,
        userPermission: state.commonReducer.userPermission,
        countryList: state.commonReducer.countryList,
        usStateList: state.commonReducer.usStateList,
        canadaStateList: state.commonReducer.canadaStateList,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getMemberFirstNameList: (query, callback) => memberActions.getMemberFirstNameListAction(query, callback, dispatch),
    getMemberLastNameList: (query, callback) => memberActions.getMemberLastNameListAction(query, callback, dispatch),
    getMemberOrganizationNameList: (query, callback) => memberActions.getMemberOrganizationNameListAction(query, callback, dispatch),
    getCommitteeDesignationNameList: (callback) => memberActions.getCommitteeDesignationNameListAction(callback, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchMember);
