import { getMessage } from 'helpers/util-common';
import { getMembershipTypeName } from '../../../members/common-functions';
import { membershipTypeJSON, MembershipTypes } from '../../../members/members.model';
import { AccountStatusOptions, ActiveOrganizationStatusId } from '../../organizations.model'
import { COMPANY_TYPE } from '../../../../../models/common.models';

let self = {};
let selectedRepAccountNumber = '';
let selectedMemberTypeId = '';
let activeStatusId = AccountStatusOptions[0].value;
let historicalStatusId = AccountStatusOptions[1].value;
let isRepStatusHistorical = false;
const UpdateActionsEnum = {
    "UpdateOrgAndRep": "UpdateOrgAndRep",
    "UpdateOrgWithAnotherRep": "UpdateOrgWithAnotherRep",
    "UpdateOnlyOrg": "UpdateOnlyOrg"
}

export const setClassInstance = (instance) => {
    self = instance;
    selectedMemberTypeId = '';
    selectedRepAccountNumber = '';
    isRepStatusHistorical = false;
}

export const onUpdateAccountStatus = (e, value) => {
    const { initialValues, messageCodes } = self.props;
    selectedRepAccountNumber = '';
    selectedMemberTypeId = '';
    // Ask for confirmation when updating the Account Status from Historical to Active
    if (value === ActiveOrganizationStatusId && initialValues.McsStatusMasterId !== ActiveOrganizationStatusId) {
        let radioOptions = [];
        let descMessage = '';

        if (!self.props.initialValues.RepAccountNumber) {
            descMessage = getMessage(messageCodes, '9039.text');
            let option1Label = getMessage(messageCodes, '9040.text');
            let option2Label = getMessage(messageCodes, '9041.text');
            // COA REP text visible in case of type matched or Rep in previous stage.
            if (initialValues.RepMembershipTypeId === MembershipTypes.cooperativeAgreementRep || !initialValues.IsRep) {
                descMessage = descMessage.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
                option1Label = option1Label.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
                option2Label = option2Label.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
            } else {
                descMessage = descMessage.replace('@membertype', membershipTypeJSON.represntative.displayName);
                option1Label = option1Label.replace('@membertype', membershipTypeJSON.represntative.displayName);
                option2Label = option2Label.replace('@membertype', membershipTypeJSON.represntative.displayName);
            }
            radioOptions = [{ label: option1Label, value: UpdateActionsEnum.UpdateOnlyOrg }, { label: option2Label, value: UpdateActionsEnum.UpdateOrgWithAnotherRep }];
        }
        // Show message when organization is associated with a Rep
        else {
            descMessage = getMessage(messageCodes, '9035.text');
            descMessage = descMessage.replace('@Representative', initialValues.RepName);
            let option1Label = getMessage(messageCodes, '9036.text');
            let option2Label = getMessage(messageCodes, '9037.text');
            if (initialValues.RepMembershipTypeId === MembershipTypes.cooperativeAgreementRep) {
                option1Label = option1Label.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
                option2Label = option2Label.replace('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
            } else {
                option1Label = option1Label.replace('@membertype', membershipTypeJSON.represntative.displayName);
                option2Label = option2Label.replace('@membertype', membershipTypeJSON.represntative.displayName);
            }
            option2Label = option2Label.replace('@Representative', initialValues.RepName);
            let option3Label = getMessage(messageCodes, '9038.text');

            radioOptions = [{ label: option1Label, value: UpdateActionsEnum.UpdateOrgAndRep }, { label: option2Label, value: UpdateActionsEnum.UpdateOrgWithAnotherRep }, { label: option3Label, value: UpdateActionsEnum.UpdateOnlyOrg }];
        }

        self.setState({ descMessage, radioOptions, showHistoricalOrgConfirmation: true });
    }
    else {
        self.setState({ selectedStatus: value });
    }
}

export const onCloseHistoricalOrgModal = (value) => {
    selectedRepAccountNumber = '';
    selectedMemberTypeId = '';

    // In case user clicked on Cancel button
    if (!value) {
        self.props.onCloseEditAccountInfo();
    } else if (value === UpdateActionsEnum.UpdateOrgAndRep) {
        // Set status to Active of both rep and org
        isRepStatusHistorical = false;
        self.setState({ selectedStatus: ActiveOrganizationStatusId });
    } else if (value === UpdateActionsEnum.UpdateOrgWithAnotherRep) {
        // Open modal to associate another rep member
        self.setState({ showAssociateRepModal: true });
    } else {
        // Remove association and set status to Active
        isRepStatusHistorical = !!self.props.initialValues.RepAccountNumber;
        self.setState({ selectedStatus: ActiveOrganizationStatusId });
    }

    self.setState({ showHistoricalOrgConfirmation: false });
}

export const onCloseAssociateRepModal = (value, memberTypeId) => {
    if (value) { // Value is true when we select a Rep who is already associated with another Organization

        if (value === true) {
            selectedRepAccountNumber = '';
            selectedMemberTypeId = '';
            isRepStatusHistorical = true;
        } else {
            selectedRepAccountNumber = value;
            selectedMemberTypeId = memberTypeId;
            isRepStatusHistorical = !!self.props.initialValues.RepAccountNumber;
        }

        self.setState({ selectedStatus: ActiveOrganizationStatusId });
    } else {
        self.props.change('McsStatusMasterId', historicalStatusId);
    }

    self.setState({ showAssociateRepModal: false });
}

// Validation method for validate all value as per requirement
export const validateHandler = (values, props) => {
    const errors = {};

    if(props.initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY){
        if (values.McsStatusMasterId !== ActiveOrganizationStatusId && !values.HistoricalReasonId) {
            errors.HistoricalReasonId = getMessage(props.messageCodes, '8124.text');
        }

        if ((!values.UpdateReason) || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
            errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
        }
    }
    if(props.initialValues.CompanyTypeId === COMPANY_TYPE.FACILITY){
        if (!values.HistoricalReasonId) {
            errors.HistoricalReasonId = getMessage(props.messageCodes, '8124.text');
        }

        if ((!values.UpdateReason) || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
            errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
        }
    }

    return errors;
}

export const onSubmitHandler = (data) => {
    const { initialValues } = self.props;
    if(initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY){
        // Check if any data is updated
        if (initialValues.McsStatusMasterId !== data.McsStatusMasterId || initialValues.PaidStatus !== data.PaidStatus ||
            (initialValues.HistoricalReasonId !== data.HistoricalReasonId && initialValues.McsStatusMasterId !== ActiveOrganizationStatusId)) {
            let requestData = {
                MemberTypeId: MembershipTypes.Representative,
                PaidStatus: data.PaidStatus,
                IsRepStatusHistorical: isRepStatusHistorical,
                RepAccountNumber: selectedRepAccountNumber,
                StatusIdOld: initialValues.McsStatusMasterId,
                StatusId: data.McsStatusMasterId,
                activeStatusId,
                historicalStatusId,
                HistoricalReasonId: data.McsStatusMasterId === ActiveOrganizationStatusId ? null : data.HistoricalReasonId,
                OldMemberTypeName: getMembershipTypeName(selectedMemberTypeId, self.props.membershipTypeList),
                NewMemberTypeName: getMembershipTypeName(MembershipTypes.Representative, self.props.membershipTypeList),
                UpdateReason: data.UpdateReason
            }

            self.props.updateOrganizationAccountDetails(data.AccountNumber, requestData, () => {
                self.props.getRepresentativeHistory(data.AccountNumber);
                self.props.getOrganizationDetails(data.AccountNumber);
                self.props.onCloseEditAccountInfo();
            })
        } else {
            self.props.displayNoChangesMadeMessage();
            self.props.onCloseEditAccountInfo();
        }
    } else {
        // Check if any data is updated
        if (initialValues.HistoricalReasonId !== data.HistoricalReasonId) {
            let requestData = {
                HistoricalReasonId: data.HistoricalReasonId,
                UpdateReason: data.UpdateReason
            }

            self.props.updateOrganizationAccountDetails(data.AccountNumber, requestData, () => {
                self.props.getRepresentativeHistory(data.AccountNumber);
                self.props.getOrganizationDetails(data.AccountNumber);
                self.props.onCloseEditAccountInfo();
            })
        } else {
            self.props.displayNoChangesMadeMessage();
            self.props.onCloseEditAccountInfo();
        }
    }
}
