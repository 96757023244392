import * as _actions from 'action-types';
import * as commonActions from 'common.actions';
import _ from 'lodash';
import { connect } from "react-redux";
import { formValueSelector, getFormValues } from "redux-form";
import memberDetailsActions from '../../members/actions/member-details.actions';
import memberActions from '../../members/actions/members.actions';
import organizationDetailsActions from '../actions/organization-details.actions';
import OrganizationDetails from '../component/organization-details';

const selector = formValueSelector("OrganizationEditGenInfoForm");

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        initialValues: state.organizationDetailsReducer.organizationDetails,
        organizationUserList: state.organizationDetailsReducer.organizationUserList,
        membershipTypeList: state.memberReducer.membershipTypeList,
        organizationRepHistoryList: state.organizationDetailsReducer.organizationRepHistoryList,
        historicalReasonList: state.organizationDetailsReducer.historicalReasonList,
        historicalFacilityReasonList: state.organizationDetailsReducer.historicalFacilityReasonList,
        organizationAuditLogFieldList: state.organizationDetailsReducer.organizationAuditLogFieldList,
        accountStatusAuditFieldName: state.memberDetailsReducer.accountStatusAuditFieldName,
        paidStatusAuditFieldName: state.memberDetailsReducer.paidStatusAuditFieldName,
        organizationAccountHistoryList: state.memberDetailsReducer.memberAccountHistoryList,
        organizationEditFormValues: selector(state, 'AccountNumber', 'MemberTypeId', 'UpdateReason'),
        userPermission: state.commonReducer.userPermission,
        memberMasterDetails: state.memberDetailsReducer.memberMasterDetails,
        countryList: state.commonReducer.countryList,
        originalCountryList: state.commonReducer.originalCountryList,
        activeCountryList: state.commonReducer.activeCountryList,
        originalActiveCountryList: state.commonReducer.originalActiveCountryList,
        usStateList: state.commonReducer.usStateList,
        canadaStateList: state.commonReducer.canadaStateList,
        orgFormValues: getFormValues('OrganizationInfoEdit')(state),
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getOrganizationDetails: (accountNumber, companyId = 0) => {
        organizationDetailsActions.getOrganizationPersonalDetailsAction(accountNumber, companyId, dispatch)
    },
    getMemberMasterData: (accountNumber) => {
        memberDetailsActions.getMemberMasterDataAction(accountNumber, dispatch)
    },
    getMembershipTypeList: () => {
        memberActions.getMembershipTypeListAction(dispatch)
    },
    getOrganizationMasterData: () => {
        organizationDetailsActions.getOrganizationMasterDataAction(dispatch)
    },
    showSuccessCopyMessage: () => {
        dispatch(commonActions.setMessage(true, '2037'));
    },
    resetDetailsData: () => {
        dispatch({ type: _actions.RESET_ORGANIZATION_DETAILS_DATA })
    },
    getUserName: (userId, callback) => {
        commonActions.getUserNameAction(userId, callback)
    },
    getRepresentativeHistory: (accountNumber, companyId = 0) => {
        organizationDetailsActions.getRepresentativeHistoryAction(accountNumber, companyId, dispatch)
    },
    getOrganizationCommunicationLog: (accountNumber, pageSize, pageNumber, showLoader, companyId = 0, callback) => {
        organizationDetailsActions.getOrganizationCommunicationLogAction(accountNumber, pageSize, pageNumber, showLoader, companyId, callback, dispatch)
    },
    addOrganizationCommunicationLog: (data, callback) => {
        organizationDetailsActions.addOrganizationCommunicationLogAction(data, callback, dispatch)
    },
    updateOrganizationCommunicationLog: (commentId, data, isOwnComment, callback) => {
        organizationDetailsActions.updateOrganizationCommunicationLogAction(commentId, data, isOwnComment, callback, dispatch)
    },
    removeOrganizationCommunicationLog: (commentId, data, isOwnComment, callback) => {
        organizationDetailsActions.removeOrganizationCommunicationLogAction(commentId, data, isOwnComment, callback, dispatch)
    },
    showAlertPopup: (alertMessage) => {
        dispatch(commonActions.showAlertPopup(true, alertMessage));
    },
    getMemberOrgActiveMapping: (searchText, accountNumber, callback) => {
        organizationDetailsActions.getMemberOrgActiveMappingAction(searchText, accountNumber, callback, dispatch)
    },
    updateOrgRepMapping: (accountNumber, data, callback) => {
        organizationDetailsActions.updateOrgRepMappingAction(accountNumber, data, callback, dispatch)
    },
    updateOrganizationAccountDetails: (accountNumber, data, callback) => {
        organizationDetailsActions.updateOrganizationAccountDetailsAction(accountNumber, data, callback, dispatch)
    },
    getOrganizationAuditLog: (accountNumber, requestData, callback) => {
        memberDetailsActions.getMemberAuditLogAction(accountNumber, requestData, callback, dispatch)
    },
    getOrganizationAssociatedMembers: (accountNumber, requestData, companyId = 0, callback) => {
        memberDetailsActions.getAssociatedMembersAction(accountNumber, requestData, companyId, callback, dispatch)
    },
    displayNoChangesMadeMessage: () => {
        dispatch(commonActions.setMessage(true, '2040'));
    },
    getOrganizationAccountHistoryDetails: (accountNumber, accountStatusAuditFieldName, paidStatusAuditFieldName) => {
        memberDetailsActions.getMemberAccountHistoryDetailsAction(accountNumber, accountStatusAuditFieldName, paidStatusAuditFieldName, dispatch);
    },
    checkIsCompanyAndAddressDetailsExistsAction: (payload, callback) => {
        organizationDetailsActions.checkIsCompanyAndAddressDetailsExistsAction(payload, callback, dispatch)
    },
    showMessageForNoChangeInCompanyInfoForm: () => {
        dispatch(commonActions.setMessage(true, '2052'));
    },
    setSafariAutoFillInfo: (flag) => {
        commonActions.setSafariAutoFillInfoAction(dispatch, flag)
    },
    getCompanyAssociatedMembersDetails: (payload, callback) => {
        memberDetailsActions.getCompanyAssociatedMembersDetails(payload, callback, dispatch)
    },
    updateOrganization: (payload, callback) => {
        commonActions.updateOrganizationAction(payload, callback, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);
