import * as _action from 'action-types';
import { checkIfUserDidAdvanceSearch, checkUserPermissionOnPage, extractValueFromUrl, formatDateToMMYYYY, getCurrentlyShowingItemsInGrid, getListItemTextByValue, getMessage, getUrlForExportData, replaceUrlParam, scrollToTopOfPage, setupDataForExcelExportFromCosmos, updateGridProps, validateExportableFields } from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds, McsStatusMasterId } from 'models/common.models';
import { COMMITTEE_LEVELS } from '../../helpers/util-common';
import { CommitteeClassificationOptions, CommitteeStatusOptions } from './committee.model';

let self = {};
let extractedStatusValueFromUrl = '';
let extractedHierarchyValueFromUrl = '';

export const setClassInstance = (instance) => {
    self = instance;

    const { bosVolumeMasterList } = self.props;
    extractedStatusValueFromUrl = '';
    extractedHierarchyValueFromUrl = '';
    loadInitialData();
    setUserAccess(self.props.userPermission);

    if (!bosVolumeMasterList || (bosVolumeMasterList.length === 0)) {
        self.props.getBosVolumeList();
    }
}

export const loadData = (pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy, showPageLoader = false) => {
    const { committeeGridPreferences } = self.props;
    const query = getQuery(pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy);

    if (!showPageLoader) {
        self.setState({ showLoader: true });
    }

    // If grid preferences alerady exist, then just get the table data
    if (committeeGridPreferences && committeeGridPreferences.length > 0) {
        self.props.getCommitteeList(query, showPageLoader, (response) => {
            handleCommitteeListDataResponse(response, committeeGridPreferences, pageSize, pageNumber);
            self.setState({
                tableHeaderConfig: committeeGridPreferences
            })
        })
    } else {
        self.props.getCommitteeListAndGridPreferences(query, showPageLoader, AppGridIds.CommitteeGrid, (response) => {
            handleCommitteeListDataResponse(response.committeeList, response.committeeGridPreference, pageSize, pageNumber);
            self.setState({
                tableHeaderConfig: response.committeeGridPreference
            })
        })
    }
}

export const handleSearchUpdate = (nextProps) => {
    const { currentSearchType, currentModuleTitle } = nextProps;

    if (nextProps.location.search !== self.props.location.search && currentSearchType === _action.SEARCH_COMMITTEES) {
        const { pageSize, sortedColumn, sortOrder } = self.state;
        const searchText = nextProps.location.search.length > 0 ? nextProps.location.search.substring(1) : '';
        let selectedStatus = getStatusFromUrl(searchText);
        let selectedHierarchy = getHierarchyFromUrl(searchText);

        self.setState({
            searchText,
            activePageNumber: 1,
            isTableHeaderReset: false,
            isFilterChanged: false,
            selectedStatus,
            selectedHierarchy
        });

        loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy);
    }

    // Recheck the user permissions when user click on Back button of window tab
    if (currentModuleTitle !== self.props.currentModuleTitle) {
        setUserAccess(nextProps.userPermission);
    }
}

export const onChangeStatusType = (e, value) => {
    const { pageSize, selectedHierarchy, sortedColumn, sortOrder, searchText } = self.state;
    loadData(1, pageSize, searchText, sortedColumn, sortOrder, value, selectedHierarchy);

    self.setState({ selectedStatus: value, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true });
}

export const onChangeHierarchy = (value) => {
    const { pageSize, selectedStatus, sortedColumn, sortOrder, searchText } = self.state;
    loadData(1, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, value);

    self.setState({ selectedHierarchy: value, selectedStatus, activePageNumber: 1, isTableHeaderReset: false, isFilterChanged: true });
}

export const onChangeNumberOfItemsPerPage = (e, value) => {
    const { sortedColumn, sortOrder, searchText, selectedStatus, selectedHierarchy } = self.state;

    loadData(1, value, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy);
    self.setState({ pageSize: value, activePageNumber: 1, isTableHeaderReset: false });
}

export const onChangeSortHandler = (sortedColumn, sortOrder) => {
    const { activePageNumber, pageSize, searchText, selectedStatus, selectedHierarchy } = self.state;
    self.setState({ sortedColumn, sortOrder, isTableHeaderReset: false });

    // Load new data
    loadData(activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy);
}

export const onPageChangeHandler = (event, data) => {
    const { pageSize, searchText, sortedColumn, sortOrder, totalPages, selectedStatus, selectedHierarchy } = self.state;

    if (totalPages > 1) {
        loadData(data.activePage, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy);
    }

    self.setState({ activePageNumber: data.activePage, isTableHeaderReset: false })
}

export const resetGridTableData = () => {
    const { activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedHierarchy, selectedStatus } = self.state;
    const query = getQuery(activePageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy);
    self.props.getCommitteeListAndGridPreferences(query, false, AppGridIds.CommitteeGrid, (response) => {
        handleCommitteeListDataResponse(response.committeeList, response.committeeGridPreference, pageSize, activePageNumber);
        self.setState({
            tableHeaderConfig: response.committeeGridPreference
        })
    })
}

export const saveGridPreferenceClickAction = () => {
    const { gridProp } = self.state;
    self.setState({ isShowGridMenu: false });
    self.props.updateGridPreference(AppGridIds.CommitteeGrid, gridProp.requestSaveParams, (result) => {
        if (result) {
            self.setState({ isShowGridMenu: true }, () => resetGridTableData());
        } else {
            self.setState({ isShowGridMenu: false });
        }
    });
}

// Http Request for Export Data
export const getCommitteeExportExcel = (e) => {
    const url = getUrlForExportData(self.state.totalItems);
    self.props.getCommitteeExportData(url, getExportResponse);

    self.setState({
        showLoader: true
    });
}

export const emptyExcelCancelHandler = () => {
    self.setState({
        showEmptyExcelConfirmation: false
    });
}

export const emptyExcelConfirmHandler = () => {
    self.setState({
        showEmptyExcelConfirmation: false
    }, () => excelExportSetup([])); // Exported Empty File
}

export const addCommitteeClickHandler = () => {
    self.setState({ showAddCommitteeModal: true, showAddCommitteePage: true });
}

export const onCloseAddCommitteeModal = () => {
    self.setState({ showAddCommitteeModal: false });
}

export const onSubmitAddCommitteeModal = (data) => {
    const { bosVolumeMasterList } = self.props;
    let list = []
    if (data && data.committeeTypeId) {
        list = [...bosVolumeMasterList]
        if (data.newLevelDetails && (data.newLevelDetails.levelId === COMMITTEE_LEVELS.SUBCOMMITTEE || data.newLevelDetails.levelId === COMMITTEE_LEVELS.SECTION)) {
            data.bosVolumeList = list;
        } else {
            list.unshift({ key: 0, text: 'Select', value: 0 })
            data.bosVolumeList = list;
        }
    }
    self.setState({ addCommitteeData: data, showAddCommitteeModal: false, showActiveClassInAddCommitteePage: true });
}

export const onCloseAddCommitteePage = (isReloadList) => {
    self.setState({ showActiveClassInAddCommitteePage: false, showAddCommitteePage: false });

    if (isReloadList) {
        loadInitialData();
    }
}

const loadInitialData = () => {
    if (!self.props.committeeTypeList || !self.props.hierarchyList || !self.props.overviewTagFieldList) {
        self.props.getMasterDataForCommitteeList();
    }
    // Set the default for addCommitteeData
    let { addCommitteeData } = self.state;
    addCommitteeData = {
        newLevelDetails: {},
        parentCommitteeLevelsDetails: {},
        bosVolumeList: []
    }

    const { pageSize, searchText } = self.state;
    const selectedStatus = getStatusFromUrl(searchText);
    const selectedHierarchy = getHierarchyFromUrl(searchText);
    loadData(1, pageSize, searchText, '', 0, selectedStatus, selectedHierarchy, true);
    self.setState({ isTableHeaderReset: true, selectedStatus, selectedHierarchy, addCommitteeData });
}

const getQuery = (pageNumber, pageSize, searchText, sortedColumn, sortOrder, selectedStatus, selectedHierarchy) => {
    let query = `gridId=${AppGridIds.CommitteeGrid}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    if (searchText && searchText.length > 0) {
        query = `${query}&${searchText}`;
    }
    if (sortedColumn && sortedColumn.length > 0) {
        query = `${query}&sortedColumn=${sortedColumn}`;
    }
    let order = sortOrder === 2 ? 'desc' : 'asc';
    query = `${query}&sortOrder=${order}`;

    if (selectedStatus >= 0) {
        if (extractedStatusValueFromUrl === null) {
            query = `${query}&${AdvanceSearchQueryFields.Status}=${selectedStatus}`;
        } else if (extractedStatusValueFromUrl !== null && Number(extractedStatusValueFromUrl) !== Number(selectedStatus)) {
            query = replaceUrlParam(query, AdvanceSearchQueryFields.Status, selectedStatus);
        }
    }
    if (selectedHierarchy && selectedHierarchy.length > 0) {
        if (extractedHierarchyValueFromUrl === null) {
            query = `${query}&${AdvanceSearchQueryFields.CommitteeLevel}=${selectedHierarchy.join(',')}`;
        } else if (extractedHierarchyValueFromUrl !== null) {
            query = replaceUrlParam(query, AdvanceSearchQueryFields.CommitteeLevel, selectedHierarchy.join(','));
        }
    } else if (extractedHierarchyValueFromUrl === 'all') {
        query = replaceUrlParam(query, AdvanceSearchQueryFields.CommitteeLevel, '');
    }

    return query;
}

const getStatusFromUrl = (searchText) => {
    extractedStatusValueFromUrl = extractValueFromUrl(searchText, AdvanceSearchQueryFields.Status);
    return extractedStatusValueFromUrl !== null ? extractedStatusValueFromUrl : (searchText.length === 0 ? CommitteeStatusOptions[1].value : CommitteeStatusOptions[0].value);
}

const getHierarchyFromUrl = (searchText) => {
    extractedHierarchyValueFromUrl = extractValueFromUrl(searchText, AdvanceSearchQueryFields.CommitteeLevel)
    if (extractedHierarchyValueFromUrl && extractedHierarchyValueFromUrl !== 'all') {
        return extractedHierarchyValueFromUrl.split(',');
    } else if (extractedHierarchyValueFromUrl === 'all') {
        return null;
    }
    return searchText.length === 0 ? ['1'] : null;
}

const handleCommitteeListDataResponse = (committeeData, gridHeader, pageSize, pageNumber) => {
    let committeeList = [];
    let count = 0;

    if (committeeData && committeeData.Count && committeeData.Committees) {
        committeeList = committeeData.Committees;
        count = committeeData.Count
    }

    if (count > pageSize) {
        self.state.showPager = true;
    }

    setAllGridPreference(committeeList, gridHeader, count);
    updateCurrentlyShowingItems(count, pageSize, pageNumber);
    // Whenever data is refreshed, Move the scroll to top.
    scrollToTopOfPage();
}

const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
    self.setState({ currentlyShowingItems, totalPages });
}

const setAllGridPreference = (committeeList, gridHeader, count) => {
    const { gridProp, isFilterChanged } = self.state;
    let columnKeys = [];
    if (self.state.isTableHeaderReset) { // Worked only isTableHeaderReset = true;
        // Set empty object for set all the grid table values
        let tableConfigProps = {
            attributes: {}, displayName: {}, expandables: [], sortables: {}, omitOnMenu: [], excelExportedColumn: [], emptyMessage: checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? getMessage(self.props.messageCodes, '9013.text') : getMessage(self.props.messageCodes, '9011.text'), records: committeeList, excelExportableFieldsCallback: excelExportableFieldsCallback
        }

        updateGridProps(gridHeader, tableConfigProps, columnKeys);

        self.setState({
            isTableHeaderReset: false,
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp: tableConfigProps
        }, () => setGridPreferenceParams(columnKeys)); // Get visible columns in grid section
    } else {
        // Update only grid records
        gridProp.records = committeeList;
        updateGridProps(gridHeader, gridProp, columnKeys);
        gridProp.emptyMessage = checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? getMessage(self.props.messageCodes, '9013.text') : getMessage(self.props.messageCodes, '9011.text');
        self.setState({
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp
        }); // Get visible columns in grid section
    }
}

const setGridPreferenceParams = (params) => {
    const { gridProp } = self.state;
    let gridConfigParams = { "Columns": params };
    gridProp.requestSaveParams = gridConfigParams;
    self.setState({
        gridProp
    })
}

const excelExportableFieldsCallback = (fields) => {
    let exportFields = {};
    let column = [];
    let columnsKeys = [];

    const { gridProp } = self.state;
    if (fields !== null) {
        for (let keys in fields) {
            if (fields[keys]) {
                columnsKeys.push(keys);
                exportFields[keys] = fields[keys];
                column.push({ title: gridProp.displayName[keys], fieldsKey: keys, style: { font: { bold: true } } })
            }
        }
        gridProp.excelExportedColumn = column;
        self.setState({
            gridProp
        }, () => setGridPreferenceParams(validateExportableFields(self.state.tableHeaderConfig, columnsKeys)))
    }
}

// Exported Response in Callback : -
const getExportResponse = (response) => {
    if (response && response.Committees && response.Committees.length > 0) {
        excelExportSetup(response.Committees);
    } else {
        self.setState({
            showEmptyExcelConfirmation: true,
            showLoader: false
        })
    }
}

const excelExportSetup = (result) => {
    const { gridProp } = self.state;

    let data = [];

    if (result && result.length > 0) {
        result.map((item) => {
            let commmitteeDetail = item;

            if (commmitteeDetail.CommitteeDetail_Status === McsStatusMasterId.InactiveCommmittee) {
                const committeeInactive = ((commmitteeDetail.CommitteeDetail_InactiveReason || '').toLowerCase() === "merged") ? (`${'Merged with ' + (commmitteeDetail.CommitteeDetail_MergedCommittee || 'Merged')} on ` + formatDateToMMYYYY(commmitteeDetail.CommitteeDetail_InactiveDate || new Date())) :
                    (commmitteeDetail.CommitteeDetail_Status === 3 && (commmitteeDetail.CommitteeDetail_InactiveReason || '').toLowerCase() === "discharged") ? (`${'Discharged on ' + formatDateToMMYYYY(commmitteeDetail.CommitteeDetail_InactiveDate || new Date())}`) : '';

                commmitteeDetail.CommitteeTitle = committeeInactive !== '' ? commmitteeDetail.CommitteeDetail_CommitteeTitle + ` (${committeeInactive})` : commmitteeDetail.CommitteeDetail_CommitteeTitle;
            }

            if (commmitteeDetail.CommitteeDetail_CommitteeClassification) {
                commmitteeDetail.CommitteeDetail_CommitteeClassification = getListItemTextByValue(CommitteeClassificationOptions, commmitteeDetail.CommitteeDetail_CommitteeClassification, 'text');;
            }

            data.push(commmitteeDetail);
        })
    }

    let dataSet = setupDataForExcelExportFromCosmos(gridProp, data);

    self.setState({
        showLoader: false,
        exportDataSet: dataSet,
        isCommitteeExcelExported: true
    }, () => resetExportComponent());
}

const resetExportComponent = () => {
    setTimeout(() => {
        self.setState({
            isCommitteeExcelExported: false
        })
    }, 100);
}

const setUserAccess = (userPermission) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;

    self.setState({
        hasExportAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EXPORT),
        hasAddAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD)
    });
}
