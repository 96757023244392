import React, { Component } from 'react';
import { Modal, Grid, Form } from 'semantic-ui-react';

class AddressValidationConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cleansedStoreValue: "false"
        }
    }

    updateAddress = (e) => {
        this.setState({ cleansedStoreValue: e.target.value })
    }
    onSubmitVerifyAddress = (action) => {
        this.props.closeModal(this.state.cleansedStoreValue, 'submit')
    }

    onCancelVerifyAddress = () => {
        this.props.closeModal(this.state.cleansedStoreValue, 'cancel')
    }

    render() {
        const { message, content } = this.props
        const { providedAddress, validateAddress } = content
        return (
            <Modal open='true' className="tiny errormodalbox" data-testid="addressValidationConfirmPopup">
                <Modal.Header>Confirm Address<i onClick={(e) => this.onCancelVerifyAddress() } className="close" title="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <>
                            <p>{message}</p>
                            <Form onSubmit={ this.onSubmitVerifyAddress}>
                                <Grid columns='equal'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="true"
                                                        name="addressCheckBox"
                                                        checked={this.state.cleansedStoreValue === "true"}
                                                        onChange={e => this.updateAddress(e)}
                                                    />
                                                    <span className='display-inline-block ml8'>Address Entered</span>
                                                </label>
                                            </div>
                                            <div className='ml20 mt10'>
                                                <div>{ providedAddress.AddressLine1 }</div>
                                                <div>{ providedAddress.AddressLine2 }</div>
                                                <div>{ providedAddress.City }</div>
                                                <div>{ `${ providedAddress.StateName } , ${providedAddress.PostalCode}` }</div>
                                                <div>{ providedAddress.CountryCode === 'US' ? 'United States' : 'Canada' }</div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="false"
                                                        name="addressCheckBox"
                                                        checked={ this.state.cleansedStoreValue === "false"}
                                                        onChange={ e => this.updateAddress(e)}
                                                    />
                                                    <span className='display-inline-block ml8'>Suggested Address</span>
                                                </label>
                                            </div>
                                            <div className='ml20 mt10'>
                                                <div>{ validateAddress.AddressLine1 }</div>
                                                <div>{ validateAddress.AddressLine2 }</div>
                                                <div>{ validateAddress.City }</div>
                                                <div>{ `${ validateAddress.StateName }, ${validateAddress.PostalCode}` }</div>
                                                <div>{ validateAddress.CountryCode === 'US' ? 'United States' : 'Canada' }</div>
                                            </div>
                                        </Grid.Column>

                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <button data-testid='btnYes' className="ui button primary" onClick={ this.onSubmitVerifyAddress} >USE SELECTED ADDRESS</button>
                    <button data-testid='btnCancel' className="ui button cancel" onClick={this.onCancelVerifyAddress} >Cancel</button>
           </Modal.Actions>
            </Modal>
        )
    }
}

export default AddressValidationConfirmPopup;
