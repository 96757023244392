import { allowOnlyPositiveNumericInput, encrypt, getMessage, validateOnlyPositiveNumericInput } from 'helpers/util-common';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal } from 'semantic-ui-react';
import RenderInput from 'shared-components/Input';
import TextArea from 'shared-components/TextArea';

const validateHandler = (values, props) => {
    let errors = {};

    if (!validateOnlyPositiveNumericInput(values.NumberOfAttendees)) {
        errors.NumberOfAttendees = getMessage(props.messageCodes, '4001.text');
    }

    if (!validateOnlyPositiveNumericInput(values.NumberOfMeetingDays)) {
        errors.NumberOfMeetingDays = getMessage(props.messageCodes, '4001.text');
    }

    if (!values.UpdateReason || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}

class EditMeetingsGenInfoModal extends PureComponent {

    onSubmitHandler = (data) => {
        let { NumberOfAttendees: oldNumberOfAttendees, NumberOfMeetingDays: oldNumberOfMeetingDays } = this.props.initialValues;
        let { NumberOfAttendees, NumberOfMeetingDays } = data;

        if (Number(NumberOfAttendees) !== Number(oldNumberOfAttendees) || Number(NumberOfMeetingDays) !== Number(oldNumberOfMeetingDays)) {
            let requestData = {
                NumberOfAttendees: Number(data.NumberOfAttendees),
                NumberOfMeetingDays: Number(data.NumberOfMeetingDays),
                UpdateReason: data.UpdateReason
            }
            const committeeId = encrypt(this.props.initialValues.CommitteeId);

            this.props.updateCommitteeMeetingAndAttendees(committeeId, requestData, () => {
                this.props.onSubmit();
            })
        } else {
            this.props.displayNoChangesMadeMessage();
            this.props.onClose();
        }
    }

    render() {
        const { onClose, handleSubmit, submitting } = this.props;

        return (
            <Modal open='true' className="tiny">
                <Form size='large' onSubmit={handleSubmit(this.onSubmitHandler)}>
                    <Modal.Header>UPDATE <i class="close" data-testid="closeBtn" onClick={onClose}>&#x2716;</i></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name='NumberOfAttendees' type="text"
                                            label='Average Number of Attendees'
                                            component={RenderInput} maxLength={5}
                                            onKeyPress={allowOnlyPositiveNumericInput}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field name='NumberOfMeetingDays' type="text"
                                            label='Number of Meeting Days'
                                            component={RenderInput} maxLength={3}
                                            onKeyPress={allowOnlyPositiveNumericInput}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field component={TextArea} type='text' label='Reason for update'
                                            name='UpdateReason' required={true} maxLength='200'
                                            placeholder="Please enter reason for update"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="mt10">
                            <button className="ui button primary" disabled={submitting} type="submit">Update</button>
                            <button className="ui button cancel" onClick={onClose}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal >
        )
    }
}

export default reduxForm({
    form: 'EditMeetingsGenInfoModal',
    validate: validateHandler
})(EditMeetingsGenInfoModal);
