import { handleAutoComplete, handleOnChangeOrgName, FORM_TYPE_ORG_UPDATE } from 'helpers/util-common';
import React from "react";
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import RenderInputField from 'shared-components/Input';
import AutoSearch from 'shared-components/Search';
import RenderSelect from 'shared-components/Select';
import ReduxZipcode from 'shared-components/ZipCodeInput';
import * as _func from './function';
class CommonFormOrganizationActivity extends React.Component {

    state = {
        organizationList: [],
        addressList: [],
        filteredAddressList: [],
        isAddressFieldEmpty: true,
        isAddressLineSearchOpen: false,
        isUnitedstatesOrCanadaSelected: false,
        isUSSelected: false,
        isOrgLoading: false,
        selectedCountry: null,
        maxLength: 9
    }

    resetState = () => {
        this.setState({
            addressList: [],
            filteredAddressList: [],
            isAddressFieldEmpty: true,
            isAddressLineSearchOpen: false,
            isUnitedstatesOrCanadaSelected: false,
            isUSSelected: false,
            isOrgLoading: false,
            maxLength: 9
        })
        this.props.change('manualOrderOrggNam', '');
        _func.setAddressFields({})
    }
    componentDidMount() {
        handleAutoComplete('off')
        _func.prefillOrganizationDetailsInOnboardingProcess();
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.checkStateConditions !== nextProps.checkStateConditions) {
            const { postData, checkStateConditions } = nextProps
            if (postData && postData.orgCountry && postData.State) {
                let { orgCountry, State } = postData;
                if (checkStateConditions && orgCountry) {
                    _func.getStateValue(_func.getCountryNameOrCode(orgCountry, 'name'), State)
                }
            }
        }
        if (JSON.stringify(this.props.postData) !== JSON.stringify(nextProps.postData)) {
            _func.handlePostData(nextProps);
        }

        if (this.props.isManualOrder && (this.props.manualOrderFormData?.orgCountryCode !== nextProps.manualOrderFormData?.orgCountryCode)) {
            if (nextProps?.manualOrderFormData && nextProps?.manualOrderFormData?.orgCountryCode) {
                const { manualOrderFormData: { orgCountryCode, manualOrderstatpro } } = nextProps;
                _func.getStateValue(orgCountryCode, manualOrderstatpro);
            }
        }
    }
    render() {
        const { formData, isEditing, isManualOrder, disabledForm, orgFormValues, manualOrderFormData, formType } = this.props;
        const { formLabels: { orgLabel, orgHint, addressLine1Label }, countryStateData: { activeCountryList, usStateList, canadaStateList } } = formData;
        const { isUnitedstatesOrCanadaSelected, isUSSelected, isAddressLineSearchOpen, filteredAddressList, isOrgLoading, organizationList, maxLength } = this.state;
        return (
            <div><Grid columns='equal' className="mt0">
                <Grid.Row>
                    <Grid.Column data-testid="manualOrderOrggNamColumn">
                        {isEditing ? (
                            <Field
                                name="manualOrderOrggNam"
                                required={true}
                                label={orgLabel}
                                placeholder={orgHint}
                                component={RenderInputField}
                                offAutoComplete={true}
                                disabled={disabledForm}
                                className="autocompleteOff"
                                maxLength={200}
                            />
                        ) : (
                            <>
                                <label class="label"><i aria-hidden="true" class="asterisk  icon"></i>{orgLabel}</label>
                                <Field name="manualOrderOrggNam"
                                    type="text"
                                    component={AutoSearch}
                                    placeholder={orgHint}
                                    required={true}
                                    source={organizationList}
                                    showNoResults={false}
                                    isLoading={isOrgLoading}
                                    disabled={disabledForm}
                                    onChange={(e, value) => { 
                                        this.props.setInvalidManualOrgMessage(); 
                                        handleOnChangeOrgName(this, value, true) 
                                    }}
                                    onResultSelect={_func.handleOnResultSelect}
                                    className="autocompleteOff"
                                    maxLength={200}
                                />
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <label class="label"><i aria-hidden="true" class="asterisk  icon"></i>{addressLine1Label}</label>
                        <Field name="manualOrderOrgAdrLine1"
                            type="text"
                            component={AutoSearch}
                            placeholder={isManualOrder ? 'Click here to type' : addressLine1Label}
                            required={true}
                            source={filteredAddressList}
                            showNoResults={false}
                            open={isAddressLineSearchOpen}
                            disabled={disabledForm}
                            onChange={_func.handleOnChangeAddressLine1}
                            onResultSelect={_func.handleOnResultSelectAddressLine1}
                            onFocus={() => { _func.setAddressLine1Visibility(true) }}
                            onBlur={() => { _func.setAddressLine1Visibility(false) }}
                            className="autocompleteOff"
                            maxLength={200}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Field
                            name="manualOrderOrgAdrLine2"
                            label="Address Line 2"
                            placeholder={isManualOrder ? 'Click here to type' : 'Address Line 2'}
                            component={RenderInputField}
                            offAutoComplete={true}
                            disabled={disabledForm}
                            maxLength={200}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns='equal' className="mt0">
                <Grid.Row >
                    <Grid.Column>
                        <Field
                            name="manualOrderOrgCty"
                            label="City" placeholder={isManualOrder ? 'Click here to type' : 'City'}
                            component={RenderInputField}
                            required={true}
                            offAutoComplete={true}
                            disabled={disabledForm}
                            maxLength={60}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Field name="orgCountry"
                            component={RenderSelect} label="Country"
                            placeholder='Please Select Country'
                            required={true}
                            options={activeCountryList}
                            onChange={(e, value) => { _func.onCountryChange(value, false) }}
                            disabled={disabledForm}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns='equal' className="mt0">
                <Grid.Row >
                    <Grid.Column>
                        {isUnitedstatesOrCanadaSelected ?
                            <Field
                                name="manualOrderstatpro"
                                component={RenderSelect}
                                label="State/Province"
                                placeholder={isManualOrder ? 'Click here to type' : 'State/Province'}
                                required={true}
                                options={isUSSelected ? usStateList : canadaStateList}
                                className="autocompleteOff"
                                disabled={disabledForm}
                            /> :
                            <Field name='manualOrderstatpro'
                                type="text"
                                label='State/Province' placeholder={isManualOrder ? 'Click here to type' : 'State/Province'}
                                component={RenderInputField} maxLength={60}
                                offAutoComplete={true}
                                disabled={disabledForm}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column>
                        <Field
                            name="manualOrderZpcd"
                            required={isUnitedstatesOrCanadaSelected}
                            component={ReduxZipcode}
                            placeholder={isManualOrder ? 'Click here to type' : "Zip/Postal Code"}
                            label="Zip/Postal Code"
                            offAutoComplete={true}
                            disabled={disabledForm}
                            maxLength={maxLength}
                            orgFormValues={orgFormValues || manualOrderFormData}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {formType === FORM_TYPE_ORG_UPDATE.Organization &&
                <>
                <Grid columns='equal' className="mt0">
                    <Grid.Row >
                    <Grid.Column>
                        <Field
                            name="manualOrderOrgEmail"
                            label="Email" placeholder={isManualOrder ? 'Click here to type' : 'Email'}
                            component={RenderInputField}
                            offAutoComplete={true}
                            disabled={disabledForm}
                            maxLength={100}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Field
                            name="manualOrderOrgPhone"
                            label="Phone" placeholder={isManualOrder ? 'Click here to type' : 'Phone'}
                            component={RenderInputField}
                            offAutoComplete={true}
                            disabled={disabledForm}
                            maxLength={30}
                        />
                    </Grid.Column>
                </Grid.Row>
                </Grid>
                    <Grid columns='equal' className="mt0">
                    <Grid.Row >
                        <Grid.Column>
                            <Field
                                name="manualOrderOrgFax"
                                label="Fax" placeholder={isManualOrder ? 'Click here to type' : 'Fax'}
                                component={RenderInputField}
                                offAutoComplete={true}
                                disabled={disabledForm}
                                maxLength={30}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Field
                                name="manualOrderOrgWebURL"
                                label="Web URL" placeholder={isManualOrder ? 'Click here to type' : 'Web URL'}
                                component={RenderInputField}
                                offAutoComplete={true}
                                disabled={disabledForm}
                                maxLength={200}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </>
            }
            </div>
        );
    }
}

export default CommonFormOrganizationActivity;
