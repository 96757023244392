import React, { Component } from "react";
import MemberBannerHeader from '../member-banner-header';
import MemberDetailsTabs from '../member-details-tabs';
import * as _func from './function';

class MemberDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountNumber: props.match.params ? props.match.params.accountNumber : null
        }
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.loadData(this.state.accountNumber);
    }

    componentWillUnmount() {
        this.props.resetDetailsData();
    }

    render() {
        const { initialValues, memberMasterDetails } = this.props;

        return (
            <div>
                {initialValues && initialValues.AccountNumber && memberMasterDetails && (
                    <div className='ui memberPage detailPage' data-testid="memberDetails">
                        <MemberBannerHeader {...this.props} modifiedDate={initialValues.ModifiedDate} memberTypeId={initialValues.MemberTypeId} 
                        FacilityName={initialValues.FacilityName}
                        />
                        <MemberDetailsTabs {...this.props} loadData={_func.loadData} />
                    </div>
                )}
            </div>
        );
    }
}

export default MemberDetail;
