import React, { Component } from 'react';
import { Modal, Button, Form, Confirm } from 'semantic-ui-react';
import CustomGroupRendererSearch from '../custom-group-renderer-search';
import { Field } from 'redux-form';
import { getMessage, handleAutoComplete } from 'helpers/util-common';
import * as _func from './function';
import { setClassInstance } from '../organization-edit-gen-info/function';
import { membershipTypeJSON } from '../../../members/members.model';


class AssociateRepAccountModal extends Component {

    state = {
        isLoading: false,
        formattedMemberList: [],
        showNoResultsMessage: false,
        showErrorMessage: false,
        error: getMessage(this.props.messageCodes, '8123.text'),
        showConfirmationModal: false,
        descMessage: ''
    }

    componentWillMount() {
        setClassInstance(this);
        _func.setInstance(this);
    }
    componentDidMount() {
        handleAutoComplete()
    }

    render() {
        const { isLoading, formattedMemberList, showNoResultsMessage, showErrorMessage, error, showConfirmationModal, descMessage } = this.state;
        const { initialValues } = this.props;
        return (
            <div>
                <Modal open='true' className="tiny">
                    <Form noValidate onSubmit={_func.onSubmitHandler}>
                        <Modal.Header>Associate {initialValues.RepMembershipTypeId === membershipTypeJSON.CooperativeAgreementRep.id ? membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName} Account<i onClick={_func.onClose} className="close" title="close">&#x2716;</i></Modal.Header>
                        <Modal.Content scrolling>

                            <Modal.Description style={{ 'min-height': '370px' }}>
                                <div className="mt20">
                                    <div>{initialValues.RepMembershipTypeId === membershipTypeJSON.CooperativeAgreementRep.id ? membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName}</div>
                                    <Field name="Representative" type="text"
                                        component={CustomGroupRendererSearch}
                                        placeholder="Search Member by Account Number, Name"
                                        maxLength="100"
                                        showNoResults={showNoResultsMessage}
                                        onChange={(e, val) => _func.onChange(val)}
                                        results={formattedMemberList}
                                        isLoading={isLoading}
                                        isOrganizationSearch={true}
                                        onResultSelect={_func.onSelect}
                                        className="autocompleteOff"
                                    />

                                    {showErrorMessage && (
                                        <span className="errorMessage mt10">{error}</span>
                                    )}
                                </div>

                            </Modal.Description>

                        </Modal.Content>
                        <Modal.Actions>
                            <div className="pl0 mt20">
                                <Button primary type="submit">Assign</Button>
                                <Button secondary onClick={_func.onClose}>Cancel</Button>
                            </div>
                        </Modal.Actions>
                    </Form>
                </Modal>
                <Confirm className="confirm-box"
                    open={showConfirmationModal}
                    content={<div className="content" dangerouslySetInnerHTML={{ __html: descMessage }}></div>}
                    onCancel={_func.onCancelModal}
                    onConfirm={_func.onConfirmModal}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        )
    }
}

export default AssociateRepAccountModal;
