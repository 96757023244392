import React, { Component, Fragment } from 'react'
import RenderCheckBox from 'shared-components/CheckBox'
import CustomTooltip from 'shared-components/Tooltip';
import { Popup, Button, Loader } from 'semantic-ui-react';
import { ResponsibilityGroup } from '../../members.model';

class ResponsibilitiesCard extends Component {

    state = { showOfficerResponsibilities: false, officerResponsibilities: [], loading: true }

    componentDidMount() {
        if (this.props && this.props.MemberId) {
            this.props.getOfficerResponsibilities([{ "OfficerTitleId": this.props.OfficerTitleId, "CommitteeId": this.props.CommitteeId, "MemberId": this.props.MemberId }], response => this.setState({ officerResponsibilities: response, loading: false }, () => this.AddResponsibility()));
        }

    }

    AddResponsibility = () => {
        let responsibilities = this.state.officerResponsibilities;
        if (responsibilities && responsibilities.length > 0) {
            responsibilities[0].ResponsibilityList.map(item => (
                item.ResponsibilityGroupList.map(itemGroup => {
                    if (itemGroup.ResponsibilityPrivilegeList.filter(fItem => (fItem.ResponsibilityPrivilegeId === -1)).length === 0) {
                        itemGroup.ResponsibilityPrivilegeList.unshift({
                            ResponsibilityPrivilegeId: -1,
                            ResponsibilityPrivilegeName: "ALL",
                            IsChecked: (itemGroup.ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1).length === itemGroup.ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1 && p.IsChecked).length),
                            ResponsibilityPrivilegeMappingId: -1
                        })
                    }
                }
                )
            ))
            this.setState({ officerResponsibilities: responsibilities });
        }

    }

    checkAllResponsibility = (privilegesIndex, groupIndex, status, responsibilityIndex) => {
        let responsibilities = this.state.officerResponsibilities;
        if (responsibilities && responsibilities.length > 0) {
            responsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.map(responsibility => {
                if (!status && privilegesIndex === 0) {
                    responsibility.IsChecked = true;
                }
                else if (status && privilegesIndex === 0) {
                    responsibility.IsChecked = false;
                }
                else if (privilegesIndex > 0) {
                    if (responsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1).length === responsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1 && p.IsChecked).length) {
                        responsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[0].IsChecked = true;
                    }
                    else {
                        responsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[0].IsChecked = false;
                    }
                }

            });
            this.setState({ officerResponsibilities: responsibilities }, () => this.AddResponsibility());
        }
    }

    handleChangeResponsibility = (responsibilityIndex, groupIndex, privilegesIndex, status) => {
        this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked = !status;

        let isViewClassification = this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[1].IsChecked;
        let isUpdateClassification = this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[2].IsChecked;
        let isUpdateVotingRights = this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[3].IsChecked;
        let responsibilityGroupName = this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityGroupName;

        if (privilegesIndex === 1 && !isViewClassification && responsibilityGroupName === ResponsibilityGroup.roster) {
            this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[2].IsChecked = false;
            this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[3].IsChecked = false;
        }
        if (privilegesIndex === 2 && isUpdateClassification && responsibilityGroupName === ResponsibilityGroup.roster) {
            this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[1].IsChecked = true;
        }
        if (privilegesIndex === 3 && isUpdateVotingRights && responsibilityGroupName === ResponsibilityGroup.roster) {
            this.state.officerResponsibilities[0].ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[1].IsChecked = true;
        }

        this.setState({ officerResponsibilities: this.state.officerResponsibilities });
        this.checkAllResponsibility(privilegesIndex, groupIndex, status, responsibilityIndex);
    }

    handleClickUpdateResponsibilties = (e, responsibilityId) => {
        this.state.officerResponsibilities[0].OfficerTitleId = this.props.OfficerTitleId;
        this.state.officerResponsibilities[0].CommitteeId = this.props.CommitteeId;
        this.state.officerResponsibilities[0].MemberId = this.props.MemberId;
        this.state.officerResponsibilities[0].ResponsibilityId = responsibilityId;
        this.props.updateResponsibilties(this.state.officerResponsibilities);
        this.props.hide(e);
    }

    getStatusCheckbox = (data) => {
        let result = 0;// 0 for none,1 for partail,2 for full
        let checkCount = 0;
        let total = 0;
        data.ResponsibilityGroupList.map((itemGroup) => {
            itemGroup.ResponsibilityPrivilegeList.map((itemResponsibility) => {
                if (itemResponsibility.IsChecked && itemResponsibility.ResponsibilityPrivilegeMappingId !== -1) {
                    checkCount++;
                }
                if (itemResponsibility.ResponsibilityPrivilegeMappingId !== -1) {
                    total++;
                }
            })
        });
        if (checkCount === 0) {
            result = 0;
        }
        else if (checkCount === total) {
            result = 2;
        }
        else {
            result = 1;
        }
        return <RenderCheckBox className={result === 0 || result === 2 ? "ui checkbox" : "ui checkbox partial"} IsEditable={true} IsChecked={result > 0} ></RenderCheckBox >
    }

    render() {
        const { officerResponsibilities, loading } = this.state;
        return (
            <Fragment>
                {loading && <Loader size='small' className="small-loader">Loading</Loader>}
                <div className={loading ? "responsibilitiesCard hide" : "responsibilitiesCard"} onClick={this.props.show} data-testid="responsibilitiesCard">
                    {officerResponsibilities && officerResponsibilities.length > 0 && <frameElement>
                        <CustomTooltip icon={<h4 className="twoLiner ellip" style={{ '-webkit-box-orient': 'vertical' }}><span>{officerResponsibilities[0].OfficerTitleName}</span></h4>} content={officerResponsibilities[0].OfficerTitleName} />
                        <span className="privelegeSubHead"> {officerResponsibilities[0].IsASTMStaff ? 'ASTM Staff' : ''}</span>
                        <div className="privileges">
                            <span className="privelegeHead">Privileges</span>
                            <ul>
                                {officerResponsibilities[0].ResponsibilityList.map((item, responsibilityIndex) => (
                                    <Popup trigger={<li data-testid="privListTooltip">
                                        {this.getStatusCheckbox(item)}
                                        <span className="privelegeName">{item.ResponsibilityName}</span></li>} flowing hoverable className="privListTooltip offResp" position="right center" on="click" hideOnScroll>
                                        <div className="max_height">
                                            {item.ResponsibilityGroupList.map((itemGroup, groupIndex) =>
                                            (
                                                <Fragment>
                                                    <div className="privGrroupname">{itemGroup.IsDisplay && itemGroup.ResponsibilityGroupName}</div>
                                                    <ul className="privActionList">
                                                        {itemGroup && itemGroup.ResponsibilityPrivilegeList.map((itemPrivileges, privilegesIndex) => (
                                                            <li>
                                                                <RenderCheckBox className="ui checkbox partial" IsEditable={true}
                                                                    IsChecked={itemPrivileges.IsChecked} defaultChecked={itemPrivileges.IsChecked}
                                                                    name={itemPrivileges.ResponsibilityPrivilegeName}
                                                                    onChange={() => this.handleChangeResponsibility(responsibilityIndex, groupIndex, privilegesIndex, itemPrivileges.IsChecked)} />
                                                                <label>{itemPrivileges.ResponsibilityPrivilegeName}</label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Fragment>))}
                                            <div className="mt30">
                                                <Button primary onClick={(e) => this.handleClickUpdateResponsibilties(e, item.ResponsibilityId)}>Update</Button>
                                                <Button className="cancel ml10" onClick={this.props.reset} >Cancel</Button>
                                            </div>
                                        </div>
                                    </Popup >
                                ))}

                            </ul>
                        </div>
                    </frameElement>}
                </div >
            </Fragment>
        )
    }
}

export default ResponsibilitiesCard;
