import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';

class MemberCommitteeOrganizationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHttpExistInURI: 'http',
            isMemberCommitteeStatusActive: !(this.props.memberCommitteeDetails.TopSection && this.props.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive'),
            checkCommitteeStatus: this.props.committeeStatus.toLowerCase() === 'a'
        }
    }
    componentWillReceiveProps(nextProps) {
        const isMemberCommitteeStatusActive = !(nextProps.memberCommitteeDetails.TopSection && nextProps.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive');
        const checkCommitteeStatus = nextProps.committeeStatus.toLowerCase() === 'a';
        this.setState({
            isMemberCommitteeStatusActive: isMemberCommitteeStatusActive,
            checkCommitteeStatus: checkCommitteeStatus
        });
    }

    render() {
        const { isMemberCommitteeStatusActive, checkCommitteeStatus, isHttpExistInURI } = this.state
        const { showEdit, memberCommitteeDetails, hasEditMemberParticipationOnCommittees } = this.props;
        const PrimaryActivityName = memberCommitteeDetails?.MemberActivity?.PrimaryActivityName;
        const PrimaryActivityId = memberCommitteeDetails?.MemberActivity?.PrimaryActivityId;
        const Manufacturer = memberCommitteeDetails?.MemberActivity?.Manufacturer;
        const Consumer = memberCommitteeDetails?.MemberActivity?.Consumer;
        const ConsumerAdvocacy = memberCommitteeDetails?.MemberActivity?.ConsumerAdvocacy;
        const Academia = memberCommitteeDetails?.MemberActivity?.Academia;
        const Consultant = memberCommitteeDetails?.MemberActivity?.Consultant;
        const Other = memberCommitteeDetails?.MemberActivity?.Other;
        const GovernmentAgency = memberCommitteeDetails?.MemberActivity?.GovernmentAgency;
        const isHttpManufacturer = Manufacturer && Manufacturer.OrganizationWebsite && Manufacturer.OrganizationWebsite.indexOf(isHttpExistInURI);
        const isHttpConsultant = Consultant && Consultant.OrganizationWebsite && Consultant.OrganizationWebsite.indexOf(isHttpExistInURI);
        const isHttpGovernmentAgency = GovernmentAgency && GovernmentAgency.WebsiteAddress && GovernmentAgency.WebsiteAddress.indexOf(isHttpExistInURI);
        const isHttpAcademia = Academia && Academia.WebsiteAddress && Academia.WebsiteAddress.indexOf(isHttpExistInURI);
        return (

            <section className="organizationalInfo">
                <h5>Classification Information
                    {isMemberCommitteeStatusActive && checkCommitteeStatus && hasEditMemberParticipationOnCommittees && memberCommitteeDetails.GeneralInformation?.LevelId === 1 ?
                        <a title="Edit" className="editBtn" data-testid="edit-btn" onClick={showEdit} ><Icon name="pencil" /></a> : ''
                    }
                </h5>
                {PrimaryActivityId !== undefined && PrimaryActivityId == 0 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary activity information not found.</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
                {PrimaryActivityId !== undefined && PrimaryActivityId > 0 && PrimaryActivityId < 5 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity of Member</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Organization Name</span>
                                <span className="labelValue">{Manufacturer.OrganizationName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Division Name</span>
                                <span className="labelValue">{Manufacturer.DivisionName}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Parent Organization</span>
                                <span className="labelValue">{Manufacturer.ParentOrganization}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Organization  Website</span>
                                <span className="labelValue">
                                    <a href={((isHttpManufacturer > -1) ? '' : `${isHttpExistInURI}://`) + Manufacturer.OrganizationWebsite} target="_blank"> {Manufacturer.OrganizationWebsite}</a>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Describe your products or services</span>
                                <span className="labelValue">{Manufacturer.Description}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>)
                }
                {PrimaryActivityId !== undefined && PrimaryActivityId == 8 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle"> Consumer Advocacy Group or Groups</span>
                                <span className="labelValue">{Consumer.IsAdvocacyGroup ? 'Yes' : 'No'}</span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={Consumer.IsAdvocacyGroup ? '' : 'hide'}>
                            <Grid.Column>
                                <span className="labelTitle">Name of Consumer Advocacy Group</span>
                                <span className="labelValue">{Consumer.NameOfConsumerAdvocacy}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle"> Advocacy Group Contact Email</span>
                                <span className="labelValue">{Consumer.ContactEmailOfConsumerAdvocacy}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={Consumer.IsAdvocacyGroup ? 'hide' : ''}>
                            <Grid.Column>
                                <span className="labelTitle">Consumer Interest(s)</span>
                                <span className="labelValue">{Consumer.ConsumerInterest}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>)
                }
                {PrimaryActivityId !== undefined && PrimaryActivityId == 9 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>

                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Consumer Interest(s)</span>
                                <span className="labelValue">{ConsumerAdvocacy.ConsumerInterest}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>)
                }
                {PrimaryActivityId !== undefined && Consultant && (PrimaryActivityId == 5 || PrimaryActivityId == 11) && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Name of Consulting Firm</span>
                                <span className="labelValue">{Consultant.NameofyourConsultingFirm ? Consultant.NameofyourConsultingFirm : ''}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Retained by</span>
                                <span className="labelValue">{Consultant.IsMultipleOrganizations ? 'Multiple Organization' : 'One Organization'}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Name of Organization(s) Representing</span>
                                <span className="labelValue">{Consultant.NameOfOrganizationRepresenting}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className={Consultant.IsMultipleOrganizations ? 'hide' : ''}>
                                <span className="labelTitle">Organization Website</span>
                                <span className="labelValue">
                                    <a href={((isHttpConsultant > -1) ? '' : `${isHttpExistInURI}://`) + Consultant.OrganizationWebsite} target="_blank">{Consultant.OrganizationWebsite}</a>
                                </span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Producer Organization(s)</span>
                                <span className="labelValue">{Consultant.IsProducerOrganization ? 'Yes' : 'No'}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">{Consultant.IsProducerOrganization ? 'Products Sold' : 'Business Activities'}</span>
                                <span className="labelValue">{Consultant.ProducerOrganizationValue}</span>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>)
                }
                {PrimaryActivityId !== undefined && PrimaryActivityId == 10 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Member Affiliation</span>
                                <span className="labelValue">{Other.MemberAffiliation}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>

                            <Grid.Column>
                                <span className="labelTitle">Other Interest(s)</span>
                                <span className="labelValue">{Other.OtherInterest}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>)
                }
                {PrimaryActivityId !== undefined && PrimaryActivityId == 6 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Government Agency Name</span>
                                <span className="labelValue">{GovernmentAgency.GovernmentAgencyName}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Branch/Office/Division</span>
                                <span className="labelValue">{GovernmentAgency.Branch}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Website Address of Agency</span>
                                <span className="labelValue">
                                    <a href={((isHttpGovernmentAgency > -1) ? '' : `${isHttpExistInURI}://`) + GovernmentAgency.WebsiteAddress} target="_blank">{GovernmentAgency.WebsiteAddress}</a>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Government Interest(s)</span>
                                <span className="labelValue">{GovernmentAgency.GovernmentInterest}</span>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>)
                }
                {PrimaryActivityId !== undefined && PrimaryActivityId == 7 && (
                    <Grid divided='vertically' columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Primary Activity</span>
                                <span className="labelValue">{PrimaryActivityName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">University/College/Institution Name</span>
                                <span className="labelValue">{Academia.UniversityName}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Department</span>
                                <span className="labelValue">{Academia.Department}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Website Address</span>
                                <span className="labelValue">
                                    <a href={((isHttpAcademia > -1) ? '' : `${isHttpExistInURI}://`) + Academia.WebsiteAddress} target="_blank"> {Academia.WebsiteAddress}</a>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Academia Interest(s)</span>
                                <span className="labelValue">{Academia.AcademiaInterest}</span>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>)
                }
            </section>
        )
    }
}
export default MemberCommitteeOrganizationDetails;
