// @flow
import * as type from '../../../action-types';
import { toDateWithTime, formatDateToMMDDYYYY } from '../../../helpers/util-common';

const initialState = {
    renewalTaskDetails: {},
    dependsOnList: [],
    dependentTasksList: [],
    usersList: [],
    successRequestType: '',
    updatedTaskStatusId: null,
    communicationLog: {},
    taskWatchersList: [],
    auditLog: {}
};

const formatUserList = (list) => {
    if (list !== null && list.length > 0) {
        let result = list.map((res) => {
            res.key = res.UserId;
            res.text = `${res.UserName}`;
            res.value = res.UserId;
            return res;
        });
        return result;
    }
    return [];
}

const formatDates = (data) => {
    return {
        ...data,
        StartDate: formatDateToMMDDYYYY(data.StartDate),
        EndDate: formatDateToMMDDYYYY(data.EndDate),
        ModifiedDate: toDateWithTime(data.ModifiedDate)
    }
}

const taskDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_ONLY_RENEWAL_TASK_DETAILS:
            return {
                ...state,
                renewalTaskDetails: formatDates(action.renewalTaskDetails),
                successRequestType: action.type
            };
        case type.GET_RENEWAL_TASK_DETAILS:
            return {
                ...state,
                renewalTaskDetails: formatDates(action.renewalTaskDetails),
                dependsOnList: action.dependsOnList,
                dependentTasksList: action.dependentTasksList,
                successRequestType: action.type
            };
        case type.GET_USERS_LIST:
            return {
                ...state,
                usersList: formatUserList(action.usersList)
            };
        case type.OPEN_DEPENDS_ON_TASK_POPUP:
            return {
                ...state,
                isOpenPopup: true
            };
        case type.CLOSE_DEPENDS_ON_TASK_POPUP:
            return {
                ...state,
                isOpenPopup: false
            };
        case type.UPDATE_TASK_ASSIGNEE_STATUS_SUCCESS:
            return {
                ...state,
                successRequestType: action.type,
                updatedTaskStatusId: action.updatedTaskStatusId
            };
        case type.UPDATE_TASK_DETAILS_SUCCESS:
            return {
                ...state,
                successRequestType: action.type
            };
        case type.CLOSE_CONFIRMATION_POPUP:
            return {
                ...state,
                successRequestType: ''
            };
        case type.RESET_TASK_DETAILS_DATA:
            return {
                ...state,
                renewalTaskDetails: [],
                dependsOnList: [],
                dependentTasksList: [],
                taskWatchersList: []
            };
        case type.GET_TASK_WATCHERS_LIST:
            return {
                ...state,
                taskWatchersList: action.taskWatchersList
            }
        default:
            return state;
    }
};

export default taskDetailsReducer;
