import { deepCopy, encrypt, formatListDataForAutoSearch } from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds, CountryCodes } from 'models/common.models';
import { change } from 'redux-form';
import { MembershipTypes } from '../../members.model';

export let TIMEOUT = null;
let self = null;
let prevFirstName = '';
let prevLastName = '';
let prevOrgName = '';

export const setClassInstance = (instance) => {
    self = instance;
}

export const loadInitialData = () => {
    self.props.getCommitteeDesignationNameList((result) => {
        let committeeDesignationNameList = formatCommitteeDesignationList(result);

        self.setState({
            committeeDesignationNameList
        })
    })

    setStateListData(self.props.initialValues.Country);
}

export const filterMembershipTypes = () => {
    let membershipTypeList = deepCopy(self.props.membershipTypeList);
    membershipTypeList = membershipTypeList.filter(type => {
        return type.key !== MembershipTypes.Organizational
    })
    return membershipTypeList;
}

export const getFirstName = (value) => {
    value = value.trim();

    clearTimeout(TIMEOUT);

    if (value !== prevFirstName) {
        self.setState({
            isLoadingFirstList: value !== '',
            firstNameList: [],
            isShowFirstNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevFirstName) {
            self.props.getMemberFirstNameList(value, (result) => {
                if (result && result.length > 0) {
                    self.setState({
                        isShowFirstNameErrorMsg: false
                    });
                } else {
                    self.setState({
                        isShowFirstNameErrorMsg: true
                    });
                }

                self.setState({
                    firstNameList: formatListDataForAutoSearch(result),
                    isLoadingFirstList: false
                });
            });
        }

        prevFirstName = value;
    }, 300);
}

export const getLastName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevLastName) {
        self.setState({
            isLoadingLastList: value !== '',
            lastNameList: [],
            isShowLastNameErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevLastName) {
            self.props.getMemberLastNameList(value, (result) => {
                if (result && result.length > 0) {
                    self.setState({
                        isShowLastNameErrorMsg: false
                    });
                } else {
                    self.setState({
                        isShowLastNameErrorMsg: true
                    });
                }

                self.setState({
                    lastNameList: formatListDataForAutoSearch(result),
                    isLoadingLastList: false
                });
            });
        }

        prevLastName = value;
    }, 300);
}

export const getOrganizationName = (value) => {
    value = value.trim();
    clearTimeout(TIMEOUT);

    if (value !== prevOrgName) {
        self.setState({ // Disabled loading
            isLoadingOrgList: value !== '',
            organizationNameList: [],
            isShowOrgErrorMsg: false
        });
    }

    TIMEOUT = setTimeout(() => {
        if (value !== prevOrgName) {
            self.props.getMemberOrganizationNameList(value, (result) => {
                if (result && result.length > 0) {
                    self.setState({
                        isShowOrgErrorMsg: false
                    });
                } else {
                    self.setState({
                        isShowOrgErrorMsg: true
                    });
                }

                self.setState({
                    organizationNameList: formatListDataForAutoSearch(result),
                    isLoadingOrgList: false
                });
            });
        }

        prevOrgName = value;
    }, 300);
}

export const formatCommitteeDesignationList = (list) => {
    if (list && list.length > 0) {
        let result = list.map((res) => {
            res.key = res.Id;
            res.text = `${res.CommitteeCode}`;
            res.value = `${res.Id}`;
            return res;
        });
        return result;
    }

    return [];
}

export const onCountryChange = (e, countryName) => {
    setStateListData(countryName);
    self.props.change('State', '');
}

const setStateListData = (countryName) => {
    let stateList = [];
    stateList.push({ key: 'All', text: 'All', value: 'All' });

    if (countryName === CountryCodes.UNITEDSTATES.Name) {
        stateList = self.props.usStateList;
    } else if (countryName === CountryCodes.CANADA.Name) {
        stateList = self.props.canadaStateList;
    }

    self.setState({ stateList, isStateListDisabled: stateList.length === 1 });
}

export const onSubmitHandler = (values, dispatch, props) => {
    const searchQuery = getSearchQuery(values);
    props.onAdvanceSearchSubmit(searchQuery);
}

export const resetForm = () => {

    Object.keys(self.props.initialValues).forEach(fieldKey => {
        let value = '';

        if (fieldKey === 'State') {
            self.setState({ stateList: [], isStateListDisabled: true });
        }
        self.props.dispatch(change(self.props.form, fieldKey, value));
    });
    self.setState({ isResetClicked: true });

    const searchQuery = getSearchQuery({});
    self.props.onResetClick(searchQuery);
}

const getSearchQuery = (values) => {
    let queries = [];

    queries.push('isAdvanceSearch=true');
    queries.push(`${AdvanceSearchQueryFields.GridId}=${AppGridIds.MemberGrid}`);
    if (values.FirstName && values.FirstName.trim().length > 0) {
        queries.push(`firstname=${encrypt(values.FirstName.trim())}`);
    }
    if (values.LastName && values.LastName.trim().length > 0) {
        queries.push(`lastname=${encrypt(values.LastName.trim())}`);
    }
    if (values.AccountNumber && values.AccountNumber.trim().length > 0) {
        queries.push(`account=${encrypt(values.AccountNumber.trim())}`);
    }
    if (values.MemberType) {
        queries.push(`membershiptypeid=${values.MemberType}`);
    }
    if (values.CompanyName && values.CompanyName.trim().length > 0) {
        queries.push(`company=${encrypt(values.CompanyName.trim())}`);
    }
    if (values.CommitteeDesignation) {
        if (`${values.CommitteeDesignation}`.length > 0) {
            queries.push(`committee=${values.CommitteeDesignation}`);
        }
    }
    if (values.AccountStatus) {
        queries.push(`status=${values.AccountStatus}`);
    } else {
        queries.push(`status=0`);
    }

    if (values.Email && values.Email.trim().length > 0) {
        queries.push(`email=${encrypt(values.Email.trim())}`);
    }
    if (values.PhoneNumber) {
        queries.push(`phonenumber=${encrypt(values.PhoneNumber)}`);
    }
    if (values.Country) {
        queries.push(`country=${encrypt(values.Country)}`);
    }
    if (values.State) {
        queries.push(`state=${encrypt(values.State)}`);
    }
    if (values.PostalCode && values.PostalCode.trim().length > 0) {
        queries.push(`postalcode=${encrypt(values.PostalCode.trim())}`);
    }
    if (values.City && values.City.trim().length > 0) {
        queries.push(`city=${encrypt(values.City.trim())}`);
    }

    return queries.join('&');
}
