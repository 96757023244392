import * as types from 'action-types';
import { formatAuditLogFieldList, formatDateToMMDDYYYY, formatDateToMMYYYY, getMessage, utcDateTimeToLocalDateTime } from 'helpers/util-common';
import { formatHistoricalReasonList } from '../common-functions';
import { AccountStatusAuditFieldId, DroppedMemberHistoricalReasonId, HistoricalReasonAuditFieldId, PaidStatusAuditFieldId } from '../members.model';

const initialState = {
    memberDetails: {},
    memberMasterDetails: {},
    mouContactCodeList: null,
    governmentTypeList: null,
    interestedCommitteeList: null,
    memberUserList: null,
    currentModifiedById: '',
    historicalReasonList: null,
    memberAuditLogFields: null,
    committeeList: [],
    noVoteReasonList: [],
    memberCommitteeDetails: {},
    commonCommitteeClassificationTypeList: null,
    isUpdateMemberCommitteeDetails: false,
    validateProducerClassification: false,
    memberAccountHistoryList: null,
    accountStatusAuditFieldName: "Account Status",
    paidStatusAuditFieldName: "Paid Status",
    historicalReasonAuditFieldName: 'Historical Reason',
    memberCommitteeHistoryList: null,
    officerTitleList: [],
    inactiveReasonList: [],
    memberCommitteeOfficerTitleHistoryList: null,
    currentModifiedType: 0
}

const formatData = (data) => {
    let name = '';
    let fullAddress = '';
    
    if (data) {
        fullAddress = data.Address ? `${data.Address}, ` : '';
        fullAddress = data.City ? `${fullAddress}${data.City}, ` : fullAddress;
        fullAddress = data.State ? `${fullAddress}${data.State}, ` : fullAddress;
        fullAddress = data.PostalCode ? `${fullAddress}${data.PostalCode}, ` : fullAddress;
        fullAddress = data.Country ? `${fullAddress}${data.Country}` : fullAddress;
    }

    if (data.LastName) {
        name = `${data.LastName}, `;
    }
    if (data.FirstName) {
        name = name + data.FirstName + ' ';
    }
    if (data.MiddleName) {
        name = name + data.MiddleName.substring(0, 1);
    }

    data.Name = name;

    data.JoinDate = formatDateToMMDDYYYY(data.JoinDate);

    if (data.PaidDate) {
        data.PaidDate = formatDateToMMDDYYYY(data.PaidDate);
    }

    if (data.GraduationDate) {
        let date = data.GraduationDate;
        data.GraduationDate = formatDateToMMYYYY(date);
    }

    if (data.ModifiedDate) {
        data.lastModifiedDate = utcDateTimeToLocalDateTime(data.ModifiedDate);
    } else {
        data.lastModifiedDate = '';
    }

    if (data.InterestedCommittee) {
        data.InterestedCommittee = data.InterestedCommittee.trim().split(',');
    }

    if (data.CopyrightAcceptance) {
        data.CopyrightAcceptance = utcDateTimeToLocalDateTime(data.CopyrightAcceptance)
    } else {
        data.CopyrightAcceptance = '--';
    }

    data.OptMailingList = data.OptMailingList ? 'true' : 'false';
    data.FullAddress = fullAddress;

    // These two fields is not passed in redux form CommitteeActivityEdit.
    data.IsMultipleOrganizations = data.IsMultipleOrganizations ? data.IsMultipleOrganizations : false
    data.IsProducerOrganization = data.IsProducerOrganization ? data.IsProducerOrganization : true
    return data;
}

const formatAddressFromMemberMasterData = (data) => {
    let fullAddress = '';
    if (data) {
        fullAddress = data.MemberDetail_Address ? `${data.MemberDetail_Address}, ` : '';
        fullAddress = data.MemberDetail_City ? `${fullAddress}${data.MemberDetail_City}, ` : fullAddress;
        fullAddress = data.MemberDetail_State ? `${fullAddress}${data.MemberDetail_State}, ` : fullAddress;
        fullAddress = data.MemberDetail_PostalCode ? `${fullAddress}${data.MemberDetail_PostalCode}, ` : fullAddress;
        fullAddress = data.MemberDetail_Country ? `${fullAddress}${data.MemberDetail_Country}` : fullAddress;
    }

    data.FullAddress = fullAddress;
    return data;
}

const formatDateMemberCommitteeDetails = (data) => {
    if (data.GeneralInformation && data.GeneralInformation.JoinAsAffiliateDate) {
        let date = data.GeneralInformation.JoinAsAffiliateDate;
        data.JoinAsAffiliateDate = formatDateToMMDDYYYY(date);
    } else {
        data.JoinAsAffiliateDate = '';
    } if (data.GeneralInformation && data.GeneralInformation.VoteDate) {
        let date = data.GeneralInformation.VoteDate;
        data.VoteDate = formatDateToMMDDYYYY(date);
    } else {
        data.VoteDate = '';
    }
    if (data.TopSection && data.TopSection.CommitteeJoinDate) {
        let date = data.TopSection.CommitteeJoinDate;
        data.CommitteeJoinDate = formatDateToMMDDYYYY(date);
    } else {
        data.CommitteeJoinDate = '';
    }
    if (data.TopSection && data.TopSection.InactiveDate) {
        let date = data.TopSection.InactiveDate;
        data.InactiveDate = formatDateToMMDDYYYY(date);
    } else {
        data.InactiveDate = '';
    }
    if (data.TopSection && data.TopSection.ReactivatedOnCommitteeDate) {
        let date = data.TopSection.ReactivatedOnCommitteeDate;
        data.ReactivatedOnCommitteeDate = formatDateToMMDDYYYY(date);
    } else {
        data.ReactivatedOnCommitteeDate = '';
    }
    if (data.TopSection && data.TopSection.OfficerAppointedDate) {
        let date = data.TopSection.OfficerAppointedDate;
        data.OfficerAppointedDate = formatDateToMMYYYY(date);
    } else {
        data.OfficerAppointedDate = '';
    }
    return data;
}

const formatList = (list) => {
    let result = [];
    if (list !== null && list.length > 0) {
        result = list.map((res) => {
            res.key = res.Id;
            res.text = `${res.Name}`;
            res.value = res.Id;
            return res;
        });
    }

    result.unshift({ 'key': 0, 'text': 'Select', 'value': 0 });

    return result;
}

const formatOfficerTitleList = (list) => {
    let result = [];
    if (list !== null && list.length > 0) {
        result = list.map((res) => {
            res.key = res.OfficerTitleId;
            res.text = `${res.OfficerTitleName}`;
            res.value = res.OfficerTitleId;
            return res;
        });
    }

    result.unshift({ 'key': 0, 'text': 'Select', 'value': 'select' });
    return result;
}

const formatInactiveReasonList = (list) => {
    let result = [];
    if (list !== null && list.length > 0) {
        result = list.map((res) => {
            res.key = res.InactiveReasonId;
            res.text = `${res.Name}`;
            res.value = res.InactiveReasonId;
            return res;
        });
    }

    result.unshift({ 'key': 0, 'text': 'Select', 'value': 'select' });
    return result;
}

const formatInterestedCommitteeList = (list) => {
    let result = [];
    if (list !== null && list.length > 0) {
        result = list.map((res) => {
            res.key = res.Id;
            res.text = `${res.Name}`;
            res.value = `${res.Name}`;
            return res;
        });
    }
    return result;
}

const formatCommonCommitteeClassificationType = (list) => {
    let result = [];
    if (list !== null && list.length > 0) {
        result = list.map((res) => {
            res.key = `${res.ClassificationTypeId}`;
            res.text = `${res.Name}`;
            res.value = `${res.Name}`;
            return res;
        });
    }
    return result;
}

const formatNoVoteReason = (list) => {
    let result = [];
    if (list !== null && list.length > 0) {
        result = list.map((res) => {
            res.key = `${res.VoteId}`;
            res.text = `${res.Description}`;
            res.value = `${res.Description}`;
            return res;
        });
    }
    return result;
}

const formatAccountHistoryData = (data, accountStatusAuditFieldName, paidStatusAuditFieldName, historicalReasonAuditFieldName) => {
    let result = [];

    if (data) {
        for (let i = 0; i < data.length; i++) {
            let auditLog = data[i];
            let fieldList = auditLog.What;
            let valueList = auditLog.Where;

            let index = fieldList.indexOf(paidStatusAuditFieldName);
            let fieldValue = '';

            if (index > -1) {
                fieldValue = getMessage(valueList[index], 'NewValue');
                result.push({ OwnerId: auditLog.OwnerId, OwnerName: auditLog.Who, When: auditLog.When, Value: fieldValue, Why: auditLog.Why, UpdateReason: '' });
            }

            index = fieldList.indexOf(accountStatusAuditFieldName);
            if (index > -1) {
                fieldValue = getMessage(valueList[index], 'NewValue');

                index = fieldList.indexOf(historicalReasonAuditFieldName);
                let historicalReason = '';
                if (index > -1) {
                    historicalReason = getMessage(valueList[index], 'NewValue');
                }

                result.push({ OwnerId: auditLog.OwnerId, OwnerName: auditLog.Who, When: auditLog.When, Value: fieldValue, Why: auditLog.Why, UpdateReason: historicalReason });
            }
        }
    }

    return result;
}

const memberDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MEMBER_DETAILS:
            return {
                ...state,
                memberDetails: formatData(action.data),
                currentModifiedById: action.data.ModifiedBy,
                currentModifiedType: action.data.ModifiedType
            }
        case types.RESET_MEMBER_DETAILS_DATA:
            return {
                ...state,
                memberDetails: {},
                memberMasterDetails: {},
                currentModifiedById: ''
            }
        case types.GET_MASTER_DATA:
            const auditFieldList = formatAuditLogFieldList(action.memberAuditLogFields);
            const accountStatusAuditField = auditFieldList.find(item => item.Id === AccountStatusAuditFieldId);
            const paidStatusAuditField = auditFieldList.find(item => item.Id === PaidStatusAuditFieldId);
            const historicalReasonAuditField = auditFieldList.find(item => item.Id === HistoricalReasonAuditFieldId);

            return {
                ...state,
                mouContactCodeList: formatList(action.mouContactCodeList),
                governmentTypeList: formatList(action.governmentTypeList),
                interestedCommitteeList: formatInterestedCommitteeList(action.interestedCommitteeList),
                historicalReasonList: formatHistoricalReasonList(action.historicalReasonList, DroppedMemberHistoricalReasonId),
                memberAuditLogFields: auditFieldList,
                accountStatusAuditFieldName: accountStatusAuditField ? accountStatusAuditField.Name : 'Account Status',
                paidStatusAuditFieldName: paidStatusAuditField ? paidStatusAuditField.Name : 'Paid Status',
                historicalReasonAuditFieldName: historicalReasonAuditField ? historicalReasonAuditField.Name : 'Historical Reason'
            }
        case types.GET_MEMBER_MASTER_DATA:
            return {
                ...state,
                memberMasterDetails: formatAddressFromMemberMasterData(action.memberMasterDetails)
            }
        case types.GET_MEMBER_USER_LIST:
            return {
                ...state,
                memberUserList: action.memberUserList
            }
        case types.GET_ADD_MEMBER_COMMITTEE_MASTER_DATA:
            return {
                ...state,
                committeeList: action.committeeList,
                noVoteReasonList: action.noVoteList
            }
        case types.GET_MEMBER_COMMITTEE_DETAILS:
            return {
                ...state,
                memberCommitteeDetails: formatDateMemberCommitteeDetails(action.memberCommitteeDetails)
            }
        case types.GET_COMMON_COMMITTEE_CLASSIFICATION_AND_NO_VOTE_REASON:
            return {
                ...state,
                commonCommitteeClassificationTypeList: formatCommonCommitteeClassificationType(action.commonCommitteeClassificationTypeList),
                noVoteReasonList: formatNoVoteReason(action.noVoteReasonList),
                validateProducerClassification: action.validateProducerClassification
            }
        case types.UPDATE_MEMBER_COMMITTEE_DETAILS:
            return {
                ...state,
                isUpdateMemberCommitteeDetails: action.isUpdateMemberCommitteeDetails
            }
        case types.GET_MEMBER_ACCOUNT_HISTORY_LIST:
            return {
                ...state,
                memberAccountHistoryList: formatAccountHistoryData(action.memberAccountHistoryList, state.accountStatusAuditFieldName, state.paidStatusAuditFieldName, state.historicalReasonAuditFieldName)
            }
        case types.GET_MEMBER_COMMITTEE_HISTORY_LIST:
            return {
                ...state,
                memberCommitteeHistoryList: action.memberCommitteeHistoryList
            }
        case types.GET_MEMBER_COMMITTEE_OFFICER_TITLE_HISTORY_LIST:
            return {
                ...state,
                memberCommitteeOfficerTitleHistoryList: action.memberCommitteeOfficerTitleHistoryList
            }
        case types.RESET_LEFT_PANEL:
            return {
                ...state,
                memberCommitteeDetails: {}
            }
        case types.GET_MEMBER_COMMITTEE_OFFICER_RESPONSIBILITIES:
            return {
                ...state,
                responsibilities: action.responsibilities
            }
        case types.GET_OFFICER_TITLE_AND_INACTIVE_REASONS_LIST:
            return {
                ...state,
                officerTitleList: formatOfficerTitleList(action.officerTitleList),
                inactiveReasonList: formatInactiveReasonList(action.inactiveReasonList)
            }
        default:
            return state;
    }
}

export default memberDetailsReducer;
