import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const MultipleButtonConfirmPopup = ({ message, closeModal }) => (

    <Modal open='true' className="tiny errormodalbox">
        <Modal.Header>Confirm<i onClick={(e) => closeModal(0)} className="close" title="close">&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <p>{message}</p>

                <div className="mt30">
                    <Button secondary onClick={(e) => closeModal(1)}>Yes</Button>
                    <Button secondary onClick={(e) => closeModal(0)}>No</Button>
                    <Button secondary onClick={(e) => closeModal(2)}>No, Associate Another Organization</Button>
                </div>

            </Modal.Description>
        </Modal.Content>
    </Modal>
)

export default MultipleButtonConfirmPopup;
