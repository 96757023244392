import * as type from 'action-types';
import { AddCommitteeFields, getStatusValueFromEligibilities } from '../committee.model';

const initialState = {
    committeeTypeList: null,
    committeeTypeListForSelect: null,
    committeeLevelList: [],
    hierarchyList: null,
    hierarchyListForFilter: null,
    allCommitteesHierarchyDetails: null,
    selectedCommitteeLevelEligibilitiesDetails: null,
    overviewTagFieldList: null
}

const formatCommitteeTypeList = (list, addSelectOption = false) => {
    let result = [];

    list.sort((a, b) => (a.CommitteeTypeName.toLowerCase() > b.CommitteeTypeName.toLowerCase()) ? 1 : -1)
        .map((item) => {
            result.push({ key: item.CommitteeTypeId, value: item.CommitteeTypeId, text: item.CommitteeTypeName });
            return true;
        });

    if (!addSelectOption) {
        result.unshift({ key: 0, value: 0, text: 'All' });
    } else {
        result.unshift({ key: 0, value: 0, text: 'Select' });
    }
    return result;
}

const formatHierarchyList = (list) => {
    let maxHierarchyCount = 0;

    if (list && list.length > 0) {
        maxHierarchyCount = list[0].MaxLevelCount;
    }

    return createHierarchyList(maxHierarchyCount);
}

const filterHirarchyListForCommittee = (committeeTypeId, committeeLevelList) => {
    let committee = committeeLevelList.find(item => item.CommitteeTypeId === committeeTypeId);
    let count = 0;

    if (committee) {
        count = committee.MaxLevelCount;
    }

    return createHierarchyList(count);
}

const createHierarchyList = (count) => {
    let result = [];
    for (let i = 0; i < count; i++) {
        let level = i + 1;
        result.push({ key: i + 1, value: `${i + 1}`, text: 'Level ' + level });
    }

    return result;
}

const formatOverviewFieldList = (list) => {
    let result = [];

    if (list && list.length > 0) {
        list.map((item) => {
            result.push({ text: item, value: item });
        })
    }

    return result;
}

const getSelectedCommitteeLevelEligibilitiesDetails = (list, committeeTypeId, levelNo = 1) => {
    let result = {};

    if (list && list.length > 0) {
        let selectedCommitteeHierarchy = list.filter(item => item.CommitteeTypeId === committeeTypeId);

        if (selectedCommitteeHierarchy && selectedCommitteeHierarchy.length > 0) {
            let hierarchyDetails = selectedCommitteeHierarchy[levelNo - 1];
            result.showClassification = getStatusValueFromEligibilities(hierarchyDetails.Eligibilities, AddCommitteeFields.Classification);
            result.showOverview = getStatusValueFromEligibilities(hierarchyDetails.Eligibilities, AddCommitteeFields.Overview);
            result.showScope = getStatusValueFromEligibilities(hierarchyDetails.Eligibilities, AddCommitteeFields.Scope);
            result.showBylaws = getStatusValueFromEligibilities(hierarchyDetails.Eligibilities, AddCommitteeFields.Bylaws);
        }
    }

    return result;
}

const committeesReducer = (state = initialState, action) => {

    switch (action.type) {
        case type.GET_COMMITTEE_LIST_MASTER_DATA:
            let hierarchyList = formatHierarchyList(action.committeeLevelList);
            return {
                ...state,
                committeeTypeList: formatCommitteeTypeList(action.committeeTypeList),
                committeeTypeListForSelect: formatCommitteeTypeList(action.committeeTypeList, true),
                committeeLevelList: action.committeeLevelList,
                hierarchyList: hierarchyList,
                hierarchyListForFilter: hierarchyList,
                allCommitteesHierarchyDetails: action.allCommitteesHierarchyDetails,
                overviewTagFieldList: formatOverviewFieldList(action.overviewTagFieldList)
            };
        case type.GET_COMMITTEE_HIERARCHY_LIST:
            let hierarchyListForFilter = [];
            if (action.committeeTypeId !== 0) {
                hierarchyListForFilter = filterHirarchyListForCommittee(action.committeeTypeId, state.committeeLevelList);
            } else {
                hierarchyListForFilter = state.hierarchyList;
            }
            return {
                ...state,
                hierarchyListForFilter
            }
        case type.GET_SELECTED_COMMITTEE_HIERARCHY_DETAILS:
            let selectedCommitteeLevelEligibilitiesDetails = getSelectedCommitteeLevelEligibilitiesDetails(state.allCommitteesHierarchyDetails, action.committeeTypeId, action.levelNo);

            return {
                ...state,
                selectedCommitteeLevelEligibilitiesDetails
            }
        case type.RESET_COMMITTEE_DETAILS_DATA:
            return {
                ...state,
                selectedCommitteeLevelEligibilitiesDetails: {}
            }
        default:
            return state;
    }
}

export default committeesReducer;
