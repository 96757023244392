import { encrypt } from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds } from 'models/common.models';
import { change } from 'redux-form';
import { getDefaultYearsList } from '../../renewal-task.model';

export const loadInitialData = (self) => {
    self.props.getUserList(false);

    // If the status list exists in reducer, do not send a call to get it.
    if (self.props.statusList && self.props.statusList.length === 0) {
        self.props.getAllTaskStatus();
    }

    // If the renewal year list exists in reducer, do not send a call to get it.
    if (self.props.renewalYearsList && self.props.renewalYearsList.length === 0) {
        self.props.getAllRenewalYears();
    }
}

export const renderUserList = (usersList) => {
    let updatedUserList = [];
    updatedUserList.push({ UserId: "", UserName: "", Status: true, key: -1, text: "All", value: 0 });
    if (usersList !== null && usersList.length > 0) {
        for (let i = 0; i < usersList.length; i++) {
            updatedUserList.push(usersList[i]);
        }
    }
    return updatedUserList;
}

export const formatStatusList = (list) => {
    if (list && list.length > 0) {
        let result = list.map((res) => {
            res.key = res.TaskStatusId;
            res.text = `${res.Name}`;
            res.value = `${res.TaskStatusId}`;
            return res;
        });
        return result;
    }

    return [];
}

export const onSubmitHandler = (values, dispatch, props) => {
    const searchQuery = getSearchQuery(values);
    props.onAdvanceSearchSubmit(searchQuery);
}

export const resetForm = (self) => {
    Object.keys(self.props.initialValues).forEach(fieldKey => {
        let value = '';
        // Reset the field's value
        if (fieldKey === 'Status') {
            value = [];
        } else if (fieldKey === 'RenewalYear') {
            value = getDefaultYearsList();
        } else if (fieldKey === 'ShowInactiveTasks') {
            value = false;
        }

        self.props.dispatch(change(self.props.form, fieldKey, value));
    });

    self.setState({ isResetClicked: true });

    const searchQuery = getSearchQuery({ RenewalYear: ["2019", "2020", "2021"] });
    self.props.onResetClick(searchQuery);
}

const getSearchQuery = (values) => {
    let queries = [];

    queries.push(`${AdvanceSearchQueryFields.GridId}=${AppGridIds.TaskGrid}`);
    if (values.TaskUniqueCode) {
        queries.push(`taskuniquecode=${encrypt(values.TaskUniqueCode.trim())}`);
    }
    if (values.Title) {
        queries.push(`title=${encrypt(values.Title.trim())}`);
    }
    if (values.Status && values.Status.length > 0) {
        queries.push(`status=${values.Status}`);
    }
    if (values.RenewalYear) {
        queries.push(`renewalyear=${values.RenewalYear}`);
    }

    if (values.Assignee !== "" && values.Assignee > -1) {
        queries.push(`assignee=${values.Assignee}`);
    }
    if (values.ShowInactiveTasks) {
        queries.push(`showinactivetasks=${values.ShowInactiveTasks}`);
    }

    return queries.join('&');
}
