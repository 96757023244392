import * as types from 'action-types';
import { connect } from "react-redux";
import committeeActions from '../actions/committee.actions';
import AdvanceSearchCommittee from '../components/advance-search';

const mapStateToProps = (state) => {
    return {
        committeeTypeList: state.committeesReducer.committeeTypeList,
        hierarchyListForFilter: state.committeesReducer.hierarchyListForFilter,
        userPermission: state.commonReducer.userPermission,
        ...state.commonReducer.PAGE_CONFIG
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDesignationList: (searchText, callback) => {
        committeeActions.getDesignationListAction(searchText, callback);
    },
    getTitleList: (searchText, callback) => {
        committeeActions.getTitleListAction(searchText, callback);
    },
    getMasterDataForCommitteeList: () => {
        committeeActions.getMasterDataForCommitteeListAction(dispatch);
    },
    changeCommitteeHierarchy: (committeeTypeId) => {
        dispatch({ type: types.GET_COMMITTEE_HIERARCHY_LIST, committeeTypeId });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchCommittee);
