import * as utilCommon from 'helpers/util-common';
import { commiteeInactiveReason } from '../../committee.model';

let self = {};
let TIMEOUT = null;
let committeeId = 0;
let postData = {}

export const setClassInstance = (instance) => {
    self = instance;
    self.props.change('fromCommittee', self.state.committeeDesignation)
    committeeId = self.props.initialValues.CommitteeId;
    self.props.change('CopyApplication', true)
    getMasterData()
}

const getMasterData = () => {
    self.props.getInActiveCommitteeMasterData(committeeId);
}

export const copyRosterHandleChange = (event) => {
    self.setState({ copyRoster: event.currentTarget.querySelector('input').value });
}

export const handleChange = (event, value, name) => {
    switch (name) {
        case 'CopyApplication':
            self.setState({ copyApplication: value })
            break;

        case 'CopyClassification':
            self.setState({ copyClassification: value }, () => {
                if (!self.state.copyClassification && self.state.copyVote) {
                    self.setState({ copyVote: self.state.copyClassification })
                    self.props.change('CopyVote', false);
                }
            })
            break;

        case 'CopyVote':
            self.setState({ copyVote: value }, () => {
                if (self.state.copyVote) {
                    self.setState({ copyClassification: self.state.copyVote });
                    self.props.change('CopyClassification', true);
                }
            })
            break;

        default:
            self.setState({ copyApplication: true, copyClassification: false, copyVote: false })
            break;
    }
}

export const validateHandler = (values, props) => {
    const errors = {};
    if (!values.InactiveReason || values.InactiveReason <= 0) {
        errors.InactiveReason = utilCommon.getMessage(props.messageCodes, '8002.text')
    }
    if (values.InactiveReason && values.InactiveReason === commiteeInactiveReason.MERGED && !values.MergeCommittee) {
        errors.MergeCommittee = utilCommon.getMessage(props.messageCodes, '3135.text');
    } else if (values.InactiveReason && values.InactiveReason === commiteeInactiveReason.MERGED && self.props.mergeCommittees.filter(item =>
        item.CommitteeDesignation.toLowerCase() === (values.MergeCommittee.trim().toLowerCase())).length <= 0) {
        errors.MergeCommittee = utilCommon.getMessage(props.messageCodes, '3135.text');
    }
    if (!values.reasonForUpdate || values.reasonForUpdate.trim() === '') {

        errors.reasonForUpdate = utilCommon.getMessage(props.messageCodes, '8121.text');
    }


    return errors;
}
export const handleSearh = (value) => {
    const trimmedValue = value.trim();

    clearTimeout(TIMEOUT);

    self.setState({
        isLoading: trimmedValue !== '',
        showNoResultsMessage: false,
        updatedResponsibility: [],
        officerTitleId: 0
    });

    TIMEOUT = setTimeout(() => {

        if (self.props.mergeCommittees.length > 0) {
            let { mergeCommittees } = self.state;

            mergeCommittees = self.props.mergeCommittees.filter(item => {
                return item.CommitteeDesignation.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0;
            })

            self.setState({
                mergeCommittees,
                isLoading: false,
                showNoResultsMessage: mergeCommittees.length === 0
            });
        } else {
            self.setState({ isLoading: false, mergeCommittees: [], ToCommitteeId: 0, updatedResponsibility: [] });
        }

    }, 300);
}

export const handleResultSelect = (value) => {
    self.setState({ ToCommitteeId: value.CommitteeId })
    self.props.change('MergeCommittee', value.CommitteeDesignation);

}
export const closeAlertPopup = () => {
    self.setState({ showdependentAlertPopup: false, formattedCommittee: '' });
}

export const confirmAlertPopup = () => {
    self.setState({ showdependentAlertPopup: false, formattedCommittee: '' }, () => inActiveCommitteeHandler());
}

const replaceLast = (x, y, z) => {
    let a = x.split("");
    a[x.lastIndexOf(y)] = z;
    return a.join("");
}

export const onSubmitHandler = (values, props) => {
    postData = {};
    if (self.props.activeMemberCount.CountActiveMembers === 0 && self.state.inActiveReasonId === commiteeInactiveReason.MERGED && self.state.copyRoster === 'true') {
        postData = values;
        inActiveCommitteeHandler();
    } else if (self.props.dependentCommittee && self.props.dependentCommittee.length > 0 && self.props.dependentCommittee.filter(item => item.CommitteeId !== committeeId).length > 0) {
        let formattedCommittee = self.state.formattedCommittee;
        formattedCommittee = '';
        self.props.dependentCommittee.map(item => formattedCommittee += item.Code + ', ')
        if (formattedCommittee !== '') {
            formattedCommittee = replaceLast(formattedCommittee, ', ', ' ');
        }
        postData = values;
        self.setState({ showdependentAlertPopup: true, formattedCommittee })
    } else {
        postData = values;
        inActiveCommitteeHandler();
    }
}
const validatevalue = (value1, value2, defaultValue) => {
    if (value1 && value1 > 0 && value2) {
        return value2
    }
    return defaultValue

}

const inActiveCommitteeHandler = () => {
    let values = postData;
    let postInactiveData = {
        CommitteeId: committeeId,
        InactiveReasonId: values.InactiveReason,
        Comment: values.reasonForUpdate,
        ToCommitteeId: self.state.ToCommitteeId || 0,
        CopyApplication: validatevalue(self.state.ToCommitteeId, values.CopyApplication, false),
        CopyClassification: validatevalue(self.state.ToCommitteeId, values.CopyClassification, false),
        CopyVote: validatevalue(self.state.ToCommitteeId, values.CopyVote, false),
        CopyRoster: self.props.activeMemberCount.CountActiveMembers === 0 ? false : (values.CopyRoster || self.state.copyRoster),
        committeeTypeId: self.props.initialValues.CommitteeTypeId,
        levelIndex: self.props.initialValues.LevelId
    }
    postInactiveData.CopyApplication = postInactiveData.CopyRoster ? postInactiveData.CopyApplication : false;
    postInactiveData.CopyClassification = postInactiveData.CopyRoster ? postInactiveData.CopyClassification : false;
    postInactiveData.CopyVote = postInactiveData.CopyRoster ? postInactiveData.CopyVote : false;
    self.props.putInactivateCommittee(postInactiveData, (response) => {
        self.props.getCommitteeDetails(utilCommon.encrypt(committeeId));
        self.props.onClose('inActiveCommittee');
    });
}
