import { routeNavigationUrl, utcDateTimeToLocalDateTime } from 'helpers/util-common';
import React from 'react';
import { Modal } from 'semantic-ui-react';

const BylawsRevisionHistoryModal = ({ onCloseModal, bylawsRevisionHistoryList, hasUserPageAccess, PAGE_TYPE, USER_PRIVILEGE }) => (

    <Modal open='true'>
        <Modal.Header> Bylaws Revision History Details<i className="close" data-testid="close" onClick={onCloseModal}>&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <div className="tableWrapper">
                    <table className="customTable memAcHistoryTable">
                        <thead>
                            <tr>
                                <th width="35%">Bylaws</th>
                                <th width="25%">Bylaws Revision Date</th>
                                <th width="25%">Updated Date</th>
                                <th width="15%">Updated By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bylawsRevisionHistoryList && bylawsRevisionHistoryList.length > 0 &&
                                bylawsRevisionHistoryList.map((item) => {
                                    return (
                                        <tr>
                                            <td width="35%">
                                                {item.BylawsUrl ? (
                                                    <a href={item.BylawsUrl} className="linkTxt" rel="noopener noreferrer" target='_blank'>{item.Bylaws}</a>
                                                ) : item.Bylaws
                                                }
                                            </td>
                                            <td width="25%">{item.BylawsRevisionDate}</td>
                                            <td width="25%">{utcDateTimeToLocalDateTime(item.When)}</td>
                                            <td width="15%">
                                                {hasUserPageAccess && Number(item.OwnerId) > 0 ? (
                                                    <a href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW, [item.OwnerId])}`}>{item.Who}</a>
                                                ) : item.Who}
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    {bylawsRevisionHistoryList && bylawsRevisionHistoryList.length === 0 &&
                        <div className="noRecordMessage">No data to display</div>
                    }
                </div>
            </Modal.Description>
        </Modal.Content>
    </Modal >
)

export default BylawsRevisionHistoryModal;
