import Parser from 'html-react-parser';
import React, { PureComponent } from 'react';
import { Grid, Icon } from "semantic-ui-react";
import { BylawsAuditFieldName } from '../../committee.model';
import BylawsRevisionHistoryModal from '../bylaws-revision-history-modal';

class ViewCommitteeGenInfo extends PureComponent {

    state = {
        bylawsRevisionHistoryList: [],
        showBylawsRevisionHistoryModal: false
    }

    openBylawsRevisionHistoryModal = () => {
        this.props.getBylawsRevisionHistoryList(this.props.initialValues.CommitteeId, BylawsAuditFieldName, (result) => {
            let bylawsRevisionHistoryList = this.formatBylawsHistoryList(result);
            this.setState({ showBylawsRevisionHistoryModal: true, bylawsRevisionHistoryList });
        });
    }

    closeBylawsRevisionHistoryModal = () => {
        this.setState({ bylawsRevisionHistoryList: [], showBylawsRevisionHistoryModal: false });
    }

    formatBylawsHistoryList = (list) => {
        let formattedList = [];
        const bylawsFieldName = "Bylaws";
        const bylawsRevisionDateFieldName = "Bylaws Revision Date";

        if (list && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                let record = { When: item.When, Who: item.Who, OwnerId: item.OwnerId };

                if (item.What && item.What.length > 0 && item.Where && item.Where.length === item.What.length) {
                    let indexOfBylaws = item.What.indexOf(bylawsFieldName);
                    let indexOfBylawsRevisionDate = item.What.indexOf(bylawsRevisionDateFieldName);

                    if (indexOfBylaws !== -1) {
                        let bylaws = item.Where[indexOfBylaws].NewValue;
                        record.Bylaws = bylaws && bylaws !== "--" ? bylaws : '--';
                        record.BylawsUrl = bylaws && bylaws !== "--" ? (bylaws.indexOf('http') === 0 ? bylaws : 'http://' + bylaws) : '';
                    }
                    if (indexOfBylawsRevisionDate !== -1) {
                        let bylawsRevisionDate = item.Where[indexOfBylawsRevisionDate].NewValue;
                        record.BylawsRevisionDate = bylawsRevisionDate && bylawsRevisionDate !== "--" ? bylawsRevisionDate : '--';
                    }
                }

                formattedList.push(record);
            }
        }

        return formattedList;
    }

    render() {
        const { bylawsRevisionHistoryList, showBylawsRevisionHistoryModal } = this.state;
        const { allCommitteesHierarchyDetails, selectedCommitteeLevelEligibilitiesDetails, initialValues, committeeTypeName, onEditGenInfo, hasUpdateAccess } = this.props;

        if (initialValues.Overview) {
            let nextLevelTitle = '';
            if (allCommitteesHierarchyDetails && allCommitteesHierarchyDetails.length > 0 && initialValues.Overview.indexOf('@NEXT LEVEL TITLE') > -1) {
                let committeeLevels = allCommitteesHierarchyDetails.filter(item => item.CommitteeTypeId === initialValues.CommitteeTypeId);

                if (committeeLevels && committeeLevels.length > initialValues.LevelId) {
                    nextLevelTitle = committeeLevels[initialValues.LevelId].CommitteeLevelTitle;
                }

                initialValues.Overview = initialValues.Overview
                    .replace(new RegExp('@NEXT LEVEL TITLE', 'g'), nextLevelTitle);
            }

            if (committeeTypeName) {
                initialValues.Overview = initialValues.Overview
                    .replace(new RegExp('@COMMITTEE TYPE', 'g'), committeeTypeName);
            }
        }

        return (
            <div>
                <h5>General Information
                {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <a title="Edit" data-testid="editBtn" className="editBtn" onClick={onEditGenInfo}><Icon name="pencil" /></a>}</h5>
                <Grid divided='vertically' columns={4}>
                    <Grid.Row>
                        <Grid.Column>
                            <span className="labelTitle">Committee Designation</span>
                            <span className="labelValue">{initialValues.CommitteeDesignation}</span>
                        </Grid.Column>
                        <Grid.Column style={{ 'width': '50%' }}>
                            <span className="labelTitle">Title</span>
                            <span className="labelValue">{initialValues.CommitteeTitle}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <span className="labelTitle">Date Formed</span>
                            <span className="labelValue">{initialValues.EstablishmentDate}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <span className="labelTitle">Committee Type</span>
                            <span className="labelValue">{committeeTypeName}</span>
                        </Grid.Column>
                        {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showClassification && (
                            <Grid.Column>
                                <span className="labelTitle">Classification</span>
                                <span className="labelValue">{initialValues.ClassificationValue}</span>
                            </Grid.Column>
                        )}
                        <Grid.Column>
                            <label className="labelTitle">Status</label>
                            <span className="labelValue">{initialValues.StatusName}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <span className="labelTitle">Balance Rule Applicable</span>
                            <span className="labelValue">{initialValues.IsBalanceRequired}</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid divided='vertically'>
                    {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showOverview && (
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Overview</span>
                                <span className="labelValue preLine">{Parser(initialValues.Overview ? initialValues.Overview : '')}</span>
                            </Grid.Column>

                        </Grid.Row>
                    )}
                    {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showScope && (
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Committee Scope</span>
                                <span className="labelValue preLine">{Parser(initialValues.Scope ? initialValues.Scope : '')}</span>
                            </Grid.Column>

                        </Grid.Row>
                    )}
                </Grid>
                {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws && (
                    <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Bylaws</span>
                                <span className="labelValue">
                                    {initialValues.BylawUrl && (
                                        <a href={initialValues.BylawUrlLink} className="linkTxt" rel="noopener noreferrer" target='_blank'>{initialValues.BylawUrl}</a>
                                    )}
                                </span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Last Bylaws Revision Date</span>
                                <span className="labelValue">{initialValues.BylawRevisionDate}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <a className="linkTxt mt10" data-testid="linkTxt" onClick={this.openBylawsRevisionHistoryModal}>View Bylaws Revision History Details</a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}

                {showBylawsRevisionHistoryModal && (
                    <BylawsRevisionHistoryModal bylawsRevisionHistoryList={bylawsRevisionHistoryList} onCloseModal={this.closeBylawsRevisionHistoryModal} {...this.props} />
                )}
            </div>
        )
    }
}

export default ViewCommitteeGenInfo;
