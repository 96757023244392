import React, { Component } from "react";
import { Confirm, Loader } from "semantic-ui-react";
import * as _func from './function';
import RenderSelect from 'shared-components/Select';
import { checkUserPermissionOnPage } from 'helpers/util-common';
import FeeGroupList from './components/fee-group-list'
import AddFeeGroupForm from './components/add-fee-group'
import { AutoRenewal, StatusFilter, PaidStatus, FeeGroupTableColumns, feeGroupSearchType } from './feegroups.model';
import FeeGroupExcelExport from "./components/fee-group-export-excel";
import { decrypt, deepCopy, getMessage } from "../../../helpers/util-common";

class FeeGroup extends Component {
    state = {
        showList: true,
        selectedStatus: this.props.location.search.length > 0 ? StatusFilter[0].value:StatusFilter[1].value,
        feeGroupList: [],
        feeGroupAllList: [],
        totalItems: 0,
        activePageNumber: 1,
        totalPages: 0,
        currentlyShowingItems: 0,
        pageSize: 25,
        selectedAutoRenewal: AutoRenewal[0].value,
        selectedPaidStatus: PaidStatus[0].value,
        feeGroupTableHeaders: deepCopy(FeeGroupTableColumns),
        sortKey: '',
        orderType: 0,
        exportedData: [],
        isExportExcel: false,
        isOpenExcelDownloadConfirmBox: false,
        loading: false,
        searchText: this.props.location.search.length > 0 ? decrypt(this.props.location.search.substring(12)) : '',
        searchType: feeGroupSearchType.noSearch,
        noRecordTextMessage: '',
        afterSearchStoreDataForFiltering: []
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    shouldComponentUpdate(nextProps) {
        _func.handleSearchUpdate(nextProps);
        return true;
    }

    render() {
        const { showList, selectedStatus, selectedAutoRenewal, selectedPaidStatus, isExportExcel, exportedData, loading, isOpenExcelDownloadConfirmBox, feeGroupList, searchType } = this.state;
        const { PAGE_TYPE, USER_PRIVILEGE, userPermission, messageCodes } = this.props;
        const noRecordTextMessage = searchType === feeGroupSearchType.advance ? getMessage(messageCodes, '9013.text') : searchType === feeGroupSearchType.basic ? getMessage(messageCodes, '9179.text') : ''
        return (
            <div data-testid='feeGroupDiv'>
                {showList &&
                    <div className='ui memberPage memberListPage'>
                        <div className='headingTitle clearfix'>
                            <h2>Fee Group</h2>
                            {checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.ADD) &&
                                <button className="ui secondary button" onClick={() => _func.openAddFeeGroup(false)}><i aria-hidden="true" className="plus icon"></i>Add Fee Group</button>}
                        </div>
                        <div className="clearfix member-header">
                            <div className="display-inline-block mr25">
                                <label className="label-inline" for="status">Status</label>
                                <RenderSelect data-testid="status"
                                    placeholder="All"
                                    name="status"
                                    id="status"
                                    value={selectedStatus}
                                    onChange={_func.onChangeStatus}
                                    options={StatusFilter}
                                />
                            </div>
                            <div className="display-inline-block mr25" data-testid="autoRenewalWrapper">
                                <label className="label-inline" for="autoRenewal">Auto-Renewal</label>
                                <RenderSelect data-testid="autoRenewal"
                                    placeholder="All"
                                    name="autoRenewal"
                                    id="autoRenewal"
                                    value={selectedAutoRenewal}
                                    onChange={_func.onChangeAutoRenewal}
                                    options={AutoRenewal}
                                />
                            </div>
                            <div className="display-inline-block mr25" data-testid="paidStatusWrapper">
                                <label className="label-inline" for="paidStatus">Paid Status</label>
                                <RenderSelect data-testid="paidStatus"
                                    placeholder="All"
                                    name="paidStatus"
                                    id="paidStatus"
                                    value={selectedPaidStatus}
                                    onChange={_func.onChangePaidStatus}
                                    options={PaidStatus}
                                />
                            </div>
                            <div className="display-inline-block mr25">
                                <label className="label-inline">Total Associated Members:</label>
                                {feeGroupList.length > 0 ? feeGroupList.reduce((n, { AssociatedMembers }) => n + AssociatedMembers, 0) : 0}
                            </div>
                            <div className="display-inline-block floatRight">
                                <span className="label2  mr10 vMiddle display-inline-block">Export as:</span>
                                <button style={{ pointerEvents: loading ? 'none' : 'auto' }} className="ui button secondary mr10" data-testid="getExcelButton" onClick={_func.getFeeGroupExportExcel}><i className="icon file excel mr5"></i>Excel</button>
                            </div>
                        </div>

                        <FeeGroupList {...this.props} {...this.state} noRecordTextMessage={noRecordTextMessage} onPageChangeHandler={_func.onPageChangeHandler} onChangeNumberOfItemsPerPage={_func.onChangeNumberOfItemsPerPage} handleSorting={_func.handleSorting} />
                    </div>
                }
                {isExportExcel && <FeeGroupExcelExport data={exportedData} />}
                <Confirm className="confirm-box"
                    open={isOpenExcelDownloadConfirmBox}
                    content={getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyExportDataCancelHandler}
                    onConfirm={_func.emptyExportDataConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
                {loading && <Loader size='small' className="small-loader" data-testid="smallLoader">Loading</Loader>}
                {!showList && <AddFeeGroupForm {...this.props} close={_func.openAddFeeGroup} />}
            </div>
        );
    }
}
export default FeeGroup;
