import * as _action from 'action-types';
import { checkUserPermissionOnPage, getLoggedInUserId, scrollToTopOfPage } from 'helpers/util-common';
import { getDefaultYearsList } from './renewal-task.model';

export const handleWillReceiveProps = (self, nextProps) => {
    const { currentSearchType, successRequestType, currentModuleTitle } = nextProps;

    if (nextProps.location.search !== self.props.location.search && currentSearchType === _action.SEARCH_TASK) {
        const searchText = nextProps.location.search.length > 0 ? nextProps.location.search.substring(1) : '';
        self.setState({ searchText });

        resetState(self);

        // Whenever url changes, set renewal year value from the url
        getRenewalYearFromUrl(self, searchText, true);
    } else if (self.props.successRequestType !== successRequestType && successRequestType === _action.UPDATE_TASK_ASSIGNEE_STATUS_SUCCESS) {
        let isCloneConfirmForDoneStatus = false;

        // Confirm Popup visibility handling only for [ 3 : Done ] task.
        if (self.state.newStatusId === 3 && self.state.hasAddAccess) {
            isCloneConfirmForDoneStatus = true;
        }

        // Refresh the data
        loadData(self, self.state.searchText, self.state.selectedRenewalYear, true, self.state.showOverdueTasks, self.state.showDependentTasks, '', '', '', '', isCloneConfirmForDoneStatus);

        // Close the confirmation popup in case it was open
        self.props.closeConfirmationPopup();
    }

    // Recent card visiblity code
    const { payload } = nextProps.addTaskStore;
    if (payload.type !== null && payload.type === _action.CLOSE_POPUP_RENEWAL_TASK_ACTION) {
        // Refresh the data
        loadData(self, self.state.searchText, self.state.selectedRenewalYear, true, self.state.showOverdueTasks, self.state.showDependentTasks, '', '', '', '', '');
        self.props.resetAddTaskStorePayload();

        self.setState({
            recentlyAddedTaskId: payload.data
        })
    }

    // Recheck the user permissions when user click on Back button of window tab
    if (currentModuleTitle !== self.props.currentModuleTitle) {
        setUserAccess(self, nextProps.userPermission, true);
    }
}

export const canLoadNewData = (self, isRefreshData) => {
    const { pageNumber, pageSize, showRenewalData, upcomingTasksCount, openTasksCount, doneTasksCount } = self.state;

    if (!showRenewalData || isRefreshData || (upcomingTasksCount > pageNumber * pageSize) ||
        (openTasksCount > pageNumber * pageSize) ||
        (doneTasksCount > pageNumber * pageSize)) {
        self.setState({
            showLoader: true,
            dataInProgress: true
        })
        return true;
    }

    return false;
}


export const loadData = (self, searchText = '', selectedRenewalYear, isRefreshData, showOverdueTasks, showDependentTasks, clickedItem, upcomingSortBy = '', openSortBy = '', doneSortBy = '', isCloneConfirmForDoneStatus = false) => {
    let { pageNumber, pageSize, upcomingTasks, openTasks, doneTasks, upcomingSortByStatus, openSortByStatus, doneSortByStatus } = self.state;
    selectedRenewalYear = selectedRenewalYear.length > 0 ? selectedRenewalYear : getDefaultYearsList()
    if (canLoadNewData(self, isRefreshData)) {

        // If route/sorting/filters is updated, reset the data
        if (isRefreshData) {
            pageNumber = 0;
            upcomingTasks = {};
            openTasks = {};
            doneTasks = {};
            upcomingSortByStatus = upcomingSortBy.length > 0 ? clickedItem : upcomingSortByStatus;
            openSortByStatus = openSortBy.length > 0 ? clickedItem : openSortByStatus;
            doneSortByStatus = doneSortBy.length > 0 ? clickedItem : doneSortByStatus;

            // Whenever data is refreshed, Move the scroll to top.
            scrollToTopOfPage()
        }

        if (searchText.indexOf('renewalyear') === -1) {
            if (searchText.length > 0) {
                searchText = `${searchText}&renewalyear=${selectedRenewalYear.join(',')}`;
            } else {
                searchText = `renewalyear=${selectedRenewalYear.join(',')}`;
            }
        }

        self.props.getTasks(pageNumber + 1, pageSize, searchText, showOverdueTasks, showDependentTasks, upcomingSortByStatus, openSortByStatus, doneSortByStatus, (renewalData) => {

            if (renewalData && renewalData.length > 0) {
                // Filter the renewal data into specific lists
                let upcomingTasksCount = 0;
                let openTasksCount = 0;
                let doneTasksCount = 0;

                for (let i = 0; i < renewalData.length; i++) {
                    if (renewalData[i].StatusName.toLowerCase() === "upcoming") {
                        if (upcomingTasks.length > 0) {
                            upcomingTasks.push(...renewalData[i].Tasks);
                        } else {
                            upcomingTasks = renewalData[i].Tasks;
                        }

                        upcomingTasksCount = renewalData[i].Count;
                    } else if (renewalData[i].StatusName.toLowerCase() === "open") {
                        if (openTasks.length > 0) {
                            openTasks.push(...renewalData[i].Tasks);
                        } else {
                            openTasks = renewalData[i].Tasks;
                        }
                        openTasksCount = renewalData[i].Count;
                    } if (renewalData[i].StatusName.toLowerCase() === "done") {
                        if (doneTasks.length > 0) {
                            doneTasks.push(...renewalData[i].Tasks);
                        } else {
                            doneTasks = renewalData[i].Tasks;
                        }
                        doneTasksCount = renewalData[i].Count;
                    }
                }

                self.setState({
                    upcomingTasks,
                    upcomingTasksCount,
                    upcomingSortByStatus,
                    openTasks,
                    openTasksCount,
                    openSortByStatus,
                    doneTasks,
                    doneTasksCount,
                    doneSortByStatus,
                    showRenewalData: true,
                    pageNumber: pageNumber + 1,
                    isCloneConfirmForDoneStatus
                })
            }

            self.setState({
                showLoader: false,
                dataInProgress: false
            })
        });
    }
}

export const getRenewalYearFromUrl = (self, searchText, isInitialCall) => {
    let selectedRenewalYear = [];

    if (searchText && searchText.length > 0 && searchText.toLowerCase().indexOf('renewalyear') >= 0) {
        let value = searchText.substring(searchText.indexOf('renewalyear') + 12);// Extract the text after renewalyear
        let index = value.indexOf('&');

        if (index > 0) {
            value = value.substring(0, index);
        }

        selectedRenewalYear = value.split(',');
    }

    if (selectedRenewalYear.length === 0) {
        self.props.getRenewalCycleDetails(response=>{
            let defaultYears = response && response.RenewalYear ? [response.RenewalYear.toString()] : '';
            selectedRenewalYear.push(...defaultYears);
            self.setState({ selectedRenewalYear });
            self.setState({ defaultRenewalYear: self.state.selectedRenewalYear })
            loadData(self, searchText, selectedRenewalYear, isInitialCall);
        }) 
       
    }

    self.setState({ selectedRenewalYear });

    return selectedRenewalYear;
}

export const formatRenewalYearsList = (list) => {
    if (list && list.length > 0) {
        let result = [];

        for (let i = 0; i < list.length; i++) {
            let obj = {};
            obj.key = i + 1;
            obj.text = `${list[i]}`;
            obj.value = `${list[i]}`;

            result.push(obj);
        }
        return result;
    }

    return [];
}

export const resetState = (self) => {
    self.setState({
        selectedTasksType: 1,
        showOverdueTasks: false,
        showDependentTasks: false
    });
}

// Need to remove the renewal year param from the search text, will be added just before we make a call to load data
export const removeRenewalYearFromSearchText = (self) => {
    let searchText = self.state.searchText;

    if (searchText.indexOf('renewalyear') >= 0) {
        let params = searchText.split('&');
        let index = -1;

        for (let i = 0; i < params.length; i++) {
            if (params[i].indexOf('renewalyear') === 0) {
                index = i;
            }
        }

        if (index !== -1) {
            params.splice(index, 1);
            searchText = params.join('&');
        }
    }

    return searchText;
}

export const updateAssigneeInSearchText = (self, value) => {
    let assigneeId = 0;
    let searchText = self.state.searchText;

    if (value === 2) {
        assigneeId = getLoggedInUserId();
    }

    if (searchText.length === 0) {
        searchText = `assignee=${assigneeId}`;
    } else if (searchText.indexOf('assignee') >= 0) {
        let params = searchText.split('&');
        let index = -1;

        for (let i = 0; i < params.length; i++) {
            if (params[i].indexOf('assignee') === 0) {
                index = i;
            }
        }

        if (index !== -1) {
            params.splice(index, 1);
            params.push(`assignee=${assigneeId}`);
            searchText = params.join('&');
        }
    } else {
        searchText = searchText + `&assignee=${assigneeId}`;
    }

    return searchText;
}

export const exportDataHandler = (self) => {
    const { upcomingTasksCount, openTasksCount, doneTasksCount } = self.state;

    if (upcomingTasksCount > 0 || openTasksCount > 0 || doneTasksCount > 0) {
        let { searchText } = self.state;
        const { showOverdueTasks, showDependentTasks, selectedRenewalYear } = self.state;

        // Add default params
        if (searchText.indexOf('renewalyear') === -1) {
            if (searchText.length > 0) {
                searchText = `${searchText}&renewalyear=${selectedRenewalYear.join(',')}`;
            } else {
                searchText = `renewalyear=${selectedRenewalYear.join(',')}`;
            }
        }

        // Send API to download the data
        self.props.getTasksForExport(searchText, showOverdueTasks, showDependentTasks, (result) => {
            // When success, set the flags to download the excecl file.
            self.exportedData = result;
            setExportFileFlag(self);
        })
    } else {
        self.setState({ showEmptyExportDataConfirmation: true });
    }
}

export const setExportFileFlag = (self) => {
    self.setState({ showExportedData: true });

    // Reset the flag
    setTimeout(() => {
        self.setState({ showExportedData: false });
    }, 100);
}

export const setUserAccess = (self, userPermission, isListPage) => {
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    self.setState({
        hasAddAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.TASK, USER_PRIVILEGE.ADD),
        hasAdminAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.TASK, USER_PRIVILEGE.TASK_ADMIN),
        hasUpdateAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.TASK, USER_PRIVILEGE.UPDATE),
        hasUserPageAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
        hasModerateCommunicationLogAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.TASK, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG)
    });

    if (isListPage) {
        self.setState({
            hasExportAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.TASK, USER_PRIVILEGE.EXPORT)
        });
    }
}

export const setReminderPopOverHandler = (self, setReminderTaskDetails) => {
    const currentUserId = getLoggedInUserId();
    const isAssigneeOrOwner = (currentUserId === setReminderTaskDetails.AssigneeId) || (currentUserId === setReminderTaskDetails.TaskOwnerId);
    const { hasAdminAccess } = self.state;
    const canAddAnotherUserInReminder = isAssigneeOrOwner || hasAdminAccess;

    self.props.getUserList();
    self.setState({
        selectedCardDetails: setReminderTaskDetails,
        showSetReminderForm: true,
        canAddAnotherUserInReminder
    })
}
