/* eslint-disable no-empty-function */
import React from 'react';
import { useDispatch } from 'react-redux';
import { flagFeature } from '../common.actions';
import CustomLoader from '../shared-components/Loader';

export const FlagFeaturesContext = React.createContext({
    isInitializing: false,
    getFlagFeatures: () => { }
});

export const FlagFeaturesProvider = React.memo(flagFeaturesProps => {
    const dispatch = useDispatch()
    const [isInitializing, setIsInitializing] = React.useState(false);
    const [loadChild, setLoadChild] = React.useState(false)
    const [flagFeatures, setFlagFeatures] = React.useState(null);

    React.useEffect(() => {
        flagFeature((response) => {
            setLoadChild(true)
            setIsInitializing(false)
            setFlagFeatures(response)
        }, dispatch)
    }, []);

    const flagFeaturesValues = React.useMemo(() => {
        return {
            isInitializing,
            getFlagFeatures: (key) => {
                if (!flagFeatures && !flagFeatures[key]) {
                    return false;
                }
                return flagFeatures[key]
            }
        };
    }, [isInitializing, flagFeatures]);
    return <FlagFeaturesContext.Provider value={flagFeaturesValues}>
        {!loadChild && <CustomLoader />}
        {loadChild && flagFeaturesProps.children}</FlagFeaturesContext.Provider>;
});
export const useFlagFeatures = () => React.useContext(FlagFeaturesContext);
