import * as type from '../../../action-types'
import { stat } from 'fs';
const init = {
    isEdit: false,
    isRoleList: true,
    roleInfo: {
        RoleId: 0,
        RoleName: "",
        Description: '',
        Status: true,
        RolePrivilege: []
    },
    roleData: [],
    isDuplicate: false,
    viewDetail: false

}
const reducerRole = (state = init, action) => {
    switch (action.type) {
        case type.ROLE_DETAIL:
            state.roleInfo = init.roleInfo;
            return {
                ...state,
                isEdit: false,
                isDuplicate: false,
                type: action.type,
                roledata: action.payload,
                roleInfo: state.roleInfo
            }
        case type.ROLE_ADD:
            state.roleInfo = init.roleInfo;
            state.roleInfo.RolePrivilege = action.payload;
            return {
                ...state,
                isEdit: false,
                type: action.type,
                roleInfo: state.roleInfo,
                isDuplicate: false
            }
        case type.ROLE_DUPLICATE:
            return {
                ...state,
                isDuplicate: true
            }
        case type.ROLE_RESETVALIDATION:
            return {
                ...state,
                isDuplicate: false
            }
        case type.ROLE_VIEW:
            state.roleInfo = action.payload[0];
            return {
                ...state,
                roleInfo: state.roleInfo,
                isDuplicate: false,
                isEdit: true
            }
        default: return state;
    }
}
export default reducerRole;
