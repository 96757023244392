import { anchorTextLengthValidate, routeNavigationUrl } from 'helpers/util-common';
import React, { Component } from "react";
import { reduxForm } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import CustomTooltip from 'shared-components/Tooltip';
import userImgBig from "../../../../../assets/images/user-circle-big.png";
import { COMPANY_TYPE } from '../../../../../models/common.models';
import { getPaidStatusName } from '../../../members/common-functions';
import { PaidStatus, MembershipTypes, membershipTypeJSON } from '../../../members/members.model';
import { ActiveOrganizationStatusId } from '../../organizations.model';

class OrganizationBannerHeader extends Component {

    state = {
        modifiedByName: '',
        isEditMode: false
    }

    componentWillMount() {
        this.getModifiedByUserName(this.props.modifiedBy);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.modifiedBy !== this.props.modifiedBy || nextProps.modifiedDate !== this.props.modifiedDate) {
            this.getModifiedByUserName(nextProps.modifiedBy);
        }
        return true;
    }

    getModifiedByUserName = (userId) => {
        if (userId === 0 || userId === '') {
            this.setState({ modifiedByName: '' });
        } else {
            this.props.getUserName(userId, (response) => {
                if (response) {
                    const modifiedByName = response;
                    this.setState({ modifiedByName });
                }
            })
        }
    }

    render() {
        const { initialValues, PAGE_TYPE, USER_PRIVILEGE, memberMasterDetails } = this.props;
        const { modifiedByName } = this.state;
        const paidStatus = getPaidStatusName(initialValues.PaidStatusId);
        return (
            <div className="bannerMember">
                <div className="ui container">
                    <form>
                        <div className="memberInfo">
                            <figure className="memberImg">
                                <img src={userImgBig} alt="" />
                            </figure>
                            <div className="memberData">
                                <CustomTooltip icon={<span className="memberName ellip">{initialValues.FacilityName}</span>} content={initialValues.FacilityName} />
                                <span className="memberAccount"><em>Account Number:</em> {initialValues.AccountNumber ? initialValues.AccountNumber : "##"}</span>
                            </div>
                            {initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY ? (
                                <>
                                    <div className="memberRole">
                                        <span className="roleTypenName">ORGANIZATIONAL</span>
                                    </div>
                                    <div className="updatedByInfo">


                                        <span className="paidStatus"><em>Paid Status: </em><span className="pdStatus">{paidStatus}</span></span>
                                        <span className="lastUpdated">Last Updated by <strong>{modifiedByName}</strong> on {initialValues.lastModifiedDate}</span>
                                    </div>
                                </>
                            ) : ''}
                        </div>
                        <div className="memberRelatedInfo">
                            {initialValues.CompanyTypeId !== COMPANY_TYPE.FACILITY ? (
                                <Grid divided='vertically' columns={4}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <span className="titleLabel">Join Date</span>
                                            <span className="titleInfo">
                                                {initialValues.JoinDate}
                                            </span>
                                        </Grid.Column>


                                        <Grid.Column>
                                            <span className="titleLabel">Address</span>
                                            <span className="titleInfo">{initialValues && initialValues.FullAddress ? initialValues.FullAddress : ''}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <span className="titleLabel">Paid Date</span>
                                            <span className="titleInfo viewModeData">
                                                {initialValues.PaidStatusId === PaidStatus.Paid ? initialValues.PaidDate : ''}
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <span className="titleLabel">Account Status</span>
                                            <span className="titleInfo">
                                                {initialValues.StatusName}
                                            </span>
                                        </Grid.Column>
                                        {initialValues.McsStatusMasterId !== ActiveOrganizationStatusId && (
                                            <Grid.Column>
                                                <span className="titleLabel">Historical Reason</span>
                                                <span className="titleInfo">
                                                    {initialValues.HistoricalReason}
                                                </span>
                                            </Grid.Column>
                                        )}
                                        <Grid.Column>
                                            <span className="titleLabel">{initialValues.RepMembershipTypeId === MembershipTypes.cooperativeAgreementRep ?  membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName}</span>
                                            {initialValues.RepName && (
                                                <CustomTooltip icon={<span className="titleInfo">
                                                    <a className="ellip" ref={(e) => anchorTextLengthValidate(e)} href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [initialValues.RepAccountNumber])}`}>
                                                        {initialValues.RepName} {initialValues.RepAccountNumber}</a>
                                                </span>} content={`${initialValues.RepName} ${initialValues.RepAccountNumber}`} position={'bottom center'} />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            ) : ''}
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default reduxForm({
    form: 'organizationBannerHeader'
})(OrganizationBannerHeader);
