import { getMessage, routeNavigationUrl } from 'helpers/util-common';
import { getMembershipTypeName } from '../../../members/common-functions';
import { membershipTypeJSON, MembershipTypes } from '../../../members/members.model';
import { onRepMemberChange } from '../organization-edit-gen-info/function';

let self = null;
let selectedMemberAccountNumber = '';
let selectedMemberTypeId = '';
let selectedMemberName = '';
let UpdateActionsEnum = {
    "MemberAlreadyAssociated": 1,
    "ChangeMemberType": 2
}
let currentAction = '';

export const setInstance = (instance) => {
    self = instance;
    selectedMemberAccountNumber = '';
    selectedMemberName = '';
    currentAction = '';
    selectedMemberTypeId = '';
}

export const onChange = (value) => {
    selectedMemberAccountNumber = '';
    selectedMemberTypeId = '';
    onRepMemberChange(value);
}

export const onSelect = (result) => {
    const { messageCodes, PAGE_TYPE, USER_PRIVILEGE } = self.props;

    selectedMemberTypeId = result.MembershipTypeId;
    let membershipName = getMembershipTypeName(selectedMemberTypeId, self.props.membershipTypeList);
    let repMembershipTypeName = selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep ? getMembershipTypeName(MembershipTypes.cooperativeAgreementRep, self.props.membershipTypeList) : getMembershipTypeName(MembershipTypes.Representative, self.props.membershipTypeList);
    selectedMemberAccountNumber = result.MemberAccountNumber;
    selectedMemberName = result.MemberName;
    let descMessage = '';
    let repMembershipType = selectedMemberTypeId === membershipTypeJSON.CooperativeAgreementRep.id ? membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName;
    // When selected Rep member is already associated with another organizaztion
    if (result.OrgName && result.OrgName.length > 0 && (selectedMemberTypeId === MembershipTypes.Representative || selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep)) {
        let repMemberLink = `<a class='linkTxt vAuto' title='${result.MemberName}' href='${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [result.MemberAccountNumber])}'>${repMembershipType}</a>`;
        descMessage = getMessage(messageCodes, '9042.text');
        descMessage = descMessage.replace('@RepresentativeLink', repMemberLink);
        if (selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
        } else {
            descMessage = descMessage.replaceAll('@membertype',membershipTypeJSON.represntative.displayName);
        }
        currentAction = UpdateActionsEnum.MemberAlreadyAssociated;
    }
    // When the selected member is not Representative
    else {
        descMessage = getMessage(messageCodes, '9029.text');
        descMessage = descMessage.replace('@SelectedMemberName', selectedMemberName)
            .replace('@PreviousMembership', membershipName)
            .replace('@Representative', repMembershipTypeName);
        currentAction = UpdateActionsEnum.ChangeMemberType;
    }

    self.setState({ descMessage, showConfirmationModal: true });
}

export const onCancelModal = () => {
    selectedMemberAccountNumber = '';
    selectedMemberTypeId = '';
    self.setState({ showConfirmationModal: false, formattedMemberList: {} });
    self.props.change('Representative', '');
}

export const onConfirmModal = () => {
    self.setState({ showConfirmationModal: false, formattedMemberList: {} });

    if (currentAction === UpdateActionsEnum.MemberAlreadyAssociated) {
        selectedMemberAccountNumber = '';
        selectedMemberTypeId = '';
        selectedMemberName = '';
        self.props.closeModal(true); // Remove the association and dont bind new member
    } else {
        self.props.closeModal(selectedMemberAccountNumber, selectedMemberTypeId);
    }

    self.props.change('Representative', selectedMemberName);
}

export const onClose = () => {
    self.props.closeModal();
}

export const onSubmitHandler = () => {
    if (selectedMemberAccountNumber === '') {
        self.setState({ showErrorMessage: true })
    }
}
