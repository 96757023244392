import { utcDateTimeToLocalDateTime, formatDateToMMDDYYYY } from 'helpers/util-common';
import React, { Component } from "react";
import { Grid, Icon } from 'semantic-ui-react';
import CustomTooltip from 'shared-components/Tooltip'

class FeeGroupBannerHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeeGroupBannerDetails: this.props.FeeGroupBannerDetails
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ FeeGroupBannerDetails: nextProps.FeeGroupBannerDetails });
    }

    render() {
        const { FeeGroupBannerDetails: { FeeGroupTitle, FeeGroupNumber, PaidStatus, UpdatedBy, UpdatedDate, PaidStatusDate, Status } } = this.state;

        return (
            <div data-testid="feeGroupBannerHeaderComp" className="bannerMember feeGroup">
                <div className="ui container">
                    <form>
                        <div className="memberInfo">
                            <div className="memberData">
                                <CustomTooltip icon={<span className="memberName ellip">{FeeGroupTitle}</span>} content={FeeGroupTitle} />
                                <span className="memberAccount"><em>Fee Group Number:</em> {(FeeGroupNumber || '').toUpperCase()}</span>
                            </div>

                            <div className="updatedByInfo">
                                <span className="lastUpdated">Last Updated by <strong>{UpdatedBy}</strong> on {UpdatedDate && utcDateTimeToLocalDateTime(UpdatedDate)}</span>
                            </div>
                        </div>
                        <div className="memberRelatedInfo">
                            <Grid divided='vertically' columns={3}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="titleLabel">Paid Status</span>
                                        <span className="titleInfo">{PaidStatus}</span>
                                    </Grid.Column>

                                    {PaidStatus && PaidStatus.toLowerCase() === 'paid' &&
                                        <Grid.Column>
                                            <span className="titleLabel">Paid Date</span>
                                            <span className="titleInfo">{PaidStatusDate && formatDateToMMDDYYYY(PaidStatusDate)}</span>
                                        </Grid.Column>
                                    }

                                    <Grid.Column>
                                        <span className="titleLabel">Status</span>
                                        <span className="titleInfo">{Status}</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default FeeGroupBannerHeader;
