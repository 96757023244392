import { cacheManager, checkUserPermissionOnPage } from 'helpers/util-common'
import React, { Component } from 'react'
import { Tab } from 'semantic-ui-react'
import MemberAuditLogTab from '../member-audit-log'
import MemberCommitteeInfo from '../member-committee-information'
import MemberCommunicationLog from '../member-communication-log'
import MemberOrderHistory from '../member-order-history'
import MemberPersonalDetails from '../member-personal-details'

class MemberDetailsTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: (cacheManager.getItem('memberCommitteeInfo') && cacheManager.getItem('memberCommitteeInfo') > 0) ? 1 : 0
        }
    }

    userAccess = {};

    componentWillMount() {
        const { PAGE_TYPE, USER_PRIVILEGE, userPermission } = this.props;
        this.userAccess = {
            hasUserPageAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.USER, USER_PRIVILEGE.VIEW),
            hasUpdateAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.MEMBER, USER_PRIVILEGE.UPDATE),
            hasExportAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.MEMBER, USER_PRIVILEGE.EXPORT),
            hasModerateCommunicationLogAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.MEMBER, USER_PRIVILEGE.MODERATE_COMMUNICATION_LOG),
            hasAddMemberToCommitteeAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.ADD_MEMBER_TO_COMMITTEE),
            hasEditMemberParticipationOnCommittees: checkUserPermissionOnPage(userPermission, PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.EDIT_MEMBER_PARTICIPATION_ON_COMMITTEE),
            hasFeeGroupViewAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW),
            hasUpdatePaidStatusAccess: checkUserPermissionOnPage(userPermission, PAGE_TYPE.MEMBER, USER_PRIVILEGE.UPDATE_PAID_STATUS)
        }
    }

    handleTabChange = (event, data) => {
        this.setState({ activeTabIndex: data.activeIndex });
    }

    render() {
        const panes = [
            {
                menuItem: 'Personal Details', render: () => <Tab.Pane attached={false}>
                    <MemberPersonalDetails {...this.props} userAccess={this.userAccess} handleTabChange={this.handleTabChange} />
                </Tab.Pane>
            },
            {
                menuItem: 'Member Committee Information', render: () => <Tab.Pane attached={false}>
                    <MemberCommitteeInfo handleTabChange={this.handleTabChange} userAccess={this.userAccess} {...this.props} hasExportAccess={this.userAccess.hasExportAccess} hasAddMemberToCommitteeAccess={this.userAccess.hasAddMemberToCommitteeAccess} hasEditMemberParticipationOnCommittees={this.userAccess.hasEditMemberParticipationOnCommittees} />
                </Tab.Pane>
            },
            {
                menuItem: 'Order History', render: () => <Tab.Pane attached={false}>
                    <MemberOrderHistory {...this.props} hasExportAccess={this.userAccess.hasExportAccess} />
                </Tab.Pane>
            },
            {
                menuItem: 'Communication Log', render: () => <Tab.Pane attached={false}>
                    <MemberCommunicationLog {...this.props} hasUserPageAccess={this.userAccess.hasUserPageAccess} hasModerateCommunicationLogAccess={this.userAccess.hasModerateCommunicationLogAccess} />
                </Tab.Pane>
            },
            {
                menuItem: 'Audit Log', render: () => <Tab.Pane attached={false}>
                    <MemberAuditLogTab {...this.props} hasUserPageAccess={this.userAccess.hasUserPageAccess} />
                </Tab.Pane>
            }
        ]

        return (
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.state.activeTabIndex} onTabChange={this.handleTabChange} />
        )
    }
}

export default MemberDetailsTabs;
