import { encrypt, getListItemTextById, getMessage } from 'helpers/util-common';

let self = null;
let indexToDelete = -1;
let isFilterChanged = false;

export const setInstance = (instance) => {
    self = instance;
    indexToDelete = -1;
    isFilterChanged = false;

    const { status, currentPageNumber, pageSize } = self.state;
    getCommitteeMeetingSequenceData();
    getCommitteeMeetingDateList(status, currentPageNumber, pageSize, true);

    self.setState({
        noMeetingDateMessage: getMessage(self.props.messageCodes, '9050.text')
    })
}

export const handleScroll = (ev) => {
    let scrollElement = ev.srcElement;

    const { meetingDateList, meetingDatesCount, previousScrollPosition } = self.state;

    // Check if scroll direction is downward and there is significant distance from the bottom of page to load new data
    if (!self.state.dataInProgress && meetingDatesCount > meetingDateList.length &&
        scrollElement.scrollTop > previousScrollPosition &&
        (scrollElement.scrollHeight - scrollElement.clientHeight - scrollElement.scrollTop < 50)) {
        const { status, currentPageNumber, pageSize } = self.state;
        self.setState({ dataInProgress: true });
        isFilterChanged = false;
        getCommitteeMeetingDateList(status, currentPageNumber + 1, pageSize, false, () => {
            self.setState({
                currentPageNumber: Number(currentPageNumber) + 1
            })
        });
    }

    self.setState({
        previousScrollPosition: scrollElement.scrollTop
    });
}

export const onChangeStatusType = (e, value) => {
    isFilterChanged = true;
    self.setState({ status: value, currentPageNumber: 1, dataInProgress: true });
    getCommitteeMeetingDateList(value, 1, self.state.pageSize, false);
}

export const onEditMeetingsGenInfo = () => {
    self.setState({ showEditMeetingsGenInfo: true });
}

export const onCloseEditMeetingsGenInfo = () => {
    self.setState({ showEditMeetingsGenInfo: false });
}

export const onSubmitEditMeetingsGenInfo = () => {
    self.setState({ showEditMeetingsGenInfo: false });
    self.props.getCommitteeDetails(encrypt(self.props.initialValues.CommitteeId));
}

export const onOpenAddMeetingSequenceModal = () => {
    self.setState({ showAddMeetingSequence: true });
}

export const onCloseMeetingSequenceModal = () => {
    self.setState({ showAddMeetingSequence: false });
}

export const onSubmitMeetingSequenceModal = () => {
    self.setState({ showAddMeetingSequence: false });
    self.props.getCommitteeDetails(encrypt(self.props.initialValues.CommitteeId));
    getCommitteeMeetingSequenceData();
}

export const onOpenDeleteMeetingSequence = (index) => {
    indexToDelete = index;
    self.setState({ showDeleteMeetingSequence: true });
}

export const onCloseDeleteMeetingSequence = () => {
    indexToDelete = -1;
    self.setState({ showDeleteMeetingSequence: false });
}

export const onSubmitDeleteMeetingSequence = (updateReason) => {
    let { meetingSequence } = self.state;
    let selectedMeetingSequence = meetingSequence[indexToDelete];
    let meetingTypeName = getListItemTextById(self.props.allMeetingTypeList, selectedMeetingSequence.MeetingTypeId, 'text')

    let data = {
        Month: selectedMeetingSequence.Month,
        MeetingTypeName: meetingTypeName,
        UpdateReason: updateReason
    }
    const committeeId = encrypt(self.props.initialValues.CommitteeId);

    self.props.removeCommitteeMeetingSequence(selectedMeetingSequence.MeetingSequenceId, committeeId, data, () => {
        meetingSequence.splice(indexToDelete, 1);
        self.setState({ meetingSequence, showDeleteMeetingSequence: false });
        self.props.getCommitteeDetails(committeeId);
    })
}

export const onOpenAddCommitteeMeetingDate = () => {
    self.setState({ action: 'add', showAddEditCommitteeMeetingDateForm: true });
}

export const onCloseAddEditCommitteeMeetingDateModal = () => {
    self.setState({ action: '', showAddEditCommitteeMeetingDateForm: false });
}

export const onSubmitAddEditCommitteeMeetingDateModal = () => {
    const { status, pageSize } = self.state;
    isFilterChanged = false;
    self.setState({ action: '', showAddEditCommitteeMeetingDateForm: false, currentPageNumber: 1 });
    getCommitteeMeetingDateList(status, 1, pageSize, false);
    self.props.getCommitteeDetails(encrypt(self.props.initialValues.CommitteeId));
}

export const onEditCommitteeMeetingDate = (index) => {
    let selectedMeetingData = self.state.meetingDateList[index];
    self.setState({ action: 'edit', selectedMeetingData, showAddEditCommitteeMeetingDateForm: true });
}

const getCommitteeMeetingSequenceData = () => {
    let committeeId = self.props.initialValues.CommitteeId;

    self.props.getCommitteeMeetingSequence(committeeId, (response) => {
        let meetingSequence = [];

        if (response && response.length > 0) {
            meetingSequence = response;
        }

        self.setState({ meetingSequence });
    });
}

const getCommitteeMeetingDateList = (status, currentPageNumber, pageSize, showPageLoader, callback) => {
    const committeeId = self.props.initialValues.CommitteeId;
    const query = `status=${status}&pageNumber=${currentPageNumber}&pageSize=${pageSize}`;

    self.props.getCommitteeMeetingDateList(committeeId, query, showPageLoader, (response) => {
        let meetingDateList = [];
        let noMeetingDateMessage = '';

        if (response && response.Count > 0) {
            meetingDateList = self.state.meetingDateList;
            if (callback) {
                callback();
            }
            if (currentPageNumber > 1 && meetingDateList.length > 0) {
                meetingDateList.push(...response.CommitteeMeetings);
            } else {
                meetingDateList = response.CommitteeMeetings;
            }
        } else {
            noMeetingDateMessage = isFilterChanged ? getMessage(self.props.messageCodes, '9051.text') : getMessage(self.props.messageCodes, '9050.text')
        }

        self.setState({ meetingDateList, meetingDatesCount: response.Count, dataInProgress: false, noMeetingDateMessage });
    });
}
