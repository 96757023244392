import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import App from "./app";
import commonActions, {
  getCountryAndStateListAction,
  initiateAzureRequestAction,
} from "./common.actions";
import loginActions from "./modules/login/login.actions";

const mapStateToProps = (state) => {
  return {
    showToastMessage: state.commonReducer.showToastMessage,
    loading: state.commonReducer.loading,
    content: _.get(
      state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage],
      state.commonReducer.code
    ),
    showErrorPopup: state.commonReducer.showErrorPopup,
    errorMessages: state.commonReducer.errorMessages,
    isShowConfirmationPopup: state.commonReducer.isShowConfirmationPopup,
    showAlertPopup: state.commonReducer.showAlertPopup,
    alertMessage: state.commonReducer.alertMessage,
    userPermission: state.commonReducer.userPermission,
    isSafariAutoFillInfoVisible:
      state.commonReducer.isSafariAutoFillInfoVisible,
    ...state.commonReducer.PAGE_CONFIG,
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateByAzureAD: (callback) => {
    loginActions.authenticateByAzureADAction(dispatch, callback);
  },
  authByCookiesToken: (inputDTO, callback) => {
    loginActions.authByCookiesTokenAction(dispatch, inputDTO, callback);
  },
  getCountryAndStateList: () => {
    getCountryAndStateListAction(dispatch);
  },
  initiateAzureRequest: (code, sessionState, authState, cb) =>
    initiateAzureRequestAction(code, sessionState, authState, cb, dispatch),
  setSafariAutoFillInfo: (flag) => {
    commonActions.setSafariAutoFillInfoAction(dispatch, flag);
  },
  checkSessionInfo: (callback) => {
    loginActions.checkSessionInfo(dispatch, callback);
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
