import { getListItemTextById, routeNavigationUrl, formatDateToMMYYYY } from 'helpers/util-common';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

class CommitteeBannerHeader extends Component {

    state = {
        modifiedByName: '',
        committeeTypeName: '',
        staffManagerName: ''
    }

    componentWillMount() {
        const { initialValues } = this.props;

        this.setUserName(initialValues.ModifiedBy);
        this.getCommitteeType(this.props.committeeTypeList);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.currentModifiedById !== this.props.currentModifiedById) {
            this.setUserName(nextProps.currentModifiedById);
            return false;
        }

        if (!this.props.committeeTypeList && nextProps.committeeTypeList) {
            this.getCommitteeType(nextProps.committeeTypeList);
            return false;
        }

        return true;
    }

    setUserName = (userId) => {
        if (userId) {
            this.props.getUserName(userId, (response) => {
                if (response) {
                    this.setState({ modifiedByName: response });
                }
            })
        }
    }

    getCommitteeType = (committeeTypeList) => {
        let result = getListItemTextById(committeeTypeList, this.props.initialValues.CommitteeTypeId, 'text');
        this.setState({ committeeTypeName: result });

        if (result) {
            this.props.setCommitteeTypeName(result);
        }
    }

    render() {
        const { initialValues, selectedCommitteeLevelEligibilitiesDetails, PAGE_TYPE, USER_PRIVILEGE } = this.props;
        const { committeeTypeName, modifiedByName } = this.state;

        return (
            <div className="bannerCommittee">
                <div className="ui container">
                    <div className="taskInfoAction clearfix">
                        <span className="taskID ellip">{initialValues.CommitteeDesignation}</span>
                        <div className="taskStatus">{committeeTypeName}</div>
                        <div className="updatedByInfo">
                            Last Updated by <strong>{modifiedByName}</strong> on {initialValues.LastModifiedDate}
                        </div>
                    </div>
                    <div className="taskTitle">{initialValues.CommitteeTitle}</div>
                    <div className="memberRelatedInfo">
                        <Grid divided='vertically' columns={4}>
                            <Grid.Row>
                                {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showClassification && (
                                    <Grid.Column>
                                        <label className="title">Classification</label>
                                        <span className="titleInfo">{initialValues.ClassificationValue}</span>
                                    </Grid.Column>
                                )}
                                <Grid.Column>
                                    <label className="title">Date Formed</label>
                                    <span className="titleInfo">{initialValues.EstablishmentDate}</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <label className="title">Staff Manager</label>
                                    <span className="titleInfo">
                                        {initialValues.CommitteeStaffManagerList && initialValues.CommitteeStaffManagerList.map((item) => {
                                            return (
                                                <div>
                                                    <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [item.AccountNumber])}`}>
                                                        {item.StaffManager}
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </span>
                                </Grid.Column>
                                <Grid.Column>
                                    <label className="title">Status</label>
                                    <span className="titleInfo">{initialValues.StatusName + `${initialValues.StatusName.toLowerCase() === 'inactive' &&
                                        ((initialValues.InactiveReason || '').toLowerCase() === "merged") ? (`${': Merged with ' + (initialValues.MergedCommittee || 'Merged')} on ` + formatDateToMMYYYY(initialValues.InactiveDate || new Date())) :
                                        ((initialValues.InactiveReason || '').toLowerCase() === "discharged") ? (`${': Discharged on ' + formatDateToMMYYYY(initialValues.InactiveDate || new Date())}`) : ''}`

                                    }
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

export default CommitteeBannerHeader;
