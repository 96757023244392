import { encrypt, getMessage } from 'helpers/util-common';
import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal } from 'semantic-ui-react';
import CheckboxElement from 'shared-components/CheckBox';
import TextArea from 'shared-components/TextArea';
import CustomTooltip from 'shared-components/Tooltip';

let self = null;

const validateHandler = (values, props) => {
    let errors = {};

    if (!values.UpdateReason || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}

const scrollToError = () => {
    if (self.refs.updateReason) {
        self.refs.updateReason.scrollIntoView({
            behavior: 'smooth'
        });
    }
}

class ManageCommitteeClassificationModal extends Component {

    legendXMessgae = '';
    legendYMessage = '';
    legendZMessage = '';

    state = {
        classificationList: []
    }

    componentWillMount() {
        const { initialValues, messageCodes, committeeTypeName } = this.props;

        this.props.getClassificationList(initialValues.CommitteeId, (result) => {
            this.setState({ classificationList: result });
        });

        this.legendXMessgae = getMessage(messageCodes, '9054.text');
        this.legendYMessage = getMessage(messageCodes, '9055.text');
        this.legendZMessage = getMessage(messageCodes, '9056.text')
            .replace("@CommitteeType", committeeTypeName);
    }

    componentDidMount() {
        self = this;
    }

    onChange = (index) => {
        let { classificationList } = this.state;

        classificationList[index].IsActive = !classificationList[index].IsActive;
        classificationList[index].IsUpdated = !classificationList[index].IsUpdated;

        this.setState({ classificationList });
    }

    onSubmit = (data) => {
        const { updateCommitteeClassification, initialValues, onClose, displayNoChangesMadeMessage, getCommitteeDetails } = this.props;

        let updatedClassificationList = [];
        this.state.classificationList.map((item) => {
            if (item.IsUpdated) {
                updatedClassificationList.push({ ClassificationId: item.Id, IsActive: item.IsActive });
            }
        })

        if (updatedClassificationList.length > 0) {
            let requestData = {
                UpdatedClassificationList: updatedClassificationList,
                UpdateReason: data.UpdateReason
            }
            updateCommitteeClassification(initialValues.CommitteeId, requestData, () => {
                onClose();
                getCommitteeDetails(encrypt(initialValues.CommitteeId));
            })
        } else {
            displayNoChangesMadeMessage();
            onClose();
        }
    }

    render() {
        const { onClose, handleSubmit, submitting, messageCodes } = this.props;
        const { classificationList } = this.state;

        return (
            <Modal open='true' className="small" data-testid="manageCommitteeClassificationModal">
                <Form size='large' onSubmit={handleSubmit(this.onSubmit)}>

                    <Modal.Header>Manage Committee Classifications <i className="close" onClick={onClose}>&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling style={{ 'max-height': 'calc(80vh)' }}>
                        <Modal.Description>

                            <Grid columns='equal'>
                                <Grid.Row style={{ 'margin-bottom': '-10px' }}>
                                    <Grid.Column>
                                        <span className="legendNotes">
                                            <strong>Note:</strong> Delinking a classification will also de-linked it at next level Committee, if not used informing about the impact of de-linking.
                                            <CustomTooltip className="legendsTooltip" icon={<a className="linkTxt ml5 vAuto" style={{ 'font-size': '14px' }}> Legends here</a>}
                                                content={<div><span className="legendNotes">{this.legendXMessgae}</span>
                                                    <span className="legendNotes">{this.legendYMessage}</span>
                                                    <span className="legendNotes">{this.legendZMessage}</span></div>} />
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="tableWrapper paddingLR0 pt0">
                                            <table className="customTable fixHeadertable">
                                                <thead>
                                                    <tr><th width="70%">Classification Type</th>
                                                        <th width="30%">Linked/Delinked</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ 'max-height': '36vh' }}>
                                                    {classificationList && classificationList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td width="70%">
                                                                    {item.Name}
                                                                    {item.IsApplicableToBalanceRule && (
                                                                        <span className="supTxtLegends">x</span>
                                                                    )}
                                                                    {item.IsApplicableToMember && (
                                                                        <span className="supTxtLegends">y</span>
                                                                    )}
                                                                    {item.IsApplicableToAllCommittees && (
                                                                        <span className="supTxtLegends">z</span>
                                                                    )}
                                                                </td>
                                                                <td width="30%">
                                                                    <CheckboxElement name={index} IsChecked={item.IsActive}
                                                                        setLabeltitleProp='Link' IsEditable={!(item.IsApplicableToBalanceRule || item.IsApplicableToMember)}
                                                                        onChange={() => { this.onChange(index) }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                            {classificationList && classificationList.length === 0 &&
                                                <div className="noRecordMessage type2">{getMessage(messageCodes, '9051.text')}</div>
                                            }
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div ref="updateReason">
                                            <Field component={TextArea} type='text' label='Reason for update'
                                                name='UpdateReason' required={true} maxLength='200'
                                                placeholder="Please enter reason for update" />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" type="submit" disabled={submitting}>Update</button>
                            <button className="ui button cancel" onClick={onClose}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'ManageCommitteeClassificationModal',
    validate: validateHandler,
    onSubmitFail: scrollToError
})(ManageCommitteeClassificationModal);
