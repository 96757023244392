import React from "react";
import ControlledEditor from "./index";
const EditorFieldComponent = props => {

  const { required, label, input: { onChange, value }, id, suggestionList, placeholder } = props;

  return suggestionList && suggestionList.length > 0 ? (
    <ControlledEditor
      id={id}
      required={required}
      onChange={onChange}
      label={label}
      value={value}
      placeholder={placeholder}
      suggestionList={suggestionList}
    />
  ) : (
      <ControlledEditor
        id={id}
        required={required}
        onChange={onChange}
        label={label}
        value={value}
        placeholder={placeholder}
      />
    )
};

export default EditorFieldComponent;
