export const TableColumns = {
    "Account": true,
    "Name": true,
    "Company": true,
    "Email": true,
    "Committee": true,
    "MemberType": false,
    "PhoneNumber": false,
    "Country": false,
    "State": false,
    "City": false,
    "PostalCode": false,
    "Status": true,
    "Paid Status": true
}

export const SortableColumns = {
    "Account": true,
    "Name": true,
    "MemberType": false,
    "Company": true,
    "Email": true,
    "PostalCode": false,
    "Country": false,
    "Status": true,
    "Committee": false,
    "PhoneNumber": false,
    "State": false,
    "City": false,
    "Paid Status": true
}

export const ExpandableColumns = ['Name', 'Company', 'Email', 'Committee', 'Status', 'MemberType', 'PhoneNumber', 'Country', 'State', 'City', 'PostalCode', "Paid Status"]

export const OmitColumns = ['Account', 'Name', 'Company', 'Email', 'Committee', 'Status']

export const ActiveMemberStatusId = 17;

export const MemberStatusOptions = [
    { key: 0, text: "All", value: 0 },
    { key: 17, text: "Active", value: 17 },
    { key: 19, text: "Historical", value: 19 }
]

export const AccountStatusOptions = [
    { key: 1, text: "Active", value: 17 },
    { key: 2, text: "Historical", value: 19 }
]

export const MemberPaidStatusOptions = [
    { key: 0, text: "All", value: 'All' },
    { key: 1, text: "Paid", value: 'P' },
    { key: 2, text: "Not Paid", value: 'N' },
    { key: 3, text: "Hold", value: 'H' }
]

export const PaidStatusOptions = [
    { key: 1, text: "Hold", value: 'H' },
    { key: 2, text: "Paid", value: 'P' },
    { key: 3, text: "Not Paid", value: 'N' }
]

export const MembershipTypes = {
    "Honorary": 1,
    "PerpInst": 2,
    "Organizational": 3,
    "Affiliate": 4,
    "MemberInst": 5,
    "MemberTech": 6, // Participating Member
    "MemberNonTech": 7, // Informational Member
    "ExemptMember": 8,
    "SeniorMember": 9,
    "LifeMember": 10,
    "PerpIndus": 11,
    "Temporary": 12,
    "ISOJoint": 13,
    "Representative": 14,
    "NewMember": 15,
    "CoopAgrmt": 16,
    "Student": 17,
    "MouMember": 18,
    "GovtRepresentative": 19,
    "cooperativeAgreementRep": 20
}

export const PaidStatus = {
    "Paid": 'P',
    "Hold": 'H',
    "UnPaid": 'U'
}

export const DegreeOptions = [
    { key: 0, text: "Select", value: "Select" },
    { key: 1, text: "Associates Degree", value: "Associates Degree" },
    { key: 2, text: "Bachelors Degree", value: "Bachelors Degree" },
    { key: 3, text: "Certificate Program", value: "Certificate Program" },
    { key: 4, text: "Doctorate Degree", value: "Doctorate Degree" },
    { key: 5, text: "Masters Degree", value: "Masters Degree" }
]

export const OrderTableColumn = [
    { 'title': 'Order Number', 'hasSorting': true, 'className': "orderNumber", "sortKey": 'OrderNo', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Item Name', 'hasSorting': true, 'className': "itemName", 'sortKey': 'ItemName', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Description', 'hasSorting': false, 'className': "descrpt", 'sortKey': 'Description', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Ship To Address', 'hasSorting': false, 'className': "shipping", 'sortKey': 'ShipToAddress', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Timestamp', 'hasSorting': true, 'className': "timestamp", 'sortKey': 'TimeStamp', 'orderBy': 1, 'orderByIcon': 2 }
]

export const MemberCommitteeTableColumn = [
    { 'title': 'Committee Designation', 'hasSorting': true, 'className': "comDesign", "sortKey": 'CommitteeDesignation', 'orderBy': 0, 'orderByIcon': 2 },
    { 'title': 'Committee Title', 'hasSorting': true, 'className': "comTitle", 'sortKey': 'CommitteeTitle', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Classification', 'hasSorting': true, 'className': "classific", 'sortKey': 'Classification', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Classification Assigned Date', 'hasSorting': true, 'className': 'classific', 'sortKey': 'ClassificationDate', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Vote', 'hasSorting': true, 'className': "vote", 'sortKey': 'Vote', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'No Vote Reason', 'hasSorting': true, 'className': "noVoteR", 'sortKey': 'NoVoteReason', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Vote Assigned Date', 'hasSorting': true, 'className': "noVote", 'sortKey': 'VoteDate', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Committee Join Date', 'hasSorting': true, 'className': "joinDate", 'sortKey': 'CommitteeJoinDate', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Action', 'hasSorting': false, 'className': "action", 'sortKey': '', 'orderBy': 0, 'orderByIcon': 0 }
]
export const MemberCommitteeTableColumnOnBulkEdit = [
    { 'title': 'Committee Designation', 'hasSorting': true, 'className': "comDesign", "sortKey": 'CommitteeDesignation', 'orderBy': 0, 'orderByIcon': 2 },
    { 'title': 'Committee Title', 'hasSorting': true, 'className': "comTitle", 'sortKey': 'CommitteeTitle', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Classification', 'hasSorting': true, 'className': "classific", 'sortKey': 'Classification', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Classification Assigned Date', 'hasSorting': true, 'className': 'assignDate', 'sortKey': 'ClassificationDate', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Vote', 'hasSorting': true, 'className': "vote", 'sortKey': 'Vote', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'No Vote Reason', 'hasSorting': true, 'className': "noVoteR", 'sortKey': 'NoVoteReason', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Vote Assigned Date', 'hasSorting': true, 'className': "noVote", 'sortKey': 'VoteDate', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Committee Join Date', 'hasSorting': true, 'className': "joinDate", 'sortKey': 'CommitteeJoinDate', 'orderBy': 0, 'orderByIcon': 0 }

]
export const RefreshMemberCommitteeHeader = () => {
    MemberCommitteeTableColumn.map(item => {
        item.orderBy = item.sortKey === 'CommitteeDesignation' ? 1 : 0;
        item.orderByIcon = item.sortKey === 'CommitteeDesignation' ? 1 : 0;
    })
}
export const RefreshMemberCommitteeTableColumnOnBulkEdit = () => {
    MemberCommitteeTableColumnOnBulkEdit.map(item => {
        item.orderBy = item.sortKey === 'CommitteeDesignation' ? 1 : 0;
        item.orderByIcon = item.sortKey === 'CommitteeDesignation' ? 1 : 0;
    })
}
export const MemberCommitteeOptions = [
    { key: "All", text: "All", value: -1 },
    { key: "Active", text: "Active", value: 1 },
    { key: "Inactive", text: "Inactive", value: 0 },
    { key: "Officer", text: "Officer", value: 2 }
]

export const CommitteeOptions = [
    { key: "All", text: "All", value: -1 },
    { key: "Active", text: "Active", value: 1 },
    { key: "Inactive", text: "Inactive", value: 0 }
]

export const AuditGridColumns = [
    { 'title': 'Modified Date/Time', 'hasSorting': true, 'className': "date", "sortKey": 'When', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Description', 'hasSorting': false, 'className': "description", 'sortKey': 'description', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Reason for Update', 'hasSorting': false, 'className': "reason", 'sortKey': 'description', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Modified By', 'hasSorting': true, 'className': "modifiedby", 'sortKey': 'Who', 'orderBy': 0, 'orderByIcon': 0 }
]

export const AssociatedMembersColumns = [
    { 'title': 'Account', 'hasSorting': true, 'className': "MemberAccountNumber", "sortKey": 'MemberAccountNumber', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Name', 'hasSorting': false, 'className': "Name", 'sortKey': 'Name', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Member Type', 'hasSorting': false, 'className': "MembershipTypeName", 'sortKey': 'MembershipTypeName', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Status', 'hasSorting': false, 'className': "status", 'sortKey': 'status', 'orderBy': 0, 'orderByIcon': 0 }
]

export const DefaultHistoricalReason = 'No Active Member';
export const DefaultHistoricalReasonId = 26;
export const FacilityHistorical = 'Historical';
export const DeceasedHistoricalReasonId = 3; // Same as db value
export const DroppedOrgHistoricalReasonId = 17;
export const DroppedFacilityHistoricalReasonId = 25;
export const DroppedMemberHistoricalReasonId = 1;
export const UpdateCommitteesModel = [
    { id: 1, 'title': 'Update Classification', 'status': false, 'isEnable': true, 'childControl': 'radio', 'radioArray': [] },
    { id: 2, 'title': 'Update Vote', 'status': false, 'isEnable': true, 'childControl': 'radio', 'radioArray': [{ label: 'Yes', value: '', id: 0 }, { label: 'No', value: '', id: 0 }], 'secondRadioArray': [] },
    { id: 3, 'title': 'Reactivate Member', 'status': false, 'isEnable': true, 'childControl': 'radio', 'radioArray': [] },
    { id: 4, 'title': 'Inactivate Member', 'status': false, 'isEnable': true, 'childControl': 'radio', 'radioArray': [] }
]
export const AccountStatusAuditFieldId = 12;
export const HistoricalReasonAuditFieldId = 13;
export const PaidStatusAuditFieldId = 14;
export const ResponsibilityGroup = {
    roster: 'Roster'
}

export const DISABLED_EDIT_ICON_ON_HISTORICAL_STATUS = [3, 6];

export const membershipTypeJSON = {
    honorary: {
        id: 1,
        externalKey: "honorary",
        displayName: "Honorary Member"
    },
    perpetualInstitution: {
        id: 2,
        externalKey: "perpetualInstitution",
        displayName: "Perpetual Institution Member"
    },
    organizational: {
        id: 3,
        externalKey: "organizational",
        displayName: "Organizational Member"
    },
    affilate: {
        id: 4,
        externalKey: "affilate",
        displayName: "Affilate Member"
    },
    memberInstitution: {
        id: 5,
        externalKey: "memberInstitution",
        displayName: "Institution Member"
    },
    participating: {
        id: 6,
        externalKey: "participating",
        displayName: "Participating Member"
    },
    informational: {
        id: 7,
        externalKey: "informational",
        displayName: "Informational Member"
    },
    exemptMember: {
        id: 8,
        externalKey: "exemptMember",
        displayName: "Exempt Member"
    },
    seniorMember: {
        id: 9,
        externalKey: "senior",
        displayName: "Senior Member"
    },
    lifeMember: {
        id: 10,
        externalKey: "lifeMember",
        displayName: "Life Member"
    },
    perpetualIndustry: {
        id: 11,
        externalKey: "perpetualIndustry",
        displayName: "Perpetual Industry Member"
    },
    temporary: {
        id: 12,
        externalKey: "temporary",
        displayName: "Temporary Member"
    },
    isoJoint: {
        id: 13,
        externalKey: "isoJoint",
        displayName: "ISO/JOINT Member"
    },
    represntative: {
        id: 14,
        externalKey: "represntative",
        displayName: "Representative"
    },
    newMember: {
        id: 15,
        externalKey: "newMember",
        displayName: "New Member"
    },
    cooperativeAgreement: {
        id: 16,
        externalKey: "cooperativeAgreement",
        displayName: "Cooperative Agreement Member"
    },
    student: {
        id: 17,
        externalKey: "student",
        displayName: "Student Member"
    },
    mouMember: {
        id: 18,
        externalKey: "mouMember",
        displayName: "MOU Member"
    },
    GovtRepresentative: {
        id: 19,
        externalKey: "GovtRepresentative",
        displayName: "Govt Representative"
    },
    CooperativeAgreementRep: {
        id: 20,
        externalKey: "CooperativeAgreementRep",
        displayName: "COA Representative"
    }
}

export const primaryActivityKey = {
    manufacturer: 1,
    salesDistributor: 2,
    userPurchaser: 3,
    testingProduct: 4,
    consultant: 5,
    governmentAgency: 6,
    academia: 7,
    consumer: 8,
    consumerAdvocacyGroup: 9,
    other: 10,
    consultingFirm: 11
}