import { compareTwoObjectLists, deepCopy, encrypt, getMessage } from 'helpers/util-common';

let self = {};
let scrollElement = {};

export const setInstance = (instance) => {
    self = instance;
    scrollElement = {};

    self.setState({
        showEnableCommitteeOnWebSection: self.props.initialValues.IsEnableCommitteeOnWeb
    });

    setPrivilegeChecked(self.props.applicationPrivilegeOnCommittee);
}

export const handleOnChangeEnableCommitteeOnWeb = () => {
    self.setState({ showEnableCommitteeOnWebSection: !self.state.showEnableCommitteeOnWebSection, showErrorMessage: false });
}

export const showConfirmationModal = () => {
    self.setState({ showConfirmationModal: true });
}

export const onCancelModal = () => {
    self.setState({ showConfirmationModal: false });
    self.props.onCloseEditEnableOnWeb();
}

export const resetEnableOnWeb = () => {
    const { applicationPrivilegeOnCommitteeType } = self.props;
    let showEnableCommitteeOnWebSection = applicationPrivilegeOnCommitteeType && applicationPrivilegeOnCommitteeType.length > 0;

    // Reset the app privileges
    if (showEnableCommitteeOnWebSection) {
        setPrivilegeChecked(applicationPrivilegeOnCommitteeType);
    }

    self.props.change('IsEnableCommitteeOnWeb', showEnableCommitteeOnWebSection);
    self.setState({ showEnableCommitteeOnWebSection, showErrorMessage: false, showConfirmationModal: false });
}

export const handleClick = (e, props) => {
    const { index } = props
    const { activeIndex } = self.state;
    const newIndex = activeIndex === index ? -1 : index

    self.setState({ activeIndex: newIndex })
}

export const handleOnChangePrivilege = (appIndex, privilegeIndex) => {
    let { applicationPrivilegeList } = self.state;

    applicationPrivilegeList[appIndex].Privilege[privilegeIndex].IsChecked = !applicationPrivilegeList[appIndex].Privilege[privilegeIndex].IsChecked;

    // Check the Designation and Title based on Checked option
    if (privilegeIndex > 1 && applicationPrivilegeList[appIndex].Privilege[privilegeIndex].IsChecked) {
        applicationPrivilegeList[appIndex].Privilege[0].IsChecked = true;
        applicationPrivilegeList[appIndex].Privilege[1].IsChecked = true;
    }

    self.setState({ applicationPrivilegeList });
    self.props.change('_validationHack', Date.now()); // Used this to call redux form validation when classifications are updated
}

export const validateHandler = (values, props) => {
    const errors = {};
    const { UpdateReason } = values;

    /*  if (values.IsEnableCommitteeOnWeb && self.state) {
         let showErrorMessage = checkIfApplicationSelected();
         self.setState({ showErrorMessage: !showErrorMessage });
     } */

    if (!UpdateReason || (UpdateReason && UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    // If Update Reason has error, then scroll to it
    if (errors.UpdateReason && self.refs) {
        scrollElement = self.refs.forScrollPurpose;
    } else {
        scrollElement = {};
    }

    return errors;
}

// Scroll to the errors for bylaw/update reason fields
export const onSubmitFailHandler = () => {
    if (scrollElement) {
        scrollElement.scrollIntoView(true);
    }
}

export const onSubmitHandler = (data) => {
    if (!self.state.showErrorMessage) {
        const { onCloseEditEnableOnWeb, displayNoChangesMadeMessage, getCommitteeDetails, applicationPrivilegeOnCommittee, applicationPrivilegeOnCommitteeType, initialValues: { IsEnableCommitteeOnWeb, CommitteeId, IsSuppressOnWI } } = self.props;
        const { applicationPrivilegeList } = self.state;
        let newAppPrivilege = data.IsEnableCommitteeOnWeb ? getNewAppPrivilege(applicationPrivilegeList, applicationPrivilegeOnCommitteeType) : [];
        let appPrivilegeUpdatedList = data.IsEnableCommitteeOnWeb ? getNewAppPrivilege(applicationPrivilegeList, applicationPrivilegeOnCommittee) : [];
        let oldAppPrivilege = getOldAppPrivilege(applicationPrivilegeOnCommittee);

        if (data.IsEnableCommitteeOnWeb !== IsEnableCommitteeOnWeb || appPrivilegeUpdatedList.length > 0 || data.IsSuppressOnWI !== IsSuppressOnWI) {
            let requestObj = {
                IsEnableCommitteeOnWeb: data.IsEnableCommitteeOnWeb,
                CommitteeApplicationPrivilege: newAppPrivilege,
                OldCommitteeApplicationPrivilege: oldAppPrivilege,
                UpdateReason: data.UpdateReason,
                IsSuppressOnWI: data.IsSuppressOnWI
            }

            self.props.updateEnableCommitteeOnWeb(encrypt(CommitteeId), requestObj, () => {
                getCommitteeDetails(encrypt(CommitteeId));
                onCloseEditEnableOnWeb();
            })
        } else {
            displayNoChangesMadeMessage();
            onCloseEditEnableOnWeb();
        }
    }
}

// Private functions
const setPrivilegeChecked = (appPrivilegeToCheck) => {
    const { applicationPrivilege } = self.props;
    let applicationPrivilegeList = deepCopy(applicationPrivilege);

    applicationPrivilegeList.map((application) => {
        if (appPrivilegeToCheck && appPrivilegeToCheck.length > 0) {
            let data = appPrivilegeToCheck.find(item => item.ApplicationId === application.ApplicationId);

            if (data && data.Privilege && data.Privilege.length > 0 && application.Privilege && application.Privilege.length > 0) {
                application.Privilege.map((privilegeItem) => {
                    let privilege = data.Privilege.find(item => item.ApplicationPrivilegeId === privilegeItem.ApplicationPrivilegeId);

                    if (privilege) {
                        privilegeItem.IsChecked = privilege.IsChecked;
                    }
                })
            } else if (application.Privilege && application.Privilege.length > 0) {
                application.Privilege.map((privilegeItem) => {
                    privilegeItem.IsChecked = false;
                })
            }
        }
    })

    self.setState({ applicationPrivilegeList });
}

const getNewAppPrivilege = (newList, oldList) => {
    let result = [];

    newList.map((item) => {
        let appPrivilege = oldList.find(oldItem => oldItem.ApplicationId === item.ApplicationId);
        let newSelectedPrivilegeList = getSelectedPrivileges(item.Privilege);

        // If the app privilege exists, then check if any privilege is updated, else add the new app privilege
        if (appPrivilege) {
            let oldSelectedPrivilegeList = getSelectedPrivileges(appPrivilege.Privilege);
            let isPrivilegesUpdated = compareTwoObjectLists(newSelectedPrivilegeList, oldSelectedPrivilegeList, "ApplicationPrivilegeId");

            if (isPrivilegesUpdated) {
                result.push({ ApplicationId: item.ApplicationId, ApplicationName: item.ApplicationName, ApplicationPrivilege: newSelectedPrivilegeList });
            }
        } else if (newSelectedPrivilegeList.length > 0) {
            result.push({ ApplicationId: item.ApplicationId, ApplicationName: item.ApplicationName, ApplicationPrivilege: newSelectedPrivilegeList });
        }
    })

    return result;
}

const checkIfApplicationSelected = () => {
    const { applicationPrivilegeList } = self.state;
    let result = false;

    if (applicationPrivilegeList && applicationPrivilegeList.length > 0) {
        for (let i = 0; i < applicationPrivilegeList.length; i++) {
            let privileges = applicationPrivilegeList[i].Privilege;

            for (let j = 0; j < privileges.length; j++) {
                if (privileges[j].IsChecked) {
                    result = true;
                    break;
                }
            }

            if (result) {
                break;
            }
        }
    }

    return result;
}

const getSelectedPrivileges = (privilegeList) => {
    let result = [];

    privilegeList.map((item) => {
        if (item.IsChecked) {
            result.push({ ApplicationPrivilegeId: item.ApplicationPrivilegeId, ApplicationPrivilegeName: item.ApplicationPrivilegeName });
        }
    })

    return result;
}

const getOldAppPrivilege = (appPrivilegeList) => {
    let result = [];

    if (appPrivilegeList && appPrivilegeList.length > 0) {
        appPrivilegeList.map((item) => {
            let privilegeList = getSelectedPrivileges(item.Privilege);
            result.push({ ApplicationId: item.ApplicationId, ApplicationName: item.ApplicationName, ApplicationPrivilege: privilegeList });
        });
    }

    return result;
}

export const handleOnChangeEnableSuppressOnWI = (e, value) => {
    const { ClassificationData } = self.props.initialValues.ClassificationStatisticalInfo;
    if (value && ClassificationData && ClassificationData.length > 1 && ClassificationData[1].Count > 0) {
        self.setState({ showActiveStandardStatusModel: true });
    }
}

export const cancelSuppressOnWI = () => {
    self.props.change('IsSuppressOnWI', false);
    self.setState({ showActiveStandardStatusModel: false });
}

export const confirmSuppressOnWI = () => {
    self.setState({ showActiveStandardStatusModel: false });
}