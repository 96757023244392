import React, { Component } from "react";
import { MembershipTypes } from '../../members.model';
import MemberAccountHistoryModal from '../member-account-history-modal';
import MemberEditAccountDetails from '../member-edit-account-details';
import MemberEditGeneralInfo from '../member-edit-gen-info';
import MemberPersonalDetailsViewEdit from '../member-personal-details-view-edit';
import MemberViewAccountDetails from '../member-view-account-details';
import MemberViewGeneralInfo from '../member-view-gen-info';
import MemberViewGeneralStudentInfo from '../member-view-gen-student-info';
import MembershipTypeEditInfo from '../membership-type-edit';
import MembershipTypeInfo from '../membership-type-info';

class MemberPersonalDetails extends Component {

    state = {
        isEditGenInfo: false,
        isEditAccountInfo: false,
        isEditMembershipTypeInfo: false,
        showAccountStatusModal: false
    }

    editGenInfo = () => {
        this.setState({ isEditGenInfo: true });
    }

    editMembershipTypeInfo = () => {
        this.setState({ isEditMembershipTypeInfo: true });
    }

    editAccountInfo = () => {
        this.setState({ isEditAccountInfo: true });
    }

    closeEditGenInfo = () => {
        this.setState({ isEditGenInfo: false });
    }

    closeEditMembershipTypeInfo = () => {
        this.setState({ isEditMembershipTypeInfo: false });
    }

    closeAccountInfo = () => {
        this.setState({ isEditAccountInfo: false });
    }

    openAccountStatusModal = () => {
        this.props.getMemberAccountHistoryDetails(this.props.initialValues.AccountNumber, this.props.accountStatusAuditFieldName, this.props.paidStatusAuditFieldName);
        this.setState({ showAccountStatusModal: true });
    }

    closeAccountHistoryModal = () => {
        this.setState({ showAccountStatusModal: false });
    }

    render() {
        const { initialValues, memberAccountHistoryList, userAccess } = this.props;
        const { isEditGenInfo, isEditAccountInfo, isEditMembershipTypeInfo, showAccountStatusModal } = this.state;
        return (
            <div className="memberPersonalDetail">
                {initialValues && initialValues.AccountNumber && (
                    <div>
                        {/* To re-render component when nickName is updated, passing a dummy nickName param*/}
                        <MemberPersonalDetailsViewEdit {...this.props} updatedFacilityName={initialValues.FacilityName}
                            nickName={initialValues.NickName} McsStatusMasterId={initialValues.McsStatusMasterId}
                            hasUpdateAccess={userAccess.hasUpdateAccess} />
                        <section>
                            <div className="dashed-border mt20 mb20"></div>
                            {!isEditMembershipTypeInfo ?
                                <MembershipTypeInfo {...this.props} onEditMembershipTypeInfo={this.editMembershipTypeInfo} userAccess={userAccess} />
                                : <MembershipTypeEditInfo {...this.props} onCloseEditMembershipTypeInfo={this.closeEditMembershipTypeInfo} />
                            }
                        </section>
                        <section>
                            <div className="dashed-border mt20 mb20"></div>
                            {!isEditGenInfo ?
                                initialValues.MemberTypeId === MembershipTypes.Student ?
                                    <MemberViewGeneralStudentInfo initialValues={initialValues} onEditGenInfo={this.editGenInfo} hasUpdateAccess={userAccess.hasUpdateAccess} />
                                    : <MemberViewGeneralInfo {...this.props} onEditGenInfo={this.editGenInfo} hasUpdateAccess={userAccess.hasUpdateAccess} hasFeeGroupViewAccess={userAccess.hasFeeGroupViewAccess} />
                                : <MemberEditGeneralInfo {...this.props} onCloseEditGenInfo={this.closeEditGenInfo} />
                            }
                        </section>
                        <section>
                            <div className="dashed-border mt20 mb20"></div>
                            {!isEditAccountInfo ? (
                                <MemberViewAccountDetails initialValues={initialValues} onEditAccountInfo={this.editAccountInfo}
                                    onClickAccountStatusHistory={this.openAccountStatusModal} hasUpdateAccess={userAccess.hasUpdateAccess} />
                            ) : <MemberEditAccountDetails {...this.props} onCloseEditAccountInfo={this.closeAccountInfo} hasUpdatePaidStatusAccess={userAccess.hasUpdatePaidStatusAccess} />
                            }
                        </section>
                    </div>
                )}
                {showAccountStatusModal && (
                    <MemberAccountHistoryModal label="MEMBER ACCOUNT HISTORY DETAILS" hasUserPageAccess={userAccess.hasUserPageAccess}
                        memberAccountHistoryList={memberAccountHistoryList} onCloseAccountHistoryModal={this.closeAccountHistoryModal} {...this.props} />
                )}
            </div>
        );
    }
}


export default MemberPersonalDetails;
