import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Grid } from 'semantic-ui-react';
import RenderInputField from 'shared-components/Input';
import AutoSearch from 'shared-components/Search';
import RenderSelect from 'shared-components/Select';
import { OrganizationStatusOptions } from '../../organizations.model';
import * as _func from './function';

class AdvanceSearchOrganization extends Component {

    state = {
        isResetClicked: false,
        orgNameList: [],
        repFirstNameList: [],
        repLastNameList: [],
        stateList: [],
        isLoadingOrgList: false,
        isLoadingRepFirstList: false,
        isLoadingRepLastist: false,
        isShowOrgErrorMsg: false,
        isShowRepFirstErrorMsg: false,
        isShowRepLastErrorMsg: false,
        isStateListDisabled: false
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { handleSubmit, countryList } = this.props;
        const { orgNameList, repFirstNameList, repLastNameList, isLoadingOrgList, isLoadingRepFirstList, isLoadingRepLastList, isShowOrgErrorMsg, isShowRepFirstErrorMsg, isShowRepLastErrorMsg, stateList, isStateListDisabled } = this.state;

        return (
            <Form onSubmit={handleSubmit} noValidate>
                <Grid columns='equal' className="mt0">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="Account" type="text"
                                component={RenderInputField} label="Account Number"
                                placeholder="Account Number"
                                maxLength="10"
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <label>Organization Name</label>
                            <Field name="OrganizationName" type="text"
                                component={AutoSearch}
                                source={orgNameList}
                                placeholder="Organization Name"
                                onChange={_func.getOrgnizationName}
                                showNoResults={isShowOrgErrorMsg}
                                isLoading={isLoadingOrgList}
                                maxLength="150"
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10" style={{ display: 'none' }}>

                        <Grid.Column>
                            <label>Representative First Name</label>
                            <Field name="FirstName" type="text"
                                component={AutoSearch}
                                placeholder="Representative First Name"
                                source={repFirstNameList}
                                onChange={_func.getRepresentativeFirstName}
                                isLoading={isLoadingRepFirstList}
                                showNoResults={isShowRepFirstErrorMsg}
                                maxLength="150" />

                        </Grid.Column>
                        <Grid.Column>
                            <label>Representative Last Name</label>
                            <Field name="LastName" type="text"
                                component={AutoSearch}
                                placeholder="Representative Last Name"
                                source={repLastNameList}
                                onChange={_func.getRepresentativeLastName}
                                isLoading={isLoadingRepLastList}
                                showNoResults={isShowRepLastErrorMsg}
                                maxLength="150" />

                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="AccountStatus"
                                component={RenderSelect} label="Organization Type"
                                placeholder="All"
                                options={OrganizationStatusOptions}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Field name="PostalCode" type="text"
                                component={RenderInputField} label="Postal Code"
                                placeholder="Postal Code"
                                maxLength="150" />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="Country"
                                component={RenderSelect} label="Country"
                                placeholder="All"
                                options={countryList}
                                onChange={_func.onCountryChange}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Field name="State" disabled={isStateListDisabled}
                                component={RenderSelect} label="State/Province"
                                placeholder="All"
                                options={stateList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="City" type="text"
                                component={RenderInputField} label="City"
                                placeholder="City"
                                maxLength="150" />

                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb0">
                        <Grid.Column className="actions">
                            <Button primary type="submit" data-testid="btnAdvSearch">Search</Button>
                            <Button data-testid="btnAdvReset" className="cancel ml10" type="reset" onClick={_func.resetForm}>Reset</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

        );
    }
}

export default (reduxForm({
    form: 'AdvanceSearchOrganizationForm',
    onSubmit: _func.onSubmitHandler
})(AdvanceSearchOrganization));
