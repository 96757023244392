import React, { Component } from 'react';

class dgActionMenu extends Component {

    state = {
        position: this.props.position,
        options: {},
        escaped: [],
        option: this.props.options
    }

    posStyle = () => {
        let styl = {
            position: 'absolute',
            zIndex: 100
        }
        return styl;
    };

    shouldBeEscaped = (option) => {
        let self = this;
        return self.props.escaped ? self.props.escaped.includes(option) : false
    }

    onChange(event, attributes) {
        let { option } = this.state;

        option[attributes] = !option[attributes];
        this.setState({ option: option })
        this.props.hidecolumn(this.state.option);
    }

    render() {
        const { position } = this.state;
        return (
            <div className="dg-menu Container" style={{ left: position.x, top: position.y, right: '50px' }} >
                <div className="panel">
                    <span className="react-grid-action-icon react-grid-active">
                        <ul className="react-grid-action-menu-container">
                            <div className="option" style={{ margin: '.5em 0' }}>
                                <button className="ui button primary">Edit</button>
                                <button className="ui button secondary floatRight">View</button>
                            </div>
                        </ul>
                    </span>
                </div>
            </div>
        )
    }
}
export default dgActionMenu;
