
import * as utilCommon from 'helpers/util-common';
import { AdvanceSearchQueryFields, AppGridIds } from 'models/common.models';
import { CommitteeStatusOptions } from 'modules/committee-management/committee.model';
import { getDefaultYearsList } from 'modules/renewal-task/renewal-task.model';
import { StatusFilter } from 'modules/member-management/fee-group/feegroups.model'
import { SEARCH_COMMITTEES, SEARCH_MEMBERS, SEARCH_ORGANIZATIONS, SEARCH_TASK, SEARCH_FEEGROUP } from '../../action-types';


let self = null;
export const setInstance = (instance) => {
    self = instance;

    toggleAdvanceSearchVisibility();
}

export const onAdvanceSearchSubmit = (query) => {
    self.props.onAdvanceSearchSubmit(query);
}

// Private functions
const toggleAdvanceSearchVisibility = () => {
    switch (self.props.currentSearchOption) {
        case SEARCH_MEMBERS:
            let memberFlag = self.state.showAdvanceSearchMember;
            if (!memberFlag) {
                setAdvanceSearchMemberInitialValues();
            }
            self.setState({
                showAdvanceSearchMember: !memberFlag
            });
            break;
        case SEARCH_ORGANIZATIONS:
            let organizationFlag = self.state.showAdvanceSearchOrganization;

            if (!organizationFlag) {
                setAdvanceSearchOrganizationInitialValues();
            }
            self.setState({
                showAdvanceSearchOrganization: !organizationFlag
            });
            break;
        case SEARCH_COMMITTEES:
            let committeeFlag = self.state.showAdvanceSearchCommittee;

            if (!committeeFlag) {
                setAdvanceSearchCommitteeInitialValues();
            }
            self.setState({
                showAdvanceSearchCommittee: !committeeFlag
            });
            break;
        case SEARCH_TASK:
            let flag = self.state.showAdvanceSearchRenewalTask;
            if (!flag) {
                setAdvanceSearchTaskInitialValues();
            }
            self.setState({
                showAdvanceSearchRenewalTask: !flag
            });
            break;
        case SEARCH_FEEGROUP:
            let feeGroupFlag = self.state.showAdvanceSearchFeeGroup;
            if (!feeGroupFlag) {
                setAdvanceFeeGroupInitialValues();
            }
            self.setState({
                showAdvanceSearchFeeGroup: !feeGroupFlag
            });
            break;
        default:
            self.setState({
                showAdvanceSearchMember: false
            });
    }
}

const setAdvanceSearchTaskInitialValues = () => {
    let TaskUniqueCode, Title, Status, RenewalYear, Assignee, ShowInactiveTasks;
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    // If the user is on renewal tasks list page, get the params from the url, if any
    if (self.props.currentSearchOption === utilCommon.getCurrentSearchPageOptions(PAGE_TYPE.TASK, USER_PRIVILEGE.VIEW)) {
        let searchQueries = getSearchQueriesList(self.props.location.search);
        // It is used to not set the values of controls with same name
        let gridID = getGridIdParam(searchQueries);
        if (Number(gridID) === AppGridIds.TaskGrid) {
            for (let i = 0; i < searchQueries.length; i++) {
                let indexOfEqualTo = searchQueries[i].indexOf('=');
                if (indexOfEqualTo > 0) {
                    let key = searchQueries[i].substring(0, indexOfEqualTo).toLowerCase();
                    // eslint-disable-next-line default-case
                    switch (key) {
                        case 'taskuniquecode':
                            TaskUniqueCode = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'title':
                            Title = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'status':
                            Status = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case 'renewalyear':
                            RenewalYear = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case 'assignee':
                            let val = parseInt(searchQueries[i].substring(indexOfEqualTo + 1));
                            Assignee = isNaN(val) ? null : val;
                            break;
                        case 'showinactivetasks':
                            ShowInactiveTasks = (searchQueries[i].substring(indexOfEqualTo + 1) === 'true');
                            break;
                    }
                }
            }
        }
    }

    if (RenewalYear && RenewalYear.length > 0) {
        RenewalYear = RenewalYear.split(',')
    } else {
        RenewalYear = getDefaultYearsList();
    }

    self.setState({
        advanceSearchTaskInitialValues: {
            TaskUniqueCode,
            Title,
            Status: Status && Status.length > 0 ? Status.split(',') : [],
            RenewalYear,
            Assignee,
            ShowInactiveTasks
        }
    });
}

const setAdvanceSearchMemberInitialValues = () => {
    let FirstName, LastName, AccountNumber, MemberType, CompanyName, CommitteeDesignation, AccountStatus, Email, PhoneNumber, Country, PostalCode, City, State;
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    // If the user is on renewal tasks list page, get the params from the url, if any
    if (self.props.currentSearchOption === utilCommon.getCurrentSearchPageOptions(PAGE_TYPE.MEMBER, USER_PRIVILEGE.VIEW)) {

        let searchQueries = getSearchQueriesList(self.props.location.search);
        // It is used to not set the values of controls with same name
        let gridID = getGridIdParam(searchQueries);

        if (Number(gridID) === AppGridIds.MemberGrid) {
            for (let i = 0; i < searchQueries.length; i++) {
                let indexOfEqualTo = searchQueries[i].indexOf('=');

                if (indexOfEqualTo > 0) {
                    let key = searchQueries[i].substring(0, indexOfEqualTo).toLowerCase();
                    // eslint-disable-next-line default-case
                    switch (key) {
                        case 'firstname':
                            FirstName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'lastname':
                            LastName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'account':
                            AccountNumber = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'membershiptypeid':
                            MemberType = Number(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'committee':
                            CommitteeDesignation = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case 'company':
                            CompanyName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'status':
                            AccountStatus = Number(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'email':
                            Email = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'phonenumber':
                            PhoneNumber = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'country':
                            Country = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'state':
                            State = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'postalcode':
                            PostalCode = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'city':
                            City = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                    }
                }
            }
        }
    }

    self.setState({
        advanceSearchMemberInitialValues: {
            FirstName,
            LastName,
            AccountNumber,
            MemberType,
            CompanyName,
            Country,
            State,
            CommitteeDesignation: CommitteeDesignation && CommitteeDesignation.length > 0 ? CommitteeDesignation.split(',') : [],
            AccountStatus, Email, PhoneNumber, PostalCode, City
        }
    });
}

const setAdvanceSearchOrganizationInitialValues = () => {

    let Account, OrganizationName, AccountStatus, FirstName, LastName, Country, State, City, PostalCode;
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    // If the user is on organization list page, get the params from the url, if any
    if (self.props.currentSearchOption === utilCommon.getCurrentSearchPageOptions(PAGE_TYPE.ORGANIZATION, USER_PRIVILEGE.VIEW)) {

        let searchQueries = getSearchQueriesList(self.props.location.search);
        // It is used to not set the values of controls with same name
        let gridID = getGridIdParam(searchQueries);
        if (Number(gridID) === AppGridIds.OrganizationGrid) {
            for (let i = 0; i < searchQueries.length; i++) {
                let indexOfEqualTo = searchQueries[i].indexOf('=');

                if (indexOfEqualTo > 0) {
                    let key = searchQueries[i].substring(0, indexOfEqualTo).toLowerCase();
                    // eslint-disable-next-line default-case
                    switch (key) {
                        case 'account':
                            Account = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'organizationname':
                            OrganizationName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'firstname':
                            FirstName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'lastname':
                            LastName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'country':
                            Country = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'state':
                            State = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case ('companytype'):
                            AccountStatus = Number(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'city':
                            City = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case 'postalcode':
                            PostalCode = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                    }
                }
            }
        }
    }

    self.setState({
        advanceSearchOrganizationInitialValues: {
            Account,
            OrganizationName,
            AccountStatus,
            Country,
            FirstName, LastName, City,
            PostalCode, State
        }
    });
}

const setAdvanceSearchCommitteeInitialValues = () => {

    let CommitteeDesignation, CommitteeTitle, Status, Classification, CommitteeType, CommitteeHierarchy;
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    // If the user is on committee list page, get the params from the url, if any
    if (self.props.currentSearchOption === utilCommon.getCurrentSearchPageOptions(PAGE_TYPE.COMMITTEE, USER_PRIVILEGE.VIEW)) {

        let searchQueries = getSearchQueriesList(self.props.location.search);
        // It is used to not set the values of controls with same name
        let gridID = getGridIdParam(searchQueries);

        if (Number(gridID) === AppGridIds.CommitteeGrid) {
            for (let i = 0; i < searchQueries.length; i++) {
                let indexOfEqualTo = searchQueries[i].indexOf('=');

                if (indexOfEqualTo > 0) {
                    let key = searchQueries[i].substring(0, indexOfEqualTo).toLowerCase();
                    // eslint-disable-next-line default-case
                    switch (key) {
                        case AdvanceSearchQueryFields.CommitteeDesignation:
                            CommitteeDesignation = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.CommitteeTitle:
                            CommitteeTitle = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.Status:
                            Status = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case AdvanceSearchQueryFields.Classification:
                            Classification = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case AdvanceSearchQueryFields.CommitteeType:
                            CommitteeType = Number(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.CommitteeLevel:
                            CommitteeHierarchy = searchQueries[i].substring(indexOfEqualTo + 1);
                            if (CommitteeHierarchy && CommitteeHierarchy !== 'all') {
                                CommitteeHierarchy = CommitteeHierarchy.split(',');
                            } else {
                                CommitteeHierarchy = null; // Setting it null to not select any option from the hierarchy dropdown
                            }
                            break;
                    }
                }
            }
        }
    }

    self.setState({
        advanceSearchCommitteeInitialValues: {
            CommitteeDesignation,
            CommitteeTitle,
            Status: Status ? Status : CommitteeStatusOptions[1].value,
            Classification,
            CommitteeType,
            CommitteeHierarchy
        }
    });
}

const setAdvanceFeeGroupInitialValues = () => {
    let FeeGroupNumber, FeeGroupTitle, Status, PaidStatus, AutoRenewal, Country, ContactName, AssociatedMemberAccountNo, AssociatedMemberFirstName, AssociatedMemberLastName;
    const { PAGE_TYPE, USER_PRIVILEGE } = self.props;
    // If the user is on committee list page, get the params from the url, if any
    if (self.props.currentSearchOption === utilCommon.getCurrentSearchPageOptions(PAGE_TYPE.FEEGROUP, USER_PRIVILEGE.VIEW)) {

        let searchQueries = getSearchQueriesList(self.props.location.search);
        // It is used to not set the values of controls with same name
        let gridID = getGridIdParam(searchQueries);
        if (Number(gridID) === AppGridIds.FeeGroupGrid) {
            for (let i = 0; i < searchQueries.length; i++) {
                let indexOfEqualTo = searchQueries[i].indexOf('=');
                if (indexOfEqualTo > 0) {
                    let key = searchQueries[i].substring(0, indexOfEqualTo);
                    // eslint-disable-next-line default-case
                    switch (key) {
                        case AdvanceSearchQueryFields.FeeGroupNumber:
                            FeeGroupNumber = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.FeeGroupTitle:
                            FeeGroupTitle = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.Status:
                            Status = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case AdvanceSearchQueryFields.PaidStatus:
                            PaidStatus = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case AdvanceSearchQueryFields.AutoRenewal:
                            AutoRenewal = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case AdvanceSearchQueryFields.Country:
                            Country = searchQueries[i].substring(indexOfEqualTo + 1);
                            break;
                        case AdvanceSearchQueryFields.ContactName:
                            ContactName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.AssociatedMemberAccountNo:
                            AssociatedMemberAccountNo = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.AssociatedMemberFirstName:
                            AssociatedMemberFirstName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                        case AdvanceSearchQueryFields.AssociatedMemberLastName:
                            AssociatedMemberLastName = utilCommon.decrypt(searchQueries[i].substring(indexOfEqualTo + 1));
                            break;
                    }
                }
            }
        }
    }

    self.setState({
        advanceSearchFeeGroupInitialValues: {
            FeeGroupNumber,
            FeeGroupTitle,
            PaidStatus,
            Status: Status ? Status : StatusFilter[1].value,
            AutoRenewal,
            Country,
            ContactName,
            AssociatedMemberAccountNo,
            AssociatedMemberFirstName,
            AssociatedMemberLastName
        }
    });
}

const getSearchQueriesList = (search) => {
    if (search.length > 1) {
        let queryParams = search.substring(1);
        return queryParams.length > 0 ? queryParams.split('&') : [];
    }

    return [];
}

const getGridIdParam = (queryParamList) => {
    let gridId = '';
    if (queryParamList && queryParamList.length > 1) {
        let gridIdParam = queryParamList.find(i => i.indexOf(AdvanceSearchQueryFields.GridId) === 0)
        if (gridIdParam) {
            let list = gridIdParam.split('=');

            if (list && list.length === 2 && list[1]) {
                gridId = list[1];
            }
        }
    }

    return gridId;
}
