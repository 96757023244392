import * as types from 'action-types';
import { deepCopy, formatAuditLogFieldList, formatDateToMMDDYYYY, formatDateToYYYY, getListItemTextById, getListItemTextByValue, utcDateTimeToLocalDateTime } from 'helpers/util-common';
import { McsStatusMasterId } from 'models/common.models';
import { CommitteeClassificationOptions, CommitteeStatusOptions } from '../committee.model';

const initialState = {
    initialValues: {},
    allMeetingTypeList: null,
    activeMeetingTypeList: null,
    committeeUserList: null,
    membershipTypeList: [],
    classificationList: [],
    noVoteReasonList: [],
    officerTitleList: [],
    operatorList: null,
    applicationPrivilege: null,
    committeeAuditLogFieldList: null,
    inActiveReasons: [],
    dependentCommittee: [],
    mergeCommittees: [],
    activeMemberCount: 0
}

const formatCommitteeDetails = ({...committeeDetails}) => {

    committeeDetails.NumberOfAttendees = committeeDetails.NumberOfAttendees !== null ? committeeDetails.NumberOfAttendees : '';
    committeeDetails.NumberOfMeetingDays = committeeDetails.NumberOfMeetingDays !== null ? committeeDetails.NumberOfMeetingDays : '';
    committeeDetails.NumberOfStandards = committeeDetails.NumberOfStandards ? committeeDetails.NumberOfStandards : '';
    committeeDetails.NumberOfTimesCommitteeMeets = committeeDetails.NumberOfTimesCommitteeMeets ? committeeDetails.NumberOfTimesCommitteeMeets : '';
    committeeDetails.MeetingMonths = committeeDetails.MeetingMonths && committeeDetails.MeetingMonths != '' && committeeDetails.MeetingMonths.length > 0 ? committeeDetails.MeetingMonths.join(', ') : ' ';
    committeeDetails.CommitteeTitle = committeeDetails.CommitteeTitle ? committeeDetails.CommitteeTitle.trim() : '';
    committeeDetails.StatusName = getListItemTextById(CommitteeStatusOptions, committeeDetails.Status, 'text');
    committeeDetails.Classification = committeeDetails.Classification ? committeeDetails.Classification.toLowerCase() : null;
    committeeDetails.ClassificationValue = getListItemTextByValue(CommitteeClassificationOptions, committeeDetails.Classification, 'text');
    committeeDetails.IsBalanceRequired = committeeDetails.IsBalanceRequired ? 'Yes' : 'No';

    // If the operator symbol is not there for committee, set it from balance rule
    if (committeeDetails.CommitteeBalanceRule && committeeDetails.CommitteeBalanceRule.OperatorSymbol) {
        committeeDetails.OperatorSymbol = committeeDetails.CommitteeBalanceRule.OperatorSymbol;
    }

    // Set the balance rule of Committee type in case status is Active and Committee does not have its own balance rule
    if (committeeDetails.Status === McsStatusMasterId.ActiveCommittee &&
        committeeDetails.CommitteeBalanceRule && committeeDetails.CommitteeBalanceRule.length === 0) {
        committeeDetails.CommitteeBalanceRule = deepCopy(committeeDetails.CommitteeTypeBalanceRule);
    }

    if (committeeDetails.EstablishmentDate) {
        committeeDetails.EstablishmentDate = formatDateToMMDDYYYY(committeeDetails.EstablishmentDate);
    }

    // Append http:// in the start
    committeeDetails.BylawUrlLink = committeeDetails.BylawUrl;
    if (committeeDetails.BylawUrl && (committeeDetails.BylawUrl.toLowerCase().indexOf('http://') !== 0 && committeeDetails.BylawUrl.toLowerCase().indexOf('https://') !== 0)) {
        committeeDetails.BylawUrlLink = 'http://' + committeeDetails.BylawUrl;
    }
    if (committeeDetails.BylawRevisionDate) {
        committeeDetails.BylawRevisionDate = formatDateToMMDDYYYY(committeeDetails.BylawRevisionDate);
    }

    committeeDetails.LastModifiedDate = '';
    if (committeeDetails.ModifiedDate) {
        committeeDetails.LastModifiedDate = utcDateTimeToLocalDateTime(committeeDetails.ModifiedDate);
    }

    committeeDetails.OverviewTemplate = committeeDetails.Overview;

    let yearFormed = formatDateToYYYY(committeeDetails.EstablishmentDate);

    // Replace the values in the overview template
    if (committeeDetails.Overview) {
        committeeDetails.Overview = committeeDetails.Overview.replace(new RegExp('@COMMITTEE DESIGNATION', 'g'), committeeDetails.CommitteeDesignation)
            .replace(new RegExp('@TITLE', 'g'), committeeDetails.CommitteeTitle)
            .replace(new RegExp('@YEAR FORMED', 'g'), yearFormed)
            .replace(new RegExp('@NUMBER OF TIMES COMMITTEE MEETS', 'g'), committeeDetails.NumberOfTimesCommitteeMeets)
            .replace(new RegExp('@MEETING MONTHS', 'g'), committeeDetails.MeetingMonths)
            .replace(new RegExp('@NUMBER OF ATTENDEES', 'g'), committeeDetails.NumberOfAttendees)
            .replace(new RegExp('@NUMBER OF MEETING DAYS', 'g'), committeeDetails.NumberOfMeetingDays)
            .replace(new RegExp('@NUMBER OF MEMBERS', 'g'), committeeDetails.NumberOfMembers)
            .replace(new RegExp('@NUMBER OF STANDARDS', 'g'), getNumberOfStandards(committeeDetails))
            .replace(new RegExp('@VOLUME NUMBER', 'g'), committeeDetails.VolumeNumber)
            .replace(new RegExp('@NUMBER OF COMMITTEES AT NEXT LEVEL', 'g'), committeeDetails.NumberOfCommitteesAtNextLevel)
    }

    // Update the ActiveCommitteeClassifications according to balance rule position left or right
    if (committeeDetails.ActiveCommitteeClassifications) {
        committeeDetails.ActiveCommitteeClassifications.map((item) => {
            item.isLeft = false;
            item.isRight = false;

            if (committeeDetails.CommitteeBalanceRule && committeeDetails.CommitteeBalanceRule.ClassificationTypeLeft && committeeDetails.CommitteeBalanceRule.ClassificationTypeRight) {
                if (committeeDetails.CommitteeBalanceRule.ClassificationTypeLeft.indexOf(item.Id) > -1) {
                    item.isLeft = true;
                } else if (committeeDetails.CommitteeBalanceRule.ClassificationTypeRight.indexOf(item.Id) > -1) {
                    item.isRight = true;
                }
            }
        })
    }

    return committeeDetails;
}

const getNumberOfStandards = (committeeDetails) => {
    let numberOfStandards = 0;
    if (committeeDetails.ClassificationStatisticalInfo &&
        committeeDetails.ClassificationStatisticalInfo.ClassificationData &&
        committeeDetails.ClassificationStatisticalInfo.ClassificationData.length > 0) {
        const standardCountItem = committeeDetails.ClassificationStatisticalInfo.ClassificationData.find(item => item.Name.toLowerCase() === 'total standards');
        if (standardCountItem) {
            numberOfStandards = standardCountItem.Count ? standardCountItem.Count : 0;
        }
    }

    return numberOfStandards;
}

const formatMeetingTypeList = (list, activeRecordsOnly) => {
    let result = [];
    result.push({ key: 0, text: 'Select', value: 0 });

    if (list && list.length > 0) {
        if (activeRecordsOnly) {
            list.map((item) => {
                if (item.IsActive) {
                    result.push({ key: item.MeetingTypeId, text: item.Name, value: item.MeetingTypeId });
                }
            })
        } else {
            list.map((item) => {
                result.push({ key: item.MeetingTypeId, text: item.Name, value: item.MeetingTypeId });
            })
        }
    }

    return result;
}
const formatMembershipTypeList = (data) => {
    let result = [];
    result = data.map(item => {
        return { key: item.MembershipTypeId, value: item.MembershipTypeId, text: item.MembershipTypeName }
    })

    result.unshift({ key: 0, value: 0, text: 'All' });

    return result;
}
const formatClassificationList = (data) => {
    let result = [];

    result = data.map(item => {
        return { key: item.ClassificationTypeId, value: item.ClassificationTypeId, text: item.Name }
    })

    result.unshift({ key: 0, value: 0, text: 'All' });

    return result;
}
const formatOfficerTitleList = (data) => {
    let result = [];

    result = data.map(item => {
        return { key: item.OfficerTitleId, value: item.OfficerTitleId, text: item.OfficerTitleName }
    })

    result.unshift({ key: 0, value: 0, text: 'All' });

    return result;
}
const formatNoVoteReasonList = (data) => {
    let result = [];

    result = data.map(item => {
        return { key: item.VoteId, value: item.VoteId, text: item.Description }
    })

    result.unshift({ key: 0, value: 0, text: 'All' });

    return result;
}
const formatInActiveReasonList = (list) => {
    let result = [];
    result.push({ key: 0, text: 'Select', value: 0 });

    if (list && list.length > 0) {

        list.map((item) => {
            result.push({ key: item.InactiveReasonId, text: item.Name, value: item.InactiveReasonId });
        })

    }

    return result;
}

const formatOperatorList = (data) => {
    let result = [];

    result = data.map(item => {
        return { key: item.OperatorId, value: item.OperatorSymbol, text: item.OperatorName }
    })

    result.unshift({ key: 0, value: 0, text: 'Select' });

    return result;
}

const committeeDetailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.GET_COMMITTEE_DETAILS:
            return {
                ...state,
                initialValues: formatCommitteeDetails(action.committeeDetails)
            }
        case types.RESET_COMMITTEE_DETAILS_DATA:
            return {
                ...state,
                initialValues: {}
            }
        case types.GET_COMMITTEE_DETAILS_MASTER_DATA:
            return {
                ...state,
                allMeetingTypeList: formatMeetingTypeList(action.meetingTypeList),
                activeMeetingTypeList: formatMeetingTypeList(action.meetingTypeList, true),
                operatorList: formatOperatorList(action.operatorList),
                applicationPrivilege: action.applicationPrivilege,
                committeeAuditLogFieldList: formatAuditLogFieldList(action.committeeAuditLogFieldList)
            }
        case types.GET_COMMITTEE_USER_LIST:
            return {
                ...state,
                committeeUserList: action.committeeUserList
            }
        case types.GET_MEMBERSHIPTYPE_LIST:
            return {
                ...state,
                membershipTypeList: formatMembershipTypeList(action.membershipTypeList)
            };
        case types.GET_COMMITTEE_CLASSIFICATION:
            return {
                ...state,
                classificationList: formatClassificationList(action.classificationList)
            }
        case types.GET_COMMITTEE_INLINE_MASTER_DATA:
            return {
                ...state,
                classificationList: formatClassificationList(action.data.classificationList),
                noVoteReasonList: formatNoVoteReasonList(action.data.noVoteReasonList),
                officerTitleList: formatOfficerTitleList(action.data.officerTitleList)
            }
        case types.GET_COMMITTEE_OFFICERTITLE:
            return {
                ...state,
                officerTitleList: formatOfficerTitleList(action.data.officerTitleList)
            }
        case types.GET_MASTER_DATA_FOR_INACTIVE_COMMITTEE:
            return {
                ...state,
                inActiveReasons: formatInActiveReasonList(action.masterData.inactiveResons),
                dependentCommittee: action.masterData.dependentCommittee || [],
                mergeCommittees: action.masterData.mergeCommittee || [],
                activeMemberCount: action.masterData.activeMemberCount

            }
        default:
            return state;
    }
}

export default committeeDetailsReducer;
