import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import "./invoice.css";
import { memberFeeNumberFormat, decrypt } from "../../../../../helpers/util-common";
import { getMemberRNETemplateAction } from "../../../actions/membership-report.action";

const GenerateInvoice = ({ match }) => {

  const INVOICE = "invoice";

  const [invoiceDetails, setInvoiceDetails] = useState({});

  const [templateUrl, setTemplateUrl] = useState();

  const { type, stock } = match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const encryptedData = queryParams.get('data');

    if (encryptedData) {
      const decryptedData = decrypt(decodeURIComponent(encryptedData));
      const parsedData = JSON.parse(decryptedData);
      setInvoiceDetails(parsedData);
    }

    if (!stock) {
      let template =
        type == INVOICE
          ? "TWVtYmVyc2hpcCBSZW5ld2FsIEludm9pY2U="
          : "TWVtYmVyc2hpcCBSZWNlaXB0";
      getMemberRNETemplateAction(template, dispatch, (templateUrlResult) => {
        if (templateUrlResult) {
          setTemplateUrl(templateUrlResult);
        }
      });
    }

    let myDiv = document.getElementById("root");
    myDiv.style.overflow = "visible";
  }, []);

  return (
    <div id="membership-container">
      <div id="membership-address">
        <span id="membership-address-span">
          {get(invoiceDetails, "fullAddress") &&
            get(invoiceDetails, "fullAddress")
              .split("\n")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
        </span>
        <span id="membership-address-span">
          {get(invoiceDetails, "poNumber")
            ? get(invoiceDetails, "poNumber")
            : ""}
        </span>
      </div>
      <div
        id={
          type == INVOICE
            ? "membership-left-section"
            : "membership-left-section-receipt"
        }
      >
        <div>
          {get(invoiceDetails, "accountNumber")
            ? get(invoiceDetails, "accountNumber")
            : ""}
        </div>
        <div>
          {get(invoiceDetails, "membershipFee")
            ? memberFeeNumberFormat(get(invoiceDetails, "membershipFee"))
            : 0}
        </div>
        <div>
          {get(invoiceDetails, "membershipType")
            ? get(invoiceDetails, "membershipType")
            : ""}
        </div>
      </div>
      <div id="membership-card">
        <div className="membership-card-item-member-name">
          {get(invoiceDetails, "fullName")
            ? get(invoiceDetails, "fullName")
            : ""}
          <div className="org-name-space"></div>
          {get(invoiceDetails, "orgName")
            ? get(invoiceDetails, "orgName")
            : ""}
        </div>
        <div id={type != INVOICE ? "membership-card-items": "membership-card-items-invoice"}>
          <span>
            {get(invoiceDetails, "accountNumber")
              ? get(invoiceDetails, "accountNumber")
              : ""}
          </span>
          <span
            id={
              type == INVOICE
                ? "membership-card-item-member-type"
                : "membership-card-item-member-type-receipt"
            }
          >
            {get(invoiceDetails, "membershipType")
              ? get(invoiceDetails, "membershipType")
              : ""}
          </span>
        </div>
      </div>
      <img id="membership-img" src={templateUrl} />
    </div>
  );
};

export default withRouter(GenerateInvoice);