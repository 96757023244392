import * as utilCommon from 'helpers/util-common';
import { CountryCodes } from 'models/common.models';
import { AlphaNumeric_Regex } from '../../../../../helpers/util-common';

let self = {};
export const setClassInstance = (instance) => {
    self = instance;
    self.setState({ countryList: utilCommon.formatCountryList(self.props.activeCountryList, true) })
}

export const validateHandler = (values, props) => {
    const errors = {};

    if (!values.FeeGroupTitle || !(values.FeeGroupTitle || '').trim()) {
        props.resetFeeGroup();
        errors.FeeGroupTitle = utilCommon.getMessage(props.messageCodes, '8147.text');
    }
    if (values.ContactEmailAddress && !utilCommon.validateEmail(values.ContactEmailAddress)) {
        errors.ContactEmailAddress = utilCommon.getMessage(props.messageCodes, '3128.text');
    }
    if (values.Country && values.Country.toLowerCase() === CountryCodes.UNITEDSTATES.Name.toLowerCase() && values.ContactPhoneNumber && values.ContactPhoneNumber.length < 10) {
        errors.ContactPhoneNumber = utilCommon.getMessage(props.messageCodes, '4023.text').replace('<number>', '10');
    }
    if (values.Address2 && (!AlphaNumeric_Regex.test(values.Address2) || values.Address2.length > 10)) {
        errors.Address2 = utilCommon.getMessage(props.messageCodes, '4001.text');
    }
    if (!values.Country || values.Country === '-1' || values.Country == 0) {
        errors.Country = utilCommon.getMessage(props.messageCodes, '8167.text');
    }

    return errors;
}

export const addFeeGroup = (value) => {
    const { stateList } = self.state;
    const { activeCountryList } = self.props

    let reqData = {
        FeeGroupId: 0,
        FeeGroupTitle: value.FeeGroupTitle,
        Organization: value.Organization,
        Address1: value.Address1 || '',
        Address2: value.Address2 || '',
        Country: (activeCountryList && activeCountryList.length > 0 && activeCountryList.filter(item => item.value.toLowerCase() === (value.Country || '').toLowerCase()).length > 0) ? (value.Country || '') : value.Country || CountryCodes.UNITEDSTATES.Name,
        State: (stateList && stateList.length > 0 && stateList.filter(item => item.value.toLowerCase() === (value.State || '').toLowerCase()).length > 0) ? (value.State || '') : value.State || '',
        City: value.City || '',
        PostalCode: value.PostalCode || '',
        IsAutoRenewal: value.IsAutoRenewal || false,
        IsSuppressFeeRenewalEmail: value.IsSuppressFeeRenewalEmail || false,
        IsSuppressFeeRenewalPrint: value.IsSuppressFeeRenewalPrint || false,
        ContactPersonName: value.ContactPersonName,
        ContactEmailAddress: value.ContactEmailAddress,
        ContactPhoneNumber: value.ContactPhoneNumber,
        ContactExtension: value.ContactExtension,
        Status: 'A'
    }
    getFeeGroupTitleExist(reqData.FeeGroupTitle, () => {
        self.props.postFeeGroup(reqData, (response) => {
            if (response) {
                self.props.close(true);
            }
        })
    });
}

export const getFeeGroupTitleExist = (val, callback) => {
    if (val) {
        self.setState({ disabledSubmit: true });
        let reqData = { feeGroupId: 0, feeGroupTitle: val || '' };
        self.props.getFeeGroupTitleExist(reqData, () => {
            self.setState({ disabledSubmit: false }, () => {
                !self.props.isDuplicate && callback();
            })
        })
    } else {
        self.setState({ disabledSubmit: false })
    }
}
