import React from "react";
import { Grid, Icon } from 'semantic-ui-react';
import CheckboxElement from 'shared-components/CheckBox';
import CustomTooltip from 'shared-components/Tooltip';
import { ActiveMemberStatusId, MembershipTypes } from '../../members.model';
import { routeNavigationUrl, encrypt } from 'helpers/util-common';

const MemberViewGeneralInfo = ({ initialValues, onEditGenInfo, hasUpdateAccess, memberMasterDetails, hasFeeGroupViewAccess, PAGE_TYPE, USER_PRIVILEGE }) =>

(
    <div data-testid="memberViewGeneralInfo">
        <h5>ASTM General Information
            {initialValues.McsStatusMasterId === ActiveMemberStatusId && hasUpdateAccess && (
                <a title="Edit" className="editBtn" onClick={onEditGenInfo}><Icon name="pencil" /></a>
            )}
        </h5>
        <Grid divided='vertically' columns={4}>
            <Grid.Row>
                <Grid.Column>
                    <span className="labelTitle">Fee Group</span>
                    {!hasFeeGroupViewAccess && <span className="labelValue">{initialValues.FeeGroupName}</span>}
                    {hasFeeGroupViewAccess &&
                        <a className="linkTxt" href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW, [encrypt(0), encrypt(initialValues.FeeGroupId)])}`} target="_blank">{initialValues.FeeGroupName}</a>
                    }
                </Grid.Column>
                <Grid.Column>
                    <span className="labelTitle">Government Type</span>
                    <span className="labelValue">
                        {initialValues.GovernmentType}
                    </span>
                </Grid.Column>
                <Grid.Column>
                    <span className="labelTitle">Government Duns Account</span>
                    <span className="labelValue">
                        {initialValues.GovtDunsAct}
                    </span>
                </Grid.Column>

                <Grid.Column>
                    <span className="labelTitle">Free Member Volume</span>
                    {initialValues && (initialValues.MemberTypeId == MembershipTypes.MemberTech || initialValues.MemberTypeId == MembershipTypes.Representative || initialValues.MemberTypeId == MembershipTypes.cooperativeAgreementRep) && (
                        < span className="labelValue">{initialValues && initialValues.FreeMemberVolume ? initialValues.FreeMemberVolume : ''}</span>
                    )}
                </Grid.Column>

            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <CheckboxElement IsEditable={false} IsChecked={initialValues.IsProfileMember} setLabeltitleProp='Profile Member' />
                    <CustomTooltip content='Profiled Member will receive hard copy of Ballots and Meeting Schedules, if checked.' icon={<Icon name="info circle" className="ml5 skyBlue vTextTop pointer" />} />

                </Grid.Column>
                <Grid.Column>
                    <CheckboxElement IsEditable={false} IsChecked={initialValues.IsAutoRenewal} setLabeltitleProp='Auto Renewal' />
                </Grid.Column>
                <Grid.Column>
                    <CheckboxElement IsEditable={false} IsChecked={initialValues.IsSupressFeeRenewalEmail} setLabeltitleProp='Suppress Fee Renewal Emails' />
                </Grid.Column>
                <Grid.Column>
                    <CheckboxElement IsEditable={false} IsChecked={initialValues.IsSupressFeeRenewalPrint} setLabeltitleProp='Suppress Fee Renewal Print' />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div >
)


export default MemberViewGeneralInfo;
