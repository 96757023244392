import { allowOnlyPhoneNumberInput } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Grid } from 'semantic-ui-react';
import RenderInputField from 'shared-components/Input';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import AutoSearch from 'shared-components/Search';
import RenderSelect from 'shared-components/Select';
import { MemberStatusOptions } from '../../members.model';
import * as _func from './function';

class AdvanceSearchMember extends Component {
    membershipTypeList = []

    state = {
        isResetClicked: false,
        firstNameList: [],
        isShowFirstNameErrorMsg: false,
        isShowLastNameErrorMsg: false,
        isShowOrgErrorMsg: false,
        isStateListDisabled: false,
        lastNameList: [],
        organizationNameList: [],
        stateList: []
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.loadInitialData();
        this.membershipTypeList = _func.filterMembershipTypes();
    }

    render() {
        const { handleSubmit, countryList } = this.props;
        const { firstNameList, lastNameList, organizationNameList, committeeDesignationNameList, isShowFirstNameErrorMsg, isShowLastNameErrorMsg, isShowOrgErrorMsg, isLoadingFirstList, isLoadingLastList, isLoadingOrgList, stateList, isStateListDisabled } = this.state;

        return (
            <Form onSubmit={handleSubmit} noValidate data-testid="advanceSearchMember">
                <Grid columns='equal' className="mt0">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label>First Name</label>
                            <Field name="FirstName" type="text"
                                component={AutoSearch}
                                placeholder="First Name"
                                maxLength="150"
                                showNoResults={isShowFirstNameErrorMsg}
                                onChange={(e, val) => _func.getFirstName(val)}
                                source={firstNameList}
                                isLoading={isLoadingFirstList}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <label>Last Name</label>
                            <Field name="LastName" type="text"
                                component={AutoSearch}
                                maxLength="150"
                                placeholder="Last Name"
                                showNoResults={isShowLastNameErrorMsg}
                                onChange={(e, val) => _func.getLastName(val)}
                                source={lastNameList}
                                isLoading={isLoadingLastList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb0">
                        <Grid.Column>
                            <Field name="AccountNumber" type="text"
                                component={RenderInputField} label="Account Number"
                                placeholder="Account Number"
                                maxLength="10"
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Field name="MemberType"
                                component={RenderSelect} label="Member Type"
                                isSearch={true}
                                isMultiple={false}
                                placeholder="All"
                                options={this.membershipTypeList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label>Organization Name</label>
                            <Field name="CompanyName" type="text"
                                component={AutoSearch} label="Organization Name"
                                placeholder="Organization Name"
                                maxLength="150"
                                showNoResults={isShowOrgErrorMsg}
                                onChange={(e, val) => _func.getOrganizationName(val)}
                                source={organizationNameList}
                                isLoading={isLoadingOrgList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name='CommitteeDesignation'
                                component={DropdownMultipleSelection}
                                isOnFocus={true}
                                label='Committee Designation'
                                placeholder="Committee Designation"
                                options={_func.formatCommitteeDesignationList(committeeDesignationNameList)}
                                isMultiple={true}
                                isSearch={true}
                                open={false}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="AccountStatus"
                                component={RenderSelect} label="Status"
                                placeholder="All"
                                options={MemberStatusOptions}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="Email" type="text"
                                component={RenderInputField} label="Email"
                                placeholder="Email"
                                maxLength="150" />
                        </Grid.Column>
                        <Grid.Column>
                            <Field name="PhoneNumber" type="text"
                                component={RenderInputField} label="Phone Number"
                                placeholder="Phone Number"
                                maxLength="150"
                                onChange={allowOnlyPhoneNumberInput} />
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="Country"
                                component={RenderSelect} label="Country"
                                placeholder="All"
                                options={countryList}
                                onChange={_func.onCountryChange}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Field name="State" disabled={isStateListDisabled}
                                component={RenderSelect} label="State/Province"
                                placeholder="All"
                                options={stateList}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="PostalCode" type="text"
                                component={RenderInputField} label="Postal Code"
                                placeholder="Postal Code"
                                maxLength="150" />
                        </Grid.Column>
                        <Grid.Column>
                            <Field name="City" type="text"
                                component={RenderInputField} label="City"
                                placeholder="City"
                                maxLength="150" />
                        </Grid.Column>

                    </Grid.Row>


                    <Grid.Row className="mb0">
                        <Grid.Column className="actions">
                            <Button primary type="submit">Search</Button>
                            <Button className="cancel ml10" type="reset" onClick={_func.resetForm}>Reset</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

        );
    }
}

export default (reduxForm({
    form: 'AdvanceSearchMember',
    onSubmit: _func.onSubmitHandler
})(AdvanceSearchMember));
