import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { getHighlightedHTMLElement } from 'helpers/util-common';

let searchValue = '';

export default class CustomGroupRendererSearch extends Component {



    resultRenderer = (value) => {
        let highlightedMemberAccountNumber = getHighlightedHTMLElement(value.AccountNumber, searchValue);
        let highlightedMemberName = getHighlightedHTMLElement(value.Name && `${`&bull;${value.Name}` || ''}`, searchValue);
        let highlightedOrganization = getHighlightedHTMLElement(value.Organization && `${`&bull; ${value.Organization}` || ''}`, searchValue);
        let renderElement = '';

        renderElement = `<p>${highlightedMemberAccountNumber}  ${highlightedMemberName}${highlightedOrganization}</p>`;
        return <div dangerouslySetInnerHTML={{ __html: renderElement }}></div>
    }

    handleResultSelect = (e, { result }) => {
        this.props.onResultSelect(result);
    }

    handleSearchChange = (e) => {
        searchValue = e.target.value.trim();
        this.props.input.onChange(e.target.value);
    }

    render() {

        const { label, required, input: { name, value }, placeholder, className, isLoading, results, showNoResults, meta: { touched, error } } = this.props;

        return (
            <div>
                <label className="label" htmlFor={name}>{required && (<i aria-hidden="true" class="asterisk  icon"></i>)}{label}</label>
                <Search
                    name={name}
                    id={name}
                    value={value}
                    placeholder={placeholder}
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                    resultRenderer={this.resultRenderer}
                    results={results}
                    showNoResults={showNoResults}
                    className={className}
                />

                {touched && (error && <span className="errorMessage mt10">{error}</span>)}
            </div>
        )

    }
}