import { encrypt, routeNavigationUrl } from 'helpers/util-common';
import { ItemsPerPage } from 'models/common.models';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Loader } from "semantic-ui-react";
import PaginationShorthand from 'shared-components/Pager';
import RenderSelect from 'shared-components/Select';
import TableHeader from 'shared-components/Table/tableHeader';
import { SubCommitteeGridHeaderInfo } from '../../committee.model';
import * as _func from './function';

class ViewSubCommitteesInfo extends Component {

    state = {
        subCommitteesCountInfo: [],
        subCommitteesList: [],
        totalSubCommittees: 0,
        showSubCommitteesList: false,
        pageNumber: 1,
        pageSize: 25,
        currentlyShowingItems: '',
        sortKey: '',
        sortOrder: '',
        showLoader: false
    }

    componentWillMount() {
        _func.setInstance(this);
    }

    shouldComponentUpdate(nextProps) {
        // Refresh the data when navigating to other committee
        if ((this.props.initialValues.CommitteeId !== nextProps.initialValues.CommitteeId) || (
            this.props.initialValues.CommitteeId === nextProps.initialValues.CommitteeId && this.props.initialValues.ModifiedDate !== nextProps.initialValues.ModifiedDate
        )) {
            _func.refreshData(nextProps.initialValues);
            this.setState({ showSubCommitteesList: false });
        }
        return true;
    }

    render() {

        const { subCommitteesCountInfo, subCommitteesList, showSubCommitteesList, pageNumber, pageSize, currentlyShowingItems, showLoader, totalSubCommittees } = this.state;
        const { PAGE_TYPE, USER_PRIVILEGE } = this.props;
        return (
            <div className="subCommitteessTable">
                {subCommitteesCountInfo.length > 0 && (
                    <div className="totalRowWrap">
                        <div className={showSubCommitteesList ? 'totalRow active' : 'totalRow'}>
                            {subCommitteesCountInfo.map((item) => (
                                <span><em>{item.LevelName}:</em> {item.Count}</span>
                            ))}

                            {subCommitteesList.length > 0 && (
                                <Icon name="caret right" data-testid="toggleIcon" className={showSubCommitteesList ? 'toggleIcon active' : 'toggleIcon'} onClick={_func.toggleSubCommitteesList} />
                            )}
                        </div>
                        {showLoader && (
                            <Loader size='small' className="small-loader">Loading</Loader>
                        )}
                        {showSubCommitteesList && (
                            <div style={{ 'padding-bottom': '20px' }}>
                                <div className="tableWrapper">
                                    <table className="customTable">
                                        <thead>
                                            <tr>
                                                <TableHeader headerProps={SubCommitteeGridHeaderInfo} onClickProps={_func.sortByHandler}></TableHeader>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subCommitteesList.map((item) => (
                                                <tr>
                                                    <td>
                                                        <Link to={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.COMMITTEE), USER_PRIVILEGE.VIEW, [encrypt(item.CommitteeId)])}`}>
                                                            {item.Designation}
                                                        </Link>
                                                    </td>
                                                    <td>{item.Title}</td>
                                                    <td>{item.CommitteeMemberCount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {totalSubCommittees > 0 && (
                                        <section className="pagerWrap">
                                            <div className="pager">
                                                <PaginationShorthand defaultActivePage={pageNumber}
                                                    totalPages={Math.ceil(totalSubCommittees / pageSize)}
                                                    onPageChange={_func.onPageChangeHandler} />
                                            </div>
                                            <div className="itemPerPage">
                                                <RenderSelect onChange={_func.onChangeNumberOfItemsPerPage}
                                                    value={pageSize} options={ItemsPerPage} />
                                                <span className="itemsPerPage">items per page</span>
                                            </div>
                                            <div className="totalPage">{currentlyShowingItems}</div>
                                        </section>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default ViewSubCommitteesInfo;
