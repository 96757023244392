import * as types from 'action-types';
import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import * as utilCommon from 'helpers/util-common';

const getInitialMemberListDataAction = (query, callback, dispatch, isLoader) => {
    let getMemberListUrl = getApiUrl('manageMembers', 'getMemberList');
    let getMembershipTypeListUrl = getApiUrl('manageMembers', 'getMembershipTypeList');
    let getMemberTableConfiguration = getApiUrl('userPermission', 'getGridUserPreferences') + '/1';
    if (query.length > 0) {
        getMemberListUrl = `${getMemberListUrl}/${query}`;
        utilCommon.cacheManager.setItem('exportedParams', query);
    }
    if (isLoader) {
        dispatch(commonActions.setLoader(true));
    }
    Promise.all([axios.get(getMemberListUrl), axios.get(getMembershipTypeListUrl), axios.get(getMemberTableConfiguration)])
        .then(([getMemberListResponse, getMembershipTypeListResponse, getMemberTableConfigurationResponse]) => {
            if (isLoader) {
                dispatch(commonActions.setLoader(false));
            }
            if (getMembershipTypeListResponse.data.status) {
                // TODO : MembershipTypeList set in cache due to heavy call.
                utilCommon.cacheManager.setItem('membershipTypeList', getMembershipTypeListResponse.data.content)
                dispatch({ type: types.GET_MEMBERSHIPTYPE_LIST, membershipTypeList: getMembershipTypeListResponse.data.content });
            }
            if (getMemberListResponse.data.status || getMemberTableConfigurationResponse.data.status) {
                callback(getMemberListResponse.data.content, getMemberTableConfigurationResponse.data.content);
                dispatch({ type: types.GET_MEMBER_GRID_PREFERENCES, memberGridPreferences: getMemberTableConfigurationResponse.data.content });
            }

            if (!getMemberListResponse.data.status) {
                dispatch(commonActions.setMessage(true, getMemberListResponse.data.message));
            }
            if (!getMemberTableConfigurationResponse.data.status) {
                dispatch(commonActions.setMessage(true, getMemberTableConfigurationResponse.data.message));
            }
            if (!getMembershipTypeListResponse.data.status) {
                dispatch(commonActions.setMessage(true, getMembershipTypeListResponse.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3039'));
        });
}

const getMemberListAction = (query, callback, dispatch, isLoader) => {
    let url = getApiUrl('manageMembers', 'getMemberList');
    if (isLoader) {
        dispatch(commonActions.setLoader(true));
    }
    if (query.length > 0) {
        url = `${url}/${query}`;
        utilCommon.cacheManager.setItem('exportedParams', query);
    }
    axios.get(url)
        .then((response) => {
            if (isLoader) {
                dispatch(commonActions.setLoader(false));
            }
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback();
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3039'));
        });
}

const getMemberFirstNameListAction = (query, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberFirstNameList');

    if (query.length > 0) {
        url = `${url}/${query}`;
    }
    else { return false; }

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback();
            }
        })
        .catch((err) => {
            callback();
        });
}

const getMemberLastNameListAction = (query, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberLastNameList');
    if (query.length > 0) {
        url = `${url}/${query}`;
    }
    else { return false; }

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback();
            }
        })
        .catch((err) => {
            callback();
        });
}

const getMemberOrganizationNameListAction = (value, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberOrganizationNameList');

    if (value && value.length > 0) {
        url = `${url}/${utilCommon.encrypt(value)}`;
    }
    else { return false; }

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                callback();
            }
        })
        .catch((err) => {
            callback();
        });
}

const getCommitteeDesignationNameListAction = (callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberCommitteeDesignationNameList');
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
                callback();
            }
        })
        // eslint-disable-next-line no-empty-function
        .catch((err) => {
        });
}

const getMembershipTypeListAction = (dispatch) => {
    let itemsList = utilCommon.cacheManager.getItem('membershipTypeList');
    if (itemsList) {
        dispatch({ type: types.GET_MEMBERSHIPTYPE_LIST, membershipTypeList: JSON.parse(itemsList) });
    } else {
        let url = getApiUrl('manageMembers', 'getMembershipTypeList');
        axios.get(url)
            .then((response) => {
                if (response.data.status) {
                    dispatch({ type: types.GET_MEMBERSHIPTYPE_LIST, membershipTypeList: response.data.content });
                } else {
                    // Handle error case
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            })
            .catch((err) => {
                dispatch(commonActions.setMessage(true, '7025'));
            });
    }
}


const getMemberExportedDataAction = (dispatch, callback, query, postData, isWord) => {
    let url = getApiUrl('manageMembers', 'memberExportFile');
    url = `${url}/${query}`;
    axios.post(url, { excelColumns: postData })
        .then((response) => {
            if (response.data.status) {
                if (isWord) {
                    callback(response.data.content);
                } else {
                    callback(response.data.content.Members);
                }
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3047'));
        });
}

export default {
    getInitialMemberListDataAction,
    getMemberListAction,
    getMemberFirstNameListAction,
    getMemberLastNameListAction,
    getMemberOrganizationNameListAction,
    getCommitteeDesignationNameListAction,
    getMembershipTypeListAction,
    getMemberExportedDataAction
}
