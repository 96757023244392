import React, { Component } from "react";
import { Button, Loader, Confirm } from 'semantic-ui-react';
import * as common from 'helpers/util-common';
import * as _func from './function';
import { feeGroupExportFormat } from '../../feegroups.model';
import FeeGroupBasicDetails from '../fee-group-basic-details'
import FeeGroupContactDetails from '../fee-group-contact-details'
import FeeGroupStatusDetails from '../fee-group-status-details'
import FeeGroupBasicDetailsEdit from '../fee-group-basic-details-edit'
import FeeGroupStatusEdit from '../fee-group-basic-status-edit'

class FeeGroupPersonalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowFeeDtail: true,
            isShowFeeStatus: true,
            showLoader: false,
            isWordEmpty: false,
            exportFormat: ''
        }
        this.loadScript();
    }

    loadScript = () => {
        common.loadScriptTag(['/docx/docx.js', '/docx/file-save.js', '/docx/docx-service.js'], 'head');
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }

    DetailEditFormHandler = (isPageReload = true) => {
        let isShowFeeDtail = !this.state.isShowFeeDtail;
        this.props.resetFeeGroup();
        if (isShowFeeDtail) {
            if (isPageReload) { this.props.refreshPage(); }
        }
        this.setState({ isShowFeeDtail })
    }

    feeGoupStatusEditHandler = (isPageReload = true) => {
        let isShowFeeStatus = !this.state.isShowFeeStatus;
        if (isShowFeeStatus) {
            if (isPageReload) {
                this.props.bannerRefresh();
                this.props.refreshPage();
            }
        }
        this.setState({ isShowFeeStatus })
    }

    render() {
        const { isShowFeeDtail, isShowFeeStatus, showLoader, isWordEmpty } = this.state;
        const { messageCodes } = this.props;
        const { DetailEditFormHandler, feeGoupStatusEditHandler } = this;

        return (
            <div data-testid="personalDetailsTab">
                {showLoader && (
                    <Loader size='small' data-testid="personalFGLoader" className="small-loader">Loading</Loader>
                )}
                <div className='text-align-right clearfix'>
                    <Button data-testid="downloadTemplate" className="ui button secondary" onClick={() => { _func.feeGroupDetailsExport(feeGroupExportFormat.template); }}>Download Template</Button>
                    <Button data-testid="downloadInvoice" className="ui button secondary" onClick={() => { _func.feeGroupDetailsExport(feeGroupExportFormat.invoice); }}>Download Invoice</Button>
                </div>

                {isShowFeeDtail && <FeeGroupBasicDetails {...this.props} editFormHandler={DetailEditFormHandler} />}
                {isShowFeeDtail && <FeeGroupContactDetails {...this.props} />}
                {!isShowFeeDtail && < FeeGroupBasicDetailsEdit {...this.props} editFormHandler={DetailEditFormHandler} />}
                {isShowFeeStatus && <FeeGroupStatusDetails {...this.props} editFormHandler={feeGoupStatusEditHandler} />}
                {!isShowFeeStatus && <FeeGroupStatusEdit {...this.props} editFormHandler={feeGoupStatusEditHandler} />}

                <Confirm className="confirm-box"
                    open={isWordEmpty}
                    content={common.getMessage(messageCodes, '9014.text')}
                    onCancel={_func.emptyWordCancelHandler}
                    onConfirm={_func.emptyWordConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        );
    }
}
export default FeeGroupPersonalDetails;
