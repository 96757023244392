import { dateMasking, formatDateToMMDDYYYY } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Icon } from 'semantic-ui-react';
import DateTimeForm from 'shared-components/DatePicker';
import RenderInputField from 'shared-components/Input';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import EditorFieldComponent from 'shared-components/TextEditor/EditorFieldComponent';
import { CommitteeClassificationOptionsWithSelect } from '../../committee.model';
import * as _func from './function';

class EditCommitteeGenInfo extends Component {

    maxDate = formatDateToMMDDYYYY();

    componentWillMount() {
        _func.setInstance(this);
    }

    render() {
        const { submitting, onCloseEditGenInfo, initialValues, committeeTypeName, selectedCommitteeLevelEligibilitiesDetails, overviewTagFieldList, handleSubmit } = this.props;

        return (
            <Form noValidate onSubmit={handleSubmit(_func.onSubmitHandler)} data-testid="editCommitteeGenInfo">
                <h5>General Information
                    <ul className="editDetailsBtn floatRight">
                        <li>
                            <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                <Icon name="check" />
                            </button>
                        </li>
                        <li>
                            <button title="Cancel" className="closeBtn" onClick={onCloseEditGenInfo}>
                                <Icon name="close" />
                            </button>
                        </li>
                    </ul>
                </h5>
                <div>
                    <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Committee Designation</span>
                                <span className="labelValue">{initialValues.CommitteeDesignation}</span>
                            </Grid.Column>
                            <Grid.Column style={{ 'width': '50%' }}>
                                <Field name="CommitteeTitle" type="text"
                                    dataTestId="CommitteeTitle"
                                    component={RenderInputField} label="Title"
                                    required='true'
                                    maxLength="100" />
                            </Grid.Column>
                            <Grid.Column>
                                <Field name="EstablishmentDate"
                                    component={DateTimeForm} label="Date Formed"
                                    showTime={false}
                                    required='true'
                                    normalize={dateMasking}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <span className="labelTitle">Committee Type</span>
                                <span className="labelValue">{committeeTypeName}</span>
                            </Grid.Column>
                            {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showClassification && (
                                <Grid.Column>
                                    <div style={{ 'width': '80%' }}>
                                        <Field name="Classification"
                                            component={RenderSelect} label="Classification"
                                            isMultiple={false}
                                            placeholder="Select"
                                            required={true}
                                            options={CommitteeClassificationOptionsWithSelect}
                                        />
                                    </div>
                                </Grid.Column>
                            )}
                            <Grid.Column>
                                <label className="labelTitle">Status</label>
                                <span className="labelValue">{initialValues.StatusName}</span>
                            </Grid.Column>
                            <Grid.Column>
                                <span className="labelTitle">Balance Rule Applicable</span>
                                <span className="labelValue">{initialValues.IsBalanceRequired}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid className="mt0">
                        {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showOverview && (
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="overviewEditor">
                                        <Field name="OverviewTemplate" type="text" id="Overview"
                                            label="Overview" suggestionList={overviewTagFieldList}
                                            placeholder="Please mention @ to add available fields."
                                            component={EditorFieldComponent} />
                                    </div>
                                    <div className="availableFields">
                                        <span className="headTitle">Available Fields</span>
                                        <ul>
                                            {overviewTagFieldList && overviewTagFieldList.map(item => (<li>{item.text}</li>))}
                                        </ul>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showScope && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="Scope" type="text" id="CommitteeScope"
                                        label="Committee Scope"
                                        component={EditorFieldComponent} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                    <div id='forScrollPurpose'></div>
                    {selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws && (
                        <Grid className="mt0" divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="BylawUrl" type="text"
                                        component={RenderInputField} label="Bylaws"
                                        placeholder='Please enter URL'
                                        maxLength="100" />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name="BylawRevisionDate"
                                        component={DateTimeForm} label="Last Bylaws Revision Date"
                                        showTime={false}
                                        maxDate={this.maxDate}
                                        normalize={dateMasking}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
                    <Grid className="mt0" divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Form>
        )
    }
}

export default reduxForm({
    form: 'EditCommitteeGenInfo',
    validate: _func.validateHandler,
    onSubmitFail: _func.onSubmitFailHandler
})(EditCommitteeGenInfo);
