import Parser from 'html-react-parser';
import lodash from 'lodash';
import React, { Component } from 'react';
import Tribute from 'tributejs';
import { routeNavigationUrl } from '../../helpers/util-common';

export default class CommentPost extends Component {

    state = {
        resultSet: []
    }

    onLoad() {
        const { PAGE_TYPE, USER_PRIVILEGE, hasUserPageAccess } = this.props;

        let tribute = new Tribute({
            values: (text, cb) => {
                this.getSearchResult(text, users => cb(users));
            },
            selectTemplate: function (item) {

                if (typeof item === 'undefined') { return null; }
                if (this.range.isContentEditable(this.current.element)) {
                    if (hasUserPageAccess) {
                        return '<span contenteditable="false"><strong><a tagUserId=' + item.original.UserId + ' href="' + routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW, [item.original.UserId]) + '" title="' + item.original.value + '">' + item.original.value + '</a></strong></span>';
                    }
                    return '<span contenteditable="false"><strong><a tagUserId=' + item.original.UserId + ' href="javascript:void(0);" className="noPointer" title="' + item.original.value + '">' + item.original.value + '</a></strong></span>';
                }
                return '@' + item.original.value;
            }
        });
        tribute.attach(this.refs.commentPost);
    }

    removeSpecialCharPattern = (value) => {
        return value.replace(/</g, ' ').replace(/>/g, ' ')
    }

    formattedAndValidateSpecialChar = (cb) => {
        if (this.props.resultSet && this.props.resultSet.length) {
            let resultSet = this.props.resultSet.map((res) => {
                res.UserName = this.removeSpecialCharPattern(lodash(res).get('UserName', ''))
                res.key = this.removeSpecialCharPattern(lodash(res).get('key', ''))
                res.text = this.removeSpecialCharPattern(lodash(res).get('text', ''))
                res.value = this.removeSpecialCharPattern(lodash(res).get('value', ''));
                return res;
            })

            if (this.props.resultSet.length === resultSet.length) {
                cb(resultSet)
                return false;
            }
        }

    }

    getSearchResult = (text, cb) => {
        cb(this.tributeSeekingHandler(this.state.resultSet, text.toLowerCase()))
    }


    tributeSeekingHandler = (items, text) => {
        text = text.split(' ');
        return items.filter((item) => {
            return text.every((el) => {
                return item.key.toLowerCase().indexOf(el.toLowerCase()) > -1;
            });
        });
    }

    componentWillMount = () => {
        this.formattedAndValidateSpecialChar((resultSet) => {
            if (resultSet && resultSet.length > 0) {
                this.setState({
                    resultSet: Object.assign([], resultSet)
                }, () => this.onLoad())
            }
        });
    }

    componentDidMount() {
        this.validateTributeListScrollHandler();
    }

    validateTributeListScrollHandler = () => {
        let mainScroll = document.getElementById('root');
        if (mainScroll !== undefined && mainScroll !== null) {
            mainScroll.addEventListener("scroll", (e) => {
                let tributeListClass = document.querySelector('.tribute-container');
                if (tributeListClass !== undefined && tributeListClass !== null) {
                    tributeListClass.style.display = 'none';
                }
            });
        }
    }

    render() {
        const { comment, placeholder, onChange } = this.props;
        return (

            <div onInput={onChange} contentEditable="true"
                ref={'commentPost'} className={'commentposteditor'} placeholder={placeholder}>{Parser(comment ? comment : '')}</div>

        )
    }
}