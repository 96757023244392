export const WorkItemStatusList = [
   { key: 1, text: 'Proposed', value: 1 }
]

export const WorkItemTypeList = [
   { key: 1, text: 'New Standard', value: 1 },
   { key: 2, text: 'Revision', value: 2 },
   { key: 3, text: 'Reapproval', value: 3 },
   { key: 4, text: 'Withdrawal', value: 4 },
   { key: 5, text: 'Reinstatement', value: 5 },
   { key: 6, text: 'Reapproval with Editorial Change', value: 6 },
   { key: 7, text: 'Withdrawal With Replacement', value: 7 }
]

export const WorkItemType = {
   NewStandard: 1,
   Revision: 2,
   Reapproval: 3,
   Withdrawal: 4,
   Reinstatement: 5,
   ReapprovalWithEditorialChange: 6,
   WithdrawalWithReplacement: 7
}

export const YesNoOptions = [
   { key: 1, text: 'Yes', value: true },
   { key: 2, text: 'No', value: false }
]

export const CompletionIntervalList = [
   { Id: 1, Interval: '3 - 6 months' },
   { Id: 2, Interval: '6 - 12 months' },
   { Id: 3, Interval: '12 - 18 months' },
   { Id: 4, Interval: '18 - 24 months' }
]

export const DeletedWorkItemTableHeader = [
   { 'title': 'Work Item Number', 'hasSorting': true, 'className': "designation", "sortKey": 'WorkItemNumber', 'orderBy': 0, 'orderByIcon': 2 },
   { 'title': 'Type', 'hasSorting': true, 'className': "type", 'sortKey': 'Type', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Title', 'hasSorting': true, 'className': "title", 'sortKey': 'Title', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Deleted By', 'hasSorting': true, 'className': "modifiedby", 'sortKey': 'UserName', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Deleted On', 'hasSorting': true, 'className': "modifiedon", 'sortKey': 'DeletedDate', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Reason for Delete', 'hasSorting': false, 'className': "reason", 'sortKey': '', 'orderBy': 0, 'orderByIcon': 0 }
]

export const WorkItemAuditLogTableHeader = [
   { 'title': 'Modified Date/Time', 'hasSorting': true, 'className': "date", "sortKey": 'When', 'orderBy': 0, 'orderByIcon': 2 },
   { 'title': 'Work Item Number', 'hasSorting': true, 'className': "designation", 'sortKey': 'Designation', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Description', 'hasSorting': false, 'className': "reason", 'sortKey': '', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Modified By', 'hasSorting': true, 'className': "modifiedby", 'sortKey': 'Who', 'orderBy': 0, 'orderByIcon': 0 }
]

export const BallotItemSubmissionTableHeader = [
   { 'title': 'Ballot Designation', 'hasSorting': true, "sortKey": 'BallotItemId', 'orderBy': 1, 'orderByIcon': 1 },
   { 'title': 'Ballot Action', 'hasSorting': true, "sortKey": 'BallotActionName', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Committee', 'hasSorting': true, "sortKey": 'CommitteeDesignation', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'Record Foot Print', 'hasSorting': true, "sortKey": 'CreatedDate', 'orderBy': 0, 'orderByIcon': 0 },
   { 'title': 'File Attachment', 'hasSorting': false, "sortKey": 'FileName', 'orderBy': 0, 'orderByIcon': 0 }
]

export const AuditLogTypeEnum = {
   Add: 1,
   Edit: 2,
   Delete: 3,
   Recover: 4,
   InactiveCommittee: 5,
   InactiveTechnicalContact: 6,
   Suppressed: 7,
   PermanentDeleted: 8,
   collabEditApiFailed: 9,
   collabDeleteApiFailed: 10,
   collabRecoverApiFailed: 11
}

export const OwnerType = {
   StaffUser: 0,
   Member: 1
}
export const BallotItemActionEnum = {
   1: 'New Standard',
   2: 'Revision',
   3: 'Reapproval',
   4: 'Withdrawal',
   5: 'Reinstatement',
   6: "Reapproval with Editorial Change",
   7: "Withdrawal With Replacement"
}
export const CollabApiFailureActionEnum = {
   Update: 1,
   Delete: 2,
   Restore: 3
}
