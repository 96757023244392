import React, { Component } from 'react';
import { AppGridIds } from '../../models/common.models';
import CheckboxElement from '../../shared-components/CheckBox';

class dgMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            escaped: [],
            option: this.props.options,
            displayName: this.props.displayName
        }
        this.singleAttributeKey = null;
    }


    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.options !== this.props.options) {
            this.setState({ option: nextProps.options });
        }
    }

    handleClick = (e) => {
        if (!this.node.contains(e.target)) {
            this.props.onClose();
        }
    }

    shouldBeEscaped = (option) => {
        let self = this;
        return self.props.escaped ? self.props.escaped.includes(option) : false
    }

    onChange(event, attributes) {
        let self = this;
        let option = { ...self.state.option };
        let i = 0;
        let dependedcount = 0;
        let otherLength = 0;
        if (option !== null) {
            for (let keys in option) {
                if (option[keys] === true && !this.shouldBeEscaped(keys)) {
                    ++i;

                    if (i === 1) {
                        this.singleAttributeKey = keys; // Set value while single key in grid menu

                    } else {
                        this.singleAttributeKey = null // Set null while no single key in grid menu

                    }

                }
            }
        }


        // Validate if no single key found.
        if (this.singleAttributeKey === null) {

            option[attributes] = !option[attributes];
            if (attributes === 'Vote') {
                option.NoVoteReason = option[attributes]
            }
            else if (attributes === 'NoVoteReason') {
                option.Vote = option[attributes]
            }
            dependedcount = Object.keys(option).filter(item => ['Vote', 'NoVoteReason'].includes(item) && option[item] === true && !self.props.escaped.includes(item)).length;
            otherLength = Object.keys(option).filter(item => !['Vote', 'NoVoteReason'].includes(item) && option[item] === true && !self.props.escaped.includes(item)).length;
            if ((dependedcount === 2) || (dependedcount === 0 && otherLength > 0)) {
                self.setState({ option: option }, () => self.props.hidecolumn(self.state.option));

            }


        } else if (this.singleAttributeKey !== attributes && !this.shouldBeEscaped(this.singleAttributeKey)) {
            // Validate if single key found.

            if (!['Vote', 'NoVoteReason'].includes(this.singleAttributeKey)) {
                option[attributes] = !option[attributes];
                if (attributes === 'Vote') {
                    option.NoVoteReason = option[attributes]
                }
                else if (attributes === 'NoVoteReason') {
                    option.Vote = option[attributes]
                }


            }
            dependedcount = Object.keys(option).filter(item => ['Vote', 'NoVoteReason'].includes(item) && option[item] === true && !self.props.escaped.includes(item)).length;
            otherLength = Object.keys(option).filter(item => !['Vote', 'NoVoteReason'].includes(item) && option[item] === true && !self.props.escaped.includes(item)).length;
            if ((dependedcount === 2) || (dependedcount === 0 && otherLength > 0)) {
                self.setState({ option: option }, () => self.props.hidecolumn(self.state.option));

            }


        }
    }

    render() {
        const { option, displayName} = this.state;
        return (
            <div data-testid="dg-menu" className="dg-menu Container" style={{ position: 'absolute', zIndex: '100' }} ref={node => this.node = node}>
                <div className="panel">
                    <span className="react-grid-action-icon react-grid-active">
                        <div className="react-grid-action-menu-container">
                            {Object.keys(option).map((value, key) => {
                                return !this.shouldBeEscaped(value) && (
                                    <div className="option">
                                        <label>
                                            <CheckboxElement data-testid="check-box" setLabeltitleProp={(displayName !== null && displayName !== undefined) ? displayName[value] : value} IsEditable={true}
                                                IsChecked={option[value]} checked={option[value]}
                                                onChange={(event) => this.onChange(event, value)} />
                                        </label>
                                    </div>
                                )
                            })}

                        </div>
                    </span>

                </div>
                {<button onClick={(e) => this.props.saveGridPerferenceClickAction(e)} className="ui button primary mt20" id="btnSavePreferences" data-testid="btnSavePreferences">Save my Preference</button>}
            </div>
        )

    }
}
export default dgMenu;
