import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import lodash from 'lodash';
import { unemojify } from "node-emoji";
import React, { Component } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './text-editor.css';
const Editor = () => {
  return import('react-draft-wysiwyg')
}

class ControlledEditor extends Component {

  state = {
    editorCmp: null,
    editorState: null
  }

  componentWillMount() {
    const self = this;
    Editor()
      .then(cmp => {
        let editorState = null;

        if (self.props.value && self.props.value.length > 0) {
          editorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(self.props.value)
            )
          )
        } else {
          editorState = EditorState.createEmpty();
        }

        self.setState({
          editorCmp: cmp,
          editorState: editorState
        }, () => {
          self.props.onChange(
            draftToHtml(convertToRaw(self.state.editorState.getCurrentContent()))
          );
        });
      })
  }

  componentDidMount() {
    this.loadEvent();
  }

  // Load event
  loadEvent = () => {
    this.mentionCounter = 0;
    this.clientHeight = 0;
    document.addEventListener('keydown', this.handleScrollByMentionFun, false);
    document.addEventListener('keyup', this.validateEditorOpenAreaHanlder, false);
  }

  getDropDownRef = () => {
    return document.querySelector('.rdw-suggestion-dropdown');
  }

  getEditorRef = () => {
    return document.querySelector(".rdw-editor-main");
  }

  getActiveDropDownRef = (selector) => {
    return selector.querySelector('.rdw-suggestion-option-active');
  }

  getTriggerRef = () => {
    return document.querySelector('.rdw-suggestion-wrapper');
  }

  handleCutTextOnSelection = (evt, selector) => {
    let parentNode = this.getTriggerRef();
    if (evt.keyCode === 16 && parentNode) {
      let childNode = parentNode.children;
      if (childNode !== null && childNode.length > 0) {
        let selection = window.getSelection();
        if (selection) {
          selection.selectAllChildren(childNode[0]);
          selector.classList.add('noselect');
        }
      }
    }
  }


  validateEditorOpenAreaHanlder = (evt) => {
    let selector = this.getDropDownRef()
    let container = this.getEditorRef()
    if (selector && container) {

      let BOUNDS = selector.getBoundingClientRect();
      container.scrollTop = BOUNDS.top;
      // IF popup will appear in right postion
      if (Number(BOUNDS.x) > 700) {
        selector.removeAttribute('style');
        selector.setAttribute('style', "left:auto;right:0")
      }
      // IF popup will appear in left postion
      if (Number(BOUNDS.x) < 48) {
        selector.removeAttribute('style');
        selector.setAttribute('style', "left:0;right:auto")

      }
      this.handleCutTextOnSelection(evt, selector);
    }
  }

  // Overflow handler for key up/down
  keyUpAndDownHandler = (evt, selector) => {
    let activeSelector = this.getActiveDropDownRef(selector)
    if (activeSelector) {
      this.mentionCounter = activeSelector.getAttribute("data-index");
      // If pressed downkey
      if (evt.keyCode === 40) {
        selector.scrollTop = activeSelector.offsetTop
      }

      // If pressed upkey
      if (evt.keyCode === 38) {
        selector.scrollTop = activeSelector.offsetTop
      }
    }
  }

  handleScrollByMentionFun = (evt) => {
    let selector = this.getDropDownRef();
    if (selector) {
      evt.stopImmediatePropagation();
      lodash.debounce(this.keyUpAndDownHandler, 10)(evt, selector);
    }
  }

  resetScrollByMentionFun = () => {
    this.mentionCounter = 0;
    this.clientHeight = 0;
  }


  onEditorStateChange = (editorState) => {

    const { onChange, value } = this.props;
    const newValue = unemojify(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    const contentState = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    if (contentState === oldContent || contentState.getPlainText().length >= 0) {
      if (value !== newValue) {
        onChange(newValue);
        this.resetScrollByMentionFun();
      }
      this.setState({ editorState });
    } else {
      editorState = EditorState.moveFocusToEnd(
        EditorState.push(
          this.state.editorState,
          ContentState.createFromText(oldContent.getPlainText())
        )
      );
      if (value !== newValue) {
        onChange(newValue);

      }
      this.setState({ editorState });
    }
  };

  render() {
    const { editorState, dafaultEditorState, editorCmp } = this.state;
    const { label, required, suggestionList, placeholder } = this.props;

    return editorCmp ? (
      <div>
        <label className="label">
          {(required) && (<i aria-hidden="true" className="asterisk  icon"></i>)}
          {label}
        </label>
        {placeholder && placeholder.length > 0 && (
          <span className="suggestionTxt">{placeholder}</span>
        )}

        {suggestionList && suggestionList.length > 0 ? (
          <editorCmp.Editor
            editorState={editorState}
            defaultEditorState={dafaultEditorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history']
            }}
            mention={{
              separator: ' ',
              trigger: '@',
              suggestions: suggestionList
            }}
          />
        ) : (
            <editorCmp.Editor
              editorState={editorState}
              defaultEditorState={dafaultEditorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history']
              }}
            />
          )}

      </div>
    ) : null
  }
}

export default ControlledEditor;
