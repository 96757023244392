import { routeNavigationUrl, utcDateTimeToLocalDateTime } from 'helpers/util-common';
import React from 'react';
import { Modal } from 'semantic-ui-react';

const MemberCommitteeHistoryModal = ({ onCloseMemberCommitteeHistoryModal, memberCommitteeHistoryList, hasUserPageAccess, PAGE_TYPE, USER_PRIVILEGE }) => (


    <Modal open='true'>
        <Modal.Header> Member Committee Status History Details <i className="close" onClick={onCloseMemberCommitteeHistoryModal}>&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <div className="tableWrapper">
                    <table className="customTable memComHistoryTable">
                        <thead>
                            <tr>
                                <th width="15%">Member Committee Status</th>
                                <th width="20%">Reason</th>
                                <th width="30%">Comment</th>
                                <th width="20%">Updated Date</th>
                                <th width="15%">Updated By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberCommitteeHistoryList && memberCommitteeHistoryList.length > 0 &&
                                memberCommitteeHistoryList.map((item) => {
                                    return (<tr>
                                        <td width="15%">{item.MemberCommitteeStatus}</td>
                                        <td width="20%">{item.Reason}</td>
                                        <td className="comment" width="30%">{item.Comment}</td>
                                        <td width="20%">{utcDateTimeToLocalDateTime(item.UpdatedDate)}</td>
                                        <td width="15%">
                                            {hasUserPageAccess && item.UpdatedUserId > 0 ?
                                                (<a target="_blank" href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW, [item.UpdatedUserId])}`}>{item.UpdatedUserName}</a>)
                                                : item.UpdatedUserName
                                            }
                                        </td>
                                    </tr>);
                                })}

                        </tbody>
                    </table>
                    {memberCommitteeHistoryList && memberCommitteeHistoryList.length === 0 &&
                        <div className="noRecordMessage">No data to display</div>
                    }
                </div>
            </Modal.Description>
        </Modal.Content>
    </Modal>
)

export default MemberCommitteeHistoryModal;
