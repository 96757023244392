import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal, Confirm } from 'semantic-ui-react';
import RenderSelect from 'shared-components/Select';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea'
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import CustomRendererSearch from '../custom-renderer-search';
import * as _func from './function';
import * as common from 'helpers/util-common';
import { commiteeInactiveReason } from '../../committee.model';

class InactivateCommitteeModal extends PureComponent {
    state = {
        copyClassification: false,
        copyVote: false,
        inActiveReason: [],
        inActiveReasonId: 0,
        copyRoster: 'true',
        mergeCommittees: this.props.mergeCommittees,
        showdependentAlertPopup: false,
        formattedCommittee: ''
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }
    onSubmitHandler = (data) => {
        let requestData = {
            CommitteeId: this.props.initialValues.CommitteeId,
            Month: data.Month,
            MeetingTypeId: data.MeetingType,
            inActiveReasonValue: 0
        }

        this.props.addCommitteeMeetingSequence(requestData, () => {
            // Refresh the data
            this.props.onSubmit();
        })
    }

    render() {
        const { handleSubmit, submitting, onClose, inActiveReasons, messageCodes, activeMemberCount } = this.props;
        const { showNoResultsMessage, isLoading, inActiveReasonId, copyRoster, mergeCommittees, showdependentAlertPopup, formattedCommittee } = this.state;

        return (
            <Modal open='true' className="tiny" data-testid="inactivateCommitteeModal">
                <Form size='large' onSubmit={handleSubmit} noValidate>

                    <Modal.Header>Inactivate Committee <i className="close" data-testid="closeBtn" onClick={() => onClose('inActiveCommittee')}>&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="InactiveReason" type="text" required={true}
                                            component={RenderSelect} label="Inactive Reason"
                                            placeholder="Select"
                                            options={inActiveReasons}
                                            onChange={(event, value) => this.setState({ inActiveReasonId: value })}
                                        />

                                    </Grid.Column>
                                </Grid.Row>
                                {inActiveReasonId === commiteeInactiveReason.MERGED &&
                                    <React.Fragment>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Field name="MergeCommittee" type="text"
                                                    component={CustomRendererSearch}
                                                    placeholder={'Search by Committee Designation'}
                                                    label={'Merge with Committee'}
                                                    maxLength="100"
                                                    showNoResults={showNoResultsMessage}
                                                    onChange={(e, val) => _func.handleSearh(val)}
                                                    results={mergeCommittees}
                                                    isLoading={isLoading}
                                                    onResultSelect={_func.handleResultSelect}
                                                    required={true}
                                                />


                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column>
                                                {activeMemberCount.CountActiveMembers > 0 ?
                                                    <div className={'radioWrap'} >
                                                        {<Field name={'CopyRoster'}
                                                            component={RenderRadioField}
                                                            className={'mr10'}
                                                            type={'radio'}
                                                            selectedValue={copyRoster}
                                                            label={'Copy Roster'}
                                                            onChange={_func.copyRosterHandleChange}
                                                            options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                                                        />}
                                                    </div> : <span className={'legendNotes mt-10 italic'}>{common.getMessage(messageCodes, '9068.text')}</span>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                        {copyRoster === 'true' && activeMemberCount.CountActiveMembers > 0 && <React.Fragment><Grid.Row>
                                            <Grid.Column>
                                                <span className="inlineBlock mr20">
                                                    <Field name="CopyApplication" type="checkbox" className="mr20"
                                                        component={ReduxCheckboxElement} label="Copy Application"
                                                        onChange={(event, value) => _func.handleChange(event, value, 'CopyApplication')}
                                                    />
                                                </span>
                                                <span className="mr20">
                                                    <Field name="CopyClassification" type="checkbox"
                                                        component={ReduxCheckboxElement} label="Copy Classification"
                                                        onChange={(event, value) => _func.handleChange(event, value, 'CopyClassification')}
                                                        required={true}

                                                    />
                                                    <i aria-hidden="true" className="asterisk  icon requiredIcon"></i>
                                                </span>
                                                <span>
                                                    <Field name="CopyVote" type="checkbox"
                                                        component={ReduxCheckboxElement} label="Copy Vote"
                                                        onChange={(event, value) => _func.handleChange(event, value, 'CopyVote')}
                                                    />
                                                </span>
                                            </Grid.Column>
                                        </Grid.Row>
                                            <span className="legendNotes mt10 mb20"><strong>Note: </strong>If Classification of copied Members is 'Producer' on a Subcommittee, the classification will be changed to 'Producer' on the Main Committee (if not already assigned as Producer). This will inform the user that if the Classification of the copied Members is Producer in Subcommittee, then in Main Committee, the Classification of those Members will also be Producer. </span>
                                        </React.Fragment>}
                                    </React.Fragment>
                                }
                                <Grid.Row>

                                    <Grid.Column>
                                        <Field component={TextArea} type='text' label='Reason for update'
                                            name='reasonForUpdate' required={true} maxLength='200'
                                            placeholder="Please enter reason for update"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button className="ui button primary" disabled={submitting} type="submit">Save</button>
                            <button className="ui button cancel" onClick={() => onClose('inActiveCommittee')}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
                <Confirm className="confirm-box"
                    open={showdependentAlertPopup}
                    content={common.getMessage(messageCodes, '9070.text').replace("<Next Level Titles>", formattedCommittee)}
                    onCancel={_func.closeAlertPopup}
                    onConfirm={_func.confirmAlertPopup}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'InactivateCommitteeModal',
    validate: _func.validateHandler,
    onSubmit: _func.onSubmitHandler
})(InactivateCommitteeModal);
