import React, { Component } from 'react';
import { Icon, Grid, Form, Confirm } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import * as _func from './function';
import { StatusFilter } from 'modules/member-management/fee-group/feegroups.model'
import * as common from 'helpers/util-common';

class FeeGroupStatusEdit extends Component {
    state = {
        showConfirm: false
    }

    componentDidMount() {
        _func.setClassInstance(this);
        this.props.change('selectedMenu', this.props.initialValues.Status)

    }

    render() {
        const { handleSubmit, submitting, editFormHandler, messageCodes } = this.props;
        const { showConfirm } = this.state

        return (
                <section className="mt30" data-testid="statusEditSec">
                    <div className="dashed-border mt20 mb20"></div>
                    <Form onSubmit={handleSubmit(_func.updateFeeGroupStatus)}>
                        <h5>Status
                            <ul className="editDetailsBtn floatRight">
                                <li>
                                    <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                        <Icon name="check" />
                                    </button>
                                </li>
                                <li>
                                    <button title="Cancel" className="closeBtn" onClick={() => editFormHandler(false)}>
                                        <Icon name="close" />
                                    </button>
                                </li>
                            </ul>
                        </h5>
                        <Grid divided='vertically' columns={4}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="selectedMenu"
                                        component={RenderSelect}
                                        placeholder="Active"
                                        onChange={this.onChangeMenu}
                                        label="Status"
                                        options={StatusFilter.filter(item => item.key > 1)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid divided='vertically' className="mt0" data-testid="textAreaDiv">

                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="UpdateReason"
                                        component={TextArea} required={true} label="Reason for Update"
                                        maxLength="200" placeholder="Please enter the reason for update"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>


                    </Form>
                    <Confirm data-testid="confirmbox" className="confirm-box"
                        open={showConfirm}
                        content={common.getMessage(messageCodes, '9072.text').replace("[X]", this.props.initialValues.AssociatedMemberCount)}
                        onCancel={() => editFormHandler(false)}
                        onConfirm={() => _func.onConfirmHandler()}
                        size="tiny"
                        cancelButton="No"
                        confirmButton="Yes"
                    />
                </section>
        )
    }
}
export default reduxForm({
    form: 'FeeGroupStatusEdit',
    validate: _func.validateHandler
})(FeeGroupStatusEdit);
