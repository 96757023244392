import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import { encrypt } from 'helpers/util-common';

// TODO : Get member Invoice/Stock/Receipt Info Action.
export const getMemberInoviceInfoAction = (accountNumber, reportType, dispatch, callback) => {
    let url = getApiUrl('manageMembers', 'getMemberAccountDetails') + `/${encrypt(accountNumber)}?reportType=${reportType}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            callback(response.data.content);
        })
}

// TODO : Get member RNE Template Action
export const getMemberRNETemplateAction = (template, dispatch, callback) => {
    let url = getApiUrl('manageMembers', 'getMemberRNETemplate') + `/${template}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, '9801'))
            }
        })
}

export const getMcsStatusReportAction = (query, dispatch, callback) => {
    const url = `${getApiUrl('reports', 'mcsStatusReport')}${query}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, '9531'))
            }
        })
}

export const getMembershipRevenuesReportAction = (query, dispatch, callback) => {
    const url = `${getApiUrl('reports', 'membershipRevenuesReport')}${query}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, '9531'))
            }
        })
}

export const getMonthlyStatisticsReportAction = (query, dispatch, callback) => {
    const url = `${getApiUrl('reports', 'monthlyStatisticsReport')}${query}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, '9531'))
            }
        })
}

export const changeBookVolumeExcelFileDownloadAction = (dispatch, callback) =>{
    const url = `${getApiUrl('reports', 'volumeMembers')}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, '9712'))
            }
        })
}
