import * as utilCommon from 'helpers/util-common';

let self = {};
let committeeId = 0;

export const setClassInstance = (instance) => {
    self = instance;
    committeeId = self.props.initialValues.CommitteeId;
    self.props.change('reactivate', self.state.reactivateSelectedValue)
}

export const handleChange = (event) => {
    const checkedRadioButton = event.currentTarget.children[0].value

    self.setState({ reactivateSelectedValue: checkedRadioButton }, () => {
        self.props.change('reactivate', self.state.reactivateSelectedValue)
    })
}
export const reActivateCommittee = (values) => {
    if (values.ParentCommitteeId > 0 && self.props.initialValues.ParentCommitteesList.length > 0) {
        let parentInfo = self.props.initialValues.ParentCommitteesList.filter(item => item.CommitteeStatus.toLowerCase() === 'inactive' && self.props.initialValues.ParentCommitteeId === item.CommitteeId && item.CommitteeId !== self.props.initialValues.CommitteeId) || [];
        if (parentInfo.length > 0) {
            let alertMessage = utilCommon.getMessage(self.props.messageCodes, '9071.text').replace('<Previous Level Committee Designation>', parentInfo[0].CommitteeDesignation);
            self.props.showAlertPopup(alertMessage);
        } else {
            putReactiveCommttee(values)
        }
    } else {

        putReactiveCommttee(values);
    }
}
const putReactiveCommttee = (values) => {
    const { CommitteeId, ParentCommitteeId, CommitteeTypeId } = self.props.initialValues;
    const postData = {
        CommitteeId: CommitteeId,
        ParentCommitteeId: ParentCommitteeId,
        CommitteeTypeId: CommitteeTypeId,
        IsActivateMembers: values.reactivate === '2',
        IsEnableCommitteeOnWeb: self.props.isEnableCommitteeOnWebForCommitteeType,
        UpdateReason: values.reasonForUpdate
    }
    self.props.putReactivateCommittee(postData, () => {
        self.props.getCommitteeDetails(utilCommon.encrypt(committeeId));
        self.props.closePopUp('reActivateCommittee');
    })
}
export const validateHandler = (values, props) => {
    const errors = {};

    if (values.reactivate === '0') {
        errors.reactivate = utilCommon.getMessage(props.messageCodes, '9017.text');
    }
    if (!values.reasonForUpdate || values.reasonForUpdate.trim() === '') {
        errors.reasonForUpdate = utilCommon.getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}
