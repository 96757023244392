import React, { Component } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import CustomRendererSearch from '../custom-renderer-search';
import { Field } from 'redux-form';
import { getMessage, handleAutoComplete } from 'helpers/util-common';
import * as _func from './function';
import { setClassInstance } from '../membership-type-edit/function';

class AssociateOrganizationAccountModal extends Component {

    state = {
        isLoading: false,
        organizationList: [],
        showNoResultsMessage: false,
        showErrorMessage: false,
        error: getMessage(this.props.messageCodes, '8123.text')
    }

    componentWillMount() {
        setClassInstance(this, 2);
        _func.setInstance(this);
    }
    componentDidMount() {
        handleAutoComplete()
    }
    render() {
        const { isLoading, organizationList, showNoResultsMessage, showErrorMessage, error } = this.state;

        return (
            <Modal open='true' className="tiny" data-testid="associateOrganizationAccountModal">
                <Form noValidate onSubmit={_func.onSubmitHandler}>
                    <Modal.Header>Associate Organization<i onClick={_func.onClose} className="close" title="close">&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>

                        <Modal.Description style={{ 'min-height': '370px' }}>
                            <div className="mt20">
                                <div>Organization</div>
                                <Field name="Organization" type="text"
                                    component={CustomRendererSearch}
                                    placeholder="Search by Account Number, Organization Name"
                                    maxLength="100"
                                    showNoResults={showNoResultsMessage}
                                    onChange={_func.onChange}
                                    source={organizationList}
                                    isLoading={isLoading}
                                    isOrganizationSearch={true}
                                    onResultSelect={_func.onOrganizationSelect}
                                    className="autocompleteOff"
                                />

                                {showErrorMessage && (
                                    <span className="errorMessage mt10">{error}</span>
                                )}
                            </div>

                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <div className="pl0 mt20">
                            <Button primary type="submit">Assign</Button>
                            <Button secondary onClick={_func.onClose}>Cancel</Button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default AssociateOrganizationAccountModal;
