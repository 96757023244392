import { extractUserIdsFromComment, scrollToTopOfPage } from 'helpers/util-common';
import { get } from 'lodash';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
}

export const loadInitialList = () => {
    const accountNumber = self.props.initialValues.AccountNumber;

    self.props.getMemberCommunicationLog(accountNumber, 6, 1, true, (communicationLogData) => {
        self.setState({
            communicationLogList: communicationLogData.MemberCommentLogListModel,
            createdByUserList: communicationLogData.UserList,
            commentCount: communicationLogData.CommentCount,
            commentLength: 0
        });
    });
}

export const fetchNextRecords = (callBack) => {
    let { pageNumber, pageSize, commentCount, communicationLogList, createdByUserList } = self.state;
    const accountNumber = self.props.initialValues.AccountNumber;

    if (commentCount > communicationLogList.length) {
        self.props.getMemberCommunicationLog(accountNumber, pageSize, pageNumber + 1, false, (updatedCommunicationLogList) => {
            if (updatedCommunicationLogList.CommentCount >= communicationLogList.length) {
                if (communicationLogList !== undefined && communicationLogList.length > 0) {
                    communicationLogList.push(...updatedCommunicationLogList.MemberCommentLogListModel);
                } else {
                    communicationLogList = updatedCommunicationLogList.MemberCommentLogListModel;
                }

                if (createdByUserList !== undefined && createdByUserList.length > 0) {
                    createdByUserList.push(...updatedCommunicationLogList.UserList);
                } else {
                    createdByUserList = updatedCommunicationLogList.UserList;
                }

                self.setState({
                    communicationLogList,
                    createdByUserList,
                    pageNumber: pageNumber + 1,
                    commentCount: updatedCommunicationLogList.CommentCount,
                    showLoader: false
                })
            }
            callBack();
        });
    }
}

export const communicationEditHandler = (commentId) => {
    const { communicationLogList } = self.state;

    if (communicationLogList !== null && communicationLogList.length > 0) {
        let result = communicationLogList.map((res) => {
            if (res.CommentId === commentId) {
                res.isClickedOnEdit = true;
            } else {
                res.isClickedOnEdit = false;
            }
            return res;
        });

        // Update the state & Comment
        self.setState({
            communicationLogList: result,
            clickedCommentId: commentId
        });
    }
}

export const onRemoveHandler = () => {
    const { clickedCommentId, communicationLogList, userDetails } = self.state;

    const clickedComment = communicationLogList.find(comment => comment.CommentId === clickedCommentId);
    const comment = clickedComment ? clickedComment.Comment : '';

    let objParams = {
        AccountNumber: self.props.initialValues.AccountNumber,
        Comment: comment,
        TaggedUserList: extractUserIdsFromComment(comment),
        UserName: userDetails ? userDetails.UserName : ''
    }

    self.setState({ pageNumber: 1, pageSize: 6, isPopOpen: false })
    self.props.removeMemberCommunicationLog(clickedCommentId, objParams, clickedComment.CreatedBy === get(userDetails, 'userId'), () => {
        loadInitialList();
        scrollToTopOfPage();
    })
}

export const onUpdateHandler = (comment, callback) => {
    const { clickedCommentId, userDetails } = self.state;

    // Get the old comment and compare with the new
    let oldComment = self.state.communicationLogList.find(item => item.CommentId === clickedCommentId);
    if (comment !== oldComment.Comment) {
        let objParams = {
            AccountNumber: self.props.initialValues.AccountNumber,
            Comment: comment,
            TaggedUserList: extractUserIdsFromComment(comment),
            UserName: userDetails ? userDetails.UserName : ''
        }

        self.props.updateMemberCommunicationLog(clickedCommentId, objParams, oldComment.CreatedBy === get(userDetails, 'userId'), () => {
            updateSuccessHandler(clickedCommentId, comment, callback);
        })
    } else {
        updateSuccessHandler(clickedCommentId, comment, callback, false);
    }
}

// Submit Hanlder for submit comment post
export const onSubmitHandler = (comment, callback) => {
    const { userDetails } = self.state;
    self.setState({ pageNumber: 1, pageSize: 6 })

    let objParams = {
        AccountNumber: self.props.initialValues.AccountNumber,
        Comment: comment,
        TaggedUserList: extractUserIdsFromComment(comment),
        UserName: userDetails ? userDetails.UserName : ''
    }
    self.props.addCommunicationLog(objParams, () => {
        callback();
        loadInitialList();
    });
}

const updateSuccessHandler = (clickedCommentId, comment, callback, isCommentUpdated = true) => {
    let { communicationLogList } = self.state;

    communicationLogList.map((log) => {
        if (log.CommentId === clickedCommentId) {
            if (isCommentUpdated) {
                log.Comment = comment;
                log.ModifiedDate = log.ModifiedDate + 1;
            }
            log.isClickedOnEdit = false
        }
        return log;
    });

    callback();

    self.setState({ commentLength: 0, communicationLogList: communicationLogList, clickedCommentId: 0 })
}
