import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from './src/app.container';
import setupInterceptors from './src/interceptor';
import configureStore from './src/store';
import { FlagFeaturesProvider } from './src/providers/flagFeature'
import './index.css';

(() => {
    let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
    if (isJiraEnabled === 'true') {
        let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
        document.write('<script src="' + src + '"><\/script>');
    }
})();

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
    };
}

// Configure Store
const store = configureStore({}, {});
// Configure Interceptor
setupInterceptors(store);
// Configure Provider
createRoot(document.getElementById('root')).render(<Provider store={store}>
    <FlagFeaturesProvider>
        <BrowserRouter>
            <AppContainer />
        </BrowserRouter>
    </FlagFeaturesProvider>
</Provider>);

