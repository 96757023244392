import React from "react";
import ExportExcel from "shared-components/ExportExcel";
import moment from 'moment';

const FeeGroupExcelExport = ({ data }) => {
    const fileName = 'Fee Group Records_' + moment(new Date()).format('MM-DD-YYYY');
    const tabName = 'Fee Group';

    const columns = [
        { title: 'Fee Group Number', style: { font: { bold: true } } },
        { title: 'Fee Group Title', style: { font: { bold: true } } },
        { title: 'Contact Name', style: { font: { bold: true } } },
        { title: 'Associated Members', style: { font: { bold: true } } },
        { title: 'Paid Status', style: { font: { bold: true } } },
        { title: 'Status', style: { font: { bold: true } } }
    ];

    let rows = [];
    for (let i = 0; i < data.length; i++) {
        let row = [
            { value: data[i].FeeGroupNumber },
            { value: data[i].FeeGroupTitle },
            { value: data[i].ContactName },
            { value: data[i].AssociatedMembers, style: { alignment: { horizontal: "center" } } },
            { value: data[i].PaidStatus },
            { value: data[i].Status }
        ];
        rows.push(row);
    }

    // Need atleast an empty row.
    if (data.length === 0) {
        let row = [
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' }
        ];
        rows.push(row);
    }

    const multiDataSet = [{ columns: columns, data: rows }];

    return (
        <ExportExcel fileName={fileName} tabName={tabName} multiDataSet={multiDataSet} />
    );
}
export default FeeGroupExcelExport;
