import { getMessage } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid, Icon } from "semantic-ui-react";
import CheckboxElement from 'shared-components/CheckBox';
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import RenderRadioField from 'shared-components/RadioBox';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import * as _func from './function';

class EditCommitteeSettings extends Component {

    state = {
        showBalanceRuleSection: false,
        classificationList: [],
        showLeftClassificationError: false,
        showRightClassificationError: false,
        showSetBalanceRuleError: false,
        showConfirmationModal: false,
        isBalanceRequired: 'No'
    }

    componentWillMount() {
        _func.setInstance(this);
    }

    render() {
        const { onCloseEditSettings, handleSubmit, initialValues, operatorList, messageCodes, submitting } = this.props;
        const { showBalanceRuleSection, classificationList, showLeftClassificationError, showRightClassificationError, showSetBalanceRuleError, isBalanceRequired, showConfirmationModal } = this.state;
        let isCommitteeUnClassified = initialValues.Classification && initialValues.Classification.toLowerCase() === 'u';

        return (
            <div data-testid="editCommitteeSettings">
                <h5 className="mt30">Settings</h5>
                <Form noValidate onSubmit={handleSubmit(_func.onSubmitHandler)} className="settingArea">
                    <Grid divided='vertically' className="mt0">
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="IsSuppressCommittee" type="checkbox"
                                    component={ReduxCheckboxElement} isDisabled={initialValues.IsSuppressCommitteeOfParent}
                                    label="Suppress Committee to appear for new or existing Member to join"
                                    onChange={_func.handleOnChangeSuppressCommittee}
                                />
                                <ul className="editDetailsBtn floatRight">
                                    {!isCommitteeUnClassified && (
                                        <li>
                                            <button type="button" title="Reset to default balance rule" className="editBtn" onClick={_func.showConfirmationModal}>
                                                <Icon name="redo" />
                                            </button>
                                        </li>
                                    )}
                                    <li>
                                        <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                            <Icon name="check" />
                                        </button>
                                    </li>
                                    <li>
                                        <button title="Cancel" className="closeBtn" onClick={onCloseEditSettings}>
                                            <Icon name="close" />
                                        </button>
                                    </li>
                                </ul>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="IsBalanceRequired"
                                    component={RenderRadioField}
                                    className="mr10"
                                    label="Balance Rule Applicable"
                                    type="radio"
                                    options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                                    selectedValue={isBalanceRequired}
                                    isDisabled={isCommitteeUnClassified}
                                    onChange={_func.toggleBalanceRuleSection}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row id='committeeBalanceRule'>
                            {showBalanceRuleSection && <Grid.Column>

                                <label className="label">Set Balance Rule</label>
                                <section className="greyBox type2 clearfix">
                                    <div className="memClassType ">
                                        <span className="memClassTypeTitle"><i aria-hidden="true" className="asterisk  icon requiredIcon"></i>Member Classification Type</span>
                                        <ul className="memClassTypeList">
                                            {classificationList.map((item, index) => {
                                                return <li>
                                                    <CheckboxElement IsChecked={item.isLeft}
                                                        IsEditable={!item.isRight} setLabeltitleProp={item.Name}
                                                        onChange={() => _func.updateClassificationList(1, index)}
                                                    />
                                                </li>
                                            })}
                                        </ul>
                                        {showLeftClassificationError && <span className="errorMessage mt0">{getMessage(messageCodes, '8138.text')}</span>}
                                    </div>
                                    <div className="operator">
                                        <Field name="OperatorSymbol"
                                            component={RenderSelect}
                                            isMultiple={false}
                                            placeholder="Select"
                                            required={true}
                                            options={operatorList}
                                            label="Operator"
                                            onChange={_func.onChangeOperatorSymbol}
                                        />
                                    </div>
                                    <div className="memClassType " id="classificationError">
                                        <span className="memClassTypeTitle"><i aria-hidden="true" className="asterisk  icon requiredIcon"></i>Member Classification Type</span>
                                        <ul className="memClassTypeList">
                                            {classificationList.map((item, index) => {
                                                return <li>
                                                    <CheckboxElement IsChecked={item.isRight}
                                                        IsEditable={!item.isLeft} setLabeltitleProp={item.Name}
                                                        onChange={() => _func.updateClassificationList(2, index)}
                                                    />
                                                </li>
                                            })}
                                        </ul>
                                        {showRightClassificationError && <span className="errorMessage mt0">{getMessage(messageCodes, '8138.text')}</span>}
                                    </div>
                                </section>
                            </Grid.Column>
                            }
                        </Grid.Row>
                    </Grid>
                    {showSetBalanceRuleError && <span className="errorMessage mt0">{getMessage(messageCodes, '8139.text')}</span>}
                    <div ref='forScrollPurpose'></div>
                    <Grid className="mt0" divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <Confirm className="confirm-box"
                    open={showConfirmationModal}
                    content={getMessage(messageCodes, '9069.text')}
                    onCancel={_func.onCancelModal}
                    onConfirm={_func.resetBalanceRule}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

            </div>

        )
    }
}

export default reduxForm({
    form: 'EditCommitteeSettings',
    validate: _func.validateHandler,
    onSubmitFail: _func.onSubmitFailHandler
})(EditCommitteeSettings);
