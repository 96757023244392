import { generateUUIDForAutofill } from 'helpers/util-common';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

class RenderInputField extends Component {

    state = {
        uuid: generateUUIDForAutofill()
    }

    // Update value in redux fields
    handleChange = (event, { name, value }) => {
        this.props.input.onChange(value);
    }

    render() {
        const { uuid } = this.state;
        // For redux form implementation
        if ('input' in this.props) {
            const { readOnly, input, offAutoComplete, label, dataTestId, type, placeholder, required, autoFocus, disabled, maxLength, isDuplicate, duplicateError, onKeyPress, meta: { touched, error } } = this.props

            return (
                <div>
                    <label className="label" htmlFor={input.name}>
                        {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                        {label}
                    </label>

                    {autoFocus === 'true' && (
                        <Form.Input fluid size='mini' id={input.name}
                            {...input} placeholder={placeholder}
                            className={touched && error ? 'error' : ''}
                            type={type} autoFocus
                            maxLength={maxLength ? maxLength : 100}
                            readOnly={readOnly ? readOnly : false}
                            autoComplete={offAutoComplete ? `none_${uuid}` : "on"}
                        />
                    )}

                    {disabled && (
                        <Form.Input fluid size='mini' id={input.name}
                            {...input} placeholder={placeholder}
                            className={touched && error ? "error" : ''}
                            type={type} disabled
                            readOnly={readOnly ? readOnly : false}
                        />
                    )}

                    {(!autoFocus || autoFocus === 'false') && !disabled && (
                        <Form.Input
                            fluid
                            size='mini'
                            data-testid={dataTestId}
                            {...input} id={input.name}
                            placeholder={placeholder}
                            className={touched && error ? 'error' : ''}
                            type={type}
                            maxLength={maxLength ? maxLength : 100}
                            onKeyPress={onKeyPress}
                            readOnly={readOnly ? readOnly : false}
                            autoComplete={offAutoComplete ? `none_${uuid}` : "on"}
                        />
                    )}

                    {touched && (error && <span className="errorMessage">{error}</span>)}
                    {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
                </div>
            )
        }

        // Without redux form implementation
        const { value, input, name, label, type, placeholder, required, autoFocus, disabled, maxLength, isDuplicate, duplicateError, onKeyPress, touched, error, onChange } = this.props
        return (
            <div>
                <label className="label" htmlFor={name}>
                    {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
                    {label}
                </label>

                <Form.Input fluid size='mini' name={name}
                    id={name}
                    {...input} placeholder={placeholder}
                    className={touched && error ? "error" : ''}
                    type={type} autoFocus={autoFocus}
                    maxLength={maxLength ? maxLength : 100}
                    onChange={(e, { data }) => typeof onChange === 'function' && onChange(e, data)}
                    value={value}
                    disabled={disabled}
                    onKeyPress={onKeyPress}
                />
                {touched && (error && <span className="errorMessage">{error}</span>)}
                {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
            </div>
        )
    }
}
export default RenderInputField;
