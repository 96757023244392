import * as utilCommon from 'helpers/util-common';
import { feeGroupExportFormat } from '../../feegroups.model';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
};

// Start work on Word export part :-
export const feeGroupDetailsExport = (exportFormat) => {
    self.setState({ showLoader: true, exportFormat });

    // Exported Word Response in Callback : -
    self.props.getFeeGroupInvoiceDetails(self.props.initialValues.FeeGroupId, (response, status) => {
        self.setState({ showLoader: false });

        if (status) {
            if (response !== null && Object.keys(response).length > 0) {
                getWordDocxPageResponse(exportFormat, response);
            } else {
                self.setState({
                    isWordEmpty: true,
                    showLoader: false
                })
            }
        }
    })
}

export const getWordDocxPageResponse = (exportFormat, feeGroupDetails) => {
    let ExportedData = {};
    let fileName = 'Fee Group';
    const pageName = 'FeeGroupDetailsExport';

    if (feeGroupDetails !== null && Object.keys(feeGroupDetails).length > 0) {
        const currentRenewalYear = utilCommon.formatDateToYYYY(feeGroupDetails.CurrentRenewalYear)
        fileName = `${feeGroupDetails.Invoice.FeeGroupTitle}_${currentRenewalYear}_Fee Group`;

        if (exportFormat === feeGroupExportFormat.template) {
            ExportedData.Header = feeGroupDetails.Header;
            ExportedData.Footer = feeGroupDetails.Footer;
            wordFileExport([], fileName, '', '', ExportedData, pageName);

        } else if (exportFormat === feeGroupExportFormat.invoice && feeGroupDetails.Invoice.AssociatedMemberCount > 0) {
            ExportedData.Header = feeGroupDetails.Header;
            ExportedData.Footer = feeGroupDetails.Footer;
            ExportedData.Invoice = feeGroupDetails.Invoice;
            ExportedData.Invoice.Date = utilCommon.formatDateToMMDDYYYY(feeGroupDetails.Invoice.Date);
            ExportedData.Invoice.InvoiceId = `${currentRenewalYear}/${feeGroupDetails.Invoice.FeeGroupNumber}`;
            ExportedData.Invoice.Items = feeGroupDetails.Invoice.Items.map((item) => {
                item.MembershipOrder = `${utilCommon.formatDateToYYYY(feeGroupDetails.Invoice.Date)} ASTM International ${item.MembershipOrder}`;
                return item;
            });
            ExportedData.Invoice.Members = feeGroupDetails.Invoice.Members.sort((a, b) => a.LastName.localeCompare(b.LastName));
            ExportedData.CurrentRenewalYear = currentRenewalYear;
            ExportedData.Term = `1 January ${currentRenewalYear} - 31 December ${currentRenewalYear}`;
            wordFileExport([], fileName, '', '', ExportedData, pageName);

        } else {
            let alertMessage = utilCommon.getMessage(self.props.messageCodes, '8161.text');
            ExportedData = {};
            self.props.showAlertPopup(alertMessage);

        }
    } else {
        ExportedData = {};
        wordFileExport([], fileName, '', '', ExportedData, pageName);

    }
}

const wordFileExport = (excelExportedColumn, fileName, HeaderDate, headerText, ExportedData, pageName) => {
    let docXConfig = new window.docXConfiguration([], fileName, '', '', ExportedData, pageName);
    utilCommon.exportWordFile(docXConfig, ExportedData);
    self.setState({ showLoader: false }) // Disable loader while file downloaded
}

export const emptyWordCancelHandler = () => {
    self.setState({
        isWordEmpty: false
    });
}
export const emptyWordConfirmHandler = () => {
    self.setState({
        isWordEmpty: false
    }, () => getWordDocxPageResponse(self.state.exportFormat, {})); // Exported Empty File
}
// End work on Word export part :-
