import * as utilCommon from 'helpers/util-common';
let self = {};
let TIMEOUT = null;
let postData = [];
let oldPrivileges = {};

export const setClassInstance = (instance) => {
    self = instance;
    if (self.props.linkedOfficerTitleId > 0) {
        const committeeId = self.props.initialValues.CommitteeId;
        self.props.getDelinkedOfficerResponsibilityOnCommittee([{ OfficerTitleId: self.props.linkedOfficerTitleId, committeeId }], (response) => {
            let responsibility = self.state.responsibility;
            oldPrivileges = utilCommon.deepCopy(response.mainResponsibility[0]);
            responsibility = { officerResponsibility: response.mainResponsibility[0] || [], extendedResponsibility: response.extendedResponsibility || [] };
            self.setState({ responsibility });
            getFinalResponsibility(responsibility.officerResponsibility, responsibility.extendedResponsibility);
        })
    }
}
export const handleResultSelect = (result) => {
    self.setState({ officerTitleId: result.OfficerTitleId })
    const committeeId = self.props.initialValues.CommitteeId;
    self.props.change('OfficerTitle', result.OfficerTitleName);
    if (result.OfficerTitleId && result.OfficerTitleId > 0) {
        self.props.getDelinkedOfficerResponsibilityOnCommittee([{ OfficerTitleId: result.OfficerTitleId, committeeId }], (response) => {
            let responsibility = self.state.responsibility;
            oldPrivileges = utilCommon.deepCopy(response.mainResponsibility[0]);
            responsibility = { officerResponsibility: response.mainResponsibility[0] || [], extendedResponsibility: response.extendedResponsibility || [] };
            self.setState({ responsibility });
            getFinalResponsibility(responsibility.officerResponsibility, responsibility.extendedResponsibility);
        })
    }
}
const getFinalResponsibility = (mainList, secondList, thirdList = []) => {
    postData = [];
    let updatedResponsibility = utilCommon.deepCopy(mainList);
    if (updatedResponsibility && updatedResponsibility.ResponsibilityList.length > 0) {
        updatedResponsibility.ResponsibilityList.map(listItem1 => {
            return listItem1.ResponsibilityGroupList.map(listItem2 => {
                if (listItem2.ResponsibilityPrivilegeList.filter(fItem => (fItem.ResponsibilityPrivilegeId === -1)).length === 0) {
                    listItem2.ResponsibilityPrivilegeList.unshift({
                        ResponsibilityPrivilegeId: -1,
                        ResponsibilityPrivilegeName: "All",
                        IsChecked: (listItem2.ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1).length === listItem2.ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1 && p.IsChecked).length),
                        ResponsibilityPrivilegeMappingId: -1
                    })
                }
                listItem2.ResponsibilityPrivilegeList.map(listItem3 => {
                    if (secondList && secondList.length > 0) {
                        secondList.map(list2Item1 => {
                            if (listItem3.ResponsibilityPrivilegeMappingId > 0 && list2Item1.ResponsibilityPrivilegeMappingId === listItem3.ResponsibilityPrivilegeMappingId) {
                                listItem3.IsChecked = list2Item1.IsAdded;
                            }
                            return list2Item1
                        })
                    }

                })
                return listItem2.ResponsibilityPrivilegeList.map(iteminfo => {
                    if (iteminfo.ResponsibilityPrivilegeMappingId === -1) {
                        iteminfo.IsChecked = (listItem2.ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1).length === listItem2.ResponsibilityPrivilegeList.filter(p => p.ResponsibilityPrivilegeMappingId !== -1 && p.IsChecked).length)
                    }
                    return iteminfo
                })
            })
        })

        if (thirdList && thirdList.length > 0) {
            updatedResponsibility.ResponsibilityList.map((listItem1) => {
                return listItem1.ResponsibilityGroupList.map(listItem2 => {
                    return listItem2.ResponsibilityPrivilegeList.filter(listItem3 => listItem3.ResponsibilityPrivilegeMappingId > 0).map(listItem3 => {
                        return thirdList.map(list2Item1 => {
                            return list2Item1.ResponsibilityGroupList.map(list2Item2 => {
                                return list2Item2.ResponsibilityPrivilegeList.filter(list2Item3 => list2Item3.ResponsibilityPrivilegeMappingId > 0 && list2Item3.ResponsibilityPrivilegeMappingId === listItem3.ResponsibilityPrivilegeMappingId
                                    && list2Item3.IsChecked !== listItem3.IsChecked && list2Item2.ResponsibilityGroupId === listItem2.ResponsibilityGroupId).map(list2Item3 => {

                                        postData.push({ "OfficerTitleId": self.state.officerTitleId || self.props.linkedOfficerTitleId, "CommitteeId": self.props.initialValues.CommitteeId, "ResponsibilityPrivilegeMappingId": list2Item3.ResponsibilityPrivilegeMappingId, "IsAdded": list2Item3.IsChecked });
                                        return list2Item3;
                                    })
                            })
                        })

                    })
                })
            })
            if (postData.length === 0) {
                postData.push({ OfficerTitleId: self.state.officerTitleId || self.props.linkedOfficerTitleId, CommitteeId: self.props.initialValues.CommitteeId, ResponsibilityPrivilegeMappingId: -1, IsAdded: false });
            }
        } else {
            self.setState({ updatedResponsibility });
        }

    }

}

export const changePrivilege = (responsibilityIndex, groupIndex, privilegesIndex, option) => {

    let updatedResponsibility = self.state.updatedResponsibility;
    switch (option) {
        case 'All':
            const checkAllStatus = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked;
            updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked = !checkAllStatus;
            if (updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked) {
                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.map((item) => {
                    item.IsChecked = true;
                    return item;
                })
            } else {
                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.map((item) => {
                    item.IsChecked = false;
                    return item;
                })
            }
            break;

        default:
            const checkStatus = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked;
            updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked = !checkStatus;

            if (option.toLowerCase().includes('update')) {
                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.map((item) => {
                    let count = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.filter((itemdetail) => itemdetail.ResponsibilityPrivilegeName.toLowerCase().includes('update') && item.ResponsibilityPrivilegeName.toLowerCase().includes('update') && itemdetail.ResponsibilityPrivilegeName.toLowerCase() != item.ResponsibilityPrivilegeName.toLowerCase() &&
                        itemdetail.IsChecked).length;

                    if (item.ResponsibilityPrivilegeName.toLowerCase().includes('view') && updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked &&
                        count === 0) {
                        item.IsChecked = !checkStatus;
                    }

                    return item;
                })
            } else if (option.toLowerCase().includes('add')) {

                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.map((item) => {

                    let addcount = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.filter((itemdetail) => itemdetail.ResponsibilityPrivilegeName.toLowerCase().includes('add') && item.ResponsibilityPrivilegeName.toLowerCase().includes('add') && itemdetail.ResponsibilityPrivilegeName.toLowerCase() != item.ResponsibilityPrivilegeName.toLowerCase() &&
                        itemdetail.IsChecked).length;

                    if (item.ResponsibilityPrivilegeName.toLowerCase().includes('view') && updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked &&
                        addcount === 0) {
                        item.IsChecked = !checkStatus;
                    }

                    return item;
                })
            }
            else if (option.toLowerCase().includes('view')) {

                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList = updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.map((item) => {
                    if (item.ResponsibilityPrivilegeName.toLowerCase().includes('add') || item.ResponsibilityPrivilegeName.toLowerCase().includes('update')) {
                        if (item.IsChecked && checkStatus) {
                            item.IsChecked = false
                        }

                    }

                    return item;
                })
            } else if (!updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked) {
                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[privilegesIndex].IsChecked = false;
            }
            if (updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.filter(item => !item.ResponsibilityPrivilegeName.toLowerCase().includes('all') && item.IsChecked).length === updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList.filter(item => item.ResponsibilityPrivilegeMappingId > 0).length) {
                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[0].IsChecked = true;
            } else {
                updatedResponsibility.ResponsibilityList[responsibilityIndex].ResponsibilityGroupList[groupIndex].ResponsibilityPrivilegeList[0].IsChecked = false
            }

            break;
    }

    self.setState({ updatedResponsibility })
}

export const onOfficerTitleChange = (value) => {

    const trimmedValue = value.trim();

    clearTimeout(TIMEOUT);

    self.setState({
        isLoading: trimmedValue !== '',
        showNoResultsMessage: false,
        updatedResponsibility: [],
        officerTitleId: 0
    });

    TIMEOUT = setTimeout(() => {

        if (self.props.deLinkOfficerList.length > 0) {
            let { deLinkOfficerList } = self.state;

            deLinkOfficerList = self.props.deLinkOfficerList.filter(item => {
                return item.OfficerTitleName.toLowerCase().indexOf(trimmedValue.toLowerCase()) >= 0;
            })

            self.setState({
                deLinkOfficerList,
                isLoading: false,
                showNoResultsMessage: deLinkOfficerList.length === 0
            });
        } else {
            self.setState({ isLoading: false, deLinkOfficerList: [], OfficerTitleId: 0, updatedResponsibility: [] });
        }

    }, 300);
}
export const validateHandler = (values, props) => {
    const errors = {};
    if (props.linkedOfficerTitleId === 0) {
        if (!values.OfficerTitle) {
            errors.OfficerTitle = utilCommon.getMessage(props.messageCodes, '8141.text')
        } else if (values.OfficerTitle && self.state.deLinkOfficerList.filter(item => item.OfficerTitleName.toLowerCase() === values.OfficerTitle.toLowerCase()).length == 0) {

            errors.OfficerTitle = utilCommon.getMessage(props.messageCodes, '8141.text')
        }
    }
    if (!values.UpdateReason) {
        errors.UpdateReason = utilCommon.getMessage(props.messageCodes, '8121.text')
    }
    return errors;
}
export const onSubmitHandler = (values, props) => {
    let responsibility = self.state.responsibility;
    getFinalResponsibility(responsibility.officerResponsibility, [], self.state.updatedResponsibility.ResponsibilityList || []);

    // Submit the form
    let requestData = {
        CommitteeId: self.props.initialValues.CommitteeId,
        IsPrivileges: true,
        Officers: self.props.deLinkOfficerList.filter(item => item.OfficerTitleId === self.state.officerTitleId).map(item => { return { OfficerTitleId: item.OfficerTitleId, IsLinked: true, IsApplicableAll: item.IsApplicableAll } }),
        Privileges: postData,
        OldPrivileges: formatOldPrivileges(),
        OfficerTitleName: values.OfficerTitle || self.props.selectedOfficerTitle,
        UpdateReason: values.UpdateReason
    }
    if (postData.length > 0 && self.props.linkedOfficerTitleId === 0) {
        self.props.postCommitteeOfficer(requestData, () => {
            self.props.showHide();
        })
    } else if (postData.length > 0 && self.props.linkedOfficerTitleId > 0) {
        self.props.manageCommitteeOfficerTitleExtendedResponsibility(requestData, () => {
            self.props.showHide();
        })

    }
}

const formatOldPrivileges = () => {
    let result = [];
    if (oldPrivileges && oldPrivileges.ResponsibilityList && oldPrivileges.ResponsibilityList.length > 0) {

        oldPrivileges.ResponsibilityList.map((item) => {

            if (item.ResponsibilityGroupList && item.ResponsibilityGroupList.length > 0) {

                item.ResponsibilityGroupList.map((group) => {
                    if (group.IsDisplay && group.ResponsibilityPrivilegeList && group.ResponsibilityPrivilegeList.length > 0) {

                        group.ResponsibilityPrivilegeList.map((privilege) => {
                            result.push({
                                OfficerTitleId: self.state.officerTitleId || self.props.linkedOfficerTitleId,
                                CommitteeId: self.props.initialValues.CommitteeId,
                                ResponsibilityPrivilegeMappingId: privilege.ResponsibilityPrivilegeId,
                                ResponsibilityPrivilegeName: privilege.ResponsibilityPrivilegeName,
                                ResponsibilityName: item.ResponsibilityName,
                                ResponsibilityGroupName: group.ResponsibilityGroupName,
                                ResponsibilityGroupId: group.ResponsibilityGroupId,
                                IsChecked: privilege.IsChecked
                            });
                        })
                    }
                })
            }
        })
    }

    return result;
}
