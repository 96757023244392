import { connect } from "react-redux";
import taskDetailsActions from '../actions/renewal-task-details.actions';
import taskActions from '../actions/renewal-task.actions';
import AdvanceSearchRenewalTask from '../components/advance-task-search';

const mapStateToProps = state => {
    return {
        usersList: state.taskDetailsReducer.usersList,
        statusList: state.commonReducer.statusList,
        renewalYearsList: state.renewalTaskReducer.renewalYearsList,
        userPermission: state.commonReducer.userPermission,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getUserList: (status) => taskDetailsActions.getAllAssignees(dispatch, status),
    getAllTaskStatus: () => taskDetailsActions.getAllTaskStatus(dispatch),
    getAllRenewalYears: () => taskActions.getAllRenewalYears(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchRenewalTask);
