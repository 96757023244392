import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid, Icon } from 'semantic-ui-react';
import MultipleButtonConfirmPopup from 'shared-components/CustomConfirmPopup/multipleButtonConfirmPopup';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import { AccountStatusOptions, ActiveMemberStatusId, PaidStatusOptions } from '../../members.model';
import AddGraduationDateModal from '../add-graduation-date-modal';
import AssociateOrganizationAccountModal from '../associate-organization-account-modal';
import StatusConfirmationModal from '../status-confirmation-modal';
import * as _func from './function';

class MemberEditAccountDetails extends Component {

    state = {
        selectedStatus: this.props.initialValues.McsStatusMasterId,
        showConfirmationModal: false,
        descMessage: '',
        showActiveRepWithActiveOrgConfirmation: false,
        historicalRadioLabel: '',
        activeRadioLabel: '',
        showAssociateOrgModal: false,
        showHistoricalRepWithOrgConfirmation: false,
        showAddGraduationDateModal: false
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { submitting, handleSubmit, initialValues, onCloseEditAccountInfo, historicalReasonList, messageCodes, hasUpdatePaidStatusAccess } = this.props;
        const { selectedStatus, descMessage, showConfirmationModal, showActiveRepWithActiveOrgConfirmation, historicalRadioLabel, activeRadioLabel, showAssociateOrgModal, showHistoricalRepWithOrgConfirmation, showAddGraduationDateModal } = this.state;

        return (
            <div data-testid="memberEditAccountDetails">
                <Form noValidate onSubmit={handleSubmit(_func.onSubmitHandler)}>

                    <h5>Member Account Status Details
                        <ul className="editDetailsBtn floatRight">
                            <li>
                                <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                    <Icon name="check" />
                                </button>
                            </li>
                            <li>
                                <button title="Cancel" className="closeBtn" onClick={onCloseEditAccountInfo}>
                                    <Icon name="close" />
                                </button>
                            </li>
                        </ul>
                    </h5>

                    <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name='McsStatusMasterId' label='Account Status'
                                    component={RenderSelect} required={true}
                                    value={selectedStatus}
                                    onChange={_func.onUpdateAccountStatus}
                                    options={AccountStatusOptions}
                                />
                            </Grid.Column>
                            {selectedStatus !== ActiveMemberStatusId && (
                                <Grid.Column>
                                    <Field name='HistoricalReasonId' label='Historical Reason'
                                        component={RenderSelect} required={true}
                                        options={historicalReasonList}
                                    />
                                </Grid.Column>
                            )}
                            {hasUpdatePaidStatusAccess &&
                                <Grid.Column>
                                    <Field name='PaidStatus' label='Paid Status'
                                        component={RenderSelect} required={true}
                                        disabled={initialValues.McsStatusMasterId === AccountStatusOptions[1].value}
                                        options={PaidStatusOptions}
                                    />
                                </Grid.Column>
                            }
                        </Grid.Row>
                    </Grid>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field component={TextArea} type='text' label='Reason for update'
                                    name='UpdateReason' required={true} maxLength='200'
                                    placeholder="Please enter reason for update"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                <Confirm className="confirm-box"
                    data-testid="confirm-box"
                    open={showConfirmationModal}
                    content={descMessage}
                    onCancel={_func.onCancelModal}
                    onConfirm={_func.onConfirmModal}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />

                {showActiveRepWithActiveOrgConfirmation && (
                    <StatusConfirmationModal descMessage={descMessage}
                        historicalRadioLabel={historicalRadioLabel} activeRadioLabel={activeRadioLabel}
                        messageCodes={messageCodes} closeModal={_func.onCloseConfirmationModal} />
                )}

                {showAssociateOrgModal && (
                    <AssociateOrganizationAccountModal {...this.props} closeModal={_func.onCloseAssociateOrgModal} />
                )}

                {showHistoricalRepWithOrgConfirmation && (
                    <MultipleButtonConfirmPopup message={descMessage} closeModal={_func.onCloseHistoricalRepWithOrgModal} />
                )}

                {showAddGraduationDateModal && (
                    <AddGraduationDateModal messageCodes={messageCodes} graduationDate={initialValues.GraduationDate}
                        closeModal={_func.onCloseAddGraduationDateModal} />
                )}
            </div>
        )
    }
}

export default reduxForm({
    form: 'MemberEditAccountDetails',
    validate: _func.validateHandler
})(MemberEditAccountDetails);
