import * as types from 'action-types';
import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import { encrypt, callAPI } from 'helpers/util-common';
import { MembershipTypes } from '../members.model';
import { API_PARAM_NULL_VALUE } from 'models/common.models';

// Get Auto Suggested Committee/No voting list in which member not present
const getAddMemberCommitteeMasterDataAction = (accountNumber, callback, dispatch) => {
    let committeeUrl = getApiUrl('manageMembers', 'getCommitteeAutoSearch') + `/${encrypt(accountNumber)}`;
    let noVoteurl = getApiUrl('manageMembers', 'getNoVoteReason') + `/${'sortColumn=description&sortOrder=asc'}`;
    dispatch(commonActions.setLoader(true));
    Promise.all([axios.get(committeeUrl), axios.get(noVoteurl)])
        .then(([committeResponse, noVoteResponse]) => {
            dispatch(commonActions.setLoader(false));
            let committeeList = [];
            let noVoteList = [];
            if (committeResponse.data.status) {
                committeeList = committeResponse.data.content ? committeResponse.data.content : [];
            }
            if (noVoteResponse.data.status) {
                noVoteList = noVoteResponse.data.content ? noVoteResponse.data.content : [];
            }
            dispatch({ type: types.GET_ADD_MEMBER_COMMITTEE_MASTER_DATA, committeeList, noVoteList });
            callback();
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3070'));
        })
}
const getAddMemberCommitteeSecondryMasterDataAction = (data, callback, dispatch) => {
    let classificationUrl = getApiUrl('manageMembers', 'getCommonCommitteeClassification');
    let officerTitleUrl = getApiUrl('manageMembers', 'getCommonOfficerTitle');
    Promise.all([axios.get(classificationUrl, { params: { requestData: data } }), axios.get(officerTitleUrl, { params: { requestData: data } })])
        .then(([classificationResponse, officerResponse]) => {
            let classificationList = [];
            let officerTitleList = [];
            if (classificationResponse.data.status) {
                classificationList = classificationResponse.data.content ? classificationResponse.data.content : [];
            }
            if (officerResponse.data.status) {
                officerTitleList = officerResponse.data.content ? officerResponse.data.content : [];
            }
            callback(classificationList, officerTitleList);
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));

        })
}
const getMemberPersonalDetailsAction = (accountNumber, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberPersonalDetails') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status && response.data.content &&
                response.data.content.MemberTypeId !== MembershipTypes.Organizational) { // To show Page not found when user paste the org account number in member details url
                dispatch({ type: types.GET_MEMBER_DETAILS, data: response.data.content });
            } else {
                // Handle error case
                dispatch({ type: types.SHOW_NOT_FOUND_PAGE });
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3046'));
        });
}

const getMemberMasterDataAction = (accountNumber, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberMasterData') + accountNumber;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: types.GET_MEMBER_MASTER_DATA, memberMasterDetails: response.data.content });
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3046'));
        });
}

const getMasterDataAction = (dispatch) => {
    let getMOUContactCodeListUrl = getApiUrl('manageMembers', 'getMOUContactCodeList');
    let getGovernmentTypeListUrl = getApiUrl('manageMembers', 'getGovernmentTypeList');
    let getInterestedCommitteeListUrl = getApiUrl('manageMembers', 'getInterestedCommitteeList');
    let getHistoricalReasonListUrl = getApiUrl('manageMembers', 'getHistoricalReasonList');
    let getMemberAuditLogFields = getApiUrl('manageMembers', 'getMemberAuditLogFieldList');

    Promise.all([axios.get(getMOUContactCodeListUrl), axios.get(getGovernmentTypeListUrl), axios.get(getInterestedCommitteeListUrl), axios.get(getHistoricalReasonListUrl), axios.get(getMemberAuditLogFields)])
        .then(([getMOUOContactCodeListResponse, getGovernmentTypeListResponse, getInterestedCommitteeListResponse, getHistoricalReasonListResponse, getMemberAuditLogFieldsResponse]) => {
            let mouContactCodeList = [];
            let governmentTypeList = [];
            let interestedCommitteeList = [];
            let historicalReasonList = [];
            let memberAuditLogFields = [];

            if (getMOUOContactCodeListResponse.data.status) {
                mouContactCodeList = getMOUOContactCodeListResponse.data.content;
            }
            if (getGovernmentTypeListResponse.data.status) {
                governmentTypeList = getGovernmentTypeListResponse.data.content;
            }
            if (getInterestedCommitteeListResponse.data.status) {
                interestedCommitteeList = getInterestedCommitteeListResponse.data.content;
            }
            if (getHistoricalReasonListResponse.data.status) {
                historicalReasonList = getHistoricalReasonListResponse.data.content;
            }
            if (getMemberAuditLogFieldsResponse.data.status) {
                memberAuditLogFields = getMemberAuditLogFieldsResponse.data.content;
            }

            dispatch({ type: types.GET_MASTER_DATA, mouContactCodeList, governmentTypeList, interestedCommitteeList, historicalReasonList, memberAuditLogFields });
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3050'));
        });
}

const getCommonCommitteeClassificationTypeAndNoVoteReasonAction = (committeeId, accountNumber, dispatch) => {
    let getCommonCommitteeClassificationTypeUrl = getApiUrl('manageMembers', 'getCommonCommitteeClassification') + `?requestData[]=${committeeId}`;
    let getNoVoteReasonUrl = getApiUrl('manageMembers', 'getNoVoteReason') + `/sortColumn=&sortOrder=asc`;
    let getValidateProducerClassificationUrl = getApiUrl('manageMembers', 'getValidateProducerClassification') + `/${encrypt(accountNumber)}/${committeeId}`;
    dispatch(commonActions.setLoader(true));
    Promise.all([axios.get(getCommonCommitteeClassificationTypeUrl), axios.get(getNoVoteReasonUrl), axios.get(getValidateProducerClassificationUrl)])
        .then(([getCommonCommitteeClassificationTypeResponse, getNoVoteReasonResponse, getValidateProducerClassificationResponse]) => {

            dispatch(commonActions.setLoader(false));
            let commonCommitteeClassificationTypeList = [];
            let noVoteReasonList = [];
            let validateProducerClassification = null;

            if (getCommonCommitteeClassificationTypeResponse.data.status) {
                commonCommitteeClassificationTypeList = getCommonCommitteeClassificationTypeResponse.data.content;
            }
            if (getNoVoteReasonResponse.data.status) {
                noVoteReasonList = getNoVoteReasonResponse.data.content;
            }
            if (getValidateProducerClassificationResponse.data.status) {
                validateProducerClassification = getValidateProducerClassificationResponse.data.content;
            }
            dispatch({ type: types.GET_COMMON_COMMITTEE_CLASSIFICATION_AND_NO_VOTE_REASON, commonCommitteeClassificationTypeList, noVoteReasonList, validateProducerClassification });
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3086'));
        });
}

const getMemberCommunicationLogAction = (accountNumber, pageSize, pageNumber, showLoader, callback, dispatch) => {
    let url = `${getApiUrl('manageMembers', 'getMemberCommunicationLogs')}/accountNumber=${encrypt(accountNumber)}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

    if (showLoader) {
        dispatch(commonActions.setLoader(true));
    }

    axios.get(url)
        .then((response) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }
            dispatch(commonActions.setMessage(true, '3057'));
        });
}

const getMemberUserListAction = (dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberUserList');

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: types.GET_MEMBER_USER_LIST, memberUserList: response.data.content });
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '7034'));
        });
}

export const getOrgRepActiveMappingListAction = (search, callback) => {
    const url = getApiUrl('manageMembers', 'getOrgRepActiveMappingList') + '/' + encrypt(search);
    let formattedList = [];

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                formattedList = response.data.content;
            }
            callback(formattedList);
        })
        .catch((err) => {
            callback(formattedList);
        })
}

const getMemberOrderHistoryAction = (accountNumber, callback, dispatch, joinDate, currentDate) => {
    let url = getApiUrl('manageMembers', 'getMemberOrderHistory') + `/${encrypt(accountNumber)}/${encrypt(joinDate)}/${encrypt(currentDate)}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content ? response.data.content : [])
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3059'));
        });
}

const getMemberAuditLogAction = (accountNumber, requestData, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberAuditLog') + `/${encrypt(accountNumber)}`;

    dispatch(commonActions.setLoader(true));

    axios.get(url, { params: { requestData: JSON.stringify(requestData) } })
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            let result = {};
            if (response.data.status) {
                result = response.data.content;
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
            callback(result);
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3040'));
            callback({});
        })
}

// Get MemberCommitteeInfo By AccountNo
const getMemberCommitteeInfoAction = (accountNumber, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberCommitteeInfo') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                let memberList = response.data.content;
                if (memberList && memberList.length > 0) {
                    let officerTitleIds = [];
                    memberList.map(item => {
                        if (item.OfficerTitleId && item.OfficerTitleId > 0) {
                            officerTitleIds.push(item.OfficerTitleId);
                        }
                    })
                    if (officerTitleIds && officerTitleIds.length > 0 && (officerTitleIds.filter(item => item > 0).length > 0)) {
                        const officerTitleURL = getApiUrl('manageMembers', 'getMemberOfficerDetail');
                        axios.post(officerTitleURL, officerTitleIds)
                            .then((officerTitleResponse) => {
                                if (officerTitleResponse.data.status) {
                                    let officerlist = officerTitleResponse.data.content || [];
                                    memberList.map(item => {
                                        if (item.OfficerTitleId && item.OfficerTitleId > 0) {
                                            item.OfficerTitleName = officerlist.find(item1 => item1.OfficerTitleId == item.OfficerTitleId) ? officerlist.find(item1 => item1.OfficerTitleId == item.OfficerTitleId).OfficerTitleName : '';
                                        }
                                        return item;
                                    })
                                }
                                callback(memberList ? memberList : [])
                                dispatch({ type: types.UPDATE_MEMBER_COMMITTEE_DETAILS, isUpdateMemberCommitteeDetails: false })
                            })
                    } else {
                        callback(response.data.content ? response.data.content : [])
                        dispatch({ type: types.UPDATE_MEMBER_COMMITTEE_DETAILS, isUpdateMemberCommitteeDetails: false })
                    }
                } else {
                    callback(response.data.content ? response.data.content : [])
                    dispatch({ type: types.UPDATE_MEMBER_COMMITTEE_DETAILS, isUpdateMemberCommitteeDetails: false })
                }
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3059'));
        });
}

// Get MemberCommittees By AccountNo
const getMemberMainCommitteesAction = (accountNumber, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberMainCommittees') + `/${accountNumber}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {

            if (response.data.status) {
                callback(response.data.content ? response.data.content : [])
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3059'));
        });
}

// Get Member's committee and sub-committee list
const getMemberMainSubCommitteesAction = (accountNumber, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberMainSubCommittee') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setminiLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setminiLoader(false));
            if (response.data.status) {
                callback(response.data.content ? response.data.content : [])
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setminiLoader(false));
            dispatch(commonActions.setMessage(true, '3070'));
        });
}

// For getting member's officer title detail
const getMemberOfficerTitleAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberOfficerDetail');
    axios.post(url, data)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content ? response.data.content : [])
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3069')); // Default exception error
        });
}

const getMemberAccountHistoryDetailsAction = (accountNumber, accountStatusAuditFieldName, paidStatusAuditFieldName, dispatch) => {
    const url = getApiUrl('manageMembers', 'getMemberAccountHistoryDetails') + encrypt(accountNumber);
    const fields = { field1: accountStatusAuditFieldName, field2: paidStatusAuditFieldName };
    dispatch(commonActions.setLoader(true));

    axios.get(url, { params: { fields: fields } })
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch({ type: types.GET_MEMBER_ACCOUNT_HISTORY_LIST, memberAccountHistoryList: response.data.content })
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3087'));
        });
}

// Get Active Member Committees By AccountNo
const getAssociatedCommitteeListAction = (accountNumber, callback, dispatch) => {
    const url = getApiUrl('manageMembers', 'getAssociatedCommitteeList') + accountNumber;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                if (response.data.content && response.data.content.length > 0) {
                    callback(response.data.content);
                } else {
                    let errorList = [];
                    errorList.push({
                        IsShowFromBackend: false,
                        MessageCode: 9066
                    })
                    dispatch(commonActions.showErrorPopup(true, errorList));
                }
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3131'));
            dispatch(commonActions.setLoader(false));
        });
}

const addCommunicationLogAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'saveMemberCommunicationLog');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2029'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3029')); // Default exception error
            return false;
        });
};

const addMemberCommitteeAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'saveMemberCommittee');
    dispatch(commonActions.setLoader(true));
    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2039'));
            } else {
                if (response.data.message == '3025') {
                    callback(response.data);
                }
                else {
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3082')); // Default exception error
            return false;
        });
}

const updateMemberDetailsAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'updateMemberDetails') + `/${encrypt(data.AccountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3051'));
        });
}

const updateMemberCommitteeDetailsAction = (data, accountNumber, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'updateMemberCommittee') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3085'));
        });
}

const updateMembershipTypeInfoAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'updateMembershipTypeInfo') + `/${encrypt(data.AccountNumber)}`;
    dispatch(commonActions.setLoader(true));
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3051'));
        });
};

const updateMemberCommunicationLogAction = (commentId, data, isOwnComment, callback, dispatch) => {
    let url = '';

    if (isOwnComment) {
        url = getApiUrl('manageMembers', 'updateOwnMemberCommunicationLog')
    } else {
        url = getApiUrl('manageMembers', 'updateMemberCommunicationLog')
    }

    url = `${url}/${commentId}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2030'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3030')); // Default exception error
        });
};

export const removeMemberCommunicationLogAction = (commentId, data, isOwnComment, callback, dispatch) => {
    let url = '';

    if (isOwnComment) {
        url = getApiUrl('manageMembers', 'removeOwnMemberCommunicationLog')
    } else {
        url = getApiUrl('manageMembers', 'removeMemberCommunicationLog')
    }

    url = `${url}/${commentId}`;
    dispatch(commonActions.setLoader(true));

    // Need to send it Put as the data is used in send mail
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2031'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((error) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3032')); // Default exception error
        });
};

const updateAccountDetailsAction = (accountNumber, data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'updateAccountDetails') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3051'));
        });
};

const updateRepAccountStatusAction = (accountNumber, data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'updateRepAccountStatus') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3051'));
        });
};

const updateResponsibilties = (data, dispatch) => {
    let url = getApiUrl('manageMembers', 'UpdateMemberCommitteeOfficerResponsibilities');
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3089'));
        });
}

// For getting member committee details
const getMemberCommitteeDetailsAction = (data, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberCommitteeDetails') + `/${encrypt(data.accountNumber)}/` + data.CommitteeId;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                if (response.data.content.TopSection.OfficerTitleId > 0) {
                    getMemberOfficerTitleAction([response.data.content.TopSection.OfficerTitleId], (result) => {

                        response.data.content.TopSection.OfficerTitleName = result[0].OfficerTitleName;
                        dispatch({ type: types.GET_MEMBER_COMMITTEE_DETAILS, memberCommitteeDetails: response.data.content });
                        dispatch(commonActions.setLoader(false));
                    }, dispatch);
                }
                else {
                    dispatch(commonActions.setLoader(false));
                    dispatch({ type: types.GET_MEMBER_COMMITTEE_DETAILS, memberCommitteeDetails: response.data.content });
                }
            } else {
                dispatch(commonActions.setLoader(false));
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setminiLoader(false));
            dispatch(commonActions.setMessage(true, '3073')); // Default exception error
        });
}
// Get member's committees list and vote list for update committees
const getMasterDataforUpdateCommitteeAction = (accountNumber, callback, dispatch) => {
    let classificationUrl = getApiUrl('manageMembers', 'getMemberCommitteeClassification') + `/${encrypt(accountNumber)}`;
    let noVoteurl = getApiUrl('manageMembers', 'getNoVoteReason') + `/${'sortColumn=description&sortOrder=asc'}`;
    let noReasonurl = getApiUrl('manageMembers', 'getInactiveReasonList') + `${encrypt(accountNumber)}/0`;
    Promise.all([axios.get(classificationUrl), axios.get(noVoteurl), axios.get(noReasonurl)])
        .then(([classificationResponse, voteResponse, noReasonResponse]) => {
            let classificationList = [];
            let voteTitleList = [];
            let noReasonList = [];
            if (classificationResponse.data.status) {
                classificationList = classificationResponse.data.content ? classificationResponse.data.content : [];
            }
            if (voteResponse.data.status) {
                voteTitleList = voteResponse.data.content ? voteResponse.data.content : [];
            }
            if (noReasonResponse.data.status) {
                noReasonList = noReasonResponse.data.content ? noReasonResponse.data.content : [];
            }
            callback(classificationList, voteTitleList, noReasonList);
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
        })
}
// Get Officer Title List And Inactive Reason List for update member committee details
const getOfficerTitleListAndInactiveReasonListAction = (data, dispatch) => {
    const officerTitleURL = getApiUrl('manageMembers', 'getOfficerTitleList') + `?requestData[]=${data.CommitteeId}`;
    const inactiveReasonURL = getApiUrl('manageMembers', 'getInactiveReasonList') + `${encrypt(data.accountNo)}/${data.CommitteeId}`;
    dispatch(commonActions.setLoader(true));

    Promise.all([axios.get(officerTitleURL), axios.get(inactiveReasonURL)])
        .then(([officerTitleResponse, inactiveReasonResponse]) => {
            dispatch(commonActions.setLoader(false));
            let officerTitleList = [];
            let inactiveReasonList = [];

            if (officerTitleResponse.data.status) {
                officerTitleList = officerTitleResponse.data.content ? officerTitleResponse.data.content : [];
            }
            if (inactiveReasonResponse.data.status) {
                inactiveReasonList = inactiveReasonResponse.data.content ? inactiveReasonResponse.data.content : [];
            }
            dispatch({ type: types.GET_OFFICER_TITLE_AND_INACTIVE_REASONS_LIST, officerTitleList, inactiveReasonList });
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3073')); // Default exception error
        })
}
const getMemberCommitteeListBultEditAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'memberCommitteeListBulkEdits');
    dispatch(commonActions.setLoader(true));
    data.accountNumber = encrypt(data.accountNumber)
    axios.put(url, data)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content ? response.data.content : [])
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setminiLoader(false));
            dispatch(commonActions.setMessage(true, '3073')); // Default exception error
        });
}

const getOfficerResponsibilitiesAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getOfficerResponsibilities');
    axios.post(url, data)
        .then((response) => {
            if (response.data.status) {
                dispatch(commonActions.setLoader(false));
                callback(response.data.content);
            } else {
                dispatch(commonActions.setLoader(false));
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3087'));
        });
}
const getImpactedCommitteeListAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getImpactedCommitteeList');
    data.AccountNo = encrypt(data.AccountNo);
    dispatch(commonActions.setLoader(true));
    axios.post(url, data)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content ? response.data.content : [])
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3073')); // Default exception error
        });
}
const updateMemberCommitteesAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'putMemberCommittees');
    data.AccountNumber = encrypt(data.AccountNumber)
    dispatch(commonActions.setLoader(true));
    axios.put(url, data)
        .then(response => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback()
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3091')); // Default exception error
        });
}
const getMemberCommitteeHistoryDetailsAction = (accountNumber, committeeId, dispatch) => {
    const url = getApiUrl('manageMembers', 'getMemberCommitteeHistoryDetails') + encrypt(accountNumber) + '/' + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch({ type: types.GET_MEMBER_COMMITTEE_HISTORY_LIST, memberCommitteeHistoryList: response.data.content })
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, ''));
        });
}
const getMemberCommitteeOfficerTitleHistoryDetailsAction = (accountNumber, committeeId, dispatch) => {
    const url = getApiUrl('manageMembers', 'getMemberCommitteeOfficerTitleHistoryDetails') + encrypt(accountNumber) + '/' + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch({ type: types.GET_MEMBER_COMMITTEE_OFFICER_TITLE_HISTORY_LIST, memberCommitteeOfficerTitleHistoryList: response.data.content })
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, ''));
        });
}
const updateMemberCommitteeBasicDetailsAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'updateCommitteeMemberBasicInformation');
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            //dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                // Update officer title in Secbuilder
                if (data.OfficerTitle != data.OfficerTitleSelectedValue) {
                    updateOfficerTitleInSpecbuilder(data.OfficerTitle, data.OfficerTitleSelectedValue,
                        data.CommitteeDesignation, data.SubCommitteeDesignation, data.AccountNumber,
                        response.data.content, dispatch);
                }
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                if (response.data.message == '3025') {
                    callback(response.data);
                }
                else {
                    // Handle error case
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3096'));
        });
}
const putMemberCommitteeGeneralInfoAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'putMemberCommitteeGeneralInfo');
    dispatch(commonActions.setLoader(true));
    data.AccountNumber = encrypt(data.AccountNumber)
    if (!data.IsdidNotChange) {

        axios.put(url, data)
            .then((response) => {
                // dispatch(commonActions.setLoader(false));
                if (response.data.status) {
                    dispatch(commonActions.setMessage(true, '2001'));
                    callback();
                } else {
                    // Handle error case
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            })
            .catch((err) => {
                dispatch(commonActions.setLoader(false));
                dispatch(commonActions.setMessage(true, '3101'));
            });
    } else {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '2040'));
        callback();
    }

}

export const getActivityList = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'getPrimaryActivities') + data;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3125'));
        });
}

const updateCommitteeActivityInfo = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'updateCommitteeActivityInfo');
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3124'));
        });
}

const getActiveFeeGroupList = (callback, dispatch) => {
    const url = getApiUrl('manageMembers', 'getActiveFeeGroupList');
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8158'));
        });
}

const getCompanyByMemberAccountNumber = (accountNumber, callback, dispatch) => {
    const url = getApiUrl('manageMembers', 'getCompanyByMemberAccountNumber') + `/${encrypt(accountNumber)}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3145'));
        });
}

const getCompanyAssociatedMembersDetails = (payload, callback, dispatch) => {
    const url = getApiUrl('manageMembers', 'getCompanyAssociatedMembersDetails');
    payload.companyId = encrypt(payload.companyId);
    dispatch(commonActions.setLoader(true));

    axios.post(url, payload)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3146'));
        });
}

const checkIsCompanyAndAddressExistsAction = (payload, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'checkIsCompanyAndAddressExists');
    dispatch(commonActions.setLoader(true));
    axios.post(url, payload)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3147'));
        });
}

const checkIsHistoricalCompanyAndAddressExistsAction = (payload, flag, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'checkIsHistoricalCompanyAndAddressExists');
    dispatch(commonActions.setLoader(true));
    let data = { ...payload, flag };
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3147'));
        });
}

const updateDemographicInfoAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageMembers', 'updateDemographicInfo');
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3144'));
        });
}


const updateCompanyInfoAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageMembers', 'updateCompanyInfo');
    dispatch(commonActions.setLoader(true));
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                if (response.data.content?.isQueryForVerifyingAddress === undefined) {
                    dispatch(commonActions.setMessage(true, '2001'));
                }
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8165'));
        });
}

const getFeeGroupTabDetailAction = (feeGroupId, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMemberFeeGroupTabDetail') + `/${encrypt(feeGroupId)}`;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8151'));
        });
};

const getMembershipTypeInfoByMemberTypeIdAction = (MemberTypeId, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getMembershipTypeInfoByMemberTypeId') + MemberTypeId;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '8166'));
        });
};

const getMemberDetailAction = (memberId, callback) => {
    let url = getApiUrl('manageMembers', 'getMemberDetail') + memberId;

    axios.get(url)
        .then((response) => {
            if (response.data.status && response.data.content) {
                callback(`${response.data.content.LastName}, ${response.data.content.FirstName}`)
            } else {
                callback();
            }
        })
        .catch((err) => {
            callback();
        });
}

const getAssociatedMembersAction = (accountNumber, requestData, companyId = 0, callback, dispatch) => {
    const apiParamNullValue = API_PARAM_NULL_VALUE;
    const paramValueAccountNumber = accountNumber ? accountNumber : apiParamNullValue;
    let url = getApiUrl('manageMembers', 'getOrganizationAssociatedMember') + `/${encrypt(paramValueAccountNumber)}/${encrypt(companyId)}`;

    axios.get(url, { params: { requestData: JSON.stringify(requestData) } })
        .then((response) => {
            let result = {};
            if (response.data.status) {
                result = response.data.content;
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
            callback(result);
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3151'));
            callback({});
        })
}

const updateOfficerTitleInSpecbuilder = (OfficerTitle, officerTitleSelectedValue,
    CommitteeDesignation, SubCommitteeDesignation, AccountNumber, req, dispatch) => {
    let oldAccountNumber = officerTitleSelectedValue === "" ? '' : AccountNumber;
    let newAccountNumber = OfficerTitle === "" ? '' : AccountNumber;
    let officerTitle = OfficerTitle === "" ? officerTitleSelectedValue : OfficerTitle;

    let updateOfficerTitle = {
        groupMainCommitteeDesignation: CommitteeDesignation,
        groupSubCommitteeDesignation: SubCommitteeDesignation,
        transfers: [
            { "officerTitle": officerTitle, oldOfficerAccountNumber: oldAccountNumber, newOfficerAccountNumber: newAccountNumber }
        ]
    };

    axios.defaults.withCredentials = true;
    const url = process.env.REACT_APP_SPECBUILDER_CONNECT_HOST;
    axios.post(`${url}account/switch?accountId=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`, '', req.OKTA_Token)
        .then(response => {
            axios.defaults.withCredentials = true;
            axios.post(`${url}groups/work-item/transfer-group-members?accountID=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`, updateOfficerTitle, req.OKTA_Token)
                .then((specResponse) => {
                    dispatch(commonActions.setLoader(false));
                }).catch((error) => {
                    dispatch(commonActions.setLoader(false));
                });
        });
}

export const getMemberAccountNumbers = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getMemberAccountNumbers');
    dispatch(commonActions.setLoader(true));
    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            callback()
            dispatch(commonActions.setMessage(true, '3126'));
        });
}

export default {
    getMemberPersonalDetailsAction,
    getAddMemberCommitteeMasterDataAction,
    getAddMemberCommitteeSecondryMasterDataAction,
    getImpactedCommitteeListAction,
    getMasterDataAction,
    getMemberMasterDataAction,
    getMemberCommunicationLogAction,
    getMemberOrderHistoryAction,
    getMemberUserListAction,
    getOrgRepActiveMappingListAction,
    getMemberAuditLogAction,
    getMemberCommitteeInfoAction,
    getMemberMainCommitteesAction,
    getMemberMainSubCommitteesAction,
    getMemberOfficerTitleAction,
    getMemberCommitteeDetailsAction,
    getCommonCommitteeClassificationTypeAndNoVoteReasonAction,
    getMasterDataforUpdateCommitteeAction,
    getMemberAccountHistoryDetailsAction,
    getMemberCommitteeListBultEditAction,
    getMemberCommitteeHistoryDetailsAction,
    getMemberCommitteeOfficerTitleHistoryDetailsAction,
    getAssociatedCommitteeListAction,
    getActiveFeeGroupList,
    addCommunicationLogAction,
    addMemberCommitteeAction,
    putMemberCommitteeGeneralInfoAction,
    updateMembershipTypeInfoAction,
    updateMemberCommunicationLogAction,
    updateMemberCommitteesAction,
    removeMemberCommunicationLogAction,
    updateAccountDetailsAction,
    updateRepAccountStatusAction,
    updateMemberDetailsAction,
    updateMemberCommitteeDetailsAction,
    getOfficerResponsibilitiesAction,
    updateResponsibilties,
    updateMemberCommitteeBasicDetailsAction,
    getOfficerTitleListAndInactiveReasonListAction,
    getActivityList,
    getCompanyByMemberAccountNumber,
    getCompanyAssociatedMembersDetails,
    checkIsCompanyAndAddressExistsAction,
    checkIsHistoricalCompanyAndAddressExistsAction,
    updateCommitteeActivityInfo,
    updateDemographicInfoAction,
    updateCompanyInfoAction,
    getFeeGroupTabDetailAction,
    getMembershipTypeInfoByMemberTypeIdAction,
    getMemberDetailAction,
    getAssociatedMembersAction,
    getMemberAccountNumbers
};
