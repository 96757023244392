// @flow

// https://github.com/diegohaz/arc/wiki/Reducers
// import { Reducers } from 'react-redux-grid';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';
import commonReducer from './common.reducer';
import manualOrderReducer from './modules/admin/manual-order/manual-order.reducer';
import reducerRole from './modules/admin/manage-roles/role.reducer';
import reducerUser from './modules/admin/manage-user/user.reducer';
import committeeDetailsReducer from './modules/committee-management/reducers/committee-details.reducer';
import committeesReducer from './modules/committee-management/reducers/committees.reducer';
// Custom components reducers
import loginReducer from './modules/login/login.reducer';
import feeGroupReducer from './modules/member-management/fee-group/reducers/fee-group.reducer';
import memberDetailsReducer from './modules/member-management/members/reducers/member-details.reducer';
import memberReducer from './modules/member-management/members/reducers/members.reducer';
import organizationDetailsReducer from './modules/member-management/organizations/reducers/organization-details.reducer';
import organizationReducer from './modules/member-management/organizations/reducers/organizations.reducer';
import taskDetailsReducer from './modules/renewal-task/reducers/renewal-task-details.reducer';
import renewalTaskReducer from './modules/renewal-task/reducers/renewal-task.reducer';
import workItemDetailsReducer from './modules/work-item-admin/work-item-details.reducer';

// const gridReducers = {
//   dataSource: Reducers.dataSource,
//   editor: Reducers.editor,
//   errorHandler: Reducers.errorHandler,
//   grid: Reducers.grid,
//   loader: Reducers.loader,
//   menu: Reducers.menu,
//   pager: Reducers.pager,
//   selection: Reducers.selection
// };

// App initial state
const reducers = {
  i18nReducer,
  form,
  loginReducer,
  commonReducer,
  taskDetailsReducer,
  renewalTaskReducer,
  // ...gridReducers,
  reducerUser,
  reducerRole,
  memberReducer,
  memberDetailsReducer,
  organizationReducer,
  organizationDetailsReducer,
  committeesReducer,
  committeeDetailsReducer,
  feeGroupReducer,
  workItemDetailsReducer,
  manualOrderReducer
};

export default combineReducers(reducers);
