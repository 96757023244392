import { checkIfDateValid, compareDates, dateMasking, formatDateToMMDDYYYY, getMessage } from 'helpers/util-common';
import { minDateForInputDateFields } from 'models/common.models';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import DateTimePicker from 'shared-components/DatePicker';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea';
import CustomTooltip from '../../../../shared-components/Tooltip';


const validateHandler = (values, props) => {
    let errors = {};
    if (!values.MeetingDate) {
        errors.MeetingDate = getMessage(props.messageCodes, '4020.text');
    } else if (!checkIfDateValid(values.MeetingDate, 'MM/DD/YYYY')) {
        errors.MeetingDate = getMessage(props.messageCodes, '4003.text');
    } else if (compareDates(minDateForInputDateFields, values.MeetingDate)) {
        errors.MeetingDate = getMessage(props.messageCodes, '4021.text');
    }
    return errors;
}

class AddEditCommitteeMeetingDateModal extends PureComponent {
    state = {
        deadLineDateRowArray: [],
        isDisabledDeadLineDate: true,
        isMeetingDateCleaned: false,
        isConfirmationPopUpOpen: false,
        meetingDate: formatDateToMMDDYYYY(),
        previousMeetingDate: formatDateToMMDDYYYY(),
        maxDeadLineDate: formatDateToMMDDYYYY(),
        isActive: false
    }
    componentDidMount() {
        if (this.props.action === 'edit') {
            let { meetingData } = this.props;
            let meetingDateValue = formatDateToMMDDYYYY(meetingData.MeetingDate);
            let isActive = meetingData.IsActive;
            this.props.change('MeetingDate', meetingDateValue);
            // Logic for update dead line date mapped data for payload.
            meetingData.CommitteeMeetingBallotDeadlineDate.map((items, index) => {
                items.isError = false;
                items.BallotDeadlineDate = moment(items.BallotDeadlineDate).format('MM/DD/YYYY');
                this.state.deadLineDateRowArray.push(items);
                this.datePickerAllowReadOnly(`DeadLineDate_${index}`);
                return items;
            });
            // If no Data found in the list.
            if (this.state.deadLineDateRowArray.length === 0) {
                this.state.deadLineDateRowArray.push({ isError: false, BallotDeadlineDate: null });
            }
            // If Item length more than zero then auto disabled.
            let isDisable = true;
            if (this.isDeadLineDateFourteenDaysDiffMatched(meetingDateValue)) {
                isDisable = false;
            }
            this.setState({
                isDisabledDeadLineDate: isActive == false ? true : isDisable,
                meetingDate: meetingDateValue,
                isActive: isActive
            })
        } else {
            // Add Action -> Default deadline date row on page load.
            this.state.deadLineDateRowArray.push({ isError: false, BallotDeadlineDate: null });
            // Allow Read Only props in date picker for zero index
            this.datePickerAllowReadOnly(`DeadLineDate_${0}`);
        }
        // Allow Read Only props in date picker for meeting date.
        this.datePickerAllowReadOnly('MeetingDate');
    }

    prepareDeadLineDatesForAction = () => {
        // Prepare deadline date data for payloads.
        const { deadLineDateRowArray } = this.state;
        const finalDeadLineDateResult = [...deadLineDateRowArray];
        return finalDeadLineDateResult.filter(items => items && items.BallotDeadlineDate && moment(formatDateToMMDDYYYY(items.BallotDeadlineDate), 'MM/DD/YYYY', true).isValid());
    }

    onSubmitHandler = (data) => {
        const { deadLineDateRowArray } = this.state;
        const isValidDeadLine = deadLineDateRowArray.filter(x => x && x.isError === true);
        if (isValidDeadLine && isValidDeadLine.length) {
            return false;
        }
        // Get Prepared data for deadline date.
        const deadLineDatesResult = this.prepareDeadLineDatesForAction();
        if (this.props.action === 'add') {
            let requestData = {
                CommitteeId: this.props.initialValues.CommitteeId,
                MeetingDate: data.MeetingDate,
                CommitteeMeetingBallotDeadlineDate: deadLineDatesResult
            }
            this.props.addCommitteeMeetingDate(requestData, () => {
                this.props.onSubmit();
            })
        } else {
            let { MeetingDateId, MeetingDate: oldMeetingDate, IsActive: oldIsActive, CommitteeMeetingBallotDeadlineDate } = this.props.meetingData;
            // IsActive is coming as undefined if there is no changes made to radio
            let newIsActive = data.IsActive ? data.IsActive === 'true' : oldIsActive;
            if (data.MeetingDate !== formatDateToMMDDYYYY(oldMeetingDate) || newIsActive !== oldIsActive || JSON.stringify(CommitteeMeetingBallotDeadlineDate) !== JSON.stringify(deadLineDatesResult)) {
                let requestData = {
                    CommitteeId: this.props.initialValues.CommitteeId,
                    MeetingDate: data.MeetingDate,
                    IsActive: newIsActive,
                    UpdateReason: data.UpdateReason,
                    CommitteeMeetingBallotDeadlineDate: deadLineDatesResult
                }
                this.props.updateCommitteeMeetingDate(MeetingDateId, requestData, () => {
                    this.props.onSubmit();
                })
            } else {
                this.props.displayNoChangesMadeMessage();
                this.props.onClose();
            }
        }
    }

    addDeadLineDateHandler = () => {
        // Logic for add row in meeting popup.
        let count = this.state.deadLineDateRowArray.length;
        if (count <= 5) {
            this.datePickerAllowReadOnly(`DeadLineDate_${count}`);// Allow Read Only props in date picker
            this.setState(prevState => ({
                deadLineDateRowArray: [...prevState.deadLineDateRowArray, { isError: false, BallotDeadlineDate: null }]
            }), () => {
                // Get Count of updated deadline date row count.
                count = this.state.deadLineDateRowArray.length;
                if (count > 1) {
                    setTimeout(() => this.props.change(`DeadLineDate_${count - 1}`, ''), 50);
                }
            });

        }
    }

    removeDeadLineDateHandler = (index) => {
        // Logic for remove row in meeting popup
        if (this.state.deadLineDateRowArray.length > 0) {
            const items = [...this.state.deadLineDateRowArray];
            items.splice(index, 1);
            this.setState({ deadLineDateRowArray: items }, () => {
                setTimeout(() => this.props.change(`DeadLineDate_${index}`, ''), 100);
            });
        }
    }

    validateIsChangeAppliedInDeadLineDate = (isRemoved = false) => {
        // Validation to check whether it is for update or remove in onChange handler.
        const { deadLineDateRowArray } = this.state;
        const dateList = deadLineDateRowArray.filter(items => items && items.BallotDeadlineDate && moment(formatDateToMMDDYYYY(items.BallotDeadlineDate), 'MM/DD/YYYY', true).isValid());
        if (dateList && dateList.length) {
            this.setState({ isMeetingDateCleaned: false, isConfirmationPopUpOpen: true });
        }
    }

    datePickerAllowReadOnly = (id) => {
        setTimeout(() => {
            const selector = document.querySelector(`#${id}`);
            if (id && selector) {
                selector.setAttribute('readOnly', true)
            }
        }, 50);
    }

    onChangeMeetingDate = (date) => {
        // Disaled row based on meeting date.
        const { isActive, meetingDate } = this.state;
        const { action } = this.props;
        let isDisabled = true;
        if (date && this.isDeadLineDateFourteenDaysDiffMatched(date)) {
            isDisabled = false;
        } else {
            isDisabled = true;
        }
        // Set value into state variable.
        this.setState({
            isDisabledDeadLineDate: (action === 'edit' && isActive === false) ? true : isDisabled,
            meetingDate: date,
            previousMeetingDate: meetingDate
        }, () => {
            this.validateIsChangeAppliedInDeadLineDate(isDisabled)
        });
    }

    isDeadLineDateFourteenDaysDiffMatched = (date) => {
        // To get diff for meeting date and current date
        const { meetingDate } = this.state;
        const currentDate = formatDateToMMDDYYYY();
        if (meetingDate && moment(date).diff(currentDate, 'days') > 14) {
            return true
        }
        return false;
    }

    onChangeDeadLineDateHandler = (date, index) => {
        const { deadLineDateRowArray } = this.state;
        let listItemDate = [...deadLineDateRowArray];
        const isDeadLineDateId = listItemDate && listItemDate.length && listItemDate[index].hasOwnProperty('BallotDeadlineDateId');
        // If criteria match of 14 days of meeting and deadline date.
        if (index > -1) {
            const isExist = listItemDate.filter((x, i) => (x && x.BallotDeadlineDate === date));
            if (isExist && isExist.length) {
                setTimeout(() => this.props.change(`DeadLineDate_${index}`, ''), 100);
            } else if (this.isDeadLineDateFourteenDaysDiffMatched(date)) {

                listItemDate[index] = isDeadLineDateId ? { isError: false, BallotDeadlineDate: date, BallotDeadlineDateId: listItemDate[index].BallotDeadlineDateId } : { isError: false, BallotDeadlineDate: date };
            } else {
                listItemDate[index] = isDeadLineDateId ? { isError: true, BallotDeadlineDate: date, BallotDeadlineDateId: listItemDate[index].BallotDeadlineDateId } : { isError: true, BallotDeadlineDate: date };
            }
            this.setState({
                deadLineDateRowArray: listItemDate
            });
        }
    }

    onConfirmHandler = (isDisabled = null) => {
        // Confirmation whether needs to reset all the data.
        const { deadLineDateRowArray } = this.state;
        let deadLineItems = [...deadLineDateRowArray];
        setTimeout(() => {
            deadLineDateRowArray.map((x, i) => this.props.change(`DeadLineDate_${i}`, ''))
        }, 50);
        deadLineItems = [{ isError: false, BallotDeadlineDate: null }];
        this.setState({
            deadLineDateRowArray: deadLineItems,
            isConfirmationPopUpOpen: false,
            isDisabledDeadLineDate: isDisabled !== null ? isDisabled : false
        })
    }

    onCancelHandler = () => {
        const { previousMeetingDate } = this.state;
        // Cancel handler for none of operation performed.
        this.setState({ isConfirmationPopUpOpen: false, meetingDate: previousMeetingDate }, () => {
            this.props.change('MeetingDate', previousMeetingDate);
        });
    }

    onRadioChangeHandler = (e) => {
        const { value } = e.target;
        let isActive = false;
        // Radio Change handlers.
        const { meetingDate } = this.state;
        if (value === 'true') {
            isActive = true;
            const isEnabled = this.isDeadLineDateFourteenDaysDiffMatched(meetingDate);
            this.onConfirmHandler(!isEnabled);
        } else {
            isActive = false;
            this.onConfirmHandler(true);
        }
        this.setState({ isActive: isActive })
    }

    render() {
        const { submitting, handleSubmit, change, onClose, action, meetingData, messageCodes } = this.props;
        const { deadLineDateRowArray, isDisabledDeadLineDate, isMeetingDateCleaned, isConfirmationPopUpOpen, meetingDate } = this.state;
        let headerText = action === 'add' ? 'ADD MEETING DATE' : 'UPDATE MEETING DATE';
        // Get one day back date of selection date.
        const meetingDateOneDayBack = formatDateToMMDDYYYY(moment(meetingDate).subtract(1, "days"));

        return (
            <Modal open='true' className="tiny">
                <Form size='large' onSubmit={handleSubmit(this.onSubmitHandler)}>
                    <Modal.Header>{headerText} <i onClick={onClose} className="close" title="close">&#x2716;</i></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="MeetingDate"
                                            component={DateTimePicker}
                                            showTime={false}
                                            normalize={dateMasking}
                                            onChange={this.onChangeMeetingDate}
                                            onKeyPress={this.onChangeMeetingDate}
                                            label="Meeting Date"
                                            required={true}
                                            popupPosition="right center"
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {action === 'edit' && (
                                            <Field name="IsActive"
                                                component={RenderRadioField}
                                                className="mr10"
                                                label="Status"
                                                type="radio" change={change}
                                                onChange={this.onRadioChangeHandler}
                                                options={[{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]}
                                                selectedValue={meetingData.IsActive ? 'true' : 'false'}
                                            />
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ alignItems: 'flex-end' }} className={`${isDisabledDeadLineDate === true ? 'disabledRow' : ''}`} >
                                    <Grid.Column>
                                        <label className="label" htmlFor="DeadLineDate">Ballot Deadline Date
                                            <CustomTooltip
                                                content={`You can select Maximum 6 Ballot Deadline Dates for any Meeting Date. The email notification will be sent to all Subcommittee Chairs of the selected Committee, with cc to Staff Manager and Administrative Assistant, 2 weeks prior to the Deadline Date entered.`}
                                                icon={<Icon name="info circle" className="ml5 vTextTop pointer" />} />
                                        </label>
                                    </Grid.Column>
                                    <Grid.Column className="txtRight">
                                        {deadLineDateRowArray.length <= 5 &&
                                            <button type="button" title="Add" className="ui secondary button" onClick={this.addDeadLineDateHandler}>
                                                <Icon name="plus" /> Add Ballot Deadline Date
                                            </button>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid columns='equal' style={{ maxHeight: '280px', overflow: 'auto' }} className='mt0'>
                                {deadLineDateRowArray.map((item, index) => {
                                    // Generate Row based on deadline date.
                                    return (
                                        <Grid.Row className={`addDateRow ${isDisabledDeadLineDate ? 'disabledRow' : ''}`} key={index} style={{ borderTop: (index === 0) ? 'solid 1px #ccc' : 'none' }}>
                                            <Grid.Column>
                                                <Field name={`DeadLineDate_${index}`}
                                                    component={DateTimePicker}
                                                    key={index}
                                                    showTime={false}
                                                    defaultValue={item ? item.BallotDeadlineDate : ''}
                                                    isDuplicate={item.isError === true}
                                                    duplicateError={item.isError === true ? getMessage(messageCodes, '2063.text') : ''}
                                                    maxDate={meetingDateOneDayBack}
                                                    onChange={(date) => this.onChangeDeadLineDateHandler(date, index)}
                                                />
                                            </Grid.Column>
                                            <Grid.Column className="txtRight">
                                                {deadLineDateRowArray.length > 1 &&
                                                    <button type="button" title="Remove" className="closeBtn" data-testid="removeBtn" onClick={(e) => this.removeDeadLineDateHandler(index)}>
                                                        <Icon name="close" style={{ lineHeight: '20px' }} />
                                                    </button>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                })}
                            </Grid>
                            <Grid columns='equal'>
                                {action === 'edit' && (
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field component={TextArea} type='text' label='Reason for update'
                                                name='UpdateReason' required={false} maxLength='200'
                                                placeholder="Please enter reason for update"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="mt10">
                            <button className="ui button primary" disabled={submitting} type="submit">{action === 'add' ? 'Save' : 'Update'}</button>
                            <button className="ui button cancel" onClick={onClose}>Cancel</button>
                        </div>
                    </Modal.Actions>
                </Form>
                <Confirm className="confirm-box"
                    open={isConfirmationPopUpOpen}
                    content={getMessage(messageCodes, isMeetingDateCleaned ? '2062.text' : '2061.text')}
                    onCancel={this.onCancelHandler}
                    onConfirm={(e) => this.onConfirmHandler(null)}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </Modal >
        )
    }
}

export default reduxForm({
    form: 'AddEditCommitteeMeetingDateModal',
    validate: validateHandler
})(AddEditCommitteeMeetingDateModal);
