import React from 'react';
import { Modal, Grid } from 'semantic-ui-react';

const StudentInfoModal = (props) => (

    <Modal open='true' className="tiny">
        <Modal.Header>Student Information<i onClick={props.closeModal} className="close" title="close">&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Grid columns='equal' className="stackable">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label className="label2">Major</label>
                            <span className="labelData">{props.data.StudyField}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <label className="label2">Graduation Date</label>
                            <span className="labelData">{props.data.GraduationDate}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label className="label2">University/College/Institution</label>
                            <span className="labelData">{props.data.FacilityName}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <label className="label2">Degree Sought</label>
                            <span className="labelData">{props.data.Degree}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label className="label2">Committees of Interest</label>
                            <span className="labelData">{props.data.InterestedCommittee ? props.data.InterestedCommittee.join(',') : ''}</span>
                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Modal.Description>
        </Modal.Content>
    </Modal >
)

export default StudentInfoModal;
