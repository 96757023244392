import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Grid } from 'semantic-ui-react';
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import RenderInputField from 'shared-components/Input';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import { formatRenewalYearsList } from '../../function';
import * as _func from './function';

class AdvanceSearchRenewalTask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isResetClicked: false
        }

        this.resetForm = this.resetForm.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    componentWillMount() {
        _func.loadInitialData(this);
    }

    resetForm() {
        _func.resetForm(this);
    }

    resetState() {
        this.setState({ isResetClicked: false });
    }


    render() {
        // eslint-disable-next-line no-unused-vars
        const { handleSubmit, initialValues, usersList, statusList, renewalYearsList } = this.props;
        const { isResetClicked } = this.state;

        return (
            <Form onSubmit={handleSubmit} noValidate data-testid="advanceSearchRenewalTask">
                <Grid columns='equal' className="mt0">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="TaskUniqueCode" type="text"
                                component={RenderInputField} label="Task ID"
                                placeholder="Task ID"
                                maxLength="100"
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <Field name="Title" type="text"
                                component={RenderInputField} label="Title"
                                placeholder="Title"
                                maxLength="150" />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name='Status'
                                component={DropdownMultipleSelection}
                                label='Status'
                                placeholder="All"
                                options={_func.formatStatusList(statusList)}
                                isMultiple={true}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="mb10">
                        <Grid.Column className="renewal-year">
                            <Field name="RenewalYear"
                                component={DropdownMultipleSelection}
                                label="Renewal Year"
                                isSinglItemPrevent={true}
                                placeholder="Renewal Year"
                                options={formatRenewalYearsList(renewalYearsList)}
                                isMultiple={true}
                            />
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <Field name="Assignee"
                                component={DropdownMultipleSelection} label="Assignee"
                                isSearch={true}
                                isMultiple={false}
                                isDefaultValue={true}
                                placeholder="All"
                                options={_func.renderUserList(usersList)}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <label className="label">&nbsp;</label>
                            <Field name="ShowInactiveTasks" type="checkbox"
                                component={ReduxCheckboxElement} label="Show Inactive Tasks"
                                callbackOnClick={this.resetState} isResetClicked={isResetClicked}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mt10">
                        <Grid.Column className="actions">
                            <Button primary type="submit">Search</Button>
                            <Button className="cancel ml10" type="reset" onClick={this.resetForm}>Reset</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}

export default (reduxForm({
    form: 'AdvanceSearchRenewalTask',
    onSubmit: _func.onSubmitHandler
})(AdvanceSearchRenewalTask));
