import { allowOnlyAlphaNumericInput, monthYearDateMask, deepCopy } from 'helpers/util-common';
import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import CheckboxElement from 'shared-components/CheckBox';
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import RenderInput from 'shared-components/Input';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import RenderRadioField from 'shared-components/RadioBox';
import RenderSelect from 'shared-components/Select';
import TextArea from 'shared-components/TextArea';
import CustomTooltip from 'shared-components/Tooltip';
import { DegreeOptions, MembershipTypes } from '../../members.model';
import * as _func from './function';

class MemberEditGeneralInfo extends Component {

    constructor(props) {
        super(props);

        const { initialValues } = props;

        this.state = {
            isAutoRenewal: initialValues.IsAutoRenewal,
            isSupressFeeRenewalEmail: initialValues.IsSupressFeeRenewalEmail,
            isSupressFeeRenewalPrint: initialValues.IsSupressFeeRenewalPrint,
            feeGroupList: [],
            selectedFeeGroupId: initialValues.FeeGroupId ? initialValues.FeeGroupId : 0
        }

        this.onChangeAutoRenewal = this.onChangeAutoRenewal.bind(this);
        this.onChangeSupressFeeRenewalEmail = this.onChangeSupressFeeRenewalEmail.bind(this);
        this.onChangeSupressFeeRenewalPrint = this.onChangeSupressFeeRenewalPrint.bind(this);
    }

    componentWillMount() {
        _func.setClassInstance(this);
    }

    onChangeAutoRenewal() {
        const { isAutoRenewal } = this.state;
        this.setState({ isAutoRenewal: !isAutoRenewal });
    }

    onChangeSupressFeeRenewalEmail() {
        const { isSupressFeeRenewalEmail } = this.state;
        this.setState({ isSupressFeeRenewalEmail: !isSupressFeeRenewalEmail });
    }

    onChangeSupressFeeRenewalPrint() {
        const { isSupressFeeRenewalPrint } = this.state;
        this.setState({ isSupressFeeRenewalPrint: !isSupressFeeRenewalPrint });
    }

    render() {
        const { submitting, handleSubmit, initialValues, onCloseEditGenInfo, governmentTypeList, interestedCommitteeList, change } = this.props;
        const { isAutoRenewal, isSupressFeeRenewalEmail, isSupressFeeRenewalPrint, feeGroupList } = this.state;

        return (
            <Form noValidate onSubmit={handleSubmit(_func.onSubmitHandler)} data-testid="memberEditGeneralInfo">

                <h5>ASTM General Information
                    <ul className="editDetailsBtn floatRight">
                        <li>
                            <button title="Update" className="updateBtn" type="submit" disabled={submitting}>
                                <Icon name="check" />
                            </button>
                        </li>
                        <li>
                            <button title="Cancel" className="closeBtn" onClick={onCloseEditGenInfo}>
                                <Icon name="close" />
                            </button>
                        </li>
                    </ul>
                </h5>

                {initialValues.MemberTypeId !== MembershipTypes.Student ? (
                    <Grid divided='vertically' columns={4}>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name='FeeGroupId' label='Fee Group'
                                    component={RenderSelect}
                                    options={feeGroupList}
                                    onChange={_func.changeFeeGroupHandler}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field name='GovernmentTypeId' label='Government Type'
                                    component={RenderSelect}
                                    options={governmentTypeList}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Field name='GovtDunsAct' type="text"
                                    label='Government Duns Account'
                                    component={RenderInput} maxLength={100}
                                    onKeyPress={allowOnlyAlphaNumericInput}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="IsProfileMember" type="checkbox"
                                    component={ReduxCheckboxElement} label="Profile Member"
                                />
                                <CustomTooltip content='Profiled Member will receive hard copy of Ballots and Meeting Schedules, if checked.' icon={<Icon name="info circle" className="ml5 skyBlue vTextTop pointer" />} />

                            </Grid.Column>
                            <Grid.Column>
                                <CheckboxElement name="IsAutoRenewal" IsChecked={isAutoRenewal}
                                    setLabeltitleProp='Auto Renewal' IsEditable={_func.checkIsEditable()}
                                    onChange={this.onChangeAutoRenewal}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <CheckboxElement name="IsSupressFeeRenewalEmail" IsChecked={isSupressFeeRenewalEmail}
                                    setLabeltitleProp='Suppress Fee Renewal Emails' IsEditable={_func.checkIsEditable()}
                                    onChange={this.onChangeSupressFeeRenewalEmail}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <CheckboxElement name="IsSupressFeeRenewalPrint" IsChecked={isSupressFeeRenewalPrint}
                                    setLabeltitleProp='Suppress Fee Renewal Print' IsEditable={_func.checkIsEditable()}
                                    onChange={this.onChangeSupressFeeRenewalPrint}
                                />
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                ) : (
                    <div>
                        <Grid divided='vertically' columns={4}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='StudyField' type="text"
                                        label='Major' maxLength={75}
                                        placeholder='Major'
                                        component={RenderInput}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name='GraduationDate' type="text"
                                        label='Graduation Date'
                                        required={true} placeholder='MM/YYYY'
                                        component={RenderInput}
                                        normalize={monthYearDateMask}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name='FacilityName' type="text"
                                        label='University/College/Institution'
                                        placeholder='University/College/Institution'
                                        component={RenderInput} maxLength={200}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='Degree' label='Degree Sought'
                                        component={RenderSelect}
                                        options={DegreeOptions}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name='InterestedCommittee'
                                        component={DropdownMultipleSelection}
                                        isOnFocus={true}
                                        label='Committees of Interest'
                                        placeholder="Committee Designation"
                                        options={interestedCommitteeList}
                                        isMultiple={true}
                                        isSearch={true}
                                        open={false}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name="OptMailingList"
                                        component={RenderRadioField}
                                        className="mr10"
                                        label="Mailing List"
                                        type="radio" change={change}
                                        options={[{ label: 'Opt-In', value: 'true' }, { label: 'Opt-Out', value: 'false' }]}
                                        selectedValue={initialValues.OptMailingList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name='HearaboutStudentMembership' type="text"
                                        label='How did you hear about Student Membership?' maxLength={100}
                                        component={RenderInput}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                )}
                <Grid divided='vertically'>
                    <Grid.Row>
                        <Grid.Column>
                            <Field component={TextArea} type='text' label='Reason for update'
                                name='UpdateReason' required={true} maxLength='200'
                                placeholder="Please enter reason for update"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        )
    }
}

export default reduxForm({
    form: 'MemberEditGeneralInfoForm',
    validate: _func.validateHandler
})(MemberEditGeneralInfo);
