import * as types from 'action-types';
import axios from 'axios';
import * as commonActions from 'common.actions';
import getApiUrl from 'helpers/api-urls';
import { encrypt } from 'helpers/util-common';

const getCommitteeDetailsAction = (committeeId, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getCommitteeDetails') + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status && response.data.content && response.data.content.CommitteeId) {
                dispatch({ type: types.GET_COMMITTEE_DETAILS, committeeDetails: response.data.content });
            } else {
                // Handle error case
                dispatch({ type: types.SHOW_NOT_FOUND_PAGE });
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3094'));
        });
}

const getMasterDataForCommitteeDetailsAction = (dispatch) => {
    let meetingTypeListUrl = getApiUrl('manageCommittee', 'getMeetingTypeList');
    let operatorListUrl = getApiUrl('manageCommittee', 'getOperatorList');
    let applicationPrivilegeUrl = getApiUrl('manageCommittee', 'getApplicationPrivilege');
    let committeeAuditLogFieldListUrl = getApiUrl('manageCommittee', 'getCommitteeAuditLogFieldList');

    Promise.all([axios.get(meetingTypeListUrl), axios.get(operatorListUrl), axios.get(applicationPrivilegeUrl), axios.get(committeeAuditLogFieldListUrl)])
        .then(([meetingTypeListResponse, operatorListResponse, applicationPrivilegeResponse, committeeAuditLogFieldResponse]) => {
            let meetingTypeList = [];
            let operatorList = [];
            let applicationPrivilege = [];
            let committeeAuditLogFieldList = [];

            if (meetingTypeListResponse.data.status) {
                meetingTypeList = meetingTypeListResponse.data.content;
            }

            if (operatorListResponse.data.status) {
                operatorList = operatorListResponse.data.content;
            }

            if (applicationPrivilegeResponse.data.status) {
                applicationPrivilege = applicationPrivilegeResponse.data.content;
            }

            if (committeeAuditLogFieldResponse.data.status) {
                committeeAuditLogFieldList = committeeAuditLogFieldResponse.data.content;
            }

            if (!meetingTypeListResponse.data.status || !operatorListResponse.data.status ||
                !applicationPrivilegeResponse.data.status || !committeeAuditLogFieldResponse.data.status) {
                dispatch(commonActions.setMessage(true, '3072'));
            }

            dispatch({ type: types.GET_COMMITTEE_DETAILS_MASTER_DATA, meetingTypeList, operatorList, applicationPrivilege, committeeAuditLogFieldList });
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3072'));
        });
}

const getCommitteeMeetingSequenceAction = (committeeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getCommitteeMeetingSequence') + encrypt(committeeId);
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3097'));
        });
}

const getCommitteeMeetingDateListAction = (committeeId, query, showPageLoader, callback, dispatch) => {
    let url = `${getApiUrl('manageCommittee', 'getCommitteeMeetingDateList')}${encrypt(committeeId)}/${query}`;

    if (showPageLoader) {
        dispatch(commonActions.setLoader(true));
    }

    axios.get(url)
        .then((response) => {
            if (showPageLoader) {
                dispatch(commonActions.setLoader(false));
            }

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            if (showPageLoader) {
                dispatch(commonActions.setLoader(false));
            }
            dispatch(commonActions.setMessage(true, '3103'));
        });
}

const getCommitteeCommunicationLogAction = (committeeId, pageSize, pageNumber, showLoader, callback, dispatch) => {
    let url = `${getApiUrl('manageCommittee', 'getCommitteeCommunicationLogs')}/committeeId=${(committeeId)}&pageSize=${pageSize}&pageNumber=${pageNumber}`;

    if (showLoader) {
        dispatch(commonActions.setLoader(true));
    }

    axios.get(url)
        .then((response) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }

            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            if (showLoader) {
                dispatch(commonActions.setLoader(false));
            }
            dispatch(commonActions.setMessage(true, '3057'));
        });
}

const getCommitteeUserListAction = (dispatch) => {
    let url = getApiUrl('manageCommittee', 'getCommitteeUserList');

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: types.GET_COMMITTEE_USER_LIST, committeeUserList: response.data.content });
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '7034'));
        });
}

const getClassificationListAction = (committeeId, callback, dispatch) => {
    let url = `${getApiUrl('manageCommittee', 'getClassificationTypeList')}${encrypt(committeeId)}`;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            let list = [];
            if (response.data.status) {
                list = response.data.content;
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }

            callback(list);
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3109'));
            callback([]);
        });
}

const getStatisticalCommitteeInfoAction = (committeeId, queryParams, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getStatisticalCommitteeInfo') + encrypt(committeeId) + '/' + queryParams;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3121'));
        });
}

const getApplicationPrivilegeOnCommitteeTypeAction = (committeeTypeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getApplicationPrivilegeOnCommitteeType') + committeeTypeId;

    axios.get(url)
        .then((response) => {
            let result = [];
            if (response.data.status) {
                result = response.data.content;
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }

            callback(result);
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3129'));
            callback([]);
        });
}

const getCommitteeAuditLogAction = (committeeId, requestData, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getCommitteeAuditLog') + encrypt(committeeId);

    axios.get(url, { params: { requestData:JSON.stringify(requestData) } })
        .then((response) => {
            let result = {};
            if (response.data.status) {
                result = response.data.content;
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
            callback(result);
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3040'));
            callback({});
        })
}

const getBylawsRevisionHistoryListAction = (committeeId, fieldName, callback, dispatch) => {
    let url = `${getApiUrl('manageCommittee', 'getBylawsRevisionHistoryList')}${encrypt(committeeId)}/${fieldName}`;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            let list = [];
            if (response.data.status) {
                list = response.data.content;
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }

            callback(list);
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3133'));
            callback([]);
        });
}

const addCommitteeMeetingSequenceAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'addCommitteeMeetingSequence');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2023'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3099'));
        });
}

const addCommitteeMeetingDateAction = (data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'addCommitteeMeetingDate');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2023'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3102'));
        });
}

const saveCommitteeCommunicationLogAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'saveCommitteeCommunicationLog');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2029'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3029')); // Default exception error
            return false;
        });
};

const updateCommitteeAction = (committeeId, data, callback, dispatch) => {
    const url = getApiUrl('manageCommittee', 'updateCommittee') + encrypt(committeeId);
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(true);
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3095'));
        });
}

const updateCommitteeCommunicationLogAction = (commentId, data, isOwnComment, callback, dispatch) => {
    let url = '';

    if (isOwnComment) {
        url = getApiUrl('manageCommittee', 'updateOwnCommitteeCommunicationLog')
    } else {
        url = getApiUrl('manageCommittee', 'updateCommitteeCommunicationLog')
    }
    url = `${url}/${commentId}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2030'));
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3030')); // Default exception error
        });
};

const removeCommitteeMeetingSequenceAction = (id, committeeId, data, callback, dispatch) => {
    let url = `${getApiUrl('manageCommittee', 'removeCommitteeMeetingSequence')}${id}/${committeeId}`;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2043'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3098'));
        });
}

export const removeCommitteeCommunicationLogAction = (commentId, data, isOwnComment, callback, dispatch) => {
    let url = '';

    if (isOwnComment) {
        url = getApiUrl('manageCommittee', 'removeOwnCommitteeCommunicationLog')
    } else {
        url = getApiUrl('manageCommittee', 'removeCommitteeCommunicationLog')
    }
    url = `${url}/${commentId}`;

    dispatch(commonActions.setLoader(true));

    // Need to send it Put as the data is used in send mail
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2031'));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((error) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3032')); // Default exception error
        });
};

const updateCommitteeMeetingAndAttendeesAction = (committeeId, data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'updateCommitteeMeetingAndAttendees') + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3095'));
        });
}

const updateCommitteeMeetingDateAction = (meetingId, data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'updateCommitteeMeetingDate') + meetingId;
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3104'));
        });
}

const updateCommitteeClassificationAction = (committeeId, data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'updateCommitteeClassification') + encrypt(committeeId);
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3110'));
        });
}

const updateCommitteeSettingsAction = (committeeId, requestData, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'updateCommitteeSettings') + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.put(url, requestData)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3116'));
        });
}

const updateEnableCommitteeOnWebAction = (committeeId, requestData, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'updateEnableCommitteeOnWeb') + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.put(url, requestData)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2001'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3130'));
        });
}

const getSearchCommitteeRosterMemberAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'searchCommitteeRosterMember') + data.committeeId + '/' + data.searchKey;

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content, false);
            } else {
                // Handle error case
                callback([], false);
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            callback([], false);
            dispatch(commonActions.setMessage(true, '3105'));
        });
}

const getMembershipTypeListAction = (dispatch) => {
    let url = getApiUrl('manageMembers', 'getMembershipTypeList');

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                dispatch({ type: types.GET_MEMBERSHIPTYPE_LIST, membershipTypeList: response.data.content });
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '7025'));
        });
}
export const getCommontClassificationAction = (committeeId, dispatch) => {
    let getCommonCommitteeClassificationTypeUrl = getApiUrl('manageMembers', 'getCommonCommitteeClassification') + `?requestData[]=${committeeId}`;
    axios.get(getCommonCommitteeClassificationTypeUrl).then((response) => {
        if (response.data.status) {
            dispatch({ type: types.GET_COMMITTEE_CLASSIFICATION, classificationList: response.data.content });
        } else {
            // Handle error case
            dispatch(commonActions.setMessage(true, response.data.message));
        }
    }).catch((err) => {
        dispatch(commonActions.setMessage(true, '7025'));
    });
}
export const getOfficerTitleOnCommitteeAction = (committeeId, dispatch) => {
    const getOfficerTitleURL = getApiUrl('manageMembers', 'getOfficerTitleList') + `?requestData[]=${committeeId}`;
    axios.get(getOfficerTitleURL).then(response => {
        if (response.data.status) {
            dispatch({ type: types.GET_COMMITTEE_OFFICERTITLE, data: { officerTitleList: response.data.content } })
        }
    }).catch((err) => {
        dispatch(commonActions.setMessage(true, '7025'));
    });
}
export const getCommitteeClassificationTypeAndNoVoteReasonAction = (committeeId, callback, dispatch) => {
    let getCommonCommitteeClassificationTypeUrl = getApiUrl('manageMembers', 'getCommonCommitteeClassification') + `?requestData[]=${committeeId}`;
    let getNoVoteReasonUrl = getApiUrl('manageMembers', 'getNoVoteReason') + `/sortColumn=&sortOrder=asc`;
    const getOfficerTitleURL = getApiUrl('manageMembers', 'getOfficerTitleList') + `?requestData[]=${committeeId}`;

    dispatch(commonActions.setLoader(true));

    Promise.all([axios.get(getCommonCommitteeClassificationTypeUrl), axios.get(getNoVoteReasonUrl), axios.get(getOfficerTitleURL)])
        .then(([getCommonCommitteeClassificationTypeResponse, getNoVoteReasonResponse, getgetOfficerTitleResponse]) => {

            dispatch(commonActions.setLoader(false));
            let commonCommitteeClassificationTypeList = [];
            let noVoteReasonList = [];
            let officerTitleList = [];
            if (getCommonCommitteeClassificationTypeResponse.data.status) {
                commonCommitteeClassificationTypeList = getCommonCommitteeClassificationTypeResponse.data.content;
            }
            if (getNoVoteReasonResponse.data.status) {
                noVoteReasonList = getNoVoteReasonResponse.data.content;
            }
            if (getgetOfficerTitleResponse.data.status) {
                officerTitleList = getgetOfficerTitleResponse.data.content;
            }

            dispatch({ type: types.GET_COMMITTEE_INLINE_MASTER_DATA, data: { classificationList: commonCommitteeClassificationTypeList, noVoteReasonList, officerTitleList } });

            if (callback) {
                callback(commonCommitteeClassificationTypeList, noVoteReasonList, officerTitleList)
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3086'));
        });
}
export const validateProducerClassificationAction = (committeeId, accountNumber, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getValidateProducerClassification') + `/${encrypt(accountNumber)}/${committeeId}`;
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3110'));
        });
}
export const checkMemberExistsInParentCommittee = (data, callback, dispatch) => {
    dispatch(commonActions.setLoader(true));
    let url = getApiUrl('manageCommittee', 'checkMemberExistsInParentCommittee');
    url = url + data.committeeId + '/' + data.memberId;
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7025'));
        });
}

export const getRosterCommitteeMemberList = (data, callback, dispatch) => {
    dispatch(commonActions.setminiLoader(true));
    let url = getApiUrl('manageCommittee', 'getCommitteeRoster');
    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setminiLoader(false));
            if (response.data.status) {
                callback(response.data.content)
            } else {
                // Handle error case
                callback([])
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setminiLoader(false));
            dispatch(commonActions.setMessage(true, '3107'));
        });

}

export const saveCommitteeMember = (data, callback, dispatch) => {
    dispatch(commonActions.setLoader(true));
    let url = getApiUrl('manageCommittee', 'SaveRosterCommitteeMemberInfo');
    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                if (response.data.message == '3025') {
                    callback(response.data);
                }
                else {
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3082'));
        });
}
export const postCommitteeRosterAction = (data, callback, dispatch) => {
    dispatch(commonActions.setLoader(true));
    let url = getApiUrl('manageCommittee', 'postCommitteeRoster');
    axios.put(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                if(data.NewOfficerTitleName != data.OfficerTitleSelectedValue) {
                    updateOfficerTitleInSpecbuilder(data.NewOfficerTitleName, data.OfficerTitleSelectedValue,
                        data.CommitteeDesignation, data.SubCommitteeDesignation, data.MemberDetail_AccountNumber, 
                        response.data.content, dispatch);
                }
                dispatch(commonActions.setMessage(true, '2001'));
                callback();
            } else {
                if (response.data.message == '3025') {
                    callback(response.data);
                }
                else {
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3082'));
        });
}

export const deleteCommitteeAction = (committeeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'deleteCommittee') + committeeId;
    dispatch(commonActions.setLoader(true));

    axios.delete(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '2044'));
            } else if (response.data.message && (response.data.message === 3118 || response.data.message === 3119)) {
                let errorList = [];
                errorList.push({
                    IsShowFromBackend: false,
                    MessageCode: 3120 // For now, we are showing a generic error message
                })
                dispatch(commonActions.showErrorPopup(true, errorList));
            } else {
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3117'));
        });
}
export const getOfficerTitleOnCommitteeLevelAction = (committeeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getOfficerTitleOnCommitteeLevel') + committeeId;
    dispatch(commonActions.setminiLoader(true));
    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setminiLoader(false));
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setminiLoader(false));
            dispatch(commonActions.setMessage(true, '3122'));
        });
}
export const getMemberOnCommitteeAction = (committeeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getMemberOnCommittee') + committeeId;
    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                callback(response.data.content);
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setMessage(true, '3124'));
        });
}
export const getOfficerTitlesAsPerRulesAndExceptionOrderAction = (OfficerTitleIds, callback, dispatch) => {
    let url = getApiUrl('manageMembers', 'getOfficerTitlesAsPerRulesAndExceptionOrder');

    axios.post(url, OfficerTitleIds)
        .then((response) => {
            if (response.data.status) {
                let responseData = response.data.content ? response.data.content : []
                callback(responseData)
            } else {
                // Handle error case
                callback([])
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            callback([])
            dispatch(commonActions.setMessage(true, '3069'));
        });
}
const postCommitteeOfficerAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'postCommitteeOfficer');
    dispatch(commonActions.setLoader(true));
    if (!data.IsdidNotChange) {
        axios.post(url, data)
            .then((response) => {
                dispatch(commonActions.setLoader(false));
                if (response.data.status) {

                    // update officer title in SPB
                    if(data.updateOfficerTitle) {
                        axios.defaults.withCredentials = true;
                        const url = process.env.REACT_APP_SPECBUILDER_CONNECT_HOST;
                        axios.post(`${url}groups/work-item/transfer-group-members?accountID=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`, data.updateOfficerTitle, response.data.content.OKTA_Token)
                            .then((specResponse) => {
                                dispatch(commonActions.setLoader(false));
                            }).catch((error) => {
                                dispatch(commonActions.setLoader(false));
                            });
                    }
                    dispatch(commonActions.setMessage(true, '2001'));
                    callback();
                } else {
                    // Handle error case
                    dispatch(commonActions.setMessage(true, response.data.message));
                }
            })
            .catch((err) => {
                dispatch(commonActions.setLoader(false));
                callback()
                dispatch(commonActions.setMessage(true, '3126'));
            });
    } else {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '2040'));
        callback();
    }
}
const getDelinkedOfficerResponsibilityOnCommitteeAction = (data, callback, dispatch) => {
    let offierUrl = getApiUrl('manageCommittee', 'getOfficerDetail') + `${data[0].OfficerTitleId}`;
    let committeeUrl = getApiUrl('manageCommittee', 'getOfficerResponsibilityCommittee');
    dispatch(commonActions.setLoader(true));
    Promise.all([axios.get(offierUrl), axios.post(committeeUrl, data)]).then(([officerResponse, CommitteeResponse]) => {
        const responsibility = { mainResponsibility: [], extendedResponsibility: [] }

        dispatch(commonActions.setLoader(false));
        if (officerResponse.data.status) {
            responsibility.mainResponsibility = officerResponse.data.content || [];
        } else {
            dispatch(commonActions.setMessage(true, '3072'));
        }

        if (CommitteeResponse.data.status) {
            responsibility.extendedResponsibility = CommitteeResponse.data.content || [];
        } else {
            dispatch(commonActions.setMessage(true, '3072'));
        }
        callback(responsibility);
    })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3072'));
        });
}
const manageCommitteeOfficerTitleExtendedResponsibilityAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'manageCommitteeOfficerTitleExtendedResponsibility');
    dispatch(commonActions.setLoader(true));
    axios.post(url, data).then(response => {
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            dispatch(commonActions.setMessage(true, '2001'));
            callback();
        } else {
            dispatch(commonActions.setMessage(true, '3132'));
        }

    }).catch((err) => {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '3132'));
    });

}
const getSearchActiveCommitteesAction = (callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getSearchActiveCommittees');

    axios.get(url)
        .then((response) => {
            if (response.data.status) {
                let responseData = response.data.content ? response.data.content : []
                callback(responseData)
            } else {
                // Handle error case
                callback([])
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            callback([])
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3134'));
        });
}
const getCountActiveMembersInCommitteeAction = (committeeId, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getCountActiveMembersInCommittee') + `/${committeeId}`;
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                let responseData = response.data.content ? response.data.content : {}
                callback(responseData)
            } else {
                // Handle error case
                callback({})
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            callback({})
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3136'));
        });
}
const postCopyMembersToCommitteeAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'postCopyMembersToCommittee');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {
            dispatch(commonActions.setLoader(false));

            if (response.data.status) {
                callback()
                dispatch(commonActions.setMessage(true, '2045'));
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '3137'));
        });
}

const getInActiveCommitteeMasterDataAction = (committeeId, dispatch) => {
    let inactiveReasonsUrl = getApiUrl('manageCommittee', 'getCommitteeInactiveReason');
    let dependentUrl = getApiUrl('manageCommittee', 'getDependentCommittees') + `/${committeeId}`;
    let mergeCommitteeUrl = getApiUrl('manageCommittee', 'getMergeActiveCommittees') + `/${committeeId}`;
    let activeMemberCounturl = getApiUrl('manageCommittee', 'getCountActiveMembersInCommittee') + `/${committeeId}`;
    dispatch(commonActions.setLoader(true));
    Promise.all([axios.get(inactiveReasonsUrl), axios.get(dependentUrl), axios.get(mergeCommitteeUrl), axios.get(activeMemberCounturl)])
        .then(([inActiveReasonsResponse, dependentResponse, mergeCommitteeResponse, activeMemberResponse]) => {
            dispatch(commonActions.setLoader(false));
            let inactiveResons = [],
                dependentCommittee = [],
                mergeCommittee = [],
                activeMemberCount = 0;

            if (inActiveReasonsResponse.data.status) {
                inactiveResons = inActiveReasonsResponse.data.content || [];
            }
            if (dependentResponse.data.status) {
                dependentCommittee = dependentResponse.data.content || [];
            }
            if (mergeCommitteeResponse.data.status) {
                mergeCommittee = mergeCommitteeResponse.data.content || [];
            }
            if (activeMemberResponse.data.status) {
                activeMemberCount = activeMemberResponse.data.content;
            }
            dispatch({ type: types.GET_MASTER_DATA_FOR_INACTIVE_COMMITTEE, masterData: { inactiveResons, dependentCommittee, mergeCommittee, activeMemberCount } });

        }).catch((err) => {
            dispatch(commonActions.setLoader(false));
        });
}
const putInactivateCommitteeAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'putInactivateCommittee');
    dispatch(commonActions.setLoader(true));
    axios.put(url, data).then(response => {
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            dispatch(commonActions.setMessage(true, '2046'));
            callback(response.data.content);
        } else {
            dispatch(commonActions.setMessage(true, '3138'));
        }

    }).catch(err => {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '3138'));
    })
}

const putReactivateCommitteeAction = (data, callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'putReactivateCommittee');
    dispatch(commonActions.setLoader(true));
    axios.put(url, data).then(response => {
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            dispatch(commonActions.setMessage(true, '2047'));
            callback();
        } else {
            dispatch(commonActions.setMessage(true, '3140'));
        }
    }).catch(err => {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '3140'));
    })
}

const getSearchActiveStandardCommitteesAction = (callback, dispatch) => {
    let url = getApiUrl('manageCommittee', 'getSearchActiveCommittees');
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then((searchActiveCommitteeResponse) => {
            // Search active committee
            if (searchActiveCommitteeResponse.data.status) {
                let responseData = searchActiveCommitteeResponse.data.content ? searchActiveCommitteeResponse.data.content : []
                callback(responseData)
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, searchActiveCommitteeResponse.data.message));
                callback([])
            }

            dispatch(commonActions.setLoader(false));
        })
        .catch((error) => {
            dispatch(commonActions.setMessage(true, '3134'));
            dispatch(commonActions.setLoader(false));
        });;
}

const updateOfficerTitleInSpecbuilder = (OfficerTitle, officerTitleSelectedValue,
    CommitteeDesignation, SubCommitteeDesignation, AccountNumber, req, dispatch) => {
    let oldAccountNumber = officerTitleSelectedValue == 'Select' ? '' : AccountNumber;
    let newAccountNumber = OfficerTitle == 'Select' ? '' : AccountNumber;
    let officerTitle = OfficerTitle && OfficerTitle == 'Select' ? officerTitleSelectedValue : OfficerTitle;

    let updateOfficerTitle = {
        groupMainCommitteeDesignation : CommitteeDesignation,
        groupSubCommitteeDesignation : SubCommitteeDesignation,
        transfers : [
            {"officerTitle" : officerTitle, oldOfficerAccountNumber : oldAccountNumber , newOfficerAccountNumber : newAccountNumber}
        ]
    };

    axios.defaults.withCredentials = true;
    const url = process.env.REACT_APP_SPECBUILDER_CONNECT_HOST;
    axios.post(`${url}account/switch?accountId=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`, '', req.OKTA_Token)
       .then(response => {
          axios.defaults.withCredentials = true;
          axios.post(`${url}groups/work-item/transfer-group-members?accountID=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`, updateOfficerTitle, req.OKTA_Token)
             .then((specResponse) => {
                dispatch(commonActions.setLoader(false));
             }).catch((error) => {
                dispatch(commonActions.setLoader(false));
             });
       })
 }

export default {
    getCommitteeDetailsAction,
    getMasterDataForCommitteeDetailsAction,
    getCommitteeMeetingSequenceAction,
    getCommitteeMeetingDateListAction,
    getCommitteeCommunicationLogAction,
    getCommitteeUserListAction,
    getSearchCommitteeRosterMemberAction,
    getMembershipTypeListAction,
    getCommontClassificationAction,
    getCommitteeClassificationTypeAndNoVoteReasonAction,
    getMemberOnCommitteeAction,
    getOfficerTitleOnCommitteeLevelAction,
    getStatisticalCommitteeInfoAction,
    getDelinkedOfficerResponsibilityOnCommitteeAction,
    checkMemberExistsInParentCommittee,
    getRosterCommitteeMemberList,
    getClassificationListAction,
    getApplicationPrivilegeOnCommitteeTypeAction,
    getCommitteeAuditLogAction,
    getOfficerTitleOnCommitteeAction,
    getBylawsRevisionHistoryListAction,
    addCommitteeMeetingSequenceAction,
    addCommitteeMeetingDateAction,
    manageCommitteeOfficerTitleExtendedResponsibilityAction,
    postCommitteeRosterAction,
    putInactivateCommitteeAction,
    putReactivateCommitteeAction,
    saveCommitteeCommunicationLogAction,
    saveCommitteeMember,
    updateCommitteeAction,
    updateCommitteeCommunicationLogAction,
    updateCommitteeMeetingAndAttendeesAction,
    updateCommitteeMeetingDateAction,
    removeCommitteeMeetingSequenceAction,
    removeCommitteeCommunicationLogAction,
    updateCommitteeClassificationAction,
    updateCommitteeSettingsAction,
    updateEnableCommitteeOnWebAction,
    validateProducerClassificationAction,
    deleteCommitteeAction,
    getOfficerTitlesAsPerRulesAndExceptionOrderAction,
    postCommitteeOfficerAction,
    getSearchActiveCommitteesAction,
    getCountActiveMembersInCommitteeAction,
    postCopyMembersToCommitteeAction,
    getInActiveCommitteeMasterDataAction,
    getSearchActiveStandardCommitteesAction
}
