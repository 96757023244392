import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';

class ReduxCheckboxElement extends Component {

  state = {
    isChecked: false
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.input.checked })
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.input && this.props.input && nextProps.input.checked !== this.props.input.checked) {
      this.setState({ isChecked: nextProps.input.checked });
    }

    return true;
  }

  handleToggle = () => {
    let isChecked = this.props.isResetClicked ? true : !this.state.isChecked;

    this.props.input.onChange(isChecked);
    this.setState({ isChecked })

    if (this.props.callbackOnClick) {
      this.props.callbackOnClick(isChecked);
    }
  }
  
  //handle change for props only element
  handleChange = (event, value) => {
    if (this.props.callbackOnClick) {
      this.props.callbackOnClick(value.checked);
    }
  }

  render() {
    const { label, isDisabled, propsOnly, checked, name } = this.props;
    //all fields will pick values from props and state not required
    if (propsOnly) {
      return (
        <Checkbox data-testid={label} name={name}
          label={label}
          disabled={isDisabled}
          checked={checked}
          onChange={this.handleChange}
        />
      )
    }
    return (
      <Checkbox data-testid={label} name={name}
        label={label}
        disabled={isDisabled}
        checked={this.state.isChecked}
        onChange={this.handleToggle} />
    )
  }

}

export default ReduxCheckboxElement;
