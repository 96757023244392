import { getMessage, routeNavigationUrl } from 'helpers/util-common';
import { getMembershipTypeName } from '../../../members/common-functions';
import { membershipTypeJSON, MembershipTypes } from '../../../members/members.model';

let self = {};
let TIMEOUT = null;
let selectedMemberAccountNumber = '';
let selectedMemberTypeId = '';
let selectedMemberName = '';
let prevSearchText = '';
let hasUserConfirmedNoRep = false;
let prevMemberList = [];
let currentAction = 0; // 1: Active Org With No Rep, Selects Non Rep Member, 2: Active Org with Active Rep, Selects Rep Member, 3: Active Org with Active Rep, select Non Rep Member, 4: User removes the association

// Caution : This method is being used in associate-rep-account-modal index.js file.
export const setClassInstance = (instance) => {
    self = instance;

    const { initialValues } = self.props;
    selectedMemberAccountNumber = initialValues.RepAccountNumber;
    selectedMemberName = initialValues.RepName;
    hasUserConfirmedNoRep = initialValues.RepAccountNumber === '';
    selectedMemberTypeId = '';
}

// Caution : This method is being used in associate-rep-account-modal index.js file.
export const onRepMemberChange = (value) => {
    selectedMemberAccountNumber = '';
    selectedMemberTypeId = '';
    const trimmedValue = value.trim();

    clearTimeout(TIMEOUT);

    self.setState({
        isLoading: trimmedValue !== '',
        showNoResultsMessage: false
    });

    TIMEOUT = setTimeout(() => {

        // Check if we need to pull data from API rather than filtering the existing list
        if (prevSearchText.trim() !== '' && trimmedValue !== '' && value.length > prevSearchText.length &&
            value.indexOf(prevSearchText) === 0 && prevMemberList.length > 0) {

            let { formattedMemberList } = self.state;

            // Filter the existing list
            formattedMemberList = getFilteredMemberList(prevMemberList, trimmedValue);

            self.setState({
                formattedMemberList,
                isLoading: false,
                showNoResultsMessage: prevMemberList.length === 0
            });
        } else if (trimmedValue.length > 0) {
            self.setState({ formattedMemberList: {} });

            self.props.getMemberOrgActiveMapping(trimmedValue, self.props.initialValues.AccountNumber, (responseList) => {

                let formattedMemberList = getFormattedMemberList(responseList);
                let showNoResultsMessage = false;

                if (prevMemberList.length === 0) {
                    showNoResultsMessage = true;
                }

                self.setState({ isLoading: false, formattedMemberList, showNoResultsMessage });
            })
        } else {
            self.setState({ isLoading: false, formattedMemberList: {} });
        }

        prevSearchText = value;
    }, 300);
}

// Caution : This method is being used in associate-rep-account-modal index.js file.
export const onRepMemberSelect = (result) => {
    const { initialValues, PAGE_TYPE, USER_PRIVILEGE } = self.props;

    selectedMemberTypeId = result.MembershipTypeId;
    let membershipName = getMembershipTypeName(selectedMemberTypeId, self.props.membershipTypeList);
    let repMembershipTypeName = selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep ? getMembershipTypeName(MembershipTypes.cooperativeAgreementRep, self.props.membershipTypeList) : getMembershipTypeName(MembershipTypes.Representative, self.props.membershipTypeList);
    let repMemberLink = '';
    let repMembershipType = selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep ? membershipTypeJSON.CooperativeAgreementRep.displayName : membershipTypeJSON.represntative.displayName;
    selectedMemberAccountNumber = result.MemberAccountNumber;
    selectedMemberName = result.MemberName;

    if (result.OrgName && result.OrgName.length > 0) {
        repMemberLink = `<a class='linkTxt vAuto' title='${result.MemberName}' href='${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [result.MemberAccountNumber])}'>${repMembershipType}</a>`;
    }

    if (initialValues.RepName === '') {
        if (result.OrgName && result.OrgName.length > 0 && (selectedMemberTypeId === MembershipTypes.Representative || selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep)) {
            resetSelectedMemberData();
            let alertMessage = getMessage(self.props.messageCodes, '9028.text');
            alertMessage = alertMessage.replace('@RepresentativeLink', repMemberLink);
            if (result.MembershipTypeId === MembershipTypes.cooperativeAgreementRep) {
                alertMessage = alertMessage.replaceAll('@membertype', membershipTypeJSON.CooperativeAgreementRep.displayName);
            } else {
                alertMessage = alertMessage.replaceAll('@membertype', membershipTypeJSON.represntative.displayName);
            }
            self.props.showAlertPopup(alertMessage);
        } else if ((selectedMemberTypeId !== MembershipTypes.Representative) && (selectedMemberTypeId !== MembershipTypes.cooperativeAgreementRep)) {
            let descMessage = getMessage(self.props.messageCodes, '9029.text');
            descMessage = descMessage.replace('@SelectedMemberName', selectedMemberName)
                .replace('@PreviousMembership', membershipName)
                .replace('@Representative', repMembershipTypeName);

            self.setState({ descMessage, showConfirmationModal: true });
            currentAction = 1;
        } else if ((selectedMemberTypeId === MembershipTypes.Representative) || (selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep)) {
            self.props.change('OrgRepName', selectedMemberName);
            self.setState({ formattedMemberList: {} });
        }
    } else if (result.OrgName && result.OrgName.length > 0) {
        currentAction = 2;
        let descMessage = getMessage(self.props.messageCodes, '9030.text');
        descMessage = descMessage.replace('@RepresentativeLink', repMemberLink)
        if (selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep) {
            descMessage = descMessage.replaceAll('@membertype',  membershipTypeJSON.CooperativeAgreementRep.displayName);
        } else {
            descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.represntative.displayName);
        }
        self.setState({ descMessage, showConfirmationModal: true });
    } else if (selectedMemberTypeId === MembershipTypes.Representative || selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep) {
        self.props.change('OrgRepName', selectedMemberName);
        self.setState({ formattedMemberList: {} });
    } else {
        currentAction = 3;
        let descMessage = getMessage(self.props.messageCodes, '9031.text');
        descMessage = descMessage.replace('@SelectedMemberName', selectedMemberName)
            .replace('@PreviousMembership', membershipName)
            .replace('@Representative', repMembershipTypeName)
            .replace('@CurrentRep', self.props.initialValues.RepName);

        self.setState({ descMessage, showConfirmationModal: true });
    }
}

export const onCancelModal = () => {
    if (currentAction === 4) {
        self.props.onCloseEditGenInfo();
    } else {
        resetSelectedMemberData();
        self.setState({ showConfirmationModal: false, formattedMemberList: {} });
        self.props.change('OrgRepName', '');
    }
}

export const onConfirmModal = () => {
    self.setState({ showConfirmationModal: false, formattedMemberList: {} });

    if (currentAction === 2 || currentAction === 4) {
        selectedMemberName = '';
        selectedMemberAccountNumber = '';
        selectedMemberTypeId = '';
        hasUserConfirmedNoRep = true;
    }

    // Submit the form
    if (currentAction === 4) {
        onSubmitHandler(self.props.organizationEditFormValues);
    }

    self.props.change('OrgRepName', selectedMemberName);
}

// Validation method for validate all value as per requirement
export const validateHandler = (values, props) => {
    const errors = {};

    if (values.OrgRepName && values.OrgRepName.length > 0 && selectedMemberAccountNumber === '') {
        errors.OrgRepName = getMessage(props.messageCodes, '8123.text');
    }

    if ((!values.UpdateReason) || (values.UpdateReason && values.UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    return errors;
}

export const onSubmitHandler = (data) => {
    const { initialValues } = self.props;

    // Send API only in case user has updated atleast one prop
    if (selectedMemberAccountNumber !== initialValues.RepAccountNumber) {
        // In case user has removed the representative
        if (!hasUserConfirmedNoRep && selectedMemberAccountNumber === '') {
            currentAction = 4;
            let descMessage = getMessage(self.props.messageCodes, '9032.text');
            if (initialValues.RepMembershipTypeId === MembershipTypes.cooperativeAgreementRep) {
                descMessage = descMessage.replaceAll('@membertype',  membershipTypeJSON.CooperativeAgreementRep.displayName);
            } else {
                descMessage = descMessage.replaceAll('@membertype', membershipTypeJSON.represntative.displayName);
            }
            self.setState({ descMessage, showConfirmationModal: true });
        } else {
            let requestData = {
                MemberTypeId: data.MemberTypeId,
                OldMemberTypeName: getMembershipTypeName(selectedMemberTypeId, self.props.membershipTypeList),
                NewMemberTypeName: selectedMemberTypeId === MembershipTypes.cooperativeAgreementRep ? getMembershipTypeName(MembershipTypes.cooperativeAgreementRep, self.props.membershipTypeList) : getMembershipTypeName(MembershipTypes.Representative, self.props.membershipTypeList),
                RepAccountNumber: selectedMemberAccountNumber,
                PrevRepAccountNumber: initialValues.RepAccountNumber,
                IsRepStatusHistorical: (initialValues.RepAccountNumber && initialValues.RepAccountNumber.length > 0 && selectedMemberAccountNumber === ''),
                UpdateReason: data.UpdateReason
            }

            self.props.updateOrgRepMapping(data.AccountNumber, requestData, () => {
                self.props.getRepresentativeHistory(data.AccountNumber);
                self.props.getOrganizationDetails(data.AccountNumber);
                self.props.onCloseEditGenInfo();
            })
        }
    } else {
        self.props.displayNoChangesMadeMessage();
        self.props.onCloseEditGenInfo();
    }
}

// Private functions
const getFormattedMemberList = (list) => {
    let results = {};
    prevMemberList = [];
    let isMemberFound = false;

    if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
            let membershipTypeName = getMembershipTypeName(list[i].MembershipTypeId, self.props.membershipTypeList);
            let memberList = list[i].MemberOrgMapping;

            if (!isMemberFound) {
                // Remove the currently associated member from the list
                // eslint-disable-next-line no-loop-func
                let index = memberList.findIndex(item => item.MemberAccountNumber === self.props.initialValues.RepAccountNumber);
                if (index > -1) {
                    isMemberFound = true;
                    memberList.splice(index, 1);
                }
            }

            if (memberList.length > 0) {
                results[membershipTypeName] = {
                    name: membershipTypeName,
                    results: memberList
                }

                prevMemberList.push({ name: membershipTypeName, results: memberList });
            }
        }
    }

    return results;
}

const getFilteredMemberList = (list, searchText) => {
    let results = {};
    prevMemberList = [];

    for (let i = 0; i < list.length; i++) {
        let membershipTypeName = list[i].name;
        let memberList = list[i].results;

        memberList = memberList.filter(item => {
            return item.MemberAccountNumber.indexOf(searchText) >= 0 || (item.MemberName && item.MemberName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
        })

        if (memberList.length > 0) {
            results[membershipTypeName] = {
                name: membershipTypeName,
                results: memberList
            }

            prevMemberList.push({ name: membershipTypeName, results: memberList });
        }
    }

    return results;
}

const resetSelectedMemberData = () => {
    selectedMemberAccountNumber = '';
    selectedMemberTypeId = '';
    selectedMemberName = '';
}
