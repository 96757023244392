import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { CountryCodes } from '../../../../../models/common.models';

class FeeGroupContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeeGroupTabDetails: this.props.FeeGroupTabDetails
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ FeeGroupTabDetails: nextProps.FeeGroupTabDetails });
    }

    render() {
        const { FeeGroupTabDetails: { ContactPersonName, ContactEmailAddress, ContactPhoneNumber, ContactExtension, Country } } = this.state;
        return (
            <section className="subCommInfo mt30">
                <div className="dashed-border mt20 mb20"></div>
                <h5>Contact Person Details</h5>

                <Grid divided='vertically' columns={4}>
                    <Grid.Row>
                        <Grid.Column>
                            <span className="labelTitle">Contact Person Name</span>
                            <span className="labelValue">{ContactPersonName}</span>
                        </Grid.Column>

                        <Grid.Column>
                            <span className="labelTitle">Email</span>
                            <span className="labelValue">
                                <a className="linkTxt" href={`mailto:${ContactEmailAddress}`}>{ContactEmailAddress}</a>
                            </span>
                        </Grid.Column>
                        <Grid.Column>
                            <span className="labelTitle">Phone Number</span>
                            <span className="labelValue">{ContactPhoneNumber}</span>
                        </Grid.Column>
                        {Country === CountryCodes.UNITEDSTATES.Name && <Grid.Column>
                            <span className="labelTitle">Extension</span>
                            <span className="labelValue">{ContactExtension}</span>
                        </Grid.Column>}
                    </Grid.Row>
                </Grid>
            </section>
        )
    }
}
export default FeeGroupContactDetails;
