import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import commonActions from "common.actions";
import Parser from 'html-react-parser';

const CustomAlertModal = ({ content, handleDismiss, handleClick, closeModal }) => (
    <Modal open='true' className="tiny errormodalbox" data-testid="errormodalbox">
        {closeModal ? (
            <Modal.Header>Message<i onClick={(e) => closeModal(0)} className="close" title="close">&#x2716;</i></Modal.Header>
        ) : (<Modal.Header>Message</Modal.Header>)}
        <Modal.Content>
            <div>{Parser(content)}</div>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClick ? handleClick : handleDismiss}>Ok</Button>
        </Modal.Actions>
    </Modal>
)

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
    handleDismiss: () => {
        commonActions.hideAlertPopup(dispatch);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAlertModal);

