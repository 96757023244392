import { routeNavigationUrl, utcDateTimeToLocalDateTime } from 'helpers/util-common';
import React from 'react';
import { Modal } from 'semantic-ui-react';

const MemberAccountHistoryModal = ({ label, onCloseAccountHistoryModal, memberAccountHistoryList, hasUserPageAccess, PAGE_TYPE, USER_PRIVILEGE }) => (

    <Modal open='true' data-testid="memberAccountHistoryModal">
        <Modal.Header> {label}<i data-testid="btnCloseAccountHistoryModal" className="close" onClick={onCloseAccountHistoryModal}>&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <div className="tableWrapper">
                    <table className="customTable memAcHistoryTable">
                        <thead>
                            <tr>
                                <th width="15%">Account Status / Paid Status</th>
                                <th width="20%">Reason</th>
                                <th width="30%">Comment</th>
                                <th width="20%">Updated Date</th>
                                <th width="15%">Updated By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberAccountHistoryList && memberAccountHistoryList.length > 0 &&
                                memberAccountHistoryList.map((item) => {
                                    return (
                                        <tr>
                                            <td width="15%">{item.Value}</td>
                                            <td width="20%">{item.UpdateReason}</td>
                                            <td className="comment" width="30%">{item.Why}</td>
                                            <td width="20%">{utcDateTimeToLocalDateTime(item.When)}</td>
                                            <td width="15%">
                                                {hasUserPageAccess && Number(item.OwnerId) > 0 ? (
                                                    <a href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.USER), USER_PRIVILEGE.VIEW, [item.OwnerId])}`}>{item.OwnerName}</a>
                                                ) : item.OwnerName}
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    {memberAccountHistoryList && memberAccountHistoryList.length === 0 &&
                        <div className="noRecordMessage">No data to display</div>
                    }
                </div>
            </Modal.Description>
        </Modal.Content>
    </Modal >
)

export default MemberAccountHistoryModal;
