import React, { Component } from 'react';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import TextArea from 'shared-components/TextArea';
import * as _func from './function';

class MemberCommitteeSummaryModal extends Component {

    componentDidMount() {
        _func.setClassInstance(this);
    }
    render() {
        const { onClose, memberSummaryModel, handleSubmit, submitting } = this.props
        memberSummaryModel.Filters = (memberSummaryModel && memberSummaryModel.Filters.length > 0) ? memberSummaryModel.Filters.sort((a, b) => b.ActionId - a.ActionId) : memberSummaryModel.Filters;
        return (
            <Modal open='true' className="tiny committeInfoSummary" data-testid="memberCommitteeSummaryModal">
                <Form onSubmit={handleSubmit(_func.postMemberCommittees)} noValidate>
                    <Modal.Header>Update Committee(s) - Summary<i onClick={(e) => onClose(e, 1)} className="close" title="close">&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>

                        <Modal.Description>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="updateTodoList">
                                            {memberSummaryModel && memberSummaryModel.Filters.length > 0 &&

                                                memberSummaryModel.Filters.map(item => {
                                                    return <React.Fragment>

                                                        {(item.ActionId === 3 && item.ActionId !== 2) && <span>Reactivated</span>}
                                                        {(item.ActionId === 1 && item.ActionId !== 2) && <span>Classification: {item.title}</span>}

                                                    </React.Fragment>

                                                })


                                            }
                                            {memberSummaryModel && memberSummaryModel.Filters.length > 0 &&

                                                memberSummaryModel.Filters.map(item => {
                                                    return <React.Fragment>
                                                        {(item.ActionId === 2) && <span>Vote: {item.title}</span>}

                                                    </React.Fragment>

                                                })
                                            }
                                            {memberSummaryModel && memberSummaryModel.Filters.length > 0 &&

                                                memberSummaryModel.Filters.map(item => {
                                                    return <React.Fragment>
                                                        {(item.ActionId === 4) && <span>Inactivated</span>}
                                                        {(item.ActionId === 4) && <span>Inactivate Reason: {item.title}</span>}

                                                    </React.Fragment>

                                                })
                                            }

                                        </span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="label2">Committee(s)</label>

                                        <span className="labelValue">{memberSummaryModel.CommitteeTitles}</span>
                                    </Grid.Column>
                                </Grid.Row>
                                {memberSummaryModel && memberSummaryModel.ImpactedCommitteeTitles != '' &&
                                    <React.Fragment>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <label className="label2">Member classification will get updated to Producer in below Main Committee(s) due to update in respective subcommittee(s)</label>
                                                <span className="labelValue">{memberSummaryModel.ImpactedCommitteeTitles}</span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </React.Fragment>
                                }
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field component={TextArea} type='text' label='Reason for update'
                                            name='UpdateReason' required={true} maxLength='200'
                                            placeholder="Please enter reason for update"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <div className="actions pl0">
                            <Button primary type="submit" disabled={submitting} >Update</Button>
                            <Button secondary onClick={(e) => onClose(e, 1)}>Cancel</Button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal >
        )
    }
}
export default reduxForm({
    form: 'MemberCommitteeSummaryModal',
    validate: _func.validateHandler
})(MemberCommitteeSummaryModal);
