import * as actions from "action-types";
import { setMessage, showAlertPopup } from "common.actions";
import _ from "lodash";
import { connect } from "react-redux";
import feeGroupDetailsActions from "../actions/fee-group-details.actions";
import feeGroupActions from "../actions/fee-group.actions";
import FeeGroupDetails from "../components/fee-group-details";

const mapStateToProps = (state) => {
  return {
    messageCodes: _.get(
      state.i18nReducer.dictionaries,
      state.i18nReducer.currentLanguage,
    ),
    isDuplicate: state.feeGroupReducer.isDuplicate,
    countryList: state.commonReducer.countryList,
    membershipTypeList: state.feeGroupReducer.membershipTypeList,
    activeCountryList: state.commonReducer.activeCountryList,
    originalActiveCountryList: state.commonReducer.originalActiveCountryList,
    usStateList: state.commonReducer.usStateList,
    canadaStateList: state.commonReducer.canadaStateList,
    userPermission: state.commonReducer.userPermission,
    initialValues: state.feeGroupReducer.initialValues,
    feeGroupAuditLogField: state.feeGroupReducer.feeGroupAuditLogField,
    ...state.commonReducer.PAGE_CONFIG,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeeGroupBannerDetail: (feeGroupId, callback) => {
    feeGroupDetailsActions.getFeeGroupBannerDetailAction(
      feeGroupId,
      callback,
      dispatch,
    );
  },
  getFeeGroupTabDetail: (feeGroupId, callback) => {
    feeGroupDetailsActions.getFeeGroupTabDetailAction(
      feeGroupId,
      callback,
      dispatch,
    );
  },
  getFeeGroupTitleExist: (data, callback) =>
    feeGroupActions.getFeeGroupTitleExistAction(data, dispatch, callback),
  getInitialMembershipTypeListData: () => {
    feeGroupDetailsActions.getInitialMembershipTypeListDataAction(dispatch);
  },
  getFeeGroupAssociatedMemberList: (feeGroupId, callback) =>
    feeGroupDetailsActions.getFeeGroupAssociatedMemberListAction(
      feeGroupId,
      callback,
      dispatch,
    ),
  resetFeeGroup: () => dispatch({ type: actions.FEEGROUP_RESET }),
  putFeeGroup: (data, callback) =>
    feeGroupActions.putFeeGroupAction(dispatch, data, callback),
  putFeeGroupStatus: (data, callback) =>
    feeGroupDetailsActions.putFeeGroupStatusAction(data, callback, dispatch),
  getFeeGroupNoneAssociatedMember: (callback) =>
    feeGroupDetailsActions.getFeeGroupNoneAssociatedMemberAction(
      callback,
      dispatch,
    ),
  putAssociateMemberFeeGroup: (data, callback) =>
    feeGroupDetailsActions.putAssociateMemberFeeGroupAction(
      data,
      callback,
      dispatch,
    ),
  deleteAssociateMemberFeeGroup: (data, callback) =>
    feeGroupDetailsActions.deleteAssociateMemberFeeGroupAction(
      data,
      callback,
      dispatch,
    ),
  getFeeGroupInvoiceDetails: (feeGroupId, callback) =>
    feeGroupDetailsActions.getFeeGroupInvoiceDetailsAction(
      feeGroupId,
      callback,
      dispatch,
    ),
  displayNoChangesMadeMessage: () => dispatch(setMessage(true, "2040")),
  showAlertPopup: (alertMessage) => {
    dispatch(showAlertPopup(true, alertMessage));
  },
  getFeeGroupAuditLog: (feeGroupNumber, requestData, callback) => {
    feeGroupDetailsActions.getFeeGroupAuditLogAction(
      feeGroupNumber,
      requestData,
      callback,
      dispatch,
    );
  },
  getAuditLogField: (moduleIds, callback) => {
    feeGroupDetailsActions.getAuditLogField(moduleIds, callback, dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeGroupDetails);
