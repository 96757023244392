import * as type from 'action-types';

const initialState = {
    membershipTypeList: []
}

const formatMembershipTypeList = (data) => {
    let result = [];

    result = data.map(item => {
        return { key: item.MembershipTypeId, value: item.MembershipTypeId, text: item.MembershipTypeName }
    })

    result.unshift({ key: 0, value: 0, text: 'All' });

    return result;
}

const memberReducer = (state = initialState, action) => {

    switch (action.type) {
        case type.GET_MEMBERSHIPTYPE_LIST:
            return {
                ...state,
                membershipTypeList: formatMembershipTypeList(action.membershipTypeList)
            };

        default:
            return state;
    }
}

export default memberReducer;
