import { formatDateToYYYY, getCurrentlyShowingItemsInGrid, getSortOrderFromHeaderClick, resetHeaderSortOrder, toUTCDateAndTime } from 'helpers/util-common';
import { endDayTime } from 'models/common.models';
import { AuditGridColumns } from '../../committee.model';

let self = null;

export const setInstance = (instance) => {
   self = instance;
}

export const loadInitialData = () => {
   const { selectedFieldName, dateFrom, dateTo, pageSize, pageNumber, sortKey, sortOrder } = self.state;

   loadAuditLogList(selectedFieldName, dateFrom, dateTo, pageSize, pageNumber, sortKey, sortOrder);
}

export const loadAuditLogList = (selectedFieldName, dateFrom, dateTo, pageSize, pageNumber, sortKey, sortOrder) => {
   self.setState({ showLoader: true });

   if (dateFrom) {
      dateFrom = toUTCDateAndTime(dateFrom, 'YYYY-MM-DD HH:mm:ss');
   }
   if (dateTo) {
      dateTo = dateTo + endDayTime;
      dateTo = toUTCDateAndTime(dateTo, 'YYYY-MM-DD HH:mm:ss');
   }

   const requestData = {
      fieldName: selectedFieldName,
      dateFrom: dateFrom,
      dateTo: dateTo,
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortKey: sortKey,
      sortOrder: sortOrder
   }

   self.props.getCommitteeAuditLog(self.props.initialValues.CommitteeId, requestData, (result) => {
      let auditLogList = [];
      let totalItems = 0;

      if (result && result.AuditLogs && result.AuditLogs.length > 0) {
         auditLogList = result.AuditLogs;
         totalItems = result.Count;
      }

      self.setState({ auditLogList, totalItems, showLoader: false });
      updateCurrentlyShowingItems(totalItems, pageSize, pageNumber);

      let scrollElement = document.getElementsByClassName('customTable auditLogtable')[0];
      if (scrollElement) {
         scrollElement.scrollIntoView({
            behavior: 'smooth'
         });
      }
   });
}

export const onPageChangeHandler = (event, data) => {
   const { selectedFieldName, dateFrom, dateTo, pageSize, sortKey, sortOrder } = self.state;
   loadAuditLogList(selectedFieldName, dateFrom, dateTo, pageSize, data.activePage, sortKey, sortOrder);

   self.setState({ pageNumber: data.activePage })
}

export const onChangeNumberOfItemsPerPage = (e, value) => {
   const { selectedFieldName, dateFrom, dateTo, sortKey, sortOrder } = self.state;
   loadAuditLogList(selectedFieldName, dateFrom, dateTo, value, 1, sortKey, sortOrder);

   self.setState({ pageSize: value, pageNumber: 1 });
}

// Reset the entire grid
export const resetForm = () => {
   const selectedFieldName = 'All';
   const dateFrom = '';
   const dateTo = '';
   const pageSize = 25;
   const pageNumber = 1;
   const sortKey = 'When';
   const sortOrder = 'desc';

   self.props.reset();
   self.props.change('dateFrom', dateFrom);
   self.props.change('dateTo', dateTo);
   self.setState({ selectedFieldName, dateFrom, dateTo, pageSize, pageNumber, sortKey, sortOrder })
   resetHeaderSortOrder(AuditGridColumns);
   loadAuditLogList(selectedFieldName, dateFrom, dateTo, pageSize, pageNumber, sortKey, sortOrder);
}

export const sortByHandler = (event, sortKey) => {
   let orderType = getSortOrderFromHeaderClick(event);
   const sortOrder = orderType === '0' ? 'asc' : 'desc';

   self.setState({ sortKey, sortOrder });

   const { selectedFieldName, dateFrom, dateTo, pageSize, pageNumber } = self.state;
   loadAuditLogList(selectedFieldName, dateFrom, dateTo, pageSize, pageNumber, sortKey, sortOrder);
}

export const onClickAuditSearch = () => {
   const { selectedFieldName, dateFrom, dateTo, pageSize, sortKey, sortOrder } = self.state;

   self.setState({ pageNumber: 1 });
   loadAuditLogList(selectedFieldName, dateFrom, dateTo, pageSize, 1, sortKey, sortOrder);
}

export const getDescription = (item) => {
   let fieldName = `<strong>${item.Field}</strong>`;
   let oldValue = item.OldValue ? item.OldValue : '--';
   let newValue = item.NewValue ? item.NewValue : '--';

   // Replace the params from overview field
   if (item.Field && item.Field.toLowerCase() === 'overview') {

      const { allCommitteesHierarchyDetails, committeeTypeName } = self.props;
      const { LevelId, CommitteeTypeId, CommitteeDesignation, CommitteeTitle, EstablishmentDate, NumberOfTimesCommitteeMeets, MeetingMonths, NumberOfAttendees, NumberOfMeetingDays, NumberOfMembers, NumberOfStandards, VolumeNumber, NumberOfCommitteesAtNextLevel } = self.props.initialValues;
      let yearFormed = formatDateToYYYY(EstablishmentDate);

      let nextLevelTitle = '';
      if (allCommitteesHierarchyDetails && allCommitteesHierarchyDetails.length > 0 && (oldValue.indexOf('@NEXT LEVEL TITLE') > -1)) {
         let committeeLevels = allCommitteesHierarchyDetails.filter(level => level.CommitteeTypeId === CommitteeTypeId);

         if (committeeLevels && committeeLevels.length > LevelId) {
            nextLevelTitle = committeeLevels[LevelId].CommitteeLevelTitle;
         }

         if (oldValue !== '--') {
            oldValue = oldValue
               .replace(new RegExp('@NEXT LEVEL TITLE', 'g'), nextLevelTitle);
         }

         if (newValue !== '--') {
            newValue = newValue
               .replace(new RegExp('@NEXT LEVEL TITLE', 'g'), nextLevelTitle);
         }
      }

      if (committeeTypeName) {
         if (oldValue !== '--') {
            oldValue = oldValue
               .replace(new RegExp('@COMMITTEE TYPE', 'g'), committeeTypeName);
         }

         if (newValue !== '--') {
            newValue = newValue
               .replace(new RegExp('@COMMITTEE TYPE', 'g'), committeeTypeName);
         }
      }

      if (oldValue !== '--') {
         oldValue = oldValue.replace(new RegExp('@COMMITTEE DESIGNATION', 'g'), CommitteeDesignation)
            .replace(new RegExp('@TITLE', 'g'), CommitteeTitle)
            .replace(new RegExp('@YEAR FORMED', 'g'), yearFormed)
            .replace(new RegExp('@NUMBER OF TIMES COMMITTEE MEETS', 'g'), NumberOfTimesCommitteeMeets)
            .replace(new RegExp('@MEETING MONTHS', 'g'), MeetingMonths)
            .replace(new RegExp('@NUMBER OF ATTENDEES', 'g'), NumberOfAttendees)
            .replace(new RegExp('@NUMBER OF MEETING DAYS', 'g'), NumberOfMeetingDays)
            .replace(new RegExp('@NUMBER OF MEMBERS', 'g'), NumberOfMembers)
            .replace(new RegExp('@NUMBER OF STANDARDS', 'g'), NumberOfStandards)
            .replace(new RegExp('@VOLUME NUMBER', 'g'), VolumeNumber)
            .replace(new RegExp('@NUMBER OF COMMITTEES AT NEXT LEVEL', 'g'), NumberOfCommitteesAtNextLevel);
      }
      if (newValue !== '--') {
         newValue = newValue.replace(new RegExp('@COMMITTEE DESIGNATION', 'g'), CommitteeDesignation)
            .replace(new RegExp('@TITLE', 'g'), CommitteeTitle)
            .replace(new RegExp('@YEAR FORMED', 'g'), yearFormed)
            .replace(new RegExp('@NUMBER OF TIMES COMMITTEE MEETS', 'g'), NumberOfTimesCommitteeMeets)
            .replace(new RegExp('@MEETING MONTHS', 'g'), MeetingMonths)
            .replace(new RegExp('@NUMBER OF ATTENDEES', 'g'), NumberOfAttendees)
            .replace(new RegExp('@NUMBER OF MEETING DAYS', 'g'), NumberOfMeetingDays)
            .replace(new RegExp('@NUMBER OF MEMBERS', 'g'), NumberOfMembers)
            .replace(new RegExp('@NUMBER OF STANDARDS', 'g'), NumberOfStandards)
            .replace(new RegExp('@VOLUME NUMBER', 'g'), VolumeNumber)
            .replace(new RegExp('@NUMBER OF COMMITTEES AT NEXT LEVEL', 'g'), NumberOfCommitteesAtNextLevel);
      }
   }

   oldValue = `<strong>${oldValue}</strong>`;
   newValue = `<strong>${newValue}</strong>`;
   return item.FormatTemplate.replace('<Field>', fieldName).replace('<OldValue>', oldValue)
      .replace('<NewValue>', newValue);
}

export const getFormatTemplateForMultiData = (item) => {
   let htmlResult = '';

   for (let i = 0; i < item.MultiData.length; i++) {
      htmlResult = `${htmlResult}<p>${getDescription(item.MultiData[i])}</p>`;
   }

   return htmlResult;
}

// Private functions
const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
   const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);

   self.setState({ currentlyShowingItems });
}
