import React, { Component } from 'react';
import CommitteeBannerHeader from '../committee-banner-header';
import CommitteeDetailsTab from '../committee-details-tabs';
class CommitteeDetails extends Component {

    state = {
        committeeTypeName: ''
    }

    componentWillMount() {
        let committeeId = this.props.match.params ? this.props.match.params.committeeId : '';
        this.props.getCommitteeDetails(committeeId);

        if (!this.props.committeeTypeList || !this.props.allCommitteesHierarchyDetails || !this.props.overviewTagFieldList) {
            this.props.getMasterDataForCommitteeList();
        }
        if (!this.props.allMeetingTypeList || !this.props.operatorList || !this.props.applicationPrivilege || !this.props.committeeAuditLogFieldList) {
            this.props.getMasterDataForCommitteeDetails();
        }
        if (!this.props.committeeUserList) {
            this.props.getCommitteeUserList();
        }
        if (!this.props.membershipTypeList || this.props.membershipTypeList.length === 0) {
            this.props.getMembershipTypeList();
        }
        if (!this.props.classificationList) {
            let committeesId = [];
            committeesId.push(this.props.match.params.committeeId)
            this.props.getCommontClassification(committeesId);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.match.params.committeeId !== nextProps.match.params.committeeId) {
            let committeeId = nextProps.match.params ? nextProps.match.params.committeeId : '';
            this.props.getCommitteeDetails(committeeId);
        }
        return true;
    }

    componentWillUnmount() {
        this.props.resetInitialValues();
    }

    setCommitteeTypeName = (value) => {
        this.setState({ committeeTypeName: value });
    }

    render() {
        const { initialValues } = this.props;
        const { committeeTypeName } = this.state;

        return initialValues && initialValues.CommitteeId ? (
            <div className="committeePage detailPage" data-testid="committeeDetails">
                <CommitteeBannerHeader {...this.props}
                    modifiedDate={initialValues.ModifiedDate} currentModifiedById={initialValues.ModifiedBy}
                    setCommitteeTypeName={this.setCommitteeTypeName} />
                <CommitteeDetailsTab {...this.props} committeeTypeName={committeeTypeName} />
            </div>
        ) : null
    }
}

export default CommitteeDetails;
