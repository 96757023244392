import * as type from "action-types";
import { formatAuditLogFieldList } from "helpers/util-common";

const initialState = {
  initialValues: {},
  isDuplicate: false,
  feeGroupReduxList: [],
  membershipTypeList: [],
};

const formatMembershipTypeList = (data) => {
  let result = [];
  result = data.map((item) => {
    return {
      key: item.MembershipTypeId,
      value: item.MembershipTypeName,
      text: item.MembershipTypeName,
    };
  });

  result.unshift({ key: 0, value: "All", text: "All" });

  return result;
};

const feeGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.FEEGROUP_ISDUPLICATE:
      return {
        ...state,
        isDuplicate: action.payload.isDuplicate,
      };
    case type.FEEGROUP_RESET:
      return {
        ...state,
        isDuplicate: false,
      };
    case type.GET_FEEGROUP_DETAILS:
      return {
        ...state,
        isDuplicate: false,
        initialValues: action.payload.initialValues,
      };
    case type.GET_FEEGROUP_LIST:
      return {
        ...state,
        feeGroupReduxList: action.payload,
      };
    case type.MEMBERSHIPTYPE_LIST:
      return {
        ...state,
        membershipTypeList: formatMembershipTypeList(action.membershipTypeList),
      };
    case type.GET_AUDIT_LOG_FEILD:
      return {
        ...state,
        feeGroupAuditLogField: formatAuditLogFieldList(action.payload),
      };
    default:
      return state;
  }
};

export default feeGroupReducer;
