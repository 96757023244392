import axios from 'axios';
import * as type from '../../../action-types';
import * as commonActions from '../../../common.actions';
import getApiUrl from '../../../helpers/api-urls';
import { RequestTypes } from '../../../models/common.models';

export const getRenewalTaskDetails = (taskId, dispatch, isLoader = true) => {
	let taskDetailsUrl = getApiUrl('renewalTask', 'getTaskDetails');
	taskDetailsUrl = `${taskDetailsUrl}/${taskId}`;

	let dependsOnUrl = getApiUrl('renewalTask', 'getDependsOn');
	dependsOnUrl = `${dependsOnUrl}/taskId=${taskId}`;

	let dependentTasksUrl = getApiUrl('renewalTask', 'getDependentTasks');
	dependentTasksUrl = `${dependentTasksUrl}/taskId=${taskId}`;

	const taskWatchersUrl = getApiUrl('renewalTask', 'getTaskWatchersDetails') + `/${taskId}`;

	// Loader : Optional - Default always work
	if (isLoader) {
		dispatch(commonActions.setLoader(true));
	}

	Promise.all([axios.get(taskDetailsUrl), axios.get(dependsOnUrl), axios.get(dependentTasksUrl), axios.get(taskWatchersUrl)]).then(([taskDetailsRes, dependsOnRes, dependentTasksRes, taskWatchersListRes]) => {
		dispatch(commonActions.setLoader(false));

		if (taskWatchersListRes.data.status) {
			let content = taskWatchersListRes.data.content ? taskWatchersListRes.data.content : [];
			dispatch({ type: type.GET_TASK_WATCHERS_LIST, taskWatchersList: content });
		}

		if (taskDetailsRes.data.status && taskDetailsRes.data.content && dependsOnRes.data.status && dependentTasksRes.data.status) {
			dispatch({ type: type.GET_RENEWAL_TASK_DETAILS, renewalTaskDetails: taskDetailsRes.data.content, dependsOnList: dependsOnRes.data.content, dependentTasksList: dependentTasksRes.data.content });
		} else {
			dispatch({ type: type.SHOW_NOT_FOUND_PAGE });
		}
	})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3010'));
		});
};

// User get api action
export const getAllAssignees = (dispatch, status = true) => {
	let url = getApiUrl('renewalTask', 'getAllAssignees');
	url = url + '/' + status;
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch({ type: type.GET_USERS_LIST, usersList: content });
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3010')); // Default exception error
		});
};


// User get communication log action
export const getCommunicationLogAction = (dispatch, taskId, rowsPerPage, pageNumber, callback) => {
	let url = getApiUrl('renewalTask', 'communicationLogDetail');
	url = `${url}/taskid=${taskId}&rowsPerPage=${rowsPerPage}&pageNumber=${pageNumber}`
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				callback(content)
			} else {
				dispatch(commonActions.setMessage(true, response.data.code));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3057')); // Default exception error
		});
};


// Get task status list api action
export const getAllTaskStatus = (dispatch) => {
	const url = getApiUrl('renewalTask', 'getTaskStatus');

	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch({ type: type.GET_STATUS_LIST, statusList: content });
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3015')); // Default exception error
		});
};

export const updateTaskAssgineeStatus = (data, dispatch) => {
	const url = getApiUrl('renewalTask', 'updateTaskAssgineeStatus');
	dispatch(commonActions.setLoader(true));
	axios.put(url, data)
		.then((response) => {

			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '2001'));
				dispatch({ type: type.UPDATE_TASK_ASSIGNEE_STATUS_SUCCESS, updatedTaskStatusId: data.TaskStatusId });
			} else if (response.data.message && response.data.message === 8017) {
				dispatch({ type: type.SET_CURRENT_REQUEST_DATA, data, requestType: RequestTypes.BreakDependency });
				dispatch({ type: type.OPEN_CONFIRMATION_POPUP, errorData: response.data.content });
			} else if (response.data.message && response.data.message === 8031) {
				dispatch({ type: type.SET_CURRENT_REQUEST_DATA, data, requestType: RequestTypes.UpdateTaskAssigneeStatus });
				dispatch({ type: type.OPEN_CONFIRMATION_POPUP, errorData: response.data.content });
			} else if (response.data.message && response.data.message === 8020) {
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3011'));
		});
};

export const updateTaskDetails = (data, dispatch) => {
	const url = getApiUrl('renewalTask', 'updateTaskDetails');
	dispatch(commonActions.setLoader(true));
	dispatch({ type: type.SET_CURRENT_REQUEST_DATA, data, requestType: RequestTypes.UpdateTaskDetails });

	axios.put(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '2001'));
				dispatch({ type: type.UPDATE_TASK_DETAILS_SUCCESS });
			} else if (response.data.message === 8017) {
				dispatch({ type: type.OPEN_CONFIRMATION_POPUP, errorData: response.data.content });
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3012'));
		});
};

export const removeTaskDependency = (taskId, dependsOnTaskId, callback, dispatch) => {
	let url = getApiUrl('renewalTask', 'removeTaskDependency');
	url = `${url}/taskId=${taskId}&dependsOnTaskId=${dependsOnTaskId}`;
	dispatch(commonActions.setLoader(true));

	axios.post(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '2001'));
			} else if (response.data.message && response.data.message === 8020) {
				dispatch(commonActions.showErrorPopup(true, response.data.content));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3014')); // Default exception error
			return false;
		});
};


export const AddCommunicationLogAction = (data, dispatch, callback) => {
	let url = getApiUrl('renewalTask', 'postCommunicationLog');
	dispatch(commonActions.setLoader(true));
	axios.post(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '2029'));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3029')); // Default exception error
			return false;
		});
};

export const removeCommunicationLogAction = (taskCommentId, isOwnComment, callback, dispatch) => {
	let url = '';

	if (isOwnComment) {
		url = getApiUrl('renewalTask', 'removeOwnCommunicationLog');
	} else {
		url = getApiUrl('renewalTask', 'removeCommunicationLog');
	}
	url = `${url}/${taskCommentId}`;
	dispatch(commonActions.setLoader(true));

	axios.delete(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(taskCommentId);
				dispatch(commonActions.setMessage(true, '2031'));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((error) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3032')); // Default exception error
			return false;
		});
};

export const updateCommunicationLogAction = (data, isOwnComment, dispatch, callback) => {
	let url = '';

	if (isOwnComment) {
		url = getApiUrl('renewalTask', 'putOwnCommunicationLog');
	} else {
		url = getApiUrl('renewalTask', 'putCommunicationLog');
	}
	dispatch(commonActions.setLoader(true));

	axios.put(url, data)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(data);
				dispatch(commonActions.setMessage(true, '2030'));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3030')); // Default exception error
		});
};

// Api to get watchers on a task
export const getTaskWatchersDetails = (taskId, dispatch) => {
	const url = getApiUrl('renewalTask', 'getTaskWatchersDetails') + `/${taskId}`;
	dispatch(commonActions.setLoader(true));

	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				let content = response.data.content ? response.data.content : [];
				dispatch({ type: type.GET_TASK_WATCHERS_LIST, taskWatchersList: content });
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3031')); // Default exception error
		});
};

export const removeDocumentAction = (documentId, taskId, dispatch, callback) => {
	const url = getApiUrl('renewalTask', 'removeDocument') + `/docId=${documentId}&taskId=${taskId}`;
	dispatch(commonActions.setLoader(true));

	axios.delete(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '2001'));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '3037')); // Default exception error
		});
}

// Get task details call used when watchers/documents are added/removed and get the updated details.
export const getTaskDetailsAction = (taskId, dispatch) => {
	let taskDetailsUrl = getApiUrl('renewalTask', 'getTaskDetails');
	taskDetailsUrl = `${taskDetailsUrl}/${taskId}`;

	axios.get(taskDetailsUrl)
		.then((response) => {
			if (response.data.status) {
				dispatch({ type: type.GET_ONLY_RENEWAL_TASK_DETAILS, renewalTaskDetails: response.data.content });
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3010'));
		});
};

// User get audit log action
export const getAuditLogAction = (requestData, callback, dispatch) => {
	const url = getApiUrl('renewalTask', 'getAuditLogDetail');

	axios.get(url, { params: { requestData: JSON.stringify(requestData) } })
		.then((response) => {
			if (response.data.status) {
				let data = response.data ? response.data : [];
				callback(data)
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3040')); // Default exception error
		});
};

export const getSearchFieldAction = (callback, dispatch) => {
	let url = getApiUrl('renewalTask', 'getSearchFieldList');
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				let data = response.data ? response.data : [];
				callback(data)
			} else {
				dispatch(commonActions.setMessage(true, response.data.code));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, '3041')); // Default exception error
		});
};

export default {
	getRenewalTaskDetails,
	getAllAssignees,
	getAllTaskStatus,
	updateTaskAssgineeStatus,
	updateTaskDetails,
	removeTaskDependency,
	getCommunicationLogAction,
	AddCommunicationLogAction,
	removeCommunicationLogAction,
	updateCommunicationLogAction,
	getTaskWatchersDetails,
	getTaskDetailsAction,
	removeDocumentAction,
	getAuditLogAction,
	getSearchFieldAction
}
