import React from 'react';
import { Route } from 'react-router-dom';
import CommitteeDetail from '../modules/committee-management/containers/committees-details.container';
import CommitteeList from '../modules/committee-management/containers/committees.container';


const CommitteeRoutes = (props) => (
    <div>
        <Route path='/committee-management/committees' exact component={CommitteeList} />
        <Route path='/committee-management/committees/details/:committeeId' exact component={CommitteeDetail} />

    </div>
)

export default CommitteeRoutes;
