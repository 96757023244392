import { getMessage } from "helpers/util-common";
import React from "react";
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import RenderInputField from 'shared-components/Input';
import RenderRadioField from 'shared-components/RadioBox';
import TextArea from 'shared-components/TextArea';
import InputTagField from "../input-tag-field";

class ConsumerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consumerAdvocacyGroupBelong: '',
            advocacyGroupEmail: '',
            natureOfInterestConsumerDetails: ''
        }
    }

    resetAllState = (item) => {
        this.setState({
            ['advocacyGroupEmail'+item]: ''
        }, () => {
            this.props.change('advocacyGroupEmail'+item, this.state['advocacyGroupEmail'+item]);
        });
    }

    onChangeAdvocacyGroupBelong = (event, value, preValue, name) => {
        this.setState({ [name]: value });
        let item=name.replace('consumerAdvocacyGroupBelong', '')
        this.resetAllState(item)
    };

    onChangeconsumerAdvocacyGroupName = (event, value, preValue, name) => {
        this.setState({ [name]: value });
    };

    render() {

        const { messageCodes, item } = this.props;

        return (
            <div>
                <Grid columns='equal' className="mt0">
                    <Grid.Row className="mt10">
                        <Grid.Column>
                            <div className="radioWrap" >
                                <Field
                                    name={"consumerAdvocacyGroupBelong"+item}
                                    label="Do you belong to a Consumer Advocacy Group or Groups?"
                                    type="radio"
                                    className="mr10"
                                    component={RenderRadioField}
                                    required={true}
                                    selectedValue={this.state["consumerAdvocacyGroupBelong"+item]}
                                    onChange={this.onChangeAdvocacyGroupBelong}
                                    options={[{ label: 'Yes', value: '2' }, { label: 'No', value: '1' }]}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {Number(this.state["consumerAdvocacyGroupBelong"+item]) === 2 &&
                    <React.Fragment>
                        <InputTagField
                            fieldName={'consumerAdvocacyGroupName'+item}
                            label='Name of Consumer Advocacy Group(s)'
                            hintText='Click here to type'
                            maxLength={255}
                            {...this.props} />
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field
                                        name={"advocacyGroupEmail"+item}
                                        label="Advocacy Group Contact Email"
                                        component={RenderInputField}
                                        onChange={this.onChangeconsumerAdvocacyGroupName}
                                        maxLength={255}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </React.Fragment>
                }
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Field
                                name={'natureOfInterestConsumerDetails'+item}
                                label={getMessage(messageCodes, '9128.text')}
                                component={TextArea}
                                placeholder='Click here to type'
                                required={true}
                                onChange={this.onChangeConsumerDetails}
                                maxLength={255}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default ConsumerDetails;
