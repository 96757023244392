import * as commonActions from 'common.actions';
import { get } from 'lodash';

let self = {};
let accountNo = '';
let memberCommitteeSubcommitteeList = [];
export const setClassInstance = (instance) => {
    self = instance;
    accountNo = self.state.accountNumber;
    getMemberMainSubCommittees();

}
export const getMemberMainSubCommittees = (activeCommitteeId = 0) => {
    try {
        let isMainCommittee = self.state.isMainCommittee;
        self.props.getMemberMainSubCommitteesList(accountNo, (response) => {
            memberCommitteeSubcommitteeList = response;
            self.props.getMemberCommitteeDetails({
                accountNumber: accountNo,
                CommitteeId: activeCommitteeId && activeCommitteeId > 0 ? activeCommitteeId : self.props.activeCommitteeId
            })
            let officerTitleIds = [];
            if (memberCommitteeSubcommitteeList && memberCommitteeSubcommitteeList.length > 0) {
                memberCommitteeSubcommitteeList.map((item) => {
                    if (item.OfficerTitleId && item.OfficerTitleId > 0) {
                        officerTitleIds.push(item.OfficerTitleId);
                    }
                    if (item.SubCommittees && item.SubCommittees.length > 0) {
                        item.SubCommittees.map((subItem) => {
                            if (subItem.OfficerTitleId && subItem.OfficerTitleId > 0) {
                                officerTitleIds.push(subItem.OfficerTitleId)
                            }

                        })
                    }
                })
                if (officerTitleIds && officerTitleIds.length > 0) {
                    getMemberofficerTitle(officerTitleIds, activeCommitteeId)
                } else {
                    let toggleCommitteeId = 0;
                    let checkCommitteeStatus = '';
                    let checkMemberStatus = '';
                    memberCommitteeSubcommitteeList.map((item) => {
                        if (activeCommitteeId && activeCommitteeId > 0 && activeCommitteeId == item.CommitteeId) {
                            toggleCommitteeId = item.CommitteeId;
                            checkCommitteeStatus = item.CommitteeStatus;
                            isMainCommittee = true;
                            checkMemberStatus = item.MemberStatus;
                            self.props.getCommonCommitteeClassificationTypeAndNoVoteReason(item.CommitteeId, accountNo);
                        } else if (self.props.activeCommitteeId == item.CommitteeId && activeCommitteeId === 0) {
                            toggleCommitteeId = item.CommitteeId;
                            checkCommitteeStatus = item.CommitteeStatus;
                            checkMemberStatus = item.MemberStatus;
                            isMainCommittee = true;
                            self.props.getCommonCommitteeClassificationTypeAndNoVoteReason(item.CommitteeId, accountNo);
                        }
                        item.OfficerTitle = response.filter(info => info.OfficerTitleId == item.OfficerTitleId).length > 0 ? response.filter(info => info.OfficerTitleId == item.OfficerTitleId)[0].OfficerTitleName : '';
                        if (item.SubCommittees && item.SubCommittees.length > 0) {
                            item.SubCommittees.map((subItem) => {
                                if (activeCommitteeId && activeCommitteeId > 0 && activeCommitteeId == subItem.CommitteeId) {
                                    toggleCommitteeId = item.CommitteeId;
                                    checkCommitteeStatus = subItem.CommitteeStatus;
                                    isMainCommittee = false;
                                    checkMemberStatus = subItem.MemberStatus;
                                    self.props.getCommonCommitteeClassificationTypeAndNoVoteReason(subItem.CommitteeId, accountNo);
                                } else if (self.props.activeCommitteeId == subItem.CommitteeId && activeCommitteeId === 0) {
                                    toggleCommitteeId = item.CommitteeId;
                                    checkCommitteeStatus = subItem.CommitteeStatus;
                                    checkMemberStatus = subItem.MemberStatus;
                                    isMainCommittee = false;
                                    self.props.getCommonCommitteeClassificationTypeAndNoVoteReason(subItem.CommitteeId, accountNo);
                                }
                                subItem.OfficerTitle = '';
                            })
                        }
                    });
                    self.setState({ memberMainSubCommittees: memberCommitteeSubcommitteeList, showLoader: false, toggleCommitteeId, checkMemberStatus, checkCommitteeStatus, isMainCommittee, isMemberCommitteeGeneralInfoEdit: false });
                }
            }
        })
    } catch (ex) {
        self.setState({ showLoader: false });
    }
}
export const handleClick = (e, index, id) => {
    const { activeIndex } = self.state
    self.props.getCommonCommitteeClassificationTypeAndNoVoteReason(id, accountNo);
    const newIndex = activeIndex === index ? -1 : index
    self.setState({ activeIndex: newIndex, activeCommitteeId: id, checkCommitteeStatus: self.state.memberMainSubCommittees[index].CommitteeStatus, checkMemberStatus: self.state.memberMainSubCommittees[index].MemberStatus, isMainCommittee: true })
    self.props.getMemberCommitteeDetails({
        accountNumber: accountNo,
        CommitteeId: id
    });
    cancelEditMemberCommitteeBasicDetails()
}
const getMemberofficerTitle = (offierTitleIds, activeCommitteeId = 0) => {
    try {
        let isMainCommittee = self.state.isMainCommittee;
        let toggleCommitteeId = 0;
        let checkCommitteeStatus = '';
        let checkMemberStatus = '';
        if (offierTitleIds && offierTitleIds.length > 0) {
            self.props.getMemberOfficerTitle(offierTitleIds, (response) => {
                if (response && response.length > 0) {
                    memberCommitteeSubcommitteeList.map((item) => {
                        if (activeCommitteeId && activeCommitteeId > 0 && activeCommitteeId == item.CommitteeId) {
                            toggleCommitteeId = item.CommitteeId;
                            checkCommitteeStatus = item.CommitteeStatus;
                            checkMemberStatus = item.MemberStatus;
                            isMainCommittee = true;
                        }
                        else if (self.props.activeCommitteeId == item.CommitteeId && activeCommitteeId === 0) {
                            toggleCommitteeId = item.CommitteeId;
                            checkCommitteeStatus = item.CommitteeStatus;
                            checkMemberStatus = item.MemberStatus;
                            isMainCommittee = true;
                        }
                        item.OfficerTitle = response.filter(info => info.OfficerTitleId == item.OfficerTitleId).length > 0 ? response.filter(info => info.OfficerTitleId == item.OfficerTitleId)[0].OfficerTitleName : '';
                        if (item.SubCommittees && item.SubCommittees.length > 0) {
                            item.SubCommittees.map((subItem) => {
                                if (activeCommitteeId && activeCommitteeId > 0 && activeCommitteeId == subItem.CommitteeId) {
                                    toggleCommitteeId = item.CommitteeId;
                                    checkCommitteeStatus = subItem.CommitteeStatus;
                                    checkMemberStatus = subItem.MemberStatus;
                                    isMainCommittee = false;
                                }
                                else if (self.props.activeCommitteeId == subItem.CommitteeId && activeCommitteeId === 0) {
                                    toggleCommitteeId = item.CommitteeId;
                                    checkCommitteeStatus = subItem.CommitteeStatus;
                                    checkMemberStatus = subItem.MemberStatus;
                                    isMainCommittee = false;
                                }
                                subItem.OfficerTitle = response.filter(info => info.OfficerTitleId == subItem.OfficerTitleId).length > 0 ? response.filter(info => info.OfficerTitleId == subItem.OfficerTitleId)[0].OfficerTitleName : '';
                            })
                        }
                    });

                }
                commonActions.setLoader(false)
                self.setState({ memberMainSubCommittees: memberCommitteeSubcommitteeList, showLoader: false, toggleCommitteeId, checkCommitteeStatus, checkMemberStatus, isMemberCommitteeGeneralInfoEdit: false, isMainCommittee });
            })
        } else {
            self.setState({ showLoader: false });
        }
    }
    catch (ex) {
        self.setState({ showLoader: false });
    }
}
export const editMemberCommitteeBasicDetails = (CommitteeId) => {
    self.setState({ isMemberCommitteeBasicDetailsEdit: true, isMemberCommitteeGeneralInfoEdit: false, isShowClassificationEdit: false })
    self.props.getOfficerTitleListAndInactiveReasonList({ CommitteeId, accountNo })
}
export const cancelEditMemberCommitteeBasicDetails = () => {
    self.setState({ isMemberCommitteeBasicDetailsEdit: false, isMemberCommitteeGeneralInfoEdit: false })
}

export const openMemberCommitteeOfficerTitleHistoryModal = (CommitteeId) => {
    self.props.getMemberCommitteeOfficerTitleHistoryDetails(accountNo, CommitteeId);
    self.setState({ showMemberCommitteeOfficerTitleStatusModal: true });
}

export const closeMemberCommitteeOfficerTitleHistoryModal = () => {
    self.setState({ showMemberCommitteeOfficerTitleStatusModal: false });
}
export const showHideGeneralInfoEdit = () => {
    let isMemberCommitteeGeneralInfoEdit = !self.state.isMemberCommitteeGeneralInfoEdit;
    self.setState({ isMemberCommitteeBasicDetailsEdit: false, isMemberCommitteeGeneralInfoEdit, isShowClassificationEdit: false })
}

export const showMemberCommitteeOrganizationDetails = () => {
    self.props.getMemberCommitteeDetails({
        accountNumber: accountNo,
        CommitteeId: self.state.activeCommitteeId
    })
    self.setState({ isShowClassificationEdit: false })
}

export const showMemberCommitteeOrganizationDetailsEdit = () => {
    self.setState({ isMemberCommitteeBasicDetailsEdit: false, isMemberCommitteeGeneralInfoEdit: false, isShowClassificationEdit: true })
}

// TODO : Get Account Information.
export const getAccountNumber = (details) => {
    if (details && get(details, 'CommitteeId')) {
        return get(details, 'memberDetails.AccountNumber');
    }
    return get(details, 'AccountNumber');
}
// TODO : Get member details
export const getMemberDetails = (details) => {
    if (details && get(details, 'CommitteeId')) {
        return {
            Name: get(details, 'memberDetails.Name'),
            Email: get(details, 'memberDetails.Email'),
            PhoneNumber: get(details, 'memberDetails.PhoneNumber')
        }
    }
    return {
        Name: get(details, 'Name'),
        Email: get(details, 'Email'),
        PhoneNumber: get(details, 'PhoneNumber')
    };
}
