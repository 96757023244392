import React, { Component } from "react";
import OrganizationBannerHeader from '../organization-banner-header';
import OrganizationDetailsTabs from '../organization-details-tabs';
import CustomInfoAlertModal from 'shared-components/CustomAlertModal/CustomInfoAlertModal';
import * as _func from './function';
import { getMessage, decrypt } from 'helpers/util-common';

class OrganizationDetails extends Component {

    constructor(props) {
        super(props);
        const paramValue = this.props.match.params ? this.props.match.params.accountNumber : null;
        let companyId = 0;
        let accountNumber = null;
        try {
            companyId = parseInt(decrypt(paramValue).split("##")[0]);
        }
        catch {
            accountNumber = paramValue;
        }

        this.state = {
            accountNumber: accountNumber,
            companyId: companyId,
            isOpenAccountNumberNullConfirmation: false
        }
    }

    componentDidMount() {
        this.setState({
            isOpenAccountNumberNullConfirmation: this.state.companyId > 0
        });
    }

    componentWillMount() {
        _func.setClassInstance(this);
        _func.loadData(this.state.accountNumber, this.state.companyId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialValues &&
            this.props.initialValues &&
            this.props.initialValues.AccountNumber &&
            prevProps.initialValues.AccountNumber !== this.props.initialValues.AccountNumber) {
            const accountNumber = this.props.initialValues.AccountNumber;
            this.props.history.replace({ pathname: `/member-management/organizations/details/${accountNumber}` });
            this.setState({ accountNumber: accountNumber });
        }
    }

    componentWillUnmount() {
        this.props.resetDetailsData();
    }

    render() {
        const { initialValues, messageCodes } = this.props;
        return (
            <div>
                {initialValues && (initialValues.AccountNumber || initialValues.OrganizationalMemberId) && (
                    <div className='ui memberPage detailPage' data-testid="organizationsDetails">
                        <OrganizationBannerHeader {...this.props} modifiedBy={initialValues.ModifiedBy} modifiedDate={initialValues.ModifiedDate} />
                        <OrganizationDetailsTabs {...this.props} />
                        {this.state.isOpenAccountNumberNullConfirmation && (
                            <CustomInfoAlertModal content={getMessage(messageCodes, '8171.text')} handleClick={_func.closeAccountNumberNullConfirmationHandler} closeModal={_func.closeAccountNumberNullConfirmationHandler} />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default OrganizationDetails;
