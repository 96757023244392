import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import ResponsibilitiesCard from '../member-committee-responsibilitiescard';
import { MembershipTypes } from '../../members.model';
import { routeNavigationUrl, encrypt } from 'helpers/util-common';

class MemberCommitteeBasicDetails extends Component {

    state = {
        loading: false,
        showOfficerResponsibilities: this.props.showOfficerResponsibilities,
        officerResponsibilities: this.props.officerResponsibilities,
        checkCommitteeStatus: this.props.committeeStatus.toLowerCase() == 'a' ? true : false,
        checkMemberStatus: false,
        isMemberCommitteeStatusActive: !(this.props.memberCommitteeDetails.TopSection && this.props.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive')
    };


    componentWillReceiveProps(nextProps) {
        const isMemberCommitteeStatusActive = !(nextProps.memberCommitteeDetails.TopSection && nextProps.memberCommitteeDetails.TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive');
        const checkCommitteeStatus = nextProps.committeeStatus.toLowerCase() == 'a' ? true : false
        const checkMemberStatus = nextProps.memberStatus.toLowerCase() == 'a' ? true : false

        this.setState({
            isMemberCommitteeStatusActive: isMemberCommitteeStatusActive,
            checkCommitteeStatus: checkCommitteeStatus,
            checkMemberStatus: checkMemberStatus
        })
        if (nextProps.isParentClicked) {
            this.handleClickHideOfficerResponsibilities();
            this.props.reverseParentClick()
        }
    }

    handleClickShowOfficerResponsibilities = () => {
        this.setState({ showOfficerResponsibilities: true });
    }

    handleClickHideOfficerResponsibilities = () => {
        this.setState({ showOfficerResponsibilities: false });
    }

    handleResetComponent = (e) => {
        e.stopPropagation();
        this.setState({ showOfficerResponsibilities: false }, () => {
            this.setState({ showOfficerResponsibilities: false });
        });
    }

    handleClickShowHideResponsibilities = (e, key) => {
        e.stopPropagation();
        if (key === 0) {
            this.handleClickHideOfficerResponsibilities();
        }
        else {
            this.handleClickShowOfficerResponsibilities();
        }
    }

    render() {
        const { memberCommitteeDetails: { TopSection, CommitteeJoinDate, InactiveDate, ReactivatedOnCommitteeDate, OfficerAppointedDate }, loading, activeCommitteeId, MemberId, hidePopUp, onClickMemberCommitteeStatusHistory, onEditClick, onClickMemberCommitteeOfficerTitleStatusHistory, hasEditMemberParticipationOnCommittees, hasFeeGroupViewAccess, PAGE_TYPE, USER_PRIVILEGE } = this.props;
        const { showOfficerResponsibilities, checkCommitteeStatus, isMemberCommitteeStatusActive } = this.state;

        return (
            <section className="subCommInfo">
                {TopSection && (
                    <div>

                        <h5>{TopSection.CommitteeSubcommitteeName}
                            {(hasEditMemberParticipationOnCommittees && checkCommitteeStatus && isMemberCommitteeStatusActive) ?
                                <a title="Edit" className="editBtn" data-testid="editBtn" onClick={onEditClick}><Icon name="pencil" /></a> : ''
                            }
                        </h5>

                        {TopSection.MemberCommitteeStatus.toLowerCase() === 'inactive' && (
                            <Grid divided='vertically' columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelTitle">Committee Join Date</span>
                                        <span className="labelValue">{CommitteeJoinDate}</span>
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row>

                                    <Grid.Column>
                                        <span className="labelTitle">Member Committee Status</span>
                                        <span className="labelValue">
                                            Inactive
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelValue">
                                            <a className="linkTxt" onClick={onClickMemberCommitteeStatusHistory}>Member Committee Status History Details</a>
                                        </span>
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelTitle">Inactive Reason</span>
                                        <span className="labelValue">{TopSection.InactiveReason}</span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelTitle">Inactive Date</span>
                                        <span className="labelValue">{InactiveDate}</span>
                                    </Grid.Column>

                                </Grid.Row>

                                <Grid.Row>
                                    <React.Fragment>
                                        <Grid.Column></Grid.Column>
                                        {TopSection.IsLogAvailable &&
                                            <Grid.Column>
                                                <span className="labelValue">
                                                    <a className="linkTxt" onClick={onClickMemberCommitteeOfficerTitleStatusHistory}>View Officer Title History Details</a>
                                                </span>
                                            </Grid.Column>
                                        }
                                    </React.Fragment>
                                </Grid.Row>


                            </Grid>)
                        }
                        {TopSection.MemberCommitteeStatus.toLowerCase() === 'active' && TopSection.OfficerTitleId === 0 && (
                            <Grid divided='vertically' columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelTitle">Committee Join Date</span>
                                        <span className="labelValue">{CommitteeJoinDate}</span>
                                    </Grid.Column>
                                    {ReactivatedOnCommitteeDate &&
                                        <Grid.Column>
                                            <span className="labelTitle">Reactivated on Committee Date</span>
                                            <span className="labelValue">{ReactivatedOnCommitteeDate}</span>
                                        </Grid.Column>
                                    }
                                </Grid.Row>
                                <Grid.Row>

                                    <Grid.Column>
                                        <span className="labelTitle">Member Committee Status</span>
                                        <span className="labelValue">
                                            {TopSection.MemberCommitteeStatus}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelValue">
                                            <a className="linkTxt" onClick={onClickMemberCommitteeStatusHistory}>Member Committee Status History Details</a>
                                        </span>
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row>

                                    <React.Fragment>
                                        <Grid.Column>
                                            <span className="labelTitle">Officer Title</span>
                                            <span className="labelValue"></span>
                                        </Grid.Column>

                                        {TopSection.IsLogAvailable === true &&
                                            <Grid.Column>
                                                <span className="labelValue">
                                                    <a className="linkTxt" onClick={onClickMemberCommitteeOfficerTitleStatusHistory}>View Officer Title History Details</a>
                                                </span>
                                            </Grid.Column>
                                        }
                                    </React.Fragment>
                                </Grid.Row>
                            </Grid>)
                        }
                        {TopSection.MemberCommitteeStatus.toLowerCase() !== 'inactive' && TopSection.OfficerTitleId !== 0 && (
                            <Grid divided='vertically' columns={2}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelTitle">Committee Join Date</span>
                                        <span className="labelValue">{CommitteeJoinDate}</span>
                                    </Grid.Column>
                                    {ReactivatedOnCommitteeDate &&
                                        <Grid.Column>
                                            <span className="labelTitle">Reactivated on Committee Date</span>
                                            <span className="labelValue">{ReactivatedOnCommitteeDate}</span>
                                        </Grid.Column>
                                    }
                                </Grid.Row>
                                <Grid.Row>

                                    <Grid.Column>
                                        <span className="labelTitle">Member Committee Status</span>
                                        <span className="labelValue">
                                            {TopSection.MemberCommitteeStatus}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelValue">
                                            <a className="linkTxt" onClick={onClickMemberCommitteeStatusHistory}>Member Committee Status History Details</a>
                                        </span>
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelTitle">Officer Title</span>
                                        <span className="labelValue">{TopSection.OfficerTitleName}</span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelTitle">Officer Appointed Date</span>
                                        <span className="labelValue">{OfficerAppointedDate}</span>
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <span className="labelValue">
                                            <a className="linkTxt" onClick={(e) => this.handleClickShowHideResponsibilities(e, 1)} >View Responsibilities</a>
                                            {showOfficerResponsibilities && this.props.memberCommitteeDetails && this.props.memberCommitteeDetails.TopSection && < ResponsibilitiesCard {...this.props} showOfficerResponsibilities={showOfficerResponsibilities} show={(e) => { this.handleClickShowHideResponsibilities(e, 1) }} hide={(e) => { this.handleClickShowHideResponsibilities(e, 0) }} reset={(e) => this.handleResetComponent(e)} CommitteeId={activeCommitteeId} MemberId={MemberId} OfficerTitleId={TopSection.OfficerTitleId} />}
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <span className="labelValue">
                                            <a className="linkTxt" onClick={onClickMemberCommitteeOfficerTitleStatusHistory}>View Officer Title History Details</a>
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>)

                        }
                        {(TopSection.HonoraryStatus && this.props.initialValues.MemberTypeId !== MembershipTypes.Student) && <Grid divided='vertically' columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="labelTitle">Honorary Committee Member</span>
                                    {(TopSection.CommitteePayStatus && !hasFeeGroupViewAccess) && <span className="labelValue">  {`Committee Provides Funding- ${TopSection.FeeGroupName}`}</span>}
                                    {(TopSection.CommitteePayStatus && hasFeeGroupViewAccess) &&
                                        <span className="labelValue">  {`Committee Provides Funding- `} <a style={{ 'margin-top': '-4px', 'font-size': '16px' }} class="linkTxt" href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.FEEGROUP), USER_PRIVILEGE.VIEW, [encrypt(0), encrypt(TopSection.FeeGroupId)])}`} target="_blank">{TopSection.FeeGroupName + '-' + TopSection.FeeGroupNumber}</a></span>
                                    }


                                    {!TopSection.CommitteePayStatus && <span className="labelValue">Committee does not provide Funding</span>}

                                    <span></span>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>}
                    </div>)}
            </section>
        )
    }
}
export default MemberCommitteeBasicDetails;
