import * as commonActions from 'common.actions';
import { getBOSVolumeMasterList } from 'common.actions';
import _ from 'lodash';
import { connect } from "react-redux";
import committeeActions from '../actions/committee.actions';
import CommitteeList from '../index';

const mapStateToProps = state => {
    return {
        messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        committeeGridPreferences: state.commonReducer.committeeGridPreferences,
        committeeTypeList: state.committeesReducer.committeeTypeList,
        committeeTypeListForSelect: state.committeesReducer.committeeTypeListForSelect,
        hierarchyList: state.committeesReducer.hierarchyList,
        currentSearchType: state.commonReducer.currentSearchType,
        allCommitteesHierarchyDetails: state.committeesReducer.allCommitteesHierarchyDetails,
        overviewTagFieldList: state.committeesReducer.overviewTagFieldList,
        userPermission: state.commonReducer.userPermission,
        currentModuleTitle: state.commonReducer.currentModuleTitle,
        bosVolumeMasterList: state.commonReducer.bosVolumeMasterList,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getCommitteeList: (query, showLoader, callback) => {
        committeeActions.getCommitteeListAction(query, showLoader, callback, dispatch)
    },
    getCommitteeListAndGridPreferences: (query, showLoader, gridId, callback) => {
        committeeActions.getCommitteeListAndGridPreferencesAction(query, showLoader, gridId, callback, dispatch)
    },
    getMasterDataForCommitteeList: () => {
        committeeActions.getMasterDataForCommitteeListAction(dispatch);
    },
    updateGridPreference: (gridId, data, callback) => {
        commonActions.updateGridPreferenceAction(gridId, data, callback, dispatch)
    },
    getCommitteeExportData: (url, callback) => {
        committeeActions.getCommmitteeExportedDataAction(dispatch, callback, url)
    },
    getDesignationList: (searchText, callback) => {
        committeeActions.getDesignationListAction(searchText, callback);
    },
    // Following api is to get designations for add committee modals
    getCommitteesForSearch: (query, callback) => {
        committeeActions.getCommitteesForSearchAction(query, callback);
    },
    isAlreadyExistsCommitteeDesignation: (query, callback) => {
        committeeActions.isAlreadyExistsCommitteeDesignationAction(query, callback, dispatch);
    },
    addCommittee: (data, callback) => {
        committeeActions.addCommitteeAction(data, callback, dispatch);
    },
    getBosVolumeList: () => getBOSVolumeMasterList(dispatch),
    getBosVolumeForCommittee: (query, callback) => {
        committeeActions.getBosVolumeForCommitteeAction(query, callback, dispatch);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommitteeList);
