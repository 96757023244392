import React from "react";
import ExportExcel from "shared-components/ExportExcel";
import moment from 'moment';
import { formatDateToMMDDYYYY } from 'helpers/util-common';

const CommitteeRosterExcelExport = ({ data, CommitteeDesignation }) => {

    const fileName = CommitteeDesignation + '-Roster_' + moment(new Date()).format('MM-DD-YYYY');
    const tabName = 'Committee Roster';

    const columns = [
        { title: 'Account', style: { font: { bold: true } } },
        { title: 'Name', style: { font: { bold: true } } },
        { title: 'Organization', style: { font: { bold: true } } },
        { title: 'Member Type', style: { font: { bold: true } } },
        { title: 'Officer Title', style: { font: { bold: true } } },
        { title: 'Classification', style: { font: { bold: true } } },
        { title: 'Classification Assigned Date', style: { font: { bold: true } } },
        { title: 'Vote', style: { font: { bold: true } } },
        { title: 'No Vote Reason', style: { font: { bold: true } } },
        { title: 'Vote Assigned Date', style: { font: { bold: true } } },
        { title: 'Join Date', style: { font: { bold: true } } },
        { title: 'Status', style: { font: { bold: true } } },
        { title: 'Address', style: { font: { bold: true } } },
        { title: 'City', style: { font: { bold: true } } },
        { title: 'State', style: { font: { bold: true } } },
        { title: 'Postal Code', style: { font: { bold: true } } },
        { title: 'Country', style: { font: { bold: true } } },
        { title: 'Phone', style: { font: { bold: true } } },
        { title: 'Email', style: { font: { bold: true } } }
    ];

    let rows = [];
    for (let i = 0; i < data.length; i++) {
        let row = [
            { value: data[i].AccountNumber },
            { value: data[i].Name},
            { value: data[i].OrganizationName },
            { value: data[i].MembershipType },
            { value: data[i].OfficerTitleName },
            { value: data[i].Classification },
            { value: data[i].ClassificationDate },
            { value: data[i].Vote},
            { value: data[i].NoVoteReason },
            { value: data[i].VoteDate },
            { value: data[i].JoinedDate },
            { value: data[i].Status },
            { value: data[i].Address },
            { value: data[i].City },
            { value: data[i].State },
            { value: data[i].PostalCode },
            { value: data[i].Country },
            { value: data[i].Phone },
            { value: data[i].Email }
        ];
        rows.push(row);
    }

    // Need atleast an empty row.
    if (data.length === 0) {
        let row = [
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' }
        ];
        rows.push(row);
    }

    const multiDataSet = [{ columns: columns, data: rows }];

    return (
        <ExportExcel fileName={fileName} tabName={tabName} multiDataSet={multiDataSet} />
    );
}
export default CommitteeRosterExcelExport;
