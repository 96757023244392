import { connect } from "react-redux";
import memberActions from '../../members/actions/members.actions';
import feeGroupActions from '../../fee-group/actions/fee-group.actions'
import AdvanceSearchFeeGroup from '../components/advance-feegroup-search';

const mapStateToProps = state => {
    return {
        feeGroupList: state.feeGroupReducer.feeGroupReduxList,
        userPermission: state.commonReducer.userPermission,
        countryList: state.commonReducer.countryList,
        usStateList: state.commonReducer.usStateList,
        canadaStateList: state.commonReducer.canadaStateList,
        ...state.commonReducer.PAGE_CONFIG
    }
};

const mapDispatchToProps = (dispatch) => ({
    getMemberFirstNameList: (query, callback) => memberActions.getMemberFirstNameListAction(query, callback, dispatch),
    getMemberLastNameList: (query, callback) => memberActions.getMemberLastNameListAction(query, callback, dispatch),
    getMemberOrganizationNameList: (query, callback) => memberActions.getMemberOrganizationNameListAction(query, callback, dispatch),
    getCommitteeDesignationNameList: (callback) => memberActions.getCommitteeDesignationNameListAction(callback, dispatch),
    getFeeGroupList: (callback) => { feeGroupActions.getFeeGroupListAction(callback, dispatch); }
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchFeeGroup);
