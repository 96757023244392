import { dateMasking, getMessage } from 'helpers/util-common';
import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { Confirm, Form, Grid } from 'semantic-ui-react';
import ReduxCheckboxElement from 'shared-components/CheckBox/redux-checkbox';
import DateTimeForm from 'shared-components/DatePicker';
import RenderInputField from 'shared-components/Input';
import RenderSelect from 'shared-components/Select';
import EditorFieldComponent from 'shared-components/TextEditor/EditorFieldComponent';
import { CommitteeClassificationOptionsWithSelect } from '../../committee.model';
import * as _func from './function';
import DropdownMultipleSelection from 'shared-components/MultiSelectDropDown';
import { COMMITTEE_LEVELS } from '../../../../helpers/util-common';

class AddCommitteePage extends Component {

    state = {
        showAddCommitteeCancelConfirmation: false
    }

    componentDidMount() {
        _func.setInstance(this);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.className.indexOf('active') > 0 && this.props.className.indexOf('active') === -1) {
            this.props.change('IsSuppressCommittee', nextProps.addCommitteeData.isSuppressCommitteeOfParent);
        }
        if (nextProps.addCommitteeData !== this.props.addCommitteeData) {
            this.updateBosVolumeId(nextProps.addCommitteeData)
        }
        return true;
    }
    updateBosVolumeId = (data) => {
        if (data.newLevelDetails && data.newLevelDetails.levelId !== COMMITTEE_LEVELS.MAINCOMMITTEE && data.newLevelDetails.parentId) {
            this.props.getBosVolumeForCommittee(data.newLevelDetails.parentId, (bosVolumeId) => {
                if (bosVolumeId > 0)
                    this.props.change('BosVolume', bosVolumeId)
            })
        }
    }

    render() {
        const { addCommitteeData, submitting, handleSubmit, messageCodes, overviewTagFieldList, className } = this.props;
        const { showAddCommitteeCancelConfirmation } = this.state;
        return (
            <div className={className} ref="fullPagePopup">
                <div className="ui container">
                    <div>
                        <div className="headingTitle clearfix mt20 mb20">
                            <h2 style={{ 'font-size': '33px' }}>Add Committee - {addCommitteeData.newLevelDetails.levelName}</h2>
                        </div>
                    </div>
                    <Form size='large' onSubmit={handleSubmit(_func.onSubmitHandler)}>
                        <Grid columns={4}>
                            <Grid.Row>
                                <Grid.Column>
                                    <label className="labelTitle">Committee Type</label>
                                    <span className="labelValue">{addCommitteeData.committeeTypeName}</span>
                                </Grid.Column>
                                {addCommitteeData.parentCommitteeLevelsDetails && addCommitteeData.parentCommitteeLevelsDetails.length > 0 &&
                                    addCommitteeData.parentCommitteeLevelsDetails.map((item) => {
                                        return (
                                            <Grid.Column>
                                                <label className="labelTitle">{item.label}</label>
                                                <span className="labelValue">{item.value}</span>
                                            </Grid.Column>
                                        )
                                    })
                                }
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="Code" type="text"
                                        component={RenderInputField} label="Committee Designation"
                                        required='true'
                                        onBlur={_func.validateCommitteeDesignation}
                                        maxLength={(addCommitteeData.newLevelDetails.levelId > 1) ? 2 : 3} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Field name="EstablishmentDate"
                                        component={DateTimeForm} label="Date Formed"
                                        showTime={false}
                                        required='true'
                                        normalize={dateMasking}
                                    />
                                </Grid.Column>
                                {addCommitteeData.newLevelDetails.showClassification && (
                                    <Grid.Column>
                                        <Field name="Classification"
                                            component={RenderSelect} label="Classification"
                                            isMultiple={false}
                                            placeholder="Select"
                                            required={true}
                                            options={CommitteeClassificationOptionsWithSelect}
                                        />
                                    </Grid.Column>
                                )}
                                {addCommitteeData.bosVolumeList && addCommitteeData.bosVolumeList.length > 0 && <Grid.Column>
                                    <Field
                                        name="BosVolume"
                                        type="number"
                                        label="BOS Volume Location"
                                        placeholder='Search BOS Volume Location'
                                        component={DropdownMultipleSelection}
                                        isSearch={true}
                                        isMultiple={false}
                                        required={addCommitteeData.newLevelDetails.levelId === COMMITTEE_LEVELS.SUBCOMMITTEE}
                                        disabled={addCommitteeData.newLevelDetails.levelId === COMMITTEE_LEVELS.SECTION}
                                        options={addCommitteeData.bosVolumeList}
                                    />
                                </Grid.Column>}
                            </Grid.Row>

                        </Grid>
                        <Grid className="mt0">
                            <Grid.Row>
                                <Grid.Column>
                                    <Field name="Title" type="text"
                                        component={RenderInputField} label="Title"
                                        required='true'
                                        maxLength="200" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {className.indexOf('active') > 0 && (
                                        <Field name="IsSuppressCommittee" type="checkbox" className="checkAccordian"
                                            component={ReduxCheckboxElement}
                                            isDisabled={addCommitteeData.isSuppressCommitteeOfParent}
                                            label="Suppress Committee to appear for new or existing Member to join"
                                        />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            {addCommitteeData.newLevelDetails.showOverview && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="overviewEditor">
                                            <Field name="Overview" type="text" id="Overview"
                                                label="Overview" suggestionList={overviewTagFieldList}
                                                placeholder="Please mention @ to add available fields."
                                                component={EditorFieldComponent} />
                                        </div>
                                        <div className="availableFields">
                                            <span className="headTitle">Available Fields</span>
                                            <ul>
                                                {overviewTagFieldList && overviewTagFieldList.map(item => (<li>{item.text}</li>))}
                                            </ul>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {addCommitteeData.newLevelDetails.showScope && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="Scope" type="text" id="CommitteeScope"
                                            label="Committee Scope"
                                            component={EditorFieldComponent} />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <Grid.Row>
                                <Grid.Column className="actions">
                                    <button className="ui button primary mr10" type="submit" disabled={submitting}>Create</button>
                                    <button className="ui button cancel" type="button" onClick={_func.onCancelHandler}>Cancel</button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
                <Confirm
                    className="confirm-box"
                    open={showAddCommitteeCancelConfirmation}
                    content={getMessage(messageCodes, '9045.text')}
                    onCancel={_func.onConfirmationCancelHandler}
                    onConfirm={_func.onConfirmationConfirmHandler}
                    size="tiny"
                    cancelButton="No"
                    confirmButton="Yes"
                />
            </div>
        )
    }
}

export default (reduxForm({
    form: 'AddCommitteePage',
    validate: _func.validateHandler,
    onSubmitFail: (errors) => _func.scrollToError(errors)
})(AddCommitteePage));
