import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { generateUUIDForAutofill } from 'helpers/util-common';
import { CountryCodes } from '../../models/common.models';

const ReduxZipcode = (props) => {
   const { readOnly, input, offAutoComplete, label, type, placeholder, required, disabled, isDuplicate, maxLength, duplicateError, onKeyPress, meta: { touched, error }, orgFormValues } = props;
   const { name, value, onChange } = input;
   const uuid = generateUUIDForAutofill();
   const [cursorPos, setCursorPos] = useState(0);

   useEffect(() => {
      setMaskedValue(value || orgFormValues?.manualOrderZpcd);
   }, [orgFormValues && orgFormValues.orgCountryCode]);

   useEffect(() => {
      let el = document.getElementById('manualOrderZpcd')
      if (el) {
         if (el.selectionStart) {
            el.setSelectionRange(cursorPos, cursorPos);
         }
      }
   });
   const removeRegFromString = (str, reg) => {
      if (str) {
         const regObj = new RegExp(reg);
         return str.replace(regObj, '');
      }
      return null;
   }
   const handleOnChange = (e) => {
      setMaskedValue(e.target.value);
   }

   const handleOnFocus = (e) => {
      setCursorPos(value.length);
   }
   const extractAplhaNumericRegex = /[^0-9A-Z]+/gi
   const extractNumericRegex = /[^0-9]+/gi;

   const setMaskedValue = (inputValue) => {
      let el = document.getElementById('manualOrderZpcd')
      let cursorPos = el ? el?.selectionStart : 0;
      let reg = extractAplhaNumericRegex;
      if (orgFormValues && orgFormValues.orgCountryCode) {
         const { orgCountryCode } = orgFormValues
         if (orgCountryCode === CountryCodes.UNITEDSTATES.Code || maxLength === 5) {
            reg = extractNumericRegex;
         }
         const inputAlphaNumericValue = removeRegFromString(inputValue, reg);
         let maskedValue = '';
         if (inputAlphaNumericValue) {
            if (orgCountryCode === CountryCodes.UNITEDSTATES.Code || maxLength === 5) {
               maskedValue = inputAlphaNumericValue.substring(0, 5);
            } else if (orgCountryCode === CountryCodes.CANADA.Code || maxLength === 7) {
               let text = inputAlphaNumericValue.substring(0, 6);
               maskedValue = insertAtIndex(3, text, ' ');
               if (cursorPos === 4) {
                  cursorPos = 5;
               }
            } else {
               maskedValue = inputAlphaNumericValue.substring(0, 9);
            }
         }
         setCursorPos(cursorPos);
         onChange(maskedValue);
      }
   }

   const insertAtIndex = (idx, str, char) => {
      if (str.length > idx) {
         return str.substring(0, idx) + char + str.substr(idx);
      }
      return str;
   }

   return (
      <div>
         <label className="label" htmlFor={name}>
            {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
            {label}
         </label>
         <Form.Input
            fluid
            size='mini'
            {...input}
            name={name}
            id={name}
            value={value}
            placeholder={placeholder}
            className={touched && error ? 'error' : ''}
            type={type}
            disabled={disabled}
            maxLength={maxLength}
            onKeyPress={onKeyPress}
            onFocus={handleOnFocus}
            onChange={handleOnChange}
            readOnly={readOnly ? readOnly : false}
            autoComplete={offAutoComplete ? `none_${uuid}` : "on"}
         />
         {touched && (error && <span className="errorMessage">{error}</span>)}
         {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
      </div>
   )
}
export default ReduxZipcode;
