import { encrypt, getListItemTextById, getMessage } from 'helpers/util-common';
import { AddCommitteeFields, getStatusValueFromEligibilities } from '../../committee.model';

let self = null;
let selectedCommitteeType = null;
let TIMEOUT = null;
let prevDesignationSelected = [];
let committeeHierarchyList = [];
let isSuppressCommitteeOfParent = false;

export const setInstance = (instance) => {
    self = instance;
    selectedCommitteeType = null;
    prevDesignationSelected = [];
    committeeHierarchyList = [];
    isSuppressCommitteeOfParent = false;
}

export const onChangeCommitteeType = (e, value) => {
    selectedCommitteeType = value;
    let selectedCommitteeHierarchy = null;

    self.props.change('CommitteeHierarchy', selectedCommitteeHierarchy);

    // Get the hierarchy list
    let filteredCommitteeHierarchyList = [];
    committeeHierarchyList = [];

    if (self.props.allCommitteesHierarchyDetails && self.props.allCommitteesHierarchyDetails.length > 0) {
        filteredCommitteeHierarchyList = self.props.allCommitteesHierarchyDetails
            .filter(item => item.CommitteeTypeId === selectedCommitteeType);

        committeeHierarchyList = formatCommitteeHierarchyList(filteredCommitteeHierarchyList);
    }
    self.setState({
        committeeHierarchyList, selectedCommitteeHierarchy, controlList: [], designationSearchListForAllControls: [],
        showNoResultErrorListForAllContorls: [], isLoadingListForAllControls: []
    })
}

export const onChangeCommitteeHierarchy = (e, value) => {
    let selectedCommitteeHierarchy = value;
    let controlList = [];
    let designationSearchListForAllControls = [];
    let showNoResultErrorListForAllContorls = [];
    let isLoadingListForAllControls = [];

    if (value > 1) {
        prevDesignationSelected = [];

        for (let i = 0; i < selectedCommitteeHierarchy - 1; i++) {
            controlList.push(i);
            designationSearchListForAllControls[i] = [];
            showNoResultErrorListForAllContorls[i] = false;
            isLoadingListForAllControls[i] = false;
            prevDesignationSelected[i] = '';
        }
    }

    for (let i = 0; i < value - 1; i++) {
        let autoSearchFieldName = committeeHierarchyList[i].name;
        self.props.change(autoSearchFieldName, '');
        self.props.untouch(autoSearchFieldName); // To remove the earlier error
    }

    self.setState({ selectedCommitteeHierarchy, controlList, designationSearchListForAllControls, showNoResultErrorListForAllContorls, isLoadingListForAllControls });
}

export const getCommitteeDesignationList = (value, index) => {
    isSuppressCommitteeOfParent = false;

    // Remove the selected committee designations below the currently update committee desgination field
    if (index < prevDesignationSelected.length - 1) {
        for (let i = index + 1; i < prevDesignationSelected.length; i++) {
            prevDesignationSelected[i] = '';
            let controlName = committeeHierarchyList[i].name;
            self.props.change(controlName, '');
        }
    }

    const trimmedValue = value.trim();
    prevDesignationSelected[index] = '';

    // If user has not selected a parent committee, then do not send api to get data for child committee
    if (index > 0 && !prevDesignationSelected[index - 1]) {
        let { isLoadingListForAllControls, showNoResultErrorListForAllContorls, designationSearchListForAllControls } = self.state;
        isLoadingListForAllControls[index] = false;
        designationSearchListForAllControls[index] = [];
        showNoResultErrorListForAllContorls[index] = true;
        self.setState({ isLoadingListForAllControls, showNoResultErrorListForAllContorls, designationSearchListForAllControls });

        return;
    }

    clearTimeout(TIMEOUT);

    let { isLoadingListForAllControls, showNoResultErrorListForAllContorls, designationSearchListForAllControls } = self.state;
    isLoadingListForAllControls[index] = trimmedValue !== '';
    showNoResultErrorListForAllContorls[index] = false;

    self.setState({
        isLoadingListForAllControls, showNoResultErrorListForAllContorls
    });

    TIMEOUT = setTimeout(() => {
        if (trimmedValue.length > 0) {
            designationSearchListForAllControls[index] = [];
            self.setState({ designationSearchListForAllControls });

            let query = `CommitteeTypeId=${selectedCommitteeType}&LevelNo=${index + 1}&SearchText=${encrypt(trimmedValue)}`;
            let prevDesignation = 0;
            if (index > 0 && prevDesignationSelected[index - 1] && prevDesignationSelected[index - 1] > 0) {
                prevDesignation = prevDesignationSelected[index - 1];
            }

            if (prevDesignation !== 0) {
                query = `${query}&ParentId=${prevDesignation}`;
            }
            self.props.getCommitteesForSearch(query, (response) => {

                let formattedList = formatListDataForCommitteeDesignation(response);
                designationSearchListForAllControls[index] = formattedList;
                showNoResultErrorListForAllContorls[index] = formattedList.length === 0;
                isLoadingListForAllControls[index] = false;

                self.setState({
                    showNoResultErrorListForAllContorls, isLoadingListForAllControls, designationSearchListForAllControls
                });
            })
        } else {
            isLoadingListForAllControls[index] = false;
            designationSearchListForAllControls[index] = [];
            self.setState({ isLoadingListForAllControls, designationSearchListForAllControls });
        }
    }, 300);
}

export const onCommitteeDesignationSelect = (result, index) => {
    prevDesignationSelected[index] = result.id;

    // Update the IsSuppressCommittee flag when immediate parent is selected
    if (index === self.state.controlList.length - 1) {
        isSuppressCommitteeOfParent = result.isSuppressCommittee;
    }

    // Remove the suggestions after the selection
    let { designationSearchListForAllControls } = self.state;
    designationSearchListForAllControls[index] = [];
    self.setState({ designationSearchListForAllControls });

    let controlName = committeeHierarchyList[index].name;
    self.props.change(controlName, result.title);
    self.props.change('_validationHack', Date.now()); // Used this to call validation when search and select values are same
}

// Validation method for validate all value as per requirement
export const validateHandler = (values, props) => {
    const errors = {};
    let message = '';

    if (!values.CommitteeType) {
        errors.CommitteeType = getMessage(props.messageCodes, '4005.text');
    }
    if (!values.CommitteeHierarchy) {
        errors.CommitteeHierarchy = getMessage(props.messageCodes, '4006.text');
    }
    if (committeeHierarchyList && prevDesignationSelected && prevDesignationSelected.length > 0) {
        for (let i = 0; i < prevDesignationSelected.length; i++) {
            if (committeeHierarchyList[i] && committeeHierarchyList[i].name) {
                let controlName = committeeHierarchyList[i].name;
                if (!values[controlName] || (values[controlName] && !prevDesignationSelected[i])) {
                    let controlLabel = committeeHierarchyList[i].text;

                    message = getMessage(props.messageCodes, '4007.text')
                        .replace("@FieldName", controlLabel);
                    errors[controlName] = message;
                }
            }
        }
    }

    return errors;
}

export const onSubmitHandler = (data) => {
    let parentCommitteeLevelsDetails = [];
    let currentLevelEligibilities = committeeHierarchyList[data.CommitteeHierarchy - 1].eligibilities;
    let committeeTypeName = getListItemTextById(self.props.committeeTypeListForSelect, data.CommitteeType, 'text');
    let showClassification = getStatusValueFromEligibilities(currentLevelEligibilities, AddCommitteeFields.Classification);
    let showOverview = getStatusValueFromEligibilities(currentLevelEligibilities, AddCommitteeFields.Overview);
    let showScope = getStatusValueFromEligibilities(currentLevelEligibilities, AddCommitteeFields.Scope);
    let parentId = 0;

    if (prevDesignationSelected && prevDesignationSelected.length > 0) {
        parentId = prevDesignationSelected[prevDesignationSelected.length - 1];
    }

    let newLevelDetails = {
        levelId: data.CommitteeHierarchy,
        levelName: committeeHierarchyList[data.CommitteeHierarchy - 1].text,
        showClassification, showOverview, showScope, parentId
    }

    if (data.CommitteeHierarchy > 1) {
        for (let i = 0; i < data.CommitteeHierarchy - 1; i++) {
            let hierarchyDetails = committeeHierarchyList[i];
            parentCommitteeLevelsDetails.push({ label: hierarchyDetails.text, value: data[hierarchyDetails.name] });
        }
    }

    let addCommitteeData = {
        committeeTypeId: data.CommitteeType,
        committeeTypeName,
        newLevelDetails,
        isSuppressCommitteeOfParent,
        parentCommitteeLevelsDetails: parentCommitteeLevelsDetails
    }

    self.props.onSubmit(addCommitteeData);
}

const formatCommitteeHierarchyList = (list) => {
    let result = [];
    let i = 1;

    if (list && list.length > 0) {
        list.map((item) => {
            result.push({ 'key': item.CommitteeLevelId, 'text': item.CommitteeLevelTitle, value: i, name: 'AutoSearch_' + item.CommitteeLevelId, eligibilities: item.Eligibilities });
            i++;
        })
    }

    return result;
}

const formatListDataForCommitteeDesignation = (list) => {
    let result = [];

    if (list && list.length > 0) {
        list.map((item) => {
            result.push({ title: item.FormattedCommittee, id: item.CommitteeId, isSuppressCommittee: item.IsSuppressCommittee })
        })
    }

    return result;
}
