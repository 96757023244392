import React from "react";
import { Grid, Icon } from 'semantic-ui-react';
import { getPaidStatusName } from '../../common-functions';
import { PaidStatus, ActiveMemberStatusId, DISABLED_EDIT_ICON_ON_HISTORICAL_STATUS } from '../../members.model';

const MemberViewAccountDetails = ({ initialValues, onEditAccountInfo, onClickAccountStatusHistory, hasUpdateAccess }) => {
    const paidStatus = getPaidStatusName(initialValues.PaidStatusId);
    // TODO : Validate Edit Icon Historical Status.
    const isEditIconVisible = (reason) => {
        if (reason) {
            return DISABLED_EDIT_ICON_ON_HISTORICAL_STATUS.indexOf(reason) === -1;
        }
        return true;
    }
    return (
        <div data-testid="memberViewAccountDetails">
            <h5>Member Account Status Details
                {hasUpdateAccess && isEditIconVisible(initialValues.HistoricalReasonId) && (
                    <a title="Edit" className="editBtn" onClick={onEditAccountInfo}><Icon name="pencil" /></a>
                )}
            </h5>
            <Grid divided='vertically' columns={4}>
                <Grid.Row>
                    <Grid.Column>
                        <a className="linkTxt" onClick={onClickAccountStatusHistory}>View Member Account Status History</a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <span className="labelTitle">Account Status</span>
                        <span className="labelValue viewModeData">
                            {initialValues.StatusName}
                        </span>
                    </Grid.Column>
                    {initialValues.McsStatusMasterId !== ActiveMemberStatusId && (
                        <Grid.Column>
                            <span className="labelTitle">Historical Reason</span>
                            <span className="labelValue viewModeData">
                                {initialValues.HistoricalReason}
                            </span>
                        </Grid.Column>
                    )}
                    <Grid.Column>
                        <span className="labelTitle">Paid Status</span>
                        <span className="labelValue viewModeData">
                            {paidStatus}
                        </span>
                    </Grid.Column>
                    <Grid.Column>
                        <span className="labelTitle">Paid Date</span>
                        {initialValues.PaidStatusId === PaidStatus.Paid && (
                            <span className="labelValue">
                                {initialValues.PaidDate}
                            </span>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default MemberViewAccountDetails;
