// @flow
import lodash from 'lodash';
import * as type from './action-types';
import { allowPermissionForAuth, filterPermissionUrl, getModulesAdditionalPrivilege } from './helpers/util-common';
import { CountryCodes } from './models/common.models';
import { PAGE_CONFIG } from './models/navigation.models';

const initialState = {
  code: null,
  loading: false,
  miniLoader: false,
  showToastMessage: false,
  showErrorPopup: false,
  errorMessages: [],
  isShowConfirmationPopup: false,
  confirmationMessage: '',
  currentRequestData: {},
  currentRequest: '',
  currentSearchType: '',
  statusList: [], // Moved this list in common as it is master data for renewal task module and will not change
  showAlertPopup: false,
  alertMessage: '',
  committeeGridPreferences: [],
  committeeRosterGridPreference: [],
  showNotFoundPage: false,
  userPermission: null,
  PAGE_CONFIG: PAGE_CONFIG,
  originalCountryList: [],
  rCountryList: [],
  countryList: [],
  activeCountryList: [],
  usStateList: [],
  canadaStateList: [],
  currentModuleTitle: '',
  appUserList: [],
  memberGridPreferences: [],
  exceptionGridPreferences: [],
  isSafariAutoFillInfoVisible: false,
  bosVolumeMasterList: []
};


// Validate All Permission [GET,PUT,DELETE,POST]
const validateAllPermission = (request) => {
  let pagePermission = {};
  let selectedUrl = lodash(request).get("Input") && lodash(request).get("Input");
  let groupUrl = lodash(request).get("groupInput") && lodash(request).get("groupInput");
  let additionalPermissionUrl = getModulesAdditionalPrivilege(selectedUrl);
  if (groupUrl && groupUrl.length > 0) {
    let groupFilter = groupUrl.filter((res) => res.componentElementOnPage.indexOf(selectedUrl.uniqueKey) > -1);
    if (groupFilter && groupFilter.length === 0 && additionalPermissionUrl && additionalPermissionUrl.length > 0) {
      // Push additional & current page If Component Element on Page get Empty
      groupFilter.push(selectedUrl, ...additionalPermissionUrl);
    }
    if (groupFilter && groupFilter.length > 0) {
      // Push additional & current page Url.
      groupFilter.push(selectedUrl, ...additionalPermissionUrl);
      groupFilter.map((modules) => {
        let isPageAuthorized = filterPermissionUrl(modules, modules.oslMethod);
        let isAllow = allowPermissionForAuth(isPageAuthorized);
        if (isAllow) {
          pagePermission[modules.uniqueKey] = true;
        }
      });
    }
  }
  return pagePermission
}

const formatCountryListForRepresentativeDetails = (list) => {
  let formattedList = [];
  formattedList.push({ key: 'All', value: 'All', text: 'All' });
  formattedList.push({ key: CountryCodes.UNITEDSTATES.Code, value: CountryCodes.UNITEDSTATES.Code, text: CountryCodes.UNITEDSTATES.Name });
  formattedList.push({ key: CountryCodes.CANADA.Code, value: CountryCodes.CANADA.Code, text: CountryCodes.CANADA.Name });
  for (let i = 0; i < list.length; i++) {
    if (list[i].CountryCode && list[i].CountryCode.toLowerCase() !== CountryCodes.UNITEDSTATES.Code.toLowerCase() &&
      list[i].CountryCode.toLowerCase() !== CountryCodes.CANADA.Code.toLowerCase()) {
      const item = { key: list[i].CountryCode, text: list[i].CountryName, value: list[i].CountryCode };
      formattedList.push(item);
    }
  }
  return formattedList;
}

const formatCountryList = (list, isActive = false) => {
  let formattedList = [];
  formattedList.push({ key: 'All', value: 'All', text: 'All', code: 'ALL' });
  formattedList.push({ key: CountryCodes.UNITEDSTATES.Name, value: CountryCodes.UNITEDSTATES.Name, text: CountryCodes.UNITEDSTATES.Name, code: 'US' });
  formattedList.push({ key: CountryCodes.CANADA.Name, value: CountryCodes.CANADA.Name, text: CountryCodes.CANADA.Name, code: 'CA' });

  for (let i = 0; i < list.length; i++) {

    if (list[i].CountryName && list[i].CountryName.toLowerCase() !== CountryCodes.UNITEDSTATES.Name.toLowerCase() &&
      list[i].CountryName.toLowerCase() !== CountryCodes.CANADA.Name.toLowerCase()) {
      // Based on isActive flag pushed active country
      if (isActive) {
        if (list[i].IsActive) {
          const item = { key: list[i].CountryName, text: list[i].CountryName, value: list[i].CountryName, code: list[i].CountryCode };
          formattedList.push(item);
        }
      } else {
        const item = { key: list[i].CountryName, text: list[i].CountryName, value: list[i].CountryName, code: list[i].CountryCode };
        formattedList.push(item);
      }

    }
  }

  return formattedList;
}

const formatStateList = (list) => {
  let formattedList = [];
  formattedList.push({ key: 'All', value: 'All', text: 'All' });

  for (let i = 0; i < list.length; i++) {
    let item = { key: list[i].StateCode, text: list[i].StateCode, value: list[i].StateCode };

    formattedList.push(item);
  }

  return formattedList;
}

const getCurrentModule = (currentModuleData) => {
  if (currentModuleData && currentModuleData.Input) {
    return currentModuleData.Input.title
  }

  return '';
}

const formatUserList = (list) => {
  let result = [];
  result.push({ key: 0, text: 'Select', value: 0 });
  result.push({ key: -1, text: 'System', value: -1, UserId: -1, UserName: 'System' });

  if (list && list.length > 0) {
    for (let i = 0; i < list.length; i++) {

      if (list[i].UserId !== -1 && list[i].UserName.toLowerCase() !== 'system') {
        result.push({ key: list[i].UserId, text: list[i].UserName, value: list[i].UserId, UserId: list[i].UserId, UserName: list[i].UserName });
      }
    }
  }

  return result;
}
// Returns Active Country List
const originalCountryActiveList = (list) => {
  return list && list.length && list.filter(x => x.IsActive)
}

const formatBOSVolumeMasterList = (list) => {
  return list.map((res) => {
    res.key = res.BosVolumeId;
    res.text = res.BosVolumeLocation;
    res.value = res.BosVolumeId;
    return res;
  });
}

const reducerCommon = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_MESSAGE:
      return {
        ...state,
        code: action.code,
        showToastMessage: action.flag
      };

    case type.SET_LOADER:
      return {
        ...state,
        loading: action.flag
      };

    case type.SHOW_ERROR_POPUP:
      return {
        ...state,
        showErrorPopup: action.flag,
        errorMessages: action.data
      };

    case type.OPEN_CONFIRMATION_POPUP:
      return {
        ...state,
        isShowConfirmationPopup: true,
        confirmationMessage: action.errorData
      };
    case type.CLOSE_CONFIRMATION_POPUP:
      return {
        ...state,
        isShowConfirmationPopup: false,
        confirmationMessage: '',
        currentRequestData: {},
        currentRequest: ''
      };
    case type.SET_CURRENT_REQUEST_DATA:
      return {
        ...state,
        currentRequestData: action.data,
        currentRequest: action.requestType
      };

    case type.SEARCH_TASK:
      return {
        ...state,
        currentSearchType: action.type
      }

    case type.SEARCH_MEMBERS:
      return {
        ...state,
        currentSearchType: action.type
      }

    case type.SEARCH_ORGANIZATIONS:
      return {
        ...state,
        currentSearchType: action.type
      }
    case type.SEARCH_FEEGROUP:
      return {
        ...state,
        currentSearchType: action.type
      }
    case type.SEARCH_COMMITTEES:
      return {
        ...state,
        currentSearchType: action.type
      }

    case type.CLEAR_SEARCH_TYPE:
      return {
        ...state,
        currentSearchType: ''
      }
    case type.GET_STATUS_LIST:
      return {
        ...state,
        statusList: action.statusList
      };
    case type.SHOW_ALERT_POPUP:
      return {
        ...state,
        showAlertPopup: action.flag,
        alertMessage: action.data
      };
    case type.GET_COMMITTEE_GRID_PREFERENCES:
      return {
        ...state,
        committeeGridPreferences: action.committeeGridPreferences
      };
    case type.SET_MINI_LOADER:
      return {
        ...state,
        miniLoader: action.flag
      }
    case type.GET_COMMITTEE_ROSTER_GRID_PREFERENCES:
      return {
        ...state,
        committeeRosterGridPreference: action.committeeRosterGridPreference
      }
    case type.SHOW_NOT_FOUND_PAGE:
      return {
        ...state,
        showNotFoundPage: true
      }
    case type.HIDE_NOT_FOUND_PAGE:
      return {
        ...state,
        showNotFoundPage: false
      }
    case type.ALLOW_PERMISSION_FOR_PAGE:
      return {
        ...state,
        userPermission: validateAllPermission(action),
        PAGE_CONFIG: { ...PAGE_CONFIG },
        currentModuleTitle: getCurrentModule(action)
      }
    case type.GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: formatCountryList(action.countryList),
        activeCountryList: formatCountryList(action.countryList, true),
        originalActiveCountryList: originalCountryActiveList(action.countryList),
        rCountryList: formatCountryListForRepresentativeDetails(action.countryList),
        originalCountryList: action.countryList
      }
    case type.GET_US_STATE_LIST:
      return {
        ...state,
        usStateList: formatStateList(action.stateList)
      };
    case type.GET_CANADA_STATE_LIST:
      return {
        ...state,
        canadaStateList: formatStateList(action.stateList)
      };
    case type.GET_APP_USER_LIST:
      return {
        ...state,
        appUserList: formatUserList(action.userList)
      };
    case type.GET_MEMBER_GRID_PREFERENCES:
      return {
        ...state,
        memberGridPreferences: action.memberGridPreferences
      };
    case type.GET_EXCEPTION_GRID_PREFERENCES:
      return {
        ...state,
        exceptionGridPreferences: action.exceptionGridPreferences
      };
    case type.IS_SAFARI_AUTOFILL_VISISBLE:
      return {
        ...state,
        isSafariAutoFillInfoVisible: action.flag
      };
    case type.GET_BOS_VOLUME_MASTER_LIST:
      return {
        ...state,
        bosVolumeMasterList: formatBOSVolumeMasterList(action.list)
      };

    default:
      return state;
  }
};

export default reducerCommon;
