import { getListItemTextByValue } from 'helpers/util-common';
import React, { Component } from 'react';
import { Button, Grid, Icon } from "semantic-ui-react";
import CheckboxElement from 'shared-components/CheckBox';
import RenderSelect from 'shared-components/Select';
import ManageCommitteeClassificationModal from '../manage-committee-classifications-modal';

class ViewCommitteeSettings extends Component {

    state = {
        activeIndex: 0,
        isShowManageCommitteeClassificationModal: false
    }

    showManageCommitteeClassificationModal = () => {
        this.setState({ isShowManageCommitteeClassificationModal: true });
    }

    hideManageCommitteeClassificationModal = () => {
        this.setState({ isShowManageCommitteeClassificationModal: false });
    }

    render() {
        const { initialValues, hasUpdateAccess, onEditSetting, operatorList } = this.props;
        const { isShowManageCommitteeClassificationModal } = this.state;
        return (
            <div>
                <h5 className="mt30">Settings</h5>
                <div className="settingArea">
                    <div className="mb20">
                        <CheckboxElement IsEditable={false} IsChecked={initialValues.IsSuppressCommittee} setLabeltitleProp='Suppress Committee to appear for new or existing Member to join' />
                        {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <span onClick={onEditSetting} title="Edit" data-testid="editBtn" className="editBtn floatRight ml10"><Icon name="pencil" /></span>}
                        {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active')&& <Button secondary className="floatRight" onClick={this.showManageCommitteeClassificationModal}>
                            Manage Committee Classifications</Button>}

                    </div>

                    <div className="dashedBorderTop mt10 mb20">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <span className="labelTitle">Balance Rule Applicable</span>
                                    <span className="labelValue">{initialValues.IsBalanceRequired}</span>
                                </Grid.Column>
                            </Grid.Row>
                            {initialValues.IsBalanceRequired === 'Yes' && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="label">Balance Rule</label>
                                        <section className="greyBox type2 clearfix">
                                            <div className="memClassType ">
                                                <span className="labelTitle">Member Classification Type</span>
                                                <ul className="memClassTypeList">
                                                    {initialValues.ActiveCommitteeClassifications.map((item) => {
                                                        if (item.isLeft) {
                                                            return <li>
                                                                <CheckboxElement IsEditable={false} setLabeltitleProp={item.Name}
                                                                    IsChecked={true} />
                                                            </li>
                                                        }
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="operator ">
                                                <span className="labelTitle">Operator</span>
                                                <RenderSelect name="operatorList" options={operatorList} value={initialValues.OperatorSymbol} disabled />

                                            </div>
                                            <div className="memClassType">
                                                <span className="labelTitle">Member Classification Type</span>
                                                <ul className="memClassTypeList">
                                                    {initialValues.ActiveCommitteeClassifications.map((item) => {
                                                        if (item.isRight) {
                                                            return <li>
                                                                <CheckboxElement IsEditable={false} setLabeltitleProp={item.Name}
                                                                    IsChecked={true} />
                                                            </li>
                                                        }
                                                    })}
                                                </ul>
                                            </div>
                                        </section>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </div>
                </div>
                {isShowManageCommitteeClassificationModal && (
                    <ManageCommitteeClassificationModal {...this.props} onClose={this.hideManageCommitteeClassificationModal} />
                )}
            </div>
        )
    }
}

export default ViewCommitteeSettings;
