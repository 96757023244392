import { checkIfDateValid, compareDates, getMessage, monthYearDateMask } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Button, Form, Modal } from 'semantic-ui-react';
import RenderInput from 'shared-components/Input';

class AddGraduationDateModal extends Component {

    graduationDate = '';

    constructor(props) {
        super(props);
        this.state = {
            showErrorMessage: false,
            error: '',
            infoMessage: getMessage(this.props.messageCodes, '9043.text'),
            currentGraduationDate: ''
        }
        this.graduationDate = props.graduationDate;
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }


    onChange = (e, val) => {
        this.graduationDate = val;
        this.setState({ showErrorMessage: false });
    }

    onClose = () => {
        this.props.closeModal();
    }

    onSubmitHandler() {
        let { messageCodes } = this.props;
        let error = '';

        if (!this.graduationDate) {
            error = getMessage(messageCodes, '8122.text');
        } else if (this.graduationDate.length < 7 || !checkIfDateValid(this.graduationDate, 'MM/YYYY')) {
            error = getMessage(messageCodes, '4003.text');
        } else {
            // Check the minimum year validation to 1900
            let year = Number(this.graduationDate.substring(3));
            if (isNaN(year)) {
                error = getMessage(messageCodes, '4003.text');
            } else if (compareDates(new Date(), this.graduationDate, false, 'MM/YYYY')) {
                error = getMessage(messageCodes, '4004.text');
            }
        }

        if (error === '') {
            this.props.closeModal(this.graduationDate);
        } else {
            this.setState({ error, showErrorMessage: true });
        }
    }

    render() {
        const { showErrorMessage, error, infoMessage } = this.state;

        return (
            <Modal open='true' className="tiny">
                <Form noValidate onSubmit={this.onSubmitHandler}>

                    <Modal.Header>Graduation Date<i onClick={this.onClose} className="close" title="close">&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description style={{ 'min-height': '200px' }}>
                            <div className="mt20">
                                <p>{infoMessage}</p>
                                <label className='display-inline-block mr10'>Graduation Date:</label>
                                <div className='display-inline-block' style={{ 'width': '120px' }}>
                                    <Field name='GraduationDate' type="text"
                                        placeholder='MM/YYYY'
                                        component={RenderInput}
                                        normalize={monthYearDateMask}
                                        onChange={this.onChange}
                                    />
                                </div>
                                {showErrorMessage && (
                                    <span className="errorMessage mt0">{error}</span>
                                )}
                            </div>

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="pl0 mt20">
                            <Button primary type="submit">Save</Button>
                            <Button secondary onClick={this.onClose}>Cancel</Button>
                        </div>
                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default AddGraduationDateModal;
