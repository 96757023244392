import { checkIfDateValid, compareDates, compareTwoRichTextFields, compareTwoTextFields, encrypt, getMessage } from 'helpers/util-common';
import { minDateForInputDateFields } from 'models/common.models';

let self = {};
let scrollElement = null;

export const setInstance = (instance) => {
    self = instance;
    scrollElement = null;
}

export const validateHandler = (values, props) => {
    const errors = {};
    scrollElement = null;
    const { EstablishmentDate, Classification, CommitteeTitle, BylawRevisionDate, UpdateReason } = values;
    const { selectedCommitteeLevelEligibilitiesDetails } = props;

    if (!EstablishmentDate) {
        errors.EstablishmentDate = getMessage(props.messageCodes, '4009.text');
    } else if (!checkIfDateValid(EstablishmentDate, 'MM/DD/YYYY')) {
        errors.EstablishmentDate = getMessage(props.messageCodes, '4003.text');
    } else if (compareDates(minDateForInputDateFields, EstablishmentDate)) {
        errors.EstablishmentDate = getMessage(props.messageCodes, '4021.text');
    }

    if (!Classification && selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showClassification) {
        errors.Classification = getMessage(props.messageCodes, '4010.text');
    }
    if (!CommitteeTitle || (CommitteeTitle && CommitteeTitle.trim().length === 0)) {
        errors.CommitteeTitle = getMessage(props.messageCodes, '4011.text');
    }
    if (selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws) {
        if (BylawRevisionDate) { // As it is not required field, if any input is filled then valid that input
            if (!checkIfDateValid(BylawRevisionDate, 'MM/DD/YYYY')) {
                errors.BylawRevisionDate = getMessage(props.messageCodes, '4003.text');
            } else if (compareDates(BylawRevisionDate, self.maxDate, true)) {
                errors.BylawRevisionDate = getMessage(props.messageCodes, '4015.text');
            } else if (compareDates(minDateForInputDateFields, BylawRevisionDate)) {
                errors.BylawRevisionDate = getMessage(props.messageCodes, '4021.text');
            }
        }
    }
    if (!UpdateReason || (UpdateReason && UpdateReason.trim().length === 0)) {
        errors.UpdateReason = getMessage(props.messageCodes, '8121.text');
    }

    if (selectedCommitteeLevelEligibilitiesDetails && (selectedCommitteeLevelEligibilitiesDetails.showOverview ||
        selectedCommitteeLevelEligibilitiesDetails.showScope) && (errors.BylawRevisionDate || errors.UpdateReason) &&
        (!errors.CommitteeTitle && !errors.EstablishmentDate && !errors.Classification)) {
        scrollElement = document.querySelector('#forScrollPurpose');
    }

    return errors;
}

// Scroll to the errors for bylaw/update reason fields
export const onSubmitFailHandler = () => {
    if (scrollElement) {
        scrollElement.scrollIntoView(true);
    }
}

export const onSubmitHandler = (data) => {
    const { initialValues, onCloseEditGenInfo, getCommitteeDetails, displayNoChangesMadeMessage, selectedCommitteeLevelEligibilitiesDetails } = self.props;

    let overview = initialValues.OverviewTemplate;
    if (selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showOverview && data.OverviewTemplate) {
        overview = data.OverviewTemplate.trim().replace(new RegExp(' href="undefined"', 'g'), '')
            .replace(new RegExp(' href=""', 'g'), ''); // To remove the hrefs which were added for mention
    }

    let isTitleChanged = data.CommitteeTitle.trim() !== initialValues.CommitteeTitle;
    let isEstablishmentDateChanged = data.EstablishmentDate !== initialValues.EstablishmentDate;
    let isClassificationChanged = selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showClassification && data.Classification !== initialValues.Classification;
    let isOverviewChanged = selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showOverview && compareTwoRichTextFields(overview, initialValues.OverviewTemplate);
    let isScopeChanged = selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showScope && compareTwoRichTextFields(data.Scope, initialValues.Scope);
    let isBylawsUrlChanged = selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws && compareTwoTextFields(data.BylawUrl, initialValues.BylawUrl);
    let isBylawsRevisionDateChanged = selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws && compareTwoTextFields(data.BylawRevisionDate, initialValues.BylawRevisionDate);

    if (isTitleChanged || isEstablishmentDateChanged || isClassificationChanged || isOverviewChanged ||
        isScopeChanged || isBylawsUrlChanged || isBylawsRevisionDateChanged) {

        // Submit the form
        let requestData = {
            Title: data.CommitteeTitle.trim(),
            EstablishmentDate: data.EstablishmentDate,
            Classification: selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showClassification ? data.Classification : initialValues.Classification,
            CommitteeBalanceRule: isClassificationChanged && data.Classification.toLowerCase() === 'c' ? getBalanceRuleClassificationList(initialValues.CommitteeBalanceRule) : [],
            OperatorSymbol: initialValues.CommitteeBalanceRule && initialValues.CommitteeBalanceRule.OperatorSymbol ? initialValues.CommitteeBalanceRule.OperatorSymbol : initialValues.OperatorSymbol,
            Overview: overview ? overview : '',
            Scope: selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showScope && data.Scope ? data.Scope.trim() : initialValues.Scope,
            BylawUrl: selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws ? (data.BylawUrl ? data.BylawUrl.trim() : '') : initialValues.BylawUrl,
            BylawRevisionDate: selectedCommitteeLevelEligibilitiesDetails && selectedCommitteeLevelEligibilitiesDetails.showBylaws ? data.BylawRevisionDate : initialValues.BylawRevisionDate,
            UpdateReason: data.UpdateReason
        }

        self.props.updateCommittee(initialValues.CommitteeId, requestData, () => {
            getCommitteeDetails(encrypt(initialValues.CommitteeId));
            onCloseEditGenInfo();
        })
    } else {
        displayNoChangesMadeMessage();
        onCloseEditGenInfo();
    }
}

const getBalanceRuleClassificationList = (balanceRule) => {
    let result = [];

    if (balanceRule && balanceRule.ClassificationTypeLeft && balanceRule.ClassificationTypeRight) {
        balanceRule.ClassificationTypeLeft.map((item) => {
            result.push({ ClassificationId: item, IsLeft: true });
        })
        balanceRule.ClassificationTypeRight.map((item) => {
            result.push({ ClassificationId: item, IsLeft: false });
        })
    }

    return result;
}
