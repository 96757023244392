import { formatDateToMMDDYYYY, getListItemTextById, getMessage } from 'helpers/util-common';
import React, { Component } from "react";
import { Button, Icon, Loader } from "semantic-ui-react";
import RenderSelect from 'shared-components/Select';
import { CommitteeMeetingStatusList } from '../../committee.model';
import AddCommitteeMeetingSequenceModal from '../add-committee-meeting-sequence-modal';
import AddEditCommitteeMeetingDateModal from '../add-edit-committee-meeting-date-modal';
import DeleteCommitteeMeetingSequenceModal from '../delete-committee-meeting-sequence';
import EditMeetingsGenInfoModal from '../edit-meetings-gen-info';
import ViewCommitteeMeetingsGenInfo from '../view-meetings-gen-info';
import * as _func from './function';

class MeetingsTab extends Component {

    state = {
        meetingSequence: [],
        meetingDateList: [],
        meetingDatesCount: 0,
        showEditMeetingsGenInfo: false,
        showAddMeetingSequence: false,
        showDeleteMeetingSequence: false,
        showAddEditCommitteeMeetingDateForm: false,
        dataInProgress: false,
        action: '',
        status: CommitteeMeetingStatusList[1].value,
        currentPageNumber: 1,
        pageSize: 10,
        previousScrollPosition: 0,
        selectedMeetingData: {},
        noMeetingDateMessage: ''
    }

    componentWillMount() {
        _func.setInstance(this);
    }

    componentDidMount() {
        let scrollElement = document.getElementById('root');
        scrollElement && scrollElement.addEventListener('scroll', _func.handleScroll);
    }

    componentWillUnmount() {
        let scrollElement = document.getElementById('root');
        scrollElement && scrollElement.removeEventListener('scroll', _func.handleScroll);
    }

    render() {
        const { meetingSequence, showEditMeetingsGenInfo, showAddMeetingSequence, showDeleteMeetingSequence, showAddEditCommitteeMeetingDateForm, meetingDateList, action, selectedMeetingData, dataInProgress, status, noMeetingDateMessage } = this.state;
        const { allMeetingTypeList, initialValues, messageCodes, hasUpdateAccess } = this.props;

        return (
            <div className="meetingsTab" data-testid="meetingsTab">
                <ViewCommitteeMeetingsGenInfo initialValues={initialValues} onEditGenInfo={_func.onEditMeetingsGenInfo} hasUpdateAccess={hasUpdateAccess} />
                <div className="meetingDetails clearfix">
                    <section className="meetingSequence clearfix">
                        <h5>Meeting Sequence</h5>
                        <div className="meetingSequenceWrap">
                            {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <div className="txtRight mb10">
                                <Button secondary onClick={_func.onOpenAddMeetingSequenceModal} >Add Meeting Sequence</Button>

                            </div>}
                            <div className="tableWrapper clearfix">
                                <table className="customTable">
                                    <thead>
                                        <tr>
                                            <th width="30%">Month</th>
                                            <th width="55%">Meeting Type</th>
                                            {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <th width="15%">Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {meetingSequence.length > 0 && meetingSequence.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.MonthName}</td>
                                                    <td>{getListItemTextById(allMeetingTypeList, item.MeetingTypeId, 'text')}</td>
                                                    {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <td><a title="Delete" className="closeBtn" data-testid="deleteBtn" onClick={() => _func.onOpenDeleteMeetingSequence(index)}>
                                                        <Icon name="trash" /></a>
                                                    </td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {meetingSequence.length === 0 && (
                                    <div className="noRecordMessage type2">{getMessage(messageCodes, '9049.text')}</div>
                                )}
                            </div>
                        </div>
                    </section>
                    <section className="meetingDates clearfix">
                        <h5>Meeting Dates</h5>

                        <div className="meetingDatesWrap">

                            <div className="display-inline-block ">
                                <label className="label-inline vMiddle" for="status">Status</label>
                                <div className="display-inline-block minWidth120 vMiddle">
                                    <RenderSelect
                                        name="status"
                                        id="status"
                                        placeholder="Active"
                                        value={status}
                                        onChange={_func.onChangeStatusType}
                                        options={CommitteeMeetingStatusList}
                                    />
                                </div>
                            </div>
                            {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <Button secondary onClick={_func.onOpenAddCommitteeMeetingDate} className="floatRight">Add Meeting Date</Button>}
                            <div className="tableWrapper">
                                <table className="customTable">
                                    <thead>
                                        <tr>
                                            <th width="40%">Meeting Dates</th>
                                            <th width="45%">Status</th>
                                            {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <th width="15%">Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {meetingDateList.length > 0 && meetingDateList.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{formatDateToMMDDYYYY(item.MeetingDate)}</td>
                                                    <td>{item.IsActive ? 'Active' : 'Inactive'}</td>
                                                    {(hasUpdateAccess && initialValues.StatusName.toLowerCase() === 'active') && <td><a title="Edit" className="editBtn" data-testid="editButton" onClick={() => _func.onEditCommitteeMeetingDate(index)}>
                                                        <Icon name="pencil" /></a>
                                                    </td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {meetingDateList.length === 0 && (
                                    <div className="noRecordMessage type2">{noMeetingDateMessage}</div>
                                )}
                                {dataInProgress && (
                                    <Loader size='small' className="small-loader">Loading</Loader>
                                )}
                            </div>

                        </div>
                    </section>

                </div>

                {showEditMeetingsGenInfo && (
                    <EditMeetingsGenInfoModal {...this.props} onClose={_func.onCloseEditMeetingsGenInfo}
                        onSubmit={_func.onSubmitEditMeetingsGenInfo} />
                )}

                {showAddMeetingSequence && (
                    <AddCommitteeMeetingSequenceModal {...this.props} meetingSequence={meetingSequence}
                        onClose={_func.onCloseMeetingSequenceModal} onSubmit={_func.onSubmitMeetingSequenceModal} />
                )}

                {showDeleteMeetingSequence && (
                    <DeleteCommitteeMeetingSequenceModal {...this.props} onClose={_func.onCloseDeleteMeetingSequence}
                        onSubmit={_func.onSubmitDeleteMeetingSequence} />
                )}

                {showAddEditCommitteeMeetingDateForm && (
                    <AddEditCommitteeMeetingDateModal action={action} {...this.props} meetingData={selectedMeetingData}
                        onClose={_func.onCloseAddEditCommitteeMeetingDateModal} onSubmit={_func.onSubmitAddEditCommitteeMeetingDateModal} />
                )}
            </div>
        );
    }
}

export default MeetingsTab;
