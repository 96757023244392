import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { Form, Grid, Modal } from 'semantic-ui-react';
import AutoSearch from 'shared-components/Search';
import RenderSelect from 'shared-components/Select';
import * as _func from './function';

class AddCommitteeModal extends Component {

    state = {
        committeeHierarchyList: [],
        controlList: [],
        designationSearchListForAllControls: [],
        showNoResultErrorListForAllContorls: [],
        isLoadingListForAllControls: [],
        selectedCommitteeHierarchy: ''
    }

    componentWillMount() {
        _func.setInstance(this);
    }

    render() {
        const { committeeTypeListForSelect, handleSubmit, onClose, submitting } = this.props;
        const { committeeHierarchyList, designationSearchListForAllControls, showNoResultErrorListForAllContorls, isLoadingListForAllControls, controlList } = this.state;

        return (

            <Modal open='true' data-testid="addCommitteeModalPage" className="tiny addCommitttee">
                <Form size='large' onSubmit={handleSubmit(_func.onSubmitHandler)}>

                    <Modal.Header>ADD COMMITTEE <i className="close" onClick={onClose}>&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description style={{ 'min-height': '300px' }}>

                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="CommitteeType" type="text" required={true}
                                            component={RenderSelect} label="Select Committee Type"
                                            placeholder="Select"
                                            options={committeeTypeListForSelect}
                                            onChange={_func.onChangeCommitteeType}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="CommitteeHierarchy" type="text" required={true}
                                            component={RenderSelect} label="Select Committee Hierarchy"
                                            placeholder="Select"
                                            options={committeeHierarchyList}
                                            onChange={_func.onChangeCommitteeHierarchy}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                {controlList && controlList.length > 0 && controlList.map((item) => {
                                    let controlName = committeeHierarchyList[item].name;
                                    let controlLabel = committeeHierarchyList[item].text;

                                    return (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <div>
                                                    <label className="label"><i aria-hidden="true" className="asterisk  icon"></i>Select {controlLabel} </label>
                                                    <Field name={controlName} type="text"
                                                        component={AutoSearch}
                                                        placeholder="Search by Committee Designation"
                                                        source={designationSearchListForAllControls[item]}
                                                        onChange={(e, value) => _func.getCommitteeDesignationList(value, item)}
                                                        onResultSelect={value => _func.onCommitteeDesignationSelect(value, item)}
                                                        showNoResults={showNoResultErrorListForAllContorls[item]}
                                                        isLoading={isLoadingListForAllControls[item]}
                                                    />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                })
                                }

                            </Grid>

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div>
                            <button data-testid='submitOK' className="ui button primary" disabled={submitting} type="submit">Ok</button>
                            <button data-testid="cancelADVForm" className="ui button cancel" onClick={onClose}>Cancel</button>
                        </div>

                    </Modal.Actions>
                </Form>
            </Modal>
        )
    }
}

export default reduxForm({
    form: 'AddCommitteeModal',
    validate: _func.validateHandler
})(AddCommitteeModal);
